// src/jwtHelper.ts
import jwt_decode from "jwt-decode";
var JwtHelper = class {
};
JwtHelper.getUserInfo = (accessToken) => {
  const decoded = jwt_decode(`Bearer ${accessToken}`);
  return decoded;
};
JwtHelper.getInterviewId = (accessToken) => {
  var _a;
  const decoded = jwt_decode(`Bearer ${accessToken}`);
  return (_a = decoded["https://hasura.io/jwt/claims"]) == null ? void 0 : _a["x-hasura-interview-id"];
};
JwtHelper.isTokenValid = (accessToken) => {
  if (!accessToken)
    return false;
  const decoded = jwt_decode(accessToken);
  return !!(decoded.exp && decoded.exp > Math.floor((/* @__PURE__ */ new Date()).getTime() / 1e3));
};
export {
  JwtHelper
};
