import styled from "styled-components";

export namespace NSCandidateResultInfoCardStyle {
  export const Container = styled.div`
    box-sizing: border-box;
    min-width: 300px;
    position: relative;
    border-radius: 16px;
    height: 100%;
    width: 100%;
    background-color: ${(props) => props.theme.colors.light.light};
    border: 1px solid ${(props) => props.theme.colors.neutrals.grey03};
  `;

  export const Header = styled.div`
    color: ${(props) => props.theme.colors.dark.dark};
    border-bottom: 1px solid ${(props) => props.theme.colors.neutrals.grey03};
    padding: 16px 32px 16px 32px;
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
  `;

  export const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 32px;
    height: calc(100% - 56px);
  `;

  export const TextContent = styled.div`
    display: flex;
    width: 100%;
    text-align: left;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
  `;

  export const InnerHeader = styled.div`
    color: ${(props) => props.theme.colors.dark.dark};
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
  `;

  export const InnerText = styled.div`
    color: ${(props) => props.theme.colors.neutrals.grey07};
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
  `;

  export const SuffixContainer = styled.div``;
}
