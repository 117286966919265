import { ForwardedRef, forwardRef } from "react";

import { IMainInput } from "../MainInput/MainInput.type";
import MainInput from "../MainInput/MainInput";

function TextInputRef(props: IMainInput, ref?: ForwardedRef<HTMLInputElement>) {
  const {
    onBlur,
    errorText,
    infoText,
    id,
    error,
    prefixItem,
    onChange,
    maxLength,
    name,
    placeholder,
    disabled = false,
    onFocus,
    label,
    onClick,
    readonly,
    type,
    suffixItem,
    variant,
    showLabel = true,
    value,
    defaultValue,
    onSuffixClick,
    onKeyUp,
    // autoFocus,
  } = props;

  return (
    <MainInput
      ref={ref}
      variant={variant}
      value={value}
      defaultValue={defaultValue}
      type={type}
      suffixItem={suffixItem}
      onBlur={onBlur}
      errorText={errorText}
      infoText={infoText}
      id={id}
      showLabel={showLabel}
      error={error}
      prefixItem={prefixItem}
      maxLength={maxLength}
      name={name}
      placeholder={placeholder}
      disabled={disabled}
      onFocus={onFocus}
      label={label}
      onClick={onClick}
      readonly={readonly}
      onChange={onChange}
      onKeyUp={onKeyUp}
      onSuffixClick={onSuffixClick}
      //  autoFocus={autoFocus}
    />
  );
}

const TextInput = forwardRef(TextInputRef);

export default TextInput;
