import { Form } from "react-router-dom";
import styled from "styled-components";

export namespace NSOTPModalStyle {
  export const ModalContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `;

  export const ModalTitle = styled.div`
    font-size: ${(props) => props.theme.typography.titleh4.size};
    line-height: ${(props) => props.theme.typography.titleh4.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
  `;

  export const ModalSubtitle = styled.div`
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    color: ${(props) => props.theme.colors.neutrals.grey06};

    margin-top: 8px;
  `;

  export const OTPForm = styled(Form)`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  `;

  export const InputContainer = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 48px 0;
  `;

  export const Input = styled.input`
    outline: none;
    border: 2px solid ${(props) => props.theme.colors.neutrals.grey03};
    border-radius: 8px;
    padding: 12px;
    margin-right: 0 !important;
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    color: ${(props) => props.theme.colors.neutrals.grey07};
  `;

  export const ErrorText = styled.div`
    position: absolute;
    bottom: -44px;
    left: 0;
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    color: ${(props) => props.theme.colors.error.red01};
  `;

  export const ResendContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    min-width: 360px;
    margin-top: 24px;
  `;

  export const ResendText = styled.div`
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    color: ${(props) => props.theme.colors.neutrals.grey06};
  `;

  export const ResendButtonText = styled.div`
    display: flex;
    justify-content: space-between;
  `;

  export const TimeLeft = styled.div`
    width: 34px;
    margin-left: 4px;
  `;
}
