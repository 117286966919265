import toast from "react-hot-toast";
import * as XLSX from "xlsx";
type ExcelData = Record<string, any>[];
import i18n from "../library/i18next";
import ToastAlert from "../components/ToastAlert/ToastAlert";

export const excelExporter = (data: ExcelData, fileName: string): void => {
  if (!Array.isArray(data) || data.length === 0) {
    toast(
      <ToastAlert
        description={i18n.t("projects.exportExcelError")}
        type="error"
      />,

      {
        id: "exportExcelError",
      }
    );
    return;
  }

  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFileXLSX(wb, fileName, { bookType: "xlsx", type: "file" });
};
