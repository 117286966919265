import { NSRegisterStyle } from "./Register.style";
import LoginPng from "../../assets/login.png";
import i18n from "../../library/i18next";
import { Outlet } from "react-router-dom";
import RegisterForm from "./RegisterForm/RegisterForm";

const Register = () => {
  return (
    <NSRegisterStyle.Container>
      <NSRegisterStyle.LeftSideContainer>
        <RegisterForm />
      </NSRegisterStyle.LeftSideContainer>
      <NSRegisterStyle.RightSideContainer imgSrc={LoginPng}>
        <NSRegisterStyle.GradientOverlay />
        <NSRegisterStyle.DescriptionContainer>
          <NSRegisterStyle.WhiteTitle>
            {i18n.t("auth.title")}
          </NSRegisterStyle.WhiteTitle>
          <NSRegisterStyle.ColoredTitle>
            {i18n.t("auth.coloredTitle")}
          </NSRegisterStyle.ColoredTitle>
          <NSRegisterStyle.SubTitle>
            {i18n.t("auth.description")}
          </NSRegisterStyle.SubTitle>
        </NSRegisterStyle.DescriptionContainer>
      </NSRegisterStyle.RightSideContainer>
      <Outlet />
    </NSRegisterStyle.Container>
  );
};

export default Register;
