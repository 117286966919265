/* eslint-disable react/jsx-props-no-spreading */
import FormErrorBox from "../../../components/FormErrorBox/FormErrorBox";
import LinkButton from "../../../components/LinkButton/LinkButton";
import Logo from "../../../components/Logo/Logo";
import { NSLoginStyle } from "./Login.style";
import { NSLoginType } from "./Login.type";
import PasswordInput from "../../../components/PasswordInput/PasswordInput";
import SolidButton from "../../../components/SolidButton/SolidButton";
import TextInput from "../../../components/TextInput/TextInput";
import i18n from "../../../library/i18next";
import OTPModal from "../../../components/OTPModal/OTPModal";

function Login({ useLoginVm }: NSLoginType.ILogin) {
  const loginVm = useLoginVm();

  return (
    <NSLoginStyle.Container>
      <Logo />
      <NSLoginStyle.FormContainer>
        <NSLoginStyle.Title>{i18n.t("auth.loginTitle")}</NSLoginStyle.Title>

        <FormErrorBox
          isShown={loginVm.error !== null}
          onClickClose={loginVm.closeErrorBox}
          error={loginVm.error ?? ""}
          description={loginVm.error}
        />
        <NSLoginStyle.LoginForm onSubmit={loginVm.handleSubmit?.()}>
          <TextInput
            id="email"
            placeholder={i18n.t("forms.emailAddress")}
            error={!!loginVm.formErrors?.email}
            errorText={loginVm.formErrors?.email?.message}
            {...loginVm.register?.("email", { required: true })}
            onChange={(value) => loginVm.setValue?.("email", value)}
          />
          <PasswordInput
            id="password"
            placeholder={i18n.t("forms.password")}
            error={!!loginVm.formErrors?.password}
            errorText={loginVm.formErrors?.password?.message}
            {...loginVm.register?.("password", { required: true })}
            onChange={(value) => loginVm.setValue?.("password", value)}
          />
          <NSLoginStyle.ForgetPasswordButtonContainer>
            <LinkButton
              label={i18n.t("auth.forgotPassword")}
              onClick={loginVm.goToForgotPassword}
            />
          </NSLoginStyle.ForgetPasswordButtonContainer>

          <SolidButton
            label={i18n.t("auth.loginHiringCycle")}
            type="submit"
            fullWidth
          />
        </NSLoginStyle.LoginForm>
      </NSLoginStyle.FormContainer>
      {loginVm.isOTPModalOpen && (
        <OTPModal
          onClose={loginVm.onClickCloseOTPModal}
          onResend={loginVm.onClickResendOTP}
          onVerify={loginVm.onClickVerifyOTP}
        />
      )}
    </NSLoginStyle.Container>
  );
}

export default Login;
