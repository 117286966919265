/* eslint-disable import/no-extraneous-dependencies */
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import idx from "idx";
import { LOGIN_USER_INFO } from "../../utils/globalConstants";
import LocalStorageHelper from "../../utils/localStorageHelper";
import { NSAuthContextType } from "./AuthContext.type";
import { NSUserType } from "../../components/User/User.type";
import useDbAuthService from "../../service/DbAuthService/useDbAuthService";
import { useGraphQLQuery } from "../../hooks/useGraphQL";
import { GetUserCompanyDocument } from "../../gql/graphql";

const AuthContext = createContext<NSAuthContextType.IAuthContext>({
  error: null,
  isLoading: false,
  login: () => new Promise(() => {}),
  logout: () => {},
  isAuthenticated: false,
  user: {},
  companyName: "",
  userRole: NSUserType.UserRole.user,
  forgotPassword: () => new Promise(() => {}),
  resetPassword: () => new Promise(() => {}),
  getToken: () => null,
  verify2FA: () => new Promise(() => {}),
  setUser: () => {},
});

function AuthProvider({
  children,
  authService,
}: NSAuthContextType.IAuthProvider) {
  const auth = authService({
    dbAuth: useDbAuthService(),
  });

  const userFromLocalStorage = JSON.parse(
    window.localStorage.getItem(LOGIN_USER_INFO)!
  );

  const { data: companyData } = useGraphQLQuery(
    GetUserCompanyDocument,
    { enabled: !!userFromLocalStorage },
    {
      id: userFromLocalStorage?.sub,
    }
  );

  const [user, setUser] = useState<NSUserType.IDbUser>(userFromLocalStorage);

  const login: any = useCallback(
    async ({ email, password }: NSAuthContextType.ILoginParams) => {
      {
        const response = await auth.login({
          email,
          password,
        });

        return response;
      }
    },
    [auth]
  );

  const verify2FA = useCallback(
    async (code: string) => {
      return await auth.verify2FA(code);
    },
    [auth]
  );

  const logout: NSAuthContextType.ILogoutFunc = useCallback(() => {
    auth.logout();
  }, [auth]);

  const forgotPassword = useCallback(
    (email: string) => auth.forgotPassword(email),
    [auth]
  );
  const resetPassword = useCallback(
    (email: string, token: string, password: string) =>
      auth.resetPassword(email, token, password),
    [auth]
  );

  const getToken = useCallback(
    () => LocalStorageHelper.getAuthorizationToken(),
    []
  );

  const value: NSAuthContextType.IAuthContext = useMemo(
    () => ({
      error: auth.authError,
      isLoading: auth.isAuthLoading,
      login,
      verify2FA,
      logout,
      isAuthenticated: auth.isAuthenticated(),
      user,
      companyName: companyData?.user_by_pk?.company.company_name ?? "",
      userRole: idx(
        user,
        (_) => _["https://hasura.io/jwt/claims"]["x-hasura-default-role"]
      ) as NSUserType.UserRole,
      forgotPassword,
      resetPassword,
      getToken,
      setUser,
    }),
    [
      auth,
      login,
      logout,
      forgotPassword,
      resetPassword,
      getToken,
      verify2FA,
      user,
      setUser,
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("please use useAuth inside AuthProvider");
  }

  return context;
};

export { AuthProvider, useAuthContext };
