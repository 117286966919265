import { SubmitHandler, useForm } from "react-hook-form";
import { object, string } from "yup";
import { useCallback, useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { NSLoginType } from "./Login.type";
import { useAuthContext } from "../../../context/AuthContext/AuthContext";
import i18n from "../../../library/i18next";
import toast from "react-hot-toast";
import ToastAlert from "../../../components/ToastAlert/ToastAlert";
import { useGraphQLMutation } from "../../../hooks/useGraphQL";
import { ResendOtpDocument } from "../../../gql/graphql";

const loginFormSchema = object({
  email: string().email().required(i18n.t("forms.emailError")),
  password: string().required(i18n.t("forms.passwordError")),
}).required();

const useLoginVm = () => {
  const { error, isLoading, user, isAuthenticated, login, verify2FA } =
    useAuthContext();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
    setFocus,
  } = useForm<NSLoginType.ILoginFormValues>({
    resolver: yupResolver(loginFormSchema),
    shouldFocusError: false,
  });

  const { mutateAsync: resendOtp } = useGraphQLMutation(ResendOtpDocument);
  const [isErrorShown, setIsErrorShown] = useState(!!error);
  const [isOTPModalOpen, setIsOTPModalOpen] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const redirectTo = searchParams.get("redirectTo");
  const loginHandler: SubmitHandler<NSLoginType.ILoginFormValues> = useCallback(
    async (data, event) => {
      event?.preventDefault();
      login({
        email: data.email,
        password: data.password,
      })
        .then((res) => {
          if (res.twoFactorRequired) {
            setIsOTPModalOpen(true);
            return;
          }

          window.location.href = redirectTo ?? "/projects";
        })
        .catch(() => {
          setIsErrorShown(true);
        });
    },
    [login]
  );

  const submitHandler = () => handleSubmit(loginHandler);

  const goToForgotPassword = useCallback(() => {
    navigate("/forgot-password");
  }, [navigate]);

  const goToRegister = useCallback(() => {
    navigate("/register");
  }, [navigate]);

  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  const closeErrorBox = useCallback(() => {
    setIsErrorShown(!isErrorShown);
  }, [isErrorShown]);

  const onClickCloseOTPModal = () => {
    setIsOTPModalOpen(false);
  };

  const onClickVerifyOTP = (otp: string) => {
    verify2FA(otp)
      .then(() => {
        window.location.href = redirectTo ?? "/projects";
      })
      .catch(() => {
        toast(
          <ToastAlert
            description={i18n.t("forms.errorOnClickVerifyOTP")}
            type="error"
          />,
          {
            id: "errorOnClickVerifyOTP",
          }
        );
      });
  };

  const onClickResendOTP = () => {
    resendOtp({
      email: getValues("email"),
    })
      .then(() => {
        toast(
          <ToastAlert
            description={i18n.t("forms.resendOtpSuccess")}
            type="success"
          />,
          {
            id: "resendOtpSuccess",
          }
        );
      })
      .catch(() => {
        toast(
          <ToastAlert
            description={i18n.t("forms.resendOtpError")}
            type="error"
          />,
          {
            id: "resendOtpError",
          }
        );
      });
  };

  return {
    error,
    isLoading,
    isErrorShown,
    isAuthenticated,
    isOTPModalOpen,
    user,
    formErrors: errors,
    setValue,
    closeErrorBox,
    login,
    register,
    handleSubmit: submitHandler,
    onClickCloseOTPModal,
    onClickVerifyOTP,
    goToForgotPassword,
    goToRegister,
    onClickResendOTP,
  };
};

export default useLoginVm;
