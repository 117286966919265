import { Form } from "react-router-dom";
import styled from "styled-components";
import { NSMainInputStyle } from "../../../components/MainInput/MainInput.style";

export namespace NSRegisterFormStyle {
  export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;
    padding-top: 9.86%;
  `;

  export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme.colors.light.light};
    border-radius: 1rem;
    padding: 40px 64px;
    margin-top: 4rem;
    width: 540px;
  `;

  export const Title = styled.h4`
    font-size: ${(props) => props.theme.typography.titleh4.size};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    line-height: ${(props) => props.theme.typography.titleh4.lineHeight};
    color: ${(props) => props.theme.colors.dark.dark};
  `;

  export const Subtitle = styled.div`
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    color: ${(props) => props.theme.colors.neutrals.grey07};
    margin-top: 8px;
  `;

  export const RegisterForm = styled(Form)`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 2rem;
  `;

  export const PhoneContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 6px;

    ${NSMainInputStyle.ErrorText} {
      height: 0;
    }
  `;

  export const PhoneWarningText = styled.div`
    font-size: ${(props) => props.theme.typography.smallText2.size};
    line-height: ${(props) => props.theme.typography.smallText2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    color: ${(props) => props.theme.colors.neutrals.grey06};
    margin-top: 24px;
  `;

  export const PassWordTitle = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph1.size};
    line-height: ${(props) => props.theme.typography.pharagraph1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
  `;

  export const PasswordInputContainer = styled.div`
    position: relative;
  `;

  export const ErrorText = styled.div`
    position: absolute;
    bottom: -20px;
    color: ${(props) => props.theme.colors.error.red01};
    font-size: ${(props) => props.theme.typography.smallText2.size};
    line-height: ${(props) => props.theme.typography.smallText2.lineHeight};
    width: 100%;
  `;

  export const PasswordContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    ${ErrorText} {
      position: relative;
    }

    ${NSMainInputStyle.ErrorText} {
      height: 8px;
    }
  `;

  export const PhoneErrorText = styled(ErrorText)`
    bottom: -16px;
  `;

  export const CheckboxContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
  `;

  export const CheckboxText = styled.div`
    font-size: ${(props) => props.theme.typography.smallText2.size};
    line-height: ${(props) => props.theme.typography.smallText2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
  `;

  export const CheckboxInputContainer = styled.div``;

  export const Link = styled.a``;
}
