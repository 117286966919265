import {
  ForgotPasswordDocument,
  LoginDocument,
  LoginOutput,
  ResetPasswordDocument,
  Verify2FaDocument,
  Verify2FaOutput,
} from "../../gql/graphql";

import { ActionResponse } from "../../types/ActionResponse";
import { DbAuth } from "../authService/authService.type";
import { useGraphQLMutation } from "../../hooks/useGraphQL";

const useDbAuthService = (): DbAuth => {
  const { mutateAsync: loginMutationAsync } = useGraphQLMutation(LoginDocument);
  const { mutateAsync: forgotPasswordMutationAsync } = useGraphQLMutation(
    ForgotPasswordDocument
  );
  const { mutateAsync: resetPasswordMutationAsync } = useGraphQLMutation(
    ResetPasswordDocument
  );
  const { mutateAsync: verify2FaMutationAsync } =
    useGraphQLMutation(Verify2FaDocument);
  async function login(
    username: string,
    password: string
  ): Promise<LoginOutput> {
    const response = await loginMutationAsync({ username, password });
    return response.login!;
  }

  async function verify2FA(code: string): Promise<Verify2FaOutput> {
    const response = await verify2FaMutationAsync({ otpCode: code });
    return response.verify2FA!;
  }

  async function forgotPassword(username: string): Promise<ActionResponse> {
    const response = await forgotPasswordMutationAsync({ username });
    return response.forgotPassword!;
  }
  async function resetPassword(
    username: string,
    recoveryToken: string,
    password: string
  ): Promise<ActionResponse> {
    const response = await resetPasswordMutationAsync({
      username,
      recoveryToken,
      password,
    });
    return response.resetPassword!;
  }
  return {
    login,
    forgotPassword,
    resetPassword,
    verify2FA,
  };
};

export default useDbAuthService;
