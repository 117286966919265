import { NSMetricPreviewBarStyle } from "./MetricPreviewBar.style";
import { NSMetricPreviewBarType } from "./MetricPreviewBar.type";

function MetricPreviewBar({
  barItems,
  selectedId,
  onClickItem,
}: NSMetricPreviewBarType.IMetricPreviewBar) {
  return (
    <NSMetricPreviewBarStyle.Container>
      {barItems.map((item, index) => (
        <NSMetricPreviewBarStyle.BarButton
          onClick={() => onClickItem?.(index)}
          selected={selectedId === item.value}
          key={item.value}
        >
          <NSMetricPreviewBarStyle.BarButtonLabel
            isPassed={selectedId > item.value!}
          >
            {item.label}
          </NSMetricPreviewBarStyle.BarButtonLabel>
        </NSMetricPreviewBarStyle.BarButton>
      ))}
    </NSMetricPreviewBarStyle.Container>
  );
}

export default MetricPreviewBar;
