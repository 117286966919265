/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  date: any;
  jsonb: any;
  numeric: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};

export type ApiKeyActionOutput = {
  __typename?: "ApiKeyActionOutput";
  apiKey?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
  success?: Maybe<Scalars["Boolean"]>;
};

export type ApplyJobInput = {
  coverLetter?: InputMaybe<Scalars["String"]>;
  cvFileName?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  name: Scalars["String"];
  phone: Scalars["String"];
  shareId: Scalars["String"];
  surname: Scalars["String"];
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["Boolean"]>;
  _gt?: InputMaybe<Scalars["Boolean"]>;
  _gte?: InputMaybe<Scalars["Boolean"]>;
  _in?: InputMaybe<Array<Scalars["Boolean"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["Boolean"]>;
  _lte?: InputMaybe<Scalars["Boolean"]>;
  _neq?: InputMaybe<Scalars["Boolean"]>;
  _nin?: InputMaybe<Array<Scalars["Boolean"]>>;
};

export type ClientCandidateSearchOutput = {
  __typename?: "ClientCandidateSearchOutput";
  id: Scalars["String"];
  name: Scalars["String"];
  projects?: Maybe<Array<Maybe<ClientCandidateSearchProjectsOutput>>>;
  surname: Scalars["String"];
};

export type ClientCandidateSearchProjectsOutput = {
  __typename?: "ClientCandidateSearchProjectsOutput";
  id?: Maybe<Scalars["String"]>;
  projectName?: Maybe<Scalars["String"]>;
};

export type ClientInterviewSettings = {
  __typename?: "ClientInterviewSettings";
  allowHrReview?: Maybe<Scalars["String"]>;
  clarificationCheckboxText?: Maybe<Scalars["String"]>;
};

export type CommonActionOutput = {
  __typename?: "CommonActionOutput";
  message?: Maybe<Scalars["String"]>;
  success?: Maybe<Scalars["Boolean"]>;
};

export type ConsentInput = {
  consentDocument: Scalars["String"];
  consentStatus: Scalars["String"];
  consentType: Scalars["String"];
};

export type CreateCompanyInput = {
  companyName: Scalars["String"];
  email: Scalars["String"];
  firstname: Scalars["String"];
  lastname: Scalars["String"];
  password: Scalars["String"];
};

/** Boolean expression to compare columns of type "Float". All fields are combined with logical 'AND'. */
export type Float_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["Float"]>;
  _gt?: InputMaybe<Scalars["Float"]>;
  _gte?: InputMaybe<Scalars["Float"]>;
  _in?: InputMaybe<Array<Scalars["Float"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["Float"]>;
  _lte?: InputMaybe<Scalars["Float"]>;
  _neq?: InputMaybe<Scalars["Float"]>;
  _nin?: InputMaybe<Array<Scalars["Float"]>>;
};

export type GenerateShareableJdLinkOutput = {
  __typename?: "GenerateShareableJDLinkOutput";
  shareLink: Scalars["String"];
};

export type InsertApiKeyInput = {
  expiredAt?: InputMaybe<Scalars["String"]>;
  label: Scalars["String"];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["Int"]>;
  _gt?: InputMaybe<Scalars["Int"]>;
  _gte?: InputMaybe<Scalars["Int"]>;
  _in?: InputMaybe<Array<Scalars["Int"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["Int"]>;
  _lte?: InputMaybe<Scalars["Int"]>;
  _neq?: InputMaybe<Scalars["Int"]>;
  _nin?: InputMaybe<Array<Scalars["Int"]>>;
};

export type InterviewCandidate = {
  __typename?: "InterviewCandidate";
  emailAddress: Scalars["String"];
  name: Scalars["String"];
  surname: Scalars["String"];
};

export type InterviewConsent = {
  __typename?: "InterviewConsent";
  consentStatus?: Maybe<Scalars["String"]>;
  consentType?: Maybe<Scalars["String"]>;
};

export type InterviewOutput = {
  __typename?: "InterviewOutput";
  candidate: InterviewCandidate;
  clientInterviewSettings?: Maybe<ClientInterviewSettings>;
  consents?: Maybe<Array<Maybe<InterviewConsent>>>;
  interviewStartsAt?: Maybe<Scalars["String"]>;
  interviewType?: Maybe<Scalars["String"]>;
  isInterviewAvailable: Scalars["Boolean"];
  language?: Maybe<Scalars["String"]>;
  interviewDuration: Scalars["Int"];
  questionsInLanguages?: Maybe<InterviewQuestionsInLanguage>;
  status?: Maybe<Scalars["String"]>;
  totalQuestionCount: Scalars["Int"];
  unansweredQuestionCount: Scalars["Int"];
};

export type InterviewQuestion = {
  __typename?: "InterviewQuestion";
  description?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  language: Scalars["String"];
  order: Scalars["Int"];
  question: Scalars["String"];
  tip?: Maybe<Scalars["String"]>;
  viqId: Scalars["Int"];
};

export type InterviewQuestionOutput = {
  __typename?: "InterviewQuestionOutput";
  question?: Maybe<InterviewQuestion>;
};

export type InterviewQuestionsInLanguage = {
  __typename?: "InterviewQuestionsInLanguage";
  en?: Maybe<Scalars["Int"]>;
  tr?: Maybe<Scalars["Int"]>;
};

export type InviteMemberInput = {
  email: Scalars["String"];
  name: Scalars["String"];
  surname: Scalars["String"];
};

export type JdAnswerData = {
  __typename?: "JDAnswerData";
  body?: Maybe<Scalars["String"]>;
  isSelected?: Maybe<Scalars["Boolean"]>;
  title?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type JdPageMeta = {
  __typename?: "JDPageMeta";
  canAddMore?: Maybe<Scalars["Boolean"]>;
  company?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  maxVisibleCount?: Maybe<Scalars["Int"]>;
  numberOfEmployees?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["String"]>;
  workingType?: Maybe<Scalars["String"]>;
};

export type JdPageOutput = {
  __typename?: "JDPageOutput";
  answers?: Maybe<Array<JdAnswerData>>;
  completed: Scalars["Int"];
  meta?: Maybe<JdPageMeta>;
  page: Scalars["String"];
  step: Scalars["String"];
  subtitle?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  validation?: Maybe<JdPageValidation>;
};

export type JdPageValidation = {
  __typename?: "JDPageValidation";
  count?: Maybe<JdPageValidationCount>;
};

export type JdPageValidationCount = {
  __typename?: "JDPageValidationCount";
  max?: Maybe<Scalars["Int"]>;
  min?: Maybe<Scalars["Int"]>;
};

export type LoginInput = {
  password: Scalars["String"];
  username: Scalars["String"];
};

export type LoginOutput = {
  __typename?: "LoginOutput";
  accessToken: Scalars["String"];
  otpExpiration?: Maybe<Scalars["String"]>;
  twoFactorRequired?: Maybe<Scalars["Boolean"]>;
};

export type PasswordChangeInput = {
  currentPassword: Scalars["String"];
  newPassword: Scalars["String"];
};

export type ProjectSearchOutput = {
  __typename?: "ProjectSearchOutput";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type RegisterInput = {
  inviteToken: Scalars["String"];
  name: Scalars["String"];
  otpCode: Scalars["String"];
  password: Scalars["String"];
  phoneNumber: Scalars["String"];
  surname: Scalars["String"];
};

export type RegisterOutput = {
  __typename?: "RegisterOutput";
  accessToken: Scalars["String"];
};

export type ResendOtpInput = {
  email?: InputMaybe<Scalars["String"]>;
  invitationToken?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
};

export type ResetPasswordInput = {
  password: Scalars["String"];
  recoveryToken: Scalars["String"];
  username: Scalars["String"];
};

export type SearchOutput = {
  __typename?: "SearchOutput";
  clientCandidate?: Maybe<Array<Maybe<ClientCandidateSearchOutput>>>;
  project?: Maybe<Array<Maybe<ProjectSearchOutput>>>;
};

export type SendOtpInput = {
  invitationToken: Scalars["String"];
  phoneNumber: Scalars["String"];
};

export type SendOtpOutput = {
  __typename?: "SendOtpOutput";
  otpExpiration: Scalars["String"];
  success: Scalars["Boolean"];
};

export type SignedUrlInput = {
  contentType: Scalars["String"];
  filenames: Array<Scalars["String"]>;
  folder: Scalars["String"];
  operation: Scalars["String"];
};

export type SignedUrlOutput = {
  __typename?: "SignedUrlOutput";
  signedUrls: Array<Scalars["String"]>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["String"]>;
  _gt?: InputMaybe<Scalars["String"]>;
  _gte?: InputMaybe<Scalars["String"]>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars["String"]>;
  _in?: InputMaybe<Array<Scalars["String"]>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars["String"]>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars["String"]>;
  _lt?: InputMaybe<Scalars["String"]>;
  _lte?: InputMaybe<Scalars["String"]>;
  _neq?: InputMaybe<Scalars["String"]>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars["String"]>;
  _nin?: InputMaybe<Array<Scalars["String"]>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars["String"]>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars["String"]>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars["String"]>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars["String"]>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars["String"]>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars["String"]>;
};

export type Verify2FaInput = {
  otpCode: Scalars["String"];
};

export type Verify2FaOutput = {
  __typename?: "Verify2FAOutput";
  accessToken: Scalars["String"];
};

/** columns and relationships of "academic_department" */
export type Academic_Department = {
  __typename?: "academic_department";
  department_name: Scalars["String"];
  id: Scalars["Int"];
};

/** aggregated selection of "academic_department" */
export type Academic_Department_Aggregate = {
  __typename?: "academic_department_aggregate";
  aggregate?: Maybe<Academic_Department_Aggregate_Fields>;
  nodes: Array<Academic_Department>;
};

/** aggregate fields of "academic_department" */
export type Academic_Department_Aggregate_Fields = {
  __typename?: "academic_department_aggregate_fields";
  avg?: Maybe<Academic_Department_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Academic_Department_Max_Fields>;
  min?: Maybe<Academic_Department_Min_Fields>;
  stddev?: Maybe<Academic_Department_Stddev_Fields>;
  stddev_pop?: Maybe<Academic_Department_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Academic_Department_Stddev_Samp_Fields>;
  sum?: Maybe<Academic_Department_Sum_Fields>;
  var_pop?: Maybe<Academic_Department_Var_Pop_Fields>;
  var_samp?: Maybe<Academic_Department_Var_Samp_Fields>;
  variance?: Maybe<Academic_Department_Variance_Fields>;
};

/** aggregate fields of "academic_department" */
export type Academic_Department_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Academic_Department_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Academic_Department_Avg_Fields = {
  __typename?: "academic_department_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "academic_department". All fields are combined with a logical 'AND'. */
export type Academic_Department_Bool_Exp = {
  _and?: InputMaybe<Array<Academic_Department_Bool_Exp>>;
  _not?: InputMaybe<Academic_Department_Bool_Exp>;
  _or?: InputMaybe<Array<Academic_Department_Bool_Exp>>;
  department_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "academic_department" */
export enum Academic_Department_Constraint {
  /** unique or primary key constraint on columns "id" */
  AcademicDepartmentPkey = "academic_department_pkey",
}

/** input type for incrementing numeric columns in table "academic_department" */
export type Academic_Department_Inc_Input = {
  id?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "academic_department" */
export type Academic_Department_Insert_Input = {
  department_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Academic_Department_Max_Fields = {
  __typename?: "academic_department_max_fields";
  department_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** aggregate min on columns */
export type Academic_Department_Min_Fields = {
  __typename?: "academic_department_min_fields";
  department_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** response of any mutation on the table "academic_department" */
export type Academic_Department_Mutation_Response = {
  __typename?: "academic_department_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Academic_Department>;
};

/** input type for inserting object relation for remote table "academic_department" */
export type Academic_Department_Obj_Rel_Insert_Input = {
  data: Academic_Department_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Academic_Department_On_Conflict>;
};

/** on_conflict condition type for table "academic_department" */
export type Academic_Department_On_Conflict = {
  constraint: Academic_Department_Constraint;
  update_columns?: Array<Academic_Department_Update_Column>;
  where?: InputMaybe<Academic_Department_Bool_Exp>;
};

/** Ordering options when selecting data from "academic_department". */
export type Academic_Department_Order_By = {
  department_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: academic_department */
export type Academic_Department_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "academic_department" */
export enum Academic_Department_Select_Column {
  /** column name */
  DepartmentName = "department_name",
  /** column name */
  Id = "id",
}

/** input type for updating data in table "academic_department" */
export type Academic_Department_Set_Input = {
  department_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Academic_Department_Stddev_Fields = {
  __typename?: "academic_department_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Academic_Department_Stddev_Pop_Fields = {
  __typename?: "academic_department_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Academic_Department_Stddev_Samp_Fields = {
  __typename?: "academic_department_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "academic_department" */
export type Academic_Department_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Academic_Department_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Academic_Department_Stream_Cursor_Value_Input = {
  department_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Academic_Department_Sum_Fields = {
  __typename?: "academic_department_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "academic_department" */
export enum Academic_Department_Update_Column {
  /** column name */
  DepartmentName = "department_name",
  /** column name */
  Id = "id",
}

export type Academic_Department_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Academic_Department_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Academic_Department_Set_Input>;
  /** filter the rows which have to be updated */
  where: Academic_Department_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Academic_Department_Var_Pop_Fields = {
  __typename?: "academic_department_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Academic_Department_Var_Samp_Fields = {
  __typename?: "academic_department_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Academic_Department_Variance_Fields = {
  __typename?: "academic_department_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "address_type" */
export type Address_Type = {
  __typename?: "address_type";
  adress_type: Scalars["String"];
};

/** aggregated selection of "address_type" */
export type Address_Type_Aggregate = {
  __typename?: "address_type_aggregate";
  aggregate?: Maybe<Address_Type_Aggregate_Fields>;
  nodes: Array<Address_Type>;
};

/** aggregate fields of "address_type" */
export type Address_Type_Aggregate_Fields = {
  __typename?: "address_type_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Address_Type_Max_Fields>;
  min?: Maybe<Address_Type_Min_Fields>;
};

/** aggregate fields of "address_type" */
export type Address_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Address_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "address_type". All fields are combined with a logical 'AND'. */
export type Address_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Address_Type_Bool_Exp>>;
  _not?: InputMaybe<Address_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Address_Type_Bool_Exp>>;
  adress_type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "address_type" */
export enum Address_Type_Constraint {
  /** unique or primary key constraint on columns "adress_type" */
  AddressTypePkey = "address_type_pkey",
}

export enum Address_Type_Enum {
  Branch = "BRANCH",
  Main = "MAIN",
}

/** Boolean expression to compare columns of type "address_type_enum". All fields are combined with logical 'AND'. */
export type Address_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Address_Type_Enum>;
  _in?: InputMaybe<Array<Address_Type_Enum>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _neq?: InputMaybe<Address_Type_Enum>;
  _nin?: InputMaybe<Array<Address_Type_Enum>>;
};

/** input type for inserting data into table "address_type" */
export type Address_Type_Insert_Input = {
  adress_type?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Address_Type_Max_Fields = {
  __typename?: "address_type_max_fields";
  adress_type?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Address_Type_Min_Fields = {
  __typename?: "address_type_min_fields";
  adress_type?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "address_type" */
export type Address_Type_Mutation_Response = {
  __typename?: "address_type_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Address_Type>;
};

/** on_conflict condition type for table "address_type" */
export type Address_Type_On_Conflict = {
  constraint: Address_Type_Constraint;
  update_columns?: Array<Address_Type_Update_Column>;
  where?: InputMaybe<Address_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "address_type". */
export type Address_Type_Order_By = {
  adress_type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: address_type */
export type Address_Type_Pk_Columns_Input = {
  adress_type: Scalars["String"];
};

/** select columns of table "address_type" */
export enum Address_Type_Select_Column {
  /** column name */
  AdressType = "adress_type",
}

/** input type for updating data in table "address_type" */
export type Address_Type_Set_Input = {
  adress_type?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "address_type" */
export type Address_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Address_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Address_Type_Stream_Cursor_Value_Input = {
  adress_type?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "address_type" */
export enum Address_Type_Update_Column {
  /** column name */
  AdressType = "adress_type",
}

export type Address_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Address_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Address_Type_Bool_Exp;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["bigint"]>;
  _gt?: InputMaybe<Scalars["bigint"]>;
  _gte?: InputMaybe<Scalars["bigint"]>;
  _in?: InputMaybe<Array<Scalars["bigint"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["bigint"]>;
  _lte?: InputMaybe<Scalars["bigint"]>;
  _neq?: InputMaybe<Scalars["bigint"]>;
  _nin?: InputMaybe<Array<Scalars["bigint"]>>;
};

/** columns and relationships of "city" */
export type City = {
  __typename?: "city";
  city_name: Scalars["String"];
  /** An object relationship */
  country: Country;
  country_id: Scalars["Int"];
  id: Scalars["Int"];
};

/** aggregated selection of "city" */
export type City_Aggregate = {
  __typename?: "city_aggregate";
  aggregate?: Maybe<City_Aggregate_Fields>;
  nodes: Array<City>;
};

export type City_Aggregate_Bool_Exp = {
  count?: InputMaybe<City_Aggregate_Bool_Exp_Count>;
};

export type City_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<City_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<City_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "city" */
export type City_Aggregate_Fields = {
  __typename?: "city_aggregate_fields";
  avg?: Maybe<City_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<City_Max_Fields>;
  min?: Maybe<City_Min_Fields>;
  stddev?: Maybe<City_Stddev_Fields>;
  stddev_pop?: Maybe<City_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<City_Stddev_Samp_Fields>;
  sum?: Maybe<City_Sum_Fields>;
  var_pop?: Maybe<City_Var_Pop_Fields>;
  var_samp?: Maybe<City_Var_Samp_Fields>;
  variance?: Maybe<City_Variance_Fields>;
};

/** aggregate fields of "city" */
export type City_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<City_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "city" */
export type City_Aggregate_Order_By = {
  avg?: InputMaybe<City_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<City_Max_Order_By>;
  min?: InputMaybe<City_Min_Order_By>;
  stddev?: InputMaybe<City_Stddev_Order_By>;
  stddev_pop?: InputMaybe<City_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<City_Stddev_Samp_Order_By>;
  sum?: InputMaybe<City_Sum_Order_By>;
  var_pop?: InputMaybe<City_Var_Pop_Order_By>;
  var_samp?: InputMaybe<City_Var_Samp_Order_By>;
  variance?: InputMaybe<City_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "city" */
export type City_Arr_Rel_Insert_Input = {
  data: Array<City_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<City_On_Conflict>;
};

/** aggregate avg on columns */
export type City_Avg_Fields = {
  __typename?: "city_avg_fields";
  country_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "city" */
export type City_Avg_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "city". All fields are combined with a logical 'AND'. */
export type City_Bool_Exp = {
  _and?: InputMaybe<Array<City_Bool_Exp>>;
  _not?: InputMaybe<City_Bool_Exp>;
  _or?: InputMaybe<Array<City_Bool_Exp>>;
  city_name?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<Country_Bool_Exp>;
  country_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "city" */
export enum City_Constraint {
  /** unique or primary key constraint on columns "id" */
  CityPkey = "city_pkey",
}

/** input type for incrementing numeric columns in table "city" */
export type City_Inc_Input = {
  country_id?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "city" */
export type City_Insert_Input = {
  city_name?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Country_Obj_Rel_Insert_Input>;
  country_id?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type City_Max_Fields = {
  __typename?: "city_max_fields";
  city_name?: Maybe<Scalars["String"]>;
  country_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "city" */
export type City_Max_Order_By = {
  city_name?: InputMaybe<Order_By>;
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type City_Min_Fields = {
  __typename?: "city_min_fields";
  city_name?: Maybe<Scalars["String"]>;
  country_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "city" */
export type City_Min_Order_By = {
  city_name?: InputMaybe<Order_By>;
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "city" */
export type City_Mutation_Response = {
  __typename?: "city_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<City>;
};

/** input type for inserting object relation for remote table "city" */
export type City_Obj_Rel_Insert_Input = {
  data: City_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<City_On_Conflict>;
};

/** on_conflict condition type for table "city" */
export type City_On_Conflict = {
  constraint: City_Constraint;
  update_columns?: Array<City_Update_Column>;
  where?: InputMaybe<City_Bool_Exp>;
};

/** Ordering options when selecting data from "city". */
export type City_Order_By = {
  city_name?: InputMaybe<Order_By>;
  country?: InputMaybe<Country_Order_By>;
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: city */
export type City_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "city" */
export enum City_Select_Column {
  /** column name */
  CityName = "city_name",
  /** column name */
  CountryId = "country_id",
  /** column name */
  Id = "id",
}

/** input type for updating data in table "city" */
export type City_Set_Input = {
  city_name?: InputMaybe<Scalars["String"]>;
  country_id?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type City_Stddev_Fields = {
  __typename?: "city_stddev_fields";
  country_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "city" */
export type City_Stddev_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type City_Stddev_Pop_Fields = {
  __typename?: "city_stddev_pop_fields";
  country_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "city" */
export type City_Stddev_Pop_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type City_Stddev_Samp_Fields = {
  __typename?: "city_stddev_samp_fields";
  country_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "city" */
export type City_Stddev_Samp_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "city" */
export type City_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: City_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type City_Stream_Cursor_Value_Input = {
  city_name?: InputMaybe<Scalars["String"]>;
  country_id?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type City_Sum_Fields = {
  __typename?: "city_sum_fields";
  country_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "city" */
export type City_Sum_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "city" */
export enum City_Update_Column {
  /** column name */
  CityName = "city_name",
  /** column name */
  CountryId = "country_id",
  /** column name */
  Id = "id",
}

export type City_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<City_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<City_Set_Input>;
  /** filter the rows which have to be updated */
  where: City_Bool_Exp;
};

/** aggregate var_pop on columns */
export type City_Var_Pop_Fields = {
  __typename?: "city_var_pop_fields";
  country_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "city" */
export type City_Var_Pop_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type City_Var_Samp_Fields = {
  __typename?: "city_var_samp_fields";
  country_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "city" */
export type City_Var_Samp_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type City_Variance_Fields = {
  __typename?: "city_variance_fields";
  country_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "city" */
export type City_Variance_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "client" */
export type Client = {
  __typename?: "client";
  /** An array relationship */
  client_locations: Array<Client_Location>;
  /** An aggregate relationship */
  client_locations_aggregate: Client_Location_Aggregate;
  client_name: Scalars["String"];
  /** An array relationship */
  client_sectors: Array<Client_Sector>;
  /** An aggregate relationship */
  client_sectors_aggregate: Client_Sector_Aggregate;
  /** An array relationship */
  client_settings: Array<Client_Setting>;
  /** An aggregate relationship */
  client_settings_aggregate: Client_Setting_Aggregate;
  /** An object relationship */
  company: Company;
  company_id: Scalars["uuid"];
  company_size?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  employee_count?: Maybe<Scalars["Int"]>;
  has_multilocation: Scalars["Boolean"];
  id: Scalars["uuid"];
  is_default: Scalars["Boolean"];
  /** An array relationship */
  projects: Array<Project>;
  /** An aggregate relationship */
  projects_aggregate: Project_Aggregate;
  working_type?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "client" */
export type ClientClient_LocationsArgs = {
  distinct_on?: InputMaybe<Array<Client_Location_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Client_Location_Order_By>>;
  where?: InputMaybe<Client_Location_Bool_Exp>;
};

/** columns and relationships of "client" */
export type ClientClient_Locations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Location_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Client_Location_Order_By>>;
  where?: InputMaybe<Client_Location_Bool_Exp>;
};

/** columns and relationships of "client" */
export type ClientClient_SectorsArgs = {
  distinct_on?: InputMaybe<Array<Client_Sector_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Client_Sector_Order_By>>;
  where?: InputMaybe<Client_Sector_Bool_Exp>;
};

/** columns and relationships of "client" */
export type ClientClient_Sectors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Sector_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Client_Sector_Order_By>>;
  where?: InputMaybe<Client_Sector_Bool_Exp>;
};

/** columns and relationships of "client" */
export type ClientClient_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Client_Setting_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Client_Setting_Order_By>>;
  where?: InputMaybe<Client_Setting_Bool_Exp>;
};

/** columns and relationships of "client" */
export type ClientClient_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Setting_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Client_Setting_Order_By>>;
  where?: InputMaybe<Client_Setting_Bool_Exp>;
};

/** columns and relationships of "client" */
export type ClientProjectsArgs = {
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Project_Order_By>>;
  where?: InputMaybe<Project_Bool_Exp>;
};

/** columns and relationships of "client" */
export type ClientProjects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Project_Order_By>>;
  where?: InputMaybe<Project_Bool_Exp>;
};

/** aggregated selection of "client" */
export type Client_Aggregate = {
  __typename?: "client_aggregate";
  aggregate?: Maybe<Client_Aggregate_Fields>;
  nodes: Array<Client>;
};

export type Client_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Client_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Client_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Client_Aggregate_Bool_Exp_Count>;
};

export type Client_Aggregate_Bool_Exp_Bool_And = {
  arguments: Client_Select_Column_Client_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Client_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Client_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Client_Select_Column_Client_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Client_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Client_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Client_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Client_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "client" */
export type Client_Aggregate_Fields = {
  __typename?: "client_aggregate_fields";
  avg?: Maybe<Client_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Client_Max_Fields>;
  min?: Maybe<Client_Min_Fields>;
  stddev?: Maybe<Client_Stddev_Fields>;
  stddev_pop?: Maybe<Client_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Client_Stddev_Samp_Fields>;
  sum?: Maybe<Client_Sum_Fields>;
  var_pop?: Maybe<Client_Var_Pop_Fields>;
  var_samp?: Maybe<Client_Var_Samp_Fields>;
  variance?: Maybe<Client_Variance_Fields>;
};

/** aggregate fields of "client" */
export type Client_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Client_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "client" */
export type Client_Aggregate_Order_By = {
  avg?: InputMaybe<Client_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Client_Max_Order_By>;
  min?: InputMaybe<Client_Min_Order_By>;
  stddev?: InputMaybe<Client_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Client_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Client_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Client_Sum_Order_By>;
  var_pop?: InputMaybe<Client_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Client_Var_Samp_Order_By>;
  variance?: InputMaybe<Client_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "client" */
export type Client_Arr_Rel_Insert_Input = {
  data: Array<Client_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Client_On_Conflict>;
};

/** aggregate avg on columns */
export type Client_Avg_Fields = {
  __typename?: "client_avg_fields";
  employee_count?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "client" */
export type Client_Avg_Order_By = {
  employee_count?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "client". All fields are combined with a logical 'AND'. */
export type Client_Bool_Exp = {
  _and?: InputMaybe<Array<Client_Bool_Exp>>;
  _not?: InputMaybe<Client_Bool_Exp>;
  _or?: InputMaybe<Array<Client_Bool_Exp>>;
  client_locations?: InputMaybe<Client_Location_Bool_Exp>;
  client_locations_aggregate?: InputMaybe<Client_Location_Aggregate_Bool_Exp>;
  client_name?: InputMaybe<String_Comparison_Exp>;
  client_sectors?: InputMaybe<Client_Sector_Bool_Exp>;
  client_sectors_aggregate?: InputMaybe<Client_Sector_Aggregate_Bool_Exp>;
  client_settings?: InputMaybe<Client_Setting_Bool_Exp>;
  client_settings_aggregate?: InputMaybe<Client_Setting_Aggregate_Bool_Exp>;
  company?: InputMaybe<Company_Bool_Exp>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  company_size?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  employee_count?: InputMaybe<Int_Comparison_Exp>;
  has_multilocation?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_default?: InputMaybe<Boolean_Comparison_Exp>;
  projects?: InputMaybe<Project_Bool_Exp>;
  projects_aggregate?: InputMaybe<Project_Aggregate_Bool_Exp>;
  working_type?: InputMaybe<String_Comparison_Exp>;
};

/** columns and relationships of "client_candidate" */
export type Client_Candidate = {
  __typename?: "client_candidate";
  /** An object relationship */
  client: Client;
  /** An array relationship */
  client_candidate_projects: Array<Client_Candidate_Project>;
  /** An aggregate relationship */
  client_candidate_projects_aggregate: Client_Candidate_Project_Aggregate;
  client_id: Scalars["uuid"];
  cover_letter?: Maybe<Scalars["String"]>;
  created_at: Scalars["timestamptz"];
  cv_file_name?: Maybe<Scalars["String"]>;
  cv_file_uri?: Maybe<Scalars["String"]>;
  email_address: Scalars["String"];
  id: Scalars["uuid"];
  mobile_number: Scalars["String"];
  name: Scalars["String"];
  source: Scalars["String"];
  surname: Scalars["String"];
  updated_at: Scalars["timestamptz"];
};

/** columns and relationships of "client_candidate" */
export type Client_CandidateClient_Candidate_ProjectsArgs = {
  distinct_on?: InputMaybe<Array<Client_Candidate_Project_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Client_Candidate_Project_Order_By>>;
  where?: InputMaybe<Client_Candidate_Project_Bool_Exp>;
};

/** columns and relationships of "client_candidate" */
export type Client_CandidateClient_Candidate_Projects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Candidate_Project_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Client_Candidate_Project_Order_By>>;
  where?: InputMaybe<Client_Candidate_Project_Bool_Exp>;
};

/** aggregated selection of "client_candidate" */
export type Client_Candidate_Aggregate = {
  __typename?: "client_candidate_aggregate";
  aggregate?: Maybe<Client_Candidate_Aggregate_Fields>;
  nodes: Array<Client_Candidate>;
};

/** aggregate fields of "client_candidate" */
export type Client_Candidate_Aggregate_Fields = {
  __typename?: "client_candidate_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Client_Candidate_Max_Fields>;
  min?: Maybe<Client_Candidate_Min_Fields>;
};

/** aggregate fields of "client_candidate" */
export type Client_Candidate_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Client_Candidate_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "client_candidate". All fields are combined with a logical 'AND'. */
export type Client_Candidate_Bool_Exp = {
  _and?: InputMaybe<Array<Client_Candidate_Bool_Exp>>;
  _not?: InputMaybe<Client_Candidate_Bool_Exp>;
  _or?: InputMaybe<Array<Client_Candidate_Bool_Exp>>;
  client?: InputMaybe<Client_Bool_Exp>;
  client_candidate_projects?: InputMaybe<Client_Candidate_Project_Bool_Exp>;
  client_candidate_projects_aggregate?: InputMaybe<Client_Candidate_Project_Aggregate_Bool_Exp>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  cover_letter?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  cv_file_name?: InputMaybe<String_Comparison_Exp>;
  cv_file_uri?: InputMaybe<String_Comparison_Exp>;
  email_address?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  mobile_number?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  surname?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "client_candidate" */
export enum Client_Candidate_Constraint {
  /** unique or primary key constraint on columns "email_address", "client_id" */
  ClientCandidateEmailAddressClientIdKey = "client_candidate_email_address_client_id_key",
  /** unique or primary key constraint on columns "id" */
  ClientCandidatePkey = "client_candidate_pkey",
}

/** input type for inserting data into table "client_candidate" */
export type Client_Candidate_Insert_Input = {
  client?: InputMaybe<Client_Obj_Rel_Insert_Input>;
  client_candidate_projects?: InputMaybe<Client_Candidate_Project_Arr_Rel_Insert_Input>;
  client_id?: InputMaybe<Scalars["uuid"]>;
  cover_letter?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  cv_file_name?: InputMaybe<Scalars["String"]>;
  cv_file_uri?: InputMaybe<Scalars["String"]>;
  email_address?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  mobile_number?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<Scalars["String"]>;
  surname?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Client_Candidate_Max_Fields = {
  __typename?: "client_candidate_max_fields";
  client_id?: Maybe<Scalars["uuid"]>;
  cover_letter?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  cv_file_name?: Maybe<Scalars["String"]>;
  cv_file_uri?: Maybe<Scalars["String"]>;
  email_address?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  mobile_number?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  source?: Maybe<Scalars["String"]>;
  surname?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type Client_Candidate_Min_Fields = {
  __typename?: "client_candidate_min_fields";
  client_id?: Maybe<Scalars["uuid"]>;
  cover_letter?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  cv_file_name?: Maybe<Scalars["String"]>;
  cv_file_uri?: Maybe<Scalars["String"]>;
  email_address?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  mobile_number?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  source?: Maybe<Scalars["String"]>;
  surname?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "client_candidate" */
export type Client_Candidate_Mutation_Response = {
  __typename?: "client_candidate_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Client_Candidate>;
};

/** input type for inserting object relation for remote table "client_candidate" */
export type Client_Candidate_Obj_Rel_Insert_Input = {
  data: Client_Candidate_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Client_Candidate_On_Conflict>;
};

/** on_conflict condition type for table "client_candidate" */
export type Client_Candidate_On_Conflict = {
  constraint: Client_Candidate_Constraint;
  update_columns?: Array<Client_Candidate_Update_Column>;
  where?: InputMaybe<Client_Candidate_Bool_Exp>;
};

/** Ordering options when selecting data from "client_candidate". */
export type Client_Candidate_Order_By = {
  client?: InputMaybe<Client_Order_By>;
  client_candidate_projects_aggregate?: InputMaybe<Client_Candidate_Project_Aggregate_Order_By>;
  client_id?: InputMaybe<Order_By>;
  cover_letter?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  cv_file_name?: InputMaybe<Order_By>;
  cv_file_uri?: InputMaybe<Order_By>;
  email_address?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mobile_number?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  surname?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: client_candidate */
export type Client_Candidate_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** columns and relationships of "client_candidate_project" */
export type Client_Candidate_Project = {
  __typename?: "client_candidate_project";
  candidate_list_status?: Maybe<Client_Candidate_Project_Candidate_List_Status_Enum>;
  candidate_resolution?: Maybe<Client_Candidate_Project_Candidate_Resolution_Enum>;
  /** An object relationship */
  client_candidate: Client_Candidate;
  client_candidate_id: Scalars["uuid"];
  /** An array relationship */
  client_candidate_project_notes: Array<Client_Candidate_Project_Note>;
  /** An aggregate relationship */
  client_candidate_project_notes_aggregate: Client_Candidate_Project_Note_Aggregate;
  created_at: Scalars["timestamptz"];
  external_reference_id?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  /** An object relationship */
  partner?: Maybe<Partner>;
  partner_id?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  project: Project;
  project_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
  /** An array relationship */
  video_interviews: Array<Video_Interview>;
  /** An aggregate relationship */
  video_interviews_aggregate: Video_Interview_Aggregate;
};

/** columns and relationships of "client_candidate_project" */
export type Client_Candidate_ProjectClient_Candidate_Project_NotesArgs = {
  distinct_on?: InputMaybe<Array<Client_Candidate_Project_Note_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Client_Candidate_Project_Note_Order_By>>;
  where?: InputMaybe<Client_Candidate_Project_Note_Bool_Exp>;
};

/** columns and relationships of "client_candidate_project" */
export type Client_Candidate_ProjectClient_Candidate_Project_Notes_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Client_Candidate_Project_Note_Select_Column>
    >;
    limit?: InputMaybe<Scalars["Int"]>;
    offset?: InputMaybe<Scalars["Int"]>;
    order_by?: InputMaybe<Array<Client_Candidate_Project_Note_Order_By>>;
    where?: InputMaybe<Client_Candidate_Project_Note_Bool_Exp>;
  };

/** columns and relationships of "client_candidate_project" */
export type Client_Candidate_ProjectVideo_InterviewsArgs = {
  distinct_on?: InputMaybe<Array<Video_Interview_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Order_By>>;
  where?: InputMaybe<Video_Interview_Bool_Exp>;
};

/** columns and relationships of "client_candidate_project" */
export type Client_Candidate_ProjectVideo_Interviews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Video_Interview_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Order_By>>;
  where?: InputMaybe<Video_Interview_Bool_Exp>;
};

/** aggregated selection of "client_candidate_project" */
export type Client_Candidate_Project_Aggregate = {
  __typename?: "client_candidate_project_aggregate";
  aggregate?: Maybe<Client_Candidate_Project_Aggregate_Fields>;
  nodes: Array<Client_Candidate_Project>;
};

export type Client_Candidate_Project_Aggregate_Bool_Exp = {
  count?: InputMaybe<Client_Candidate_Project_Aggregate_Bool_Exp_Count>;
};

export type Client_Candidate_Project_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Client_Candidate_Project_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Client_Candidate_Project_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "client_candidate_project" */
export type Client_Candidate_Project_Aggregate_Fields = {
  __typename?: "client_candidate_project_aggregate_fields";
  avg?: Maybe<Client_Candidate_Project_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Client_Candidate_Project_Max_Fields>;
  min?: Maybe<Client_Candidate_Project_Min_Fields>;
  stddev?: Maybe<Client_Candidate_Project_Stddev_Fields>;
  stddev_pop?: Maybe<Client_Candidate_Project_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Client_Candidate_Project_Stddev_Samp_Fields>;
  sum?: Maybe<Client_Candidate_Project_Sum_Fields>;
  var_pop?: Maybe<Client_Candidate_Project_Var_Pop_Fields>;
  var_samp?: Maybe<Client_Candidate_Project_Var_Samp_Fields>;
  variance?: Maybe<Client_Candidate_Project_Variance_Fields>;
};

/** aggregate fields of "client_candidate_project" */
export type Client_Candidate_Project_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Client_Candidate_Project_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "client_candidate_project" */
export type Client_Candidate_Project_Aggregate_Order_By = {
  avg?: InputMaybe<Client_Candidate_Project_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Client_Candidate_Project_Max_Order_By>;
  min?: InputMaybe<Client_Candidate_Project_Min_Order_By>;
  stddev?: InputMaybe<Client_Candidate_Project_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Client_Candidate_Project_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Client_Candidate_Project_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Client_Candidate_Project_Sum_Order_By>;
  var_pop?: InputMaybe<Client_Candidate_Project_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Client_Candidate_Project_Var_Samp_Order_By>;
  variance?: InputMaybe<Client_Candidate_Project_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "client_candidate_project" */
export type Client_Candidate_Project_Arr_Rel_Insert_Input = {
  data: Array<Client_Candidate_Project_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Client_Candidate_Project_On_Conflict>;
};

/** aggregate avg on columns */
export type Client_Candidate_Project_Avg_Fields = {
  __typename?: "client_candidate_project_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "client_candidate_project" */
export type Client_Candidate_Project_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "client_candidate_project". All fields are combined with a logical 'AND'. */
export type Client_Candidate_Project_Bool_Exp = {
  _and?: InputMaybe<Array<Client_Candidate_Project_Bool_Exp>>;
  _not?: InputMaybe<Client_Candidate_Project_Bool_Exp>;
  _or?: InputMaybe<Array<Client_Candidate_Project_Bool_Exp>>;
  candidate_list_status?: InputMaybe<Client_Candidate_Project_Candidate_List_Status_Enum_Comparison_Exp>;
  candidate_resolution?: InputMaybe<Client_Candidate_Project_Candidate_Resolution_Enum_Comparison_Exp>;
  client_candidate?: InputMaybe<Client_Candidate_Bool_Exp>;
  client_candidate_id?: InputMaybe<Uuid_Comparison_Exp>;
  client_candidate_project_notes?: InputMaybe<Client_Candidate_Project_Note_Bool_Exp>;
  client_candidate_project_notes_aggregate?: InputMaybe<Client_Candidate_Project_Note_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  external_reference_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  partner?: InputMaybe<Partner_Bool_Exp>;
  partner_id?: InputMaybe<Uuid_Comparison_Exp>;
  project?: InputMaybe<Project_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  video_interviews?: InputMaybe<Video_Interview_Bool_Exp>;
  video_interviews_aggregate?: InputMaybe<Video_Interview_Aggregate_Bool_Exp>;
};

/** columns and relationships of "client_candidate_project_candidate_list_status" */
export type Client_Candidate_Project_Candidate_List_Status = {
  __typename?: "client_candidate_project_candidate_list_status";
  candidate_status: Scalars["String"];
};

/** aggregated selection of "client_candidate_project_candidate_list_status" */
export type Client_Candidate_Project_Candidate_List_Status_Aggregate = {
  __typename?: "client_candidate_project_candidate_list_status_aggregate";
  aggregate?: Maybe<Client_Candidate_Project_Candidate_List_Status_Aggregate_Fields>;
  nodes: Array<Client_Candidate_Project_Candidate_List_Status>;
};

/** aggregate fields of "client_candidate_project_candidate_list_status" */
export type Client_Candidate_Project_Candidate_List_Status_Aggregate_Fields = {
  __typename?: "client_candidate_project_candidate_list_status_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Client_Candidate_Project_Candidate_List_Status_Max_Fields>;
  min?: Maybe<Client_Candidate_Project_Candidate_List_Status_Min_Fields>;
};

/** aggregate fields of "client_candidate_project_candidate_list_status" */
export type Client_Candidate_Project_Candidate_List_Status_Aggregate_FieldsCountArgs =
  {
    columns?: InputMaybe<
      Array<Client_Candidate_Project_Candidate_List_Status_Select_Column>
    >;
    distinct?: InputMaybe<Scalars["Boolean"]>;
  };

/** Boolean expression to filter rows from the table "client_candidate_project_candidate_list_status". All fields are combined with a logical 'AND'. */
export type Client_Candidate_Project_Candidate_List_Status_Bool_Exp = {
  _and?: InputMaybe<
    Array<Client_Candidate_Project_Candidate_List_Status_Bool_Exp>
  >;
  _not?: InputMaybe<Client_Candidate_Project_Candidate_List_Status_Bool_Exp>;
  _or?: InputMaybe<
    Array<Client_Candidate_Project_Candidate_List_Status_Bool_Exp>
  >;
  candidate_status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "client_candidate_project_candidate_list_status" */
export enum Client_Candidate_Project_Candidate_List_Status_Constraint {
  /** unique or primary key constraint on columns "candidate_status" */
  ClientCandidateProjectCandidateStatusPkey = "client_candidate_project_candidate_status_pkey",
}

export enum Client_Candidate_Project_Candidate_List_Status_Enum {
  Applied = "APPLIED",
  InLonglist = "IN_LONGLIST",
  InShortlist = "IN_SHORTLIST",
  Pool = "POOL",
}

/** Boolean expression to compare columns of type "client_candidate_project_candidate_list_status_enum". All fields are combined with logical 'AND'. */
export type Client_Candidate_Project_Candidate_List_Status_Enum_Comparison_Exp =
  {
    _eq?: InputMaybe<Client_Candidate_Project_Candidate_List_Status_Enum>;
    _in?: InputMaybe<
      Array<Client_Candidate_Project_Candidate_List_Status_Enum>
    >;
    _is_null?: InputMaybe<Scalars["Boolean"]>;
    _neq?: InputMaybe<Client_Candidate_Project_Candidate_List_Status_Enum>;
    _nin?: InputMaybe<
      Array<Client_Candidate_Project_Candidate_List_Status_Enum>
    >;
  };

/** input type for inserting data into table "client_candidate_project_candidate_list_status" */
export type Client_Candidate_Project_Candidate_List_Status_Insert_Input = {
  candidate_status?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Client_Candidate_Project_Candidate_List_Status_Max_Fields = {
  __typename?: "client_candidate_project_candidate_list_status_max_fields";
  candidate_status?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Client_Candidate_Project_Candidate_List_Status_Min_Fields = {
  __typename?: "client_candidate_project_candidate_list_status_min_fields";
  candidate_status?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "client_candidate_project_candidate_list_status" */
export type Client_Candidate_Project_Candidate_List_Status_Mutation_Response = {
  __typename?: "client_candidate_project_candidate_list_status_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Client_Candidate_Project_Candidate_List_Status>;
};

/** on_conflict condition type for table "client_candidate_project_candidate_list_status" */
export type Client_Candidate_Project_Candidate_List_Status_On_Conflict = {
  constraint: Client_Candidate_Project_Candidate_List_Status_Constraint;
  update_columns?: Array<Client_Candidate_Project_Candidate_List_Status_Update_Column>;
  where?: InputMaybe<Client_Candidate_Project_Candidate_List_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "client_candidate_project_candidate_list_status". */
export type Client_Candidate_Project_Candidate_List_Status_Order_By = {
  candidate_status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: client_candidate_project_candidate_list_status */
export type Client_Candidate_Project_Candidate_List_Status_Pk_Columns_Input = {
  candidate_status: Scalars["String"];
};

/** select columns of table "client_candidate_project_candidate_list_status" */
export enum Client_Candidate_Project_Candidate_List_Status_Select_Column {
  /** column name */
  CandidateStatus = "candidate_status",
}

/** input type for updating data in table "client_candidate_project_candidate_list_status" */
export type Client_Candidate_Project_Candidate_List_Status_Set_Input = {
  candidate_status?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "client_candidate_project_candidate_list_status" */
export type Client_Candidate_Project_Candidate_List_Status_Stream_Cursor_Input =
  {
    /** Stream column input with initial value */
    initial_value: Client_Candidate_Project_Candidate_List_Status_Stream_Cursor_Value_Input;
    /** cursor ordering */
    ordering?: InputMaybe<Cursor_Ordering>;
  };

/** Initial value of the column from where the streaming should start */
export type Client_Candidate_Project_Candidate_List_Status_Stream_Cursor_Value_Input =
  {
    candidate_status?: InputMaybe<Scalars["String"]>;
  };

/** update columns of table "client_candidate_project_candidate_list_status" */
export enum Client_Candidate_Project_Candidate_List_Status_Update_Column {
  /** column name */
  CandidateStatus = "candidate_status",
}

export type Client_Candidate_Project_Candidate_List_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Client_Candidate_Project_Candidate_List_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Client_Candidate_Project_Candidate_List_Status_Bool_Exp;
};

/** columns and relationships of "client_candidate_project_candidate_resolution" */
export type Client_Candidate_Project_Candidate_Resolution = {
  __typename?: "client_candidate_project_candidate_resolution";
  resolution: Scalars["String"];
};

/** aggregated selection of "client_candidate_project_candidate_resolution" */
export type Client_Candidate_Project_Candidate_Resolution_Aggregate = {
  __typename?: "client_candidate_project_candidate_resolution_aggregate";
  aggregate?: Maybe<Client_Candidate_Project_Candidate_Resolution_Aggregate_Fields>;
  nodes: Array<Client_Candidate_Project_Candidate_Resolution>;
};

/** aggregate fields of "client_candidate_project_candidate_resolution" */
export type Client_Candidate_Project_Candidate_Resolution_Aggregate_Fields = {
  __typename?: "client_candidate_project_candidate_resolution_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Client_Candidate_Project_Candidate_Resolution_Max_Fields>;
  min?: Maybe<Client_Candidate_Project_Candidate_Resolution_Min_Fields>;
};

/** aggregate fields of "client_candidate_project_candidate_resolution" */
export type Client_Candidate_Project_Candidate_Resolution_Aggregate_FieldsCountArgs =
  {
    columns?: InputMaybe<
      Array<Client_Candidate_Project_Candidate_Resolution_Select_Column>
    >;
    distinct?: InputMaybe<Scalars["Boolean"]>;
  };

/** Boolean expression to filter rows from the table "client_candidate_project_candidate_resolution". All fields are combined with a logical 'AND'. */
export type Client_Candidate_Project_Candidate_Resolution_Bool_Exp = {
  _and?: InputMaybe<
    Array<Client_Candidate_Project_Candidate_Resolution_Bool_Exp>
  >;
  _not?: InputMaybe<Client_Candidate_Project_Candidate_Resolution_Bool_Exp>;
  _or?: InputMaybe<
    Array<Client_Candidate_Project_Candidate_Resolution_Bool_Exp>
  >;
  resolution?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "client_candidate_project_candidate_resolution" */
export enum Client_Candidate_Project_Candidate_Resolution_Constraint {
  /** unique or primary key constraint on columns "resolution" */
  ClientCandidateProjectCandidateResolutionPkey = "client_candidate_project_candidate_resolution_pkey",
}

export enum Client_Candidate_Project_Candidate_Resolution_Enum {
  CandidateWithdrew = "CANDIDATE_WITHDREW",
  DoesNotMeetCriteria = "DOES_NOT_MEET_CRITERIA",
  Hired = "HIRED",
  InterviewSuccessful = "INTERVIEW_SUCCESSFUL",
  InterviewUnsuccessful = "INTERVIEW_UNSUCCESSFUL",
  NoShow = "NO_SHOW",
  OfferDeclined = "OFFER_DECLINED",
  OfferMade = "OFFER_MADE",
}

/** Boolean expression to compare columns of type "client_candidate_project_candidate_resolution_enum". All fields are combined with logical 'AND'. */
export type Client_Candidate_Project_Candidate_Resolution_Enum_Comparison_Exp =
  {
    _eq?: InputMaybe<Client_Candidate_Project_Candidate_Resolution_Enum>;
    _in?: InputMaybe<Array<Client_Candidate_Project_Candidate_Resolution_Enum>>;
    _is_null?: InputMaybe<Scalars["Boolean"]>;
    _neq?: InputMaybe<Client_Candidate_Project_Candidate_Resolution_Enum>;
    _nin?: InputMaybe<
      Array<Client_Candidate_Project_Candidate_Resolution_Enum>
    >;
  };

/** input type for inserting data into table "client_candidate_project_candidate_resolution" */
export type Client_Candidate_Project_Candidate_Resolution_Insert_Input = {
  resolution?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Client_Candidate_Project_Candidate_Resolution_Max_Fields = {
  __typename?: "client_candidate_project_candidate_resolution_max_fields";
  resolution?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Client_Candidate_Project_Candidate_Resolution_Min_Fields = {
  __typename?: "client_candidate_project_candidate_resolution_min_fields";
  resolution?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "client_candidate_project_candidate_resolution" */
export type Client_Candidate_Project_Candidate_Resolution_Mutation_Response = {
  __typename?: "client_candidate_project_candidate_resolution_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Client_Candidate_Project_Candidate_Resolution>;
};

/** on_conflict condition type for table "client_candidate_project_candidate_resolution" */
export type Client_Candidate_Project_Candidate_Resolution_On_Conflict = {
  constraint: Client_Candidate_Project_Candidate_Resolution_Constraint;
  update_columns?: Array<Client_Candidate_Project_Candidate_Resolution_Update_Column>;
  where?: InputMaybe<Client_Candidate_Project_Candidate_Resolution_Bool_Exp>;
};

/** Ordering options when selecting data from "client_candidate_project_candidate_resolution". */
export type Client_Candidate_Project_Candidate_Resolution_Order_By = {
  resolution?: InputMaybe<Order_By>;
};

/** primary key columns input for table: client_candidate_project_candidate_resolution */
export type Client_Candidate_Project_Candidate_Resolution_Pk_Columns_Input = {
  resolution: Scalars["String"];
};

/** select columns of table "client_candidate_project_candidate_resolution" */
export enum Client_Candidate_Project_Candidate_Resolution_Select_Column {
  /** column name */
  Resolution = "resolution",
}

/** input type for updating data in table "client_candidate_project_candidate_resolution" */
export type Client_Candidate_Project_Candidate_Resolution_Set_Input = {
  resolution?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "client_candidate_project_candidate_resolution" */
export type Client_Candidate_Project_Candidate_Resolution_Stream_Cursor_Input =
  {
    /** Stream column input with initial value */
    initial_value: Client_Candidate_Project_Candidate_Resolution_Stream_Cursor_Value_Input;
    /** cursor ordering */
    ordering?: InputMaybe<Cursor_Ordering>;
  };

/** Initial value of the column from where the streaming should start */
export type Client_Candidate_Project_Candidate_Resolution_Stream_Cursor_Value_Input =
  {
    resolution?: InputMaybe<Scalars["String"]>;
  };

/** update columns of table "client_candidate_project_candidate_resolution" */
export enum Client_Candidate_Project_Candidate_Resolution_Update_Column {
  /** column name */
  Resolution = "resolution",
}

export type Client_Candidate_Project_Candidate_Resolution_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Client_Candidate_Project_Candidate_Resolution_Set_Input>;
  /** filter the rows which have to be updated */
  where: Client_Candidate_Project_Candidate_Resolution_Bool_Exp;
};

/** unique or primary key constraints on table "client_candidate_project" */
export enum Client_Candidate_Project_Constraint {
  /** unique or primary key constraint on columns "id" */
  ClientCandidateProjectPkey = "client_candidate_project_pkey",
  /** unique or primary key constraint on columns "project_id", "client_candidate_id" */
  ClientCandidateProjectProjectIdClientCandidateIdKey = "client_candidate_project_project_id_client_candidate_id_key",
}

/** input type for incrementing numeric columns in table "client_candidate_project" */
export type Client_Candidate_Project_Inc_Input = {
  id?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "client_candidate_project" */
export type Client_Candidate_Project_Insert_Input = {
  candidate_list_status?: InputMaybe<Client_Candidate_Project_Candidate_List_Status_Enum>;
  candidate_resolution?: InputMaybe<Client_Candidate_Project_Candidate_Resolution_Enum>;
  client_candidate?: InputMaybe<Client_Candidate_Obj_Rel_Insert_Input>;
  client_candidate_id?: InputMaybe<Scalars["uuid"]>;
  client_candidate_project_notes?: InputMaybe<Client_Candidate_Project_Note_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  external_reference_id?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  partner?: InputMaybe<Partner_Obj_Rel_Insert_Input>;
  partner_id?: InputMaybe<Scalars["uuid"]>;
  project?: InputMaybe<Project_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  video_interviews?: InputMaybe<Video_Interview_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Client_Candidate_Project_Max_Fields = {
  __typename?: "client_candidate_project_max_fields";
  client_candidate_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  external_reference_id?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  partner_id?: Maybe<Scalars["uuid"]>;
  project_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "client_candidate_project" */
export type Client_Candidate_Project_Max_Order_By = {
  client_candidate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  external_reference_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  partner_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Client_Candidate_Project_Min_Fields = {
  __typename?: "client_candidate_project_min_fields";
  client_candidate_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  external_reference_id?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  partner_id?: Maybe<Scalars["uuid"]>;
  project_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "client_candidate_project" */
export type Client_Candidate_Project_Min_Order_By = {
  client_candidate_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  external_reference_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  partner_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "client_candidate_project" */
export type Client_Candidate_Project_Mutation_Response = {
  __typename?: "client_candidate_project_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Client_Candidate_Project>;
};

/** columns and relationships of "client_candidate_project_note" */
export type Client_Candidate_Project_Note = {
  __typename?: "client_candidate_project_note";
  /** An object relationship */
  client_candidate_project: Client_Candidate_Project;
  client_candidate_project_id: Scalars["Int"];
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by: Scalars["uuid"];
  id: Scalars["Int"];
  note: Scalars["String"];
  updated_at?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  user: User;
};

/** aggregated selection of "client_candidate_project_note" */
export type Client_Candidate_Project_Note_Aggregate = {
  __typename?: "client_candidate_project_note_aggregate";
  aggregate?: Maybe<Client_Candidate_Project_Note_Aggregate_Fields>;
  nodes: Array<Client_Candidate_Project_Note>;
};

export type Client_Candidate_Project_Note_Aggregate_Bool_Exp = {
  count?: InputMaybe<Client_Candidate_Project_Note_Aggregate_Bool_Exp_Count>;
};

export type Client_Candidate_Project_Note_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Client_Candidate_Project_Note_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Client_Candidate_Project_Note_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "client_candidate_project_note" */
export type Client_Candidate_Project_Note_Aggregate_Fields = {
  __typename?: "client_candidate_project_note_aggregate_fields";
  avg?: Maybe<Client_Candidate_Project_Note_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Client_Candidate_Project_Note_Max_Fields>;
  min?: Maybe<Client_Candidate_Project_Note_Min_Fields>;
  stddev?: Maybe<Client_Candidate_Project_Note_Stddev_Fields>;
  stddev_pop?: Maybe<Client_Candidate_Project_Note_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Client_Candidate_Project_Note_Stddev_Samp_Fields>;
  sum?: Maybe<Client_Candidate_Project_Note_Sum_Fields>;
  var_pop?: Maybe<Client_Candidate_Project_Note_Var_Pop_Fields>;
  var_samp?: Maybe<Client_Candidate_Project_Note_Var_Samp_Fields>;
  variance?: Maybe<Client_Candidate_Project_Note_Variance_Fields>;
};

/** aggregate fields of "client_candidate_project_note" */
export type Client_Candidate_Project_Note_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Client_Candidate_Project_Note_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "client_candidate_project_note" */
export type Client_Candidate_Project_Note_Aggregate_Order_By = {
  avg?: InputMaybe<Client_Candidate_Project_Note_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Client_Candidate_Project_Note_Max_Order_By>;
  min?: InputMaybe<Client_Candidate_Project_Note_Min_Order_By>;
  stddev?: InputMaybe<Client_Candidate_Project_Note_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Client_Candidate_Project_Note_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Client_Candidate_Project_Note_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Client_Candidate_Project_Note_Sum_Order_By>;
  var_pop?: InputMaybe<Client_Candidate_Project_Note_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Client_Candidate_Project_Note_Var_Samp_Order_By>;
  variance?: InputMaybe<Client_Candidate_Project_Note_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "client_candidate_project_note" */
export type Client_Candidate_Project_Note_Arr_Rel_Insert_Input = {
  data: Array<Client_Candidate_Project_Note_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Client_Candidate_Project_Note_On_Conflict>;
};

/** aggregate avg on columns */
export type Client_Candidate_Project_Note_Avg_Fields = {
  __typename?: "client_candidate_project_note_avg_fields";
  client_candidate_project_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "client_candidate_project_note" */
export type Client_Candidate_Project_Note_Avg_Order_By = {
  client_candidate_project_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "client_candidate_project_note". All fields are combined with a logical 'AND'. */
export type Client_Candidate_Project_Note_Bool_Exp = {
  _and?: InputMaybe<Array<Client_Candidate_Project_Note_Bool_Exp>>;
  _not?: InputMaybe<Client_Candidate_Project_Note_Bool_Exp>;
  _or?: InputMaybe<Array<Client_Candidate_Project_Note_Bool_Exp>>;
  client_candidate_project?: InputMaybe<Client_Candidate_Project_Bool_Exp>;
  client_candidate_project_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  note?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
};

/** unique or primary key constraints on table "client_candidate_project_note" */
export enum Client_Candidate_Project_Note_Constraint {
  /** unique or primary key constraint on columns "id" */
  ClientCandidateProjectNotePkey = "client_candidate_project_note_pkey",
}

/** input type for incrementing numeric columns in table "client_candidate_project_note" */
export type Client_Candidate_Project_Note_Inc_Input = {
  client_candidate_project_id?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "client_candidate_project_note" */
export type Client_Candidate_Project_Note_Insert_Input = {
  client_candidate_project?: InputMaybe<Client_Candidate_Project_Obj_Rel_Insert_Input>;
  client_candidate_project_id?: InputMaybe<Scalars["Int"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  created_by?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["Int"]>;
  note?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Client_Candidate_Project_Note_Max_Fields = {
  __typename?: "client_candidate_project_note_max_fields";
  client_candidate_project_id?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["Int"]>;
  note?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "client_candidate_project_note" */
export type Client_Candidate_Project_Note_Max_Order_By = {
  client_candidate_project_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Client_Candidate_Project_Note_Min_Fields = {
  __typename?: "client_candidate_project_note_min_fields";
  client_candidate_project_id?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["Int"]>;
  note?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "client_candidate_project_note" */
export type Client_Candidate_Project_Note_Min_Order_By = {
  client_candidate_project_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "client_candidate_project_note" */
export type Client_Candidate_Project_Note_Mutation_Response = {
  __typename?: "client_candidate_project_note_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Client_Candidate_Project_Note>;
};

/** on_conflict condition type for table "client_candidate_project_note" */
export type Client_Candidate_Project_Note_On_Conflict = {
  constraint: Client_Candidate_Project_Note_Constraint;
  update_columns?: Array<Client_Candidate_Project_Note_Update_Column>;
  where?: InputMaybe<Client_Candidate_Project_Note_Bool_Exp>;
};

/** Ordering options when selecting data from "client_candidate_project_note". */
export type Client_Candidate_Project_Note_Order_By = {
  client_candidate_project?: InputMaybe<Client_Candidate_Project_Order_By>;
  client_candidate_project_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
};

/** primary key columns input for table: client_candidate_project_note */
export type Client_Candidate_Project_Note_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "client_candidate_project_note" */
export enum Client_Candidate_Project_Note_Select_Column {
  /** column name */
  ClientCandidateProjectId = "client_candidate_project_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CreatedBy = "created_by",
  /** column name */
  Id = "id",
  /** column name */
  Note = "note",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "client_candidate_project_note" */
export type Client_Candidate_Project_Note_Set_Input = {
  client_candidate_project_id?: InputMaybe<Scalars["Int"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  created_by?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["Int"]>;
  note?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Client_Candidate_Project_Note_Stddev_Fields = {
  __typename?: "client_candidate_project_note_stddev_fields";
  client_candidate_project_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "client_candidate_project_note" */
export type Client_Candidate_Project_Note_Stddev_Order_By = {
  client_candidate_project_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Client_Candidate_Project_Note_Stddev_Pop_Fields = {
  __typename?: "client_candidate_project_note_stddev_pop_fields";
  client_candidate_project_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "client_candidate_project_note" */
export type Client_Candidate_Project_Note_Stddev_Pop_Order_By = {
  client_candidate_project_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Client_Candidate_Project_Note_Stddev_Samp_Fields = {
  __typename?: "client_candidate_project_note_stddev_samp_fields";
  client_candidate_project_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "client_candidate_project_note" */
export type Client_Candidate_Project_Note_Stddev_Samp_Order_By = {
  client_candidate_project_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "client_candidate_project_note" */
export type Client_Candidate_Project_Note_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Client_Candidate_Project_Note_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Client_Candidate_Project_Note_Stream_Cursor_Value_Input = {
  client_candidate_project_id?: InputMaybe<Scalars["Int"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  created_by?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["Int"]>;
  note?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type Client_Candidate_Project_Note_Sum_Fields = {
  __typename?: "client_candidate_project_note_sum_fields";
  client_candidate_project_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "client_candidate_project_note" */
export type Client_Candidate_Project_Note_Sum_Order_By = {
  client_candidate_project_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "client_candidate_project_note" */
export enum Client_Candidate_Project_Note_Update_Column {
  /** column name */
  ClientCandidateProjectId = "client_candidate_project_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CreatedBy = "created_by",
  /** column name */
  Id = "id",
  /** column name */
  Note = "note",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Client_Candidate_Project_Note_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Client_Candidate_Project_Note_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Client_Candidate_Project_Note_Set_Input>;
  /** filter the rows which have to be updated */
  where: Client_Candidate_Project_Note_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Client_Candidate_Project_Note_Var_Pop_Fields = {
  __typename?: "client_candidate_project_note_var_pop_fields";
  client_candidate_project_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "client_candidate_project_note" */
export type Client_Candidate_Project_Note_Var_Pop_Order_By = {
  client_candidate_project_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Client_Candidate_Project_Note_Var_Samp_Fields = {
  __typename?: "client_candidate_project_note_var_samp_fields";
  client_candidate_project_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "client_candidate_project_note" */
export type Client_Candidate_Project_Note_Var_Samp_Order_By = {
  client_candidate_project_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Client_Candidate_Project_Note_Variance_Fields = {
  __typename?: "client_candidate_project_note_variance_fields";
  client_candidate_project_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "client_candidate_project_note" */
export type Client_Candidate_Project_Note_Variance_Order_By = {
  client_candidate_project_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "client_candidate_project" */
export type Client_Candidate_Project_Obj_Rel_Insert_Input = {
  data: Client_Candidate_Project_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Client_Candidate_Project_On_Conflict>;
};

/** on_conflict condition type for table "client_candidate_project" */
export type Client_Candidate_Project_On_Conflict = {
  constraint: Client_Candidate_Project_Constraint;
  update_columns?: Array<Client_Candidate_Project_Update_Column>;
  where?: InputMaybe<Client_Candidate_Project_Bool_Exp>;
};

/** Ordering options when selecting data from "client_candidate_project". */
export type Client_Candidate_Project_Order_By = {
  candidate_list_status?: InputMaybe<Order_By>;
  candidate_resolution?: InputMaybe<Order_By>;
  client_candidate?: InputMaybe<Client_Candidate_Order_By>;
  client_candidate_id?: InputMaybe<Order_By>;
  client_candidate_project_notes_aggregate?: InputMaybe<Client_Candidate_Project_Note_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  external_reference_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  partner?: InputMaybe<Partner_Order_By>;
  partner_id?: InputMaybe<Order_By>;
  project?: InputMaybe<Project_Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  video_interviews_aggregate?: InputMaybe<Video_Interview_Aggregate_Order_By>;
};

/** primary key columns input for table: client_candidate_project */
export type Client_Candidate_Project_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "client_candidate_project" */
export enum Client_Candidate_Project_Select_Column {
  /** column name */
  CandidateListStatus = "candidate_list_status",
  /** column name */
  CandidateResolution = "candidate_resolution",
  /** column name */
  ClientCandidateId = "client_candidate_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExternalReferenceId = "external_reference_id",
  /** column name */
  Id = "id",
  /** column name */
  PartnerId = "partner_id",
  /** column name */
  ProjectId = "project_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "client_candidate_project" */
export type Client_Candidate_Project_Set_Input = {
  candidate_list_status?: InputMaybe<Client_Candidate_Project_Candidate_List_Status_Enum>;
  candidate_resolution?: InputMaybe<Client_Candidate_Project_Candidate_Resolution_Enum>;
  client_candidate_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  external_reference_id?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  partner_id?: InputMaybe<Scalars["uuid"]>;
  project_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Client_Candidate_Project_Stddev_Fields = {
  __typename?: "client_candidate_project_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "client_candidate_project" */
export type Client_Candidate_Project_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Client_Candidate_Project_Stddev_Pop_Fields = {
  __typename?: "client_candidate_project_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "client_candidate_project" */
export type Client_Candidate_Project_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Client_Candidate_Project_Stddev_Samp_Fields = {
  __typename?: "client_candidate_project_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "client_candidate_project" */
export type Client_Candidate_Project_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "client_candidate_project" */
export type Client_Candidate_Project_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Client_Candidate_Project_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Client_Candidate_Project_Stream_Cursor_Value_Input = {
  candidate_list_status?: InputMaybe<Client_Candidate_Project_Candidate_List_Status_Enum>;
  candidate_resolution?: InputMaybe<Client_Candidate_Project_Candidate_Resolution_Enum>;
  client_candidate_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  external_reference_id?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  partner_id?: InputMaybe<Scalars["uuid"]>;
  project_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type Client_Candidate_Project_Sum_Fields = {
  __typename?: "client_candidate_project_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "client_candidate_project" */
export type Client_Candidate_Project_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "client_candidate_project" */
export enum Client_Candidate_Project_Update_Column {
  /** column name */
  CandidateListStatus = "candidate_list_status",
  /** column name */
  CandidateResolution = "candidate_resolution",
  /** column name */
  ClientCandidateId = "client_candidate_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExternalReferenceId = "external_reference_id",
  /** column name */
  Id = "id",
  /** column name */
  PartnerId = "partner_id",
  /** column name */
  ProjectId = "project_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Client_Candidate_Project_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Client_Candidate_Project_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Client_Candidate_Project_Set_Input>;
  /** filter the rows which have to be updated */
  where: Client_Candidate_Project_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Client_Candidate_Project_Var_Pop_Fields = {
  __typename?: "client_candidate_project_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "client_candidate_project" */
export type Client_Candidate_Project_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Client_Candidate_Project_Var_Samp_Fields = {
  __typename?: "client_candidate_project_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "client_candidate_project" */
export type Client_Candidate_Project_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Client_Candidate_Project_Variance_Fields = {
  __typename?: "client_candidate_project_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "client_candidate_project" */
export type Client_Candidate_Project_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** select columns of table "client_candidate" */
export enum Client_Candidate_Select_Column {
  /** column name */
  ClientId = "client_id",
  /** column name */
  CoverLetter = "cover_letter",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CvFileName = "cv_file_name",
  /** column name */
  CvFileUri = "cv_file_uri",
  /** column name */
  EmailAddress = "email_address",
  /** column name */
  Id = "id",
  /** column name */
  MobileNumber = "mobile_number",
  /** column name */
  Name = "name",
  /** column name */
  Source = "source",
  /** column name */
  Surname = "surname",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "client_candidate" */
export type Client_Candidate_Set_Input = {
  client_id?: InputMaybe<Scalars["uuid"]>;
  cover_letter?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  cv_file_name?: InputMaybe<Scalars["String"]>;
  cv_file_uri?: InputMaybe<Scalars["String"]>;
  email_address?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  mobile_number?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<Scalars["String"]>;
  surname?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "client_candidate" */
export type Client_Candidate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Client_Candidate_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Client_Candidate_Stream_Cursor_Value_Input = {
  client_id?: InputMaybe<Scalars["uuid"]>;
  cover_letter?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  cv_file_name?: InputMaybe<Scalars["String"]>;
  cv_file_uri?: InputMaybe<Scalars["String"]>;
  email_address?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  mobile_number?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<Scalars["String"]>;
  surname?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "client_candidate" */
export enum Client_Candidate_Update_Column {
  /** column name */
  ClientId = "client_id",
  /** column name */
  CoverLetter = "cover_letter",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CvFileName = "cv_file_name",
  /** column name */
  CvFileUri = "cv_file_uri",
  /** column name */
  EmailAddress = "email_address",
  /** column name */
  Id = "id",
  /** column name */
  MobileNumber = "mobile_number",
  /** column name */
  Name = "name",
  /** column name */
  Source = "source",
  /** column name */
  Surname = "surname",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Client_Candidate_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Client_Candidate_Set_Input>;
  /** filter the rows which have to be updated */
  where: Client_Candidate_Bool_Exp;
};

/** unique or primary key constraints on table "client" */
export enum Client_Constraint {
  /** unique or primary key constraint on columns "id" */
  ClientPkey = "client_pkey",
}

/** input type for incrementing numeric columns in table "client" */
export type Client_Inc_Input = {
  employee_count?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "client" */
export type Client_Insert_Input = {
  client_locations?: InputMaybe<Client_Location_Arr_Rel_Insert_Input>;
  client_name?: InputMaybe<Scalars["String"]>;
  client_sectors?: InputMaybe<Client_Sector_Arr_Rel_Insert_Input>;
  client_settings?: InputMaybe<Client_Setting_Arr_Rel_Insert_Input>;
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars["uuid"]>;
  company_size?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  employee_count?: InputMaybe<Scalars["Int"]>;
  has_multilocation?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_default?: InputMaybe<Scalars["Boolean"]>;
  projects?: InputMaybe<Project_Arr_Rel_Insert_Input>;
  working_type?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "client_location" */
export type Client_Location = {
  __typename?: "client_location";
  address?: Maybe<Scalars["String"]>;
  address_phone?: Maybe<Scalars["String"]>;
  address_type: Address_Type_Enum;
  /** An object relationship */
  city: City;
  city_id: Scalars["Int"];
  /** An object relationship */
  client: Client;
  client_id: Scalars["uuid"];
  contact_info?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
};

/** aggregated selection of "client_location" */
export type Client_Location_Aggregate = {
  __typename?: "client_location_aggregate";
  aggregate?: Maybe<Client_Location_Aggregate_Fields>;
  nodes: Array<Client_Location>;
};

export type Client_Location_Aggregate_Bool_Exp = {
  count?: InputMaybe<Client_Location_Aggregate_Bool_Exp_Count>;
};

export type Client_Location_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Client_Location_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Client_Location_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "client_location" */
export type Client_Location_Aggregate_Fields = {
  __typename?: "client_location_aggregate_fields";
  avg?: Maybe<Client_Location_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Client_Location_Max_Fields>;
  min?: Maybe<Client_Location_Min_Fields>;
  stddev?: Maybe<Client_Location_Stddev_Fields>;
  stddev_pop?: Maybe<Client_Location_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Client_Location_Stddev_Samp_Fields>;
  sum?: Maybe<Client_Location_Sum_Fields>;
  var_pop?: Maybe<Client_Location_Var_Pop_Fields>;
  var_samp?: Maybe<Client_Location_Var_Samp_Fields>;
  variance?: Maybe<Client_Location_Variance_Fields>;
};

/** aggregate fields of "client_location" */
export type Client_Location_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Client_Location_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "client_location" */
export type Client_Location_Aggregate_Order_By = {
  avg?: InputMaybe<Client_Location_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Client_Location_Max_Order_By>;
  min?: InputMaybe<Client_Location_Min_Order_By>;
  stddev?: InputMaybe<Client_Location_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Client_Location_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Client_Location_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Client_Location_Sum_Order_By>;
  var_pop?: InputMaybe<Client_Location_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Client_Location_Var_Samp_Order_By>;
  variance?: InputMaybe<Client_Location_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "client_location" */
export type Client_Location_Arr_Rel_Insert_Input = {
  data: Array<Client_Location_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Client_Location_On_Conflict>;
};

/** aggregate avg on columns */
export type Client_Location_Avg_Fields = {
  __typename?: "client_location_avg_fields";
  city_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "client_location" */
export type Client_Location_Avg_Order_By = {
  city_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "client_location". All fields are combined with a logical 'AND'. */
export type Client_Location_Bool_Exp = {
  _and?: InputMaybe<Array<Client_Location_Bool_Exp>>;
  _not?: InputMaybe<Client_Location_Bool_Exp>;
  _or?: InputMaybe<Array<Client_Location_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  address_phone?: InputMaybe<String_Comparison_Exp>;
  address_type?: InputMaybe<Address_Type_Enum_Comparison_Exp>;
  city?: InputMaybe<City_Bool_Exp>;
  city_id?: InputMaybe<Int_Comparison_Exp>;
  client?: InputMaybe<Client_Bool_Exp>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  contact_info?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "client_location" */
export enum Client_Location_Constraint {
  /** unique or primary key constraint on columns "id" */
  ClientLocationPkey = "client_location_pkey",
}

/** input type for incrementing numeric columns in table "client_location" */
export type Client_Location_Inc_Input = {
  city_id?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "client_location" */
export type Client_Location_Insert_Input = {
  address?: InputMaybe<Scalars["String"]>;
  address_phone?: InputMaybe<Scalars["String"]>;
  address_type?: InputMaybe<Address_Type_Enum>;
  city?: InputMaybe<City_Obj_Rel_Insert_Input>;
  city_id?: InputMaybe<Scalars["Int"]>;
  client?: InputMaybe<Client_Obj_Rel_Insert_Input>;
  client_id?: InputMaybe<Scalars["uuid"]>;
  contact_info?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Client_Location_Max_Fields = {
  __typename?: "client_location_max_fields";
  address?: Maybe<Scalars["String"]>;
  address_phone?: Maybe<Scalars["String"]>;
  city_id?: Maybe<Scalars["Int"]>;
  client_id?: Maybe<Scalars["uuid"]>;
  contact_info?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "client_location" */
export type Client_Location_Max_Order_By = {
  address?: InputMaybe<Order_By>;
  address_phone?: InputMaybe<Order_By>;
  city_id?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  contact_info?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Client_Location_Min_Fields = {
  __typename?: "client_location_min_fields";
  address?: Maybe<Scalars["String"]>;
  address_phone?: Maybe<Scalars["String"]>;
  city_id?: Maybe<Scalars["Int"]>;
  client_id?: Maybe<Scalars["uuid"]>;
  contact_info?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "client_location" */
export type Client_Location_Min_Order_By = {
  address?: InputMaybe<Order_By>;
  address_phone?: InputMaybe<Order_By>;
  city_id?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  contact_info?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "client_location" */
export type Client_Location_Mutation_Response = {
  __typename?: "client_location_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Client_Location>;
};

/** on_conflict condition type for table "client_location" */
export type Client_Location_On_Conflict = {
  constraint: Client_Location_Constraint;
  update_columns?: Array<Client_Location_Update_Column>;
  where?: InputMaybe<Client_Location_Bool_Exp>;
};

/** Ordering options when selecting data from "client_location". */
export type Client_Location_Order_By = {
  address?: InputMaybe<Order_By>;
  address_phone?: InputMaybe<Order_By>;
  address_type?: InputMaybe<Order_By>;
  city?: InputMaybe<City_Order_By>;
  city_id?: InputMaybe<Order_By>;
  client?: InputMaybe<Client_Order_By>;
  client_id?: InputMaybe<Order_By>;
  contact_info?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: client_location */
export type Client_Location_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "client_location" */
export enum Client_Location_Select_Column {
  /** column name */
  Address = "address",
  /** column name */
  AddressPhone = "address_phone",
  /** column name */
  AddressType = "address_type",
  /** column name */
  CityId = "city_id",
  /** column name */
  ClientId = "client_id",
  /** column name */
  ContactInfo = "contact_info",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
}

/** input type for updating data in table "client_location" */
export type Client_Location_Set_Input = {
  address?: InputMaybe<Scalars["String"]>;
  address_phone?: InputMaybe<Scalars["String"]>;
  address_type?: InputMaybe<Address_Type_Enum>;
  city_id?: InputMaybe<Scalars["Int"]>;
  client_id?: InputMaybe<Scalars["uuid"]>;
  contact_info?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type Client_Location_Stddev_Fields = {
  __typename?: "client_location_stddev_fields";
  city_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "client_location" */
export type Client_Location_Stddev_Order_By = {
  city_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Client_Location_Stddev_Pop_Fields = {
  __typename?: "client_location_stddev_pop_fields";
  city_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "client_location" */
export type Client_Location_Stddev_Pop_Order_By = {
  city_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Client_Location_Stddev_Samp_Fields = {
  __typename?: "client_location_stddev_samp_fields";
  city_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "client_location" */
export type Client_Location_Stddev_Samp_Order_By = {
  city_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "client_location" */
export type Client_Location_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Client_Location_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Client_Location_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars["String"]>;
  address_phone?: InputMaybe<Scalars["String"]>;
  address_type?: InputMaybe<Address_Type_Enum>;
  city_id?: InputMaybe<Scalars["Int"]>;
  client_id?: InputMaybe<Scalars["uuid"]>;
  contact_info?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate sum on columns */
export type Client_Location_Sum_Fields = {
  __typename?: "client_location_sum_fields";
  city_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "client_location" */
export type Client_Location_Sum_Order_By = {
  city_id?: InputMaybe<Order_By>;
};

/** update columns of table "client_location" */
export enum Client_Location_Update_Column {
  /** column name */
  Address = "address",
  /** column name */
  AddressPhone = "address_phone",
  /** column name */
  AddressType = "address_type",
  /** column name */
  CityId = "city_id",
  /** column name */
  ClientId = "client_id",
  /** column name */
  ContactInfo = "contact_info",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
}

export type Client_Location_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Client_Location_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Client_Location_Set_Input>;
  /** filter the rows which have to be updated */
  where: Client_Location_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Client_Location_Var_Pop_Fields = {
  __typename?: "client_location_var_pop_fields";
  city_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "client_location" */
export type Client_Location_Var_Pop_Order_By = {
  city_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Client_Location_Var_Samp_Fields = {
  __typename?: "client_location_var_samp_fields";
  city_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "client_location" */
export type Client_Location_Var_Samp_Order_By = {
  city_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Client_Location_Variance_Fields = {
  __typename?: "client_location_variance_fields";
  city_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "client_location" */
export type Client_Location_Variance_Order_By = {
  city_id?: InputMaybe<Order_By>;
};

/** aggregate max on columns */
export type Client_Max_Fields = {
  __typename?: "client_max_fields";
  client_name?: Maybe<Scalars["String"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  company_size?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  employee_count?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  working_type?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "client" */
export type Client_Max_Order_By = {
  client_name?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  company_size?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  employee_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  working_type?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Client_Min_Fields = {
  __typename?: "client_min_fields";
  client_name?: Maybe<Scalars["String"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  company_size?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  employee_count?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  working_type?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "client" */
export type Client_Min_Order_By = {
  client_name?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  company_size?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  employee_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  working_type?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "client" */
export type Client_Mutation_Response = {
  __typename?: "client_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Client>;
};

/** input type for inserting object relation for remote table "client" */
export type Client_Obj_Rel_Insert_Input = {
  data: Client_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Client_On_Conflict>;
};

/** on_conflict condition type for table "client" */
export type Client_On_Conflict = {
  constraint: Client_Constraint;
  update_columns?: Array<Client_Update_Column>;
  where?: InputMaybe<Client_Bool_Exp>;
};

/** Ordering options when selecting data from "client". */
export type Client_Order_By = {
  client_locations_aggregate?: InputMaybe<Client_Location_Aggregate_Order_By>;
  client_name?: InputMaybe<Order_By>;
  client_sectors_aggregate?: InputMaybe<Client_Sector_Aggregate_Order_By>;
  client_settings_aggregate?: InputMaybe<Client_Setting_Aggregate_Order_By>;
  company?: InputMaybe<Company_Order_By>;
  company_id?: InputMaybe<Order_By>;
  company_size?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  employee_count?: InputMaybe<Order_By>;
  has_multilocation?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_default?: InputMaybe<Order_By>;
  projects_aggregate?: InputMaybe<Project_Aggregate_Order_By>;
  working_type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: client */
export type Client_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** columns and relationships of "client_sector" */
export type Client_Sector = {
  __typename?: "client_sector";
  /** An object relationship */
  client: Client;
  client_id: Scalars["uuid"];
  id: Scalars["Int"];
  /** An object relationship */
  sector: Sector;
  sector_id: Scalars["Int"];
};

/** aggregated selection of "client_sector" */
export type Client_Sector_Aggregate = {
  __typename?: "client_sector_aggregate";
  aggregate?: Maybe<Client_Sector_Aggregate_Fields>;
  nodes: Array<Client_Sector>;
};

export type Client_Sector_Aggregate_Bool_Exp = {
  count?: InputMaybe<Client_Sector_Aggregate_Bool_Exp_Count>;
};

export type Client_Sector_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Client_Sector_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Client_Sector_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "client_sector" */
export type Client_Sector_Aggregate_Fields = {
  __typename?: "client_sector_aggregate_fields";
  avg?: Maybe<Client_Sector_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Client_Sector_Max_Fields>;
  min?: Maybe<Client_Sector_Min_Fields>;
  stddev?: Maybe<Client_Sector_Stddev_Fields>;
  stddev_pop?: Maybe<Client_Sector_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Client_Sector_Stddev_Samp_Fields>;
  sum?: Maybe<Client_Sector_Sum_Fields>;
  var_pop?: Maybe<Client_Sector_Var_Pop_Fields>;
  var_samp?: Maybe<Client_Sector_Var_Samp_Fields>;
  variance?: Maybe<Client_Sector_Variance_Fields>;
};

/** aggregate fields of "client_sector" */
export type Client_Sector_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Client_Sector_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "client_sector" */
export type Client_Sector_Aggregate_Order_By = {
  avg?: InputMaybe<Client_Sector_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Client_Sector_Max_Order_By>;
  min?: InputMaybe<Client_Sector_Min_Order_By>;
  stddev?: InputMaybe<Client_Sector_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Client_Sector_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Client_Sector_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Client_Sector_Sum_Order_By>;
  var_pop?: InputMaybe<Client_Sector_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Client_Sector_Var_Samp_Order_By>;
  variance?: InputMaybe<Client_Sector_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "client_sector" */
export type Client_Sector_Arr_Rel_Insert_Input = {
  data: Array<Client_Sector_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Client_Sector_On_Conflict>;
};

/** aggregate avg on columns */
export type Client_Sector_Avg_Fields = {
  __typename?: "client_sector_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  sector_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "client_sector" */
export type Client_Sector_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  sector_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "client_sector". All fields are combined with a logical 'AND'. */
export type Client_Sector_Bool_Exp = {
  _and?: InputMaybe<Array<Client_Sector_Bool_Exp>>;
  _not?: InputMaybe<Client_Sector_Bool_Exp>;
  _or?: InputMaybe<Array<Client_Sector_Bool_Exp>>;
  client?: InputMaybe<Client_Bool_Exp>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  sector?: InputMaybe<Sector_Bool_Exp>;
  sector_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "client_sector" */
export enum Client_Sector_Constraint {
  /** unique or primary key constraint on columns "id" */
  ClientSectorPkey = "client_sector_pkey",
}

/** input type for incrementing numeric columns in table "client_sector" */
export type Client_Sector_Inc_Input = {
  sector_id?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "client_sector" */
export type Client_Sector_Insert_Input = {
  client?: InputMaybe<Client_Obj_Rel_Insert_Input>;
  client_id?: InputMaybe<Scalars["uuid"]>;
  sector?: InputMaybe<Sector_Obj_Rel_Insert_Input>;
  sector_id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Client_Sector_Max_Fields = {
  __typename?: "client_sector_max_fields";
  client_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["Int"]>;
  sector_id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "client_sector" */
export type Client_Sector_Max_Order_By = {
  client_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sector_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Client_Sector_Min_Fields = {
  __typename?: "client_sector_min_fields";
  client_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["Int"]>;
  sector_id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "client_sector" */
export type Client_Sector_Min_Order_By = {
  client_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sector_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "client_sector" */
export type Client_Sector_Mutation_Response = {
  __typename?: "client_sector_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Client_Sector>;
};

/** on_conflict condition type for table "client_sector" */
export type Client_Sector_On_Conflict = {
  constraint: Client_Sector_Constraint;
  update_columns?: Array<Client_Sector_Update_Column>;
  where?: InputMaybe<Client_Sector_Bool_Exp>;
};

/** Ordering options when selecting data from "client_sector". */
export type Client_Sector_Order_By = {
  client?: InputMaybe<Client_Order_By>;
  client_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sector?: InputMaybe<Sector_Order_By>;
  sector_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: client_sector */
export type Client_Sector_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "client_sector" */
export enum Client_Sector_Select_Column {
  /** column name */
  ClientId = "client_id",
  /** column name */
  Id = "id",
  /** column name */
  SectorId = "sector_id",
}

/** input type for updating data in table "client_sector" */
export type Client_Sector_Set_Input = {
  client_id?: InputMaybe<Scalars["uuid"]>;
  sector_id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Client_Sector_Stddev_Fields = {
  __typename?: "client_sector_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  sector_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "client_sector" */
export type Client_Sector_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  sector_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Client_Sector_Stddev_Pop_Fields = {
  __typename?: "client_sector_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  sector_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "client_sector" */
export type Client_Sector_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  sector_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Client_Sector_Stddev_Samp_Fields = {
  __typename?: "client_sector_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  sector_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "client_sector" */
export type Client_Sector_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  sector_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "client_sector" */
export type Client_Sector_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Client_Sector_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Client_Sector_Stream_Cursor_Value_Input = {
  client_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["Int"]>;
  sector_id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Client_Sector_Sum_Fields = {
  __typename?: "client_sector_sum_fields";
  id?: Maybe<Scalars["Int"]>;
  sector_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "client_sector" */
export type Client_Sector_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  sector_id?: InputMaybe<Order_By>;
};

/** update columns of table "client_sector" */
export enum Client_Sector_Update_Column {
  /** column name */
  ClientId = "client_id",
  /** column name */
  SectorId = "sector_id",
}

export type Client_Sector_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Client_Sector_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Client_Sector_Set_Input>;
  /** filter the rows which have to be updated */
  where: Client_Sector_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Client_Sector_Var_Pop_Fields = {
  __typename?: "client_sector_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  sector_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "client_sector" */
export type Client_Sector_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  sector_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Client_Sector_Var_Samp_Fields = {
  __typename?: "client_sector_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  sector_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "client_sector" */
export type Client_Sector_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  sector_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Client_Sector_Variance_Fields = {
  __typename?: "client_sector_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  sector_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "client_sector" */
export type Client_Sector_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  sector_id?: InputMaybe<Order_By>;
};

/** select columns of table "client" */
export enum Client_Select_Column {
  /** column name */
  ClientName = "client_name",
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CompanySize = "company_size",
  /** column name */
  Description = "description",
  /** column name */
  EmployeeCount = "employee_count",
  /** column name */
  HasMultilocation = "has_multilocation",
  /** column name */
  Id = "id",
  /** column name */
  IsDefault = "is_default",
  /** column name */
  WorkingType = "working_type",
}

/** select "client_aggregate_bool_exp_bool_and_arguments_columns" columns of table "client" */
export enum Client_Select_Column_Client_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  HasMultilocation = "has_multilocation",
  /** column name */
  IsDefault = "is_default",
}

/** select "client_aggregate_bool_exp_bool_or_arguments_columns" columns of table "client" */
export enum Client_Select_Column_Client_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  HasMultilocation = "has_multilocation",
  /** column name */
  IsDefault = "is_default",
}

/** input type for updating data in table "client" */
export type Client_Set_Input = {
  client_name?: InputMaybe<Scalars["String"]>;
  company_id?: InputMaybe<Scalars["uuid"]>;
  company_size?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  employee_count?: InputMaybe<Scalars["Int"]>;
  has_multilocation?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_default?: InputMaybe<Scalars["Boolean"]>;
  working_type?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "client_setting" */
export type Client_Setting = {
  __typename?: "client_setting";
  client_id: Scalars["uuid"];
  created_at: Scalars["timestamptz"];
  id: Scalars["Int"];
  setting_key: Client_Setting_Key_Enum;
  setting_value?: Maybe<Scalars["String"]>;
  updated_at: Scalars["timestamptz"];
};

/** aggregated selection of "client_setting" */
export type Client_Setting_Aggregate = {
  __typename?: "client_setting_aggregate";
  aggregate?: Maybe<Client_Setting_Aggregate_Fields>;
  nodes: Array<Client_Setting>;
};

export type Client_Setting_Aggregate_Bool_Exp = {
  count?: InputMaybe<Client_Setting_Aggregate_Bool_Exp_Count>;
};

export type Client_Setting_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Client_Setting_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Client_Setting_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "client_setting" */
export type Client_Setting_Aggregate_Fields = {
  __typename?: "client_setting_aggregate_fields";
  avg?: Maybe<Client_Setting_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Client_Setting_Max_Fields>;
  min?: Maybe<Client_Setting_Min_Fields>;
  stddev?: Maybe<Client_Setting_Stddev_Fields>;
  stddev_pop?: Maybe<Client_Setting_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Client_Setting_Stddev_Samp_Fields>;
  sum?: Maybe<Client_Setting_Sum_Fields>;
  var_pop?: Maybe<Client_Setting_Var_Pop_Fields>;
  var_samp?: Maybe<Client_Setting_Var_Samp_Fields>;
  variance?: Maybe<Client_Setting_Variance_Fields>;
};

/** aggregate fields of "client_setting" */
export type Client_Setting_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Client_Setting_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "client_setting" */
export type Client_Setting_Aggregate_Order_By = {
  avg?: InputMaybe<Client_Setting_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Client_Setting_Max_Order_By>;
  min?: InputMaybe<Client_Setting_Min_Order_By>;
  stddev?: InputMaybe<Client_Setting_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Client_Setting_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Client_Setting_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Client_Setting_Sum_Order_By>;
  var_pop?: InputMaybe<Client_Setting_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Client_Setting_Var_Samp_Order_By>;
  variance?: InputMaybe<Client_Setting_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "client_setting" */
export type Client_Setting_Arr_Rel_Insert_Input = {
  data: Array<Client_Setting_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Client_Setting_On_Conflict>;
};

/** aggregate avg on columns */
export type Client_Setting_Avg_Fields = {
  __typename?: "client_setting_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "client_setting" */
export type Client_Setting_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "client_setting". All fields are combined with a logical 'AND'. */
export type Client_Setting_Bool_Exp = {
  _and?: InputMaybe<Array<Client_Setting_Bool_Exp>>;
  _not?: InputMaybe<Client_Setting_Bool_Exp>;
  _or?: InputMaybe<Array<Client_Setting_Bool_Exp>>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  setting_key?: InputMaybe<Client_Setting_Key_Enum_Comparison_Exp>;
  setting_value?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "client_setting" */
export enum Client_Setting_Constraint {
  /** unique or primary key constraint on columns "id" */
  ClientSettingPkey = "client_setting_pkey",
}

/** input type for incrementing numeric columns in table "client_setting" */
export type Client_Setting_Inc_Input = {
  id?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "client_setting" */
export type Client_Setting_Insert_Input = {
  client_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["Int"]>;
  setting_key?: InputMaybe<Client_Setting_Key_Enum>;
  setting_value?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** columns and relationships of "client_setting_key" */
export type Client_Setting_Key = {
  __typename?: "client_setting_key";
  key: Scalars["String"];
};

/** aggregated selection of "client_setting_key" */
export type Client_Setting_Key_Aggregate = {
  __typename?: "client_setting_key_aggregate";
  aggregate?: Maybe<Client_Setting_Key_Aggregate_Fields>;
  nodes: Array<Client_Setting_Key>;
};

/** aggregate fields of "client_setting_key" */
export type Client_Setting_Key_Aggregate_Fields = {
  __typename?: "client_setting_key_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Client_Setting_Key_Max_Fields>;
  min?: Maybe<Client_Setting_Key_Min_Fields>;
};

/** aggregate fields of "client_setting_key" */
export type Client_Setting_Key_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Client_Setting_Key_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "client_setting_key". All fields are combined with a logical 'AND'. */
export type Client_Setting_Key_Bool_Exp = {
  _and?: InputMaybe<Array<Client_Setting_Key_Bool_Exp>>;
  _not?: InputMaybe<Client_Setting_Key_Bool_Exp>;
  _or?: InputMaybe<Array<Client_Setting_Key_Bool_Exp>>;
  key?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "client_setting_key" */
export enum Client_Setting_Key_Constraint {
  /** unique or primary key constraint on columns "key" */
  ClientSettingKeyPkey = "client_setting_key_pkey",
}

export enum Client_Setting_Key_Enum {
  AllowHrReview = "ALLOW_HR_REVIEW",
  ClarificationCheckboxText = "CLARIFICATION_CHECKBOX_TEXT",
}

/** Boolean expression to compare columns of type "client_setting_key_enum". All fields are combined with logical 'AND'. */
export type Client_Setting_Key_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Client_Setting_Key_Enum>;
  _in?: InputMaybe<Array<Client_Setting_Key_Enum>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _neq?: InputMaybe<Client_Setting_Key_Enum>;
  _nin?: InputMaybe<Array<Client_Setting_Key_Enum>>;
};

/** input type for inserting data into table "client_setting_key" */
export type Client_Setting_Key_Insert_Input = {
  key?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Client_Setting_Key_Max_Fields = {
  __typename?: "client_setting_key_max_fields";
  key?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Client_Setting_Key_Min_Fields = {
  __typename?: "client_setting_key_min_fields";
  key?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "client_setting_key" */
export type Client_Setting_Key_Mutation_Response = {
  __typename?: "client_setting_key_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Client_Setting_Key>;
};

/** on_conflict condition type for table "client_setting_key" */
export type Client_Setting_Key_On_Conflict = {
  constraint: Client_Setting_Key_Constraint;
  update_columns?: Array<Client_Setting_Key_Update_Column>;
  where?: InputMaybe<Client_Setting_Key_Bool_Exp>;
};

/** Ordering options when selecting data from "client_setting_key". */
export type Client_Setting_Key_Order_By = {
  key?: InputMaybe<Order_By>;
};

/** primary key columns input for table: client_setting_key */
export type Client_Setting_Key_Pk_Columns_Input = {
  key: Scalars["String"];
};

/** select columns of table "client_setting_key" */
export enum Client_Setting_Key_Select_Column {
  /** column name */
  Key = "key",
}

/** input type for updating data in table "client_setting_key" */
export type Client_Setting_Key_Set_Input = {
  key?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "client_setting_key" */
export type Client_Setting_Key_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Client_Setting_Key_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Client_Setting_Key_Stream_Cursor_Value_Input = {
  key?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "client_setting_key" */
export enum Client_Setting_Key_Update_Column {
  /** column name */
  Key = "key",
}

export type Client_Setting_Key_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Client_Setting_Key_Set_Input>;
  /** filter the rows which have to be updated */
  where: Client_Setting_Key_Bool_Exp;
};

/** aggregate max on columns */
export type Client_Setting_Max_Fields = {
  __typename?: "client_setting_max_fields";
  client_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  setting_value?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "client_setting" */
export type Client_Setting_Max_Order_By = {
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  setting_value?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Client_Setting_Min_Fields = {
  __typename?: "client_setting_min_fields";
  client_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  setting_value?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "client_setting" */
export type Client_Setting_Min_Order_By = {
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  setting_value?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "client_setting" */
export type Client_Setting_Mutation_Response = {
  __typename?: "client_setting_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Client_Setting>;
};

/** on_conflict condition type for table "client_setting" */
export type Client_Setting_On_Conflict = {
  constraint: Client_Setting_Constraint;
  update_columns?: Array<Client_Setting_Update_Column>;
  where?: InputMaybe<Client_Setting_Bool_Exp>;
};

/** Ordering options when selecting data from "client_setting". */
export type Client_Setting_Order_By = {
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  setting_key?: InputMaybe<Order_By>;
  setting_value?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: client_setting */
export type Client_Setting_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "client_setting" */
export enum Client_Setting_Select_Column {
  /** column name */
  ClientId = "client_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  SettingKey = "setting_key",
  /** column name */
  SettingValue = "setting_value",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "client_setting" */
export type Client_Setting_Set_Input = {
  client_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["Int"]>;
  setting_key?: InputMaybe<Client_Setting_Key_Enum>;
  setting_value?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Client_Setting_Stddev_Fields = {
  __typename?: "client_setting_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "client_setting" */
export type Client_Setting_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Client_Setting_Stddev_Pop_Fields = {
  __typename?: "client_setting_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "client_setting" */
export type Client_Setting_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Client_Setting_Stddev_Samp_Fields = {
  __typename?: "client_setting_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "client_setting" */
export type Client_Setting_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "client_setting" */
export type Client_Setting_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Client_Setting_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Client_Setting_Stream_Cursor_Value_Input = {
  client_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["Int"]>;
  setting_key?: InputMaybe<Client_Setting_Key_Enum>;
  setting_value?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type Client_Setting_Sum_Fields = {
  __typename?: "client_setting_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "client_setting" */
export type Client_Setting_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "client_setting" */
export enum Client_Setting_Update_Column {
  /** column name */
  ClientId = "client_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  SettingKey = "setting_key",
  /** column name */
  SettingValue = "setting_value",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Client_Setting_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Client_Setting_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Client_Setting_Set_Input>;
  /** filter the rows which have to be updated */
  where: Client_Setting_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Client_Setting_Var_Pop_Fields = {
  __typename?: "client_setting_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "client_setting" */
export type Client_Setting_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Client_Setting_Var_Samp_Fields = {
  __typename?: "client_setting_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "client_setting" */
export type Client_Setting_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Client_Setting_Variance_Fields = {
  __typename?: "client_setting_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "client_setting" */
export type Client_Setting_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev on columns */
export type Client_Stddev_Fields = {
  __typename?: "client_stddev_fields";
  employee_count?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "client" */
export type Client_Stddev_Order_By = {
  employee_count?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Client_Stddev_Pop_Fields = {
  __typename?: "client_stddev_pop_fields";
  employee_count?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "client" */
export type Client_Stddev_Pop_Order_By = {
  employee_count?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Client_Stddev_Samp_Fields = {
  __typename?: "client_stddev_samp_fields";
  employee_count?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "client" */
export type Client_Stddev_Samp_Order_By = {
  employee_count?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "client" */
export type Client_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Client_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Client_Stream_Cursor_Value_Input = {
  client_name?: InputMaybe<Scalars["String"]>;
  company_id?: InputMaybe<Scalars["uuid"]>;
  company_size?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  employee_count?: InputMaybe<Scalars["Int"]>;
  has_multilocation?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  is_default?: InputMaybe<Scalars["Boolean"]>;
  working_type?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Client_Sum_Fields = {
  __typename?: "client_sum_fields";
  employee_count?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "client" */
export type Client_Sum_Order_By = {
  employee_count?: InputMaybe<Order_By>;
};

/** update columns of table "client" */
export enum Client_Update_Column {
  /** column name */
  ClientName = "client_name",
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CompanySize = "company_size",
  /** column name */
  Description = "description",
  /** column name */
  EmployeeCount = "employee_count",
  /** column name */
  HasMultilocation = "has_multilocation",
  /** column name */
  Id = "id",
  /** column name */
  IsDefault = "is_default",
  /** column name */
  WorkingType = "working_type",
}

export type Client_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Client_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Client_Set_Input>;
  /** filter the rows which have to be updated */
  where: Client_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Client_Var_Pop_Fields = {
  __typename?: "client_var_pop_fields";
  employee_count?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "client" */
export type Client_Var_Pop_Order_By = {
  employee_count?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Client_Var_Samp_Fields = {
  __typename?: "client_var_samp_fields";
  employee_count?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "client" */
export type Client_Var_Samp_Order_By = {
  employee_count?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Client_Variance_Fields = {
  __typename?: "client_variance_fields";
  employee_count?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "client" */
export type Client_Variance_Order_By = {
  employee_count?: InputMaybe<Order_By>;
};

/** columns and relationships of "company" */
export type Company = {
  __typename?: "company";
  /** An array relationship */
  clients: Array<Client>;
  /** An aggregate relationship */
  clients_aggregate: Client_Aggregate;
  company_name: Scalars["String"];
  id: Scalars["uuid"];
  tax_chamber?: Maybe<Scalars["String"]>;
  tax_number?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "company" */
export type CompanyClientsArgs = {
  distinct_on?: InputMaybe<Array<Client_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Client_Order_By>>;
  where?: InputMaybe<Client_Bool_Exp>;
};

/** columns and relationships of "company" */
export type CompanyClients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Client_Order_By>>;
  where?: InputMaybe<Client_Bool_Exp>;
};

/** aggregated selection of "company" */
export type Company_Aggregate = {
  __typename?: "company_aggregate";
  aggregate?: Maybe<Company_Aggregate_Fields>;
  nodes: Array<Company>;
};

/** aggregate fields of "company" */
export type Company_Aggregate_Fields = {
  __typename?: "company_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Company_Max_Fields>;
  min?: Maybe<Company_Min_Fields>;
};

/** aggregate fields of "company" */
export type Company_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Company_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "company". All fields are combined with a logical 'AND'. */
export type Company_Bool_Exp = {
  _and?: InputMaybe<Array<Company_Bool_Exp>>;
  _not?: InputMaybe<Company_Bool_Exp>;
  _or?: InputMaybe<Array<Company_Bool_Exp>>;
  clients?: InputMaybe<Client_Bool_Exp>;
  clients_aggregate?: InputMaybe<Client_Aggregate_Bool_Exp>;
  company_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  tax_chamber?: InputMaybe<String_Comparison_Exp>;
  tax_number?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "company" */
export enum Company_Constraint {
  /** unique or primary key constraint on columns "id" */
  CompanyPkey = "company_pkey",
}

/** input type for inserting data into table "company" */
export type Company_Insert_Input = {
  clients?: InputMaybe<Client_Arr_Rel_Insert_Input>;
  company_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  tax_chamber?: InputMaybe<Scalars["String"]>;
  tax_number?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Company_Max_Fields = {
  __typename?: "company_max_fields";
  company_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  tax_chamber?: Maybe<Scalars["String"]>;
  tax_number?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Company_Min_Fields = {
  __typename?: "company_min_fields";
  company_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  tax_chamber?: Maybe<Scalars["String"]>;
  tax_number?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "company" */
export type Company_Mutation_Response = {
  __typename?: "company_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Company>;
};

/** input type for inserting object relation for remote table "company" */
export type Company_Obj_Rel_Insert_Input = {
  data: Company_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Company_On_Conflict>;
};

/** on_conflict condition type for table "company" */
export type Company_On_Conflict = {
  constraint: Company_Constraint;
  update_columns?: Array<Company_Update_Column>;
  where?: InputMaybe<Company_Bool_Exp>;
};

/** Ordering options when selecting data from "company". */
export type Company_Order_By = {
  clients_aggregate?: InputMaybe<Client_Aggregate_Order_By>;
  company_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tax_chamber?: InputMaybe<Order_By>;
  tax_number?: InputMaybe<Order_By>;
};

/** primary key columns input for table: company */
export type Company_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "company" */
export enum Company_Select_Column {
  /** column name */
  CompanyName = "company_name",
  /** column name */
  Id = "id",
  /** column name */
  TaxChamber = "tax_chamber",
  /** column name */
  TaxNumber = "tax_number",
}

/** input type for updating data in table "company" */
export type Company_Set_Input = {
  company_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  tax_chamber?: InputMaybe<Scalars["String"]>;
  tax_number?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "company" */
export type Company_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Company_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Company_Stream_Cursor_Value_Input = {
  company_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  tax_chamber?: InputMaybe<Scalars["String"]>;
  tax_number?: InputMaybe<Scalars["String"]>;
};

/** company_subscription */
export type Company_Subscription = {
  __typename?: "company_subscription";
  company_id: Scalars["uuid"];
  created_at: Scalars["timestamptz"];
  ends_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  /** An object relationship */
  product_price: Product_Price;
  product_price_id: Scalars["uuid"];
  starts_at: Scalars["timestamptz"];
  status: Scalars["String"];
};

/** aggregated selection of "company_subscription" */
export type Company_Subscription_Aggregate = {
  __typename?: "company_subscription_aggregate";
  aggregate?: Maybe<Company_Subscription_Aggregate_Fields>;
  nodes: Array<Company_Subscription>;
};

/** aggregate fields of "company_subscription" */
export type Company_Subscription_Aggregate_Fields = {
  __typename?: "company_subscription_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Company_Subscription_Max_Fields>;
  min?: Maybe<Company_Subscription_Min_Fields>;
};

/** aggregate fields of "company_subscription" */
export type Company_Subscription_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Company_Subscription_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "company_subscription". All fields are combined with a logical 'AND'. */
export type Company_Subscription_Bool_Exp = {
  _and?: InputMaybe<Array<Company_Subscription_Bool_Exp>>;
  _not?: InputMaybe<Company_Subscription_Bool_Exp>;
  _or?: InputMaybe<Array<Company_Subscription_Bool_Exp>>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  ends_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  product_price?: InputMaybe<Product_Price_Bool_Exp>;
  product_price_id?: InputMaybe<Uuid_Comparison_Exp>;
  starts_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "company_subscription" */
export enum Company_Subscription_Constraint {
  /** unique or primary key constraint on columns "id" */
  CompanySubscriptionPkey = "company_subscription_pkey",
}

/** input type for inserting data into table "company_subscription" */
export type Company_Subscription_Insert_Input = {
  company_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  ends_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  product_price?: InputMaybe<Product_Price_Obj_Rel_Insert_Input>;
  product_price_id?: InputMaybe<Scalars["uuid"]>;
  starts_at?: InputMaybe<Scalars["timestamptz"]>;
  status?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Company_Subscription_Max_Fields = {
  __typename?: "company_subscription_max_fields";
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  ends_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  product_price_id?: Maybe<Scalars["uuid"]>;
  starts_at?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Company_Subscription_Min_Fields = {
  __typename?: "company_subscription_min_fields";
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  ends_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  product_price_id?: Maybe<Scalars["uuid"]>;
  starts_at?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "company_subscription" */
export type Company_Subscription_Mutation_Response = {
  __typename?: "company_subscription_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Company_Subscription>;
};

/** on_conflict condition type for table "company_subscription" */
export type Company_Subscription_On_Conflict = {
  constraint: Company_Subscription_Constraint;
  update_columns?: Array<Company_Subscription_Update_Column>;
  where?: InputMaybe<Company_Subscription_Bool_Exp>;
};

/** Ordering options when selecting data from "company_subscription". */
export type Company_Subscription_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  ends_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_price?: InputMaybe<Product_Price_Order_By>;
  product_price_id?: InputMaybe<Order_By>;
  starts_at?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: company_subscription */
export type Company_Subscription_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "company_subscription" */
export enum Company_Subscription_Select_Column {
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  EndsAt = "ends_at",
  /** column name */
  Id = "id",
  /** column name */
  ProductPriceId = "product_price_id",
  /** column name */
  StartsAt = "starts_at",
  /** column name */
  Status = "status",
}

/** input type for updating data in table "company_subscription" */
export type Company_Subscription_Set_Input = {
  company_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  ends_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  product_price_id?: InputMaybe<Scalars["uuid"]>;
  starts_at?: InputMaybe<Scalars["timestamptz"]>;
  status?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "company_subscription" */
export type Company_Subscription_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Company_Subscription_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Company_Subscription_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  ends_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  product_price_id?: InputMaybe<Scalars["uuid"]>;
  starts_at?: InputMaybe<Scalars["timestamptz"]>;
  status?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "company_subscription" */
export enum Company_Subscription_Update_Column {
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  EndsAt = "ends_at",
  /** column name */
  Id = "id",
  /** column name */
  ProductPriceId = "product_price_id",
  /** column name */
  StartsAt = "starts_at",
  /** column name */
  Status = "status",
}

export type Company_Subscription_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Company_Subscription_Set_Input>;
  /** filter the rows which have to be updated */
  where: Company_Subscription_Bool_Exp;
};

/** update columns of table "company" */
export enum Company_Update_Column {
  /** column name */
  CompanyName = "company_name",
  /** column name */
  Id = "id",
  /** column name */
  TaxChamber = "tax_chamber",
  /** column name */
  TaxNumber = "tax_number",
}

export type Company_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Company_Set_Input>;
  /** filter the rows which have to be updated */
  where: Company_Bool_Exp;
};

/** columns and relationships of "consent" */
export type Consent = {
  __typename?: "consent";
  consent_document?: Maybe<Scalars["String"]>;
  consent_status: Consent_Status_Enum;
  consent_type: Consent_Type_Enum;
  given_at: Scalars["timestamp"];
  given_for: Scalars["String"];
  id: Scalars["bigint"];
  user_identification: Scalars["String"];
  user_type: Scalars["String"];
};

/** aggregated selection of "consent" */
export type Consent_Aggregate = {
  __typename?: "consent_aggregate";
  aggregate?: Maybe<Consent_Aggregate_Fields>;
  nodes: Array<Consent>;
};

/** aggregate fields of "consent" */
export type Consent_Aggregate_Fields = {
  __typename?: "consent_aggregate_fields";
  avg?: Maybe<Consent_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Consent_Max_Fields>;
  min?: Maybe<Consent_Min_Fields>;
  stddev?: Maybe<Consent_Stddev_Fields>;
  stddev_pop?: Maybe<Consent_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Consent_Stddev_Samp_Fields>;
  sum?: Maybe<Consent_Sum_Fields>;
  var_pop?: Maybe<Consent_Var_Pop_Fields>;
  var_samp?: Maybe<Consent_Var_Samp_Fields>;
  variance?: Maybe<Consent_Variance_Fields>;
};

/** aggregate fields of "consent" */
export type Consent_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Consent_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Consent_Avg_Fields = {
  __typename?: "consent_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "consent". All fields are combined with a logical 'AND'. */
export type Consent_Bool_Exp = {
  _and?: InputMaybe<Array<Consent_Bool_Exp>>;
  _not?: InputMaybe<Consent_Bool_Exp>;
  _or?: InputMaybe<Array<Consent_Bool_Exp>>;
  consent_document?: InputMaybe<String_Comparison_Exp>;
  consent_status?: InputMaybe<Consent_Status_Enum_Comparison_Exp>;
  consent_type?: InputMaybe<Consent_Type_Enum_Comparison_Exp>;
  given_at?: InputMaybe<Timestamp_Comparison_Exp>;
  given_for?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  user_identification?: InputMaybe<String_Comparison_Exp>;
  user_type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "consent" */
export enum Consent_Constraint {
  /** unique or primary key constraint on columns "id" */
  ConsentPkey = "consent_pkey",
}

/** input type for incrementing numeric columns in table "consent" */
export type Consent_Inc_Input = {
  id?: InputMaybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "consent" */
export type Consent_Insert_Input = {
  consent_document?: InputMaybe<Scalars["String"]>;
  consent_status?: InputMaybe<Consent_Status_Enum>;
  consent_type?: InputMaybe<Consent_Type_Enum>;
  given_at?: InputMaybe<Scalars["timestamp"]>;
  given_for?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  user_identification?: InputMaybe<Scalars["String"]>;
  user_type?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Consent_Max_Fields = {
  __typename?: "consent_max_fields";
  consent_document?: Maybe<Scalars["String"]>;
  given_at?: Maybe<Scalars["timestamp"]>;
  given_for?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  user_identification?: Maybe<Scalars["String"]>;
  user_type?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Consent_Min_Fields = {
  __typename?: "consent_min_fields";
  consent_document?: Maybe<Scalars["String"]>;
  given_at?: Maybe<Scalars["timestamp"]>;
  given_for?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["bigint"]>;
  user_identification?: Maybe<Scalars["String"]>;
  user_type?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "consent" */
export type Consent_Mutation_Response = {
  __typename?: "consent_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Consent>;
};

/** on_conflict condition type for table "consent" */
export type Consent_On_Conflict = {
  constraint: Consent_Constraint;
  update_columns?: Array<Consent_Update_Column>;
  where?: InputMaybe<Consent_Bool_Exp>;
};

/** Ordering options when selecting data from "consent". */
export type Consent_Order_By = {
  consent_document?: InputMaybe<Order_By>;
  consent_status?: InputMaybe<Order_By>;
  consent_type?: InputMaybe<Order_By>;
  given_at?: InputMaybe<Order_By>;
  given_for?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_identification?: InputMaybe<Order_By>;
  user_type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: consent */
export type Consent_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "consent" */
export enum Consent_Select_Column {
  /** column name */
  ConsentDocument = "consent_document",
  /** column name */
  ConsentStatus = "consent_status",
  /** column name */
  ConsentType = "consent_type",
  /** column name */
  GivenAt = "given_at",
  /** column name */
  GivenFor = "given_for",
  /** column name */
  Id = "id",
  /** column name */
  UserIdentification = "user_identification",
  /** column name */
  UserType = "user_type",
}

/** input type for updating data in table "consent" */
export type Consent_Set_Input = {
  consent_document?: InputMaybe<Scalars["String"]>;
  consent_status?: InputMaybe<Consent_Status_Enum>;
  consent_type?: InputMaybe<Consent_Type_Enum>;
  given_at?: InputMaybe<Scalars["timestamp"]>;
  given_for?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  user_identification?: InputMaybe<Scalars["String"]>;
  user_type?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "consent_status" */
export type Consent_Status = {
  __typename?: "consent_status";
  consent_status: Scalars["String"];
};

/** aggregated selection of "consent_status" */
export type Consent_Status_Aggregate = {
  __typename?: "consent_status_aggregate";
  aggregate?: Maybe<Consent_Status_Aggregate_Fields>;
  nodes: Array<Consent_Status>;
};

/** aggregate fields of "consent_status" */
export type Consent_Status_Aggregate_Fields = {
  __typename?: "consent_status_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Consent_Status_Max_Fields>;
  min?: Maybe<Consent_Status_Min_Fields>;
};

/** aggregate fields of "consent_status" */
export type Consent_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Consent_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "consent_status". All fields are combined with a logical 'AND'. */
export type Consent_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Consent_Status_Bool_Exp>>;
  _not?: InputMaybe<Consent_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Consent_Status_Bool_Exp>>;
  consent_status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "consent_status" */
export enum Consent_Status_Constraint {
  /** unique or primary key constraint on columns "consent_status" */
  ConsentStatusPkey = "consent_status_pkey",
}

export enum Consent_Status_Enum {
  NoChoice = "NO_CHOICE",
  OptIn = "OPT_IN",
  OptOut = "OPT_OUT",
}

/** Boolean expression to compare columns of type "consent_status_enum". All fields are combined with logical 'AND'. */
export type Consent_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Consent_Status_Enum>;
  _in?: InputMaybe<Array<Consent_Status_Enum>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _neq?: InputMaybe<Consent_Status_Enum>;
  _nin?: InputMaybe<Array<Consent_Status_Enum>>;
};

/** input type for inserting data into table "consent_status" */
export type Consent_Status_Insert_Input = {
  consent_status?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Consent_Status_Max_Fields = {
  __typename?: "consent_status_max_fields";
  consent_status?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Consent_Status_Min_Fields = {
  __typename?: "consent_status_min_fields";
  consent_status?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "consent_status" */
export type Consent_Status_Mutation_Response = {
  __typename?: "consent_status_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Consent_Status>;
};

/** on_conflict condition type for table "consent_status" */
export type Consent_Status_On_Conflict = {
  constraint: Consent_Status_Constraint;
  update_columns?: Array<Consent_Status_Update_Column>;
  where?: InputMaybe<Consent_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "consent_status". */
export type Consent_Status_Order_By = {
  consent_status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: consent_status */
export type Consent_Status_Pk_Columns_Input = {
  consent_status: Scalars["String"];
};

/** select columns of table "consent_status" */
export enum Consent_Status_Select_Column {
  /** column name */
  ConsentStatus = "consent_status",
}

/** input type for updating data in table "consent_status" */
export type Consent_Status_Set_Input = {
  consent_status?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "consent_status" */
export type Consent_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Consent_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Consent_Status_Stream_Cursor_Value_Input = {
  consent_status?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "consent_status" */
export enum Consent_Status_Update_Column {
  /** column name */
  ConsentStatus = "consent_status",
}

export type Consent_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Consent_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Consent_Status_Bool_Exp;
};

/** aggregate stddev on columns */
export type Consent_Stddev_Fields = {
  __typename?: "consent_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Consent_Stddev_Pop_Fields = {
  __typename?: "consent_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Consent_Stddev_Samp_Fields = {
  __typename?: "consent_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "consent" */
export type Consent_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Consent_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Consent_Stream_Cursor_Value_Input = {
  consent_document?: InputMaybe<Scalars["String"]>;
  consent_status?: InputMaybe<Consent_Status_Enum>;
  consent_type?: InputMaybe<Consent_Type_Enum>;
  given_at?: InputMaybe<Scalars["timestamp"]>;
  given_for?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  user_identification?: InputMaybe<Scalars["String"]>;
  user_type?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Consent_Sum_Fields = {
  __typename?: "consent_sum_fields";
  id?: Maybe<Scalars["bigint"]>;
};

/** columns and relationships of "consent_type" */
export type Consent_Type = {
  __typename?: "consent_type";
  consent_type: Scalars["String"];
};

/** aggregated selection of "consent_type" */
export type Consent_Type_Aggregate = {
  __typename?: "consent_type_aggregate";
  aggregate?: Maybe<Consent_Type_Aggregate_Fields>;
  nodes: Array<Consent_Type>;
};

/** aggregate fields of "consent_type" */
export type Consent_Type_Aggregate_Fields = {
  __typename?: "consent_type_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Consent_Type_Max_Fields>;
  min?: Maybe<Consent_Type_Min_Fields>;
};

/** aggregate fields of "consent_type" */
export type Consent_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Consent_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "consent_type". All fields are combined with a logical 'AND'. */
export type Consent_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Consent_Type_Bool_Exp>>;
  _not?: InputMaybe<Consent_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Consent_Type_Bool_Exp>>;
  consent_type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "consent_type" */
export enum Consent_Type_Constraint {
  /** unique or primary key constraint on columns "consent_type" */
  ConsentTypePkey = "consent_type_pkey",
}

export enum Consent_Type_Enum {
  ClarificationText = "CLARIFICATION_TEXT",
  ExplicitConsent = "EXPLICIT_CONSENT",
}

/** Boolean expression to compare columns of type "consent_type_enum". All fields are combined with logical 'AND'. */
export type Consent_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Consent_Type_Enum>;
  _in?: InputMaybe<Array<Consent_Type_Enum>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _neq?: InputMaybe<Consent_Type_Enum>;
  _nin?: InputMaybe<Array<Consent_Type_Enum>>;
};

/** input type for inserting data into table "consent_type" */
export type Consent_Type_Insert_Input = {
  consent_type?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Consent_Type_Max_Fields = {
  __typename?: "consent_type_max_fields";
  consent_type?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Consent_Type_Min_Fields = {
  __typename?: "consent_type_min_fields";
  consent_type?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "consent_type" */
export type Consent_Type_Mutation_Response = {
  __typename?: "consent_type_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Consent_Type>;
};

/** on_conflict condition type for table "consent_type" */
export type Consent_Type_On_Conflict = {
  constraint: Consent_Type_Constraint;
  update_columns?: Array<Consent_Type_Update_Column>;
  where?: InputMaybe<Consent_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "consent_type". */
export type Consent_Type_Order_By = {
  consent_type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: consent_type */
export type Consent_Type_Pk_Columns_Input = {
  consent_type: Scalars["String"];
};

/** select columns of table "consent_type" */
export enum Consent_Type_Select_Column {
  /** column name */
  ConsentType = "consent_type",
}

/** input type for updating data in table "consent_type" */
export type Consent_Type_Set_Input = {
  consent_type?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "consent_type" */
export type Consent_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Consent_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Consent_Type_Stream_Cursor_Value_Input = {
  consent_type?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "consent_type" */
export enum Consent_Type_Update_Column {
  /** column name */
  ConsentType = "consent_type",
}

export type Consent_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Consent_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Consent_Type_Bool_Exp;
};

/** update columns of table "consent" */
export enum Consent_Update_Column {
  /** column name */
  ConsentDocument = "consent_document",
  /** column name */
  ConsentStatus = "consent_status",
  /** column name */
  ConsentType = "consent_type",
  /** column name */
  GivenAt = "given_at",
  /** column name */
  GivenFor = "given_for",
  /** column name */
  Id = "id",
  /** column name */
  UserIdentification = "user_identification",
  /** column name */
  UserType = "user_type",
}

export type Consent_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Consent_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Consent_Set_Input>;
  /** filter the rows which have to be updated */
  where: Consent_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Consent_Var_Pop_Fields = {
  __typename?: "consent_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Consent_Var_Samp_Fields = {
  __typename?: "consent_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Consent_Variance_Fields = {
  __typename?: "consent_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "country" */
export type Country = {
  __typename?: "country";
  /** An array relationship */
  cities: Array<City>;
  /** An aggregate relationship */
  cities_aggregate: City_Aggregate;
  country_name: Scalars["String"];
  id: Scalars["Int"];
};

/** columns and relationships of "country" */
export type CountryCitiesArgs = {
  distinct_on?: InputMaybe<Array<City_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<City_Order_By>>;
  where?: InputMaybe<City_Bool_Exp>;
};

/** columns and relationships of "country" */
export type CountryCities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<City_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<City_Order_By>>;
  where?: InputMaybe<City_Bool_Exp>;
};

/** aggregated selection of "country" */
export type Country_Aggregate = {
  __typename?: "country_aggregate";
  aggregate?: Maybe<Country_Aggregate_Fields>;
  nodes: Array<Country>;
};

/** aggregate fields of "country" */
export type Country_Aggregate_Fields = {
  __typename?: "country_aggregate_fields";
  avg?: Maybe<Country_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Country_Max_Fields>;
  min?: Maybe<Country_Min_Fields>;
  stddev?: Maybe<Country_Stddev_Fields>;
  stddev_pop?: Maybe<Country_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Country_Stddev_Samp_Fields>;
  sum?: Maybe<Country_Sum_Fields>;
  var_pop?: Maybe<Country_Var_Pop_Fields>;
  var_samp?: Maybe<Country_Var_Samp_Fields>;
  variance?: Maybe<Country_Variance_Fields>;
};

/** aggregate fields of "country" */
export type Country_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Country_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Country_Avg_Fields = {
  __typename?: "country_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "country". All fields are combined with a logical 'AND'. */
export type Country_Bool_Exp = {
  _and?: InputMaybe<Array<Country_Bool_Exp>>;
  _not?: InputMaybe<Country_Bool_Exp>;
  _or?: InputMaybe<Array<Country_Bool_Exp>>;
  cities?: InputMaybe<City_Bool_Exp>;
  cities_aggregate?: InputMaybe<City_Aggregate_Bool_Exp>;
  country_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "country" */
export enum Country_Constraint {
  /** unique or primary key constraint on columns "id" */
  CountryPkey = "country_pkey",
}

/** input type for incrementing numeric columns in table "country" */
export type Country_Inc_Input = {
  id?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "country" */
export type Country_Insert_Input = {
  cities?: InputMaybe<City_Arr_Rel_Insert_Input>;
  country_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Country_Max_Fields = {
  __typename?: "country_max_fields";
  country_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** aggregate min on columns */
export type Country_Min_Fields = {
  __typename?: "country_min_fields";
  country_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** response of any mutation on the table "country" */
export type Country_Mutation_Response = {
  __typename?: "country_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Country>;
};

/** input type for inserting object relation for remote table "country" */
export type Country_Obj_Rel_Insert_Input = {
  data: Country_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Country_On_Conflict>;
};

/** on_conflict condition type for table "country" */
export type Country_On_Conflict = {
  constraint: Country_Constraint;
  update_columns?: Array<Country_Update_Column>;
  where?: InputMaybe<Country_Bool_Exp>;
};

/** Ordering options when selecting data from "country". */
export type Country_Order_By = {
  cities_aggregate?: InputMaybe<City_Aggregate_Order_By>;
  country_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: country */
export type Country_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "country" */
export enum Country_Select_Column {
  /** column name */
  CountryName = "country_name",
  /** column name */
  Id = "id",
}

/** input type for updating data in table "country" */
export type Country_Set_Input = {
  country_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Country_Stddev_Fields = {
  __typename?: "country_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Country_Stddev_Pop_Fields = {
  __typename?: "country_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Country_Stddev_Samp_Fields = {
  __typename?: "country_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "country" */
export type Country_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Country_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Country_Stream_Cursor_Value_Input = {
  country_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Country_Sum_Fields = {
  __typename?: "country_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "country" */
export enum Country_Update_Column {
  /** column name */
  CountryName = "country_name",
  /** column name */
  Id = "id",
}

export type Country_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Country_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Country_Set_Input>;
  /** filter the rows which have to be updated */
  where: Country_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Country_Var_Pop_Fields = {
  __typename?: "country_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Country_Var_Samp_Fields = {
  __typename?: "country_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Country_Variance_Fields = {
  __typename?: "country_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "currency" */
export type Currency = {
  __typename?: "currency";
  currency: Scalars["String"];
};

/** aggregated selection of "currency" */
export type Currency_Aggregate = {
  __typename?: "currency_aggregate";
  aggregate?: Maybe<Currency_Aggregate_Fields>;
  nodes: Array<Currency>;
};

/** aggregate fields of "currency" */
export type Currency_Aggregate_Fields = {
  __typename?: "currency_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Currency_Max_Fields>;
  min?: Maybe<Currency_Min_Fields>;
};

/** aggregate fields of "currency" */
export type Currency_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Currency_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "currency". All fields are combined with a logical 'AND'. */
export type Currency_Bool_Exp = {
  _and?: InputMaybe<Array<Currency_Bool_Exp>>;
  _not?: InputMaybe<Currency_Bool_Exp>;
  _or?: InputMaybe<Array<Currency_Bool_Exp>>;
  currency?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "currency" */
export enum Currency_Constraint {
  /** unique or primary key constraint on columns "currency" */
  CurrencyPkey = "currency_pkey",
}

export enum Currency_Enum {
  Try = "TRY",
  Usd = "USD",
}

/** Boolean expression to compare columns of type "currency_enum". All fields are combined with logical 'AND'. */
export type Currency_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Currency_Enum>;
  _in?: InputMaybe<Array<Currency_Enum>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _neq?: InputMaybe<Currency_Enum>;
  _nin?: InputMaybe<Array<Currency_Enum>>;
};

/** input type for inserting data into table "currency" */
export type Currency_Insert_Input = {
  currency?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Currency_Max_Fields = {
  __typename?: "currency_max_fields";
  currency?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Currency_Min_Fields = {
  __typename?: "currency_min_fields";
  currency?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "currency" */
export type Currency_Mutation_Response = {
  __typename?: "currency_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Currency>;
};

/** on_conflict condition type for table "currency" */
export type Currency_On_Conflict = {
  constraint: Currency_Constraint;
  update_columns?: Array<Currency_Update_Column>;
  where?: InputMaybe<Currency_Bool_Exp>;
};

/** Ordering options when selecting data from "currency". */
export type Currency_Order_By = {
  currency?: InputMaybe<Order_By>;
};

/** primary key columns input for table: currency */
export type Currency_Pk_Columns_Input = {
  currency: Scalars["String"];
};

/** select columns of table "currency" */
export enum Currency_Select_Column {
  /** column name */
  Currency = "currency",
}

/** input type for updating data in table "currency" */
export type Currency_Set_Input = {
  currency?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "currency" */
export type Currency_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Currency_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Currency_Stream_Cursor_Value_Input = {
  currency?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "currency" */
export enum Currency_Update_Column {
  /** column name */
  Currency = "currency",
}

export type Currency_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Currency_Set_Input>;
  /** filter the rows which have to be updated */
  where: Currency_Bool_Exp;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = "ASC",
  /** descending ordering of the cursor */
  Desc = "DESC",
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["date"]>;
  _gt?: InputMaybe<Scalars["date"]>;
  _gte?: InputMaybe<Scalars["date"]>;
  _in?: InputMaybe<Array<Scalars["date"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["date"]>;
  _lte?: InputMaybe<Scalars["date"]>;
  _neq?: InputMaybe<Scalars["date"]>;
  _nin?: InputMaybe<Array<Scalars["date"]>>;
};

/** columns and relationships of "department" */
export type Department = {
  __typename?: "department";
  department_name: Scalars["String"];
  id: Scalars["Int"];
  /** An array relationship */
  roles: Array<Role>;
  /** An aggregate relationship */
  roles_aggregate: Role_Aggregate;
  /** An object relationship */
  sector: Sector;
  sector_id: Scalars["Int"];
};

/** columns and relationships of "department" */
export type DepartmentRolesArgs = {
  distinct_on?: InputMaybe<Array<Role_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Order_By>>;
  where?: InputMaybe<Role_Bool_Exp>;
};

/** columns and relationships of "department" */
export type DepartmentRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Order_By>>;
  where?: InputMaybe<Role_Bool_Exp>;
};

/** aggregated selection of "department" */
export type Department_Aggregate = {
  __typename?: "department_aggregate";
  aggregate?: Maybe<Department_Aggregate_Fields>;
  nodes: Array<Department>;
};

export type Department_Aggregate_Bool_Exp = {
  count?: InputMaybe<Department_Aggregate_Bool_Exp_Count>;
};

export type Department_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Department_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Department_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "department" */
export type Department_Aggregate_Fields = {
  __typename?: "department_aggregate_fields";
  avg?: Maybe<Department_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Department_Max_Fields>;
  min?: Maybe<Department_Min_Fields>;
  stddev?: Maybe<Department_Stddev_Fields>;
  stddev_pop?: Maybe<Department_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Department_Stddev_Samp_Fields>;
  sum?: Maybe<Department_Sum_Fields>;
  var_pop?: Maybe<Department_Var_Pop_Fields>;
  var_samp?: Maybe<Department_Var_Samp_Fields>;
  variance?: Maybe<Department_Variance_Fields>;
};

/** aggregate fields of "department" */
export type Department_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Department_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "department" */
export type Department_Aggregate_Order_By = {
  avg?: InputMaybe<Department_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Department_Max_Order_By>;
  min?: InputMaybe<Department_Min_Order_By>;
  stddev?: InputMaybe<Department_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Department_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Department_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Department_Sum_Order_By>;
  var_pop?: InputMaybe<Department_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Department_Var_Samp_Order_By>;
  variance?: InputMaybe<Department_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "department" */
export type Department_Arr_Rel_Insert_Input = {
  data: Array<Department_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Department_On_Conflict>;
};

/** aggregate avg on columns */
export type Department_Avg_Fields = {
  __typename?: "department_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  sector_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "department" */
export type Department_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  sector_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "department". All fields are combined with a logical 'AND'. */
export type Department_Bool_Exp = {
  _and?: InputMaybe<Array<Department_Bool_Exp>>;
  _not?: InputMaybe<Department_Bool_Exp>;
  _or?: InputMaybe<Array<Department_Bool_Exp>>;
  department_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  roles?: InputMaybe<Role_Bool_Exp>;
  roles_aggregate?: InputMaybe<Role_Aggregate_Bool_Exp>;
  sector?: InputMaybe<Sector_Bool_Exp>;
  sector_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "department" */
export enum Department_Constraint {
  /** unique or primary key constraint on columns "id" */
  DepartmentPkey = "department_pkey",
}

/** input type for incrementing numeric columns in table "department" */
export type Department_Inc_Input = {
  id?: InputMaybe<Scalars["Int"]>;
  sector_id?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "department" */
export type Department_Insert_Input = {
  department_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  roles?: InputMaybe<Role_Arr_Rel_Insert_Input>;
  sector?: InputMaybe<Sector_Obj_Rel_Insert_Input>;
  sector_id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Department_Max_Fields = {
  __typename?: "department_max_fields";
  department_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  sector_id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "department" */
export type Department_Max_Order_By = {
  department_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sector_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Department_Min_Fields = {
  __typename?: "department_min_fields";
  department_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  sector_id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "department" */
export type Department_Min_Order_By = {
  department_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sector_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "department" */
export type Department_Mutation_Response = {
  __typename?: "department_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Department>;
};

/** input type for inserting object relation for remote table "department" */
export type Department_Obj_Rel_Insert_Input = {
  data: Department_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Department_On_Conflict>;
};

/** on_conflict condition type for table "department" */
export type Department_On_Conflict = {
  constraint: Department_Constraint;
  update_columns?: Array<Department_Update_Column>;
  where?: InputMaybe<Department_Bool_Exp>;
};

/** Ordering options when selecting data from "department". */
export type Department_Order_By = {
  department_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  roles_aggregate?: InputMaybe<Role_Aggregate_Order_By>;
  sector?: InputMaybe<Sector_Order_By>;
  sector_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: department */
export type Department_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "department" */
export enum Department_Select_Column {
  /** column name */
  DepartmentName = "department_name",
  /** column name */
  Id = "id",
  /** column name */
  SectorId = "sector_id",
}

/** input type for updating data in table "department" */
export type Department_Set_Input = {
  department_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  sector_id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Department_Stddev_Fields = {
  __typename?: "department_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  sector_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "department" */
export type Department_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  sector_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Department_Stddev_Pop_Fields = {
  __typename?: "department_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  sector_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "department" */
export type Department_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  sector_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Department_Stddev_Samp_Fields = {
  __typename?: "department_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  sector_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "department" */
export type Department_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  sector_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "department" */
export type Department_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Department_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Department_Stream_Cursor_Value_Input = {
  department_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  sector_id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Department_Sum_Fields = {
  __typename?: "department_sum_fields";
  id?: Maybe<Scalars["Int"]>;
  sector_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "department" */
export type Department_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  sector_id?: InputMaybe<Order_By>;
};

/** update columns of table "department" */
export enum Department_Update_Column {
  /** column name */
  DepartmentName = "department_name",
  /** column name */
  Id = "id",
  /** column name */
  SectorId = "sector_id",
}

export type Department_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Department_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Department_Set_Input>;
  /** filter the rows which have to be updated */
  where: Department_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Department_Var_Pop_Fields = {
  __typename?: "department_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  sector_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "department" */
export type Department_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  sector_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Department_Var_Samp_Fields = {
  __typename?: "department_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  sector_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "department" */
export type Department_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  sector_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Department_Variance_Fields = {
  __typename?: "department_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  sector_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "department" */
export type Department_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  sector_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "education_type" */
export type Education_Type = {
  __typename?: "education_type";
  education_name: Scalars["String"];
  id: Scalars["Int"];
};

/** aggregated selection of "education_type" */
export type Education_Type_Aggregate = {
  __typename?: "education_type_aggregate";
  aggregate?: Maybe<Education_Type_Aggregate_Fields>;
  nodes: Array<Education_Type>;
};

/** aggregate fields of "education_type" */
export type Education_Type_Aggregate_Fields = {
  __typename?: "education_type_aggregate_fields";
  avg?: Maybe<Education_Type_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Education_Type_Max_Fields>;
  min?: Maybe<Education_Type_Min_Fields>;
  stddev?: Maybe<Education_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Education_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Education_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Education_Type_Sum_Fields>;
  var_pop?: Maybe<Education_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Education_Type_Var_Samp_Fields>;
  variance?: Maybe<Education_Type_Variance_Fields>;
};

/** aggregate fields of "education_type" */
export type Education_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Education_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Education_Type_Avg_Fields = {
  __typename?: "education_type_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "education_type". All fields are combined with a logical 'AND'. */
export type Education_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Education_Type_Bool_Exp>>;
  _not?: InputMaybe<Education_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Education_Type_Bool_Exp>>;
  education_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "education_type" */
export enum Education_Type_Constraint {
  /** unique or primary key constraint on columns "id" */
  EducationTypePkey = "education_type_pkey",
}

/** input type for incrementing numeric columns in table "education_type" */
export type Education_Type_Inc_Input = {
  id?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "education_type" */
export type Education_Type_Insert_Input = {
  education_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Education_Type_Max_Fields = {
  __typename?: "education_type_max_fields";
  education_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** aggregate min on columns */
export type Education_Type_Min_Fields = {
  __typename?: "education_type_min_fields";
  education_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** response of any mutation on the table "education_type" */
export type Education_Type_Mutation_Response = {
  __typename?: "education_type_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Education_Type>;
};

/** on_conflict condition type for table "education_type" */
export type Education_Type_On_Conflict = {
  constraint: Education_Type_Constraint;
  update_columns?: Array<Education_Type_Update_Column>;
  where?: InputMaybe<Education_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "education_type". */
export type Education_Type_Order_By = {
  education_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: education_type */
export type Education_Type_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "education_type" */
export enum Education_Type_Select_Column {
  /** column name */
  EducationName = "education_name",
  /** column name */
  Id = "id",
}

/** input type for updating data in table "education_type" */
export type Education_Type_Set_Input = {
  education_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Education_Type_Stddev_Fields = {
  __typename?: "education_type_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Education_Type_Stddev_Pop_Fields = {
  __typename?: "education_type_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Education_Type_Stddev_Samp_Fields = {
  __typename?: "education_type_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "education_type" */
export type Education_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Education_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Education_Type_Stream_Cursor_Value_Input = {
  education_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Education_Type_Sum_Fields = {
  __typename?: "education_type_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "education_type" */
export enum Education_Type_Update_Column {
  /** column name */
  EducationName = "education_name",
  /** column name */
  Id = "id",
}

export type Education_Type_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Education_Type_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Education_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Education_Type_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Education_Type_Var_Pop_Fields = {
  __typename?: "education_type_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Education_Type_Var_Samp_Fields = {
  __typename?: "education_type_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Education_Type_Variance_Fields = {
  __typename?: "education_type_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "interview_criterion" */
export type Interview_Criterion = {
  __typename?: "interview_criterion";
  id: Scalars["Int"];
  judge: Scalars["String"];
  name: Scalars["String"];
  /** An array relationship */
  review_metric_question_criteria: Array<Review_Metric_Question_Criterion>;
  /** An aggregate relationship */
  review_metric_question_criteria_aggregate: Review_Metric_Question_Criterion_Aggregate;
  system_promt: Scalars["String"];
  weight: Scalars["Int"];
};

/** columns and relationships of "interview_criterion" */
export type Interview_CriterionReview_Metric_Question_CriteriaArgs = {
  distinct_on?: InputMaybe<
    Array<Review_Metric_Question_Criterion_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Review_Metric_Question_Criterion_Order_By>>;
  where?: InputMaybe<Review_Metric_Question_Criterion_Bool_Exp>;
};

/** columns and relationships of "interview_criterion" */
export type Interview_CriterionReview_Metric_Question_Criteria_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Review_Metric_Question_Criterion_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Review_Metric_Question_Criterion_Order_By>>;
  where?: InputMaybe<Review_Metric_Question_Criterion_Bool_Exp>;
};

/** aggregated selection of "interview_criterion" */
export type Interview_Criterion_Aggregate = {
  __typename?: "interview_criterion_aggregate";
  aggregate?: Maybe<Interview_Criterion_Aggregate_Fields>;
  nodes: Array<Interview_Criterion>;
};

/** aggregate fields of "interview_criterion" */
export type Interview_Criterion_Aggregate_Fields = {
  __typename?: "interview_criterion_aggregate_fields";
  avg?: Maybe<Interview_Criterion_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Interview_Criterion_Max_Fields>;
  min?: Maybe<Interview_Criterion_Min_Fields>;
  stddev?: Maybe<Interview_Criterion_Stddev_Fields>;
  stddev_pop?: Maybe<Interview_Criterion_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Interview_Criterion_Stddev_Samp_Fields>;
  sum?: Maybe<Interview_Criterion_Sum_Fields>;
  var_pop?: Maybe<Interview_Criterion_Var_Pop_Fields>;
  var_samp?: Maybe<Interview_Criterion_Var_Samp_Fields>;
  variance?: Maybe<Interview_Criterion_Variance_Fields>;
};

/** aggregate fields of "interview_criterion" */
export type Interview_Criterion_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Interview_Criterion_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Interview_Criterion_Avg_Fields = {
  __typename?: "interview_criterion_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  weight?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "interview_criterion". All fields are combined with a logical 'AND'. */
export type Interview_Criterion_Bool_Exp = {
  _and?: InputMaybe<Array<Interview_Criterion_Bool_Exp>>;
  _not?: InputMaybe<Interview_Criterion_Bool_Exp>;
  _or?: InputMaybe<Array<Interview_Criterion_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  judge?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  review_metric_question_criteria?: InputMaybe<Review_Metric_Question_Criterion_Bool_Exp>;
  review_metric_question_criteria_aggregate?: InputMaybe<Review_Metric_Question_Criterion_Aggregate_Bool_Exp>;
  system_promt?: InputMaybe<String_Comparison_Exp>;
  weight?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "interview_criterion" */
export enum Interview_Criterion_Constraint {
  /** unique or primary key constraint on columns "id" */
  ReviewMetricCriterionPkey = "review_metric_criterion_pkey",
}

/** input type for incrementing numeric columns in table "interview_criterion" */
export type Interview_Criterion_Inc_Input = {
  id?: InputMaybe<Scalars["Int"]>;
  weight?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "interview_criterion" */
export type Interview_Criterion_Insert_Input = {
  id?: InputMaybe<Scalars["Int"]>;
  judge?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  review_metric_question_criteria?: InputMaybe<Review_Metric_Question_Criterion_Arr_Rel_Insert_Input>;
  system_promt?: InputMaybe<Scalars["String"]>;
  weight?: InputMaybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Interview_Criterion_Max_Fields = {
  __typename?: "interview_criterion_max_fields";
  id?: Maybe<Scalars["Int"]>;
  judge?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  system_promt?: Maybe<Scalars["String"]>;
  weight?: Maybe<Scalars["Int"]>;
};

/** aggregate min on columns */
export type Interview_Criterion_Min_Fields = {
  __typename?: "interview_criterion_min_fields";
  id?: Maybe<Scalars["Int"]>;
  judge?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  system_promt?: Maybe<Scalars["String"]>;
  weight?: Maybe<Scalars["Int"]>;
};

/** response of any mutation on the table "interview_criterion" */
export type Interview_Criterion_Mutation_Response = {
  __typename?: "interview_criterion_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Interview_Criterion>;
};

/** input type for inserting object relation for remote table "interview_criterion" */
export type Interview_Criterion_Obj_Rel_Insert_Input = {
  data: Interview_Criterion_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Interview_Criterion_On_Conflict>;
};

/** on_conflict condition type for table "interview_criterion" */
export type Interview_Criterion_On_Conflict = {
  constraint: Interview_Criterion_Constraint;
  update_columns?: Array<Interview_Criterion_Update_Column>;
  where?: InputMaybe<Interview_Criterion_Bool_Exp>;
};

/** Ordering options when selecting data from "interview_criterion". */
export type Interview_Criterion_Order_By = {
  id?: InputMaybe<Order_By>;
  judge?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  review_metric_question_criteria_aggregate?: InputMaybe<Review_Metric_Question_Criterion_Aggregate_Order_By>;
  system_promt?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** primary key columns input for table: interview_criterion */
export type Interview_Criterion_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "interview_criterion" */
export enum Interview_Criterion_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  Judge = "judge",
  /** column name */
  Name = "name",
  /** column name */
  SystemPromt = "system_promt",
  /** column name */
  Weight = "weight",
}

/** input type for updating data in table "interview_criterion" */
export type Interview_Criterion_Set_Input = {
  id?: InputMaybe<Scalars["Int"]>;
  judge?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  system_promt?: InputMaybe<Scalars["String"]>;
  weight?: InputMaybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Interview_Criterion_Stddev_Fields = {
  __typename?: "interview_criterion_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  weight?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Interview_Criterion_Stddev_Pop_Fields = {
  __typename?: "interview_criterion_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  weight?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Interview_Criterion_Stddev_Samp_Fields = {
  __typename?: "interview_criterion_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  weight?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "interview_criterion" */
export type Interview_Criterion_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Interview_Criterion_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Interview_Criterion_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars["Int"]>;
  judge?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  system_promt?: InputMaybe<Scalars["String"]>;
  weight?: InputMaybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Interview_Criterion_Sum_Fields = {
  __typename?: "interview_criterion_sum_fields";
  id?: Maybe<Scalars["Int"]>;
  weight?: Maybe<Scalars["Int"]>;
};

/** update columns of table "interview_criterion" */
export enum Interview_Criterion_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  Judge = "judge",
  /** column name */
  Name = "name",
  /** column name */
  SystemPromt = "system_promt",
  /** column name */
  Weight = "weight",
}

export type Interview_Criterion_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Interview_Criterion_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Interview_Criterion_Set_Input>;
  /** filter the rows which have to be updated */
  where: Interview_Criterion_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Interview_Criterion_Var_Pop_Fields = {
  __typename?: "interview_criterion_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  weight?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Interview_Criterion_Var_Samp_Fields = {
  __typename?: "interview_criterion_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  weight?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Interview_Criterion_Variance_Fields = {
  __typename?: "interview_criterion_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  weight?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "interview_template" */
export type Interview_Template = {
  __typename?: "interview_template";
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["Int"];
  /** An array relationship */
  interview_template_review_metric_questions: Array<Interview_Template_Review_Metric_Question>;
  /** An aggregate relationship */
  interview_template_review_metric_questions_aggregate: Interview_Template_Review_Metric_Question_Aggregate;
  interview_type: Video_Interview_Type_Enum;
  role_id?: Maybe<Scalars["uuid"]>;
  template_name: Scalars["String"];
};

/** columns and relationships of "interview_template" */
export type Interview_TemplateInterview_Template_Review_Metric_QuestionsArgs = {
  distinct_on?: InputMaybe<
    Array<Interview_Template_Review_Metric_Question_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<
    Array<Interview_Template_Review_Metric_Question_Order_By>
  >;
  where?: InputMaybe<Interview_Template_Review_Metric_Question_Bool_Exp>;
};

/** columns and relationships of "interview_template" */
export type Interview_TemplateInterview_Template_Review_Metric_Questions_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Interview_Template_Review_Metric_Question_Select_Column>
    >;
    limit?: InputMaybe<Scalars["Int"]>;
    offset?: InputMaybe<Scalars["Int"]>;
    order_by?: InputMaybe<
      Array<Interview_Template_Review_Metric_Question_Order_By>
    >;
    where?: InputMaybe<Interview_Template_Review_Metric_Question_Bool_Exp>;
  };

/** aggregated selection of "interview_template" */
export type Interview_Template_Aggregate = {
  __typename?: "interview_template_aggregate";
  aggregate?: Maybe<Interview_Template_Aggregate_Fields>;
  nodes: Array<Interview_Template>;
};

/** aggregate fields of "interview_template" */
export type Interview_Template_Aggregate_Fields = {
  __typename?: "interview_template_aggregate_fields";
  avg?: Maybe<Interview_Template_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Interview_Template_Max_Fields>;
  min?: Maybe<Interview_Template_Min_Fields>;
  stddev?: Maybe<Interview_Template_Stddev_Fields>;
  stddev_pop?: Maybe<Interview_Template_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Interview_Template_Stddev_Samp_Fields>;
  sum?: Maybe<Interview_Template_Sum_Fields>;
  var_pop?: Maybe<Interview_Template_Var_Pop_Fields>;
  var_samp?: Maybe<Interview_Template_Var_Samp_Fields>;
  variance?: Maybe<Interview_Template_Variance_Fields>;
};

/** aggregate fields of "interview_template" */
export type Interview_Template_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Interview_Template_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Interview_Template_Avg_Fields = {
  __typename?: "interview_template_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "interview_template". All fields are combined with a logical 'AND'. */
export type Interview_Template_Bool_Exp = {
  _and?: InputMaybe<Array<Interview_Template_Bool_Exp>>;
  _not?: InputMaybe<Interview_Template_Bool_Exp>;
  _or?: InputMaybe<Array<Interview_Template_Bool_Exp>>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  interview_template_review_metric_questions?: InputMaybe<Interview_Template_Review_Metric_Question_Bool_Exp>;
  interview_template_review_metric_questions_aggregate?: InputMaybe<Interview_Template_Review_Metric_Question_Aggregate_Bool_Exp>;
  interview_type?: InputMaybe<Video_Interview_Type_Enum_Comparison_Exp>;
  role_id?: InputMaybe<Uuid_Comparison_Exp>;
  template_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "interview_template" */
export enum Interview_Template_Constraint {
  /** unique or primary key constraint on columns "id" */
  InterviewTemplatePkey = "interview_template_pkey",
}

/** input type for incrementing numeric columns in table "interview_template" */
export type Interview_Template_Inc_Input = {
  id?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "interview_template" */
export type Interview_Template_Insert_Input = {
  company_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["Int"]>;
  interview_template_review_metric_questions?: InputMaybe<Interview_Template_Review_Metric_Question_Arr_Rel_Insert_Input>;
  interview_type?: InputMaybe<Video_Interview_Type_Enum>;
  role_id?: InputMaybe<Scalars["uuid"]>;
  template_name?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Interview_Template_Max_Fields = {
  __typename?: "interview_template_max_fields";
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  role_id?: Maybe<Scalars["uuid"]>;
  template_name?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Interview_Template_Min_Fields = {
  __typename?: "interview_template_min_fields";
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  role_id?: Maybe<Scalars["uuid"]>;
  template_name?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "interview_template" */
export type Interview_Template_Mutation_Response = {
  __typename?: "interview_template_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Interview_Template>;
};

/** input type for inserting object relation for remote table "interview_template" */
export type Interview_Template_Obj_Rel_Insert_Input = {
  data: Interview_Template_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Interview_Template_On_Conflict>;
};

/** on_conflict condition type for table "interview_template" */
export type Interview_Template_On_Conflict = {
  constraint: Interview_Template_Constraint;
  update_columns?: Array<Interview_Template_Update_Column>;
  where?: InputMaybe<Interview_Template_Bool_Exp>;
};

/** Ordering options when selecting data from "interview_template". */
export type Interview_Template_Order_By = {
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interview_template_review_metric_questions_aggregate?: InputMaybe<Interview_Template_Review_Metric_Question_Aggregate_Order_By>;
  interview_type?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  template_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: interview_template */
export type Interview_Template_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** columns and relationships of "interview_template_review_metric_question" */
export type Interview_Template_Review_Metric_Question = {
  __typename?: "interview_template_review_metric_question";
  id: Scalars["bigint"];
  /** An object relationship */
  interview_template: Interview_Template;
  interview_template_id: Scalars["Int"];
  max_duration?: Maybe<Scalars["Int"]>;
  question_order: Scalars["Int"];
  /** An object relationship */
  review_metric_question: Review_Metric_Question;
  review_metric_question_id: Scalars["Int"];
  weight?: Maybe<Scalars["numeric"]>;
};

/** aggregated selection of "interview_template_review_metric_question" */
export type Interview_Template_Review_Metric_Question_Aggregate = {
  __typename?: "interview_template_review_metric_question_aggregate";
  aggregate?: Maybe<Interview_Template_Review_Metric_Question_Aggregate_Fields>;
  nodes: Array<Interview_Template_Review_Metric_Question>;
};

export type Interview_Template_Review_Metric_Question_Aggregate_Bool_Exp = {
  count?: InputMaybe<Interview_Template_Review_Metric_Question_Aggregate_Bool_Exp_Count>;
};

export type Interview_Template_Review_Metric_Question_Aggregate_Bool_Exp_Count =
  {
    arguments?: InputMaybe<
      Array<Interview_Template_Review_Metric_Question_Select_Column>
    >;
    distinct?: InputMaybe<Scalars["Boolean"]>;
    filter?: InputMaybe<Interview_Template_Review_Metric_Question_Bool_Exp>;
    predicate: Int_Comparison_Exp;
  };

/** aggregate fields of "interview_template_review_metric_question" */
export type Interview_Template_Review_Metric_Question_Aggregate_Fields = {
  __typename?: "interview_template_review_metric_question_aggregate_fields";
  avg?: Maybe<Interview_Template_Review_Metric_Question_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Interview_Template_Review_Metric_Question_Max_Fields>;
  min?: Maybe<Interview_Template_Review_Metric_Question_Min_Fields>;
  stddev?: Maybe<Interview_Template_Review_Metric_Question_Stddev_Fields>;
  stddev_pop?: Maybe<Interview_Template_Review_Metric_Question_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Interview_Template_Review_Metric_Question_Stddev_Samp_Fields>;
  sum?: Maybe<Interview_Template_Review_Metric_Question_Sum_Fields>;
  var_pop?: Maybe<Interview_Template_Review_Metric_Question_Var_Pop_Fields>;
  var_samp?: Maybe<Interview_Template_Review_Metric_Question_Var_Samp_Fields>;
  variance?: Maybe<Interview_Template_Review_Metric_Question_Variance_Fields>;
};

/** aggregate fields of "interview_template_review_metric_question" */
export type Interview_Template_Review_Metric_Question_Aggregate_FieldsCountArgs =
  {
    columns?: InputMaybe<
      Array<Interview_Template_Review_Metric_Question_Select_Column>
    >;
    distinct?: InputMaybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "interview_template_review_metric_question" */
export type Interview_Template_Review_Metric_Question_Aggregate_Order_By = {
  avg?: InputMaybe<Interview_Template_Review_Metric_Question_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Interview_Template_Review_Metric_Question_Max_Order_By>;
  min?: InputMaybe<Interview_Template_Review_Metric_Question_Min_Order_By>;
  stddev?: InputMaybe<Interview_Template_Review_Metric_Question_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Interview_Template_Review_Metric_Question_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Interview_Template_Review_Metric_Question_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Interview_Template_Review_Metric_Question_Sum_Order_By>;
  var_pop?: InputMaybe<Interview_Template_Review_Metric_Question_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Interview_Template_Review_Metric_Question_Var_Samp_Order_By>;
  variance?: InputMaybe<Interview_Template_Review_Metric_Question_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "interview_template_review_metric_question" */
export type Interview_Template_Review_Metric_Question_Arr_Rel_Insert_Input = {
  data: Array<Interview_Template_Review_Metric_Question_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Interview_Template_Review_Metric_Question_On_Conflict>;
};

/** aggregate avg on columns */
export type Interview_Template_Review_Metric_Question_Avg_Fields = {
  __typename?: "interview_template_review_metric_question_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  interview_template_id?: Maybe<Scalars["Float"]>;
  max_duration?: Maybe<Scalars["Float"]>;
  question_order?: Maybe<Scalars["Float"]>;
  review_metric_question_id?: Maybe<Scalars["Float"]>;
  weight?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "interview_template_review_metric_question" */
export type Interview_Template_Review_Metric_Question_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  interview_template_id?: InputMaybe<Order_By>;
  max_duration?: InputMaybe<Order_By>;
  question_order?: InputMaybe<Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "interview_template_review_metric_question". All fields are combined with a logical 'AND'. */
export type Interview_Template_Review_Metric_Question_Bool_Exp = {
  _and?: InputMaybe<Array<Interview_Template_Review_Metric_Question_Bool_Exp>>;
  _not?: InputMaybe<Interview_Template_Review_Metric_Question_Bool_Exp>;
  _or?: InputMaybe<Array<Interview_Template_Review_Metric_Question_Bool_Exp>>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  interview_template?: InputMaybe<Interview_Template_Bool_Exp>;
  interview_template_id?: InputMaybe<Int_Comparison_Exp>;
  max_duration?: InputMaybe<Int_Comparison_Exp>;
  question_order?: InputMaybe<Int_Comparison_Exp>;
  review_metric_question?: InputMaybe<Review_Metric_Question_Bool_Exp>;
  review_metric_question_id?: InputMaybe<Int_Comparison_Exp>;
  weight?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "interview_template_review_metric_question" */
export enum Interview_Template_Review_Metric_Question_Constraint {
  /** unique or primary key constraint on columns "id" */
  InterviewTemplateReviewMetricQuestionPkey = "interview_template_review_metric_question_pkey",
}

/** input type for incrementing numeric columns in table "interview_template_review_metric_question" */
export type Interview_Template_Review_Metric_Question_Inc_Input = {
  id?: InputMaybe<Scalars["bigint"]>;
  interview_template_id?: InputMaybe<Scalars["Int"]>;
  max_duration?: InputMaybe<Scalars["Int"]>;
  question_order?: InputMaybe<Scalars["Int"]>;
  review_metric_question_id?: InputMaybe<Scalars["Int"]>;
  weight?: InputMaybe<Scalars["numeric"]>;
};

/** input type for inserting data into table "interview_template_review_metric_question" */
export type Interview_Template_Review_Metric_Question_Insert_Input = {
  id?: InputMaybe<Scalars["bigint"]>;
  interview_template?: InputMaybe<Interview_Template_Obj_Rel_Insert_Input>;
  interview_template_id?: InputMaybe<Scalars["Int"]>;
  max_duration?: InputMaybe<Scalars["Int"]>;
  question_order?: InputMaybe<Scalars["Int"]>;
  review_metric_question?: InputMaybe<Review_Metric_Question_Obj_Rel_Insert_Input>;
  review_metric_question_id?: InputMaybe<Scalars["Int"]>;
  weight?: InputMaybe<Scalars["numeric"]>;
};

/** aggregate max on columns */
export type Interview_Template_Review_Metric_Question_Max_Fields = {
  __typename?: "interview_template_review_metric_question_max_fields";
  id?: Maybe<Scalars["bigint"]>;
  interview_template_id?: Maybe<Scalars["Int"]>;
  max_duration?: Maybe<Scalars["Int"]>;
  question_order?: Maybe<Scalars["Int"]>;
  review_metric_question_id?: Maybe<Scalars["Int"]>;
  weight?: Maybe<Scalars["numeric"]>;
};

/** order by max() on columns of table "interview_template_review_metric_question" */
export type Interview_Template_Review_Metric_Question_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  interview_template_id?: InputMaybe<Order_By>;
  max_duration?: InputMaybe<Order_By>;
  question_order?: InputMaybe<Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Interview_Template_Review_Metric_Question_Min_Fields = {
  __typename?: "interview_template_review_metric_question_min_fields";
  id?: Maybe<Scalars["bigint"]>;
  interview_template_id?: Maybe<Scalars["Int"]>;
  max_duration?: Maybe<Scalars["Int"]>;
  question_order?: Maybe<Scalars["Int"]>;
  review_metric_question_id?: Maybe<Scalars["Int"]>;
  weight?: Maybe<Scalars["numeric"]>;
};

/** order by min() on columns of table "interview_template_review_metric_question" */
export type Interview_Template_Review_Metric_Question_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  interview_template_id?: InputMaybe<Order_By>;
  max_duration?: InputMaybe<Order_By>;
  question_order?: InputMaybe<Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "interview_template_review_metric_question" */
export type Interview_Template_Review_Metric_Question_Mutation_Response = {
  __typename?: "interview_template_review_metric_question_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Interview_Template_Review_Metric_Question>;
};

/** on_conflict condition type for table "interview_template_review_metric_question" */
export type Interview_Template_Review_Metric_Question_On_Conflict = {
  constraint: Interview_Template_Review_Metric_Question_Constraint;
  update_columns?: Array<Interview_Template_Review_Metric_Question_Update_Column>;
  where?: InputMaybe<Interview_Template_Review_Metric_Question_Bool_Exp>;
};

/** Ordering options when selecting data from "interview_template_review_metric_question". */
export type Interview_Template_Review_Metric_Question_Order_By = {
  id?: InputMaybe<Order_By>;
  interview_template?: InputMaybe<Interview_Template_Order_By>;
  interview_template_id?: InputMaybe<Order_By>;
  max_duration?: InputMaybe<Order_By>;
  question_order?: InputMaybe<Order_By>;
  review_metric_question?: InputMaybe<Review_Metric_Question_Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** primary key columns input for table: interview_template_review_metric_question */
export type Interview_Template_Review_Metric_Question_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "interview_template_review_metric_question" */
export enum Interview_Template_Review_Metric_Question_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  InterviewTemplateId = "interview_template_id",
  /** column name */
  MaxDuration = "max_duration",
  /** column name */
  QuestionOrder = "question_order",
  /** column name */
  ReviewMetricQuestionId = "review_metric_question_id",
  /** column name */
  Weight = "weight",
}

/** input type for updating data in table "interview_template_review_metric_question" */
export type Interview_Template_Review_Metric_Question_Set_Input = {
  id?: InputMaybe<Scalars["bigint"]>;
  interview_template_id?: InputMaybe<Scalars["Int"]>;
  max_duration?: InputMaybe<Scalars["Int"]>;
  question_order?: InputMaybe<Scalars["Int"]>;
  review_metric_question_id?: InputMaybe<Scalars["Int"]>;
  weight?: InputMaybe<Scalars["numeric"]>;
};

/** aggregate stddev on columns */
export type Interview_Template_Review_Metric_Question_Stddev_Fields = {
  __typename?: "interview_template_review_metric_question_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  interview_template_id?: Maybe<Scalars["Float"]>;
  max_duration?: Maybe<Scalars["Float"]>;
  question_order?: Maybe<Scalars["Float"]>;
  review_metric_question_id?: Maybe<Scalars["Float"]>;
  weight?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "interview_template_review_metric_question" */
export type Interview_Template_Review_Metric_Question_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  interview_template_id?: InputMaybe<Order_By>;
  max_duration?: InputMaybe<Order_By>;
  question_order?: InputMaybe<Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Interview_Template_Review_Metric_Question_Stddev_Pop_Fields = {
  __typename?: "interview_template_review_metric_question_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  interview_template_id?: Maybe<Scalars["Float"]>;
  max_duration?: Maybe<Scalars["Float"]>;
  question_order?: Maybe<Scalars["Float"]>;
  review_metric_question_id?: Maybe<Scalars["Float"]>;
  weight?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "interview_template_review_metric_question" */
export type Interview_Template_Review_Metric_Question_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  interview_template_id?: InputMaybe<Order_By>;
  max_duration?: InputMaybe<Order_By>;
  question_order?: InputMaybe<Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Interview_Template_Review_Metric_Question_Stddev_Samp_Fields = {
  __typename?: "interview_template_review_metric_question_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  interview_template_id?: Maybe<Scalars["Float"]>;
  max_duration?: Maybe<Scalars["Float"]>;
  question_order?: Maybe<Scalars["Float"]>;
  review_metric_question_id?: Maybe<Scalars["Float"]>;
  weight?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "interview_template_review_metric_question" */
export type Interview_Template_Review_Metric_Question_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  interview_template_id?: InputMaybe<Order_By>;
  max_duration?: InputMaybe<Order_By>;
  question_order?: InputMaybe<Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "interview_template_review_metric_question" */
export type Interview_Template_Review_Metric_Question_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Interview_Template_Review_Metric_Question_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Interview_Template_Review_Metric_Question_Stream_Cursor_Value_Input =
  {
    id?: InputMaybe<Scalars["bigint"]>;
    interview_template_id?: InputMaybe<Scalars["Int"]>;
    max_duration?: InputMaybe<Scalars["Int"]>;
    question_order?: InputMaybe<Scalars["Int"]>;
    review_metric_question_id?: InputMaybe<Scalars["Int"]>;
    weight?: InputMaybe<Scalars["numeric"]>;
  };

/** aggregate sum on columns */
export type Interview_Template_Review_Metric_Question_Sum_Fields = {
  __typename?: "interview_template_review_metric_question_sum_fields";
  id?: Maybe<Scalars["bigint"]>;
  interview_template_id?: Maybe<Scalars["Int"]>;
  max_duration?: Maybe<Scalars["Int"]>;
  question_order?: Maybe<Scalars["Int"]>;
  review_metric_question_id?: Maybe<Scalars["Int"]>;
  weight?: Maybe<Scalars["numeric"]>;
};

/** order by sum() on columns of table "interview_template_review_metric_question" */
export type Interview_Template_Review_Metric_Question_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  interview_template_id?: InputMaybe<Order_By>;
  max_duration?: InputMaybe<Order_By>;
  question_order?: InputMaybe<Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** update columns of table "interview_template_review_metric_question" */
export enum Interview_Template_Review_Metric_Question_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  InterviewTemplateId = "interview_template_id",
  /** column name */
  MaxDuration = "max_duration",
  /** column name */
  QuestionOrder = "question_order",
  /** column name */
  ReviewMetricQuestionId = "review_metric_question_id",
  /** column name */
  Weight = "weight",
}

export type Interview_Template_Review_Metric_Question_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Interview_Template_Review_Metric_Question_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Interview_Template_Review_Metric_Question_Set_Input>;
  /** filter the rows which have to be updated */
  where: Interview_Template_Review_Metric_Question_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Interview_Template_Review_Metric_Question_Var_Pop_Fields = {
  __typename?: "interview_template_review_metric_question_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  interview_template_id?: Maybe<Scalars["Float"]>;
  max_duration?: Maybe<Scalars["Float"]>;
  question_order?: Maybe<Scalars["Float"]>;
  review_metric_question_id?: Maybe<Scalars["Float"]>;
  weight?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "interview_template_review_metric_question" */
export type Interview_Template_Review_Metric_Question_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  interview_template_id?: InputMaybe<Order_By>;
  max_duration?: InputMaybe<Order_By>;
  question_order?: InputMaybe<Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Interview_Template_Review_Metric_Question_Var_Samp_Fields = {
  __typename?: "interview_template_review_metric_question_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  interview_template_id?: Maybe<Scalars["Float"]>;
  max_duration?: Maybe<Scalars["Float"]>;
  question_order?: Maybe<Scalars["Float"]>;
  review_metric_question_id?: Maybe<Scalars["Float"]>;
  weight?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "interview_template_review_metric_question" */
export type Interview_Template_Review_Metric_Question_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  interview_template_id?: InputMaybe<Order_By>;
  max_duration?: InputMaybe<Order_By>;
  question_order?: InputMaybe<Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Interview_Template_Review_Metric_Question_Variance_Fields = {
  __typename?: "interview_template_review_metric_question_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  interview_template_id?: Maybe<Scalars["Float"]>;
  max_duration?: Maybe<Scalars["Float"]>;
  question_order?: Maybe<Scalars["Float"]>;
  review_metric_question_id?: Maybe<Scalars["Float"]>;
  weight?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "interview_template_review_metric_question" */
export type Interview_Template_Review_Metric_Question_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  interview_template_id?: InputMaybe<Order_By>;
  max_duration?: InputMaybe<Order_By>;
  question_order?: InputMaybe<Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
};

/** select columns of table "interview_template" */
export enum Interview_Template_Select_Column {
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InterviewType = "interview_type",
  /** column name */
  RoleId = "role_id",
  /** column name */
  TemplateName = "template_name",
}

/** input type for updating data in table "interview_template" */
export type Interview_Template_Set_Input = {
  company_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["Int"]>;
  interview_type?: InputMaybe<Video_Interview_Type_Enum>;
  role_id?: InputMaybe<Scalars["uuid"]>;
  template_name?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Interview_Template_Stddev_Fields = {
  __typename?: "interview_template_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Interview_Template_Stddev_Pop_Fields = {
  __typename?: "interview_template_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Interview_Template_Stddev_Samp_Fields = {
  __typename?: "interview_template_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "interview_template" */
export type Interview_Template_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Interview_Template_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Interview_Template_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["Int"]>;
  interview_type?: InputMaybe<Video_Interview_Type_Enum>;
  role_id?: InputMaybe<Scalars["uuid"]>;
  template_name?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Interview_Template_Sum_Fields = {
  __typename?: "interview_template_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "interview_template" */
export enum Interview_Template_Update_Column {
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InterviewType = "interview_type",
  /** column name */
  RoleId = "role_id",
  /** column name */
  TemplateName = "template_name",
}

export type Interview_Template_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Interview_Template_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Interview_Template_Set_Input>;
  /** filter the rows which have to be updated */
  where: Interview_Template_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Interview_Template_Var_Pop_Fields = {
  __typename?: "interview_template_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Interview_Template_Var_Samp_Fields = {
  __typename?: "interview_template_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Interview_Template_Variance_Fields = {
  __typename?: "interview_template_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "job_description" */
export type Job_Description = {
  __typename?: "job_description";
  academic_department?: Maybe<Scalars["jsonb"]>;
  created_at: Scalars["timestamptz"];
  created_by: Scalars["uuid"];
  id: Scalars["uuid"];
  job_description_about?: Maybe<Scalars["jsonb"]>;
  job_description_benefits?: Maybe<Scalars["jsonb"]>;
  job_description_company_name?: Maybe<Scalars["String"]>;
  job_description_intro?: Maybe<Scalars["jsonb"]>;
  job_description_requirements?: Maybe<Scalars["jsonb"]>;
  job_description_responsibilities?: Maybe<Scalars["jsonb"]>;
  job_description_skills?: Maybe<Scalars["jsonb"]>;
  job_environment?: Maybe<Scalars["jsonb"]>;
  job_experience?: Maybe<Scalars["jsonb"]>;
  job_type?: Maybe<Scalars["jsonb"]>;
  /** An object relationship */
  owner: User;
  /** An object relationship */
  project: Project;
  project_id: Scalars["uuid"];
  /** An object relationship */
  role?: Maybe<Role>;
  role_id?: Maybe<Scalars["uuid"]>;
  salary?: Maybe<Scalars["jsonb"]>;
  sections?: Maybe<Scalars["jsonb"]>;
  share_id?: Maybe<Scalars["String"]>;
  status: Scalars["String"];
  step: Job_Description_Step_Enum;
  title: Scalars["String"];
  working_hours?: Maybe<Scalars["jsonb"]>;
};

/** columns and relationships of "job_description" */
export type Job_DescriptionAcademic_DepartmentArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "job_description" */
export type Job_DescriptionJob_Description_AboutArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "job_description" */
export type Job_DescriptionJob_Description_BenefitsArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "job_description" */
export type Job_DescriptionJob_Description_IntroArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "job_description" */
export type Job_DescriptionJob_Description_RequirementsArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "job_description" */
export type Job_DescriptionJob_Description_ResponsibilitiesArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "job_description" */
export type Job_DescriptionJob_Description_SkillsArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "job_description" */
export type Job_DescriptionJob_EnvironmentArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "job_description" */
export type Job_DescriptionJob_ExperienceArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "job_description" */
export type Job_DescriptionJob_TypeArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "job_description" */
export type Job_DescriptionSalaryArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "job_description" */
export type Job_DescriptionSectionsArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "job_description" */
export type Job_DescriptionWorking_HoursArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** aggregated selection of "job_description" */
export type Job_Description_Aggregate = {
  __typename?: "job_description_aggregate";
  aggregate?: Maybe<Job_Description_Aggregate_Fields>;
  nodes: Array<Job_Description>;
};

export type Job_Description_Aggregate_Bool_Exp = {
  count?: InputMaybe<Job_Description_Aggregate_Bool_Exp_Count>;
};

export type Job_Description_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Job_Description_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Job_Description_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "job_description" */
export type Job_Description_Aggregate_Fields = {
  __typename?: "job_description_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Job_Description_Max_Fields>;
  min?: Maybe<Job_Description_Min_Fields>;
};

/** aggregate fields of "job_description" */
export type Job_Description_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Job_Description_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "job_description" */
export type Job_Description_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Job_Description_Max_Order_By>;
  min?: InputMaybe<Job_Description_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Job_Description_Append_Input = {
  academic_department?: InputMaybe<Scalars["jsonb"]>;
  job_description_about?: InputMaybe<Scalars["jsonb"]>;
  job_description_benefits?: InputMaybe<Scalars["jsonb"]>;
  job_description_intro?: InputMaybe<Scalars["jsonb"]>;
  job_description_requirements?: InputMaybe<Scalars["jsonb"]>;
  job_description_responsibilities?: InputMaybe<Scalars["jsonb"]>;
  job_description_skills?: InputMaybe<Scalars["jsonb"]>;
  job_environment?: InputMaybe<Scalars["jsonb"]>;
  job_experience?: InputMaybe<Scalars["jsonb"]>;
  job_type?: InputMaybe<Scalars["jsonb"]>;
  salary?: InputMaybe<Scalars["jsonb"]>;
  sections?: InputMaybe<Scalars["jsonb"]>;
  working_hours?: InputMaybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "job_description" */
export type Job_Description_Arr_Rel_Insert_Input = {
  data: Array<Job_Description_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Job_Description_On_Conflict>;
};

/** Boolean expression to filter rows from the table "job_description". All fields are combined with a logical 'AND'. */
export type Job_Description_Bool_Exp = {
  _and?: InputMaybe<Array<Job_Description_Bool_Exp>>;
  _not?: InputMaybe<Job_Description_Bool_Exp>;
  _or?: InputMaybe<Array<Job_Description_Bool_Exp>>;
  academic_department?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  job_description_about?: InputMaybe<Jsonb_Comparison_Exp>;
  job_description_benefits?: InputMaybe<Jsonb_Comparison_Exp>;
  job_description_company_name?: InputMaybe<String_Comparison_Exp>;
  job_description_intro?: InputMaybe<Jsonb_Comparison_Exp>;
  job_description_requirements?: InputMaybe<Jsonb_Comparison_Exp>;
  job_description_responsibilities?: InputMaybe<Jsonb_Comparison_Exp>;
  job_description_skills?: InputMaybe<Jsonb_Comparison_Exp>;
  job_environment?: InputMaybe<Jsonb_Comparison_Exp>;
  job_experience?: InputMaybe<Jsonb_Comparison_Exp>;
  job_type?: InputMaybe<Jsonb_Comparison_Exp>;
  owner?: InputMaybe<User_Bool_Exp>;
  project?: InputMaybe<Project_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  role?: InputMaybe<Role_Bool_Exp>;
  role_id?: InputMaybe<Uuid_Comparison_Exp>;
  salary?: InputMaybe<Jsonb_Comparison_Exp>;
  sections?: InputMaybe<Jsonb_Comparison_Exp>;
  share_id?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  step?: InputMaybe<Job_Description_Step_Enum_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  working_hours?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "job_description" */
export enum Job_Description_Constraint {
  /** unique or primary key constraint on columns "id" */
  JobDescriptionPkey = "job_description_pkey",
  /** unique or primary key constraint on columns "share_id" */
  JobDescriptionShareIdKey = "job_description_share_id_key",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Job_Description_Delete_At_Path_Input = {
  academic_department?: InputMaybe<Array<Scalars["String"]>>;
  job_description_about?: InputMaybe<Array<Scalars["String"]>>;
  job_description_benefits?: InputMaybe<Array<Scalars["String"]>>;
  job_description_intro?: InputMaybe<Array<Scalars["String"]>>;
  job_description_requirements?: InputMaybe<Array<Scalars["String"]>>;
  job_description_responsibilities?: InputMaybe<Array<Scalars["String"]>>;
  job_description_skills?: InputMaybe<Array<Scalars["String"]>>;
  job_environment?: InputMaybe<Array<Scalars["String"]>>;
  job_experience?: InputMaybe<Array<Scalars["String"]>>;
  job_type?: InputMaybe<Array<Scalars["String"]>>;
  salary?: InputMaybe<Array<Scalars["String"]>>;
  sections?: InputMaybe<Array<Scalars["String"]>>;
  working_hours?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Job_Description_Delete_Elem_Input = {
  academic_department?: InputMaybe<Scalars["Int"]>;
  job_description_about?: InputMaybe<Scalars["Int"]>;
  job_description_benefits?: InputMaybe<Scalars["Int"]>;
  job_description_intro?: InputMaybe<Scalars["Int"]>;
  job_description_requirements?: InputMaybe<Scalars["Int"]>;
  job_description_responsibilities?: InputMaybe<Scalars["Int"]>;
  job_description_skills?: InputMaybe<Scalars["Int"]>;
  job_environment?: InputMaybe<Scalars["Int"]>;
  job_experience?: InputMaybe<Scalars["Int"]>;
  job_type?: InputMaybe<Scalars["Int"]>;
  salary?: InputMaybe<Scalars["Int"]>;
  sections?: InputMaybe<Scalars["Int"]>;
  working_hours?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Job_Description_Delete_Key_Input = {
  academic_department?: InputMaybe<Scalars["String"]>;
  job_description_about?: InputMaybe<Scalars["String"]>;
  job_description_benefits?: InputMaybe<Scalars["String"]>;
  job_description_intro?: InputMaybe<Scalars["String"]>;
  job_description_requirements?: InputMaybe<Scalars["String"]>;
  job_description_responsibilities?: InputMaybe<Scalars["String"]>;
  job_description_skills?: InputMaybe<Scalars["String"]>;
  job_environment?: InputMaybe<Scalars["String"]>;
  job_experience?: InputMaybe<Scalars["String"]>;
  job_type?: InputMaybe<Scalars["String"]>;
  salary?: InputMaybe<Scalars["String"]>;
  sections?: InputMaybe<Scalars["String"]>;
  working_hours?: InputMaybe<Scalars["String"]>;
};

/** input type for inserting data into table "job_description" */
export type Job_Description_Insert_Input = {
  academic_department?: InputMaybe<Scalars["jsonb"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  created_by?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  job_description_about?: InputMaybe<Scalars["jsonb"]>;
  job_description_benefits?: InputMaybe<Scalars["jsonb"]>;
  job_description_company_name?: InputMaybe<Scalars["String"]>;
  job_description_intro?: InputMaybe<Scalars["jsonb"]>;
  job_description_requirements?: InputMaybe<Scalars["jsonb"]>;
  job_description_responsibilities?: InputMaybe<Scalars["jsonb"]>;
  job_description_skills?: InputMaybe<Scalars["jsonb"]>;
  job_environment?: InputMaybe<Scalars["jsonb"]>;
  job_experience?: InputMaybe<Scalars["jsonb"]>;
  job_type?: InputMaybe<Scalars["jsonb"]>;
  owner?: InputMaybe<User_Obj_Rel_Insert_Input>;
  project?: InputMaybe<Project_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars["uuid"]>;
  role?: InputMaybe<Role_Obj_Rel_Insert_Input>;
  role_id?: InputMaybe<Scalars["uuid"]>;
  salary?: InputMaybe<Scalars["jsonb"]>;
  sections?: InputMaybe<Scalars["jsonb"]>;
  share_id?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  step?: InputMaybe<Job_Description_Step_Enum>;
  title?: InputMaybe<Scalars["String"]>;
  working_hours?: InputMaybe<Scalars["jsonb"]>;
};

/** aggregate max on columns */
export type Job_Description_Max_Fields = {
  __typename?: "job_description_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  job_description_company_name?: Maybe<Scalars["String"]>;
  project_id?: Maybe<Scalars["uuid"]>;
  role_id?: Maybe<Scalars["uuid"]>;
  share_id?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "job_description" */
export type Job_Description_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_description_company_name?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  share_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Job_Description_Min_Fields = {
  __typename?: "job_description_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  job_description_company_name?: Maybe<Scalars["String"]>;
  project_id?: Maybe<Scalars["uuid"]>;
  role_id?: Maybe<Scalars["uuid"]>;
  share_id?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "job_description" */
export type Job_Description_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_description_company_name?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  share_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "job_description" */
export type Job_Description_Mutation_Response = {
  __typename?: "job_description_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Job_Description>;
};

/** on_conflict condition type for table "job_description" */
export type Job_Description_On_Conflict = {
  constraint: Job_Description_Constraint;
  update_columns?: Array<Job_Description_Update_Column>;
  where?: InputMaybe<Job_Description_Bool_Exp>;
};

/** Ordering options when selecting data from "job_description". */
export type Job_Description_Order_By = {
  academic_department?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_description_about?: InputMaybe<Order_By>;
  job_description_benefits?: InputMaybe<Order_By>;
  job_description_company_name?: InputMaybe<Order_By>;
  job_description_intro?: InputMaybe<Order_By>;
  job_description_requirements?: InputMaybe<Order_By>;
  job_description_responsibilities?: InputMaybe<Order_By>;
  job_description_skills?: InputMaybe<Order_By>;
  job_environment?: InputMaybe<Order_By>;
  job_experience?: InputMaybe<Order_By>;
  job_type?: InputMaybe<Order_By>;
  owner?: InputMaybe<User_Order_By>;
  project?: InputMaybe<Project_Order_By>;
  project_id?: InputMaybe<Order_By>;
  role?: InputMaybe<Role_Order_By>;
  role_id?: InputMaybe<Order_By>;
  salary?: InputMaybe<Order_By>;
  sections?: InputMaybe<Order_By>;
  share_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  step?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  working_hours?: InputMaybe<Order_By>;
};

/** primary key columns input for table: job_description */
export type Job_Description_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Job_Description_Prepend_Input = {
  academic_department?: InputMaybe<Scalars["jsonb"]>;
  job_description_about?: InputMaybe<Scalars["jsonb"]>;
  job_description_benefits?: InputMaybe<Scalars["jsonb"]>;
  job_description_intro?: InputMaybe<Scalars["jsonb"]>;
  job_description_requirements?: InputMaybe<Scalars["jsonb"]>;
  job_description_responsibilities?: InputMaybe<Scalars["jsonb"]>;
  job_description_skills?: InputMaybe<Scalars["jsonb"]>;
  job_environment?: InputMaybe<Scalars["jsonb"]>;
  job_experience?: InputMaybe<Scalars["jsonb"]>;
  job_type?: InputMaybe<Scalars["jsonb"]>;
  salary?: InputMaybe<Scalars["jsonb"]>;
  sections?: InputMaybe<Scalars["jsonb"]>;
  working_hours?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "job_description" */
export enum Job_Description_Select_Column {
  /** column name */
  AcademicDepartment = "academic_department",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CreatedBy = "created_by",
  /** column name */
  Id = "id",
  /** column name */
  JobDescriptionAbout = "job_description_about",
  /** column name */
  JobDescriptionBenefits = "job_description_benefits",
  /** column name */
  JobDescriptionCompanyName = "job_description_company_name",
  /** column name */
  JobDescriptionIntro = "job_description_intro",
  /** column name */
  JobDescriptionRequirements = "job_description_requirements",
  /** column name */
  JobDescriptionResponsibilities = "job_description_responsibilities",
  /** column name */
  JobDescriptionSkills = "job_description_skills",
  /** column name */
  JobEnvironment = "job_environment",
  /** column name */
  JobExperience = "job_experience",
  /** column name */
  JobType = "job_type",
  /** column name */
  ProjectId = "project_id",
  /** column name */
  RoleId = "role_id",
  /** column name */
  Salary = "salary",
  /** column name */
  Sections = "sections",
  /** column name */
  ShareId = "share_id",
  /** column name */
  Status = "status",
  /** column name */
  Step = "step",
  /** column name */
  Title = "title",
  /** column name */
  WorkingHours = "working_hours",
}

/** input type for updating data in table "job_description" */
export type Job_Description_Set_Input = {
  academic_department?: InputMaybe<Scalars["jsonb"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  created_by?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  job_description_about?: InputMaybe<Scalars["jsonb"]>;
  job_description_benefits?: InputMaybe<Scalars["jsonb"]>;
  job_description_company_name?: InputMaybe<Scalars["String"]>;
  job_description_intro?: InputMaybe<Scalars["jsonb"]>;
  job_description_requirements?: InputMaybe<Scalars["jsonb"]>;
  job_description_responsibilities?: InputMaybe<Scalars["jsonb"]>;
  job_description_skills?: InputMaybe<Scalars["jsonb"]>;
  job_environment?: InputMaybe<Scalars["jsonb"]>;
  job_experience?: InputMaybe<Scalars["jsonb"]>;
  job_type?: InputMaybe<Scalars["jsonb"]>;
  project_id?: InputMaybe<Scalars["uuid"]>;
  role_id?: InputMaybe<Scalars["uuid"]>;
  salary?: InputMaybe<Scalars["jsonb"]>;
  sections?: InputMaybe<Scalars["jsonb"]>;
  share_id?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  step?: InputMaybe<Job_Description_Step_Enum>;
  title?: InputMaybe<Scalars["String"]>;
  working_hours?: InputMaybe<Scalars["jsonb"]>;
};

/** columns and relationships of "job_description_step" */
export type Job_Description_Step = {
  __typename?: "job_description_step";
  step: Scalars["String"];
};

/** aggregated selection of "job_description_step" */
export type Job_Description_Step_Aggregate = {
  __typename?: "job_description_step_aggregate";
  aggregate?: Maybe<Job_Description_Step_Aggregate_Fields>;
  nodes: Array<Job_Description_Step>;
};

/** aggregate fields of "job_description_step" */
export type Job_Description_Step_Aggregate_Fields = {
  __typename?: "job_description_step_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Job_Description_Step_Max_Fields>;
  min?: Maybe<Job_Description_Step_Min_Fields>;
};

/** aggregate fields of "job_description_step" */
export type Job_Description_Step_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Job_Description_Step_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "job_description_step". All fields are combined with a logical 'AND'. */
export type Job_Description_Step_Bool_Exp = {
  _and?: InputMaybe<Array<Job_Description_Step_Bool_Exp>>;
  _not?: InputMaybe<Job_Description_Step_Bool_Exp>;
  _or?: InputMaybe<Array<Job_Description_Step_Bool_Exp>>;
  step?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "job_description_step" */
export enum Job_Description_Step_Constraint {
  /** unique or primary key constraint on columns "step" */
  JobDescriptionStepPkey = "job_description_step_pkey",
}

export enum Job_Description_Step_Enum {
  Completed = "COMPLETED",
  GenerateJd = "GENERATE_JD",
  SelectBenefits = "SELECT_BENEFITS",
  SelectEducation = "SELECT_EDUCATION",
  SelectExperience = "SELECT_EXPERIENCE",
  SelectIntroduction = "SELECT_INTRODUCTION",
  SelectJobEnvironment = "SELECT_JOB_ENVIRONMENT",
  SelectRequirements = "SELECT_REQUIREMENTS",
  SelectResponsibilities = "SELECT_RESPONSIBILITIES",
  SelectSalary = "SELECT_SALARY",
  SelectSkill = "SELECT_SKILL",
  SkillsSelected = "SKILLS_SELECTED",
}

/** Boolean expression to compare columns of type "job_description_step_enum". All fields are combined with logical 'AND'. */
export type Job_Description_Step_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Job_Description_Step_Enum>;
  _in?: InputMaybe<Array<Job_Description_Step_Enum>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _neq?: InputMaybe<Job_Description_Step_Enum>;
  _nin?: InputMaybe<Array<Job_Description_Step_Enum>>;
};

/** input type for inserting data into table "job_description_step" */
export type Job_Description_Step_Insert_Input = {
  step?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Job_Description_Step_Max_Fields = {
  __typename?: "job_description_step_max_fields";
  step?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Job_Description_Step_Min_Fields = {
  __typename?: "job_description_step_min_fields";
  step?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "job_description_step" */
export type Job_Description_Step_Mutation_Response = {
  __typename?: "job_description_step_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Job_Description_Step>;
};

/** on_conflict condition type for table "job_description_step" */
export type Job_Description_Step_On_Conflict = {
  constraint: Job_Description_Step_Constraint;
  update_columns?: Array<Job_Description_Step_Update_Column>;
  where?: InputMaybe<Job_Description_Step_Bool_Exp>;
};

/** Ordering options when selecting data from "job_description_step". */
export type Job_Description_Step_Order_By = {
  step?: InputMaybe<Order_By>;
};

/** primary key columns input for table: job_description_step */
export type Job_Description_Step_Pk_Columns_Input = {
  step: Scalars["String"];
};

/** select columns of table "job_description_step" */
export enum Job_Description_Step_Select_Column {
  /** column name */
  Step = "step",
}

/** input type for updating data in table "job_description_step" */
export type Job_Description_Step_Set_Input = {
  step?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "job_description_step" */
export type Job_Description_Step_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Job_Description_Step_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Job_Description_Step_Stream_Cursor_Value_Input = {
  step?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "job_description_step" */
export enum Job_Description_Step_Update_Column {
  /** column name */
  Step = "step",
}

export type Job_Description_Step_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Job_Description_Step_Set_Input>;
  /** filter the rows which have to be updated */
  where: Job_Description_Step_Bool_Exp;
};

/** Streaming cursor of the table "job_description" */
export type Job_Description_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Job_Description_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Job_Description_Stream_Cursor_Value_Input = {
  academic_department?: InputMaybe<Scalars["jsonb"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  created_by?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  job_description_about?: InputMaybe<Scalars["jsonb"]>;
  job_description_benefits?: InputMaybe<Scalars["jsonb"]>;
  job_description_company_name?: InputMaybe<Scalars["String"]>;
  job_description_intro?: InputMaybe<Scalars["jsonb"]>;
  job_description_requirements?: InputMaybe<Scalars["jsonb"]>;
  job_description_responsibilities?: InputMaybe<Scalars["jsonb"]>;
  job_description_skills?: InputMaybe<Scalars["jsonb"]>;
  job_environment?: InputMaybe<Scalars["jsonb"]>;
  job_experience?: InputMaybe<Scalars["jsonb"]>;
  job_type?: InputMaybe<Scalars["jsonb"]>;
  project_id?: InputMaybe<Scalars["uuid"]>;
  role_id?: InputMaybe<Scalars["uuid"]>;
  salary?: InputMaybe<Scalars["jsonb"]>;
  sections?: InputMaybe<Scalars["jsonb"]>;
  share_id?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  step?: InputMaybe<Job_Description_Step_Enum>;
  title?: InputMaybe<Scalars["String"]>;
  working_hours?: InputMaybe<Scalars["jsonb"]>;
};

/** update columns of table "job_description" */
export enum Job_Description_Update_Column {
  /** column name */
  AcademicDepartment = "academic_department",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CreatedBy = "created_by",
  /** column name */
  Id = "id",
  /** column name */
  JobDescriptionAbout = "job_description_about",
  /** column name */
  JobDescriptionBenefits = "job_description_benefits",
  /** column name */
  JobDescriptionCompanyName = "job_description_company_name",
  /** column name */
  JobDescriptionIntro = "job_description_intro",
  /** column name */
  JobDescriptionRequirements = "job_description_requirements",
  /** column name */
  JobDescriptionResponsibilities = "job_description_responsibilities",
  /** column name */
  JobDescriptionSkills = "job_description_skills",
  /** column name */
  JobEnvironment = "job_environment",
  /** column name */
  JobExperience = "job_experience",
  /** column name */
  JobType = "job_type",
  /** column name */
  ProjectId = "project_id",
  /** column name */
  RoleId = "role_id",
  /** column name */
  Salary = "salary",
  /** column name */
  Sections = "sections",
  /** column name */
  ShareId = "share_id",
  /** column name */
  Status = "status",
  /** column name */
  Step = "step",
  /** column name */
  Title = "title",
  /** column name */
  WorkingHours = "working_hours",
}

export type Job_Description_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Job_Description_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Job_Description_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Job_Description_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Job_Description_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Job_Description_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Job_Description_Set_Input>;
  /** filter the rows which have to be updated */
  where: Job_Description_Bool_Exp;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars["jsonb"]>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars["jsonb"]>;
  _eq?: InputMaybe<Scalars["jsonb"]>;
  _gt?: InputMaybe<Scalars["jsonb"]>;
  _gte?: InputMaybe<Scalars["jsonb"]>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars["String"]>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars["String"]>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars["String"]>>;
  _in?: InputMaybe<Array<Scalars["jsonb"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["jsonb"]>;
  _lte?: InputMaybe<Scalars["jsonb"]>;
  _neq?: InputMaybe<Scalars["jsonb"]>;
  _nin?: InputMaybe<Array<Scalars["jsonb"]>>;
};

/** columns and relationships of "language" */
export type Language = {
  __typename?: "language";
  language: Scalars["String"];
};

/** aggregated selection of "language" */
export type Language_Aggregate = {
  __typename?: "language_aggregate";
  aggregate?: Maybe<Language_Aggregate_Fields>;
  nodes: Array<Language>;
};

/** aggregate fields of "language" */
export type Language_Aggregate_Fields = {
  __typename?: "language_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Language_Max_Fields>;
  min?: Maybe<Language_Min_Fields>;
};

/** aggregate fields of "language" */
export type Language_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Language_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "language". All fields are combined with a logical 'AND'. */
export type Language_Bool_Exp = {
  _and?: InputMaybe<Array<Language_Bool_Exp>>;
  _not?: InputMaybe<Language_Bool_Exp>;
  _or?: InputMaybe<Array<Language_Bool_Exp>>;
  language?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "language" */
export enum Language_Constraint {
  /** unique or primary key constraint on columns "language" */
  LanguagePkey = "language_pkey",
}

export enum Language_Enum {
  En = "en",
  Tr = "tr",
}

/** Boolean expression to compare columns of type "language_enum". All fields are combined with logical 'AND'. */
export type Language_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Language_Enum>;
  _in?: InputMaybe<Array<Language_Enum>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _neq?: InputMaybe<Language_Enum>;
  _nin?: InputMaybe<Array<Language_Enum>>;
};

/** input type for inserting data into table "language" */
export type Language_Insert_Input = {
  language?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Language_Max_Fields = {
  __typename?: "language_max_fields";
  language?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Language_Min_Fields = {
  __typename?: "language_min_fields";
  language?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "language" */
export type Language_Mutation_Response = {
  __typename?: "language_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Language>;
};

/** input type for inserting object relation for remote table "language" */
export type Language_Obj_Rel_Insert_Input = {
  data: Language_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Language_On_Conflict>;
};

/** on_conflict condition type for table "language" */
export type Language_On_Conflict = {
  constraint: Language_Constraint;
  update_columns?: Array<Language_Update_Column>;
  where?: InputMaybe<Language_Bool_Exp>;
};

/** Ordering options when selecting data from "language". */
export type Language_Order_By = {
  language?: InputMaybe<Order_By>;
};

/** primary key columns input for table: language */
export type Language_Pk_Columns_Input = {
  language: Scalars["String"];
};

/** select columns of table "language" */
export enum Language_Select_Column {
  /** column name */
  Language = "language",
}

/** input type for updating data in table "language" */
export type Language_Set_Input = {
  language?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "language" */
export type Language_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Language_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Language_Stream_Cursor_Value_Input = {
  language?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "language" */
export enum Language_Update_Column {
  /** column name */
  Language = "language",
}

export type Language_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Language_Set_Input>;
  /** filter the rows which have to be updated */
  where: Language_Bool_Exp;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: "mutation_root";
  /** Candidate does not want ai review */
  addCandidateDoesNotWantAiReview?: Maybe<CommonActionOutput>;
  /** Candidate is not interested interview */
  addCandidateNotInterestedStatus?: Maybe<CommonActionOutput>;
  addConsent?: Maybe<CommonActionOutput>;
  applyJob?: Maybe<CommonActionOutput>;
  /** Change Password */
  changePassword?: Maybe<CommonActionOutput>;
  /** createCompany */
  createCompany?: Maybe<CommonActionOutput>;
  /** delete data from the table: "academic_department" */
  delete_academic_department?: Maybe<Academic_Department_Mutation_Response>;
  /** delete single row from the table: "academic_department" */
  delete_academic_department_by_pk?: Maybe<Academic_Department>;
  /** delete data from the table: "address_type" */
  delete_address_type?: Maybe<Address_Type_Mutation_Response>;
  /** delete single row from the table: "address_type" */
  delete_address_type_by_pk?: Maybe<Address_Type>;
  /** delete data from the table: "city" */
  delete_city?: Maybe<City_Mutation_Response>;
  /** delete single row from the table: "city" */
  delete_city_by_pk?: Maybe<City>;
  /** delete data from the table: "client" */
  delete_client?: Maybe<Client_Mutation_Response>;
  /** delete single row from the table: "client" */
  delete_client_by_pk?: Maybe<Client>;
  /** delete data from the table: "client_candidate" */
  delete_client_candidate?: Maybe<Client_Candidate_Mutation_Response>;
  /** delete single row from the table: "client_candidate" */
  delete_client_candidate_by_pk?: Maybe<Client_Candidate>;
  /** delete data from the table: "client_candidate_project" */
  delete_client_candidate_project?: Maybe<Client_Candidate_Project_Mutation_Response>;
  /** delete single row from the table: "client_candidate_project" */
  delete_client_candidate_project_by_pk?: Maybe<Client_Candidate_Project>;
  /** delete data from the table: "client_candidate_project_candidate_list_status" */
  delete_client_candidate_project_candidate_list_status?: Maybe<Client_Candidate_Project_Candidate_List_Status_Mutation_Response>;
  /** delete single row from the table: "client_candidate_project_candidate_list_status" */
  delete_client_candidate_project_candidate_list_status_by_pk?: Maybe<Client_Candidate_Project_Candidate_List_Status>;
  /** delete data from the table: "client_candidate_project_candidate_resolution" */
  delete_client_candidate_project_candidate_resolution?: Maybe<Client_Candidate_Project_Candidate_Resolution_Mutation_Response>;
  /** delete single row from the table: "client_candidate_project_candidate_resolution" */
  delete_client_candidate_project_candidate_resolution_by_pk?: Maybe<Client_Candidate_Project_Candidate_Resolution>;
  /** delete data from the table: "client_candidate_project_note" */
  delete_client_candidate_project_note?: Maybe<Client_Candidate_Project_Note_Mutation_Response>;
  /** delete single row from the table: "client_candidate_project_note" */
  delete_client_candidate_project_note_by_pk?: Maybe<Client_Candidate_Project_Note>;
  /** delete data from the table: "client_location" */
  delete_client_location?: Maybe<Client_Location_Mutation_Response>;
  /** delete single row from the table: "client_location" */
  delete_client_location_by_pk?: Maybe<Client_Location>;
  /** delete data from the table: "client_sector" */
  delete_client_sector?: Maybe<Client_Sector_Mutation_Response>;
  /** delete single row from the table: "client_sector" */
  delete_client_sector_by_pk?: Maybe<Client_Sector>;
  /** delete data from the table: "client_setting" */
  delete_client_setting?: Maybe<Client_Setting_Mutation_Response>;
  /** delete single row from the table: "client_setting" */
  delete_client_setting_by_pk?: Maybe<Client_Setting>;
  /** delete data from the table: "client_setting_key" */
  delete_client_setting_key?: Maybe<Client_Setting_Key_Mutation_Response>;
  /** delete single row from the table: "client_setting_key" */
  delete_client_setting_key_by_pk?: Maybe<Client_Setting_Key>;
  /** delete data from the table: "company" */
  delete_company?: Maybe<Company_Mutation_Response>;
  /** delete single row from the table: "company" */
  delete_company_by_pk?: Maybe<Company>;
  /** delete data from the table: "company_subscription" */
  delete_company_subscription?: Maybe<Company_Subscription_Mutation_Response>;
  /** delete single row from the table: "company_subscription" */
  delete_company_subscription_by_pk?: Maybe<Company_Subscription>;
  /** delete data from the table: "consent" */
  delete_consent?: Maybe<Consent_Mutation_Response>;
  /** delete single row from the table: "consent" */
  delete_consent_by_pk?: Maybe<Consent>;
  /** delete data from the table: "consent_status" */
  delete_consent_status?: Maybe<Consent_Status_Mutation_Response>;
  /** delete single row from the table: "consent_status" */
  delete_consent_status_by_pk?: Maybe<Consent_Status>;
  /** delete data from the table: "consent_type" */
  delete_consent_type?: Maybe<Consent_Type_Mutation_Response>;
  /** delete single row from the table: "consent_type" */
  delete_consent_type_by_pk?: Maybe<Consent_Type>;
  /** delete data from the table: "country" */
  delete_country?: Maybe<Country_Mutation_Response>;
  /** delete single row from the table: "country" */
  delete_country_by_pk?: Maybe<Country>;
  /** delete data from the table: "currency" */
  delete_currency?: Maybe<Currency_Mutation_Response>;
  /** delete single row from the table: "currency" */
  delete_currency_by_pk?: Maybe<Currency>;
  /** delete data from the table: "department" */
  delete_department?: Maybe<Department_Mutation_Response>;
  /** delete single row from the table: "department" */
  delete_department_by_pk?: Maybe<Department>;
  /** delete data from the table: "education_type" */
  delete_education_type?: Maybe<Education_Type_Mutation_Response>;
  /** delete single row from the table: "education_type" */
  delete_education_type_by_pk?: Maybe<Education_Type>;
  /** delete data from the table: "interview_criterion" */
  delete_interview_criterion?: Maybe<Interview_Criterion_Mutation_Response>;
  /** delete single row from the table: "interview_criterion" */
  delete_interview_criterion_by_pk?: Maybe<Interview_Criterion>;
  /** delete data from the table: "interview_template" */
  delete_interview_template?: Maybe<Interview_Template_Mutation_Response>;
  /** delete single row from the table: "interview_template" */
  delete_interview_template_by_pk?: Maybe<Interview_Template>;
  /** delete data from the table: "interview_template_review_metric_question" */
  delete_interview_template_review_metric_question?: Maybe<Interview_Template_Review_Metric_Question_Mutation_Response>;
  /** delete single row from the table: "interview_template_review_metric_question" */
  delete_interview_template_review_metric_question_by_pk?: Maybe<Interview_Template_Review_Metric_Question>;
  /** delete data from the table: "job_description" */
  delete_job_description?: Maybe<Job_Description_Mutation_Response>;
  /** delete single row from the table: "job_description" */
  delete_job_description_by_pk?: Maybe<Job_Description>;
  /** delete data from the table: "job_description_step" */
  delete_job_description_step?: Maybe<Job_Description_Step_Mutation_Response>;
  /** delete single row from the table: "job_description_step" */
  delete_job_description_step_by_pk?: Maybe<Job_Description_Step>;
  /** delete data from the table: "language" */
  delete_language?: Maybe<Language_Mutation_Response>;
  /** delete single row from the table: "language" */
  delete_language_by_pk?: Maybe<Language>;
  /** delete data from the table: "partner" */
  delete_partner?: Maybe<Partner_Mutation_Response>;
  /** delete single row from the table: "partner" */
  delete_partner_by_pk?: Maybe<Partner>;
  /** delete data from the table: "product" */
  delete_product?: Maybe<Product_Mutation_Response>;
  /** delete single row from the table: "product" */
  delete_product_by_pk?: Maybe<Product>;
  /** delete data from the table: "product_price" */
  delete_product_price?: Maybe<Product_Price_Mutation_Response>;
  /** delete single row from the table: "product_price" */
  delete_product_price_by_pk?: Maybe<Product_Price>;
  /** delete data from the table: "project" */
  delete_project?: Maybe<Project_Mutation_Response>;
  /** delete single row from the table: "project" */
  delete_project_by_pk?: Maybe<Project>;
  /** delete data from the table: "project_status" */
  delete_project_status?: Maybe<Project_Status_Mutation_Response>;
  /** delete single row from the table: "project_status" */
  delete_project_status_by_pk?: Maybe<Project_Status>;
  /** delete data from the table: "project_user" */
  delete_project_user?: Maybe<Project_User_Mutation_Response>;
  /** delete single row from the table: "project_user" */
  delete_project_user_by_pk?: Maybe<Project_User>;
  /** delete data from the table: "review_metric" */
  delete_review_metric?: Maybe<Review_Metric_Mutation_Response>;
  /** delete single row from the table: "review_metric" */
  delete_review_metric_by_pk?: Maybe<Review_Metric>;
  /** delete data from the table: "review_metric_question" */
  delete_review_metric_question?: Maybe<Review_Metric_Question_Mutation_Response>;
  /** delete single row from the table: "review_metric_question" */
  delete_review_metric_question_by_pk?: Maybe<Review_Metric_Question>;
  /** delete data from the table: "review_metric_question_criterion" */
  delete_review_metric_question_criterion?: Maybe<Review_Metric_Question_Criterion_Mutation_Response>;
  /** delete single row from the table: "review_metric_question_criterion" */
  delete_review_metric_question_criterion_by_pk?: Maybe<Review_Metric_Question_Criterion>;
  /** delete data from the table: "review_metric_question_response" */
  delete_review_metric_question_response?: Maybe<Review_Metric_Question_Response_Mutation_Response>;
  /** delete single row from the table: "review_metric_question_response" */
  delete_review_metric_question_response_by_pk?: Maybe<Review_Metric_Question_Response>;
  /** delete data from the table: "review_metric_question_type" */
  delete_review_metric_question_type?: Maybe<Review_Metric_Question_Type_Mutation_Response>;
  /** delete single row from the table: "review_metric_question_type" */
  delete_review_metric_question_type_by_pk?: Maybe<Review_Metric_Question_Type>;
  /** delete data from the table: "role" */
  delete_role?: Maybe<Role_Mutation_Response>;
  /** delete data from the table: "role_academic_department" */
  delete_role_academic_department?: Maybe<Role_Academic_Department_Mutation_Response>;
  /** delete single row from the table: "role_academic_department" */
  delete_role_academic_department_by_pk?: Maybe<Role_Academic_Department>;
  /** delete data from the table: "role_benefit" */
  delete_role_benefit?: Maybe<Role_Benefit_Mutation_Response>;
  /** delete single row from the table: "role_benefit" */
  delete_role_benefit_by_pk?: Maybe<Role_Benefit>;
  /** delete single row from the table: "role" */
  delete_role_by_pk?: Maybe<Role>;
  /** delete data from the table: "role_competence" */
  delete_role_competence?: Maybe<Role_Competence_Mutation_Response>;
  /** delete single row from the table: "role_competence" */
  delete_role_competence_by_pk?: Maybe<Role_Competence>;
  /** delete data from the table: "role_description" */
  delete_role_description?: Maybe<Role_Description_Mutation_Response>;
  /** delete single row from the table: "role_description" */
  delete_role_description_by_pk?: Maybe<Role_Description>;
  /** delete data from the table: "role_responsibility" */
  delete_role_responsibility?: Maybe<Role_Responsibility_Mutation_Response>;
  /** delete single row from the table: "role_responsibility" */
  delete_role_responsibility_by_pk?: Maybe<Role_Responsibility>;
  /** delete data from the table: "role_review_metric" */
  delete_role_review_metric?: Maybe<Role_Review_Metric_Mutation_Response>;
  /** delete single row from the table: "role_review_metric" */
  delete_role_review_metric_by_pk?: Maybe<Role_Review_Metric>;
  /** delete data from the table: "role_skill" */
  delete_role_skill?: Maybe<Role_Skill_Mutation_Response>;
  /** delete single row from the table: "role_skill" */
  delete_role_skill_by_pk?: Maybe<Role_Skill>;
  /** delete data from the table: "sector" */
  delete_sector?: Maybe<Sector_Mutation_Response>;
  /** delete single row from the table: "sector" */
  delete_sector_by_pk?: Maybe<Sector>;
  /** delete data from the table: "user" */
  delete_user?: Maybe<User_Mutation_Response>;
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<User>;
  /** delete data from the table: "user_key" */
  delete_user_key?: Maybe<User_Key_Mutation_Response>;
  /** delete single row from the table: "user_key" */
  delete_user_key_by_pk?: Maybe<User_Key>;
  /** delete data from the table: "user_role" */
  delete_user_role?: Maybe<User_Role_Mutation_Response>;
  /** delete single row from the table: "user_role" */
  delete_user_role_by_pk?: Maybe<User_Role>;
  /** delete data from the table: "user_setting" */
  delete_user_setting?: Maybe<User_Setting_Mutation_Response>;
  /** delete single row from the table: "user_setting" */
  delete_user_setting_by_pk?: Maybe<User_Setting>;
  /** delete data from the table: "user_setting_key" */
  delete_user_setting_key?: Maybe<User_Setting_Key_Mutation_Response>;
  /** delete single row from the table: "user_setting_key" */
  delete_user_setting_key_by_pk?: Maybe<User_Setting_Key>;
  /** delete data from the table: "user_status" */
  delete_user_status?: Maybe<User_Status_Mutation_Response>;
  /** delete single row from the table: "user_status" */
  delete_user_status_by_pk?: Maybe<User_Status>;
  /** delete data from the table: "video_interview" */
  delete_video_interview?: Maybe<Video_Interview_Mutation_Response>;
  /** delete single row from the table: "video_interview" */
  delete_video_interview_by_pk?: Maybe<Video_Interview>;
  /** delete data from the table: "video_interview_criterion_score" */
  delete_video_interview_criterion_score?: Maybe<Video_Interview_Criterion_Score_Mutation_Response>;
  /** delete single row from the table: "video_interview_criterion_score" */
  delete_video_interview_criterion_score_by_pk?: Maybe<Video_Interview_Criterion_Score>;
  /** delete data from the table: "video_interview_question" */
  delete_video_interview_question?: Maybe<Video_Interview_Question_Mutation_Response>;
  /** delete single row from the table: "video_interview_question" */
  delete_video_interview_question_by_pk?: Maybe<Video_Interview_Question>;
  /** delete data from the table: "video_interview_question_event" */
  delete_video_interview_question_event?: Maybe<Video_Interview_Question_Event_Mutation_Response>;
  /** delete single row from the table: "video_interview_question_event" */
  delete_video_interview_question_event_by_pk?: Maybe<Video_Interview_Question_Event>;
  /** delete data from the table: "video_interview_question_event_type" */
  delete_video_interview_question_event_type?: Maybe<Video_Interview_Question_Event_Type_Mutation_Response>;
  /** delete single row from the table: "video_interview_question_event_type" */
  delete_video_interview_question_event_type_by_pk?: Maybe<Video_Interview_Question_Event_Type>;
  /** delete data from the table: "video_interview_question_score" */
  delete_video_interview_question_score?: Maybe<Video_Interview_Question_Score_Mutation_Response>;
  /** delete single row from the table: "video_interview_question_score" */
  delete_video_interview_question_score_by_pk?: Maybe<Video_Interview_Question_Score>;
  /** delete data from the table: "video_interview_reviewer" */
  delete_video_interview_reviewer?: Maybe<Video_Interview_Reviewer_Mutation_Response>;
  /** delete single row from the table: "video_interview_reviewer" */
  delete_video_interview_reviewer_by_pk?: Maybe<Video_Interview_Reviewer>;
  /** delete data from the table: "video_interview_status" */
  delete_video_interview_status?: Maybe<Video_Interview_Status_Mutation_Response>;
  /** delete single row from the table: "video_interview_status" */
  delete_video_interview_status_by_pk?: Maybe<Video_Interview_Status>;
  /** delete data from the table: "video_interview_status_history" */
  delete_video_interview_status_history?: Maybe<Video_Interview_Status_History_Mutation_Response>;
  /** delete single row from the table: "video_interview_status_history" */
  delete_video_interview_status_history_by_pk?: Maybe<Video_Interview_Status_History>;
  /** delete data from the table: "video_interview_type" */
  delete_video_interview_type?: Maybe<Video_Interview_Type_Mutation_Response>;
  /** delete single row from the table: "video_interview_type" */
  delete_video_interview_type_by_pk?: Maybe<Video_Interview_Type>;
  /** delete data from the table: "video_process_status" */
  delete_video_process_status?: Maybe<Video_Process_Status_Mutation_Response>;
  /** delete single row from the table: "video_process_status" */
  delete_video_process_status_by_pk?: Maybe<Video_Process_Status>;
  forgotPassword?: Maybe<CommonActionOutput>;
  generateShareableJDLink?: Maybe<GenerateShareableJdLinkOutput>;
  insertApiKey: ApiKeyActionOutput;
  /** insert data into the table: "academic_department" */
  insert_academic_department?: Maybe<Academic_Department_Mutation_Response>;
  /** insert a single row into the table: "academic_department" */
  insert_academic_department_one?: Maybe<Academic_Department>;
  /** insert data into the table: "address_type" */
  insert_address_type?: Maybe<Address_Type_Mutation_Response>;
  /** insert a single row into the table: "address_type" */
  insert_address_type_one?: Maybe<Address_Type>;
  /** insert data into the table: "city" */
  insert_city?: Maybe<City_Mutation_Response>;
  /** insert a single row into the table: "city" */
  insert_city_one?: Maybe<City>;
  /** insert data into the table: "client" */
  insert_client?: Maybe<Client_Mutation_Response>;
  /** insert data into the table: "client_candidate" */
  insert_client_candidate?: Maybe<Client_Candidate_Mutation_Response>;
  /** insert a single row into the table: "client_candidate" */
  insert_client_candidate_one?: Maybe<Client_Candidate>;
  /** insert data into the table: "client_candidate_project" */
  insert_client_candidate_project?: Maybe<Client_Candidate_Project_Mutation_Response>;
  /** insert data into the table: "client_candidate_project_candidate_list_status" */
  insert_client_candidate_project_candidate_list_status?: Maybe<Client_Candidate_Project_Candidate_List_Status_Mutation_Response>;
  /** insert a single row into the table: "client_candidate_project_candidate_list_status" */
  insert_client_candidate_project_candidate_list_status_one?: Maybe<Client_Candidate_Project_Candidate_List_Status>;
  /** insert data into the table: "client_candidate_project_candidate_resolution" */
  insert_client_candidate_project_candidate_resolution?: Maybe<Client_Candidate_Project_Candidate_Resolution_Mutation_Response>;
  /** insert a single row into the table: "client_candidate_project_candidate_resolution" */
  insert_client_candidate_project_candidate_resolution_one?: Maybe<Client_Candidate_Project_Candidate_Resolution>;
  /** insert data into the table: "client_candidate_project_note" */
  insert_client_candidate_project_note?: Maybe<Client_Candidate_Project_Note_Mutation_Response>;
  /** insert a single row into the table: "client_candidate_project_note" */
  insert_client_candidate_project_note_one?: Maybe<Client_Candidate_Project_Note>;
  /** insert a single row into the table: "client_candidate_project" */
  insert_client_candidate_project_one?: Maybe<Client_Candidate_Project>;
  /** insert data into the table: "client_location" */
  insert_client_location?: Maybe<Client_Location_Mutation_Response>;
  /** insert a single row into the table: "client_location" */
  insert_client_location_one?: Maybe<Client_Location>;
  /** insert a single row into the table: "client" */
  insert_client_one?: Maybe<Client>;
  /** insert data into the table: "client_sector" */
  insert_client_sector?: Maybe<Client_Sector_Mutation_Response>;
  /** insert a single row into the table: "client_sector" */
  insert_client_sector_one?: Maybe<Client_Sector>;
  /** insert data into the table: "client_setting" */
  insert_client_setting?: Maybe<Client_Setting_Mutation_Response>;
  /** insert data into the table: "client_setting_key" */
  insert_client_setting_key?: Maybe<Client_Setting_Key_Mutation_Response>;
  /** insert a single row into the table: "client_setting_key" */
  insert_client_setting_key_one?: Maybe<Client_Setting_Key>;
  /** insert a single row into the table: "client_setting" */
  insert_client_setting_one?: Maybe<Client_Setting>;
  /** insert data into the table: "company" */
  insert_company?: Maybe<Company_Mutation_Response>;
  /** insert a single row into the table: "company" */
  insert_company_one?: Maybe<Company>;
  /** insert data into the table: "company_subscription" */
  insert_company_subscription?: Maybe<Company_Subscription_Mutation_Response>;
  /** insert a single row into the table: "company_subscription" */
  insert_company_subscription_one?: Maybe<Company_Subscription>;
  /** insert data into the table: "consent" */
  insert_consent?: Maybe<Consent_Mutation_Response>;
  /** insert a single row into the table: "consent" */
  insert_consent_one?: Maybe<Consent>;
  /** insert data into the table: "consent_status" */
  insert_consent_status?: Maybe<Consent_Status_Mutation_Response>;
  /** insert a single row into the table: "consent_status" */
  insert_consent_status_one?: Maybe<Consent_Status>;
  /** insert data into the table: "consent_type" */
  insert_consent_type?: Maybe<Consent_Type_Mutation_Response>;
  /** insert a single row into the table: "consent_type" */
  insert_consent_type_one?: Maybe<Consent_Type>;
  /** insert data into the table: "country" */
  insert_country?: Maybe<Country_Mutation_Response>;
  /** insert a single row into the table: "country" */
  insert_country_one?: Maybe<Country>;
  /** insert data into the table: "currency" */
  insert_currency?: Maybe<Currency_Mutation_Response>;
  /** insert a single row into the table: "currency" */
  insert_currency_one?: Maybe<Currency>;
  /** insert data into the table: "department" */
  insert_department?: Maybe<Department_Mutation_Response>;
  /** insert a single row into the table: "department" */
  insert_department_one?: Maybe<Department>;
  /** insert data into the table: "education_type" */
  insert_education_type?: Maybe<Education_Type_Mutation_Response>;
  /** insert a single row into the table: "education_type" */
  insert_education_type_one?: Maybe<Education_Type>;
  /** insert data into the table: "interview_criterion" */
  insert_interview_criterion?: Maybe<Interview_Criterion_Mutation_Response>;
  /** insert a single row into the table: "interview_criterion" */
  insert_interview_criterion_one?: Maybe<Interview_Criterion>;
  /** insert data into the table: "interview_template" */
  insert_interview_template?: Maybe<Interview_Template_Mutation_Response>;
  /** insert a single row into the table: "interview_template" */
  insert_interview_template_one?: Maybe<Interview_Template>;
  /** insert data into the table: "interview_template_review_metric_question" */
  insert_interview_template_review_metric_question?: Maybe<Interview_Template_Review_Metric_Question_Mutation_Response>;
  /** insert a single row into the table: "interview_template_review_metric_question" */
  insert_interview_template_review_metric_question_one?: Maybe<Interview_Template_Review_Metric_Question>;
  /** insert data into the table: "job_description" */
  insert_job_description?: Maybe<Job_Description_Mutation_Response>;
  /** insert a single row into the table: "job_description" */
  insert_job_description_one?: Maybe<Job_Description>;
  /** insert data into the table: "job_description_step" */
  insert_job_description_step?: Maybe<Job_Description_Step_Mutation_Response>;
  /** insert a single row into the table: "job_description_step" */
  insert_job_description_step_one?: Maybe<Job_Description_Step>;
  /** insert data into the table: "language" */
  insert_language?: Maybe<Language_Mutation_Response>;
  /** insert a single row into the table: "language" */
  insert_language_one?: Maybe<Language>;
  /** insert data into the table: "partner" */
  insert_partner?: Maybe<Partner_Mutation_Response>;
  /** insert a single row into the table: "partner" */
  insert_partner_one?: Maybe<Partner>;
  /** insert data into the table: "product" */
  insert_product?: Maybe<Product_Mutation_Response>;
  /** insert a single row into the table: "product" */
  insert_product_one?: Maybe<Product>;
  /** insert data into the table: "product_price" */
  insert_product_price?: Maybe<Product_Price_Mutation_Response>;
  /** insert a single row into the table: "product_price" */
  insert_product_price_one?: Maybe<Product_Price>;
  /** insert data into the table: "project" */
  insert_project?: Maybe<Project_Mutation_Response>;
  /** insert a single row into the table: "project" */
  insert_project_one?: Maybe<Project>;
  /** insert data into the table: "project_status" */
  insert_project_status?: Maybe<Project_Status_Mutation_Response>;
  /** insert a single row into the table: "project_status" */
  insert_project_status_one?: Maybe<Project_Status>;
  /** insert data into the table: "project_user" */
  insert_project_user?: Maybe<Project_User_Mutation_Response>;
  /** insert a single row into the table: "project_user" */
  insert_project_user_one?: Maybe<Project_User>;
  /** insert data into the table: "review_metric" */
  insert_review_metric?: Maybe<Review_Metric_Mutation_Response>;
  /** insert a single row into the table: "review_metric" */
  insert_review_metric_one?: Maybe<Review_Metric>;
  /** insert data into the table: "review_metric_question" */
  insert_review_metric_question?: Maybe<Review_Metric_Question_Mutation_Response>;
  /** insert data into the table: "review_metric_question_criterion" */
  insert_review_metric_question_criterion?: Maybe<Review_Metric_Question_Criterion_Mutation_Response>;
  /** insert a single row into the table: "review_metric_question_criterion" */
  insert_review_metric_question_criterion_one?: Maybe<Review_Metric_Question_Criterion>;
  /** insert a single row into the table: "review_metric_question" */
  insert_review_metric_question_one?: Maybe<Review_Metric_Question>;
  /** insert data into the table: "review_metric_question_response" */
  insert_review_metric_question_response?: Maybe<Review_Metric_Question_Response_Mutation_Response>;
  /** insert a single row into the table: "review_metric_question_response" */
  insert_review_metric_question_response_one?: Maybe<Review_Metric_Question_Response>;
  /** insert data into the table: "review_metric_question_type" */
  insert_review_metric_question_type?: Maybe<Review_Metric_Question_Type_Mutation_Response>;
  /** insert a single row into the table: "review_metric_question_type" */
  insert_review_metric_question_type_one?: Maybe<Review_Metric_Question_Type>;
  /** insert data into the table: "role" */
  insert_role?: Maybe<Role_Mutation_Response>;
  /** insert data into the table: "role_academic_department" */
  insert_role_academic_department?: Maybe<Role_Academic_Department_Mutation_Response>;
  /** insert a single row into the table: "role_academic_department" */
  insert_role_academic_department_one?: Maybe<Role_Academic_Department>;
  /** insert data into the table: "role_benefit" */
  insert_role_benefit?: Maybe<Role_Benefit_Mutation_Response>;
  /** insert a single row into the table: "role_benefit" */
  insert_role_benefit_one?: Maybe<Role_Benefit>;
  /** insert data into the table: "role_competence" */
  insert_role_competence?: Maybe<Role_Competence_Mutation_Response>;
  /** insert a single row into the table: "role_competence" */
  insert_role_competence_one?: Maybe<Role_Competence>;
  /** insert data into the table: "role_description" */
  insert_role_description?: Maybe<Role_Description_Mutation_Response>;
  /** insert a single row into the table: "role_description" */
  insert_role_description_one?: Maybe<Role_Description>;
  /** insert a single row into the table: "role" */
  insert_role_one?: Maybe<Role>;
  /** insert data into the table: "role_responsibility" */
  insert_role_responsibility?: Maybe<Role_Responsibility_Mutation_Response>;
  /** insert a single row into the table: "role_responsibility" */
  insert_role_responsibility_one?: Maybe<Role_Responsibility>;
  /** insert data into the table: "role_review_metric" */
  insert_role_review_metric?: Maybe<Role_Review_Metric_Mutation_Response>;
  /** insert a single row into the table: "role_review_metric" */
  insert_role_review_metric_one?: Maybe<Role_Review_Metric>;
  /** insert data into the table: "role_skill" */
  insert_role_skill?: Maybe<Role_Skill_Mutation_Response>;
  /** insert a single row into the table: "role_skill" */
  insert_role_skill_one?: Maybe<Role_Skill>;
  /** insert data into the table: "sector" */
  insert_sector?: Maybe<Sector_Mutation_Response>;
  /** insert a single row into the table: "sector" */
  insert_sector_one?: Maybe<Sector>;
  /** insert data into the table: "user" */
  insert_user?: Maybe<User_Mutation_Response>;
  /** insert data into the table: "user_key" */
  insert_user_key?: Maybe<User_Key_Mutation_Response>;
  /** insert a single row into the table: "user_key" */
  insert_user_key_one?: Maybe<User_Key>;
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<User>;
  /** insert data into the table: "user_role" */
  insert_user_role?: Maybe<User_Role_Mutation_Response>;
  /** insert a single row into the table: "user_role" */
  insert_user_role_one?: Maybe<User_Role>;
  /** insert data into the table: "user_setting" */
  insert_user_setting?: Maybe<User_Setting_Mutation_Response>;
  /** insert data into the table: "user_setting_key" */
  insert_user_setting_key?: Maybe<User_Setting_Key_Mutation_Response>;
  /** insert a single row into the table: "user_setting_key" */
  insert_user_setting_key_one?: Maybe<User_Setting_Key>;
  /** insert a single row into the table: "user_setting" */
  insert_user_setting_one?: Maybe<User_Setting>;
  /** insert data into the table: "user_status" */
  insert_user_status?: Maybe<User_Status_Mutation_Response>;
  /** insert a single row into the table: "user_status" */
  insert_user_status_one?: Maybe<User_Status>;
  /** insert data into the table: "video_interview" */
  insert_video_interview?: Maybe<Video_Interview_Mutation_Response>;
  /** insert data into the table: "video_interview_criterion_score" */
  insert_video_interview_criterion_score?: Maybe<Video_Interview_Criterion_Score_Mutation_Response>;
  /** insert a single row into the table: "video_interview_criterion_score" */
  insert_video_interview_criterion_score_one?: Maybe<Video_Interview_Criterion_Score>;
  /** insert a single row into the table: "video_interview" */
  insert_video_interview_one?: Maybe<Video_Interview>;
  /** insert data into the table: "video_interview_question" */
  insert_video_interview_question?: Maybe<Video_Interview_Question_Mutation_Response>;
  /** insert data into the table: "video_interview_question_event" */
  insert_video_interview_question_event?: Maybe<Video_Interview_Question_Event_Mutation_Response>;
  /** insert a single row into the table: "video_interview_question_event" */
  insert_video_interview_question_event_one?: Maybe<Video_Interview_Question_Event>;
  /** insert data into the table: "video_interview_question_event_type" */
  insert_video_interview_question_event_type?: Maybe<Video_Interview_Question_Event_Type_Mutation_Response>;
  /** insert a single row into the table: "video_interview_question_event_type" */
  insert_video_interview_question_event_type_one?: Maybe<Video_Interview_Question_Event_Type>;
  /** insert a single row into the table: "video_interview_question" */
  insert_video_interview_question_one?: Maybe<Video_Interview_Question>;
  /** insert data into the table: "video_interview_question_score" */
  insert_video_interview_question_score?: Maybe<Video_Interview_Question_Score_Mutation_Response>;
  /** insert a single row into the table: "video_interview_question_score" */
  insert_video_interview_question_score_one?: Maybe<Video_Interview_Question_Score>;
  /** insert data into the table: "video_interview_reviewer" */
  insert_video_interview_reviewer?: Maybe<Video_Interview_Reviewer_Mutation_Response>;
  /** insert a single row into the table: "video_interview_reviewer" */
  insert_video_interview_reviewer_one?: Maybe<Video_Interview_Reviewer>;
  /** insert data into the table: "video_interview_status" */
  insert_video_interview_status?: Maybe<Video_Interview_Status_Mutation_Response>;
  /** insert data into the table: "video_interview_status_history" */
  insert_video_interview_status_history?: Maybe<Video_Interview_Status_History_Mutation_Response>;
  /** insert a single row into the table: "video_interview_status_history" */
  insert_video_interview_status_history_one?: Maybe<Video_Interview_Status_History>;
  /** insert a single row into the table: "video_interview_status" */
  insert_video_interview_status_one?: Maybe<Video_Interview_Status>;
  /** insert data into the table: "video_interview_type" */
  insert_video_interview_type?: Maybe<Video_Interview_Type_Mutation_Response>;
  /** insert a single row into the table: "video_interview_type" */
  insert_video_interview_type_one?: Maybe<Video_Interview_Type>;
  /** insert data into the table: "video_process_status" */
  insert_video_process_status?: Maybe<Video_Process_Status_Mutation_Response>;
  /** insert a single row into the table: "video_process_status" */
  insert_video_process_status_one?: Maybe<Video_Process_Status>;
  inviteMember?: Maybe<CommonActionOutput>;
  login?: Maybe<LoginOutput>;
  register?: Maybe<RegisterOutput>;
  resendOtp?: Maybe<SendOtpOutput>;
  resetPassword: CommonActionOutput;
  sendOtp?: Maybe<SendOtpOutput>;
  sendVideoInterviewLinkToCandidates: CommonActionOutput;
  /** update data of the table: "academic_department" */
  update_academic_department?: Maybe<Academic_Department_Mutation_Response>;
  /** update single row of the table: "academic_department" */
  update_academic_department_by_pk?: Maybe<Academic_Department>;
  /** update multiples rows of table: "academic_department" */
  update_academic_department_many?: Maybe<
    Array<Maybe<Academic_Department_Mutation_Response>>
  >;
  /** update data of the table: "address_type" */
  update_address_type?: Maybe<Address_Type_Mutation_Response>;
  /** update single row of the table: "address_type" */
  update_address_type_by_pk?: Maybe<Address_Type>;
  /** update multiples rows of table: "address_type" */
  update_address_type_many?: Maybe<
    Array<Maybe<Address_Type_Mutation_Response>>
  >;
  /** update data of the table: "city" */
  update_city?: Maybe<City_Mutation_Response>;
  /** update single row of the table: "city" */
  update_city_by_pk?: Maybe<City>;
  /** update multiples rows of table: "city" */
  update_city_many?: Maybe<Array<Maybe<City_Mutation_Response>>>;
  /** update data of the table: "client" */
  update_client?: Maybe<Client_Mutation_Response>;
  /** update single row of the table: "client" */
  update_client_by_pk?: Maybe<Client>;
  /** update data of the table: "client_candidate" */
  update_client_candidate?: Maybe<Client_Candidate_Mutation_Response>;
  /** update single row of the table: "client_candidate" */
  update_client_candidate_by_pk?: Maybe<Client_Candidate>;
  /** update multiples rows of table: "client_candidate" */
  update_client_candidate_many?: Maybe<
    Array<Maybe<Client_Candidate_Mutation_Response>>
  >;
  /** update data of the table: "client_candidate_project" */
  update_client_candidate_project?: Maybe<Client_Candidate_Project_Mutation_Response>;
  /** update single row of the table: "client_candidate_project" */
  update_client_candidate_project_by_pk?: Maybe<Client_Candidate_Project>;
  /** update data of the table: "client_candidate_project_candidate_list_status" */
  update_client_candidate_project_candidate_list_status?: Maybe<Client_Candidate_Project_Candidate_List_Status_Mutation_Response>;
  /** update single row of the table: "client_candidate_project_candidate_list_status" */
  update_client_candidate_project_candidate_list_status_by_pk?: Maybe<Client_Candidate_Project_Candidate_List_Status>;
  /** update multiples rows of table: "client_candidate_project_candidate_list_status" */
  update_client_candidate_project_candidate_list_status_many?: Maybe<
    Array<
      Maybe<Client_Candidate_Project_Candidate_List_Status_Mutation_Response>
    >
  >;
  /** update data of the table: "client_candidate_project_candidate_resolution" */
  update_client_candidate_project_candidate_resolution?: Maybe<Client_Candidate_Project_Candidate_Resolution_Mutation_Response>;
  /** update single row of the table: "client_candidate_project_candidate_resolution" */
  update_client_candidate_project_candidate_resolution_by_pk?: Maybe<Client_Candidate_Project_Candidate_Resolution>;
  /** update multiples rows of table: "client_candidate_project_candidate_resolution" */
  update_client_candidate_project_candidate_resolution_many?: Maybe<
    Array<
      Maybe<Client_Candidate_Project_Candidate_Resolution_Mutation_Response>
    >
  >;
  /** update multiples rows of table: "client_candidate_project" */
  update_client_candidate_project_many?: Maybe<
    Array<Maybe<Client_Candidate_Project_Mutation_Response>>
  >;
  /** update data of the table: "client_candidate_project_note" */
  update_client_candidate_project_note?: Maybe<Client_Candidate_Project_Note_Mutation_Response>;
  /** update single row of the table: "client_candidate_project_note" */
  update_client_candidate_project_note_by_pk?: Maybe<Client_Candidate_Project_Note>;
  /** update multiples rows of table: "client_candidate_project_note" */
  update_client_candidate_project_note_many?: Maybe<
    Array<Maybe<Client_Candidate_Project_Note_Mutation_Response>>
  >;
  /** update data of the table: "client_location" */
  update_client_location?: Maybe<Client_Location_Mutation_Response>;
  /** update single row of the table: "client_location" */
  update_client_location_by_pk?: Maybe<Client_Location>;
  /** update multiples rows of table: "client_location" */
  update_client_location_many?: Maybe<
    Array<Maybe<Client_Location_Mutation_Response>>
  >;
  /** update multiples rows of table: "client" */
  update_client_many?: Maybe<Array<Maybe<Client_Mutation_Response>>>;
  /** update data of the table: "client_sector" */
  update_client_sector?: Maybe<Client_Sector_Mutation_Response>;
  /** update single row of the table: "client_sector" */
  update_client_sector_by_pk?: Maybe<Client_Sector>;
  /** update multiples rows of table: "client_sector" */
  update_client_sector_many?: Maybe<
    Array<Maybe<Client_Sector_Mutation_Response>>
  >;
  /** update data of the table: "client_setting" */
  update_client_setting?: Maybe<Client_Setting_Mutation_Response>;
  /** update single row of the table: "client_setting" */
  update_client_setting_by_pk?: Maybe<Client_Setting>;
  /** update data of the table: "client_setting_key" */
  update_client_setting_key?: Maybe<Client_Setting_Key_Mutation_Response>;
  /** update single row of the table: "client_setting_key" */
  update_client_setting_key_by_pk?: Maybe<Client_Setting_Key>;
  /** update multiples rows of table: "client_setting_key" */
  update_client_setting_key_many?: Maybe<
    Array<Maybe<Client_Setting_Key_Mutation_Response>>
  >;
  /** update multiples rows of table: "client_setting" */
  update_client_setting_many?: Maybe<
    Array<Maybe<Client_Setting_Mutation_Response>>
  >;
  /** update data of the table: "company" */
  update_company?: Maybe<Company_Mutation_Response>;
  /** update single row of the table: "company" */
  update_company_by_pk?: Maybe<Company>;
  /** update multiples rows of table: "company" */
  update_company_many?: Maybe<Array<Maybe<Company_Mutation_Response>>>;
  /** update data of the table: "company_subscription" */
  update_company_subscription?: Maybe<Company_Subscription_Mutation_Response>;
  /** update single row of the table: "company_subscription" */
  update_company_subscription_by_pk?: Maybe<Company_Subscription>;
  /** update multiples rows of table: "company_subscription" */
  update_company_subscription_many?: Maybe<
    Array<Maybe<Company_Subscription_Mutation_Response>>
  >;
  /** update data of the table: "consent" */
  update_consent?: Maybe<Consent_Mutation_Response>;
  /** update single row of the table: "consent" */
  update_consent_by_pk?: Maybe<Consent>;
  /** update multiples rows of table: "consent" */
  update_consent_many?: Maybe<Array<Maybe<Consent_Mutation_Response>>>;
  /** update data of the table: "consent_status" */
  update_consent_status?: Maybe<Consent_Status_Mutation_Response>;
  /** update single row of the table: "consent_status" */
  update_consent_status_by_pk?: Maybe<Consent_Status>;
  /** update multiples rows of table: "consent_status" */
  update_consent_status_many?: Maybe<
    Array<Maybe<Consent_Status_Mutation_Response>>
  >;
  /** update data of the table: "consent_type" */
  update_consent_type?: Maybe<Consent_Type_Mutation_Response>;
  /** update single row of the table: "consent_type" */
  update_consent_type_by_pk?: Maybe<Consent_Type>;
  /** update multiples rows of table: "consent_type" */
  update_consent_type_many?: Maybe<
    Array<Maybe<Consent_Type_Mutation_Response>>
  >;
  /** update data of the table: "country" */
  update_country?: Maybe<Country_Mutation_Response>;
  /** update single row of the table: "country" */
  update_country_by_pk?: Maybe<Country>;
  /** update multiples rows of table: "country" */
  update_country_many?: Maybe<Array<Maybe<Country_Mutation_Response>>>;
  /** update data of the table: "currency" */
  update_currency?: Maybe<Currency_Mutation_Response>;
  /** update single row of the table: "currency" */
  update_currency_by_pk?: Maybe<Currency>;
  /** update multiples rows of table: "currency" */
  update_currency_many?: Maybe<Array<Maybe<Currency_Mutation_Response>>>;
  /** update data of the table: "department" */
  update_department?: Maybe<Department_Mutation_Response>;
  /** update single row of the table: "department" */
  update_department_by_pk?: Maybe<Department>;
  /** update multiples rows of table: "department" */
  update_department_many?: Maybe<Array<Maybe<Department_Mutation_Response>>>;
  /** update data of the table: "education_type" */
  update_education_type?: Maybe<Education_Type_Mutation_Response>;
  /** update single row of the table: "education_type" */
  update_education_type_by_pk?: Maybe<Education_Type>;
  /** update multiples rows of table: "education_type" */
  update_education_type_many?: Maybe<
    Array<Maybe<Education_Type_Mutation_Response>>
  >;
  /** update data of the table: "interview_criterion" */
  update_interview_criterion?: Maybe<Interview_Criterion_Mutation_Response>;
  /** update single row of the table: "interview_criterion" */
  update_interview_criterion_by_pk?: Maybe<Interview_Criterion>;
  /** update multiples rows of table: "interview_criterion" */
  update_interview_criterion_many?: Maybe<
    Array<Maybe<Interview_Criterion_Mutation_Response>>
  >;
  /** update data of the table: "interview_template" */
  update_interview_template?: Maybe<Interview_Template_Mutation_Response>;
  /** update single row of the table: "interview_template" */
  update_interview_template_by_pk?: Maybe<Interview_Template>;
  /** update multiples rows of table: "interview_template" */
  update_interview_template_many?: Maybe<
    Array<Maybe<Interview_Template_Mutation_Response>>
  >;
  /** update data of the table: "interview_template_review_metric_question" */
  update_interview_template_review_metric_question?: Maybe<Interview_Template_Review_Metric_Question_Mutation_Response>;
  /** update single row of the table: "interview_template_review_metric_question" */
  update_interview_template_review_metric_question_by_pk?: Maybe<Interview_Template_Review_Metric_Question>;
  /** update multiples rows of table: "interview_template_review_metric_question" */
  update_interview_template_review_metric_question_many?: Maybe<
    Array<Maybe<Interview_Template_Review_Metric_Question_Mutation_Response>>
  >;
  /** update data of the table: "job_description" */
  update_job_description?: Maybe<Job_Description_Mutation_Response>;
  /** update single row of the table: "job_description" */
  update_job_description_by_pk?: Maybe<Job_Description>;
  /** update multiples rows of table: "job_description" */
  update_job_description_many?: Maybe<
    Array<Maybe<Job_Description_Mutation_Response>>
  >;
  /** update data of the table: "job_description_step" */
  update_job_description_step?: Maybe<Job_Description_Step_Mutation_Response>;
  /** update single row of the table: "job_description_step" */
  update_job_description_step_by_pk?: Maybe<Job_Description_Step>;
  /** update multiples rows of table: "job_description_step" */
  update_job_description_step_many?: Maybe<
    Array<Maybe<Job_Description_Step_Mutation_Response>>
  >;
  /** update data of the table: "language" */
  update_language?: Maybe<Language_Mutation_Response>;
  /** update single row of the table: "language" */
  update_language_by_pk?: Maybe<Language>;
  /** update multiples rows of table: "language" */
  update_language_many?: Maybe<Array<Maybe<Language_Mutation_Response>>>;
  /** update data of the table: "partner" */
  update_partner?: Maybe<Partner_Mutation_Response>;
  /** update single row of the table: "partner" */
  update_partner_by_pk?: Maybe<Partner>;
  /** update multiples rows of table: "partner" */
  update_partner_many?: Maybe<Array<Maybe<Partner_Mutation_Response>>>;
  /** update data of the table: "product" */
  update_product?: Maybe<Product_Mutation_Response>;
  /** update single row of the table: "product" */
  update_product_by_pk?: Maybe<Product>;
  /** update multiples rows of table: "product" */
  update_product_many?: Maybe<Array<Maybe<Product_Mutation_Response>>>;
  /** update data of the table: "product_price" */
  update_product_price?: Maybe<Product_Price_Mutation_Response>;
  /** update single row of the table: "product_price" */
  update_product_price_by_pk?: Maybe<Product_Price>;
  /** update multiples rows of table: "product_price" */
  update_product_price_many?: Maybe<
    Array<Maybe<Product_Price_Mutation_Response>>
  >;
  /** update data of the table: "project" */
  update_project?: Maybe<Project_Mutation_Response>;
  /** update single row of the table: "project" */
  update_project_by_pk?: Maybe<Project>;
  /** update multiples rows of table: "project" */
  update_project_many?: Maybe<Array<Maybe<Project_Mutation_Response>>>;
  /** update data of the table: "project_status" */
  update_project_status?: Maybe<Project_Status_Mutation_Response>;
  /** update single row of the table: "project_status" */
  update_project_status_by_pk?: Maybe<Project_Status>;
  /** update multiples rows of table: "project_status" */
  update_project_status_many?: Maybe<
    Array<Maybe<Project_Status_Mutation_Response>>
  >;
  /** update data of the table: "project_user" */
  update_project_user?: Maybe<Project_User_Mutation_Response>;
  /** update single row of the table: "project_user" */
  update_project_user_by_pk?: Maybe<Project_User>;
  /** update multiples rows of table: "project_user" */
  update_project_user_many?: Maybe<
    Array<Maybe<Project_User_Mutation_Response>>
  >;
  /** update data of the table: "review_metric" */
  update_review_metric?: Maybe<Review_Metric_Mutation_Response>;
  /** update single row of the table: "review_metric" */
  update_review_metric_by_pk?: Maybe<Review_Metric>;
  /** update multiples rows of table: "review_metric" */
  update_review_metric_many?: Maybe<
    Array<Maybe<Review_Metric_Mutation_Response>>
  >;
  /** update data of the table: "review_metric_question" */
  update_review_metric_question?: Maybe<Review_Metric_Question_Mutation_Response>;
  /** update single row of the table: "review_metric_question" */
  update_review_metric_question_by_pk?: Maybe<Review_Metric_Question>;
  /** update data of the table: "review_metric_question_criterion" */
  update_review_metric_question_criterion?: Maybe<Review_Metric_Question_Criterion_Mutation_Response>;
  /** update single row of the table: "review_metric_question_criterion" */
  update_review_metric_question_criterion_by_pk?: Maybe<Review_Metric_Question_Criterion>;
  /** update multiples rows of table: "review_metric_question_criterion" */
  update_review_metric_question_criterion_many?: Maybe<
    Array<Maybe<Review_Metric_Question_Criterion_Mutation_Response>>
  >;
  /** update multiples rows of table: "review_metric_question" */
  update_review_metric_question_many?: Maybe<
    Array<Maybe<Review_Metric_Question_Mutation_Response>>
  >;
  /** update data of the table: "review_metric_question_response" */
  update_review_metric_question_response?: Maybe<Review_Metric_Question_Response_Mutation_Response>;
  /** update single row of the table: "review_metric_question_response" */
  update_review_metric_question_response_by_pk?: Maybe<Review_Metric_Question_Response>;
  /** update multiples rows of table: "review_metric_question_response" */
  update_review_metric_question_response_many?: Maybe<
    Array<Maybe<Review_Metric_Question_Response_Mutation_Response>>
  >;
  /** update data of the table: "review_metric_question_type" */
  update_review_metric_question_type?: Maybe<Review_Metric_Question_Type_Mutation_Response>;
  /** update single row of the table: "review_metric_question_type" */
  update_review_metric_question_type_by_pk?: Maybe<Review_Metric_Question_Type>;
  /** update multiples rows of table: "review_metric_question_type" */
  update_review_metric_question_type_many?: Maybe<
    Array<Maybe<Review_Metric_Question_Type_Mutation_Response>>
  >;
  /** update data of the table: "role" */
  update_role?: Maybe<Role_Mutation_Response>;
  /** update data of the table: "role_academic_department" */
  update_role_academic_department?: Maybe<Role_Academic_Department_Mutation_Response>;
  /** update single row of the table: "role_academic_department" */
  update_role_academic_department_by_pk?: Maybe<Role_Academic_Department>;
  /** update multiples rows of table: "role_academic_department" */
  update_role_academic_department_many?: Maybe<
    Array<Maybe<Role_Academic_Department_Mutation_Response>>
  >;
  /** update data of the table: "role_benefit" */
  update_role_benefit?: Maybe<Role_Benefit_Mutation_Response>;
  /** update single row of the table: "role_benefit" */
  update_role_benefit_by_pk?: Maybe<Role_Benefit>;
  /** update multiples rows of table: "role_benefit" */
  update_role_benefit_many?: Maybe<
    Array<Maybe<Role_Benefit_Mutation_Response>>
  >;
  /** update single row of the table: "role" */
  update_role_by_pk?: Maybe<Role>;
  /** update data of the table: "role_competence" */
  update_role_competence?: Maybe<Role_Competence_Mutation_Response>;
  /** update single row of the table: "role_competence" */
  update_role_competence_by_pk?: Maybe<Role_Competence>;
  /** update multiples rows of table: "role_competence" */
  update_role_competence_many?: Maybe<
    Array<Maybe<Role_Competence_Mutation_Response>>
  >;
  /** update data of the table: "role_description" */
  update_role_description?: Maybe<Role_Description_Mutation_Response>;
  /** update single row of the table: "role_description" */
  update_role_description_by_pk?: Maybe<Role_Description>;
  /** update multiples rows of table: "role_description" */
  update_role_description_many?: Maybe<
    Array<Maybe<Role_Description_Mutation_Response>>
  >;
  /** update multiples rows of table: "role" */
  update_role_many?: Maybe<Array<Maybe<Role_Mutation_Response>>>;
  /** update data of the table: "role_responsibility" */
  update_role_responsibility?: Maybe<Role_Responsibility_Mutation_Response>;
  /** update single row of the table: "role_responsibility" */
  update_role_responsibility_by_pk?: Maybe<Role_Responsibility>;
  /** update multiples rows of table: "role_responsibility" */
  update_role_responsibility_many?: Maybe<
    Array<Maybe<Role_Responsibility_Mutation_Response>>
  >;
  /** update data of the table: "role_review_metric" */
  update_role_review_metric?: Maybe<Role_Review_Metric_Mutation_Response>;
  /** update single row of the table: "role_review_metric" */
  update_role_review_metric_by_pk?: Maybe<Role_Review_Metric>;
  /** update multiples rows of table: "role_review_metric" */
  update_role_review_metric_many?: Maybe<
    Array<Maybe<Role_Review_Metric_Mutation_Response>>
  >;
  /** update data of the table: "role_skill" */
  update_role_skill?: Maybe<Role_Skill_Mutation_Response>;
  /** update single row of the table: "role_skill" */
  update_role_skill_by_pk?: Maybe<Role_Skill>;
  /** update multiples rows of table: "role_skill" */
  update_role_skill_many?: Maybe<Array<Maybe<Role_Skill_Mutation_Response>>>;
  /** update data of the table: "sector" */
  update_sector?: Maybe<Sector_Mutation_Response>;
  /** update single row of the table: "sector" */
  update_sector_by_pk?: Maybe<Sector>;
  /** update multiples rows of table: "sector" */
  update_sector_many?: Maybe<Array<Maybe<Sector_Mutation_Response>>>;
  /** update data of the table: "user" */
  update_user?: Maybe<User_Mutation_Response>;
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>;
  /** update data of the table: "user_key" */
  update_user_key?: Maybe<User_Key_Mutation_Response>;
  /** update single row of the table: "user_key" */
  update_user_key_by_pk?: Maybe<User_Key>;
  /** update multiples rows of table: "user_key" */
  update_user_key_many?: Maybe<Array<Maybe<User_Key_Mutation_Response>>>;
  /** update multiples rows of table: "user" */
  update_user_many?: Maybe<Array<Maybe<User_Mutation_Response>>>;
  /** update data of the table: "user_role" */
  update_user_role?: Maybe<User_Role_Mutation_Response>;
  /** update single row of the table: "user_role" */
  update_user_role_by_pk?: Maybe<User_Role>;
  /** update multiples rows of table: "user_role" */
  update_user_role_many?: Maybe<Array<Maybe<User_Role_Mutation_Response>>>;
  /** update data of the table: "user_setting" */
  update_user_setting?: Maybe<User_Setting_Mutation_Response>;
  /** update single row of the table: "user_setting" */
  update_user_setting_by_pk?: Maybe<User_Setting>;
  /** update data of the table: "user_setting_key" */
  update_user_setting_key?: Maybe<User_Setting_Key_Mutation_Response>;
  /** update single row of the table: "user_setting_key" */
  update_user_setting_key_by_pk?: Maybe<User_Setting_Key>;
  /** update multiples rows of table: "user_setting_key" */
  update_user_setting_key_many?: Maybe<
    Array<Maybe<User_Setting_Key_Mutation_Response>>
  >;
  /** update multiples rows of table: "user_setting" */
  update_user_setting_many?: Maybe<
    Array<Maybe<User_Setting_Mutation_Response>>
  >;
  /** update data of the table: "user_status" */
  update_user_status?: Maybe<User_Status_Mutation_Response>;
  /** update single row of the table: "user_status" */
  update_user_status_by_pk?: Maybe<User_Status>;
  /** update multiples rows of table: "user_status" */
  update_user_status_many?: Maybe<Array<Maybe<User_Status_Mutation_Response>>>;
  /** update data of the table: "video_interview" */
  update_video_interview?: Maybe<Video_Interview_Mutation_Response>;
  /** update single row of the table: "video_interview" */
  update_video_interview_by_pk?: Maybe<Video_Interview>;
  /** update data of the table: "video_interview_criterion_score" */
  update_video_interview_criterion_score?: Maybe<Video_Interview_Criterion_Score_Mutation_Response>;
  /** update single row of the table: "video_interview_criterion_score" */
  update_video_interview_criterion_score_by_pk?: Maybe<Video_Interview_Criterion_Score>;
  /** update multiples rows of table: "video_interview_criterion_score" */
  update_video_interview_criterion_score_many?: Maybe<
    Array<Maybe<Video_Interview_Criterion_Score_Mutation_Response>>
  >;
  /** update multiples rows of table: "video_interview" */
  update_video_interview_many?: Maybe<
    Array<Maybe<Video_Interview_Mutation_Response>>
  >;
  /** update data of the table: "video_interview_question" */
  update_video_interview_question?: Maybe<Video_Interview_Question_Mutation_Response>;
  /** update single row of the table: "video_interview_question" */
  update_video_interview_question_by_pk?: Maybe<Video_Interview_Question>;
  /** update data of the table: "video_interview_question_event" */
  update_video_interview_question_event?: Maybe<Video_Interview_Question_Event_Mutation_Response>;
  /** update single row of the table: "video_interview_question_event" */
  update_video_interview_question_event_by_pk?: Maybe<Video_Interview_Question_Event>;
  /** update multiples rows of table: "video_interview_question_event" */
  update_video_interview_question_event_many?: Maybe<
    Array<Maybe<Video_Interview_Question_Event_Mutation_Response>>
  >;
  /** update data of the table: "video_interview_question_event_type" */
  update_video_interview_question_event_type?: Maybe<Video_Interview_Question_Event_Type_Mutation_Response>;
  /** update single row of the table: "video_interview_question_event_type" */
  update_video_interview_question_event_type_by_pk?: Maybe<Video_Interview_Question_Event_Type>;
  /** update multiples rows of table: "video_interview_question_event_type" */
  update_video_interview_question_event_type_many?: Maybe<
    Array<Maybe<Video_Interview_Question_Event_Type_Mutation_Response>>
  >;
  /** update multiples rows of table: "video_interview_question" */
  update_video_interview_question_many?: Maybe<
    Array<Maybe<Video_Interview_Question_Mutation_Response>>
  >;
  /** update data of the table: "video_interview_question_score" */
  update_video_interview_question_score?: Maybe<Video_Interview_Question_Score_Mutation_Response>;
  /** update single row of the table: "video_interview_question_score" */
  update_video_interview_question_score_by_pk?: Maybe<Video_Interview_Question_Score>;
  /** update multiples rows of table: "video_interview_question_score" */
  update_video_interview_question_score_many?: Maybe<
    Array<Maybe<Video_Interview_Question_Score_Mutation_Response>>
  >;
  /** update data of the table: "video_interview_reviewer" */
  update_video_interview_reviewer?: Maybe<Video_Interview_Reviewer_Mutation_Response>;
  /** update single row of the table: "video_interview_reviewer" */
  update_video_interview_reviewer_by_pk?: Maybe<Video_Interview_Reviewer>;
  /** update multiples rows of table: "video_interview_reviewer" */
  update_video_interview_reviewer_many?: Maybe<
    Array<Maybe<Video_Interview_Reviewer_Mutation_Response>>
  >;
  /** update data of the table: "video_interview_status" */
  update_video_interview_status?: Maybe<Video_Interview_Status_Mutation_Response>;
  /** update single row of the table: "video_interview_status" */
  update_video_interview_status_by_pk?: Maybe<Video_Interview_Status>;
  /** update data of the table: "video_interview_status_history" */
  update_video_interview_status_history?: Maybe<Video_Interview_Status_History_Mutation_Response>;
  /** update single row of the table: "video_interview_status_history" */
  update_video_interview_status_history_by_pk?: Maybe<Video_Interview_Status_History>;
  /** update multiples rows of table: "video_interview_status_history" */
  update_video_interview_status_history_many?: Maybe<
    Array<Maybe<Video_Interview_Status_History_Mutation_Response>>
  >;
  /** update multiples rows of table: "video_interview_status" */
  update_video_interview_status_many?: Maybe<
    Array<Maybe<Video_Interview_Status_Mutation_Response>>
  >;
  /** update data of the table: "video_interview_type" */
  update_video_interview_type?: Maybe<Video_Interview_Type_Mutation_Response>;
  /** update single row of the table: "video_interview_type" */
  update_video_interview_type_by_pk?: Maybe<Video_Interview_Type>;
  /** update multiples rows of table: "video_interview_type" */
  update_video_interview_type_many?: Maybe<
    Array<Maybe<Video_Interview_Type_Mutation_Response>>
  >;
  /** update data of the table: "video_process_status" */
  update_video_process_status?: Maybe<Video_Process_Status_Mutation_Response>;
  /** update single row of the table: "video_process_status" */
  update_video_process_status_by_pk?: Maybe<Video_Process_Status>;
  /** update multiples rows of table: "video_process_status" */
  update_video_process_status_many?: Maybe<
    Array<Maybe<Video_Process_Status_Mutation_Response>>
  >;
  /** verify2FA */
  verify2FA?: Maybe<Verify2FaOutput>;
};

/** mutation root */
export type Mutation_RootAddConsentArgs = {
  consents: Array<ConsentInput>;
};

/** mutation root */
export type Mutation_RootApplyJobArgs = {
  applyJobInput: ApplyJobInput;
};

/** mutation root */
export type Mutation_RootChangePasswordArgs = {
  passwordChangeInput: PasswordChangeInput;
};

/** mutation root */
export type Mutation_RootCreateCompanyArgs = {
  createCompanyInput: CreateCompanyInput;
};

/** mutation root */
export type Mutation_RootDelete_Academic_DepartmentArgs = {
  where: Academic_Department_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Academic_Department_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Address_TypeArgs = {
  where: Address_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Address_Type_By_PkArgs = {
  adress_type: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_CityArgs = {
  where: City_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_City_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_ClientArgs = {
  where: Client_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Client_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Client_CandidateArgs = {
  where: Client_Candidate_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Client_Candidate_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Client_Candidate_ProjectArgs = {
  where: Client_Candidate_Project_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Client_Candidate_Project_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Client_Candidate_Project_Candidate_List_StatusArgs =
  {
    where: Client_Candidate_Project_Candidate_List_Status_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Client_Candidate_Project_Candidate_List_Status_By_PkArgs =
  {
    candidate_status: Scalars["String"];
  };

/** mutation root */
export type Mutation_RootDelete_Client_Candidate_Project_Candidate_ResolutionArgs =
  {
    where: Client_Candidate_Project_Candidate_Resolution_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Client_Candidate_Project_Candidate_Resolution_By_PkArgs =
  {
    resolution: Scalars["String"];
  };

/** mutation root */
export type Mutation_RootDelete_Client_Candidate_Project_NoteArgs = {
  where: Client_Candidate_Project_Note_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Client_Candidate_Project_Note_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Client_LocationArgs = {
  where: Client_Location_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Client_Location_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Client_SectorArgs = {
  where: Client_Sector_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Client_Sector_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Client_SettingArgs = {
  where: Client_Setting_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Client_Setting_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Client_Setting_KeyArgs = {
  where: Client_Setting_Key_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Client_Setting_Key_By_PkArgs = {
  key: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_CompanyArgs = {
  where: Company_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Company_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Company_SubscriptionArgs = {
  where: Company_Subscription_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Company_Subscription_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_ConsentArgs = {
  where: Consent_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Consent_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_Consent_StatusArgs = {
  where: Consent_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Consent_Status_By_PkArgs = {
  consent_status: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_Consent_TypeArgs = {
  where: Consent_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Consent_Type_By_PkArgs = {
  consent_type: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_CountryArgs = {
  where: Country_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Country_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_CurrencyArgs = {
  where: Currency_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Currency_By_PkArgs = {
  currency: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_DepartmentArgs = {
  where: Department_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Department_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Education_TypeArgs = {
  where: Education_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Education_Type_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Interview_CriterionArgs = {
  where: Interview_Criterion_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Interview_Criterion_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Interview_TemplateArgs = {
  where: Interview_Template_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Interview_Template_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Interview_Template_Review_Metric_QuestionArgs =
  {
    where: Interview_Template_Review_Metric_Question_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Interview_Template_Review_Metric_Question_By_PkArgs =
  {
    id: Scalars["bigint"];
  };

/** mutation root */
export type Mutation_RootDelete_Job_DescriptionArgs = {
  where: Job_Description_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Job_Description_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Job_Description_StepArgs = {
  where: Job_Description_Step_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Job_Description_Step_By_PkArgs = {
  step: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_LanguageArgs = {
  where: Language_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Language_By_PkArgs = {
  language: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_PartnerArgs = {
  where: Partner_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Partner_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_ProductArgs = {
  where: Product_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Product_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Product_PriceArgs = {
  where: Product_Price_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Product_Price_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_ProjectArgs = {
  where: Project_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Project_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Project_StatusArgs = {
  where: Project_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Project_Status_By_PkArgs = {
  status: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_Project_UserArgs = {
  where: Project_User_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Project_User_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Review_MetricArgs = {
  where: Review_Metric_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Review_Metric_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Review_Metric_QuestionArgs = {
  where: Review_Metric_Question_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Review_Metric_Question_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Review_Metric_Question_CriterionArgs = {
  where: Review_Metric_Question_Criterion_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Review_Metric_Question_Criterion_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Review_Metric_Question_ResponseArgs = {
  where: Review_Metric_Question_Response_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Review_Metric_Question_Response_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Review_Metric_Question_TypeArgs = {
  where: Review_Metric_Question_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Review_Metric_Question_Type_By_PkArgs = {
  question_type: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_RoleArgs = {
  where: Role_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Role_Academic_DepartmentArgs = {
  where: Role_Academic_Department_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Role_Academic_Department_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Role_BenefitArgs = {
  where: Role_Benefit_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Role_Benefit_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Role_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Role_CompetenceArgs = {
  where: Role_Competence_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Role_Competence_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Role_DescriptionArgs = {
  where: Role_Description_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Role_Description_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Role_ResponsibilityArgs = {
  where: Role_Responsibility_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Role_Responsibility_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Role_Review_MetricArgs = {
  where: Role_Review_Metric_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Role_Review_Metric_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Role_SkillArgs = {
  where: Role_Skill_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Role_Skill_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_SectorArgs = {
  where: Sector_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Sector_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_User_KeyArgs = {
  where: User_Key_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Key_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_User_RoleArgs = {
  where: User_Role_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Role_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_User_SettingArgs = {
  where: User_Setting_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Setting_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_User_Setting_KeyArgs = {
  where: User_Setting_Key_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Setting_Key_By_PkArgs = {
  key: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_User_StatusArgs = {
  where: User_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Status_By_PkArgs = {
  status: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_Video_InterviewArgs = {
  where: Video_Interview_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Video_Interview_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Video_Interview_Criterion_ScoreArgs = {
  where: Video_Interview_Criterion_Score_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Video_Interview_Criterion_Score_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Video_Interview_QuestionArgs = {
  where: Video_Interview_Question_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Video_Interview_Question_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Video_Interview_Question_EventArgs = {
  where: Video_Interview_Question_Event_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Video_Interview_Question_Event_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_Video_Interview_Question_Event_TypeArgs = {
  where: Video_Interview_Question_Event_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Video_Interview_Question_Event_Type_By_PkArgs =
  {
    type: Scalars["String"];
  };

/** mutation root */
export type Mutation_RootDelete_Video_Interview_Question_ScoreArgs = {
  where: Video_Interview_Question_Score_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Video_Interview_Question_Score_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Video_Interview_ReviewerArgs = {
  where: Video_Interview_Reviewer_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Video_Interview_Reviewer_By_PkArgs = {
  reviewer: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_Video_Interview_StatusArgs = {
  where: Video_Interview_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Video_Interview_Status_By_PkArgs = {
  status: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_Video_Interview_Status_HistoryArgs = {
  where: Video_Interview_Status_History_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Video_Interview_Status_History_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Video_Interview_TypeArgs = {
  where: Video_Interview_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Video_Interview_Type_By_PkArgs = {
  video_interview_type: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_Video_Process_StatusArgs = {
  where: Video_Process_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Video_Process_Status_By_PkArgs = {
  status: Scalars["String"];
};

/** mutation root */
export type Mutation_RootForgotPasswordArgs = {
  username: Scalars["String"];
};

/** mutation root */
export type Mutation_RootGenerateShareableJdLinkArgs = {
  jdId: Scalars["String"];
};

/** mutation root */
export type Mutation_RootInsertApiKeyArgs = {
  insertApiKeyInput: InsertApiKeyInput;
};

/** mutation root */
export type Mutation_RootInsert_Academic_DepartmentArgs = {
  objects: Array<Academic_Department_Insert_Input>;
  on_conflict?: InputMaybe<Academic_Department_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Academic_Department_OneArgs = {
  object: Academic_Department_Insert_Input;
  on_conflict?: InputMaybe<Academic_Department_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Address_TypeArgs = {
  objects: Array<Address_Type_Insert_Input>;
  on_conflict?: InputMaybe<Address_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Address_Type_OneArgs = {
  object: Address_Type_Insert_Input;
  on_conflict?: InputMaybe<Address_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CityArgs = {
  objects: Array<City_Insert_Input>;
  on_conflict?: InputMaybe<City_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_City_OneArgs = {
  object: City_Insert_Input;
  on_conflict?: InputMaybe<City_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ClientArgs = {
  objects: Array<Client_Insert_Input>;
  on_conflict?: InputMaybe<Client_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_CandidateArgs = {
  objects: Array<Client_Candidate_Insert_Input>;
  on_conflict?: InputMaybe<Client_Candidate_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_Candidate_OneArgs = {
  object: Client_Candidate_Insert_Input;
  on_conflict?: InputMaybe<Client_Candidate_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_Candidate_ProjectArgs = {
  objects: Array<Client_Candidate_Project_Insert_Input>;
  on_conflict?: InputMaybe<Client_Candidate_Project_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_Candidate_Project_Candidate_List_StatusArgs =
  {
    objects: Array<Client_Candidate_Project_Candidate_List_Status_Insert_Input>;
    on_conflict?: InputMaybe<Client_Candidate_Project_Candidate_List_Status_On_Conflict>;
  };

/** mutation root */
export type Mutation_RootInsert_Client_Candidate_Project_Candidate_List_Status_OneArgs =
  {
    object: Client_Candidate_Project_Candidate_List_Status_Insert_Input;
    on_conflict?: InputMaybe<Client_Candidate_Project_Candidate_List_Status_On_Conflict>;
  };

/** mutation root */
export type Mutation_RootInsert_Client_Candidate_Project_Candidate_ResolutionArgs =
  {
    objects: Array<Client_Candidate_Project_Candidate_Resolution_Insert_Input>;
    on_conflict?: InputMaybe<Client_Candidate_Project_Candidate_Resolution_On_Conflict>;
  };

/** mutation root */
export type Mutation_RootInsert_Client_Candidate_Project_Candidate_Resolution_OneArgs =
  {
    object: Client_Candidate_Project_Candidate_Resolution_Insert_Input;
    on_conflict?: InputMaybe<Client_Candidate_Project_Candidate_Resolution_On_Conflict>;
  };

/** mutation root */
export type Mutation_RootInsert_Client_Candidate_Project_NoteArgs = {
  objects: Array<Client_Candidate_Project_Note_Insert_Input>;
  on_conflict?: InputMaybe<Client_Candidate_Project_Note_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_Candidate_Project_Note_OneArgs = {
  object: Client_Candidate_Project_Note_Insert_Input;
  on_conflict?: InputMaybe<Client_Candidate_Project_Note_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_Candidate_Project_OneArgs = {
  object: Client_Candidate_Project_Insert_Input;
  on_conflict?: InputMaybe<Client_Candidate_Project_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_LocationArgs = {
  objects: Array<Client_Location_Insert_Input>;
  on_conflict?: InputMaybe<Client_Location_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_Location_OneArgs = {
  object: Client_Location_Insert_Input;
  on_conflict?: InputMaybe<Client_Location_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_OneArgs = {
  object: Client_Insert_Input;
  on_conflict?: InputMaybe<Client_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_SectorArgs = {
  objects: Array<Client_Sector_Insert_Input>;
  on_conflict?: InputMaybe<Client_Sector_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_Sector_OneArgs = {
  object: Client_Sector_Insert_Input;
  on_conflict?: InputMaybe<Client_Sector_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_SettingArgs = {
  objects: Array<Client_Setting_Insert_Input>;
  on_conflict?: InputMaybe<Client_Setting_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_Setting_KeyArgs = {
  objects: Array<Client_Setting_Key_Insert_Input>;
  on_conflict?: InputMaybe<Client_Setting_Key_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_Setting_Key_OneArgs = {
  object: Client_Setting_Key_Insert_Input;
  on_conflict?: InputMaybe<Client_Setting_Key_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_Setting_OneArgs = {
  object: Client_Setting_Insert_Input;
  on_conflict?: InputMaybe<Client_Setting_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CompanyArgs = {
  objects: Array<Company_Insert_Input>;
  on_conflict?: InputMaybe<Company_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Company_OneArgs = {
  object: Company_Insert_Input;
  on_conflict?: InputMaybe<Company_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Company_SubscriptionArgs = {
  objects: Array<Company_Subscription_Insert_Input>;
  on_conflict?: InputMaybe<Company_Subscription_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Company_Subscription_OneArgs = {
  object: Company_Subscription_Insert_Input;
  on_conflict?: InputMaybe<Company_Subscription_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ConsentArgs = {
  objects: Array<Consent_Insert_Input>;
  on_conflict?: InputMaybe<Consent_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Consent_OneArgs = {
  object: Consent_Insert_Input;
  on_conflict?: InputMaybe<Consent_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Consent_StatusArgs = {
  objects: Array<Consent_Status_Insert_Input>;
  on_conflict?: InputMaybe<Consent_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Consent_Status_OneArgs = {
  object: Consent_Status_Insert_Input;
  on_conflict?: InputMaybe<Consent_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Consent_TypeArgs = {
  objects: Array<Consent_Type_Insert_Input>;
  on_conflict?: InputMaybe<Consent_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Consent_Type_OneArgs = {
  object: Consent_Type_Insert_Input;
  on_conflict?: InputMaybe<Consent_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CountryArgs = {
  objects: Array<Country_Insert_Input>;
  on_conflict?: InputMaybe<Country_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Country_OneArgs = {
  object: Country_Insert_Input;
  on_conflict?: InputMaybe<Country_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CurrencyArgs = {
  objects: Array<Currency_Insert_Input>;
  on_conflict?: InputMaybe<Currency_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Currency_OneArgs = {
  object: Currency_Insert_Input;
  on_conflict?: InputMaybe<Currency_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DepartmentArgs = {
  objects: Array<Department_Insert_Input>;
  on_conflict?: InputMaybe<Department_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Department_OneArgs = {
  object: Department_Insert_Input;
  on_conflict?: InputMaybe<Department_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Education_TypeArgs = {
  objects: Array<Education_Type_Insert_Input>;
  on_conflict?: InputMaybe<Education_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Education_Type_OneArgs = {
  object: Education_Type_Insert_Input;
  on_conflict?: InputMaybe<Education_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Interview_CriterionArgs = {
  objects: Array<Interview_Criterion_Insert_Input>;
  on_conflict?: InputMaybe<Interview_Criterion_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Interview_Criterion_OneArgs = {
  object: Interview_Criterion_Insert_Input;
  on_conflict?: InputMaybe<Interview_Criterion_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Interview_TemplateArgs = {
  objects: Array<Interview_Template_Insert_Input>;
  on_conflict?: InputMaybe<Interview_Template_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Interview_Template_OneArgs = {
  object: Interview_Template_Insert_Input;
  on_conflict?: InputMaybe<Interview_Template_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Interview_Template_Review_Metric_QuestionArgs =
  {
    objects: Array<Interview_Template_Review_Metric_Question_Insert_Input>;
    on_conflict?: InputMaybe<Interview_Template_Review_Metric_Question_On_Conflict>;
  };

/** mutation root */
export type Mutation_RootInsert_Interview_Template_Review_Metric_Question_OneArgs =
  {
    object: Interview_Template_Review_Metric_Question_Insert_Input;
    on_conflict?: InputMaybe<Interview_Template_Review_Metric_Question_On_Conflict>;
  };

/** mutation root */
export type Mutation_RootInsert_Job_DescriptionArgs = {
  objects: Array<Job_Description_Insert_Input>;
  on_conflict?: InputMaybe<Job_Description_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Job_Description_OneArgs = {
  object: Job_Description_Insert_Input;
  on_conflict?: InputMaybe<Job_Description_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Job_Description_StepArgs = {
  objects: Array<Job_Description_Step_Insert_Input>;
  on_conflict?: InputMaybe<Job_Description_Step_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Job_Description_Step_OneArgs = {
  object: Job_Description_Step_Insert_Input;
  on_conflict?: InputMaybe<Job_Description_Step_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_LanguageArgs = {
  objects: Array<Language_Insert_Input>;
  on_conflict?: InputMaybe<Language_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Language_OneArgs = {
  object: Language_Insert_Input;
  on_conflict?: InputMaybe<Language_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_PartnerArgs = {
  objects: Array<Partner_Insert_Input>;
  on_conflict?: InputMaybe<Partner_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Partner_OneArgs = {
  object: Partner_Insert_Input;
  on_conflict?: InputMaybe<Partner_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ProductArgs = {
  objects: Array<Product_Insert_Input>;
  on_conflict?: InputMaybe<Product_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Product_OneArgs = {
  object: Product_Insert_Input;
  on_conflict?: InputMaybe<Product_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Product_PriceArgs = {
  objects: Array<Product_Price_Insert_Input>;
  on_conflict?: InputMaybe<Product_Price_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Product_Price_OneArgs = {
  object: Product_Price_Insert_Input;
  on_conflict?: InputMaybe<Product_Price_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ProjectArgs = {
  objects: Array<Project_Insert_Input>;
  on_conflict?: InputMaybe<Project_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_OneArgs = {
  object: Project_Insert_Input;
  on_conflict?: InputMaybe<Project_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_StatusArgs = {
  objects: Array<Project_Status_Insert_Input>;
  on_conflict?: InputMaybe<Project_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Status_OneArgs = {
  object: Project_Status_Insert_Input;
  on_conflict?: InputMaybe<Project_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_UserArgs = {
  objects: Array<Project_User_Insert_Input>;
  on_conflict?: InputMaybe<Project_User_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_User_OneArgs = {
  object: Project_User_Insert_Input;
  on_conflict?: InputMaybe<Project_User_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Review_MetricArgs = {
  objects: Array<Review_Metric_Insert_Input>;
  on_conflict?: InputMaybe<Review_Metric_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Review_Metric_OneArgs = {
  object: Review_Metric_Insert_Input;
  on_conflict?: InputMaybe<Review_Metric_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Review_Metric_QuestionArgs = {
  objects: Array<Review_Metric_Question_Insert_Input>;
  on_conflict?: InputMaybe<Review_Metric_Question_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Review_Metric_Question_CriterionArgs = {
  objects: Array<Review_Metric_Question_Criterion_Insert_Input>;
  on_conflict?: InputMaybe<Review_Metric_Question_Criterion_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Review_Metric_Question_Criterion_OneArgs = {
  object: Review_Metric_Question_Criterion_Insert_Input;
  on_conflict?: InputMaybe<Review_Metric_Question_Criterion_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Review_Metric_Question_OneArgs = {
  object: Review_Metric_Question_Insert_Input;
  on_conflict?: InputMaybe<Review_Metric_Question_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Review_Metric_Question_ResponseArgs = {
  objects: Array<Review_Metric_Question_Response_Insert_Input>;
  on_conflict?: InputMaybe<Review_Metric_Question_Response_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Review_Metric_Question_Response_OneArgs = {
  object: Review_Metric_Question_Response_Insert_Input;
  on_conflict?: InputMaybe<Review_Metric_Question_Response_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Review_Metric_Question_TypeArgs = {
  objects: Array<Review_Metric_Question_Type_Insert_Input>;
  on_conflict?: InputMaybe<Review_Metric_Question_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Review_Metric_Question_Type_OneArgs = {
  object: Review_Metric_Question_Type_Insert_Input;
  on_conflict?: InputMaybe<Review_Metric_Question_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_RoleArgs = {
  objects: Array<Role_Insert_Input>;
  on_conflict?: InputMaybe<Role_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Role_Academic_DepartmentArgs = {
  objects: Array<Role_Academic_Department_Insert_Input>;
  on_conflict?: InputMaybe<Role_Academic_Department_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Role_Academic_Department_OneArgs = {
  object: Role_Academic_Department_Insert_Input;
  on_conflict?: InputMaybe<Role_Academic_Department_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Role_BenefitArgs = {
  objects: Array<Role_Benefit_Insert_Input>;
  on_conflict?: InputMaybe<Role_Benefit_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Role_Benefit_OneArgs = {
  object: Role_Benefit_Insert_Input;
  on_conflict?: InputMaybe<Role_Benefit_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Role_CompetenceArgs = {
  objects: Array<Role_Competence_Insert_Input>;
  on_conflict?: InputMaybe<Role_Competence_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Role_Competence_OneArgs = {
  object: Role_Competence_Insert_Input;
  on_conflict?: InputMaybe<Role_Competence_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Role_DescriptionArgs = {
  objects: Array<Role_Description_Insert_Input>;
  on_conflict?: InputMaybe<Role_Description_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Role_Description_OneArgs = {
  object: Role_Description_Insert_Input;
  on_conflict?: InputMaybe<Role_Description_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Role_OneArgs = {
  object: Role_Insert_Input;
  on_conflict?: InputMaybe<Role_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Role_ResponsibilityArgs = {
  objects: Array<Role_Responsibility_Insert_Input>;
  on_conflict?: InputMaybe<Role_Responsibility_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Role_Responsibility_OneArgs = {
  object: Role_Responsibility_Insert_Input;
  on_conflict?: InputMaybe<Role_Responsibility_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Role_Review_MetricArgs = {
  objects: Array<Role_Review_Metric_Insert_Input>;
  on_conflict?: InputMaybe<Role_Review_Metric_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Role_Review_Metric_OneArgs = {
  object: Role_Review_Metric_Insert_Input;
  on_conflict?: InputMaybe<Role_Review_Metric_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Role_SkillArgs = {
  objects: Array<Role_Skill_Insert_Input>;
  on_conflict?: InputMaybe<Role_Skill_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Role_Skill_OneArgs = {
  object: Role_Skill_Insert_Input;
  on_conflict?: InputMaybe<Role_Skill_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_SectorArgs = {
  objects: Array<Sector_Insert_Input>;
  on_conflict?: InputMaybe<Sector_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Sector_OneArgs = {
  object: Sector_Insert_Input;
  on_conflict?: InputMaybe<Sector_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>;
  on_conflict?: InputMaybe<User_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_KeyArgs = {
  objects: Array<User_Key_Insert_Input>;
  on_conflict?: InputMaybe<User_Key_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Key_OneArgs = {
  object: User_Key_Insert_Input;
  on_conflict?: InputMaybe<User_Key_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input;
  on_conflict?: InputMaybe<User_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_RoleArgs = {
  objects: Array<User_Role_Insert_Input>;
  on_conflict?: InputMaybe<User_Role_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Role_OneArgs = {
  object: User_Role_Insert_Input;
  on_conflict?: InputMaybe<User_Role_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_SettingArgs = {
  objects: Array<User_Setting_Insert_Input>;
  on_conflict?: InputMaybe<User_Setting_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Setting_KeyArgs = {
  objects: Array<User_Setting_Key_Insert_Input>;
  on_conflict?: InputMaybe<User_Setting_Key_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Setting_Key_OneArgs = {
  object: User_Setting_Key_Insert_Input;
  on_conflict?: InputMaybe<User_Setting_Key_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Setting_OneArgs = {
  object: User_Setting_Insert_Input;
  on_conflict?: InputMaybe<User_Setting_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_StatusArgs = {
  objects: Array<User_Status_Insert_Input>;
  on_conflict?: InputMaybe<User_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Status_OneArgs = {
  object: User_Status_Insert_Input;
  on_conflict?: InputMaybe<User_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Video_InterviewArgs = {
  objects: Array<Video_Interview_Insert_Input>;
  on_conflict?: InputMaybe<Video_Interview_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Video_Interview_Criterion_ScoreArgs = {
  objects: Array<Video_Interview_Criterion_Score_Insert_Input>;
  on_conflict?: InputMaybe<Video_Interview_Criterion_Score_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Video_Interview_Criterion_Score_OneArgs = {
  object: Video_Interview_Criterion_Score_Insert_Input;
  on_conflict?: InputMaybe<Video_Interview_Criterion_Score_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Video_Interview_OneArgs = {
  object: Video_Interview_Insert_Input;
  on_conflict?: InputMaybe<Video_Interview_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Video_Interview_QuestionArgs = {
  objects: Array<Video_Interview_Question_Insert_Input>;
  on_conflict?: InputMaybe<Video_Interview_Question_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Video_Interview_Question_EventArgs = {
  objects: Array<Video_Interview_Question_Event_Insert_Input>;
  on_conflict?: InputMaybe<Video_Interview_Question_Event_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Video_Interview_Question_Event_OneArgs = {
  object: Video_Interview_Question_Event_Insert_Input;
  on_conflict?: InputMaybe<Video_Interview_Question_Event_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Video_Interview_Question_Event_TypeArgs = {
  objects: Array<Video_Interview_Question_Event_Type_Insert_Input>;
  on_conflict?: InputMaybe<Video_Interview_Question_Event_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Video_Interview_Question_Event_Type_OneArgs = {
  object: Video_Interview_Question_Event_Type_Insert_Input;
  on_conflict?: InputMaybe<Video_Interview_Question_Event_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Video_Interview_Question_OneArgs = {
  object: Video_Interview_Question_Insert_Input;
  on_conflict?: InputMaybe<Video_Interview_Question_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Video_Interview_Question_ScoreArgs = {
  objects: Array<Video_Interview_Question_Score_Insert_Input>;
  on_conflict?: InputMaybe<Video_Interview_Question_Score_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Video_Interview_Question_Score_OneArgs = {
  object: Video_Interview_Question_Score_Insert_Input;
  on_conflict?: InputMaybe<Video_Interview_Question_Score_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Video_Interview_ReviewerArgs = {
  objects: Array<Video_Interview_Reviewer_Insert_Input>;
  on_conflict?: InputMaybe<Video_Interview_Reviewer_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Video_Interview_Reviewer_OneArgs = {
  object: Video_Interview_Reviewer_Insert_Input;
  on_conflict?: InputMaybe<Video_Interview_Reviewer_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Video_Interview_StatusArgs = {
  objects: Array<Video_Interview_Status_Insert_Input>;
  on_conflict?: InputMaybe<Video_Interview_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Video_Interview_Status_HistoryArgs = {
  objects: Array<Video_Interview_Status_History_Insert_Input>;
  on_conflict?: InputMaybe<Video_Interview_Status_History_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Video_Interview_Status_History_OneArgs = {
  object: Video_Interview_Status_History_Insert_Input;
  on_conflict?: InputMaybe<Video_Interview_Status_History_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Video_Interview_Status_OneArgs = {
  object: Video_Interview_Status_Insert_Input;
  on_conflict?: InputMaybe<Video_Interview_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Video_Interview_TypeArgs = {
  objects: Array<Video_Interview_Type_Insert_Input>;
  on_conflict?: InputMaybe<Video_Interview_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Video_Interview_Type_OneArgs = {
  object: Video_Interview_Type_Insert_Input;
  on_conflict?: InputMaybe<Video_Interview_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Video_Process_StatusArgs = {
  objects: Array<Video_Process_Status_Insert_Input>;
  on_conflict?: InputMaybe<Video_Process_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Video_Process_Status_OneArgs = {
  object: Video_Process_Status_Insert_Input;
  on_conflict?: InputMaybe<Video_Process_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInviteMemberArgs = {
  inviteMemberInput: InviteMemberInput;
};

/** mutation root */
export type Mutation_RootLoginArgs = {
  loginInput: LoginInput;
};

/** mutation root */
export type Mutation_RootRegisterArgs = {
  registerInput: RegisterInput;
};

/** mutation root */
export type Mutation_RootResendOtpArgs = {
  resendOtpInput: ResendOtpInput;
};

/** mutation root */
export type Mutation_RootResetPasswordArgs = {
  resetPasswordInput: ResetPasswordInput;
};

/** mutation root */
export type Mutation_RootSendOtpArgs = {
  sendOtpInput: SendOtpInput;
};

/** mutation root */
export type Mutation_RootSendVideoInterviewLinkToCandidatesArgs = {
  ccpIds: Array<Scalars["Int"]>;
  interviewType?: InputMaybe<Scalars["String"]>;
  resetInterview?: InputMaybe<Scalars["Boolean"]>;
};

/** mutation root */
export type Mutation_RootUpdate_Academic_DepartmentArgs = {
  _inc?: InputMaybe<Academic_Department_Inc_Input>;
  _set?: InputMaybe<Academic_Department_Set_Input>;
  where: Academic_Department_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Academic_Department_By_PkArgs = {
  _inc?: InputMaybe<Academic_Department_Inc_Input>;
  _set?: InputMaybe<Academic_Department_Set_Input>;
  pk_columns: Academic_Department_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Academic_Department_ManyArgs = {
  updates: Array<Academic_Department_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Address_TypeArgs = {
  _set?: InputMaybe<Address_Type_Set_Input>;
  where: Address_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Address_Type_By_PkArgs = {
  _set?: InputMaybe<Address_Type_Set_Input>;
  pk_columns: Address_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Address_Type_ManyArgs = {
  updates: Array<Address_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_CityArgs = {
  _inc?: InputMaybe<City_Inc_Input>;
  _set?: InputMaybe<City_Set_Input>;
  where: City_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_City_By_PkArgs = {
  _inc?: InputMaybe<City_Inc_Input>;
  _set?: InputMaybe<City_Set_Input>;
  pk_columns: City_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_City_ManyArgs = {
  updates: Array<City_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ClientArgs = {
  _inc?: InputMaybe<Client_Inc_Input>;
  _set?: InputMaybe<Client_Set_Input>;
  where: Client_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Client_By_PkArgs = {
  _inc?: InputMaybe<Client_Inc_Input>;
  _set?: InputMaybe<Client_Set_Input>;
  pk_columns: Client_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Client_CandidateArgs = {
  _set?: InputMaybe<Client_Candidate_Set_Input>;
  where: Client_Candidate_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Candidate_By_PkArgs = {
  _set?: InputMaybe<Client_Candidate_Set_Input>;
  pk_columns: Client_Candidate_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Candidate_ManyArgs = {
  updates: Array<Client_Candidate_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Candidate_ProjectArgs = {
  _inc?: InputMaybe<Client_Candidate_Project_Inc_Input>;
  _set?: InputMaybe<Client_Candidate_Project_Set_Input>;
  where: Client_Candidate_Project_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Candidate_Project_By_PkArgs = {
  _inc?: InputMaybe<Client_Candidate_Project_Inc_Input>;
  _set?: InputMaybe<Client_Candidate_Project_Set_Input>;
  pk_columns: Client_Candidate_Project_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Candidate_Project_Candidate_List_StatusArgs =
  {
    _set?: InputMaybe<Client_Candidate_Project_Candidate_List_Status_Set_Input>;
    where: Client_Candidate_Project_Candidate_List_Status_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Client_Candidate_Project_Candidate_List_Status_By_PkArgs =
  {
    _set?: InputMaybe<Client_Candidate_Project_Candidate_List_Status_Set_Input>;
    pk_columns: Client_Candidate_Project_Candidate_List_Status_Pk_Columns_Input;
  };

/** mutation root */
export type Mutation_RootUpdate_Client_Candidate_Project_Candidate_List_Status_ManyArgs =
  {
    updates: Array<Client_Candidate_Project_Candidate_List_Status_Updates>;
  };

/** mutation root */
export type Mutation_RootUpdate_Client_Candidate_Project_Candidate_ResolutionArgs =
  {
    _set?: InputMaybe<Client_Candidate_Project_Candidate_Resolution_Set_Input>;
    where: Client_Candidate_Project_Candidate_Resolution_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Client_Candidate_Project_Candidate_Resolution_By_PkArgs =
  {
    _set?: InputMaybe<Client_Candidate_Project_Candidate_Resolution_Set_Input>;
    pk_columns: Client_Candidate_Project_Candidate_Resolution_Pk_Columns_Input;
  };

/** mutation root */
export type Mutation_RootUpdate_Client_Candidate_Project_Candidate_Resolution_ManyArgs =
  {
    updates: Array<Client_Candidate_Project_Candidate_Resolution_Updates>;
  };

/** mutation root */
export type Mutation_RootUpdate_Client_Candidate_Project_ManyArgs = {
  updates: Array<Client_Candidate_Project_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Candidate_Project_NoteArgs = {
  _inc?: InputMaybe<Client_Candidate_Project_Note_Inc_Input>;
  _set?: InputMaybe<Client_Candidate_Project_Note_Set_Input>;
  where: Client_Candidate_Project_Note_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Candidate_Project_Note_By_PkArgs = {
  _inc?: InputMaybe<Client_Candidate_Project_Note_Inc_Input>;
  _set?: InputMaybe<Client_Candidate_Project_Note_Set_Input>;
  pk_columns: Client_Candidate_Project_Note_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Candidate_Project_Note_ManyArgs = {
  updates: Array<Client_Candidate_Project_Note_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Client_LocationArgs = {
  _inc?: InputMaybe<Client_Location_Inc_Input>;
  _set?: InputMaybe<Client_Location_Set_Input>;
  where: Client_Location_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Location_By_PkArgs = {
  _inc?: InputMaybe<Client_Location_Inc_Input>;
  _set?: InputMaybe<Client_Location_Set_Input>;
  pk_columns: Client_Location_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Location_ManyArgs = {
  updates: Array<Client_Location_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Client_ManyArgs = {
  updates: Array<Client_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Client_SectorArgs = {
  _inc?: InputMaybe<Client_Sector_Inc_Input>;
  _set?: InputMaybe<Client_Sector_Set_Input>;
  where: Client_Sector_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Sector_By_PkArgs = {
  _inc?: InputMaybe<Client_Sector_Inc_Input>;
  _set?: InputMaybe<Client_Sector_Set_Input>;
  pk_columns: Client_Sector_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Sector_ManyArgs = {
  updates: Array<Client_Sector_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Client_SettingArgs = {
  _inc?: InputMaybe<Client_Setting_Inc_Input>;
  _set?: InputMaybe<Client_Setting_Set_Input>;
  where: Client_Setting_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Setting_By_PkArgs = {
  _inc?: InputMaybe<Client_Setting_Inc_Input>;
  _set?: InputMaybe<Client_Setting_Set_Input>;
  pk_columns: Client_Setting_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Setting_KeyArgs = {
  _set?: InputMaybe<Client_Setting_Key_Set_Input>;
  where: Client_Setting_Key_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Setting_Key_By_PkArgs = {
  _set?: InputMaybe<Client_Setting_Key_Set_Input>;
  pk_columns: Client_Setting_Key_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Setting_Key_ManyArgs = {
  updates: Array<Client_Setting_Key_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Setting_ManyArgs = {
  updates: Array<Client_Setting_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_CompanyArgs = {
  _set?: InputMaybe<Company_Set_Input>;
  where: Company_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Company_By_PkArgs = {
  _set?: InputMaybe<Company_Set_Input>;
  pk_columns: Company_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Company_ManyArgs = {
  updates: Array<Company_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Company_SubscriptionArgs = {
  _set?: InputMaybe<Company_Subscription_Set_Input>;
  where: Company_Subscription_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Company_Subscription_By_PkArgs = {
  _set?: InputMaybe<Company_Subscription_Set_Input>;
  pk_columns: Company_Subscription_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Company_Subscription_ManyArgs = {
  updates: Array<Company_Subscription_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ConsentArgs = {
  _inc?: InputMaybe<Consent_Inc_Input>;
  _set?: InputMaybe<Consent_Set_Input>;
  where: Consent_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Consent_By_PkArgs = {
  _inc?: InputMaybe<Consent_Inc_Input>;
  _set?: InputMaybe<Consent_Set_Input>;
  pk_columns: Consent_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Consent_ManyArgs = {
  updates: Array<Consent_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Consent_StatusArgs = {
  _set?: InputMaybe<Consent_Status_Set_Input>;
  where: Consent_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Consent_Status_By_PkArgs = {
  _set?: InputMaybe<Consent_Status_Set_Input>;
  pk_columns: Consent_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Consent_Status_ManyArgs = {
  updates: Array<Consent_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Consent_TypeArgs = {
  _set?: InputMaybe<Consent_Type_Set_Input>;
  where: Consent_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Consent_Type_By_PkArgs = {
  _set?: InputMaybe<Consent_Type_Set_Input>;
  pk_columns: Consent_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Consent_Type_ManyArgs = {
  updates: Array<Consent_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_CountryArgs = {
  _inc?: InputMaybe<Country_Inc_Input>;
  _set?: InputMaybe<Country_Set_Input>;
  where: Country_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Country_By_PkArgs = {
  _inc?: InputMaybe<Country_Inc_Input>;
  _set?: InputMaybe<Country_Set_Input>;
  pk_columns: Country_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Country_ManyArgs = {
  updates: Array<Country_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_CurrencyArgs = {
  _set?: InputMaybe<Currency_Set_Input>;
  where: Currency_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Currency_By_PkArgs = {
  _set?: InputMaybe<Currency_Set_Input>;
  pk_columns: Currency_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Currency_ManyArgs = {
  updates: Array<Currency_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_DepartmentArgs = {
  _inc?: InputMaybe<Department_Inc_Input>;
  _set?: InputMaybe<Department_Set_Input>;
  where: Department_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Department_By_PkArgs = {
  _inc?: InputMaybe<Department_Inc_Input>;
  _set?: InputMaybe<Department_Set_Input>;
  pk_columns: Department_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Department_ManyArgs = {
  updates: Array<Department_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Education_TypeArgs = {
  _inc?: InputMaybe<Education_Type_Inc_Input>;
  _set?: InputMaybe<Education_Type_Set_Input>;
  where: Education_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Education_Type_By_PkArgs = {
  _inc?: InputMaybe<Education_Type_Inc_Input>;
  _set?: InputMaybe<Education_Type_Set_Input>;
  pk_columns: Education_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Education_Type_ManyArgs = {
  updates: Array<Education_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Interview_CriterionArgs = {
  _inc?: InputMaybe<Interview_Criterion_Inc_Input>;
  _set?: InputMaybe<Interview_Criterion_Set_Input>;
  where: Interview_Criterion_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Interview_Criterion_By_PkArgs = {
  _inc?: InputMaybe<Interview_Criterion_Inc_Input>;
  _set?: InputMaybe<Interview_Criterion_Set_Input>;
  pk_columns: Interview_Criterion_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Interview_Criterion_ManyArgs = {
  updates: Array<Interview_Criterion_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Interview_TemplateArgs = {
  _inc?: InputMaybe<Interview_Template_Inc_Input>;
  _set?: InputMaybe<Interview_Template_Set_Input>;
  where: Interview_Template_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Interview_Template_By_PkArgs = {
  _inc?: InputMaybe<Interview_Template_Inc_Input>;
  _set?: InputMaybe<Interview_Template_Set_Input>;
  pk_columns: Interview_Template_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Interview_Template_ManyArgs = {
  updates: Array<Interview_Template_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Interview_Template_Review_Metric_QuestionArgs =
  {
    _inc?: InputMaybe<Interview_Template_Review_Metric_Question_Inc_Input>;
    _set?: InputMaybe<Interview_Template_Review_Metric_Question_Set_Input>;
    where: Interview_Template_Review_Metric_Question_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Interview_Template_Review_Metric_Question_By_PkArgs =
  {
    _inc?: InputMaybe<Interview_Template_Review_Metric_Question_Inc_Input>;
    _set?: InputMaybe<Interview_Template_Review_Metric_Question_Set_Input>;
    pk_columns: Interview_Template_Review_Metric_Question_Pk_Columns_Input;
  };

/** mutation root */
export type Mutation_RootUpdate_Interview_Template_Review_Metric_Question_ManyArgs =
  {
    updates: Array<Interview_Template_Review_Metric_Question_Updates>;
  };

/** mutation root */
export type Mutation_RootUpdate_Job_DescriptionArgs = {
  _append?: InputMaybe<Job_Description_Append_Input>;
  _delete_at_path?: InputMaybe<Job_Description_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Job_Description_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Job_Description_Delete_Key_Input>;
  _prepend?: InputMaybe<Job_Description_Prepend_Input>;
  _set?: InputMaybe<Job_Description_Set_Input>;
  where: Job_Description_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Description_By_PkArgs = {
  _append?: InputMaybe<Job_Description_Append_Input>;
  _delete_at_path?: InputMaybe<Job_Description_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Job_Description_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Job_Description_Delete_Key_Input>;
  _prepend?: InputMaybe<Job_Description_Prepend_Input>;
  _set?: InputMaybe<Job_Description_Set_Input>;
  pk_columns: Job_Description_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Description_ManyArgs = {
  updates: Array<Job_Description_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Description_StepArgs = {
  _set?: InputMaybe<Job_Description_Step_Set_Input>;
  where: Job_Description_Step_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Description_Step_By_PkArgs = {
  _set?: InputMaybe<Job_Description_Step_Set_Input>;
  pk_columns: Job_Description_Step_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Job_Description_Step_ManyArgs = {
  updates: Array<Job_Description_Step_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_LanguageArgs = {
  _set?: InputMaybe<Language_Set_Input>;
  where: Language_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Language_By_PkArgs = {
  _set?: InputMaybe<Language_Set_Input>;
  pk_columns: Language_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Language_ManyArgs = {
  updates: Array<Language_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_PartnerArgs = {
  _set?: InputMaybe<Partner_Set_Input>;
  where: Partner_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Partner_By_PkArgs = {
  _set?: InputMaybe<Partner_Set_Input>;
  pk_columns: Partner_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Partner_ManyArgs = {
  updates: Array<Partner_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ProductArgs = {
  _set?: InputMaybe<Product_Set_Input>;
  where: Product_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Product_By_PkArgs = {
  _set?: InputMaybe<Product_Set_Input>;
  pk_columns: Product_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Product_ManyArgs = {
  updates: Array<Product_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Product_PriceArgs = {
  _inc?: InputMaybe<Product_Price_Inc_Input>;
  _set?: InputMaybe<Product_Price_Set_Input>;
  where: Product_Price_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Product_Price_By_PkArgs = {
  _inc?: InputMaybe<Product_Price_Inc_Input>;
  _set?: InputMaybe<Product_Price_Set_Input>;
  pk_columns: Product_Price_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Product_Price_ManyArgs = {
  updates: Array<Product_Price_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ProjectArgs = {
  _inc?: InputMaybe<Project_Inc_Input>;
  _set?: InputMaybe<Project_Set_Input>;
  where: Project_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Project_By_PkArgs = {
  _inc?: InputMaybe<Project_Inc_Input>;
  _set?: InputMaybe<Project_Set_Input>;
  pk_columns: Project_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Project_ManyArgs = {
  updates: Array<Project_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Project_StatusArgs = {
  _set?: InputMaybe<Project_Status_Set_Input>;
  where: Project_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Status_By_PkArgs = {
  _set?: InputMaybe<Project_Status_Set_Input>;
  pk_columns: Project_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Status_ManyArgs = {
  updates: Array<Project_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Project_UserArgs = {
  _inc?: InputMaybe<Project_User_Inc_Input>;
  _set?: InputMaybe<Project_User_Set_Input>;
  where: Project_User_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Project_User_By_PkArgs = {
  _inc?: InputMaybe<Project_User_Inc_Input>;
  _set?: InputMaybe<Project_User_Set_Input>;
  pk_columns: Project_User_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Project_User_ManyArgs = {
  updates: Array<Project_User_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Review_MetricArgs = {
  _inc?: InputMaybe<Review_Metric_Inc_Input>;
  _set?: InputMaybe<Review_Metric_Set_Input>;
  where: Review_Metric_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Review_Metric_By_PkArgs = {
  _inc?: InputMaybe<Review_Metric_Inc_Input>;
  _set?: InputMaybe<Review_Metric_Set_Input>;
  pk_columns: Review_Metric_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Review_Metric_ManyArgs = {
  updates: Array<Review_Metric_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Review_Metric_QuestionArgs = {
  _inc?: InputMaybe<Review_Metric_Question_Inc_Input>;
  _set?: InputMaybe<Review_Metric_Question_Set_Input>;
  where: Review_Metric_Question_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Review_Metric_Question_By_PkArgs = {
  _inc?: InputMaybe<Review_Metric_Question_Inc_Input>;
  _set?: InputMaybe<Review_Metric_Question_Set_Input>;
  pk_columns: Review_Metric_Question_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Review_Metric_Question_CriterionArgs = {
  _inc?: InputMaybe<Review_Metric_Question_Criterion_Inc_Input>;
  _set?: InputMaybe<Review_Metric_Question_Criterion_Set_Input>;
  where: Review_Metric_Question_Criterion_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Review_Metric_Question_Criterion_By_PkArgs = {
  _inc?: InputMaybe<Review_Metric_Question_Criterion_Inc_Input>;
  _set?: InputMaybe<Review_Metric_Question_Criterion_Set_Input>;
  pk_columns: Review_Metric_Question_Criterion_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Review_Metric_Question_Criterion_ManyArgs = {
  updates: Array<Review_Metric_Question_Criterion_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Review_Metric_Question_ManyArgs = {
  updates: Array<Review_Metric_Question_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Review_Metric_Question_ResponseArgs = {
  _inc?: InputMaybe<Review_Metric_Question_Response_Inc_Input>;
  _set?: InputMaybe<Review_Metric_Question_Response_Set_Input>;
  where: Review_Metric_Question_Response_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Review_Metric_Question_Response_By_PkArgs = {
  _inc?: InputMaybe<Review_Metric_Question_Response_Inc_Input>;
  _set?: InputMaybe<Review_Metric_Question_Response_Set_Input>;
  pk_columns: Review_Metric_Question_Response_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Review_Metric_Question_Response_ManyArgs = {
  updates: Array<Review_Metric_Question_Response_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Review_Metric_Question_TypeArgs = {
  _set?: InputMaybe<Review_Metric_Question_Type_Set_Input>;
  where: Review_Metric_Question_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Review_Metric_Question_Type_By_PkArgs = {
  _set?: InputMaybe<Review_Metric_Question_Type_Set_Input>;
  pk_columns: Review_Metric_Question_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Review_Metric_Question_Type_ManyArgs = {
  updates: Array<Review_Metric_Question_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_RoleArgs = {
  _inc?: InputMaybe<Role_Inc_Input>;
  _set?: InputMaybe<Role_Set_Input>;
  where: Role_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Role_Academic_DepartmentArgs = {
  _inc?: InputMaybe<Role_Academic_Department_Inc_Input>;
  _set?: InputMaybe<Role_Academic_Department_Set_Input>;
  where: Role_Academic_Department_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Role_Academic_Department_By_PkArgs = {
  _inc?: InputMaybe<Role_Academic_Department_Inc_Input>;
  _set?: InputMaybe<Role_Academic_Department_Set_Input>;
  pk_columns: Role_Academic_Department_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Role_Academic_Department_ManyArgs = {
  updates: Array<Role_Academic_Department_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Role_BenefitArgs = {
  _set?: InputMaybe<Role_Benefit_Set_Input>;
  where: Role_Benefit_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Role_Benefit_By_PkArgs = {
  _set?: InputMaybe<Role_Benefit_Set_Input>;
  pk_columns: Role_Benefit_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Role_Benefit_ManyArgs = {
  updates: Array<Role_Benefit_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Role_By_PkArgs = {
  _inc?: InputMaybe<Role_Inc_Input>;
  _set?: InputMaybe<Role_Set_Input>;
  pk_columns: Role_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Role_CompetenceArgs = {
  _set?: InputMaybe<Role_Competence_Set_Input>;
  where: Role_Competence_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Role_Competence_By_PkArgs = {
  _set?: InputMaybe<Role_Competence_Set_Input>;
  pk_columns: Role_Competence_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Role_Competence_ManyArgs = {
  updates: Array<Role_Competence_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Role_DescriptionArgs = {
  _set?: InputMaybe<Role_Description_Set_Input>;
  where: Role_Description_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Role_Description_By_PkArgs = {
  _set?: InputMaybe<Role_Description_Set_Input>;
  pk_columns: Role_Description_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Role_Description_ManyArgs = {
  updates: Array<Role_Description_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Role_ManyArgs = {
  updates: Array<Role_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Role_ResponsibilityArgs = {
  _set?: InputMaybe<Role_Responsibility_Set_Input>;
  where: Role_Responsibility_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Role_Responsibility_By_PkArgs = {
  _set?: InputMaybe<Role_Responsibility_Set_Input>;
  pk_columns: Role_Responsibility_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Role_Responsibility_ManyArgs = {
  updates: Array<Role_Responsibility_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Role_Review_MetricArgs = {
  _inc?: InputMaybe<Role_Review_Metric_Inc_Input>;
  _set?: InputMaybe<Role_Review_Metric_Set_Input>;
  where: Role_Review_Metric_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Role_Review_Metric_By_PkArgs = {
  _inc?: InputMaybe<Role_Review_Metric_Inc_Input>;
  _set?: InputMaybe<Role_Review_Metric_Set_Input>;
  pk_columns: Role_Review_Metric_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Role_Review_Metric_ManyArgs = {
  updates: Array<Role_Review_Metric_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Role_SkillArgs = {
  _inc?: InputMaybe<Role_Skill_Inc_Input>;
  _set?: InputMaybe<Role_Skill_Set_Input>;
  where: Role_Skill_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Role_Skill_By_PkArgs = {
  _inc?: InputMaybe<Role_Skill_Inc_Input>;
  _set?: InputMaybe<Role_Skill_Set_Input>;
  pk_columns: Role_Skill_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Role_Skill_ManyArgs = {
  updates: Array<Role_Skill_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_SectorArgs = {
  _inc?: InputMaybe<Sector_Inc_Input>;
  _set?: InputMaybe<Sector_Set_Input>;
  where: Sector_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Sector_By_PkArgs = {
  _inc?: InputMaybe<Sector_Inc_Input>;
  _set?: InputMaybe<Sector_Set_Input>;
  pk_columns: Sector_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Sector_ManyArgs = {
  updates: Array<Sector_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _set?: InputMaybe<User_Set_Input>;
  where: User_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _set?: InputMaybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_KeyArgs = {
  _set?: InputMaybe<User_Key_Set_Input>;
  where: User_Key_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Key_By_PkArgs = {
  _set?: InputMaybe<User_Key_Set_Input>;
  pk_columns: User_Key_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Key_ManyArgs = {
  updates: Array<User_Key_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_ManyArgs = {
  updates: Array<User_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_RoleArgs = {
  _inc?: InputMaybe<User_Role_Inc_Input>;
  _set?: InputMaybe<User_Role_Set_Input>;
  where: User_Role_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Role_By_PkArgs = {
  _inc?: InputMaybe<User_Role_Inc_Input>;
  _set?: InputMaybe<User_Role_Set_Input>;
  pk_columns: User_Role_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Role_ManyArgs = {
  updates: Array<User_Role_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_SettingArgs = {
  _inc?: InputMaybe<User_Setting_Inc_Input>;
  _set?: InputMaybe<User_Setting_Set_Input>;
  where: User_Setting_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Setting_By_PkArgs = {
  _inc?: InputMaybe<User_Setting_Inc_Input>;
  _set?: InputMaybe<User_Setting_Set_Input>;
  pk_columns: User_Setting_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Setting_KeyArgs = {
  _set?: InputMaybe<User_Setting_Key_Set_Input>;
  where: User_Setting_Key_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Setting_Key_By_PkArgs = {
  _set?: InputMaybe<User_Setting_Key_Set_Input>;
  pk_columns: User_Setting_Key_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Setting_Key_ManyArgs = {
  updates: Array<User_Setting_Key_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_Setting_ManyArgs = {
  updates: Array<User_Setting_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_StatusArgs = {
  _set?: InputMaybe<User_Status_Set_Input>;
  where: User_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Status_By_PkArgs = {
  _set?: InputMaybe<User_Status_Set_Input>;
  pk_columns: User_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Status_ManyArgs = {
  updates: Array<User_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Video_InterviewArgs = {
  _inc?: InputMaybe<Video_Interview_Inc_Input>;
  _set?: InputMaybe<Video_Interview_Set_Input>;
  where: Video_Interview_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Video_Interview_By_PkArgs = {
  _inc?: InputMaybe<Video_Interview_Inc_Input>;
  _set?: InputMaybe<Video_Interview_Set_Input>;
  pk_columns: Video_Interview_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Video_Interview_Criterion_ScoreArgs = {
  _inc?: InputMaybe<Video_Interview_Criterion_Score_Inc_Input>;
  _set?: InputMaybe<Video_Interview_Criterion_Score_Set_Input>;
  where: Video_Interview_Criterion_Score_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Video_Interview_Criterion_Score_By_PkArgs = {
  _inc?: InputMaybe<Video_Interview_Criterion_Score_Inc_Input>;
  _set?: InputMaybe<Video_Interview_Criterion_Score_Set_Input>;
  pk_columns: Video_Interview_Criterion_Score_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Video_Interview_Criterion_Score_ManyArgs = {
  updates: Array<Video_Interview_Criterion_Score_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Video_Interview_ManyArgs = {
  updates: Array<Video_Interview_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Video_Interview_QuestionArgs = {
  _inc?: InputMaybe<Video_Interview_Question_Inc_Input>;
  _set?: InputMaybe<Video_Interview_Question_Set_Input>;
  where: Video_Interview_Question_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Video_Interview_Question_By_PkArgs = {
  _inc?: InputMaybe<Video_Interview_Question_Inc_Input>;
  _set?: InputMaybe<Video_Interview_Question_Set_Input>;
  pk_columns: Video_Interview_Question_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Video_Interview_Question_EventArgs = {
  _inc?: InputMaybe<Video_Interview_Question_Event_Inc_Input>;
  _set?: InputMaybe<Video_Interview_Question_Event_Set_Input>;
  where: Video_Interview_Question_Event_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Video_Interview_Question_Event_By_PkArgs = {
  _inc?: InputMaybe<Video_Interview_Question_Event_Inc_Input>;
  _set?: InputMaybe<Video_Interview_Question_Event_Set_Input>;
  pk_columns: Video_Interview_Question_Event_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Video_Interview_Question_Event_ManyArgs = {
  updates: Array<Video_Interview_Question_Event_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Video_Interview_Question_Event_TypeArgs = {
  _set?: InputMaybe<Video_Interview_Question_Event_Type_Set_Input>;
  where: Video_Interview_Question_Event_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Video_Interview_Question_Event_Type_By_PkArgs =
  {
    _set?: InputMaybe<Video_Interview_Question_Event_Type_Set_Input>;
    pk_columns: Video_Interview_Question_Event_Type_Pk_Columns_Input;
  };

/** mutation root */
export type Mutation_RootUpdate_Video_Interview_Question_Event_Type_ManyArgs = {
  updates: Array<Video_Interview_Question_Event_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Video_Interview_Question_ManyArgs = {
  updates: Array<Video_Interview_Question_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Video_Interview_Question_ScoreArgs = {
  _inc?: InputMaybe<Video_Interview_Question_Score_Inc_Input>;
  _set?: InputMaybe<Video_Interview_Question_Score_Set_Input>;
  where: Video_Interview_Question_Score_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Video_Interview_Question_Score_By_PkArgs = {
  _inc?: InputMaybe<Video_Interview_Question_Score_Inc_Input>;
  _set?: InputMaybe<Video_Interview_Question_Score_Set_Input>;
  pk_columns: Video_Interview_Question_Score_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Video_Interview_Question_Score_ManyArgs = {
  updates: Array<Video_Interview_Question_Score_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Video_Interview_ReviewerArgs = {
  _set?: InputMaybe<Video_Interview_Reviewer_Set_Input>;
  where: Video_Interview_Reviewer_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Video_Interview_Reviewer_By_PkArgs = {
  _set?: InputMaybe<Video_Interview_Reviewer_Set_Input>;
  pk_columns: Video_Interview_Reviewer_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Video_Interview_Reviewer_ManyArgs = {
  updates: Array<Video_Interview_Reviewer_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Video_Interview_StatusArgs = {
  _set?: InputMaybe<Video_Interview_Status_Set_Input>;
  where: Video_Interview_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Video_Interview_Status_By_PkArgs = {
  _set?: InputMaybe<Video_Interview_Status_Set_Input>;
  pk_columns: Video_Interview_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Video_Interview_Status_HistoryArgs = {
  _inc?: InputMaybe<Video_Interview_Status_History_Inc_Input>;
  _set?: InputMaybe<Video_Interview_Status_History_Set_Input>;
  where: Video_Interview_Status_History_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Video_Interview_Status_History_By_PkArgs = {
  _inc?: InputMaybe<Video_Interview_Status_History_Inc_Input>;
  _set?: InputMaybe<Video_Interview_Status_History_Set_Input>;
  pk_columns: Video_Interview_Status_History_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Video_Interview_Status_History_ManyArgs = {
  updates: Array<Video_Interview_Status_History_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Video_Interview_Status_ManyArgs = {
  updates: Array<Video_Interview_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Video_Interview_TypeArgs = {
  _set?: InputMaybe<Video_Interview_Type_Set_Input>;
  where: Video_Interview_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Video_Interview_Type_By_PkArgs = {
  _set?: InputMaybe<Video_Interview_Type_Set_Input>;
  pk_columns: Video_Interview_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Video_Interview_Type_ManyArgs = {
  updates: Array<Video_Interview_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Video_Process_StatusArgs = {
  _set?: InputMaybe<Video_Process_Status_Set_Input>;
  where: Video_Process_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Video_Process_Status_By_PkArgs = {
  _set?: InputMaybe<Video_Process_Status_Set_Input>;
  pk_columns: Video_Process_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Video_Process_Status_ManyArgs = {
  updates: Array<Video_Process_Status_Updates>;
};

/** mutation root */
export type Mutation_RootVerify2FaArgs = {
  verify2FAInput: Verify2FaInput;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["numeric"]>;
  _gt?: InputMaybe<Scalars["numeric"]>;
  _gte?: InputMaybe<Scalars["numeric"]>;
  _in?: InputMaybe<Array<Scalars["numeric"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["numeric"]>;
  _lte?: InputMaybe<Scalars["numeric"]>;
  _neq?: InputMaybe<Scalars["numeric"]>;
  _nin?: InputMaybe<Array<Scalars["numeric"]>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = "asc",
  /** in ascending order, nulls first */
  AscNullsFirst = "asc_nulls_first",
  /** in ascending order, nulls last */
  AscNullsLast = "asc_nulls_last",
  /** in descending order, nulls first */
  Desc = "desc",
  /** in descending order, nulls first */
  DescNullsFirst = "desc_nulls_first",
  /** in descending order, nulls last */
  DescNullsLast = "desc_nulls_last",
}

/** columns and relationships of "partner" */
export type Partner = {
  __typename?: "partner";
  callback_url?: Maybe<Scalars["String"]>;
  created_at: Scalars["timestamptz"];
  id: Scalars["uuid"];
  name: Scalars["String"];
};

/** aggregated selection of "partner" */
export type Partner_Aggregate = {
  __typename?: "partner_aggregate";
  aggregate?: Maybe<Partner_Aggregate_Fields>;
  nodes: Array<Partner>;
};

/** aggregate fields of "partner" */
export type Partner_Aggregate_Fields = {
  __typename?: "partner_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Partner_Max_Fields>;
  min?: Maybe<Partner_Min_Fields>;
};

/** aggregate fields of "partner" */
export type Partner_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Partner_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "partner". All fields are combined with a logical 'AND'. */
export type Partner_Bool_Exp = {
  _and?: InputMaybe<Array<Partner_Bool_Exp>>;
  _not?: InputMaybe<Partner_Bool_Exp>;
  _or?: InputMaybe<Array<Partner_Bool_Exp>>;
  callback_url?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "partner" */
export enum Partner_Constraint {
  /** unique or primary key constraint on columns "id" */
  PartnerPkey = "partner_pkey",
}

/** input type for inserting data into table "partner" */
export type Partner_Insert_Input = {
  callback_url?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Partner_Max_Fields = {
  __typename?: "partner_max_fields";
  callback_url?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Partner_Min_Fields = {
  __typename?: "partner_min_fields";
  callback_url?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "partner" */
export type Partner_Mutation_Response = {
  __typename?: "partner_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Partner>;
};

/** input type for inserting object relation for remote table "partner" */
export type Partner_Obj_Rel_Insert_Input = {
  data: Partner_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Partner_On_Conflict>;
};

/** on_conflict condition type for table "partner" */
export type Partner_On_Conflict = {
  constraint: Partner_Constraint;
  update_columns?: Array<Partner_Update_Column>;
  where?: InputMaybe<Partner_Bool_Exp>;
};

/** Ordering options when selecting data from "partner". */
export type Partner_Order_By = {
  callback_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: partner */
export type Partner_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "partner" */
export enum Partner_Select_Column {
  /** column name */
  CallbackUrl = "callback_url",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
}

/** input type for updating data in table "partner" */
export type Partner_Set_Input = {
  callback_url?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "partner" */
export type Partner_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Partner_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Partner_Stream_Cursor_Value_Input = {
  callback_url?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "partner" */
export enum Partner_Update_Column {
  /** column name */
  CallbackUrl = "callback_url",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
}

export type Partner_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Partner_Set_Input>;
  /** filter the rows which have to be updated */
  where: Partner_Bool_Exp;
};

/** columns and relationships of "product" */
export type Product = {
  __typename?: "product";
  id: Scalars["uuid"];
  name: Scalars["String"];
};

/** aggregated selection of "product" */
export type Product_Aggregate = {
  __typename?: "product_aggregate";
  aggregate?: Maybe<Product_Aggregate_Fields>;
  nodes: Array<Product>;
};

/** aggregate fields of "product" */
export type Product_Aggregate_Fields = {
  __typename?: "product_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Product_Max_Fields>;
  min?: Maybe<Product_Min_Fields>;
};

/** aggregate fields of "product" */
export type Product_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "product". All fields are combined with a logical 'AND'. */
export type Product_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Bool_Exp>>;
  _not?: InputMaybe<Product_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "product" */
export enum Product_Constraint {
  /** unique or primary key constraint on columns "name" */
  ProductNameKey = "product_name_key",
  /** unique or primary key constraint on columns "id" */
  ProductPkey = "product_pkey",
}

/** input type for inserting data into table "product" */
export type Product_Insert_Input = {
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Product_Max_Fields = {
  __typename?: "product_max_fields";
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Product_Min_Fields = {
  __typename?: "product_min_fields";
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "product" */
export type Product_Mutation_Response = {
  __typename?: "product_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Product>;
};

/** on_conflict condition type for table "product" */
export type Product_On_Conflict = {
  constraint: Product_Constraint;
  update_columns?: Array<Product_Update_Column>;
  where?: InputMaybe<Product_Bool_Exp>;
};

/** Ordering options when selecting data from "product". */
export type Product_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product */
export type Product_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** columns and relationships of "product_price" */
export type Product_Price = {
  __typename?: "product_price";
  currency: Currency_Enum;
  id: Scalars["uuid"];
  interval_month: Scalars["Int"];
  max_credit: Scalars["Int"];
  name: Scalars["String"];
  price: Scalars["numeric"];
};

/** aggregated selection of "product_price" */
export type Product_Price_Aggregate = {
  __typename?: "product_price_aggregate";
  aggregate?: Maybe<Product_Price_Aggregate_Fields>;
  nodes: Array<Product_Price>;
};

/** aggregate fields of "product_price" */
export type Product_Price_Aggregate_Fields = {
  __typename?: "product_price_aggregate_fields";
  avg?: Maybe<Product_Price_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Product_Price_Max_Fields>;
  min?: Maybe<Product_Price_Min_Fields>;
  stddev?: Maybe<Product_Price_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Price_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Price_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Price_Sum_Fields>;
  var_pop?: Maybe<Product_Price_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Price_Var_Samp_Fields>;
  variance?: Maybe<Product_Price_Variance_Fields>;
};

/** aggregate fields of "product_price" */
export type Product_Price_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Price_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Product_Price_Avg_Fields = {
  __typename?: "product_price_avg_fields";
  interval_month?: Maybe<Scalars["Float"]>;
  max_credit?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "product_price". All fields are combined with a logical 'AND'. */
export type Product_Price_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Price_Bool_Exp>>;
  _not?: InputMaybe<Product_Price_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Price_Bool_Exp>>;
  currency?: InputMaybe<Currency_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  interval_month?: InputMaybe<Int_Comparison_Exp>;
  max_credit?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_price" */
export enum Product_Price_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductPricePkey = "product_price_pkey",
}

/** input type for incrementing numeric columns in table "product_price" */
export type Product_Price_Inc_Input = {
  interval_month?: InputMaybe<Scalars["Int"]>;
  max_credit?: InputMaybe<Scalars["Int"]>;
  price?: InputMaybe<Scalars["numeric"]>;
};

/** input type for inserting data into table "product_price" */
export type Product_Price_Insert_Input = {
  currency?: InputMaybe<Currency_Enum>;
  id?: InputMaybe<Scalars["uuid"]>;
  interval_month?: InputMaybe<Scalars["Int"]>;
  max_credit?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  price?: InputMaybe<Scalars["numeric"]>;
};

/** aggregate max on columns */
export type Product_Price_Max_Fields = {
  __typename?: "product_price_max_fields";
  id?: Maybe<Scalars["uuid"]>;
  interval_month?: Maybe<Scalars["Int"]>;
  max_credit?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["numeric"]>;
};

/** aggregate min on columns */
export type Product_Price_Min_Fields = {
  __typename?: "product_price_min_fields";
  id?: Maybe<Scalars["uuid"]>;
  interval_month?: Maybe<Scalars["Int"]>;
  max_credit?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["numeric"]>;
};

/** response of any mutation on the table "product_price" */
export type Product_Price_Mutation_Response = {
  __typename?: "product_price_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Price>;
};

/** input type for inserting object relation for remote table "product_price" */
export type Product_Price_Obj_Rel_Insert_Input = {
  data: Product_Price_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_Price_On_Conflict>;
};

/** on_conflict condition type for table "product_price" */
export type Product_Price_On_Conflict = {
  constraint: Product_Price_Constraint;
  update_columns?: Array<Product_Price_Update_Column>;
  where?: InputMaybe<Product_Price_Bool_Exp>;
};

/** Ordering options when selecting data from "product_price". */
export type Product_Price_Order_By = {
  currency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interval_month?: InputMaybe<Order_By>;
  max_credit?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product_price */
export type Product_Price_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "product_price" */
export enum Product_Price_Select_Column {
  /** column name */
  Currency = "currency",
  /** column name */
  Id = "id",
  /** column name */
  IntervalMonth = "interval_month",
  /** column name */
  MaxCredit = "max_credit",
  /** column name */
  Name = "name",
  /** column name */
  Price = "price",
}

/** input type for updating data in table "product_price" */
export type Product_Price_Set_Input = {
  currency?: InputMaybe<Currency_Enum>;
  id?: InputMaybe<Scalars["uuid"]>;
  interval_month?: InputMaybe<Scalars["Int"]>;
  max_credit?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  price?: InputMaybe<Scalars["numeric"]>;
};

/** aggregate stddev on columns */
export type Product_Price_Stddev_Fields = {
  __typename?: "product_price_stddev_fields";
  interval_month?: Maybe<Scalars["Float"]>;
  max_credit?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Product_Price_Stddev_Pop_Fields = {
  __typename?: "product_price_stddev_pop_fields";
  interval_month?: Maybe<Scalars["Float"]>;
  max_credit?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Product_Price_Stddev_Samp_Fields = {
  __typename?: "product_price_stddev_samp_fields";
  interval_month?: Maybe<Scalars["Float"]>;
  max_credit?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "product_price" */
export type Product_Price_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Price_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Price_Stream_Cursor_Value_Input = {
  currency?: InputMaybe<Currency_Enum>;
  id?: InputMaybe<Scalars["uuid"]>;
  interval_month?: InputMaybe<Scalars["Int"]>;
  max_credit?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  price?: InputMaybe<Scalars["numeric"]>;
};

/** aggregate sum on columns */
export type Product_Price_Sum_Fields = {
  __typename?: "product_price_sum_fields";
  interval_month?: Maybe<Scalars["Int"]>;
  max_credit?: Maybe<Scalars["Int"]>;
  price?: Maybe<Scalars["numeric"]>;
};

/** update columns of table "product_price" */
export enum Product_Price_Update_Column {
  /** column name */
  Currency = "currency",
  /** column name */
  Id = "id",
  /** column name */
  IntervalMonth = "interval_month",
  /** column name */
  MaxCredit = "max_credit",
  /** column name */
  Name = "name",
  /** column name */
  Price = "price",
}

export type Product_Price_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Product_Price_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Price_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Price_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Product_Price_Var_Pop_Fields = {
  __typename?: "product_price_var_pop_fields";
  interval_month?: Maybe<Scalars["Float"]>;
  max_credit?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Product_Price_Var_Samp_Fields = {
  __typename?: "product_price_var_samp_fields";
  interval_month?: Maybe<Scalars["Float"]>;
  max_credit?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Product_Price_Variance_Fields = {
  __typename?: "product_price_variance_fields";
  interval_month?: Maybe<Scalars["Float"]>;
  max_credit?: Maybe<Scalars["Float"]>;
  price?: Maybe<Scalars["Float"]>;
};

/** select columns of table "product" */
export enum Product_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
}

/** input type for updating data in table "product" */
export type Product_Set_Input = {
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "product" */
export type Product_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "product" */
export enum Product_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
}

export type Product_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Bool_Exp;
};

/** columns and relationships of "project" */
export type Project = {
  __typename?: "project";
  /** An object relationship */
  client: Client;
  client_id: Scalars["uuid"];
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by: Scalars["uuid"];
  description?: Maybe<Scalars["String"]>;
  email_template_external_id?: Maybe<Scalars["String"]>;
  end_date?: Maybe<Scalars["date"]>;
  id: Scalars["uuid"];
  interview_ends_at?: Maybe<Scalars["timestamptz"]>;
  interview_starts_at?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  interview_template?: Maybe<Interview_Template>;
  interview_template_id?: Maybe<Scalars["Int"]>;
  is_default?: Maybe<Scalars["Boolean"]>;
  /** An array relationship */
  job_descriptions: Array<Job_Description>;
  /** An aggregate relationship */
  job_descriptions_aggregate: Job_Description_Aggregate;
  judge: Scalars["String"];
  language: Language_Enum;
  /** An object relationship */
  owner: User;
  project_name: Scalars["String"];
  /** An object relationship */
  project_status?: Maybe<Project_Status>;
  /** An array relationship */
  project_users: Array<Project_User>;
  /** An aggregate relationship */
  project_users_aggregate: Project_User_Aggregate;
  /** An object relationship */
  role?: Maybe<Role>;
  role_id?: Maybe<Scalars["uuid"]>;
  sms_template?: Maybe<Scalars["String"]>;
  start_date?: Maybe<Scalars["date"]>;
  status: Project_Status_Enum;
  tone: Scalars["Float"];
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** columns and relationships of "project" */
export type ProjectJob_DescriptionsArgs = {
  distinct_on?: InputMaybe<Array<Job_Description_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Job_Description_Order_By>>;
  where?: InputMaybe<Job_Description_Bool_Exp>;
};

/** columns and relationships of "project" */
export type ProjectJob_Descriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Description_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Job_Description_Order_By>>;
  where?: InputMaybe<Job_Description_Bool_Exp>;
};

/** columns and relationships of "project" */
export type ProjectProject_UsersArgs = {
  distinct_on?: InputMaybe<Array<Project_User_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Project_User_Order_By>>;
  where?: InputMaybe<Project_User_Bool_Exp>;
};

/** columns and relationships of "project" */
export type ProjectProject_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_User_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Project_User_Order_By>>;
  where?: InputMaybe<Project_User_Bool_Exp>;
};

/** aggregated selection of "project" */
export type Project_Aggregate = {
  __typename?: "project_aggregate";
  aggregate?: Maybe<Project_Aggregate_Fields>;
  nodes: Array<Project>;
};

export type Project_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Project_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Project_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Project_Aggregate_Bool_Exp_Count>;
};

export type Project_Aggregate_Bool_Exp_Bool_And = {
  arguments: Project_Select_Column_Project_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Project_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Project_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Project_Select_Column_Project_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Project_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Project_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Project_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Project_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "project" */
export type Project_Aggregate_Fields = {
  __typename?: "project_aggregate_fields";
  avg?: Maybe<Project_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Project_Max_Fields>;
  min?: Maybe<Project_Min_Fields>;
  stddev?: Maybe<Project_Stddev_Fields>;
  stddev_pop?: Maybe<Project_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Project_Stddev_Samp_Fields>;
  sum?: Maybe<Project_Sum_Fields>;
  var_pop?: Maybe<Project_Var_Pop_Fields>;
  var_samp?: Maybe<Project_Var_Samp_Fields>;
  variance?: Maybe<Project_Variance_Fields>;
};

/** aggregate fields of "project" */
export type Project_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "project" */
export type Project_Aggregate_Order_By = {
  avg?: InputMaybe<Project_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Project_Max_Order_By>;
  min?: InputMaybe<Project_Min_Order_By>;
  stddev?: InputMaybe<Project_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Project_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Project_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Project_Sum_Order_By>;
  var_pop?: InputMaybe<Project_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Project_Var_Samp_Order_By>;
  variance?: InputMaybe<Project_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "project" */
export type Project_Arr_Rel_Insert_Input = {
  data: Array<Project_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_On_Conflict>;
};

/** aggregate avg on columns */
export type Project_Avg_Fields = {
  __typename?: "project_avg_fields";
  interview_template_id?: Maybe<Scalars["Float"]>;
  tone?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "project" */
export type Project_Avg_Order_By = {
  interview_template_id?: InputMaybe<Order_By>;
  tone?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "project". All fields are combined with a logical 'AND'. */
export type Project_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Bool_Exp>>;
  _not?: InputMaybe<Project_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Bool_Exp>>;
  client?: InputMaybe<Client_Bool_Exp>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  email_template_external_id?: InputMaybe<String_Comparison_Exp>;
  end_date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  interview_ends_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  interview_starts_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  interview_template?: InputMaybe<Interview_Template_Bool_Exp>;
  interview_template_id?: InputMaybe<Int_Comparison_Exp>;
  is_default?: InputMaybe<Boolean_Comparison_Exp>;
  job_descriptions?: InputMaybe<Job_Description_Bool_Exp>;
  job_descriptions_aggregate?: InputMaybe<Job_Description_Aggregate_Bool_Exp>;
  judge?: InputMaybe<String_Comparison_Exp>;
  language?: InputMaybe<Language_Enum_Comparison_Exp>;
  owner?: InputMaybe<User_Bool_Exp>;
  project_name?: InputMaybe<String_Comparison_Exp>;
  project_status?: InputMaybe<Project_Status_Bool_Exp>;
  project_users?: InputMaybe<Project_User_Bool_Exp>;
  project_users_aggregate?: InputMaybe<Project_User_Aggregate_Bool_Exp>;
  role?: InputMaybe<Role_Bool_Exp>;
  role_id?: InputMaybe<Uuid_Comparison_Exp>;
  sms_template?: InputMaybe<String_Comparison_Exp>;
  start_date?: InputMaybe<Date_Comparison_Exp>;
  status?: InputMaybe<Project_Status_Enum_Comparison_Exp>;
  tone?: InputMaybe<Float_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "project" */
export enum Project_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectPkey = "project_pkey",
}

/** input type for incrementing numeric columns in table "project" */
export type Project_Inc_Input = {
  interview_template_id?: InputMaybe<Scalars["Int"]>;
  tone?: InputMaybe<Scalars["Float"]>;
};

/** input type for inserting data into table "project" */
export type Project_Insert_Input = {
  client?: InputMaybe<Client_Obj_Rel_Insert_Input>;
  client_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  created_by?: InputMaybe<Scalars["uuid"]>;
  description?: InputMaybe<Scalars["String"]>;
  email_template_external_id?: InputMaybe<Scalars["String"]>;
  end_date?: InputMaybe<Scalars["date"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  interview_ends_at?: InputMaybe<Scalars["timestamptz"]>;
  interview_starts_at?: InputMaybe<Scalars["timestamptz"]>;
  interview_template?: InputMaybe<Interview_Template_Obj_Rel_Insert_Input>;
  interview_template_id?: InputMaybe<Scalars["Int"]>;
  is_default?: InputMaybe<Scalars["Boolean"]>;
  job_descriptions?: InputMaybe<Job_Description_Arr_Rel_Insert_Input>;
  judge?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<Language_Enum>;
  owner?: InputMaybe<User_Obj_Rel_Insert_Input>;
  project_name?: InputMaybe<Scalars["String"]>;
  project_status?: InputMaybe<Project_Status_Obj_Rel_Insert_Input>;
  project_users?: InputMaybe<Project_User_Arr_Rel_Insert_Input>;
  role?: InputMaybe<Role_Obj_Rel_Insert_Input>;
  role_id?: InputMaybe<Scalars["uuid"]>;
  sms_template?: InputMaybe<Scalars["String"]>;
  start_date?: InputMaybe<Scalars["date"]>;
  status?: InputMaybe<Project_Status_Enum>;
  tone?: InputMaybe<Scalars["Float"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Project_Max_Fields = {
  __typename?: "project_max_fields";
  client_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by?: Maybe<Scalars["uuid"]>;
  description?: Maybe<Scalars["String"]>;
  email_template_external_id?: Maybe<Scalars["String"]>;
  end_date?: Maybe<Scalars["date"]>;
  id?: Maybe<Scalars["uuid"]>;
  interview_ends_at?: Maybe<Scalars["timestamptz"]>;
  interview_starts_at?: Maybe<Scalars["timestamptz"]>;
  interview_template_id?: Maybe<Scalars["Int"]>;
  judge?: Maybe<Scalars["String"]>;
  project_name?: Maybe<Scalars["String"]>;
  role_id?: Maybe<Scalars["uuid"]>;
  sms_template?: Maybe<Scalars["String"]>;
  start_date?: Maybe<Scalars["date"]>;
  tone?: Maybe<Scalars["Float"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "project" */
export type Project_Max_Order_By = {
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  email_template_external_id?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interview_ends_at?: InputMaybe<Order_By>;
  interview_starts_at?: InputMaybe<Order_By>;
  interview_template_id?: InputMaybe<Order_By>;
  judge?: InputMaybe<Order_By>;
  project_name?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  sms_template?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  tone?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Project_Min_Fields = {
  __typename?: "project_min_fields";
  client_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by?: Maybe<Scalars["uuid"]>;
  description?: Maybe<Scalars["String"]>;
  email_template_external_id?: Maybe<Scalars["String"]>;
  end_date?: Maybe<Scalars["date"]>;
  id?: Maybe<Scalars["uuid"]>;
  interview_ends_at?: Maybe<Scalars["timestamptz"]>;
  interview_starts_at?: Maybe<Scalars["timestamptz"]>;
  interview_template_id?: Maybe<Scalars["Int"]>;
  judge?: Maybe<Scalars["String"]>;
  project_name?: Maybe<Scalars["String"]>;
  role_id?: Maybe<Scalars["uuid"]>;
  sms_template?: Maybe<Scalars["String"]>;
  start_date?: Maybe<Scalars["date"]>;
  tone?: Maybe<Scalars["Float"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "project" */
export type Project_Min_Order_By = {
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  email_template_external_id?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interview_ends_at?: InputMaybe<Order_By>;
  interview_starts_at?: InputMaybe<Order_By>;
  interview_template_id?: InputMaybe<Order_By>;
  judge?: InputMaybe<Order_By>;
  project_name?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  sms_template?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  tone?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "project" */
export type Project_Mutation_Response = {
  __typename?: "project_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Project>;
};

/** input type for inserting object relation for remote table "project" */
export type Project_Obj_Rel_Insert_Input = {
  data: Project_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_On_Conflict>;
};

/** on_conflict condition type for table "project" */
export type Project_On_Conflict = {
  constraint: Project_Constraint;
  update_columns?: Array<Project_Update_Column>;
  where?: InputMaybe<Project_Bool_Exp>;
};

/** Ordering options when selecting data from "project". */
export type Project_Order_By = {
  client?: InputMaybe<Client_Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  email_template_external_id?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interview_ends_at?: InputMaybe<Order_By>;
  interview_starts_at?: InputMaybe<Order_By>;
  interview_template?: InputMaybe<Interview_Template_Order_By>;
  interview_template_id?: InputMaybe<Order_By>;
  is_default?: InputMaybe<Order_By>;
  job_descriptions_aggregate?: InputMaybe<Job_Description_Aggregate_Order_By>;
  judge?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  owner?: InputMaybe<User_Order_By>;
  project_name?: InputMaybe<Order_By>;
  project_status?: InputMaybe<Project_Status_Order_By>;
  project_users_aggregate?: InputMaybe<Project_User_Aggregate_Order_By>;
  role?: InputMaybe<Role_Order_By>;
  role_id?: InputMaybe<Order_By>;
  sms_template?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tone?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project */
export type Project_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "project" */
export enum Project_Select_Column {
  /** column name */
  ClientId = "client_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CreatedBy = "created_by",
  /** column name */
  Description = "description",
  /** column name */
  EmailTemplateExternalId = "email_template_external_id",
  /** column name */
  EndDate = "end_date",
  /** column name */
  Id = "id",
  /** column name */
  InterviewEndsAt = "interview_ends_at",
  /** column name */
  InterviewStartsAt = "interview_starts_at",
  /** column name */
  InterviewTemplateId = "interview_template_id",
  /** column name */
  IsDefault = "is_default",
  /** column name */
  Judge = "judge",
  /** column name */
  Language = "language",
  /** column name */
  ProjectName = "project_name",
  /** column name */
  RoleId = "role_id",
  /** column name */
  SmsTemplate = "sms_template",
  /** column name */
  StartDate = "start_date",
  /** column name */
  Status = "status",
  /** column name */
  Tone = "tone",
  /** column name */
  UpdatedAt = "updated_at",
}

/** select "project_aggregate_bool_exp_bool_and_arguments_columns" columns of table "project" */
export enum Project_Select_Column_Project_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsDefault = "is_default",
}

/** select "project_aggregate_bool_exp_bool_or_arguments_columns" columns of table "project" */
export enum Project_Select_Column_Project_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsDefault = "is_default",
}

/** input type for updating data in table "project" */
export type Project_Set_Input = {
  client_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  created_by?: InputMaybe<Scalars["uuid"]>;
  description?: InputMaybe<Scalars["String"]>;
  email_template_external_id?: InputMaybe<Scalars["String"]>;
  end_date?: InputMaybe<Scalars["date"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  interview_ends_at?: InputMaybe<Scalars["timestamptz"]>;
  interview_starts_at?: InputMaybe<Scalars["timestamptz"]>;
  interview_template_id?: InputMaybe<Scalars["Int"]>;
  is_default?: InputMaybe<Scalars["Boolean"]>;
  judge?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<Language_Enum>;
  project_name?: InputMaybe<Scalars["String"]>;
  role_id?: InputMaybe<Scalars["uuid"]>;
  sms_template?: InputMaybe<Scalars["String"]>;
  start_date?: InputMaybe<Scalars["date"]>;
  status?: InputMaybe<Project_Status_Enum>;
  tone?: InputMaybe<Scalars["Float"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Project status enum table */
export type Project_Status = {
  __typename?: "project_status";
  status: Scalars["String"];
};

/** aggregated selection of "project_status" */
export type Project_Status_Aggregate = {
  __typename?: "project_status_aggregate";
  aggregate?: Maybe<Project_Status_Aggregate_Fields>;
  nodes: Array<Project_Status>;
};

/** aggregate fields of "project_status" */
export type Project_Status_Aggregate_Fields = {
  __typename?: "project_status_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Project_Status_Max_Fields>;
  min?: Maybe<Project_Status_Min_Fields>;
};

/** aggregate fields of "project_status" */
export type Project_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "project_status". All fields are combined with a logical 'AND'. */
export type Project_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Status_Bool_Exp>>;
  _not?: InputMaybe<Project_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Status_Bool_Exp>>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "project_status" */
export enum Project_Status_Constraint {
  /** unique or primary key constraint on columns "status" */
  ProjectStatusPkey = "project_status_pkey",
}

export enum Project_Status_Enum {
  Active = "ACTIVE",
  Archived = "ARCHIVED",
  Cancelled = "CANCELLED",
  Done = "DONE",
}

/** Boolean expression to compare columns of type "project_status_enum". All fields are combined with logical 'AND'. */
export type Project_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Project_Status_Enum>;
  _in?: InputMaybe<Array<Project_Status_Enum>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _neq?: InputMaybe<Project_Status_Enum>;
  _nin?: InputMaybe<Array<Project_Status_Enum>>;
};

/** input type for inserting data into table "project_status" */
export type Project_Status_Insert_Input = {
  status?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Project_Status_Max_Fields = {
  __typename?: "project_status_max_fields";
  status?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Project_Status_Min_Fields = {
  __typename?: "project_status_min_fields";
  status?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "project_status" */
export type Project_Status_Mutation_Response = {
  __typename?: "project_status_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Status>;
};

/** input type for inserting object relation for remote table "project_status" */
export type Project_Status_Obj_Rel_Insert_Input = {
  data: Project_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Status_On_Conflict>;
};

/** on_conflict condition type for table "project_status" */
export type Project_Status_On_Conflict = {
  constraint: Project_Status_Constraint;
  update_columns?: Array<Project_Status_Update_Column>;
  where?: InputMaybe<Project_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "project_status". */
export type Project_Status_Order_By = {
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project_status */
export type Project_Status_Pk_Columns_Input = {
  status: Scalars["String"];
};

/** select columns of table "project_status" */
export enum Project_Status_Select_Column {
  /** column name */
  Status = "status",
}

/** input type for updating data in table "project_status" */
export type Project_Status_Set_Input = {
  status?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "project_status" */
export type Project_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Status_Stream_Cursor_Value_Input = {
  status?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "project_status" */
export enum Project_Status_Update_Column {
  /** column name */
  Status = "status",
}

export type Project_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Status_Bool_Exp;
};

/** aggregate stddev on columns */
export type Project_Stddev_Fields = {
  __typename?: "project_stddev_fields";
  interview_template_id?: Maybe<Scalars["Float"]>;
  tone?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "project" */
export type Project_Stddev_Order_By = {
  interview_template_id?: InputMaybe<Order_By>;
  tone?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Project_Stddev_Pop_Fields = {
  __typename?: "project_stddev_pop_fields";
  interview_template_id?: Maybe<Scalars["Float"]>;
  tone?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "project" */
export type Project_Stddev_Pop_Order_By = {
  interview_template_id?: InputMaybe<Order_By>;
  tone?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Project_Stddev_Samp_Fields = {
  __typename?: "project_stddev_samp_fields";
  interview_template_id?: Maybe<Scalars["Float"]>;
  tone?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "project" */
export type Project_Stddev_Samp_Order_By = {
  interview_template_id?: InputMaybe<Order_By>;
  tone?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "project" */
export type Project_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Stream_Cursor_Value_Input = {
  client_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  created_by?: InputMaybe<Scalars["uuid"]>;
  description?: InputMaybe<Scalars["String"]>;
  email_template_external_id?: InputMaybe<Scalars["String"]>;
  end_date?: InputMaybe<Scalars["date"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  interview_ends_at?: InputMaybe<Scalars["timestamptz"]>;
  interview_starts_at?: InputMaybe<Scalars["timestamptz"]>;
  interview_template_id?: InputMaybe<Scalars["Int"]>;
  is_default?: InputMaybe<Scalars["Boolean"]>;
  judge?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<Language_Enum>;
  project_name?: InputMaybe<Scalars["String"]>;
  role_id?: InputMaybe<Scalars["uuid"]>;
  sms_template?: InputMaybe<Scalars["String"]>;
  start_date?: InputMaybe<Scalars["date"]>;
  status?: InputMaybe<Project_Status_Enum>;
  tone?: InputMaybe<Scalars["Float"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type Project_Sum_Fields = {
  __typename?: "project_sum_fields";
  interview_template_id?: Maybe<Scalars["Int"]>;
  tone?: Maybe<Scalars["Float"]>;
};

/** order by sum() on columns of table "project" */
export type Project_Sum_Order_By = {
  interview_template_id?: InputMaybe<Order_By>;
  tone?: InputMaybe<Order_By>;
};

/** update columns of table "project" */
export enum Project_Update_Column {
  /** column name */
  ClientId = "client_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CreatedBy = "created_by",
  /** column name */
  Description = "description",
  /** column name */
  EmailTemplateExternalId = "email_template_external_id",
  /** column name */
  EndDate = "end_date",
  /** column name */
  Id = "id",
  /** column name */
  InterviewEndsAt = "interview_ends_at",
  /** column name */
  InterviewStartsAt = "interview_starts_at",
  /** column name */
  InterviewTemplateId = "interview_template_id",
  /** column name */
  IsDefault = "is_default",
  /** column name */
  Judge = "judge",
  /** column name */
  Language = "language",
  /** column name */
  ProjectName = "project_name",
  /** column name */
  RoleId = "role_id",
  /** column name */
  SmsTemplate = "sms_template",
  /** column name */
  StartDate = "start_date",
  /** column name */
  Status = "status",
  /** column name */
  Tone = "tone",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Project_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Project_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Bool_Exp;
};

/** columns and relationships of "project_user" */
export type Project_User = {
  __typename?: "project_user";
  id: Scalars["Int"];
  /** An object relationship */
  project: Project;
  project_id: Scalars["uuid"];
  /** An object relationship */
  user: User;
  user_id: Scalars["uuid"];
};

/** aggregated selection of "project_user" */
export type Project_User_Aggregate = {
  __typename?: "project_user_aggregate";
  aggregate?: Maybe<Project_User_Aggregate_Fields>;
  nodes: Array<Project_User>;
};

export type Project_User_Aggregate_Bool_Exp = {
  count?: InputMaybe<Project_User_Aggregate_Bool_Exp_Count>;
};

export type Project_User_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Project_User_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Project_User_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "project_user" */
export type Project_User_Aggregate_Fields = {
  __typename?: "project_user_aggregate_fields";
  avg?: Maybe<Project_User_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Project_User_Max_Fields>;
  min?: Maybe<Project_User_Min_Fields>;
  stddev?: Maybe<Project_User_Stddev_Fields>;
  stddev_pop?: Maybe<Project_User_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Project_User_Stddev_Samp_Fields>;
  sum?: Maybe<Project_User_Sum_Fields>;
  var_pop?: Maybe<Project_User_Var_Pop_Fields>;
  var_samp?: Maybe<Project_User_Var_Samp_Fields>;
  variance?: Maybe<Project_User_Variance_Fields>;
};

/** aggregate fields of "project_user" */
export type Project_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_User_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "project_user" */
export type Project_User_Aggregate_Order_By = {
  avg?: InputMaybe<Project_User_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Project_User_Max_Order_By>;
  min?: InputMaybe<Project_User_Min_Order_By>;
  stddev?: InputMaybe<Project_User_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Project_User_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Project_User_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Project_User_Sum_Order_By>;
  var_pop?: InputMaybe<Project_User_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Project_User_Var_Samp_Order_By>;
  variance?: InputMaybe<Project_User_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "project_user" */
export type Project_User_Arr_Rel_Insert_Input = {
  data: Array<Project_User_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_User_On_Conflict>;
};

/** aggregate avg on columns */
export type Project_User_Avg_Fields = {
  __typename?: "project_user_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "project_user" */
export type Project_User_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "project_user". All fields are combined with a logical 'AND'. */
export type Project_User_Bool_Exp = {
  _and?: InputMaybe<Array<Project_User_Bool_Exp>>;
  _not?: InputMaybe<Project_User_Bool_Exp>;
  _or?: InputMaybe<Array<Project_User_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  project?: InputMaybe<Project_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "project_user" */
export enum Project_User_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectUserPkey = "project_user_pkey",
}

/** input type for incrementing numeric columns in table "project_user" */
export type Project_User_Inc_Input = {
  id?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "project_user" */
export type Project_User_Insert_Input = {
  id?: InputMaybe<Scalars["Int"]>;
  project?: InputMaybe<Project_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars["uuid"]>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Project_User_Max_Fields = {
  __typename?: "project_user_max_fields";
  id?: Maybe<Scalars["Int"]>;
  project_id?: Maybe<Scalars["uuid"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "project_user" */
export type Project_User_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Project_User_Min_Fields = {
  __typename?: "project_user_min_fields";
  id?: Maybe<Scalars["Int"]>;
  project_id?: Maybe<Scalars["uuid"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "project_user" */
export type Project_User_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "project_user" */
export type Project_User_Mutation_Response = {
  __typename?: "project_user_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Project_User>;
};

/** on_conflict condition type for table "project_user" */
export type Project_User_On_Conflict = {
  constraint: Project_User_Constraint;
  update_columns?: Array<Project_User_Update_Column>;
  where?: InputMaybe<Project_User_Bool_Exp>;
};

/** Ordering options when selecting data from "project_user". */
export type Project_User_Order_By = {
  id?: InputMaybe<Order_By>;
  project?: InputMaybe<Project_Order_By>;
  project_id?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project_user */
export type Project_User_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "project_user" */
export enum Project_User_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  ProjectId = "project_id",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "project_user" */
export type Project_User_Set_Input = {
  id?: InputMaybe<Scalars["Int"]>;
  project_id?: InputMaybe<Scalars["uuid"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type Project_User_Stddev_Fields = {
  __typename?: "project_user_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "project_user" */
export type Project_User_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Project_User_Stddev_Pop_Fields = {
  __typename?: "project_user_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "project_user" */
export type Project_User_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Project_User_Stddev_Samp_Fields = {
  __typename?: "project_user_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "project_user" */
export type Project_User_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "project_user" */
export type Project_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_User_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars["Int"]>;
  project_id?: InputMaybe<Scalars["uuid"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate sum on columns */
export type Project_User_Sum_Fields = {
  __typename?: "project_user_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "project_user" */
export type Project_User_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "project_user" */
export enum Project_User_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  ProjectId = "project_id",
  /** column name */
  UserId = "user_id",
}

export type Project_User_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Project_User_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_User_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_User_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Project_User_Var_Pop_Fields = {
  __typename?: "project_user_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "project_user" */
export type Project_User_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Project_User_Var_Samp_Fields = {
  __typename?: "project_user_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "project_user" */
export type Project_User_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Project_User_Variance_Fields = {
  __typename?: "project_user_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "project_user" */
export type Project_User_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Project_Var_Pop_Fields = {
  __typename?: "project_var_pop_fields";
  interview_template_id?: Maybe<Scalars["Float"]>;
  tone?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "project" */
export type Project_Var_Pop_Order_By = {
  interview_template_id?: InputMaybe<Order_By>;
  tone?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Project_Var_Samp_Fields = {
  __typename?: "project_var_samp_fields";
  interview_template_id?: Maybe<Scalars["Float"]>;
  tone?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "project" */
export type Project_Var_Samp_Order_By = {
  interview_template_id?: InputMaybe<Order_By>;
  tone?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Project_Variance_Fields = {
  __typename?: "project_variance_fields";
  interview_template_id?: Maybe<Scalars["Float"]>;
  tone?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "project" */
export type Project_Variance_Order_By = {
  interview_template_id?: InputMaybe<Order_By>;
  tone?: InputMaybe<Order_By>;
};

export type Query_Root = {
  __typename?: "query_root";
  /** fetch data from the table: "academic_department" */
  academic_department: Array<Academic_Department>;
  /** fetch aggregated fields from the table: "academic_department" */
  academic_department_aggregate: Academic_Department_Aggregate;
  /** fetch data from the table: "academic_department" using primary key columns */
  academic_department_by_pk?: Maybe<Academic_Department>;
  /** fetch data from the table: "address_type" */
  address_type: Array<Address_Type>;
  /** fetch aggregated fields from the table: "address_type" */
  address_type_aggregate: Address_Type_Aggregate;
  /** fetch data from the table: "address_type" using primary key columns */
  address_type_by_pk?: Maybe<Address_Type>;
  /** fetch data from the table: "city" */
  city: Array<City>;
  /** fetch aggregated fields from the table: "city" */
  city_aggregate: City_Aggregate;
  /** fetch data from the table: "city" using primary key columns */
  city_by_pk?: Maybe<City>;
  /** fetch data from the table: "client" */
  client: Array<Client>;
  /** fetch aggregated fields from the table: "client" */
  client_aggregate: Client_Aggregate;
  /** fetch data from the table: "client" using primary key columns */
  client_by_pk?: Maybe<Client>;
  /** fetch data from the table: "client_candidate" */
  client_candidate: Array<Client_Candidate>;
  /** fetch aggregated fields from the table: "client_candidate" */
  client_candidate_aggregate: Client_Candidate_Aggregate;
  /** fetch data from the table: "client_candidate" using primary key columns */
  client_candidate_by_pk?: Maybe<Client_Candidate>;
  /** fetch data from the table: "client_candidate_project" */
  client_candidate_project: Array<Client_Candidate_Project>;
  /** fetch aggregated fields from the table: "client_candidate_project" */
  client_candidate_project_aggregate: Client_Candidate_Project_Aggregate;
  /** fetch data from the table: "client_candidate_project" using primary key columns */
  client_candidate_project_by_pk?: Maybe<Client_Candidate_Project>;
  /** fetch data from the table: "client_candidate_project_candidate_list_status" */
  client_candidate_project_candidate_list_status: Array<Client_Candidate_Project_Candidate_List_Status>;
  /** fetch aggregated fields from the table: "client_candidate_project_candidate_list_status" */
  client_candidate_project_candidate_list_status_aggregate: Client_Candidate_Project_Candidate_List_Status_Aggregate;
  /** fetch data from the table: "client_candidate_project_candidate_list_status" using primary key columns */
  client_candidate_project_candidate_list_status_by_pk?: Maybe<Client_Candidate_Project_Candidate_List_Status>;
  /** fetch data from the table: "client_candidate_project_candidate_resolution" */
  client_candidate_project_candidate_resolution: Array<Client_Candidate_Project_Candidate_Resolution>;
  /** fetch aggregated fields from the table: "client_candidate_project_candidate_resolution" */
  client_candidate_project_candidate_resolution_aggregate: Client_Candidate_Project_Candidate_Resolution_Aggregate;
  /** fetch data from the table: "client_candidate_project_candidate_resolution" using primary key columns */
  client_candidate_project_candidate_resolution_by_pk?: Maybe<Client_Candidate_Project_Candidate_Resolution>;
  /** fetch data from the table: "client_candidate_project_note" */
  client_candidate_project_note: Array<Client_Candidate_Project_Note>;
  /** fetch aggregated fields from the table: "client_candidate_project_note" */
  client_candidate_project_note_aggregate: Client_Candidate_Project_Note_Aggregate;
  /** fetch data from the table: "client_candidate_project_note" using primary key columns */
  client_candidate_project_note_by_pk?: Maybe<Client_Candidate_Project_Note>;
  /** fetch data from the table: "client_location" */
  client_location: Array<Client_Location>;
  /** fetch aggregated fields from the table: "client_location" */
  client_location_aggregate: Client_Location_Aggregate;
  /** fetch data from the table: "client_location" using primary key columns */
  client_location_by_pk?: Maybe<Client_Location>;
  /** fetch data from the table: "client_sector" */
  client_sector: Array<Client_Sector>;
  /** fetch aggregated fields from the table: "client_sector" */
  client_sector_aggregate: Client_Sector_Aggregate;
  /** fetch data from the table: "client_sector" using primary key columns */
  client_sector_by_pk?: Maybe<Client_Sector>;
  /** fetch data from the table: "client_setting" */
  client_setting: Array<Client_Setting>;
  /** fetch aggregated fields from the table: "client_setting" */
  client_setting_aggregate: Client_Setting_Aggregate;
  /** fetch data from the table: "client_setting" using primary key columns */
  client_setting_by_pk?: Maybe<Client_Setting>;
  /** fetch data from the table: "client_setting_key" */
  client_setting_key: Array<Client_Setting_Key>;
  /** fetch aggregated fields from the table: "client_setting_key" */
  client_setting_key_aggregate: Client_Setting_Key_Aggregate;
  /** fetch data from the table: "client_setting_key" using primary key columns */
  client_setting_key_by_pk?: Maybe<Client_Setting_Key>;
  /** fetch data from the table: "company" */
  company: Array<Company>;
  /** fetch aggregated fields from the table: "company" */
  company_aggregate: Company_Aggregate;
  /** fetch data from the table: "company" using primary key columns */
  company_by_pk?: Maybe<Company>;
  /** fetch data from the table: "company_subscription" */
  company_subscription: Array<Company_Subscription>;
  /** fetch aggregated fields from the table: "company_subscription" */
  company_subscription_aggregate: Company_Subscription_Aggregate;
  /** fetch data from the table: "company_subscription" using primary key columns */
  company_subscription_by_pk?: Maybe<Company_Subscription>;
  /** fetch data from the table: "consent" */
  consent: Array<Consent>;
  /** fetch aggregated fields from the table: "consent" */
  consent_aggregate: Consent_Aggregate;
  /** fetch data from the table: "consent" using primary key columns */
  consent_by_pk?: Maybe<Consent>;
  /** fetch data from the table: "consent_status" */
  consent_status: Array<Consent_Status>;
  /** fetch aggregated fields from the table: "consent_status" */
  consent_status_aggregate: Consent_Status_Aggregate;
  /** fetch data from the table: "consent_status" using primary key columns */
  consent_status_by_pk?: Maybe<Consent_Status>;
  /** fetch data from the table: "consent_type" */
  consent_type: Array<Consent_Type>;
  /** fetch aggregated fields from the table: "consent_type" */
  consent_type_aggregate: Consent_Type_Aggregate;
  /** fetch data from the table: "consent_type" using primary key columns */
  consent_type_by_pk?: Maybe<Consent_Type>;
  /** fetch data from the table: "country" */
  country: Array<Country>;
  /** fetch aggregated fields from the table: "country" */
  country_aggregate: Country_Aggregate;
  /** fetch data from the table: "country" using primary key columns */
  country_by_pk?: Maybe<Country>;
  /** fetch data from the table: "currency" */
  currency: Array<Currency>;
  /** fetch aggregated fields from the table: "currency" */
  currency_aggregate: Currency_Aggregate;
  /** fetch data from the table: "currency" using primary key columns */
  currency_by_pk?: Maybe<Currency>;
  /** fetch data from the table: "department" */
  department: Array<Department>;
  /** fetch aggregated fields from the table: "department" */
  department_aggregate: Department_Aggregate;
  /** fetch data from the table: "department" using primary key columns */
  department_by_pk?: Maybe<Department>;
  /** fetch data from the table: "education_type" */
  education_type: Array<Education_Type>;
  /** fetch aggregated fields from the table: "education_type" */
  education_type_aggregate: Education_Type_Aggregate;
  /** fetch data from the table: "education_type" using primary key columns */
  education_type_by_pk?: Maybe<Education_Type>;
  /** Get Interview Details */
  getInterview: InterviewOutput;
  /** geInterviewQuestion */
  getInterviewQuestion?: Maybe<InterviewQuestionOutput>;
  getNextJDQuestion?: Maybe<JdPageOutput>;
  /** getSignedUrl */
  getSignedUrl?: Maybe<SignedUrlOutput>;
  /** fetch data from the table: "interview_criterion" */
  interview_criterion: Array<Interview_Criterion>;
  /** fetch aggregated fields from the table: "interview_criterion" */
  interview_criterion_aggregate: Interview_Criterion_Aggregate;
  /** fetch data from the table: "interview_criterion" using primary key columns */
  interview_criterion_by_pk?: Maybe<Interview_Criterion>;
  /** fetch data from the table: "interview_template" */
  interview_template: Array<Interview_Template>;
  /** fetch aggregated fields from the table: "interview_template" */
  interview_template_aggregate: Interview_Template_Aggregate;
  /** fetch data from the table: "interview_template" using primary key columns */
  interview_template_by_pk?: Maybe<Interview_Template>;
  /** fetch data from the table: "interview_template_review_metric_question" */
  interview_template_review_metric_question: Array<Interview_Template_Review_Metric_Question>;
  /** fetch aggregated fields from the table: "interview_template_review_metric_question" */
  interview_template_review_metric_question_aggregate: Interview_Template_Review_Metric_Question_Aggregate;
  /** fetch data from the table: "interview_template_review_metric_question" using primary key columns */
  interview_template_review_metric_question_by_pk?: Maybe<Interview_Template_Review_Metric_Question>;
  /** fetch data from the table: "job_description" */
  job_description: Array<Job_Description>;
  /** fetch aggregated fields from the table: "job_description" */
  job_description_aggregate: Job_Description_Aggregate;
  /** fetch data from the table: "job_description" using primary key columns */
  job_description_by_pk?: Maybe<Job_Description>;
  /** fetch data from the table: "job_description_step" */
  job_description_step: Array<Job_Description_Step>;
  /** fetch aggregated fields from the table: "job_description_step" */
  job_description_step_aggregate: Job_Description_Step_Aggregate;
  /** fetch data from the table: "job_description_step" using primary key columns */
  job_description_step_by_pk?: Maybe<Job_Description_Step>;
  /** fetch data from the table: "language" */
  language: Array<Language>;
  /** fetch aggregated fields from the table: "language" */
  language_aggregate: Language_Aggregate;
  /** fetch data from the table: "language" using primary key columns */
  language_by_pk?: Maybe<Language>;
  /** fetch data from the table: "partner" */
  partner: Array<Partner>;
  /** fetch aggregated fields from the table: "partner" */
  partner_aggregate: Partner_Aggregate;
  /** fetch data from the table: "partner" using primary key columns */
  partner_by_pk?: Maybe<Partner>;
  /** fetch data from the table: "product" */
  product: Array<Product>;
  /** fetch aggregated fields from the table: "product" */
  product_aggregate: Product_Aggregate;
  /** fetch data from the table: "product" using primary key columns */
  product_by_pk?: Maybe<Product>;
  /** fetch data from the table: "product_price" */
  product_price: Array<Product_Price>;
  /** fetch aggregated fields from the table: "product_price" */
  product_price_aggregate: Product_Price_Aggregate;
  /** fetch data from the table: "product_price" using primary key columns */
  product_price_by_pk?: Maybe<Product_Price>;
  /** fetch data from the table: "project" */
  project: Array<Project>;
  /** fetch aggregated fields from the table: "project" */
  project_aggregate: Project_Aggregate;
  /** fetch data from the table: "project" using primary key columns */
  project_by_pk?: Maybe<Project>;
  /** fetch data from the table: "project_status" */
  project_status: Array<Project_Status>;
  /** fetch aggregated fields from the table: "project_status" */
  project_status_aggregate: Project_Status_Aggregate;
  /** fetch data from the table: "project_status" using primary key columns */
  project_status_by_pk?: Maybe<Project_Status>;
  /** fetch data from the table: "project_user" */
  project_user: Array<Project_User>;
  /** fetch aggregated fields from the table: "project_user" */
  project_user_aggregate: Project_User_Aggregate;
  /** fetch data from the table: "project_user" using primary key columns */
  project_user_by_pk?: Maybe<Project_User>;
  /** fetch data from the table: "review_metric" */
  review_metric: Array<Review_Metric>;
  /** fetch aggregated fields from the table: "review_metric" */
  review_metric_aggregate: Review_Metric_Aggregate;
  /** fetch data from the table: "review_metric" using primary key columns */
  review_metric_by_pk?: Maybe<Review_Metric>;
  /** fetch data from the table: "review_metric_question" */
  review_metric_question: Array<Review_Metric_Question>;
  /** fetch aggregated fields from the table: "review_metric_question" */
  review_metric_question_aggregate: Review_Metric_Question_Aggregate;
  /** fetch data from the table: "review_metric_question" using primary key columns */
  review_metric_question_by_pk?: Maybe<Review_Metric_Question>;
  /** fetch data from the table: "review_metric_question_criterion" */
  review_metric_question_criterion: Array<Review_Metric_Question_Criterion>;
  /** fetch aggregated fields from the table: "review_metric_question_criterion" */
  review_metric_question_criterion_aggregate: Review_Metric_Question_Criterion_Aggregate;
  /** fetch data from the table: "review_metric_question_criterion" using primary key columns */
  review_metric_question_criterion_by_pk?: Maybe<Review_Metric_Question_Criterion>;
  /** fetch data from the table: "review_metric_question_response" */
  review_metric_question_response: Array<Review_Metric_Question_Response>;
  /** fetch aggregated fields from the table: "review_metric_question_response" */
  review_metric_question_response_aggregate: Review_Metric_Question_Response_Aggregate;
  /** fetch data from the table: "review_metric_question_response" using primary key columns */
  review_metric_question_response_by_pk?: Maybe<Review_Metric_Question_Response>;
  /** fetch data from the table: "review_metric_question_type" */
  review_metric_question_type: Array<Review_Metric_Question_Type>;
  /** fetch aggregated fields from the table: "review_metric_question_type" */
  review_metric_question_type_aggregate: Review_Metric_Question_Type_Aggregate;
  /** fetch data from the table: "review_metric_question_type" using primary key columns */
  review_metric_question_type_by_pk?: Maybe<Review_Metric_Question_Type>;
  /** fetch data from the table: "role" */
  role: Array<Role>;
  /** fetch data from the table: "role_academic_department" */
  role_academic_department: Array<Role_Academic_Department>;
  /** fetch aggregated fields from the table: "role_academic_department" */
  role_academic_department_aggregate: Role_Academic_Department_Aggregate;
  /** fetch data from the table: "role_academic_department" using primary key columns */
  role_academic_department_by_pk?: Maybe<Role_Academic_Department>;
  /** fetch aggregated fields from the table: "role" */
  role_aggregate: Role_Aggregate;
  /** fetch data from the table: "role_benefit" */
  role_benefit: Array<Role_Benefit>;
  /** fetch aggregated fields from the table: "role_benefit" */
  role_benefit_aggregate: Role_Benefit_Aggregate;
  /** fetch data from the table: "role_benefit" using primary key columns */
  role_benefit_by_pk?: Maybe<Role_Benefit>;
  /** fetch data from the table: "role" using primary key columns */
  role_by_pk?: Maybe<Role>;
  /** fetch data from the table: "role_competence" */
  role_competence: Array<Role_Competence>;
  /** fetch aggregated fields from the table: "role_competence" */
  role_competence_aggregate: Role_Competence_Aggregate;
  /** fetch data from the table: "role_competence" using primary key columns */
  role_competence_by_pk?: Maybe<Role_Competence>;
  /** fetch data from the table: "role_description" */
  role_description: Array<Role_Description>;
  /** fetch aggregated fields from the table: "role_description" */
  role_description_aggregate: Role_Description_Aggregate;
  /** fetch data from the table: "role_description" using primary key columns */
  role_description_by_pk?: Maybe<Role_Description>;
  /** fetch data from the table: "role_responsibility" */
  role_responsibility: Array<Role_Responsibility>;
  /** fetch aggregated fields from the table: "role_responsibility" */
  role_responsibility_aggregate: Role_Responsibility_Aggregate;
  /** fetch data from the table: "role_responsibility" using primary key columns */
  role_responsibility_by_pk?: Maybe<Role_Responsibility>;
  /** fetch data from the table: "role_review_metric" */
  role_review_metric: Array<Role_Review_Metric>;
  /** fetch aggregated fields from the table: "role_review_metric" */
  role_review_metric_aggregate: Role_Review_Metric_Aggregate;
  /** fetch data from the table: "role_review_metric" using primary key columns */
  role_review_metric_by_pk?: Maybe<Role_Review_Metric>;
  /** fetch data from the table: "role_skill" */
  role_skill: Array<Role_Skill>;
  /** fetch aggregated fields from the table: "role_skill" */
  role_skill_aggregate: Role_Skill_Aggregate;
  /** fetch data from the table: "role_skill" using primary key columns */
  role_skill_by_pk?: Maybe<Role_Skill>;
  search?: Maybe<SearchOutput>;
  /** fetch data from the table: "sector" */
  sector: Array<Sector>;
  /** fetch aggregated fields from the table: "sector" */
  sector_aggregate: Sector_Aggregate;
  /** fetch data from the table: "sector" using primary key columns */
  sector_by_pk?: Maybe<Sector>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "user_key" */
  user_key: Array<User_Key>;
  /** fetch aggregated fields from the table: "user_key" */
  user_key_aggregate: User_Key_Aggregate;
  /** fetch data from the table: "user_key" using primary key columns */
  user_key_by_pk?: Maybe<User_Key>;
  /** fetch data from the table: "user_role" */
  user_role: Array<User_Role>;
  /** fetch aggregated fields from the table: "user_role" */
  user_role_aggregate: User_Role_Aggregate;
  /** fetch data from the table: "user_role" using primary key columns */
  user_role_by_pk?: Maybe<User_Role>;
  /** fetch data from the table: "user_setting" */
  user_setting: Array<User_Setting>;
  /** fetch aggregated fields from the table: "user_setting" */
  user_setting_aggregate: User_Setting_Aggregate;
  /** fetch data from the table: "user_setting" using primary key columns */
  user_setting_by_pk?: Maybe<User_Setting>;
  /** fetch data from the table: "user_setting_key" */
  user_setting_key: Array<User_Setting_Key>;
  /** fetch aggregated fields from the table: "user_setting_key" */
  user_setting_key_aggregate: User_Setting_Key_Aggregate;
  /** fetch data from the table: "user_setting_key" using primary key columns */
  user_setting_key_by_pk?: Maybe<User_Setting_Key>;
  /** fetch data from the table: "user_status" */
  user_status: Array<User_Status>;
  /** fetch aggregated fields from the table: "user_status" */
  user_status_aggregate: User_Status_Aggregate;
  /** fetch data from the table: "user_status" using primary key columns */
  user_status_by_pk?: Maybe<User_Status>;
  /** fetch data from the table: "video_interview" */
  video_interview: Array<Video_Interview>;
  /** fetch aggregated fields from the table: "video_interview" */
  video_interview_aggregate: Video_Interview_Aggregate;
  /** fetch data from the table: "video_interview" using primary key columns */
  video_interview_by_pk?: Maybe<Video_Interview>;
  /** fetch data from the table: "video_interview_criterion_score" */
  video_interview_criterion_score: Array<Video_Interview_Criterion_Score>;
  /** fetch aggregated fields from the table: "video_interview_criterion_score" */
  video_interview_criterion_score_aggregate: Video_Interview_Criterion_Score_Aggregate;
  /** fetch data from the table: "video_interview_criterion_score" using primary key columns */
  video_interview_criterion_score_by_pk?: Maybe<Video_Interview_Criterion_Score>;
  /** fetch data from the table: "video_interview_question" */
  video_interview_question: Array<Video_Interview_Question>;
  /** fetch aggregated fields from the table: "video_interview_question" */
  video_interview_question_aggregate: Video_Interview_Question_Aggregate;
  /** fetch data from the table: "video_interview_question" using primary key columns */
  video_interview_question_by_pk?: Maybe<Video_Interview_Question>;
  /** fetch data from the table: "video_interview_question_event" */
  video_interview_question_event: Array<Video_Interview_Question_Event>;
  /** fetch aggregated fields from the table: "video_interview_question_event" */
  video_interview_question_event_aggregate: Video_Interview_Question_Event_Aggregate;
  /** fetch data from the table: "video_interview_question_event" using primary key columns */
  video_interview_question_event_by_pk?: Maybe<Video_Interview_Question_Event>;
  /** fetch data from the table: "video_interview_question_event_type" */
  video_interview_question_event_type: Array<Video_Interview_Question_Event_Type>;
  /** fetch aggregated fields from the table: "video_interview_question_event_type" */
  video_interview_question_event_type_aggregate: Video_Interview_Question_Event_Type_Aggregate;
  /** fetch data from the table: "video_interview_question_event_type" using primary key columns */
  video_interview_question_event_type_by_pk?: Maybe<Video_Interview_Question_Event_Type>;
  /** fetch data from the table: "video_interview_question_score" */
  video_interview_question_score: Array<Video_Interview_Question_Score>;
  /** fetch aggregated fields from the table: "video_interview_question_score" */
  video_interview_question_score_aggregate: Video_Interview_Question_Score_Aggregate;
  /** fetch data from the table: "video_interview_question_score" using primary key columns */
  video_interview_question_score_by_pk?: Maybe<Video_Interview_Question_Score>;
  /** fetch data from the table: "video_interview_reviewer" */
  video_interview_reviewer: Array<Video_Interview_Reviewer>;
  /** fetch aggregated fields from the table: "video_interview_reviewer" */
  video_interview_reviewer_aggregate: Video_Interview_Reviewer_Aggregate;
  /** fetch data from the table: "video_interview_reviewer" using primary key columns */
  video_interview_reviewer_by_pk?: Maybe<Video_Interview_Reviewer>;
  /** fetch data from the table: "video_interview_status" */
  video_interview_status: Array<Video_Interview_Status>;
  /** fetch aggregated fields from the table: "video_interview_status" */
  video_interview_status_aggregate: Video_Interview_Status_Aggregate;
  /** fetch data from the table: "video_interview_status" using primary key columns */
  video_interview_status_by_pk?: Maybe<Video_Interview_Status>;
  /** fetch data from the table: "video_interview_status_history" */
  video_interview_status_history: Array<Video_Interview_Status_History>;
  /** fetch aggregated fields from the table: "video_interview_status_history" */
  video_interview_status_history_aggregate: Video_Interview_Status_History_Aggregate;
  /** fetch data from the table: "video_interview_status_history" using primary key columns */
  video_interview_status_history_by_pk?: Maybe<Video_Interview_Status_History>;
  /** fetch data from the table: "video_interview_type" */
  video_interview_type: Array<Video_Interview_Type>;
  /** fetch aggregated fields from the table: "video_interview_type" */
  video_interview_type_aggregate: Video_Interview_Type_Aggregate;
  /** fetch data from the table: "video_interview_type" using primary key columns */
  video_interview_type_by_pk?: Maybe<Video_Interview_Type>;
  /** fetch data from the table: "video_process_status" */
  video_process_status: Array<Video_Process_Status>;
  /** fetch aggregated fields from the table: "video_process_status" */
  video_process_status_aggregate: Video_Process_Status_Aggregate;
  /** fetch data from the table: "video_process_status" using primary key columns */
  video_process_status_by_pk?: Maybe<Video_Process_Status>;
  /** fetch data from the table: "view__client_candidate_projects" */
  view__client_candidate_projects: Array<View__Client_Candidate_Projects>;
  /** fetch aggregated fields from the table: "view__client_candidate_projects" */
  view__client_candidate_projects_aggregate: View__Client_Candidate_Projects_Aggregate;
  /** fetch data from the table: "view__ordered_video_interview_question" */
  view__ordered_video_interview_question: Array<View__Ordered_Video_Interview_Question>;
  /** fetch aggregated fields from the table: "view__ordered_video_interview_question" */
  view__ordered_video_interview_question_aggregate: View__Ordered_Video_Interview_Question_Aggregate;
};

export type Query_RootAcademic_DepartmentArgs = {
  distinct_on?: InputMaybe<Array<Academic_Department_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Academic_Department_Order_By>>;
  where?: InputMaybe<Academic_Department_Bool_Exp>;
};

export type Query_RootAcademic_Department_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Academic_Department_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Academic_Department_Order_By>>;
  where?: InputMaybe<Academic_Department_Bool_Exp>;
};

export type Query_RootAcademic_Department_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootAddress_TypeArgs = {
  distinct_on?: InputMaybe<Array<Address_Type_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Address_Type_Order_By>>;
  where?: InputMaybe<Address_Type_Bool_Exp>;
};

export type Query_RootAddress_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Address_Type_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Address_Type_Order_By>>;
  where?: InputMaybe<Address_Type_Bool_Exp>;
};

export type Query_RootAddress_Type_By_PkArgs = {
  adress_type: Scalars["String"];
};

export type Query_RootCityArgs = {
  distinct_on?: InputMaybe<Array<City_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<City_Order_By>>;
  where?: InputMaybe<City_Bool_Exp>;
};

export type Query_RootCity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<City_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<City_Order_By>>;
  where?: InputMaybe<City_Bool_Exp>;
};

export type Query_RootCity_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootClientArgs = {
  distinct_on?: InputMaybe<Array<Client_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Client_Order_By>>;
  where?: InputMaybe<Client_Bool_Exp>;
};

export type Query_RootClient_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Client_Order_By>>;
  where?: InputMaybe<Client_Bool_Exp>;
};

export type Query_RootClient_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootClient_CandidateArgs = {
  distinct_on?: InputMaybe<Array<Client_Candidate_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Client_Candidate_Order_By>>;
  where?: InputMaybe<Client_Candidate_Bool_Exp>;
};

export type Query_RootClient_Candidate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Candidate_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Client_Candidate_Order_By>>;
  where?: InputMaybe<Client_Candidate_Bool_Exp>;
};

export type Query_RootClient_Candidate_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootClient_Candidate_ProjectArgs = {
  distinct_on?: InputMaybe<Array<Client_Candidate_Project_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Client_Candidate_Project_Order_By>>;
  where?: InputMaybe<Client_Candidate_Project_Bool_Exp>;
};

export type Query_RootClient_Candidate_Project_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Candidate_Project_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Client_Candidate_Project_Order_By>>;
  where?: InputMaybe<Client_Candidate_Project_Bool_Exp>;
};

export type Query_RootClient_Candidate_Project_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootClient_Candidate_Project_Candidate_List_StatusArgs = {
  distinct_on?: InputMaybe<
    Array<Client_Candidate_Project_Candidate_List_Status_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<
    Array<Client_Candidate_Project_Candidate_List_Status_Order_By>
  >;
  where?: InputMaybe<Client_Candidate_Project_Candidate_List_Status_Bool_Exp>;
};

export type Query_RootClient_Candidate_Project_Candidate_List_Status_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Client_Candidate_Project_Candidate_List_Status_Select_Column>
    >;
    limit?: InputMaybe<Scalars["Int"]>;
    offset?: InputMaybe<Scalars["Int"]>;
    order_by?: InputMaybe<
      Array<Client_Candidate_Project_Candidate_List_Status_Order_By>
    >;
    where?: InputMaybe<Client_Candidate_Project_Candidate_List_Status_Bool_Exp>;
  };

export type Query_RootClient_Candidate_Project_Candidate_List_Status_By_PkArgs =
  {
    candidate_status: Scalars["String"];
  };

export type Query_RootClient_Candidate_Project_Candidate_ResolutionArgs = {
  distinct_on?: InputMaybe<
    Array<Client_Candidate_Project_Candidate_Resolution_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<
    Array<Client_Candidate_Project_Candidate_Resolution_Order_By>
  >;
  where?: InputMaybe<Client_Candidate_Project_Candidate_Resolution_Bool_Exp>;
};

export type Query_RootClient_Candidate_Project_Candidate_Resolution_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Client_Candidate_Project_Candidate_Resolution_Select_Column>
    >;
    limit?: InputMaybe<Scalars["Int"]>;
    offset?: InputMaybe<Scalars["Int"]>;
    order_by?: InputMaybe<
      Array<Client_Candidate_Project_Candidate_Resolution_Order_By>
    >;
    where?: InputMaybe<Client_Candidate_Project_Candidate_Resolution_Bool_Exp>;
  };

export type Query_RootClient_Candidate_Project_Candidate_Resolution_By_PkArgs =
  {
    resolution: Scalars["String"];
  };

export type Query_RootClient_Candidate_Project_NoteArgs = {
  distinct_on?: InputMaybe<Array<Client_Candidate_Project_Note_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Client_Candidate_Project_Note_Order_By>>;
  where?: InputMaybe<Client_Candidate_Project_Note_Bool_Exp>;
};

export type Query_RootClient_Candidate_Project_Note_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Candidate_Project_Note_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Client_Candidate_Project_Note_Order_By>>;
  where?: InputMaybe<Client_Candidate_Project_Note_Bool_Exp>;
};

export type Query_RootClient_Candidate_Project_Note_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootClient_LocationArgs = {
  distinct_on?: InputMaybe<Array<Client_Location_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Client_Location_Order_By>>;
  where?: InputMaybe<Client_Location_Bool_Exp>;
};

export type Query_RootClient_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Location_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Client_Location_Order_By>>;
  where?: InputMaybe<Client_Location_Bool_Exp>;
};

export type Query_RootClient_Location_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootClient_SectorArgs = {
  distinct_on?: InputMaybe<Array<Client_Sector_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Client_Sector_Order_By>>;
  where?: InputMaybe<Client_Sector_Bool_Exp>;
};

export type Query_RootClient_Sector_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Sector_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Client_Sector_Order_By>>;
  where?: InputMaybe<Client_Sector_Bool_Exp>;
};

export type Query_RootClient_Sector_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootClient_SettingArgs = {
  distinct_on?: InputMaybe<Array<Client_Setting_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Client_Setting_Order_By>>;
  where?: InputMaybe<Client_Setting_Bool_Exp>;
};

export type Query_RootClient_Setting_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Setting_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Client_Setting_Order_By>>;
  where?: InputMaybe<Client_Setting_Bool_Exp>;
};

export type Query_RootClient_Setting_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootClient_Setting_KeyArgs = {
  distinct_on?: InputMaybe<Array<Client_Setting_Key_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Client_Setting_Key_Order_By>>;
  where?: InputMaybe<Client_Setting_Key_Bool_Exp>;
};

export type Query_RootClient_Setting_Key_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Setting_Key_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Client_Setting_Key_Order_By>>;
  where?: InputMaybe<Client_Setting_Key_Bool_Exp>;
};

export type Query_RootClient_Setting_Key_By_PkArgs = {
  key: Scalars["String"];
};

export type Query_RootCompanyArgs = {
  distinct_on?: InputMaybe<Array<Company_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Company_Order_By>>;
  where?: InputMaybe<Company_Bool_Exp>;
};

export type Query_RootCompany_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Company_Order_By>>;
  where?: InputMaybe<Company_Bool_Exp>;
};

export type Query_RootCompany_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootCompany_SubscriptionArgs = {
  distinct_on?: InputMaybe<Array<Company_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Company_Subscription_Order_By>>;
  where?: InputMaybe<Company_Subscription_Bool_Exp>;
};

export type Query_RootCompany_Subscription_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Company_Subscription_Order_By>>;
  where?: InputMaybe<Company_Subscription_Bool_Exp>;
};

export type Query_RootCompany_Subscription_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootConsentArgs = {
  distinct_on?: InputMaybe<Array<Consent_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Consent_Order_By>>;
  where?: InputMaybe<Consent_Bool_Exp>;
};

export type Query_RootConsent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Consent_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Consent_Order_By>>;
  where?: InputMaybe<Consent_Bool_Exp>;
};

export type Query_RootConsent_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootConsent_StatusArgs = {
  distinct_on?: InputMaybe<Array<Consent_Status_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Consent_Status_Order_By>>;
  where?: InputMaybe<Consent_Status_Bool_Exp>;
};

export type Query_RootConsent_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Consent_Status_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Consent_Status_Order_By>>;
  where?: InputMaybe<Consent_Status_Bool_Exp>;
};

export type Query_RootConsent_Status_By_PkArgs = {
  consent_status: Scalars["String"];
};

export type Query_RootConsent_TypeArgs = {
  distinct_on?: InputMaybe<Array<Consent_Type_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Consent_Type_Order_By>>;
  where?: InputMaybe<Consent_Type_Bool_Exp>;
};

export type Query_RootConsent_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Consent_Type_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Consent_Type_Order_By>>;
  where?: InputMaybe<Consent_Type_Bool_Exp>;
};

export type Query_RootConsent_Type_By_PkArgs = {
  consent_type: Scalars["String"];
};

export type Query_RootCountryArgs = {
  distinct_on?: InputMaybe<Array<Country_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Country_Order_By>>;
  where?: InputMaybe<Country_Bool_Exp>;
};

export type Query_RootCountry_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Country_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Country_Order_By>>;
  where?: InputMaybe<Country_Bool_Exp>;
};

export type Query_RootCountry_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootCurrencyArgs = {
  distinct_on?: InputMaybe<Array<Currency_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Currency_Order_By>>;
  where?: InputMaybe<Currency_Bool_Exp>;
};

export type Query_RootCurrency_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Currency_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Currency_Order_By>>;
  where?: InputMaybe<Currency_Bool_Exp>;
};

export type Query_RootCurrency_By_PkArgs = {
  currency: Scalars["String"];
};

export type Query_RootDepartmentArgs = {
  distinct_on?: InputMaybe<Array<Department_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Department_Order_By>>;
  where?: InputMaybe<Department_Bool_Exp>;
};

export type Query_RootDepartment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Department_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Department_Order_By>>;
  where?: InputMaybe<Department_Bool_Exp>;
};

export type Query_RootDepartment_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootEducation_TypeArgs = {
  distinct_on?: InputMaybe<Array<Education_Type_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Education_Type_Order_By>>;
  where?: InputMaybe<Education_Type_Bool_Exp>;
};

export type Query_RootEducation_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Education_Type_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Education_Type_Order_By>>;
  where?: InputMaybe<Education_Type_Bool_Exp>;
};

export type Query_RootEducation_Type_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootGetNextJdQuestionArgs = {
  jdId: Scalars["uuid"];
};

export type Query_RootGetSignedUrlArgs = {
  signedUrlInput: SignedUrlInput;
};

export type Query_RootInterview_CriterionArgs = {
  distinct_on?: InputMaybe<Array<Interview_Criterion_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Interview_Criterion_Order_By>>;
  where?: InputMaybe<Interview_Criterion_Bool_Exp>;
};

export type Query_RootInterview_Criterion_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Interview_Criterion_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Interview_Criterion_Order_By>>;
  where?: InputMaybe<Interview_Criterion_Bool_Exp>;
};

export type Query_RootInterview_Criterion_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootInterview_TemplateArgs = {
  distinct_on?: InputMaybe<Array<Interview_Template_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Interview_Template_Order_By>>;
  where?: InputMaybe<Interview_Template_Bool_Exp>;
};

export type Query_RootInterview_Template_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Interview_Template_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Interview_Template_Order_By>>;
  where?: InputMaybe<Interview_Template_Bool_Exp>;
};

export type Query_RootInterview_Template_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootInterview_Template_Review_Metric_QuestionArgs = {
  distinct_on?: InputMaybe<
    Array<Interview_Template_Review_Metric_Question_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<
    Array<Interview_Template_Review_Metric_Question_Order_By>
  >;
  where?: InputMaybe<Interview_Template_Review_Metric_Question_Bool_Exp>;
};

export type Query_RootInterview_Template_Review_Metric_Question_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Interview_Template_Review_Metric_Question_Select_Column>
    >;
    limit?: InputMaybe<Scalars["Int"]>;
    offset?: InputMaybe<Scalars["Int"]>;
    order_by?: InputMaybe<
      Array<Interview_Template_Review_Metric_Question_Order_By>
    >;
    where?: InputMaybe<Interview_Template_Review_Metric_Question_Bool_Exp>;
  };

export type Query_RootInterview_Template_Review_Metric_Question_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootJob_DescriptionArgs = {
  distinct_on?: InputMaybe<Array<Job_Description_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Job_Description_Order_By>>;
  where?: InputMaybe<Job_Description_Bool_Exp>;
};

export type Query_RootJob_Description_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Description_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Job_Description_Order_By>>;
  where?: InputMaybe<Job_Description_Bool_Exp>;
};

export type Query_RootJob_Description_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootJob_Description_StepArgs = {
  distinct_on?: InputMaybe<Array<Job_Description_Step_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Job_Description_Step_Order_By>>;
  where?: InputMaybe<Job_Description_Step_Bool_Exp>;
};

export type Query_RootJob_Description_Step_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Description_Step_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Job_Description_Step_Order_By>>;
  where?: InputMaybe<Job_Description_Step_Bool_Exp>;
};

export type Query_RootJob_Description_Step_By_PkArgs = {
  step: Scalars["String"];
};

export type Query_RootLanguageArgs = {
  distinct_on?: InputMaybe<Array<Language_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Language_Order_By>>;
  where?: InputMaybe<Language_Bool_Exp>;
};

export type Query_RootLanguage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Language_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Language_Order_By>>;
  where?: InputMaybe<Language_Bool_Exp>;
};

export type Query_RootLanguage_By_PkArgs = {
  language: Scalars["String"];
};

export type Query_RootPartnerArgs = {
  distinct_on?: InputMaybe<Array<Partner_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Partner_Order_By>>;
  where?: InputMaybe<Partner_Bool_Exp>;
};

export type Query_RootPartner_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Partner_Order_By>>;
  where?: InputMaybe<Partner_Bool_Exp>;
};

export type Query_RootPartner_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootProductArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};

export type Query_RootProduct_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};

export type Query_RootProduct_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootProduct_PriceArgs = {
  distinct_on?: InputMaybe<Array<Product_Price_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Price_Order_By>>;
  where?: InputMaybe<Product_Price_Bool_Exp>;
};

export type Query_RootProduct_Price_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Price_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Price_Order_By>>;
  where?: InputMaybe<Product_Price_Bool_Exp>;
};

export type Query_RootProduct_Price_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootProjectArgs = {
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Project_Order_By>>;
  where?: InputMaybe<Project_Bool_Exp>;
};

export type Query_RootProject_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Project_Order_By>>;
  where?: InputMaybe<Project_Bool_Exp>;
};

export type Query_RootProject_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootProject_StatusArgs = {
  distinct_on?: InputMaybe<Array<Project_Status_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Project_Status_Order_By>>;
  where?: InputMaybe<Project_Status_Bool_Exp>;
};

export type Query_RootProject_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Status_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Project_Status_Order_By>>;
  where?: InputMaybe<Project_Status_Bool_Exp>;
};

export type Query_RootProject_Status_By_PkArgs = {
  status: Scalars["String"];
};

export type Query_RootProject_UserArgs = {
  distinct_on?: InputMaybe<Array<Project_User_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Project_User_Order_By>>;
  where?: InputMaybe<Project_User_Bool_Exp>;
};

export type Query_RootProject_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_User_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Project_User_Order_By>>;
  where?: InputMaybe<Project_User_Bool_Exp>;
};

export type Query_RootProject_User_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootReview_MetricArgs = {
  distinct_on?: InputMaybe<Array<Review_Metric_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Review_Metric_Order_By>>;
  where?: InputMaybe<Review_Metric_Bool_Exp>;
};

export type Query_RootReview_Metric_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Review_Metric_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Review_Metric_Order_By>>;
  where?: InputMaybe<Review_Metric_Bool_Exp>;
};

export type Query_RootReview_Metric_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootReview_Metric_QuestionArgs = {
  distinct_on?: InputMaybe<Array<Review_Metric_Question_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Review_Metric_Question_Order_By>>;
  where?: InputMaybe<Review_Metric_Question_Bool_Exp>;
};

export type Query_RootReview_Metric_Question_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Review_Metric_Question_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Review_Metric_Question_Order_By>>;
  where?: InputMaybe<Review_Metric_Question_Bool_Exp>;
};

export type Query_RootReview_Metric_Question_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootReview_Metric_Question_CriterionArgs = {
  distinct_on?: InputMaybe<
    Array<Review_Metric_Question_Criterion_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Review_Metric_Question_Criterion_Order_By>>;
  where?: InputMaybe<Review_Metric_Question_Criterion_Bool_Exp>;
};

export type Query_RootReview_Metric_Question_Criterion_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Review_Metric_Question_Criterion_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Review_Metric_Question_Criterion_Order_By>>;
  where?: InputMaybe<Review_Metric_Question_Criterion_Bool_Exp>;
};

export type Query_RootReview_Metric_Question_Criterion_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootReview_Metric_Question_ResponseArgs = {
  distinct_on?: InputMaybe<
    Array<Review_Metric_Question_Response_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Review_Metric_Question_Response_Order_By>>;
  where?: InputMaybe<Review_Metric_Question_Response_Bool_Exp>;
};

export type Query_RootReview_Metric_Question_Response_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Review_Metric_Question_Response_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Review_Metric_Question_Response_Order_By>>;
  where?: InputMaybe<Review_Metric_Question_Response_Bool_Exp>;
};

export type Query_RootReview_Metric_Question_Response_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootReview_Metric_Question_TypeArgs = {
  distinct_on?: InputMaybe<Array<Review_Metric_Question_Type_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Review_Metric_Question_Type_Order_By>>;
  where?: InputMaybe<Review_Metric_Question_Type_Bool_Exp>;
};

export type Query_RootReview_Metric_Question_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Review_Metric_Question_Type_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Review_Metric_Question_Type_Order_By>>;
  where?: InputMaybe<Review_Metric_Question_Type_Bool_Exp>;
};

export type Query_RootReview_Metric_Question_Type_By_PkArgs = {
  question_type: Scalars["String"];
};

export type Query_RootRoleArgs = {
  distinct_on?: InputMaybe<Array<Role_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Order_By>>;
  where?: InputMaybe<Role_Bool_Exp>;
};

export type Query_RootRole_Academic_DepartmentArgs = {
  distinct_on?: InputMaybe<Array<Role_Academic_Department_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Academic_Department_Order_By>>;
  where?: InputMaybe<Role_Academic_Department_Bool_Exp>;
};

export type Query_RootRole_Academic_Department_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Academic_Department_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Academic_Department_Order_By>>;
  where?: InputMaybe<Role_Academic_Department_Bool_Exp>;
};

export type Query_RootRole_Academic_Department_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootRole_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Order_By>>;
  where?: InputMaybe<Role_Bool_Exp>;
};

export type Query_RootRole_BenefitArgs = {
  distinct_on?: InputMaybe<Array<Role_Benefit_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Benefit_Order_By>>;
  where?: InputMaybe<Role_Benefit_Bool_Exp>;
};

export type Query_RootRole_Benefit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Benefit_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Benefit_Order_By>>;
  where?: InputMaybe<Role_Benefit_Bool_Exp>;
};

export type Query_RootRole_Benefit_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootRole_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootRole_CompetenceArgs = {
  distinct_on?: InputMaybe<Array<Role_Competence_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Competence_Order_By>>;
  where?: InputMaybe<Role_Competence_Bool_Exp>;
};

export type Query_RootRole_Competence_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Competence_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Competence_Order_By>>;
  where?: InputMaybe<Role_Competence_Bool_Exp>;
};

export type Query_RootRole_Competence_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootRole_DescriptionArgs = {
  distinct_on?: InputMaybe<Array<Role_Description_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Description_Order_By>>;
  where?: InputMaybe<Role_Description_Bool_Exp>;
};

export type Query_RootRole_Description_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Description_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Description_Order_By>>;
  where?: InputMaybe<Role_Description_Bool_Exp>;
};

export type Query_RootRole_Description_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootRole_ResponsibilityArgs = {
  distinct_on?: InputMaybe<Array<Role_Responsibility_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Responsibility_Order_By>>;
  where?: InputMaybe<Role_Responsibility_Bool_Exp>;
};

export type Query_RootRole_Responsibility_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Responsibility_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Responsibility_Order_By>>;
  where?: InputMaybe<Role_Responsibility_Bool_Exp>;
};

export type Query_RootRole_Responsibility_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootRole_Review_MetricArgs = {
  distinct_on?: InputMaybe<Array<Role_Review_Metric_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Review_Metric_Order_By>>;
  where?: InputMaybe<Role_Review_Metric_Bool_Exp>;
};

export type Query_RootRole_Review_Metric_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Review_Metric_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Review_Metric_Order_By>>;
  where?: InputMaybe<Role_Review_Metric_Bool_Exp>;
};

export type Query_RootRole_Review_Metric_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootRole_SkillArgs = {
  distinct_on?: InputMaybe<Array<Role_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Skill_Order_By>>;
  where?: InputMaybe<Role_Skill_Bool_Exp>;
};

export type Query_RootRole_Skill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Skill_Order_By>>;
  where?: InputMaybe<Role_Skill_Bool_Exp>;
};

export type Query_RootRole_Skill_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootSearchArgs = {
  index: Scalars["String"];
  limit: Scalars["Int"];
  offset: Scalars["Int"];
  query: Scalars["String"];
};

export type Query_RootSectorArgs = {
  distinct_on?: InputMaybe<Array<Sector_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Sector_Order_By>>;
  where?: InputMaybe<Sector_Bool_Exp>;
};

export type Query_RootSector_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sector_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Sector_Order_By>>;
  where?: InputMaybe<Sector_Bool_Exp>;
};

export type Query_RootSector_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};

export type Query_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};

export type Query_RootUser_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootUser_KeyArgs = {
  distinct_on?: InputMaybe<Array<User_Key_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Key_Order_By>>;
  where?: InputMaybe<User_Key_Bool_Exp>;
};

export type Query_RootUser_Key_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Key_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Key_Order_By>>;
  where?: InputMaybe<User_Key_Bool_Exp>;
};

export type Query_RootUser_Key_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootUser_RoleArgs = {
  distinct_on?: InputMaybe<Array<User_Role_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Role_Order_By>>;
  where?: InputMaybe<User_Role_Bool_Exp>;
};

export type Query_RootUser_Role_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Role_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Role_Order_By>>;
  where?: InputMaybe<User_Role_Bool_Exp>;
};

export type Query_RootUser_Role_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootUser_SettingArgs = {
  distinct_on?: InputMaybe<Array<User_Setting_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Setting_Order_By>>;
  where?: InputMaybe<User_Setting_Bool_Exp>;
};

export type Query_RootUser_Setting_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Setting_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Setting_Order_By>>;
  where?: InputMaybe<User_Setting_Bool_Exp>;
};

export type Query_RootUser_Setting_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootUser_Setting_KeyArgs = {
  distinct_on?: InputMaybe<Array<User_Setting_Key_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Setting_Key_Order_By>>;
  where?: InputMaybe<User_Setting_Key_Bool_Exp>;
};

export type Query_RootUser_Setting_Key_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Setting_Key_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Setting_Key_Order_By>>;
  where?: InputMaybe<User_Setting_Key_Bool_Exp>;
};

export type Query_RootUser_Setting_Key_By_PkArgs = {
  key: Scalars["String"];
};

export type Query_RootUser_StatusArgs = {
  distinct_on?: InputMaybe<Array<User_Status_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Status_Order_By>>;
  where?: InputMaybe<User_Status_Bool_Exp>;
};

export type Query_RootUser_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Status_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Status_Order_By>>;
  where?: InputMaybe<User_Status_Bool_Exp>;
};

export type Query_RootUser_Status_By_PkArgs = {
  status: Scalars["String"];
};

export type Query_RootVideo_InterviewArgs = {
  distinct_on?: InputMaybe<Array<Video_Interview_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Order_By>>;
  where?: InputMaybe<Video_Interview_Bool_Exp>;
};

export type Query_RootVideo_Interview_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Video_Interview_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Order_By>>;
  where?: InputMaybe<Video_Interview_Bool_Exp>;
};

export type Query_RootVideo_Interview_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootVideo_Interview_Criterion_ScoreArgs = {
  distinct_on?: InputMaybe<
    Array<Video_Interview_Criterion_Score_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Criterion_Score_Order_By>>;
  where?: InputMaybe<Video_Interview_Criterion_Score_Bool_Exp>;
};

export type Query_RootVideo_Interview_Criterion_Score_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Video_Interview_Criterion_Score_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Criterion_Score_Order_By>>;
  where?: InputMaybe<Video_Interview_Criterion_Score_Bool_Exp>;
};

export type Query_RootVideo_Interview_Criterion_Score_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootVideo_Interview_QuestionArgs = {
  distinct_on?: InputMaybe<Array<Video_Interview_Question_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Question_Order_By>>;
  where?: InputMaybe<Video_Interview_Question_Bool_Exp>;
};

export type Query_RootVideo_Interview_Question_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Video_Interview_Question_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Question_Order_By>>;
  where?: InputMaybe<Video_Interview_Question_Bool_Exp>;
};

export type Query_RootVideo_Interview_Question_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootVideo_Interview_Question_EventArgs = {
  distinct_on?: InputMaybe<Array<Video_Interview_Question_Event_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Question_Event_Order_By>>;
  where?: InputMaybe<Video_Interview_Question_Event_Bool_Exp>;
};

export type Query_RootVideo_Interview_Question_Event_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Video_Interview_Question_Event_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Question_Event_Order_By>>;
  where?: InputMaybe<Video_Interview_Question_Event_Bool_Exp>;
};

export type Query_RootVideo_Interview_Question_Event_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootVideo_Interview_Question_Event_TypeArgs = {
  distinct_on?: InputMaybe<
    Array<Video_Interview_Question_Event_Type_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Question_Event_Type_Order_By>>;
  where?: InputMaybe<Video_Interview_Question_Event_Type_Bool_Exp>;
};

export type Query_RootVideo_Interview_Question_Event_Type_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Video_Interview_Question_Event_Type_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Question_Event_Type_Order_By>>;
  where?: InputMaybe<Video_Interview_Question_Event_Type_Bool_Exp>;
};

export type Query_RootVideo_Interview_Question_Event_Type_By_PkArgs = {
  type: Scalars["String"];
};

export type Query_RootVideo_Interview_Question_ScoreArgs = {
  distinct_on?: InputMaybe<Array<Video_Interview_Question_Score_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Question_Score_Order_By>>;
  where?: InputMaybe<Video_Interview_Question_Score_Bool_Exp>;
};

export type Query_RootVideo_Interview_Question_Score_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Video_Interview_Question_Score_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Question_Score_Order_By>>;
  where?: InputMaybe<Video_Interview_Question_Score_Bool_Exp>;
};

export type Query_RootVideo_Interview_Question_Score_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootVideo_Interview_ReviewerArgs = {
  distinct_on?: InputMaybe<Array<Video_Interview_Reviewer_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Reviewer_Order_By>>;
  where?: InputMaybe<Video_Interview_Reviewer_Bool_Exp>;
};

export type Query_RootVideo_Interview_Reviewer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Video_Interview_Reviewer_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Reviewer_Order_By>>;
  where?: InputMaybe<Video_Interview_Reviewer_Bool_Exp>;
};

export type Query_RootVideo_Interview_Reviewer_By_PkArgs = {
  reviewer: Scalars["String"];
};

export type Query_RootVideo_Interview_StatusArgs = {
  distinct_on?: InputMaybe<Array<Video_Interview_Status_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Status_Order_By>>;
  where?: InputMaybe<Video_Interview_Status_Bool_Exp>;
};

export type Query_RootVideo_Interview_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Video_Interview_Status_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Status_Order_By>>;
  where?: InputMaybe<Video_Interview_Status_Bool_Exp>;
};

export type Query_RootVideo_Interview_Status_By_PkArgs = {
  status: Scalars["String"];
};

export type Query_RootVideo_Interview_Status_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Video_Interview_Status_History_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Status_History_Order_By>>;
  where?: InputMaybe<Video_Interview_Status_History_Bool_Exp>;
};

export type Query_RootVideo_Interview_Status_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Video_Interview_Status_History_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Status_History_Order_By>>;
  where?: InputMaybe<Video_Interview_Status_History_Bool_Exp>;
};

export type Query_RootVideo_Interview_Status_History_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootVideo_Interview_TypeArgs = {
  distinct_on?: InputMaybe<Array<Video_Interview_Type_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Type_Order_By>>;
  where?: InputMaybe<Video_Interview_Type_Bool_Exp>;
};

export type Query_RootVideo_Interview_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Video_Interview_Type_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Type_Order_By>>;
  where?: InputMaybe<Video_Interview_Type_Bool_Exp>;
};

export type Query_RootVideo_Interview_Type_By_PkArgs = {
  video_interview_type: Scalars["String"];
};

export type Query_RootVideo_Process_StatusArgs = {
  distinct_on?: InputMaybe<Array<Video_Process_Status_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Process_Status_Order_By>>;
  where?: InputMaybe<Video_Process_Status_Bool_Exp>;
};

export type Query_RootVideo_Process_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Video_Process_Status_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Process_Status_Order_By>>;
  where?: InputMaybe<Video_Process_Status_Bool_Exp>;
};

export type Query_RootVideo_Process_Status_By_PkArgs = {
  status: Scalars["String"];
};

export type Query_RootView__Client_Candidate_ProjectsArgs = {
  distinct_on?: InputMaybe<
    Array<View__Client_Candidate_Projects_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<View__Client_Candidate_Projects_Order_By>>;
  where?: InputMaybe<View__Client_Candidate_Projects_Bool_Exp>;
};

export type Query_RootView__Client_Candidate_Projects_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<View__Client_Candidate_Projects_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<View__Client_Candidate_Projects_Order_By>>;
  where?: InputMaybe<View__Client_Candidate_Projects_Bool_Exp>;
};

export type Query_RootView__Ordered_Video_Interview_QuestionArgs = {
  distinct_on?: InputMaybe<
    Array<View__Ordered_Video_Interview_Question_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<View__Ordered_Video_Interview_Question_Order_By>>;
  where?: InputMaybe<View__Ordered_Video_Interview_Question_Bool_Exp>;
};

export type Query_RootView__Ordered_Video_Interview_Question_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<View__Ordered_Video_Interview_Question_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<View__Ordered_Video_Interview_Question_Order_By>>;
  where?: InputMaybe<View__Ordered_Video_Interview_Question_Bool_Exp>;
};

/** columns and relationships of "review_metric" */
export type Review_Metric = {
  __typename?: "review_metric";
  description: Scalars["String"];
  id: Scalars["Int"];
  language: Language_Enum;
  order: Scalars["Int"];
  /** An object relationship */
  review_metric_language: Language;
  /** An array relationship */
  review_metric_questions: Array<Review_Metric_Question>;
  /** An aggregate relationship */
  review_metric_questions_aggregate: Review_Metric_Question_Aggregate;
};

/** columns and relationships of "review_metric" */
export type Review_MetricReview_Metric_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Review_Metric_Question_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Review_Metric_Question_Order_By>>;
  where?: InputMaybe<Review_Metric_Question_Bool_Exp>;
};

/** columns and relationships of "review_metric" */
export type Review_MetricReview_Metric_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Review_Metric_Question_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Review_Metric_Question_Order_By>>;
  where?: InputMaybe<Review_Metric_Question_Bool_Exp>;
};

/** aggregated selection of "review_metric" */
export type Review_Metric_Aggregate = {
  __typename?: "review_metric_aggregate";
  aggregate?: Maybe<Review_Metric_Aggregate_Fields>;
  nodes: Array<Review_Metric>;
};

/** aggregate fields of "review_metric" */
export type Review_Metric_Aggregate_Fields = {
  __typename?: "review_metric_aggregate_fields";
  avg?: Maybe<Review_Metric_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Review_Metric_Max_Fields>;
  min?: Maybe<Review_Metric_Min_Fields>;
  stddev?: Maybe<Review_Metric_Stddev_Fields>;
  stddev_pop?: Maybe<Review_Metric_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Review_Metric_Stddev_Samp_Fields>;
  sum?: Maybe<Review_Metric_Sum_Fields>;
  var_pop?: Maybe<Review_Metric_Var_Pop_Fields>;
  var_samp?: Maybe<Review_Metric_Var_Samp_Fields>;
  variance?: Maybe<Review_Metric_Variance_Fields>;
};

/** aggregate fields of "review_metric" */
export type Review_Metric_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Review_Metric_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Review_Metric_Avg_Fields = {
  __typename?: "review_metric_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  order?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "review_metric". All fields are combined with a logical 'AND'. */
export type Review_Metric_Bool_Exp = {
  _and?: InputMaybe<Array<Review_Metric_Bool_Exp>>;
  _not?: InputMaybe<Review_Metric_Bool_Exp>;
  _or?: InputMaybe<Array<Review_Metric_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  language?: InputMaybe<Language_Enum_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  review_metric_language?: InputMaybe<Language_Bool_Exp>;
  review_metric_questions?: InputMaybe<Review_Metric_Question_Bool_Exp>;
  review_metric_questions_aggregate?: InputMaybe<Review_Metric_Question_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "review_metric" */
export enum Review_Metric_Constraint {
  /** unique or primary key constraint on columns "id" */
  ReviewMetricPkey = "review_metric_pkey",
}

/** input type for incrementing numeric columns in table "review_metric" */
export type Review_Metric_Inc_Input = {
  id?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "review_metric" */
export type Review_Metric_Insert_Input = {
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  language?: InputMaybe<Language_Enum>;
  order?: InputMaybe<Scalars["Int"]>;
  review_metric_language?: InputMaybe<Language_Obj_Rel_Insert_Input>;
  review_metric_questions?: InputMaybe<Review_Metric_Question_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Review_Metric_Max_Fields = {
  __typename?: "review_metric_max_fields";
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  order?: Maybe<Scalars["Int"]>;
};

/** aggregate min on columns */
export type Review_Metric_Min_Fields = {
  __typename?: "review_metric_min_fields";
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  order?: Maybe<Scalars["Int"]>;
};

/** response of any mutation on the table "review_metric" */
export type Review_Metric_Mutation_Response = {
  __typename?: "review_metric_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Review_Metric>;
};

/** input type for inserting object relation for remote table "review_metric" */
export type Review_Metric_Obj_Rel_Insert_Input = {
  data: Review_Metric_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Review_Metric_On_Conflict>;
};

/** on_conflict condition type for table "review_metric" */
export type Review_Metric_On_Conflict = {
  constraint: Review_Metric_Constraint;
  update_columns?: Array<Review_Metric_Update_Column>;
  where?: InputMaybe<Review_Metric_Bool_Exp>;
};

/** Ordering options when selecting data from "review_metric". */
export type Review_Metric_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  review_metric_language?: InputMaybe<Language_Order_By>;
  review_metric_questions_aggregate?: InputMaybe<Review_Metric_Question_Aggregate_Order_By>;
};

/** primary key columns input for table: review_metric */
export type Review_Metric_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** columns and relationships of "review_metric_question" */
export type Review_Metric_Question = {
  __typename?: "review_metric_question";
  company_id?: Maybe<Scalars["uuid"]>;
  description: Scalars["String"];
  id: Scalars["Int"];
  /** An array relationship */
  interview_template_review_metric_questions: Array<Interview_Template_Review_Metric_Question>;
  /** An aggregate relationship */
  interview_template_review_metric_questions_aggregate: Interview_Template_Review_Metric_Question_Aggregate;
  is_shown_to_candidate: Scalars["Boolean"];
  judge?: Maybe<Scalars["String"]>;
  order: Scalars["Int"];
  question?: Maybe<Scalars["String"]>;
  question_type: Review_Metric_Question_Type_Enum;
  /** An object relationship */
  review_metric: Review_Metric;
  review_metric_id: Scalars["Int"];
  /** An array relationship */
  review_metric_question_criteria: Array<Review_Metric_Question_Criterion>;
  /** An aggregate relationship */
  review_metric_question_criteria_aggregate: Review_Metric_Question_Criterion_Aggregate;
  /** An array relationship */
  review_metric_question_responses: Array<Review_Metric_Question_Response>;
  /** An aggregate relationship */
  review_metric_question_responses_aggregate: Review_Metric_Question_Response_Aggregate;
  reviewer_description?: Maybe<Scalars["String"]>;
  tip?: Maybe<Scalars["String"]>;
  /** An array relationship */
  video_interview_questions: Array<Video_Interview_Question>;
  /** An aggregate relationship */
  video_interview_questions_aggregate: Video_Interview_Question_Aggregate;
};

/** columns and relationships of "review_metric_question" */
export type Review_Metric_QuestionInterview_Template_Review_Metric_QuestionsArgs =
  {
    distinct_on?: InputMaybe<
      Array<Interview_Template_Review_Metric_Question_Select_Column>
    >;
    limit?: InputMaybe<Scalars["Int"]>;
    offset?: InputMaybe<Scalars["Int"]>;
    order_by?: InputMaybe<
      Array<Interview_Template_Review_Metric_Question_Order_By>
    >;
    where?: InputMaybe<Interview_Template_Review_Metric_Question_Bool_Exp>;
  };

/** columns and relationships of "review_metric_question" */
export type Review_Metric_QuestionInterview_Template_Review_Metric_Questions_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Interview_Template_Review_Metric_Question_Select_Column>
    >;
    limit?: InputMaybe<Scalars["Int"]>;
    offset?: InputMaybe<Scalars["Int"]>;
    order_by?: InputMaybe<
      Array<Interview_Template_Review_Metric_Question_Order_By>
    >;
    where?: InputMaybe<Interview_Template_Review_Metric_Question_Bool_Exp>;
  };

/** columns and relationships of "review_metric_question" */
export type Review_Metric_QuestionReview_Metric_Question_CriteriaArgs = {
  distinct_on?: InputMaybe<
    Array<Review_Metric_Question_Criterion_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Review_Metric_Question_Criterion_Order_By>>;
  where?: InputMaybe<Review_Metric_Question_Criterion_Bool_Exp>;
};

/** columns and relationships of "review_metric_question" */
export type Review_Metric_QuestionReview_Metric_Question_Criteria_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Review_Metric_Question_Criterion_Select_Column>
    >;
    limit?: InputMaybe<Scalars["Int"]>;
    offset?: InputMaybe<Scalars["Int"]>;
    order_by?: InputMaybe<Array<Review_Metric_Question_Criterion_Order_By>>;
    where?: InputMaybe<Review_Metric_Question_Criterion_Bool_Exp>;
  };

/** columns and relationships of "review_metric_question" */
export type Review_Metric_QuestionReview_Metric_Question_ResponsesArgs = {
  distinct_on?: InputMaybe<
    Array<Review_Metric_Question_Response_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Review_Metric_Question_Response_Order_By>>;
  where?: InputMaybe<Review_Metric_Question_Response_Bool_Exp>;
};

/** columns and relationships of "review_metric_question" */
export type Review_Metric_QuestionReview_Metric_Question_Responses_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Review_Metric_Question_Response_Select_Column>
    >;
    limit?: InputMaybe<Scalars["Int"]>;
    offset?: InputMaybe<Scalars["Int"]>;
    order_by?: InputMaybe<Array<Review_Metric_Question_Response_Order_By>>;
    where?: InputMaybe<Review_Metric_Question_Response_Bool_Exp>;
  };

/** columns and relationships of "review_metric_question" */
export type Review_Metric_QuestionVideo_Interview_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Video_Interview_Question_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Question_Order_By>>;
  where?: InputMaybe<Video_Interview_Question_Bool_Exp>;
};

/** columns and relationships of "review_metric_question" */
export type Review_Metric_QuestionVideo_Interview_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Video_Interview_Question_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Question_Order_By>>;
  where?: InputMaybe<Video_Interview_Question_Bool_Exp>;
};

/** aggregated selection of "review_metric_question" */
export type Review_Metric_Question_Aggregate = {
  __typename?: "review_metric_question_aggregate";
  aggregate?: Maybe<Review_Metric_Question_Aggregate_Fields>;
  nodes: Array<Review_Metric_Question>;
};

export type Review_Metric_Question_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Review_Metric_Question_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Review_Metric_Question_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Review_Metric_Question_Aggregate_Bool_Exp_Count>;
};

export type Review_Metric_Question_Aggregate_Bool_Exp_Bool_And = {
  arguments: Review_Metric_Question_Select_Column_Review_Metric_Question_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Review_Metric_Question_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Review_Metric_Question_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Review_Metric_Question_Select_Column_Review_Metric_Question_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Review_Metric_Question_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Review_Metric_Question_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Review_Metric_Question_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Review_Metric_Question_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "review_metric_question" */
export type Review_Metric_Question_Aggregate_Fields = {
  __typename?: "review_metric_question_aggregate_fields";
  avg?: Maybe<Review_Metric_Question_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Review_Metric_Question_Max_Fields>;
  min?: Maybe<Review_Metric_Question_Min_Fields>;
  stddev?: Maybe<Review_Metric_Question_Stddev_Fields>;
  stddev_pop?: Maybe<Review_Metric_Question_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Review_Metric_Question_Stddev_Samp_Fields>;
  sum?: Maybe<Review_Metric_Question_Sum_Fields>;
  var_pop?: Maybe<Review_Metric_Question_Var_Pop_Fields>;
  var_samp?: Maybe<Review_Metric_Question_Var_Samp_Fields>;
  variance?: Maybe<Review_Metric_Question_Variance_Fields>;
};

/** aggregate fields of "review_metric_question" */
export type Review_Metric_Question_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Review_Metric_Question_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "review_metric_question" */
export type Review_Metric_Question_Aggregate_Order_By = {
  avg?: InputMaybe<Review_Metric_Question_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Review_Metric_Question_Max_Order_By>;
  min?: InputMaybe<Review_Metric_Question_Min_Order_By>;
  stddev?: InputMaybe<Review_Metric_Question_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Review_Metric_Question_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Review_Metric_Question_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Review_Metric_Question_Sum_Order_By>;
  var_pop?: InputMaybe<Review_Metric_Question_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Review_Metric_Question_Var_Samp_Order_By>;
  variance?: InputMaybe<Review_Metric_Question_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "review_metric_question" */
export type Review_Metric_Question_Arr_Rel_Insert_Input = {
  data: Array<Review_Metric_Question_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Review_Metric_Question_On_Conflict>;
};

/** aggregate avg on columns */
export type Review_Metric_Question_Avg_Fields = {
  __typename?: "review_metric_question_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  order?: Maybe<Scalars["Float"]>;
  review_metric_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "review_metric_question" */
export type Review_Metric_Question_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  review_metric_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "review_metric_question". All fields are combined with a logical 'AND'. */
export type Review_Metric_Question_Bool_Exp = {
  _and?: InputMaybe<Array<Review_Metric_Question_Bool_Exp>>;
  _not?: InputMaybe<Review_Metric_Question_Bool_Exp>;
  _or?: InputMaybe<Array<Review_Metric_Question_Bool_Exp>>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  interview_template_review_metric_questions?: InputMaybe<Interview_Template_Review_Metric_Question_Bool_Exp>;
  interview_template_review_metric_questions_aggregate?: InputMaybe<Interview_Template_Review_Metric_Question_Aggregate_Bool_Exp>;
  is_shown_to_candidate?: InputMaybe<Boolean_Comparison_Exp>;
  judge?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  question?: InputMaybe<String_Comparison_Exp>;
  question_type?: InputMaybe<Review_Metric_Question_Type_Enum_Comparison_Exp>;
  review_metric?: InputMaybe<Review_Metric_Bool_Exp>;
  review_metric_id?: InputMaybe<Int_Comparison_Exp>;
  review_metric_question_criteria?: InputMaybe<Review_Metric_Question_Criterion_Bool_Exp>;
  review_metric_question_criteria_aggregate?: InputMaybe<Review_Metric_Question_Criterion_Aggregate_Bool_Exp>;
  review_metric_question_responses?: InputMaybe<Review_Metric_Question_Response_Bool_Exp>;
  review_metric_question_responses_aggregate?: InputMaybe<Review_Metric_Question_Response_Aggregate_Bool_Exp>;
  reviewer_description?: InputMaybe<String_Comparison_Exp>;
  tip?: InputMaybe<String_Comparison_Exp>;
  video_interview_questions?: InputMaybe<Video_Interview_Question_Bool_Exp>;
  video_interview_questions_aggregate?: InputMaybe<Video_Interview_Question_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "review_metric_question" */
export enum Review_Metric_Question_Constraint {
  /** unique or primary key constraint on columns "id" */
  ReviewMetricQuestionPkey = "review_metric_question_pkey",
}

/** columns and relationships of "review_metric_question_criterion" */
export type Review_Metric_Question_Criterion = {
  __typename?: "review_metric_question_criterion";
  criterion_id: Scalars["Int"];
  id: Scalars["Int"];
  /** An object relationship */
  review_metric_criterion: Interview_Criterion;
  /** An object relationship */
  review_metric_question: Review_Metric_Question;
  review_metric_question_id: Scalars["Int"];
};

/** aggregated selection of "review_metric_question_criterion" */
export type Review_Metric_Question_Criterion_Aggregate = {
  __typename?: "review_metric_question_criterion_aggregate";
  aggregate?: Maybe<Review_Metric_Question_Criterion_Aggregate_Fields>;
  nodes: Array<Review_Metric_Question_Criterion>;
};

export type Review_Metric_Question_Criterion_Aggregate_Bool_Exp = {
  count?: InputMaybe<Review_Metric_Question_Criterion_Aggregate_Bool_Exp_Count>;
};

export type Review_Metric_Question_Criterion_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Review_Metric_Question_Criterion_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Review_Metric_Question_Criterion_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "review_metric_question_criterion" */
export type Review_Metric_Question_Criterion_Aggregate_Fields = {
  __typename?: "review_metric_question_criterion_aggregate_fields";
  avg?: Maybe<Review_Metric_Question_Criterion_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Review_Metric_Question_Criterion_Max_Fields>;
  min?: Maybe<Review_Metric_Question_Criterion_Min_Fields>;
  stddev?: Maybe<Review_Metric_Question_Criterion_Stddev_Fields>;
  stddev_pop?: Maybe<Review_Metric_Question_Criterion_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Review_Metric_Question_Criterion_Stddev_Samp_Fields>;
  sum?: Maybe<Review_Metric_Question_Criterion_Sum_Fields>;
  var_pop?: Maybe<Review_Metric_Question_Criterion_Var_Pop_Fields>;
  var_samp?: Maybe<Review_Metric_Question_Criterion_Var_Samp_Fields>;
  variance?: Maybe<Review_Metric_Question_Criterion_Variance_Fields>;
};

/** aggregate fields of "review_metric_question_criterion" */
export type Review_Metric_Question_Criterion_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Review_Metric_Question_Criterion_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "review_metric_question_criterion" */
export type Review_Metric_Question_Criterion_Aggregate_Order_By = {
  avg?: InputMaybe<Review_Metric_Question_Criterion_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Review_Metric_Question_Criterion_Max_Order_By>;
  min?: InputMaybe<Review_Metric_Question_Criterion_Min_Order_By>;
  stddev?: InputMaybe<Review_Metric_Question_Criterion_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Review_Metric_Question_Criterion_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Review_Metric_Question_Criterion_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Review_Metric_Question_Criterion_Sum_Order_By>;
  var_pop?: InputMaybe<Review_Metric_Question_Criterion_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Review_Metric_Question_Criterion_Var_Samp_Order_By>;
  variance?: InputMaybe<Review_Metric_Question_Criterion_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "review_metric_question_criterion" */
export type Review_Metric_Question_Criterion_Arr_Rel_Insert_Input = {
  data: Array<Review_Metric_Question_Criterion_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Review_Metric_Question_Criterion_On_Conflict>;
};

/** aggregate avg on columns */
export type Review_Metric_Question_Criterion_Avg_Fields = {
  __typename?: "review_metric_question_criterion_avg_fields";
  criterion_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  review_metric_question_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "review_metric_question_criterion" */
export type Review_Metric_Question_Criterion_Avg_Order_By = {
  criterion_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "review_metric_question_criterion". All fields are combined with a logical 'AND'. */
export type Review_Metric_Question_Criterion_Bool_Exp = {
  _and?: InputMaybe<Array<Review_Metric_Question_Criterion_Bool_Exp>>;
  _not?: InputMaybe<Review_Metric_Question_Criterion_Bool_Exp>;
  _or?: InputMaybe<Array<Review_Metric_Question_Criterion_Bool_Exp>>;
  criterion_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  review_metric_criterion?: InputMaybe<Interview_Criterion_Bool_Exp>;
  review_metric_question?: InputMaybe<Review_Metric_Question_Bool_Exp>;
  review_metric_question_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "review_metric_question_criterion" */
export enum Review_Metric_Question_Criterion_Constraint {
  /** unique or primary key constraint on columns "id" */
  ReviewMetricQuestionCriterionPkey = "review_metric_question_criterion_pkey",
}

/** input type for incrementing numeric columns in table "review_metric_question_criterion" */
export type Review_Metric_Question_Criterion_Inc_Input = {
  criterion_id?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["Int"]>;
  review_metric_question_id?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "review_metric_question_criterion" */
export type Review_Metric_Question_Criterion_Insert_Input = {
  criterion_id?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["Int"]>;
  review_metric_criterion?: InputMaybe<Interview_Criterion_Obj_Rel_Insert_Input>;
  review_metric_question?: InputMaybe<Review_Metric_Question_Obj_Rel_Insert_Input>;
  review_metric_question_id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Review_Metric_Question_Criterion_Max_Fields = {
  __typename?: "review_metric_question_criterion_max_fields";
  criterion_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  review_metric_question_id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "review_metric_question_criterion" */
export type Review_Metric_Question_Criterion_Max_Order_By = {
  criterion_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Review_Metric_Question_Criterion_Min_Fields = {
  __typename?: "review_metric_question_criterion_min_fields";
  criterion_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  review_metric_question_id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "review_metric_question_criterion" */
export type Review_Metric_Question_Criterion_Min_Order_By = {
  criterion_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "review_metric_question_criterion" */
export type Review_Metric_Question_Criterion_Mutation_Response = {
  __typename?: "review_metric_question_criterion_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Review_Metric_Question_Criterion>;
};

/** on_conflict condition type for table "review_metric_question_criterion" */
export type Review_Metric_Question_Criterion_On_Conflict = {
  constraint: Review_Metric_Question_Criterion_Constraint;
  update_columns?: Array<Review_Metric_Question_Criterion_Update_Column>;
  where?: InputMaybe<Review_Metric_Question_Criterion_Bool_Exp>;
};

/** Ordering options when selecting data from "review_metric_question_criterion". */
export type Review_Metric_Question_Criterion_Order_By = {
  criterion_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  review_metric_criterion?: InputMaybe<Interview_Criterion_Order_By>;
  review_metric_question?: InputMaybe<Review_Metric_Question_Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: review_metric_question_criterion */
export type Review_Metric_Question_Criterion_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "review_metric_question_criterion" */
export enum Review_Metric_Question_Criterion_Select_Column {
  /** column name */
  CriterionId = "criterion_id",
  /** column name */
  Id = "id",
  /** column name */
  ReviewMetricQuestionId = "review_metric_question_id",
}

/** input type for updating data in table "review_metric_question_criterion" */
export type Review_Metric_Question_Criterion_Set_Input = {
  criterion_id?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["Int"]>;
  review_metric_question_id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Review_Metric_Question_Criterion_Stddev_Fields = {
  __typename?: "review_metric_question_criterion_stddev_fields";
  criterion_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  review_metric_question_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "review_metric_question_criterion" */
export type Review_Metric_Question_Criterion_Stddev_Order_By = {
  criterion_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Review_Metric_Question_Criterion_Stddev_Pop_Fields = {
  __typename?: "review_metric_question_criterion_stddev_pop_fields";
  criterion_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  review_metric_question_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "review_metric_question_criterion" */
export type Review_Metric_Question_Criterion_Stddev_Pop_Order_By = {
  criterion_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Review_Metric_Question_Criterion_Stddev_Samp_Fields = {
  __typename?: "review_metric_question_criterion_stddev_samp_fields";
  criterion_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  review_metric_question_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "review_metric_question_criterion" */
export type Review_Metric_Question_Criterion_Stddev_Samp_Order_By = {
  criterion_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "review_metric_question_criterion" */
export type Review_Metric_Question_Criterion_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Review_Metric_Question_Criterion_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Review_Metric_Question_Criterion_Stream_Cursor_Value_Input = {
  criterion_id?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["Int"]>;
  review_metric_question_id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Review_Metric_Question_Criterion_Sum_Fields = {
  __typename?: "review_metric_question_criterion_sum_fields";
  criterion_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  review_metric_question_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "review_metric_question_criterion" */
export type Review_Metric_Question_Criterion_Sum_Order_By = {
  criterion_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
};

/** update columns of table "review_metric_question_criterion" */
export enum Review_Metric_Question_Criterion_Update_Column {
  /** column name */
  CriterionId = "criterion_id",
  /** column name */
  Id = "id",
  /** column name */
  ReviewMetricQuestionId = "review_metric_question_id",
}

export type Review_Metric_Question_Criterion_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Review_Metric_Question_Criterion_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Review_Metric_Question_Criterion_Set_Input>;
  /** filter the rows which have to be updated */
  where: Review_Metric_Question_Criterion_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Review_Metric_Question_Criterion_Var_Pop_Fields = {
  __typename?: "review_metric_question_criterion_var_pop_fields";
  criterion_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  review_metric_question_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "review_metric_question_criterion" */
export type Review_Metric_Question_Criterion_Var_Pop_Order_By = {
  criterion_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Review_Metric_Question_Criterion_Var_Samp_Fields = {
  __typename?: "review_metric_question_criterion_var_samp_fields";
  criterion_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  review_metric_question_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "review_metric_question_criterion" */
export type Review_Metric_Question_Criterion_Var_Samp_Order_By = {
  criterion_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Review_Metric_Question_Criterion_Variance_Fields = {
  __typename?: "review_metric_question_criterion_variance_fields";
  criterion_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  review_metric_question_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "review_metric_question_criterion" */
export type Review_Metric_Question_Criterion_Variance_Order_By = {
  criterion_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "review_metric_question" */
export type Review_Metric_Question_Inc_Input = {
  id?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Scalars["Int"]>;
  review_metric_id?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "review_metric_question" */
export type Review_Metric_Question_Insert_Input = {
  company_id?: InputMaybe<Scalars["uuid"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  interview_template_review_metric_questions?: InputMaybe<Interview_Template_Review_Metric_Question_Arr_Rel_Insert_Input>;
  is_shown_to_candidate?: InputMaybe<Scalars["Boolean"]>;
  judge?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
  question?: InputMaybe<Scalars["String"]>;
  question_type?: InputMaybe<Review_Metric_Question_Type_Enum>;
  review_metric?: InputMaybe<Review_Metric_Obj_Rel_Insert_Input>;
  review_metric_id?: InputMaybe<Scalars["Int"]>;
  review_metric_question_criteria?: InputMaybe<Review_Metric_Question_Criterion_Arr_Rel_Insert_Input>;
  review_metric_question_responses?: InputMaybe<Review_Metric_Question_Response_Arr_Rel_Insert_Input>;
  reviewer_description?: InputMaybe<Scalars["String"]>;
  tip?: InputMaybe<Scalars["String"]>;
  video_interview_questions?: InputMaybe<Video_Interview_Question_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Review_Metric_Question_Max_Fields = {
  __typename?: "review_metric_question_max_fields";
  company_id?: Maybe<Scalars["uuid"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  judge?: Maybe<Scalars["String"]>;
  order?: Maybe<Scalars["Int"]>;
  question?: Maybe<Scalars["String"]>;
  review_metric_id?: Maybe<Scalars["Int"]>;
  reviewer_description?: Maybe<Scalars["String"]>;
  tip?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "review_metric_question" */
export type Review_Metric_Question_Max_Order_By = {
  company_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  judge?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
  review_metric_id?: InputMaybe<Order_By>;
  reviewer_description?: InputMaybe<Order_By>;
  tip?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Review_Metric_Question_Min_Fields = {
  __typename?: "review_metric_question_min_fields";
  company_id?: Maybe<Scalars["uuid"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  judge?: Maybe<Scalars["String"]>;
  order?: Maybe<Scalars["Int"]>;
  question?: Maybe<Scalars["String"]>;
  review_metric_id?: Maybe<Scalars["Int"]>;
  reviewer_description?: Maybe<Scalars["String"]>;
  tip?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "review_metric_question" */
export type Review_Metric_Question_Min_Order_By = {
  company_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  judge?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
  review_metric_id?: InputMaybe<Order_By>;
  reviewer_description?: InputMaybe<Order_By>;
  tip?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "review_metric_question" */
export type Review_Metric_Question_Mutation_Response = {
  __typename?: "review_metric_question_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Review_Metric_Question>;
};

/** input type for inserting object relation for remote table "review_metric_question" */
export type Review_Metric_Question_Obj_Rel_Insert_Input = {
  data: Review_Metric_Question_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Review_Metric_Question_On_Conflict>;
};

/** on_conflict condition type for table "review_metric_question" */
export type Review_Metric_Question_On_Conflict = {
  constraint: Review_Metric_Question_Constraint;
  update_columns?: Array<Review_Metric_Question_Update_Column>;
  where?: InputMaybe<Review_Metric_Question_Bool_Exp>;
};

/** Ordering options when selecting data from "review_metric_question". */
export type Review_Metric_Question_Order_By = {
  company_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interview_template_review_metric_questions_aggregate?: InputMaybe<Interview_Template_Review_Metric_Question_Aggregate_Order_By>;
  is_shown_to_candidate?: InputMaybe<Order_By>;
  judge?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
  question_type?: InputMaybe<Order_By>;
  review_metric?: InputMaybe<Review_Metric_Order_By>;
  review_metric_id?: InputMaybe<Order_By>;
  review_metric_question_criteria_aggregate?: InputMaybe<Review_Metric_Question_Criterion_Aggregate_Order_By>;
  review_metric_question_responses_aggregate?: InputMaybe<Review_Metric_Question_Response_Aggregate_Order_By>;
  reviewer_description?: InputMaybe<Order_By>;
  tip?: InputMaybe<Order_By>;
  video_interview_questions_aggregate?: InputMaybe<Video_Interview_Question_Aggregate_Order_By>;
};

/** primary key columns input for table: review_metric_question */
export type Review_Metric_Question_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** columns and relationships of "review_metric_question_response" */
export type Review_Metric_Question_Response = {
  __typename?: "review_metric_question_response";
  description: Scalars["String"];
  id: Scalars["Int"];
  /** An object relationship */
  review_metric_question: Review_Metric_Question;
  review_metric_question_id: Scalars["Int"];
  score: Scalars["Int"];
};

/** aggregated selection of "review_metric_question_response" */
export type Review_Metric_Question_Response_Aggregate = {
  __typename?: "review_metric_question_response_aggregate";
  aggregate?: Maybe<Review_Metric_Question_Response_Aggregate_Fields>;
  nodes: Array<Review_Metric_Question_Response>;
};

export type Review_Metric_Question_Response_Aggregate_Bool_Exp = {
  count?: InputMaybe<Review_Metric_Question_Response_Aggregate_Bool_Exp_Count>;
};

export type Review_Metric_Question_Response_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Review_Metric_Question_Response_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Review_Metric_Question_Response_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "review_metric_question_response" */
export type Review_Metric_Question_Response_Aggregate_Fields = {
  __typename?: "review_metric_question_response_aggregate_fields";
  avg?: Maybe<Review_Metric_Question_Response_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Review_Metric_Question_Response_Max_Fields>;
  min?: Maybe<Review_Metric_Question_Response_Min_Fields>;
  stddev?: Maybe<Review_Metric_Question_Response_Stddev_Fields>;
  stddev_pop?: Maybe<Review_Metric_Question_Response_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Review_Metric_Question_Response_Stddev_Samp_Fields>;
  sum?: Maybe<Review_Metric_Question_Response_Sum_Fields>;
  var_pop?: Maybe<Review_Metric_Question_Response_Var_Pop_Fields>;
  var_samp?: Maybe<Review_Metric_Question_Response_Var_Samp_Fields>;
  variance?: Maybe<Review_Metric_Question_Response_Variance_Fields>;
};

/** aggregate fields of "review_metric_question_response" */
export type Review_Metric_Question_Response_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Review_Metric_Question_Response_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "review_metric_question_response" */
export type Review_Metric_Question_Response_Aggregate_Order_By = {
  avg?: InputMaybe<Review_Metric_Question_Response_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Review_Metric_Question_Response_Max_Order_By>;
  min?: InputMaybe<Review_Metric_Question_Response_Min_Order_By>;
  stddev?: InputMaybe<Review_Metric_Question_Response_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Review_Metric_Question_Response_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Review_Metric_Question_Response_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Review_Metric_Question_Response_Sum_Order_By>;
  var_pop?: InputMaybe<Review_Metric_Question_Response_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Review_Metric_Question_Response_Var_Samp_Order_By>;
  variance?: InputMaybe<Review_Metric_Question_Response_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "review_metric_question_response" */
export type Review_Metric_Question_Response_Arr_Rel_Insert_Input = {
  data: Array<Review_Metric_Question_Response_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Review_Metric_Question_Response_On_Conflict>;
};

/** aggregate avg on columns */
export type Review_Metric_Question_Response_Avg_Fields = {
  __typename?: "review_metric_question_response_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  review_metric_question_id?: Maybe<Scalars["Float"]>;
  score?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "review_metric_question_response" */
export type Review_Metric_Question_Response_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "review_metric_question_response". All fields are combined with a logical 'AND'. */
export type Review_Metric_Question_Response_Bool_Exp = {
  _and?: InputMaybe<Array<Review_Metric_Question_Response_Bool_Exp>>;
  _not?: InputMaybe<Review_Metric_Question_Response_Bool_Exp>;
  _or?: InputMaybe<Array<Review_Metric_Question_Response_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  review_metric_question?: InputMaybe<Review_Metric_Question_Bool_Exp>;
  review_metric_question_id?: InputMaybe<Int_Comparison_Exp>;
  score?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "review_metric_question_response" */
export enum Review_Metric_Question_Response_Constraint {
  /** unique or primary key constraint on columns "id" */
  ReviewMetricQuestionResponsePkey = "review_metric_question_response_pkey",
}

/** input type for incrementing numeric columns in table "review_metric_question_response" */
export type Review_Metric_Question_Response_Inc_Input = {
  id?: InputMaybe<Scalars["Int"]>;
  review_metric_question_id?: InputMaybe<Scalars["Int"]>;
  score?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "review_metric_question_response" */
export type Review_Metric_Question_Response_Insert_Input = {
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  review_metric_question?: InputMaybe<Review_Metric_Question_Obj_Rel_Insert_Input>;
  review_metric_question_id?: InputMaybe<Scalars["Int"]>;
  score?: InputMaybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Review_Metric_Question_Response_Max_Fields = {
  __typename?: "review_metric_question_response_max_fields";
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  review_metric_question_id?: Maybe<Scalars["Int"]>;
  score?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "review_metric_question_response" */
export type Review_Metric_Question_Response_Max_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Review_Metric_Question_Response_Min_Fields = {
  __typename?: "review_metric_question_response_min_fields";
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  review_metric_question_id?: Maybe<Scalars["Int"]>;
  score?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "review_metric_question_response" */
export type Review_Metric_Question_Response_Min_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "review_metric_question_response" */
export type Review_Metric_Question_Response_Mutation_Response = {
  __typename?: "review_metric_question_response_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Review_Metric_Question_Response>;
};

/** input type for inserting object relation for remote table "review_metric_question_response" */
export type Review_Metric_Question_Response_Obj_Rel_Insert_Input = {
  data: Review_Metric_Question_Response_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Review_Metric_Question_Response_On_Conflict>;
};

/** on_conflict condition type for table "review_metric_question_response" */
export type Review_Metric_Question_Response_On_Conflict = {
  constraint: Review_Metric_Question_Response_Constraint;
  update_columns?: Array<Review_Metric_Question_Response_Update_Column>;
  where?: InputMaybe<Review_Metric_Question_Response_Bool_Exp>;
};

/** Ordering options when selecting data from "review_metric_question_response". */
export type Review_Metric_Question_Response_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  review_metric_question?: InputMaybe<Review_Metric_Question_Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** primary key columns input for table: review_metric_question_response */
export type Review_Metric_Question_Response_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "review_metric_question_response" */
export enum Review_Metric_Question_Response_Select_Column {
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  ReviewMetricQuestionId = "review_metric_question_id",
  /** column name */
  Score = "score",
}

/** input type for updating data in table "review_metric_question_response" */
export type Review_Metric_Question_Response_Set_Input = {
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  review_metric_question_id?: InputMaybe<Scalars["Int"]>;
  score?: InputMaybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Review_Metric_Question_Response_Stddev_Fields = {
  __typename?: "review_metric_question_response_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  review_metric_question_id?: Maybe<Scalars["Float"]>;
  score?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "review_metric_question_response" */
export type Review_Metric_Question_Response_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Review_Metric_Question_Response_Stddev_Pop_Fields = {
  __typename?: "review_metric_question_response_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  review_metric_question_id?: Maybe<Scalars["Float"]>;
  score?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "review_metric_question_response" */
export type Review_Metric_Question_Response_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Review_Metric_Question_Response_Stddev_Samp_Fields = {
  __typename?: "review_metric_question_response_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  review_metric_question_id?: Maybe<Scalars["Float"]>;
  score?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "review_metric_question_response" */
export type Review_Metric_Question_Response_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "review_metric_question_response" */
export type Review_Metric_Question_Response_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Review_Metric_Question_Response_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Review_Metric_Question_Response_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  review_metric_question_id?: InputMaybe<Scalars["Int"]>;
  score?: InputMaybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Review_Metric_Question_Response_Sum_Fields = {
  __typename?: "review_metric_question_response_sum_fields";
  id?: Maybe<Scalars["Int"]>;
  review_metric_question_id?: Maybe<Scalars["Int"]>;
  score?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "review_metric_question_response" */
export type Review_Metric_Question_Response_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** update columns of table "review_metric_question_response" */
export enum Review_Metric_Question_Response_Update_Column {
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  ReviewMetricQuestionId = "review_metric_question_id",
  /** column name */
  Score = "score",
}

export type Review_Metric_Question_Response_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Review_Metric_Question_Response_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Review_Metric_Question_Response_Set_Input>;
  /** filter the rows which have to be updated */
  where: Review_Metric_Question_Response_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Review_Metric_Question_Response_Var_Pop_Fields = {
  __typename?: "review_metric_question_response_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  review_metric_question_id?: Maybe<Scalars["Float"]>;
  score?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "review_metric_question_response" */
export type Review_Metric_Question_Response_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Review_Metric_Question_Response_Var_Samp_Fields = {
  __typename?: "review_metric_question_response_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  review_metric_question_id?: Maybe<Scalars["Float"]>;
  score?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "review_metric_question_response" */
export type Review_Metric_Question_Response_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Review_Metric_Question_Response_Variance_Fields = {
  __typename?: "review_metric_question_response_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  review_metric_question_id?: Maybe<Scalars["Float"]>;
  score?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "review_metric_question_response" */
export type Review_Metric_Question_Response_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** select columns of table "review_metric_question" */
export enum Review_Metric_Question_Select_Column {
  /** column name */
  CompanyId = "company_id",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  IsShownToCandidate = "is_shown_to_candidate",
  /** column name */
  Judge = "judge",
  /** column name */
  Order = "order",
  /** column name */
  Question = "question",
  /** column name */
  QuestionType = "question_type",
  /** column name */
  ReviewMetricId = "review_metric_id",
  /** column name */
  ReviewerDescription = "reviewer_description",
  /** column name */
  Tip = "tip",
}

/** select "review_metric_question_aggregate_bool_exp_bool_and_arguments_columns" columns of table "review_metric_question" */
export enum Review_Metric_Question_Select_Column_Review_Metric_Question_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsShownToCandidate = "is_shown_to_candidate",
}

/** select "review_metric_question_aggregate_bool_exp_bool_or_arguments_columns" columns of table "review_metric_question" */
export enum Review_Metric_Question_Select_Column_Review_Metric_Question_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsShownToCandidate = "is_shown_to_candidate",
}

/** input type for updating data in table "review_metric_question" */
export type Review_Metric_Question_Set_Input = {
  company_id?: InputMaybe<Scalars["uuid"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  is_shown_to_candidate?: InputMaybe<Scalars["Boolean"]>;
  judge?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
  question?: InputMaybe<Scalars["String"]>;
  question_type?: InputMaybe<Review_Metric_Question_Type_Enum>;
  review_metric_id?: InputMaybe<Scalars["Int"]>;
  reviewer_description?: InputMaybe<Scalars["String"]>;
  tip?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Review_Metric_Question_Stddev_Fields = {
  __typename?: "review_metric_question_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  order?: Maybe<Scalars["Float"]>;
  review_metric_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "review_metric_question" */
export type Review_Metric_Question_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  review_metric_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Review_Metric_Question_Stddev_Pop_Fields = {
  __typename?: "review_metric_question_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  order?: Maybe<Scalars["Float"]>;
  review_metric_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "review_metric_question" */
export type Review_Metric_Question_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  review_metric_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Review_Metric_Question_Stddev_Samp_Fields = {
  __typename?: "review_metric_question_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  order?: Maybe<Scalars["Float"]>;
  review_metric_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "review_metric_question" */
export type Review_Metric_Question_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  review_metric_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "review_metric_question" */
export type Review_Metric_Question_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Review_Metric_Question_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Review_Metric_Question_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars["uuid"]>;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  is_shown_to_candidate?: InputMaybe<Scalars["Boolean"]>;
  judge?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
  question?: InputMaybe<Scalars["String"]>;
  question_type?: InputMaybe<Review_Metric_Question_Type_Enum>;
  review_metric_id?: InputMaybe<Scalars["Int"]>;
  reviewer_description?: InputMaybe<Scalars["String"]>;
  tip?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Review_Metric_Question_Sum_Fields = {
  __typename?: "review_metric_question_sum_fields";
  id?: Maybe<Scalars["Int"]>;
  order?: Maybe<Scalars["Int"]>;
  review_metric_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "review_metric_question" */
export type Review_Metric_Question_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  review_metric_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "review_metric_question_type" */
export type Review_Metric_Question_Type = {
  __typename?: "review_metric_question_type";
  question_type: Scalars["String"];
};

/** aggregated selection of "review_metric_question_type" */
export type Review_Metric_Question_Type_Aggregate = {
  __typename?: "review_metric_question_type_aggregate";
  aggregate?: Maybe<Review_Metric_Question_Type_Aggregate_Fields>;
  nodes: Array<Review_Metric_Question_Type>;
};

/** aggregate fields of "review_metric_question_type" */
export type Review_Metric_Question_Type_Aggregate_Fields = {
  __typename?: "review_metric_question_type_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Review_Metric_Question_Type_Max_Fields>;
  min?: Maybe<Review_Metric_Question_Type_Min_Fields>;
};

/** aggregate fields of "review_metric_question_type" */
export type Review_Metric_Question_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Review_Metric_Question_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "review_metric_question_type". All fields are combined with a logical 'AND'. */
export type Review_Metric_Question_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Review_Metric_Question_Type_Bool_Exp>>;
  _not?: InputMaybe<Review_Metric_Question_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Review_Metric_Question_Type_Bool_Exp>>;
  question_type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "review_metric_question_type" */
export enum Review_Metric_Question_Type_Constraint {
  /** unique or primary key constraint on columns "question_type" */
  ReviewMetricQuestionTypePkey = "review_metric_question_type_pkey",
}

export enum Review_Metric_Question_Type_Enum {
  EnglishTest = "ENGLISH_TEST",
  LanguageAssessment = "LANGUAGE_ASSESSMENT",
  QaAssessment = "QA_ASSESSMENT",
}

/** Boolean expression to compare columns of type "review_metric_question_type_enum". All fields are combined with logical 'AND'. */
export type Review_Metric_Question_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Review_Metric_Question_Type_Enum>;
  _in?: InputMaybe<Array<Review_Metric_Question_Type_Enum>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _neq?: InputMaybe<Review_Metric_Question_Type_Enum>;
  _nin?: InputMaybe<Array<Review_Metric_Question_Type_Enum>>;
};

/** input type for inserting data into table "review_metric_question_type" */
export type Review_Metric_Question_Type_Insert_Input = {
  question_type?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Review_Metric_Question_Type_Max_Fields = {
  __typename?: "review_metric_question_type_max_fields";
  question_type?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Review_Metric_Question_Type_Min_Fields = {
  __typename?: "review_metric_question_type_min_fields";
  question_type?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "review_metric_question_type" */
export type Review_Metric_Question_Type_Mutation_Response = {
  __typename?: "review_metric_question_type_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Review_Metric_Question_Type>;
};

/** on_conflict condition type for table "review_metric_question_type" */
export type Review_Metric_Question_Type_On_Conflict = {
  constraint: Review_Metric_Question_Type_Constraint;
  update_columns?: Array<Review_Metric_Question_Type_Update_Column>;
  where?: InputMaybe<Review_Metric_Question_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "review_metric_question_type". */
export type Review_Metric_Question_Type_Order_By = {
  question_type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: review_metric_question_type */
export type Review_Metric_Question_Type_Pk_Columns_Input = {
  question_type: Scalars["String"];
};

/** select columns of table "review_metric_question_type" */
export enum Review_Metric_Question_Type_Select_Column {
  /** column name */
  QuestionType = "question_type",
}

/** input type for updating data in table "review_metric_question_type" */
export type Review_Metric_Question_Type_Set_Input = {
  question_type?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "review_metric_question_type" */
export type Review_Metric_Question_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Review_Metric_Question_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Review_Metric_Question_Type_Stream_Cursor_Value_Input = {
  question_type?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "review_metric_question_type" */
export enum Review_Metric_Question_Type_Update_Column {
  /** column name */
  QuestionType = "question_type",
}

export type Review_Metric_Question_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Review_Metric_Question_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Review_Metric_Question_Type_Bool_Exp;
};

/** update columns of table "review_metric_question" */
export enum Review_Metric_Question_Update_Column {
  /** column name */
  CompanyId = "company_id",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  IsShownToCandidate = "is_shown_to_candidate",
  /** column name */
  Judge = "judge",
  /** column name */
  Order = "order",
  /** column name */
  Question = "question",
  /** column name */
  QuestionType = "question_type",
  /** column name */
  ReviewMetricId = "review_metric_id",
  /** column name */
  ReviewerDescription = "reviewer_description",
  /** column name */
  Tip = "tip",
}

export type Review_Metric_Question_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Review_Metric_Question_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Review_Metric_Question_Set_Input>;
  /** filter the rows which have to be updated */
  where: Review_Metric_Question_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Review_Metric_Question_Var_Pop_Fields = {
  __typename?: "review_metric_question_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  order?: Maybe<Scalars["Float"]>;
  review_metric_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "review_metric_question" */
export type Review_Metric_Question_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  review_metric_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Review_Metric_Question_Var_Samp_Fields = {
  __typename?: "review_metric_question_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  order?: Maybe<Scalars["Float"]>;
  review_metric_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "review_metric_question" */
export type Review_Metric_Question_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  review_metric_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Review_Metric_Question_Variance_Fields = {
  __typename?: "review_metric_question_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  order?: Maybe<Scalars["Float"]>;
  review_metric_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "review_metric_question" */
export type Review_Metric_Question_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  review_metric_id?: InputMaybe<Order_By>;
};

/** select columns of table "review_metric" */
export enum Review_Metric_Select_Column {
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  Language = "language",
  /** column name */
  Order = "order",
}

/** input type for updating data in table "review_metric" */
export type Review_Metric_Set_Input = {
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  language?: InputMaybe<Language_Enum>;
  order?: InputMaybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Review_Metric_Stddev_Fields = {
  __typename?: "review_metric_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  order?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Review_Metric_Stddev_Pop_Fields = {
  __typename?: "review_metric_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  order?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Review_Metric_Stddev_Samp_Fields = {
  __typename?: "review_metric_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  order?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "review_metric" */
export type Review_Metric_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Review_Metric_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Review_Metric_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  language?: InputMaybe<Language_Enum>;
  order?: InputMaybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Review_Metric_Sum_Fields = {
  __typename?: "review_metric_sum_fields";
  id?: Maybe<Scalars["Int"]>;
  order?: Maybe<Scalars["Int"]>;
};

/** update columns of table "review_metric" */
export enum Review_Metric_Update_Column {
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  Language = "language",
  /** column name */
  Order = "order",
}

export type Review_Metric_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Review_Metric_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Review_Metric_Set_Input>;
  /** filter the rows which have to be updated */
  where: Review_Metric_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Review_Metric_Var_Pop_Fields = {
  __typename?: "review_metric_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  order?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Review_Metric_Var_Samp_Fields = {
  __typename?: "review_metric_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  order?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Review_Metric_Variance_Fields = {
  __typename?: "review_metric_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  order?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "role" */
export type Role = {
  __typename?: "role";
  /** An object relationship */
  department?: Maybe<Department>;
  department_id?: Maybe<Scalars["Int"]>;
  experience_level?: Maybe<Scalars["String"]>;
  id: Scalars["uuid"];
  /** An array relationship */
  job_descriptions: Array<Job_Description>;
  /** An aggregate relationship */
  job_descriptions_aggregate: Job_Description_Aggregate;
  position?: Maybe<Scalars["String"]>;
  /** An array relationship */
  role_academic_departments: Array<Role_Academic_Department>;
  /** An aggregate relationship */
  role_academic_departments_aggregate: Role_Academic_Department_Aggregate;
  /** An array relationship */
  role_benefits: Array<Role_Benefit>;
  /** An aggregate relationship */
  role_benefits_aggregate: Role_Benefit_Aggregate;
  /** An array relationship */
  role_competences: Array<Role_Competence>;
  /** An aggregate relationship */
  role_competences_aggregate: Role_Competence_Aggregate;
  /** An array relationship */
  role_descriptions: Array<Role_Description>;
  /** An aggregate relationship */
  role_descriptions_aggregate: Role_Description_Aggregate;
  /** An array relationship */
  role_responsibilities: Array<Role_Responsibility>;
  /** An aggregate relationship */
  role_responsibilities_aggregate: Role_Responsibility_Aggregate;
  /** An array relationship */
  role_skills: Array<Role_Skill>;
  /** An aggregate relationship */
  role_skills_aggregate: Role_Skill_Aggregate;
  title: Scalars["String"];
};

/** columns and relationships of "role" */
export type RoleJob_DescriptionsArgs = {
  distinct_on?: InputMaybe<Array<Job_Description_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Job_Description_Order_By>>;
  where?: InputMaybe<Job_Description_Bool_Exp>;
};

/** columns and relationships of "role" */
export type RoleJob_Descriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Description_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Job_Description_Order_By>>;
  where?: InputMaybe<Job_Description_Bool_Exp>;
};

/** columns and relationships of "role" */
export type RoleRole_Academic_DepartmentsArgs = {
  distinct_on?: InputMaybe<Array<Role_Academic_Department_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Academic_Department_Order_By>>;
  where?: InputMaybe<Role_Academic_Department_Bool_Exp>;
};

/** columns and relationships of "role" */
export type RoleRole_Academic_Departments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Academic_Department_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Academic_Department_Order_By>>;
  where?: InputMaybe<Role_Academic_Department_Bool_Exp>;
};

/** columns and relationships of "role" */
export type RoleRole_BenefitsArgs = {
  distinct_on?: InputMaybe<Array<Role_Benefit_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Benefit_Order_By>>;
  where?: InputMaybe<Role_Benefit_Bool_Exp>;
};

/** columns and relationships of "role" */
export type RoleRole_Benefits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Benefit_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Benefit_Order_By>>;
  where?: InputMaybe<Role_Benefit_Bool_Exp>;
};

/** columns and relationships of "role" */
export type RoleRole_CompetencesArgs = {
  distinct_on?: InputMaybe<Array<Role_Competence_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Competence_Order_By>>;
  where?: InputMaybe<Role_Competence_Bool_Exp>;
};

/** columns and relationships of "role" */
export type RoleRole_Competences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Competence_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Competence_Order_By>>;
  where?: InputMaybe<Role_Competence_Bool_Exp>;
};

/** columns and relationships of "role" */
export type RoleRole_DescriptionsArgs = {
  distinct_on?: InputMaybe<Array<Role_Description_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Description_Order_By>>;
  where?: InputMaybe<Role_Description_Bool_Exp>;
};

/** columns and relationships of "role" */
export type RoleRole_Descriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Description_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Description_Order_By>>;
  where?: InputMaybe<Role_Description_Bool_Exp>;
};

/** columns and relationships of "role" */
export type RoleRole_ResponsibilitiesArgs = {
  distinct_on?: InputMaybe<Array<Role_Responsibility_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Responsibility_Order_By>>;
  where?: InputMaybe<Role_Responsibility_Bool_Exp>;
};

/** columns and relationships of "role" */
export type RoleRole_Responsibilities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Responsibility_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Responsibility_Order_By>>;
  where?: InputMaybe<Role_Responsibility_Bool_Exp>;
};

/** columns and relationships of "role" */
export type RoleRole_SkillsArgs = {
  distinct_on?: InputMaybe<Array<Role_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Skill_Order_By>>;
  where?: InputMaybe<Role_Skill_Bool_Exp>;
};

/** columns and relationships of "role" */
export type RoleRole_Skills_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Skill_Order_By>>;
  where?: InputMaybe<Role_Skill_Bool_Exp>;
};

/** columns and relationships of "role_academic_department" */
export type Role_Academic_Department = {
  __typename?: "role_academic_department";
  /** An object relationship */
  academic_department: Academic_Department;
  department_id: Scalars["Int"];
  id: Scalars["Int"];
  /** An object relationship */
  role: Role;
  role_id: Scalars["uuid"];
};

/** aggregated selection of "role_academic_department" */
export type Role_Academic_Department_Aggregate = {
  __typename?: "role_academic_department_aggregate";
  aggregate?: Maybe<Role_Academic_Department_Aggregate_Fields>;
  nodes: Array<Role_Academic_Department>;
};

export type Role_Academic_Department_Aggregate_Bool_Exp = {
  count?: InputMaybe<Role_Academic_Department_Aggregate_Bool_Exp_Count>;
};

export type Role_Academic_Department_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Role_Academic_Department_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Role_Academic_Department_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "role_academic_department" */
export type Role_Academic_Department_Aggregate_Fields = {
  __typename?: "role_academic_department_aggregate_fields";
  avg?: Maybe<Role_Academic_Department_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Role_Academic_Department_Max_Fields>;
  min?: Maybe<Role_Academic_Department_Min_Fields>;
  stddev?: Maybe<Role_Academic_Department_Stddev_Fields>;
  stddev_pop?: Maybe<Role_Academic_Department_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Role_Academic_Department_Stddev_Samp_Fields>;
  sum?: Maybe<Role_Academic_Department_Sum_Fields>;
  var_pop?: Maybe<Role_Academic_Department_Var_Pop_Fields>;
  var_samp?: Maybe<Role_Academic_Department_Var_Samp_Fields>;
  variance?: Maybe<Role_Academic_Department_Variance_Fields>;
};

/** aggregate fields of "role_academic_department" */
export type Role_Academic_Department_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Role_Academic_Department_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "role_academic_department" */
export type Role_Academic_Department_Aggregate_Order_By = {
  avg?: InputMaybe<Role_Academic_Department_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Role_Academic_Department_Max_Order_By>;
  min?: InputMaybe<Role_Academic_Department_Min_Order_By>;
  stddev?: InputMaybe<Role_Academic_Department_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Role_Academic_Department_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Role_Academic_Department_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Role_Academic_Department_Sum_Order_By>;
  var_pop?: InputMaybe<Role_Academic_Department_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Role_Academic_Department_Var_Samp_Order_By>;
  variance?: InputMaybe<Role_Academic_Department_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "role_academic_department" */
export type Role_Academic_Department_Arr_Rel_Insert_Input = {
  data: Array<Role_Academic_Department_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Role_Academic_Department_On_Conflict>;
};

/** aggregate avg on columns */
export type Role_Academic_Department_Avg_Fields = {
  __typename?: "role_academic_department_avg_fields";
  department_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "role_academic_department" */
export type Role_Academic_Department_Avg_Order_By = {
  department_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "role_academic_department". All fields are combined with a logical 'AND'. */
export type Role_Academic_Department_Bool_Exp = {
  _and?: InputMaybe<Array<Role_Academic_Department_Bool_Exp>>;
  _not?: InputMaybe<Role_Academic_Department_Bool_Exp>;
  _or?: InputMaybe<Array<Role_Academic_Department_Bool_Exp>>;
  academic_department?: InputMaybe<Academic_Department_Bool_Exp>;
  department_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  role?: InputMaybe<Role_Bool_Exp>;
  role_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "role_academic_department" */
export enum Role_Academic_Department_Constraint {
  /** unique or primary key constraint on columns "id" */
  RoleAcademicDepartmentPkey = "role_academic_department_pkey",
}

/** input type for incrementing numeric columns in table "role_academic_department" */
export type Role_Academic_Department_Inc_Input = {
  department_id?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "role_academic_department" */
export type Role_Academic_Department_Insert_Input = {
  academic_department?: InputMaybe<Academic_Department_Obj_Rel_Insert_Input>;
  department_id?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["Int"]>;
  role?: InputMaybe<Role_Obj_Rel_Insert_Input>;
  role_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Role_Academic_Department_Max_Fields = {
  __typename?: "role_academic_department_max_fields";
  department_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  role_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "role_academic_department" */
export type Role_Academic_Department_Max_Order_By = {
  department_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Role_Academic_Department_Min_Fields = {
  __typename?: "role_academic_department_min_fields";
  department_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  role_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "role_academic_department" */
export type Role_Academic_Department_Min_Order_By = {
  department_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "role_academic_department" */
export type Role_Academic_Department_Mutation_Response = {
  __typename?: "role_academic_department_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Role_Academic_Department>;
};

/** on_conflict condition type for table "role_academic_department" */
export type Role_Academic_Department_On_Conflict = {
  constraint: Role_Academic_Department_Constraint;
  update_columns?: Array<Role_Academic_Department_Update_Column>;
  where?: InputMaybe<Role_Academic_Department_Bool_Exp>;
};

/** Ordering options when selecting data from "role_academic_department". */
export type Role_Academic_Department_Order_By = {
  academic_department?: InputMaybe<Academic_Department_Order_By>;
  department_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role?: InputMaybe<Role_Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: role_academic_department */
export type Role_Academic_Department_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "role_academic_department" */
export enum Role_Academic_Department_Select_Column {
  /** column name */
  DepartmentId = "department_id",
  /** column name */
  Id = "id",
  /** column name */
  RoleId = "role_id",
}

/** input type for updating data in table "role_academic_department" */
export type Role_Academic_Department_Set_Input = {
  department_id?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["Int"]>;
  role_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type Role_Academic_Department_Stddev_Fields = {
  __typename?: "role_academic_department_stddev_fields";
  department_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "role_academic_department" */
export type Role_Academic_Department_Stddev_Order_By = {
  department_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Role_Academic_Department_Stddev_Pop_Fields = {
  __typename?: "role_academic_department_stddev_pop_fields";
  department_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "role_academic_department" */
export type Role_Academic_Department_Stddev_Pop_Order_By = {
  department_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Role_Academic_Department_Stddev_Samp_Fields = {
  __typename?: "role_academic_department_stddev_samp_fields";
  department_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "role_academic_department" */
export type Role_Academic_Department_Stddev_Samp_Order_By = {
  department_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "role_academic_department" */
export type Role_Academic_Department_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Role_Academic_Department_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Role_Academic_Department_Stream_Cursor_Value_Input = {
  department_id?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["Int"]>;
  role_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate sum on columns */
export type Role_Academic_Department_Sum_Fields = {
  __typename?: "role_academic_department_sum_fields";
  department_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "role_academic_department" */
export type Role_Academic_Department_Sum_Order_By = {
  department_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "role_academic_department" */
export enum Role_Academic_Department_Update_Column {
  /** column name */
  DepartmentId = "department_id",
  /** column name */
  Id = "id",
  /** column name */
  RoleId = "role_id",
}

export type Role_Academic_Department_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Role_Academic_Department_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Role_Academic_Department_Set_Input>;
  /** filter the rows which have to be updated */
  where: Role_Academic_Department_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Role_Academic_Department_Var_Pop_Fields = {
  __typename?: "role_academic_department_var_pop_fields";
  department_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "role_academic_department" */
export type Role_Academic_Department_Var_Pop_Order_By = {
  department_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Role_Academic_Department_Var_Samp_Fields = {
  __typename?: "role_academic_department_var_samp_fields";
  department_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "role_academic_department" */
export type Role_Academic_Department_Var_Samp_Order_By = {
  department_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Role_Academic_Department_Variance_Fields = {
  __typename?: "role_academic_department_variance_fields";
  department_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "role_academic_department" */
export type Role_Academic_Department_Variance_Order_By = {
  department_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregated selection of "role" */
export type Role_Aggregate = {
  __typename?: "role_aggregate";
  aggregate?: Maybe<Role_Aggregate_Fields>;
  nodes: Array<Role>;
};

export type Role_Aggregate_Bool_Exp = {
  count?: InputMaybe<Role_Aggregate_Bool_Exp_Count>;
};

export type Role_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Role_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Role_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "role" */
export type Role_Aggregate_Fields = {
  __typename?: "role_aggregate_fields";
  avg?: Maybe<Role_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Role_Max_Fields>;
  min?: Maybe<Role_Min_Fields>;
  stddev?: Maybe<Role_Stddev_Fields>;
  stddev_pop?: Maybe<Role_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Role_Stddev_Samp_Fields>;
  sum?: Maybe<Role_Sum_Fields>;
  var_pop?: Maybe<Role_Var_Pop_Fields>;
  var_samp?: Maybe<Role_Var_Samp_Fields>;
  variance?: Maybe<Role_Variance_Fields>;
};

/** aggregate fields of "role" */
export type Role_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Role_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "role" */
export type Role_Aggregate_Order_By = {
  avg?: InputMaybe<Role_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Role_Max_Order_By>;
  min?: InputMaybe<Role_Min_Order_By>;
  stddev?: InputMaybe<Role_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Role_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Role_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Role_Sum_Order_By>;
  var_pop?: InputMaybe<Role_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Role_Var_Samp_Order_By>;
  variance?: InputMaybe<Role_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "role" */
export type Role_Arr_Rel_Insert_Input = {
  data: Array<Role_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Role_On_Conflict>;
};

/** aggregate avg on columns */
export type Role_Avg_Fields = {
  __typename?: "role_avg_fields";
  department_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "role" */
export type Role_Avg_Order_By = {
  department_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "role_benefit" */
export type Role_Benefit = {
  __typename?: "role_benefit";
  benefit_explanation: Scalars["String"];
  benefit_name?: Maybe<Scalars["String"]>;
  id: Scalars["uuid"];
  /** An object relationship */
  role: Role;
  role_id: Scalars["uuid"];
};

/** aggregated selection of "role_benefit" */
export type Role_Benefit_Aggregate = {
  __typename?: "role_benefit_aggregate";
  aggregate?: Maybe<Role_Benefit_Aggregate_Fields>;
  nodes: Array<Role_Benefit>;
};

export type Role_Benefit_Aggregate_Bool_Exp = {
  count?: InputMaybe<Role_Benefit_Aggregate_Bool_Exp_Count>;
};

export type Role_Benefit_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Role_Benefit_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Role_Benefit_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "role_benefit" */
export type Role_Benefit_Aggregate_Fields = {
  __typename?: "role_benefit_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Role_Benefit_Max_Fields>;
  min?: Maybe<Role_Benefit_Min_Fields>;
};

/** aggregate fields of "role_benefit" */
export type Role_Benefit_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Role_Benefit_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "role_benefit" */
export type Role_Benefit_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Role_Benefit_Max_Order_By>;
  min?: InputMaybe<Role_Benefit_Min_Order_By>;
};

/** input type for inserting array relation for remote table "role_benefit" */
export type Role_Benefit_Arr_Rel_Insert_Input = {
  data: Array<Role_Benefit_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Role_Benefit_On_Conflict>;
};

/** Boolean expression to filter rows from the table "role_benefit". All fields are combined with a logical 'AND'. */
export type Role_Benefit_Bool_Exp = {
  _and?: InputMaybe<Array<Role_Benefit_Bool_Exp>>;
  _not?: InputMaybe<Role_Benefit_Bool_Exp>;
  _or?: InputMaybe<Array<Role_Benefit_Bool_Exp>>;
  benefit_explanation?: InputMaybe<String_Comparison_Exp>;
  benefit_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  role?: InputMaybe<Role_Bool_Exp>;
  role_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "role_benefit" */
export enum Role_Benefit_Constraint {
  /** unique or primary key constraint on columns "id" */
  RoleBenefitPkey = "role_benefit_pkey",
}

/** input type for inserting data into table "role_benefit" */
export type Role_Benefit_Insert_Input = {
  benefit_explanation?: InputMaybe<Scalars["String"]>;
  benefit_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  role?: InputMaybe<Role_Obj_Rel_Insert_Input>;
  role_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Role_Benefit_Max_Fields = {
  __typename?: "role_benefit_max_fields";
  benefit_explanation?: Maybe<Scalars["String"]>;
  benefit_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  role_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "role_benefit" */
export type Role_Benefit_Max_Order_By = {
  benefit_explanation?: InputMaybe<Order_By>;
  benefit_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Role_Benefit_Min_Fields = {
  __typename?: "role_benefit_min_fields";
  benefit_explanation?: Maybe<Scalars["String"]>;
  benefit_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  role_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "role_benefit" */
export type Role_Benefit_Min_Order_By = {
  benefit_explanation?: InputMaybe<Order_By>;
  benefit_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "role_benefit" */
export type Role_Benefit_Mutation_Response = {
  __typename?: "role_benefit_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Role_Benefit>;
};

/** on_conflict condition type for table "role_benefit" */
export type Role_Benefit_On_Conflict = {
  constraint: Role_Benefit_Constraint;
  update_columns?: Array<Role_Benefit_Update_Column>;
  where?: InputMaybe<Role_Benefit_Bool_Exp>;
};

/** Ordering options when selecting data from "role_benefit". */
export type Role_Benefit_Order_By = {
  benefit_explanation?: InputMaybe<Order_By>;
  benefit_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role?: InputMaybe<Role_Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: role_benefit */
export type Role_Benefit_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "role_benefit" */
export enum Role_Benefit_Select_Column {
  /** column name */
  BenefitExplanation = "benefit_explanation",
  /** column name */
  BenefitName = "benefit_name",
  /** column name */
  Id = "id",
  /** column name */
  RoleId = "role_id",
}

/** input type for updating data in table "role_benefit" */
export type Role_Benefit_Set_Input = {
  benefit_explanation?: InputMaybe<Scalars["String"]>;
  benefit_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  role_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "role_benefit" */
export type Role_Benefit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Role_Benefit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Role_Benefit_Stream_Cursor_Value_Input = {
  benefit_explanation?: InputMaybe<Scalars["String"]>;
  benefit_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  role_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "role_benefit" */
export enum Role_Benefit_Update_Column {
  /** column name */
  BenefitExplanation = "benefit_explanation",
  /** column name */
  BenefitName = "benefit_name",
  /** column name */
  Id = "id",
  /** column name */
  RoleId = "role_id",
}

export type Role_Benefit_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Role_Benefit_Set_Input>;
  /** filter the rows which have to be updated */
  where: Role_Benefit_Bool_Exp;
};

/** Boolean expression to filter rows from the table "role". All fields are combined with a logical 'AND'. */
export type Role_Bool_Exp = {
  _and?: InputMaybe<Array<Role_Bool_Exp>>;
  _not?: InputMaybe<Role_Bool_Exp>;
  _or?: InputMaybe<Array<Role_Bool_Exp>>;
  department?: InputMaybe<Department_Bool_Exp>;
  department_id?: InputMaybe<Int_Comparison_Exp>;
  experience_level?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  job_descriptions?: InputMaybe<Job_Description_Bool_Exp>;
  job_descriptions_aggregate?: InputMaybe<Job_Description_Aggregate_Bool_Exp>;
  position?: InputMaybe<String_Comparison_Exp>;
  role_academic_departments?: InputMaybe<Role_Academic_Department_Bool_Exp>;
  role_academic_departments_aggregate?: InputMaybe<Role_Academic_Department_Aggregate_Bool_Exp>;
  role_benefits?: InputMaybe<Role_Benefit_Bool_Exp>;
  role_benefits_aggregate?: InputMaybe<Role_Benefit_Aggregate_Bool_Exp>;
  role_competences?: InputMaybe<Role_Competence_Bool_Exp>;
  role_competences_aggregate?: InputMaybe<Role_Competence_Aggregate_Bool_Exp>;
  role_descriptions?: InputMaybe<Role_Description_Bool_Exp>;
  role_descriptions_aggregate?: InputMaybe<Role_Description_Aggregate_Bool_Exp>;
  role_responsibilities?: InputMaybe<Role_Responsibility_Bool_Exp>;
  role_responsibilities_aggregate?: InputMaybe<Role_Responsibility_Aggregate_Bool_Exp>;
  role_skills?: InputMaybe<Role_Skill_Bool_Exp>;
  role_skills_aggregate?: InputMaybe<Role_Skill_Aggregate_Bool_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** columns and relationships of "role_competence" */
export type Role_Competence = {
  __typename?: "role_competence";
  competence_explanation: Scalars["String"];
  competence_name?: Maybe<Scalars["String"]>;
  id: Scalars["uuid"];
  /** An object relationship */
  role: Role;
  role_id: Scalars["uuid"];
};

/** aggregated selection of "role_competence" */
export type Role_Competence_Aggregate = {
  __typename?: "role_competence_aggregate";
  aggregate?: Maybe<Role_Competence_Aggregate_Fields>;
  nodes: Array<Role_Competence>;
};

export type Role_Competence_Aggregate_Bool_Exp = {
  count?: InputMaybe<Role_Competence_Aggregate_Bool_Exp_Count>;
};

export type Role_Competence_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Role_Competence_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Role_Competence_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "role_competence" */
export type Role_Competence_Aggregate_Fields = {
  __typename?: "role_competence_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Role_Competence_Max_Fields>;
  min?: Maybe<Role_Competence_Min_Fields>;
};

/** aggregate fields of "role_competence" */
export type Role_Competence_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Role_Competence_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "role_competence" */
export type Role_Competence_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Role_Competence_Max_Order_By>;
  min?: InputMaybe<Role_Competence_Min_Order_By>;
};

/** input type for inserting array relation for remote table "role_competence" */
export type Role_Competence_Arr_Rel_Insert_Input = {
  data: Array<Role_Competence_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Role_Competence_On_Conflict>;
};

/** Boolean expression to filter rows from the table "role_competence". All fields are combined with a logical 'AND'. */
export type Role_Competence_Bool_Exp = {
  _and?: InputMaybe<Array<Role_Competence_Bool_Exp>>;
  _not?: InputMaybe<Role_Competence_Bool_Exp>;
  _or?: InputMaybe<Array<Role_Competence_Bool_Exp>>;
  competence_explanation?: InputMaybe<String_Comparison_Exp>;
  competence_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  role?: InputMaybe<Role_Bool_Exp>;
  role_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "role_competence" */
export enum Role_Competence_Constraint {
  /** unique or primary key constraint on columns "id" */
  RoleCompetencePkey = "role_competence_pkey",
}

/** input type for inserting data into table "role_competence" */
export type Role_Competence_Insert_Input = {
  competence_explanation?: InputMaybe<Scalars["String"]>;
  competence_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  role?: InputMaybe<Role_Obj_Rel_Insert_Input>;
  role_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Role_Competence_Max_Fields = {
  __typename?: "role_competence_max_fields";
  competence_explanation?: Maybe<Scalars["String"]>;
  competence_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  role_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "role_competence" */
export type Role_Competence_Max_Order_By = {
  competence_explanation?: InputMaybe<Order_By>;
  competence_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Role_Competence_Min_Fields = {
  __typename?: "role_competence_min_fields";
  competence_explanation?: Maybe<Scalars["String"]>;
  competence_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  role_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "role_competence" */
export type Role_Competence_Min_Order_By = {
  competence_explanation?: InputMaybe<Order_By>;
  competence_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "role_competence" */
export type Role_Competence_Mutation_Response = {
  __typename?: "role_competence_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Role_Competence>;
};

/** on_conflict condition type for table "role_competence" */
export type Role_Competence_On_Conflict = {
  constraint: Role_Competence_Constraint;
  update_columns?: Array<Role_Competence_Update_Column>;
  where?: InputMaybe<Role_Competence_Bool_Exp>;
};

/** Ordering options when selecting data from "role_competence". */
export type Role_Competence_Order_By = {
  competence_explanation?: InputMaybe<Order_By>;
  competence_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role?: InputMaybe<Role_Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: role_competence */
export type Role_Competence_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "role_competence" */
export enum Role_Competence_Select_Column {
  /** column name */
  CompetenceExplanation = "competence_explanation",
  /** column name */
  CompetenceName = "competence_name",
  /** column name */
  Id = "id",
  /** column name */
  RoleId = "role_id",
}

/** input type for updating data in table "role_competence" */
export type Role_Competence_Set_Input = {
  competence_explanation?: InputMaybe<Scalars["String"]>;
  competence_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  role_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "role_competence" */
export type Role_Competence_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Role_Competence_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Role_Competence_Stream_Cursor_Value_Input = {
  competence_explanation?: InputMaybe<Scalars["String"]>;
  competence_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  role_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "role_competence" */
export enum Role_Competence_Update_Column {
  /** column name */
  CompetenceExplanation = "competence_explanation",
  /** column name */
  CompetenceName = "competence_name",
  /** column name */
  Id = "id",
  /** column name */
  RoleId = "role_id",
}

export type Role_Competence_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Role_Competence_Set_Input>;
  /** filter the rows which have to be updated */
  where: Role_Competence_Bool_Exp;
};

/** unique or primary key constraints on table "role" */
export enum Role_Constraint {
  /** unique or primary key constraint on columns "id" */
  RolePkey = "role_pkey",
}

/** columns and relationships of "role_description" */
export type Role_Description = {
  __typename?: "role_description";
  description_explanation?: Maybe<Scalars["String"]>;
  description_name: Scalars["String"];
  id: Scalars["uuid"];
  /** An object relationship */
  role: Role;
  role_id: Scalars["uuid"];
};

/** aggregated selection of "role_description" */
export type Role_Description_Aggregate = {
  __typename?: "role_description_aggregate";
  aggregate?: Maybe<Role_Description_Aggregate_Fields>;
  nodes: Array<Role_Description>;
};

export type Role_Description_Aggregate_Bool_Exp = {
  count?: InputMaybe<Role_Description_Aggregate_Bool_Exp_Count>;
};

export type Role_Description_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Role_Description_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Role_Description_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "role_description" */
export type Role_Description_Aggregate_Fields = {
  __typename?: "role_description_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Role_Description_Max_Fields>;
  min?: Maybe<Role_Description_Min_Fields>;
};

/** aggregate fields of "role_description" */
export type Role_Description_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Role_Description_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "role_description" */
export type Role_Description_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Role_Description_Max_Order_By>;
  min?: InputMaybe<Role_Description_Min_Order_By>;
};

/** input type for inserting array relation for remote table "role_description" */
export type Role_Description_Arr_Rel_Insert_Input = {
  data: Array<Role_Description_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Role_Description_On_Conflict>;
};

/** Boolean expression to filter rows from the table "role_description". All fields are combined with a logical 'AND'. */
export type Role_Description_Bool_Exp = {
  _and?: InputMaybe<Array<Role_Description_Bool_Exp>>;
  _not?: InputMaybe<Role_Description_Bool_Exp>;
  _or?: InputMaybe<Array<Role_Description_Bool_Exp>>;
  description_explanation?: InputMaybe<String_Comparison_Exp>;
  description_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  role?: InputMaybe<Role_Bool_Exp>;
  role_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "role_description" */
export enum Role_Description_Constraint {
  /** unique or primary key constraint on columns "id" */
  RoleDescriptionPkey = "role_description_pkey",
}

/** input type for inserting data into table "role_description" */
export type Role_Description_Insert_Input = {
  description_explanation?: InputMaybe<Scalars["String"]>;
  description_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  role?: InputMaybe<Role_Obj_Rel_Insert_Input>;
  role_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Role_Description_Max_Fields = {
  __typename?: "role_description_max_fields";
  description_explanation?: Maybe<Scalars["String"]>;
  description_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  role_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "role_description" */
export type Role_Description_Max_Order_By = {
  description_explanation?: InputMaybe<Order_By>;
  description_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Role_Description_Min_Fields = {
  __typename?: "role_description_min_fields";
  description_explanation?: Maybe<Scalars["String"]>;
  description_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  role_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "role_description" */
export type Role_Description_Min_Order_By = {
  description_explanation?: InputMaybe<Order_By>;
  description_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "role_description" */
export type Role_Description_Mutation_Response = {
  __typename?: "role_description_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Role_Description>;
};

/** on_conflict condition type for table "role_description" */
export type Role_Description_On_Conflict = {
  constraint: Role_Description_Constraint;
  update_columns?: Array<Role_Description_Update_Column>;
  where?: InputMaybe<Role_Description_Bool_Exp>;
};

/** Ordering options when selecting data from "role_description". */
export type Role_Description_Order_By = {
  description_explanation?: InputMaybe<Order_By>;
  description_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role?: InputMaybe<Role_Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: role_description */
export type Role_Description_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "role_description" */
export enum Role_Description_Select_Column {
  /** column name */
  DescriptionExplanation = "description_explanation",
  /** column name */
  DescriptionName = "description_name",
  /** column name */
  Id = "id",
  /** column name */
  RoleId = "role_id",
}

/** input type for updating data in table "role_description" */
export type Role_Description_Set_Input = {
  description_explanation?: InputMaybe<Scalars["String"]>;
  description_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  role_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "role_description" */
export type Role_Description_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Role_Description_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Role_Description_Stream_Cursor_Value_Input = {
  description_explanation?: InputMaybe<Scalars["String"]>;
  description_name?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  role_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "role_description" */
export enum Role_Description_Update_Column {
  /** column name */
  DescriptionExplanation = "description_explanation",
  /** column name */
  DescriptionName = "description_name",
  /** column name */
  Id = "id",
  /** column name */
  RoleId = "role_id",
}

export type Role_Description_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Role_Description_Set_Input>;
  /** filter the rows which have to be updated */
  where: Role_Description_Bool_Exp;
};

/** input type for incrementing numeric columns in table "role" */
export type Role_Inc_Input = {
  department_id?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "role" */
export type Role_Insert_Input = {
  department?: InputMaybe<Department_Obj_Rel_Insert_Input>;
  department_id?: InputMaybe<Scalars["Int"]>;
  experience_level?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  job_descriptions?: InputMaybe<Job_Description_Arr_Rel_Insert_Input>;
  position?: InputMaybe<Scalars["String"]>;
  role_academic_departments?: InputMaybe<Role_Academic_Department_Arr_Rel_Insert_Input>;
  role_benefits?: InputMaybe<Role_Benefit_Arr_Rel_Insert_Input>;
  role_competences?: InputMaybe<Role_Competence_Arr_Rel_Insert_Input>;
  role_descriptions?: InputMaybe<Role_Description_Arr_Rel_Insert_Input>;
  role_responsibilities?: InputMaybe<Role_Responsibility_Arr_Rel_Insert_Input>;
  role_skills?: InputMaybe<Role_Skill_Arr_Rel_Insert_Input>;
  title?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Role_Max_Fields = {
  __typename?: "role_max_fields";
  department_id?: Maybe<Scalars["Int"]>;
  experience_level?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  position?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "role" */
export type Role_Max_Order_By = {
  department_id?: InputMaybe<Order_By>;
  experience_level?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Role_Min_Fields = {
  __typename?: "role_min_fields";
  department_id?: Maybe<Scalars["Int"]>;
  experience_level?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  position?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "role" */
export type Role_Min_Order_By = {
  department_id?: InputMaybe<Order_By>;
  experience_level?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "role" */
export type Role_Mutation_Response = {
  __typename?: "role_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Role>;
};

/** input type for inserting object relation for remote table "role" */
export type Role_Obj_Rel_Insert_Input = {
  data: Role_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Role_On_Conflict>;
};

/** on_conflict condition type for table "role" */
export type Role_On_Conflict = {
  constraint: Role_Constraint;
  update_columns?: Array<Role_Update_Column>;
  where?: InputMaybe<Role_Bool_Exp>;
};

/** Ordering options when selecting data from "role". */
export type Role_Order_By = {
  department?: InputMaybe<Department_Order_By>;
  department_id?: InputMaybe<Order_By>;
  experience_level?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_descriptions_aggregate?: InputMaybe<Job_Description_Aggregate_Order_By>;
  position?: InputMaybe<Order_By>;
  role_academic_departments_aggregate?: InputMaybe<Role_Academic_Department_Aggregate_Order_By>;
  role_benefits_aggregate?: InputMaybe<Role_Benefit_Aggregate_Order_By>;
  role_competences_aggregate?: InputMaybe<Role_Competence_Aggregate_Order_By>;
  role_descriptions_aggregate?: InputMaybe<Role_Description_Aggregate_Order_By>;
  role_responsibilities_aggregate?: InputMaybe<Role_Responsibility_Aggregate_Order_By>;
  role_skills_aggregate?: InputMaybe<Role_Skill_Aggregate_Order_By>;
  title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: role */
export type Role_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** columns and relationships of "role_responsibility" */
export type Role_Responsibility = {
  __typename?: "role_responsibility";
  id: Scalars["uuid"];
  responsibility_explanation: Scalars["String"];
  responsibility_name?: Maybe<Scalars["String"]>;
  /** An object relationship */
  role: Role;
  role_id: Scalars["uuid"];
};

/** aggregated selection of "role_responsibility" */
export type Role_Responsibility_Aggregate = {
  __typename?: "role_responsibility_aggregate";
  aggregate?: Maybe<Role_Responsibility_Aggregate_Fields>;
  nodes: Array<Role_Responsibility>;
};

export type Role_Responsibility_Aggregate_Bool_Exp = {
  count?: InputMaybe<Role_Responsibility_Aggregate_Bool_Exp_Count>;
};

export type Role_Responsibility_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Role_Responsibility_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Role_Responsibility_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "role_responsibility" */
export type Role_Responsibility_Aggregate_Fields = {
  __typename?: "role_responsibility_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Role_Responsibility_Max_Fields>;
  min?: Maybe<Role_Responsibility_Min_Fields>;
};

/** aggregate fields of "role_responsibility" */
export type Role_Responsibility_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Role_Responsibility_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "role_responsibility" */
export type Role_Responsibility_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Role_Responsibility_Max_Order_By>;
  min?: InputMaybe<Role_Responsibility_Min_Order_By>;
};

/** input type for inserting array relation for remote table "role_responsibility" */
export type Role_Responsibility_Arr_Rel_Insert_Input = {
  data: Array<Role_Responsibility_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Role_Responsibility_On_Conflict>;
};

/** Boolean expression to filter rows from the table "role_responsibility". All fields are combined with a logical 'AND'. */
export type Role_Responsibility_Bool_Exp = {
  _and?: InputMaybe<Array<Role_Responsibility_Bool_Exp>>;
  _not?: InputMaybe<Role_Responsibility_Bool_Exp>;
  _or?: InputMaybe<Array<Role_Responsibility_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  responsibility_explanation?: InputMaybe<String_Comparison_Exp>;
  responsibility_name?: InputMaybe<String_Comparison_Exp>;
  role?: InputMaybe<Role_Bool_Exp>;
  role_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "role_responsibility" */
export enum Role_Responsibility_Constraint {
  /** unique or primary key constraint on columns "id" */
  RoleResponsibilityPkey = "role_responsibility_pkey",
}

/** input type for inserting data into table "role_responsibility" */
export type Role_Responsibility_Insert_Input = {
  id?: InputMaybe<Scalars["uuid"]>;
  responsibility_explanation?: InputMaybe<Scalars["String"]>;
  responsibility_name?: InputMaybe<Scalars["String"]>;
  role?: InputMaybe<Role_Obj_Rel_Insert_Input>;
  role_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Role_Responsibility_Max_Fields = {
  __typename?: "role_responsibility_max_fields";
  id?: Maybe<Scalars["uuid"]>;
  responsibility_explanation?: Maybe<Scalars["String"]>;
  responsibility_name?: Maybe<Scalars["String"]>;
  role_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "role_responsibility" */
export type Role_Responsibility_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  responsibility_explanation?: InputMaybe<Order_By>;
  responsibility_name?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Role_Responsibility_Min_Fields = {
  __typename?: "role_responsibility_min_fields";
  id?: Maybe<Scalars["uuid"]>;
  responsibility_explanation?: Maybe<Scalars["String"]>;
  responsibility_name?: Maybe<Scalars["String"]>;
  role_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "role_responsibility" */
export type Role_Responsibility_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  responsibility_explanation?: InputMaybe<Order_By>;
  responsibility_name?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "role_responsibility" */
export type Role_Responsibility_Mutation_Response = {
  __typename?: "role_responsibility_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Role_Responsibility>;
};

/** on_conflict condition type for table "role_responsibility" */
export type Role_Responsibility_On_Conflict = {
  constraint: Role_Responsibility_Constraint;
  update_columns?: Array<Role_Responsibility_Update_Column>;
  where?: InputMaybe<Role_Responsibility_Bool_Exp>;
};

/** Ordering options when selecting data from "role_responsibility". */
export type Role_Responsibility_Order_By = {
  id?: InputMaybe<Order_By>;
  responsibility_explanation?: InputMaybe<Order_By>;
  responsibility_name?: InputMaybe<Order_By>;
  role?: InputMaybe<Role_Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: role_responsibility */
export type Role_Responsibility_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "role_responsibility" */
export enum Role_Responsibility_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  ResponsibilityExplanation = "responsibility_explanation",
  /** column name */
  ResponsibilityName = "responsibility_name",
  /** column name */
  RoleId = "role_id",
}

/** input type for updating data in table "role_responsibility" */
export type Role_Responsibility_Set_Input = {
  id?: InputMaybe<Scalars["uuid"]>;
  responsibility_explanation?: InputMaybe<Scalars["String"]>;
  responsibility_name?: InputMaybe<Scalars["String"]>;
  role_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "role_responsibility" */
export type Role_Responsibility_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Role_Responsibility_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Role_Responsibility_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars["uuid"]>;
  responsibility_explanation?: InputMaybe<Scalars["String"]>;
  responsibility_name?: InputMaybe<Scalars["String"]>;
  role_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "role_responsibility" */
export enum Role_Responsibility_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  ResponsibilityExplanation = "responsibility_explanation",
  /** column name */
  ResponsibilityName = "responsibility_name",
  /** column name */
  RoleId = "role_id",
}

export type Role_Responsibility_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Role_Responsibility_Set_Input>;
  /** filter the rows which have to be updated */
  where: Role_Responsibility_Bool_Exp;
};

/** columns and relationships of "role_review_metric" */
export type Role_Review_Metric = {
  __typename?: "role_review_metric";
  id: Scalars["Int"];
  /** An object relationship */
  review_metric: Review_Metric;
  review_metric_id: Scalars["Int"];
  /** An object relationship */
  role: Role;
  role_id: Scalars["uuid"];
};

/** aggregated selection of "role_review_metric" */
export type Role_Review_Metric_Aggregate = {
  __typename?: "role_review_metric_aggregate";
  aggregate?: Maybe<Role_Review_Metric_Aggregate_Fields>;
  nodes: Array<Role_Review_Metric>;
};

/** aggregate fields of "role_review_metric" */
export type Role_Review_Metric_Aggregate_Fields = {
  __typename?: "role_review_metric_aggregate_fields";
  avg?: Maybe<Role_Review_Metric_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Role_Review_Metric_Max_Fields>;
  min?: Maybe<Role_Review_Metric_Min_Fields>;
  stddev?: Maybe<Role_Review_Metric_Stddev_Fields>;
  stddev_pop?: Maybe<Role_Review_Metric_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Role_Review_Metric_Stddev_Samp_Fields>;
  sum?: Maybe<Role_Review_Metric_Sum_Fields>;
  var_pop?: Maybe<Role_Review_Metric_Var_Pop_Fields>;
  var_samp?: Maybe<Role_Review_Metric_Var_Samp_Fields>;
  variance?: Maybe<Role_Review_Metric_Variance_Fields>;
};

/** aggregate fields of "role_review_metric" */
export type Role_Review_Metric_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Role_Review_Metric_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Role_Review_Metric_Avg_Fields = {
  __typename?: "role_review_metric_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  review_metric_id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "role_review_metric". All fields are combined with a logical 'AND'. */
export type Role_Review_Metric_Bool_Exp = {
  _and?: InputMaybe<Array<Role_Review_Metric_Bool_Exp>>;
  _not?: InputMaybe<Role_Review_Metric_Bool_Exp>;
  _or?: InputMaybe<Array<Role_Review_Metric_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  review_metric?: InputMaybe<Review_Metric_Bool_Exp>;
  review_metric_id?: InputMaybe<Int_Comparison_Exp>;
  role?: InputMaybe<Role_Bool_Exp>;
  role_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "role_review_metric" */
export enum Role_Review_Metric_Constraint {
  /** unique or primary key constraint on columns "id" */
  RoleReviewMetricPkey = "role_review_metric_pkey",
}

/** input type for incrementing numeric columns in table "role_review_metric" */
export type Role_Review_Metric_Inc_Input = {
  id?: InputMaybe<Scalars["Int"]>;
  review_metric_id?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "role_review_metric" */
export type Role_Review_Metric_Insert_Input = {
  id?: InputMaybe<Scalars["Int"]>;
  review_metric?: InputMaybe<Review_Metric_Obj_Rel_Insert_Input>;
  review_metric_id?: InputMaybe<Scalars["Int"]>;
  role?: InputMaybe<Role_Obj_Rel_Insert_Input>;
  role_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Role_Review_Metric_Max_Fields = {
  __typename?: "role_review_metric_max_fields";
  id?: Maybe<Scalars["Int"]>;
  review_metric_id?: Maybe<Scalars["Int"]>;
  role_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate min on columns */
export type Role_Review_Metric_Min_Fields = {
  __typename?: "role_review_metric_min_fields";
  id?: Maybe<Scalars["Int"]>;
  review_metric_id?: Maybe<Scalars["Int"]>;
  role_id?: Maybe<Scalars["uuid"]>;
};

/** response of any mutation on the table "role_review_metric" */
export type Role_Review_Metric_Mutation_Response = {
  __typename?: "role_review_metric_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Role_Review_Metric>;
};

/** on_conflict condition type for table "role_review_metric" */
export type Role_Review_Metric_On_Conflict = {
  constraint: Role_Review_Metric_Constraint;
  update_columns?: Array<Role_Review_Metric_Update_Column>;
  where?: InputMaybe<Role_Review_Metric_Bool_Exp>;
};

/** Ordering options when selecting data from "role_review_metric". */
export type Role_Review_Metric_Order_By = {
  id?: InputMaybe<Order_By>;
  review_metric?: InputMaybe<Review_Metric_Order_By>;
  review_metric_id?: InputMaybe<Order_By>;
  role?: InputMaybe<Role_Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: role_review_metric */
export type Role_Review_Metric_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "role_review_metric" */
export enum Role_Review_Metric_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  ReviewMetricId = "review_metric_id",
  /** column name */
  RoleId = "role_id",
}

/** input type for updating data in table "role_review_metric" */
export type Role_Review_Metric_Set_Input = {
  id?: InputMaybe<Scalars["Int"]>;
  review_metric_id?: InputMaybe<Scalars["Int"]>;
  role_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type Role_Review_Metric_Stddev_Fields = {
  __typename?: "role_review_metric_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  review_metric_id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Role_Review_Metric_Stddev_Pop_Fields = {
  __typename?: "role_review_metric_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  review_metric_id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Role_Review_Metric_Stddev_Samp_Fields = {
  __typename?: "role_review_metric_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  review_metric_id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "role_review_metric" */
export type Role_Review_Metric_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Role_Review_Metric_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Role_Review_Metric_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars["Int"]>;
  review_metric_id?: InputMaybe<Scalars["Int"]>;
  role_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate sum on columns */
export type Role_Review_Metric_Sum_Fields = {
  __typename?: "role_review_metric_sum_fields";
  id?: Maybe<Scalars["Int"]>;
  review_metric_id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "role_review_metric" */
export enum Role_Review_Metric_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  ReviewMetricId = "review_metric_id",
  /** column name */
  RoleId = "role_id",
}

export type Role_Review_Metric_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Role_Review_Metric_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Role_Review_Metric_Set_Input>;
  /** filter the rows which have to be updated */
  where: Role_Review_Metric_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Role_Review_Metric_Var_Pop_Fields = {
  __typename?: "role_review_metric_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  review_metric_id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Role_Review_Metric_Var_Samp_Fields = {
  __typename?: "role_review_metric_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  review_metric_id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Role_Review_Metric_Variance_Fields = {
  __typename?: "role_review_metric_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  review_metric_id?: Maybe<Scalars["Float"]>;
};

/** select columns of table "role" */
export enum Role_Select_Column {
  /** column name */
  DepartmentId = "department_id",
  /** column name */
  ExperienceLevel = "experience_level",
  /** column name */
  Id = "id",
  /** column name */
  Position = "position",
  /** column name */
  Title = "title",
}

/** input type for updating data in table "role" */
export type Role_Set_Input = {
  department_id?: InputMaybe<Scalars["Int"]>;
  experience_level?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  position?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "role_skill" */
export type Role_Skill = {
  __typename?: "role_skill";
  id: Scalars["uuid"];
  /** An object relationship */
  role: Role;
  role_id: Scalars["uuid"];
  skill_explanation: Scalars["String"];
  skill_name?: Maybe<Scalars["String"]>;
  skill_rate?: Maybe<Scalars["Int"]>;
};

/** aggregated selection of "role_skill" */
export type Role_Skill_Aggregate = {
  __typename?: "role_skill_aggregate";
  aggregate?: Maybe<Role_Skill_Aggregate_Fields>;
  nodes: Array<Role_Skill>;
};

export type Role_Skill_Aggregate_Bool_Exp = {
  count?: InputMaybe<Role_Skill_Aggregate_Bool_Exp_Count>;
};

export type Role_Skill_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Role_Skill_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Role_Skill_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "role_skill" */
export type Role_Skill_Aggregate_Fields = {
  __typename?: "role_skill_aggregate_fields";
  avg?: Maybe<Role_Skill_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Role_Skill_Max_Fields>;
  min?: Maybe<Role_Skill_Min_Fields>;
  stddev?: Maybe<Role_Skill_Stddev_Fields>;
  stddev_pop?: Maybe<Role_Skill_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Role_Skill_Stddev_Samp_Fields>;
  sum?: Maybe<Role_Skill_Sum_Fields>;
  var_pop?: Maybe<Role_Skill_Var_Pop_Fields>;
  var_samp?: Maybe<Role_Skill_Var_Samp_Fields>;
  variance?: Maybe<Role_Skill_Variance_Fields>;
};

/** aggregate fields of "role_skill" */
export type Role_Skill_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Role_Skill_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "role_skill" */
export type Role_Skill_Aggregate_Order_By = {
  avg?: InputMaybe<Role_Skill_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Role_Skill_Max_Order_By>;
  min?: InputMaybe<Role_Skill_Min_Order_By>;
  stddev?: InputMaybe<Role_Skill_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Role_Skill_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Role_Skill_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Role_Skill_Sum_Order_By>;
  var_pop?: InputMaybe<Role_Skill_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Role_Skill_Var_Samp_Order_By>;
  variance?: InputMaybe<Role_Skill_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "role_skill" */
export type Role_Skill_Arr_Rel_Insert_Input = {
  data: Array<Role_Skill_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Role_Skill_On_Conflict>;
};

/** aggregate avg on columns */
export type Role_Skill_Avg_Fields = {
  __typename?: "role_skill_avg_fields";
  skill_rate?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "role_skill" */
export type Role_Skill_Avg_Order_By = {
  skill_rate?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "role_skill". All fields are combined with a logical 'AND'. */
export type Role_Skill_Bool_Exp = {
  _and?: InputMaybe<Array<Role_Skill_Bool_Exp>>;
  _not?: InputMaybe<Role_Skill_Bool_Exp>;
  _or?: InputMaybe<Array<Role_Skill_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  role?: InputMaybe<Role_Bool_Exp>;
  role_id?: InputMaybe<Uuid_Comparison_Exp>;
  skill_explanation?: InputMaybe<String_Comparison_Exp>;
  skill_name?: InputMaybe<String_Comparison_Exp>;
  skill_rate?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "role_skill" */
export enum Role_Skill_Constraint {
  /** unique or primary key constraint on columns "id" */
  SkillPkey = "skill_pkey",
}

/** input type for incrementing numeric columns in table "role_skill" */
export type Role_Skill_Inc_Input = {
  skill_rate?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "role_skill" */
export type Role_Skill_Insert_Input = {
  id?: InputMaybe<Scalars["uuid"]>;
  role?: InputMaybe<Role_Obj_Rel_Insert_Input>;
  role_id?: InputMaybe<Scalars["uuid"]>;
  skill_explanation?: InputMaybe<Scalars["String"]>;
  skill_name?: InputMaybe<Scalars["String"]>;
  skill_rate?: InputMaybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Role_Skill_Max_Fields = {
  __typename?: "role_skill_max_fields";
  id?: Maybe<Scalars["uuid"]>;
  role_id?: Maybe<Scalars["uuid"]>;
  skill_explanation?: Maybe<Scalars["String"]>;
  skill_name?: Maybe<Scalars["String"]>;
  skill_rate?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "role_skill" */
export type Role_Skill_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  skill_explanation?: InputMaybe<Order_By>;
  skill_name?: InputMaybe<Order_By>;
  skill_rate?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Role_Skill_Min_Fields = {
  __typename?: "role_skill_min_fields";
  id?: Maybe<Scalars["uuid"]>;
  role_id?: Maybe<Scalars["uuid"]>;
  skill_explanation?: Maybe<Scalars["String"]>;
  skill_name?: Maybe<Scalars["String"]>;
  skill_rate?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "role_skill" */
export type Role_Skill_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  skill_explanation?: InputMaybe<Order_By>;
  skill_name?: InputMaybe<Order_By>;
  skill_rate?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "role_skill" */
export type Role_Skill_Mutation_Response = {
  __typename?: "role_skill_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Role_Skill>;
};

/** on_conflict condition type for table "role_skill" */
export type Role_Skill_On_Conflict = {
  constraint: Role_Skill_Constraint;
  update_columns?: Array<Role_Skill_Update_Column>;
  where?: InputMaybe<Role_Skill_Bool_Exp>;
};

/** Ordering options when selecting data from "role_skill". */
export type Role_Skill_Order_By = {
  id?: InputMaybe<Order_By>;
  role?: InputMaybe<Role_Order_By>;
  role_id?: InputMaybe<Order_By>;
  skill_explanation?: InputMaybe<Order_By>;
  skill_name?: InputMaybe<Order_By>;
  skill_rate?: InputMaybe<Order_By>;
};

/** primary key columns input for table: role_skill */
export type Role_Skill_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "role_skill" */
export enum Role_Skill_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  RoleId = "role_id",
  /** column name */
  SkillExplanation = "skill_explanation",
  /** column name */
  SkillName = "skill_name",
  /** column name */
  SkillRate = "skill_rate",
}

/** input type for updating data in table "role_skill" */
export type Role_Skill_Set_Input = {
  id?: InputMaybe<Scalars["uuid"]>;
  role_id?: InputMaybe<Scalars["uuid"]>;
  skill_explanation?: InputMaybe<Scalars["String"]>;
  skill_name?: InputMaybe<Scalars["String"]>;
  skill_rate?: InputMaybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Role_Skill_Stddev_Fields = {
  __typename?: "role_skill_stddev_fields";
  skill_rate?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "role_skill" */
export type Role_Skill_Stddev_Order_By = {
  skill_rate?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Role_Skill_Stddev_Pop_Fields = {
  __typename?: "role_skill_stddev_pop_fields";
  skill_rate?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "role_skill" */
export type Role_Skill_Stddev_Pop_Order_By = {
  skill_rate?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Role_Skill_Stddev_Samp_Fields = {
  __typename?: "role_skill_stddev_samp_fields";
  skill_rate?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "role_skill" */
export type Role_Skill_Stddev_Samp_Order_By = {
  skill_rate?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "role_skill" */
export type Role_Skill_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Role_Skill_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Role_Skill_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars["uuid"]>;
  role_id?: InputMaybe<Scalars["uuid"]>;
  skill_explanation?: InputMaybe<Scalars["String"]>;
  skill_name?: InputMaybe<Scalars["String"]>;
  skill_rate?: InputMaybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Role_Skill_Sum_Fields = {
  __typename?: "role_skill_sum_fields";
  skill_rate?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "role_skill" */
export type Role_Skill_Sum_Order_By = {
  skill_rate?: InputMaybe<Order_By>;
};

/** update columns of table "role_skill" */
export enum Role_Skill_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  RoleId = "role_id",
  /** column name */
  SkillExplanation = "skill_explanation",
  /** column name */
  SkillName = "skill_name",
  /** column name */
  SkillRate = "skill_rate",
}

export type Role_Skill_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Role_Skill_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Role_Skill_Set_Input>;
  /** filter the rows which have to be updated */
  where: Role_Skill_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Role_Skill_Var_Pop_Fields = {
  __typename?: "role_skill_var_pop_fields";
  skill_rate?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "role_skill" */
export type Role_Skill_Var_Pop_Order_By = {
  skill_rate?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Role_Skill_Var_Samp_Fields = {
  __typename?: "role_skill_var_samp_fields";
  skill_rate?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "role_skill" */
export type Role_Skill_Var_Samp_Order_By = {
  skill_rate?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Role_Skill_Variance_Fields = {
  __typename?: "role_skill_variance_fields";
  skill_rate?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "role_skill" */
export type Role_Skill_Variance_Order_By = {
  skill_rate?: InputMaybe<Order_By>;
};

/** aggregate stddev on columns */
export type Role_Stddev_Fields = {
  __typename?: "role_stddev_fields";
  department_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "role" */
export type Role_Stddev_Order_By = {
  department_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Role_Stddev_Pop_Fields = {
  __typename?: "role_stddev_pop_fields";
  department_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "role" */
export type Role_Stddev_Pop_Order_By = {
  department_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Role_Stddev_Samp_Fields = {
  __typename?: "role_stddev_samp_fields";
  department_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "role" */
export type Role_Stddev_Samp_Order_By = {
  department_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "role" */
export type Role_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Role_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Role_Stream_Cursor_Value_Input = {
  department_id?: InputMaybe<Scalars["Int"]>;
  experience_level?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  position?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Role_Sum_Fields = {
  __typename?: "role_sum_fields";
  department_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "role" */
export type Role_Sum_Order_By = {
  department_id?: InputMaybe<Order_By>;
};

/** update columns of table "role" */
export enum Role_Update_Column {
  /** column name */
  DepartmentId = "department_id",
  /** column name */
  ExperienceLevel = "experience_level",
  /** column name */
  Id = "id",
  /** column name */
  Position = "position",
  /** column name */
  Title = "title",
}

export type Role_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Role_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Role_Set_Input>;
  /** filter the rows which have to be updated */
  where: Role_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Role_Var_Pop_Fields = {
  __typename?: "role_var_pop_fields";
  department_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "role" */
export type Role_Var_Pop_Order_By = {
  department_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Role_Var_Samp_Fields = {
  __typename?: "role_var_samp_fields";
  department_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "role" */
export type Role_Var_Samp_Order_By = {
  department_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Role_Variance_Fields = {
  __typename?: "role_variance_fields";
  department_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "role" */
export type Role_Variance_Order_By = {
  department_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "sector" */
export type Sector = {
  __typename?: "sector";
  /** An array relationship */
  departments: Array<Department>;
  /** An aggregate relationship */
  departments_aggregate: Department_Aggregate;
  id: Scalars["Int"];
  sector_name: Scalars["String"];
};

/** columns and relationships of "sector" */
export type SectorDepartmentsArgs = {
  distinct_on?: InputMaybe<Array<Department_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Department_Order_By>>;
  where?: InputMaybe<Department_Bool_Exp>;
};

/** columns and relationships of "sector" */
export type SectorDepartments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Department_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Department_Order_By>>;
  where?: InputMaybe<Department_Bool_Exp>;
};

/** aggregated selection of "sector" */
export type Sector_Aggregate = {
  __typename?: "sector_aggregate";
  aggregate?: Maybe<Sector_Aggregate_Fields>;
  nodes: Array<Sector>;
};

/** aggregate fields of "sector" */
export type Sector_Aggregate_Fields = {
  __typename?: "sector_aggregate_fields";
  avg?: Maybe<Sector_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sector_Max_Fields>;
  min?: Maybe<Sector_Min_Fields>;
  stddev?: Maybe<Sector_Stddev_Fields>;
  stddev_pop?: Maybe<Sector_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sector_Stddev_Samp_Fields>;
  sum?: Maybe<Sector_Sum_Fields>;
  var_pop?: Maybe<Sector_Var_Pop_Fields>;
  var_samp?: Maybe<Sector_Var_Samp_Fields>;
  variance?: Maybe<Sector_Variance_Fields>;
};

/** aggregate fields of "sector" */
export type Sector_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sector_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Sector_Avg_Fields = {
  __typename?: "sector_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "sector". All fields are combined with a logical 'AND'. */
export type Sector_Bool_Exp = {
  _and?: InputMaybe<Array<Sector_Bool_Exp>>;
  _not?: InputMaybe<Sector_Bool_Exp>;
  _or?: InputMaybe<Array<Sector_Bool_Exp>>;
  departments?: InputMaybe<Department_Bool_Exp>;
  departments_aggregate?: InputMaybe<Department_Aggregate_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  sector_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "sector" */
export enum Sector_Constraint {
  /** unique or primary key constraint on columns "id" */
  SectorPkey = "sector_pkey",
}

/** input type for incrementing numeric columns in table "sector" */
export type Sector_Inc_Input = {
  id?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "sector" */
export type Sector_Insert_Input = {
  departments?: InputMaybe<Department_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars["Int"]>;
  sector_name?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Sector_Max_Fields = {
  __typename?: "sector_max_fields";
  id?: Maybe<Scalars["Int"]>;
  sector_name?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Sector_Min_Fields = {
  __typename?: "sector_min_fields";
  id?: Maybe<Scalars["Int"]>;
  sector_name?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "sector" */
export type Sector_Mutation_Response = {
  __typename?: "sector_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Sector>;
};

/** input type for inserting object relation for remote table "sector" */
export type Sector_Obj_Rel_Insert_Input = {
  data: Sector_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Sector_On_Conflict>;
};

/** on_conflict condition type for table "sector" */
export type Sector_On_Conflict = {
  constraint: Sector_Constraint;
  update_columns?: Array<Sector_Update_Column>;
  where?: InputMaybe<Sector_Bool_Exp>;
};

/** Ordering options when selecting data from "sector". */
export type Sector_Order_By = {
  departments_aggregate?: InputMaybe<Department_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  sector_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: sector */
export type Sector_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "sector" */
export enum Sector_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  SectorName = "sector_name",
}

/** input type for updating data in table "sector" */
export type Sector_Set_Input = {
  id?: InputMaybe<Scalars["Int"]>;
  sector_name?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Sector_Stddev_Fields = {
  __typename?: "sector_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Sector_Stddev_Pop_Fields = {
  __typename?: "sector_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Sector_Stddev_Samp_Fields = {
  __typename?: "sector_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "sector" */
export type Sector_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sector_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sector_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars["Int"]>;
  sector_name?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Sector_Sum_Fields = {
  __typename?: "sector_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "sector" */
export enum Sector_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  SectorName = "sector_name",
}

export type Sector_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Sector_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Sector_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sector_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Sector_Var_Pop_Fields = {
  __typename?: "sector_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Sector_Var_Samp_Fields = {
  __typename?: "sector_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Sector_Variance_Fields = {
  __typename?: "sector_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Subscription_Root = {
  __typename?: "subscription_root";
  /** fetch data from the table: "academic_department" */
  academic_department: Array<Academic_Department>;
  /** fetch aggregated fields from the table: "academic_department" */
  academic_department_aggregate: Academic_Department_Aggregate;
  /** fetch data from the table: "academic_department" using primary key columns */
  academic_department_by_pk?: Maybe<Academic_Department>;
  /** fetch data from the table in a streaming manner: "academic_department" */
  academic_department_stream: Array<Academic_Department>;
  /** fetch data from the table: "address_type" */
  address_type: Array<Address_Type>;
  /** fetch aggregated fields from the table: "address_type" */
  address_type_aggregate: Address_Type_Aggregate;
  /** fetch data from the table: "address_type" using primary key columns */
  address_type_by_pk?: Maybe<Address_Type>;
  /** fetch data from the table in a streaming manner: "address_type" */
  address_type_stream: Array<Address_Type>;
  /** fetch data from the table: "city" */
  city: Array<City>;
  /** fetch aggregated fields from the table: "city" */
  city_aggregate: City_Aggregate;
  /** fetch data from the table: "city" using primary key columns */
  city_by_pk?: Maybe<City>;
  /** fetch data from the table in a streaming manner: "city" */
  city_stream: Array<City>;
  /** fetch data from the table: "client" */
  client: Array<Client>;
  /** fetch aggregated fields from the table: "client" */
  client_aggregate: Client_Aggregate;
  /** fetch data from the table: "client" using primary key columns */
  client_by_pk?: Maybe<Client>;
  /** fetch data from the table: "client_candidate" */
  client_candidate: Array<Client_Candidate>;
  /** fetch aggregated fields from the table: "client_candidate" */
  client_candidate_aggregate: Client_Candidate_Aggregate;
  /** fetch data from the table: "client_candidate" using primary key columns */
  client_candidate_by_pk?: Maybe<Client_Candidate>;
  /** fetch data from the table: "client_candidate_project" */
  client_candidate_project: Array<Client_Candidate_Project>;
  /** fetch aggregated fields from the table: "client_candidate_project" */
  client_candidate_project_aggregate: Client_Candidate_Project_Aggregate;
  /** fetch data from the table: "client_candidate_project" using primary key columns */
  client_candidate_project_by_pk?: Maybe<Client_Candidate_Project>;
  /** fetch data from the table: "client_candidate_project_candidate_list_status" */
  client_candidate_project_candidate_list_status: Array<Client_Candidate_Project_Candidate_List_Status>;
  /** fetch aggregated fields from the table: "client_candidate_project_candidate_list_status" */
  client_candidate_project_candidate_list_status_aggregate: Client_Candidate_Project_Candidate_List_Status_Aggregate;
  /** fetch data from the table: "client_candidate_project_candidate_list_status" using primary key columns */
  client_candidate_project_candidate_list_status_by_pk?: Maybe<Client_Candidate_Project_Candidate_List_Status>;
  /** fetch data from the table in a streaming manner: "client_candidate_project_candidate_list_status" */
  client_candidate_project_candidate_list_status_stream: Array<Client_Candidate_Project_Candidate_List_Status>;
  /** fetch data from the table: "client_candidate_project_candidate_resolution" */
  client_candidate_project_candidate_resolution: Array<Client_Candidate_Project_Candidate_Resolution>;
  /** fetch aggregated fields from the table: "client_candidate_project_candidate_resolution" */
  client_candidate_project_candidate_resolution_aggregate: Client_Candidate_Project_Candidate_Resolution_Aggregate;
  /** fetch data from the table: "client_candidate_project_candidate_resolution" using primary key columns */
  client_candidate_project_candidate_resolution_by_pk?: Maybe<Client_Candidate_Project_Candidate_Resolution>;
  /** fetch data from the table in a streaming manner: "client_candidate_project_candidate_resolution" */
  client_candidate_project_candidate_resolution_stream: Array<Client_Candidate_Project_Candidate_Resolution>;
  /** fetch data from the table: "client_candidate_project_note" */
  client_candidate_project_note: Array<Client_Candidate_Project_Note>;
  /** fetch aggregated fields from the table: "client_candidate_project_note" */
  client_candidate_project_note_aggregate: Client_Candidate_Project_Note_Aggregate;
  /** fetch data from the table: "client_candidate_project_note" using primary key columns */
  client_candidate_project_note_by_pk?: Maybe<Client_Candidate_Project_Note>;
  /** fetch data from the table in a streaming manner: "client_candidate_project_note" */
  client_candidate_project_note_stream: Array<Client_Candidate_Project_Note>;
  /** fetch data from the table in a streaming manner: "client_candidate_project" */
  client_candidate_project_stream: Array<Client_Candidate_Project>;
  /** fetch data from the table in a streaming manner: "client_candidate" */
  client_candidate_stream: Array<Client_Candidate>;
  /** fetch data from the table: "client_location" */
  client_location: Array<Client_Location>;
  /** fetch aggregated fields from the table: "client_location" */
  client_location_aggregate: Client_Location_Aggregate;
  /** fetch data from the table: "client_location" using primary key columns */
  client_location_by_pk?: Maybe<Client_Location>;
  /** fetch data from the table in a streaming manner: "client_location" */
  client_location_stream: Array<Client_Location>;
  /** fetch data from the table: "client_sector" */
  client_sector: Array<Client_Sector>;
  /** fetch aggregated fields from the table: "client_sector" */
  client_sector_aggregate: Client_Sector_Aggregate;
  /** fetch data from the table: "client_sector" using primary key columns */
  client_sector_by_pk?: Maybe<Client_Sector>;
  /** fetch data from the table in a streaming manner: "client_sector" */
  client_sector_stream: Array<Client_Sector>;
  /** fetch data from the table: "client_setting" */
  client_setting: Array<Client_Setting>;
  /** fetch aggregated fields from the table: "client_setting" */
  client_setting_aggregate: Client_Setting_Aggregate;
  /** fetch data from the table: "client_setting" using primary key columns */
  client_setting_by_pk?: Maybe<Client_Setting>;
  /** fetch data from the table: "client_setting_key" */
  client_setting_key: Array<Client_Setting_Key>;
  /** fetch aggregated fields from the table: "client_setting_key" */
  client_setting_key_aggregate: Client_Setting_Key_Aggregate;
  /** fetch data from the table: "client_setting_key" using primary key columns */
  client_setting_key_by_pk?: Maybe<Client_Setting_Key>;
  /** fetch data from the table in a streaming manner: "client_setting_key" */
  client_setting_key_stream: Array<Client_Setting_Key>;
  /** fetch data from the table in a streaming manner: "client_setting" */
  client_setting_stream: Array<Client_Setting>;
  /** fetch data from the table in a streaming manner: "client" */
  client_stream: Array<Client>;
  /** fetch data from the table: "company" */
  company: Array<Company>;
  /** fetch aggregated fields from the table: "company" */
  company_aggregate: Company_Aggregate;
  /** fetch data from the table: "company" using primary key columns */
  company_by_pk?: Maybe<Company>;
  /** fetch data from the table in a streaming manner: "company" */
  company_stream: Array<Company>;
  /** fetch data from the table: "company_subscription" */
  company_subscription: Array<Company_Subscription>;
  /** fetch aggregated fields from the table: "company_subscription" */
  company_subscription_aggregate: Company_Subscription_Aggregate;
  /** fetch data from the table: "company_subscription" using primary key columns */
  company_subscription_by_pk?: Maybe<Company_Subscription>;
  /** fetch data from the table in a streaming manner: "company_subscription" */
  company_subscription_stream: Array<Company_Subscription>;
  /** fetch data from the table: "consent" */
  consent: Array<Consent>;
  /** fetch aggregated fields from the table: "consent" */
  consent_aggregate: Consent_Aggregate;
  /** fetch data from the table: "consent" using primary key columns */
  consent_by_pk?: Maybe<Consent>;
  /** fetch data from the table: "consent_status" */
  consent_status: Array<Consent_Status>;
  /** fetch aggregated fields from the table: "consent_status" */
  consent_status_aggregate: Consent_Status_Aggregate;
  /** fetch data from the table: "consent_status" using primary key columns */
  consent_status_by_pk?: Maybe<Consent_Status>;
  /** fetch data from the table in a streaming manner: "consent_status" */
  consent_status_stream: Array<Consent_Status>;
  /** fetch data from the table in a streaming manner: "consent" */
  consent_stream: Array<Consent>;
  /** fetch data from the table: "consent_type" */
  consent_type: Array<Consent_Type>;
  /** fetch aggregated fields from the table: "consent_type" */
  consent_type_aggregate: Consent_Type_Aggregate;
  /** fetch data from the table: "consent_type" using primary key columns */
  consent_type_by_pk?: Maybe<Consent_Type>;
  /** fetch data from the table in a streaming manner: "consent_type" */
  consent_type_stream: Array<Consent_Type>;
  /** fetch data from the table: "country" */
  country: Array<Country>;
  /** fetch aggregated fields from the table: "country" */
  country_aggregate: Country_Aggregate;
  /** fetch data from the table: "country" using primary key columns */
  country_by_pk?: Maybe<Country>;
  /** fetch data from the table in a streaming manner: "country" */
  country_stream: Array<Country>;
  /** fetch data from the table: "currency" */
  currency: Array<Currency>;
  /** fetch aggregated fields from the table: "currency" */
  currency_aggregate: Currency_Aggregate;
  /** fetch data from the table: "currency" using primary key columns */
  currency_by_pk?: Maybe<Currency>;
  /** fetch data from the table in a streaming manner: "currency" */
  currency_stream: Array<Currency>;
  /** fetch data from the table: "department" */
  department: Array<Department>;
  /** fetch aggregated fields from the table: "department" */
  department_aggregate: Department_Aggregate;
  /** fetch data from the table: "department" using primary key columns */
  department_by_pk?: Maybe<Department>;
  /** fetch data from the table in a streaming manner: "department" */
  department_stream: Array<Department>;
  /** fetch data from the table: "education_type" */
  education_type: Array<Education_Type>;
  /** fetch aggregated fields from the table: "education_type" */
  education_type_aggregate: Education_Type_Aggregate;
  /** fetch data from the table: "education_type" using primary key columns */
  education_type_by_pk?: Maybe<Education_Type>;
  /** fetch data from the table in a streaming manner: "education_type" */
  education_type_stream: Array<Education_Type>;
  /** fetch data from the table: "interview_criterion" */
  interview_criterion: Array<Interview_Criterion>;
  /** fetch aggregated fields from the table: "interview_criterion" */
  interview_criterion_aggregate: Interview_Criterion_Aggregate;
  /** fetch data from the table: "interview_criterion" using primary key columns */
  interview_criterion_by_pk?: Maybe<Interview_Criterion>;
  /** fetch data from the table in a streaming manner: "interview_criterion" */
  interview_criterion_stream: Array<Interview_Criterion>;
  /** fetch data from the table: "interview_template" */
  interview_template: Array<Interview_Template>;
  /** fetch aggregated fields from the table: "interview_template" */
  interview_template_aggregate: Interview_Template_Aggregate;
  /** fetch data from the table: "interview_template" using primary key columns */
  interview_template_by_pk?: Maybe<Interview_Template>;
  /** fetch data from the table: "interview_template_review_metric_question" */
  interview_template_review_metric_question: Array<Interview_Template_Review_Metric_Question>;
  /** fetch aggregated fields from the table: "interview_template_review_metric_question" */
  interview_template_review_metric_question_aggregate: Interview_Template_Review_Metric_Question_Aggregate;
  /** fetch data from the table: "interview_template_review_metric_question" using primary key columns */
  interview_template_review_metric_question_by_pk?: Maybe<Interview_Template_Review_Metric_Question>;
  /** fetch data from the table in a streaming manner: "interview_template_review_metric_question" */
  interview_template_review_metric_question_stream: Array<Interview_Template_Review_Metric_Question>;
  /** fetch data from the table in a streaming manner: "interview_template" */
  interview_template_stream: Array<Interview_Template>;
  /** fetch data from the table: "job_description" */
  job_description: Array<Job_Description>;
  /** fetch aggregated fields from the table: "job_description" */
  job_description_aggregate: Job_Description_Aggregate;
  /** fetch data from the table: "job_description" using primary key columns */
  job_description_by_pk?: Maybe<Job_Description>;
  /** fetch data from the table: "job_description_step" */
  job_description_step: Array<Job_Description_Step>;
  /** fetch aggregated fields from the table: "job_description_step" */
  job_description_step_aggregate: Job_Description_Step_Aggregate;
  /** fetch data from the table: "job_description_step" using primary key columns */
  job_description_step_by_pk?: Maybe<Job_Description_Step>;
  /** fetch data from the table in a streaming manner: "job_description_step" */
  job_description_step_stream: Array<Job_Description_Step>;
  /** fetch data from the table in a streaming manner: "job_description" */
  job_description_stream: Array<Job_Description>;
  /** fetch data from the table: "language" */
  language: Array<Language>;
  /** fetch aggregated fields from the table: "language" */
  language_aggregate: Language_Aggregate;
  /** fetch data from the table: "language" using primary key columns */
  language_by_pk?: Maybe<Language>;
  /** fetch data from the table in a streaming manner: "language" */
  language_stream: Array<Language>;
  /** fetch data from the table: "partner" */
  partner: Array<Partner>;
  /** fetch aggregated fields from the table: "partner" */
  partner_aggregate: Partner_Aggregate;
  /** fetch data from the table: "partner" using primary key columns */
  partner_by_pk?: Maybe<Partner>;
  /** fetch data from the table in a streaming manner: "partner" */
  partner_stream: Array<Partner>;
  /** fetch data from the table: "product" */
  product: Array<Product>;
  /** fetch aggregated fields from the table: "product" */
  product_aggregate: Product_Aggregate;
  /** fetch data from the table: "product" using primary key columns */
  product_by_pk?: Maybe<Product>;
  /** fetch data from the table: "product_price" */
  product_price: Array<Product_Price>;
  /** fetch aggregated fields from the table: "product_price" */
  product_price_aggregate: Product_Price_Aggregate;
  /** fetch data from the table: "product_price" using primary key columns */
  product_price_by_pk?: Maybe<Product_Price>;
  /** fetch data from the table in a streaming manner: "product_price" */
  product_price_stream: Array<Product_Price>;
  /** fetch data from the table in a streaming manner: "product" */
  product_stream: Array<Product>;
  /** fetch data from the table: "project" */
  project: Array<Project>;
  /** fetch aggregated fields from the table: "project" */
  project_aggregate: Project_Aggregate;
  /** fetch data from the table: "project" using primary key columns */
  project_by_pk?: Maybe<Project>;
  /** fetch data from the table: "project_status" */
  project_status: Array<Project_Status>;
  /** fetch aggregated fields from the table: "project_status" */
  project_status_aggregate: Project_Status_Aggregate;
  /** fetch data from the table: "project_status" using primary key columns */
  project_status_by_pk?: Maybe<Project_Status>;
  /** fetch data from the table in a streaming manner: "project_status" */
  project_status_stream: Array<Project_Status>;
  /** fetch data from the table in a streaming manner: "project" */
  project_stream: Array<Project>;
  /** fetch data from the table: "project_user" */
  project_user: Array<Project_User>;
  /** fetch aggregated fields from the table: "project_user" */
  project_user_aggregate: Project_User_Aggregate;
  /** fetch data from the table: "project_user" using primary key columns */
  project_user_by_pk?: Maybe<Project_User>;
  /** fetch data from the table in a streaming manner: "project_user" */
  project_user_stream: Array<Project_User>;
  /** fetch data from the table: "review_metric" */
  review_metric: Array<Review_Metric>;
  /** fetch aggregated fields from the table: "review_metric" */
  review_metric_aggregate: Review_Metric_Aggregate;
  /** fetch data from the table: "review_metric" using primary key columns */
  review_metric_by_pk?: Maybe<Review_Metric>;
  /** fetch data from the table: "review_metric_question" */
  review_metric_question: Array<Review_Metric_Question>;
  /** fetch aggregated fields from the table: "review_metric_question" */
  review_metric_question_aggregate: Review_Metric_Question_Aggregate;
  /** fetch data from the table: "review_metric_question" using primary key columns */
  review_metric_question_by_pk?: Maybe<Review_Metric_Question>;
  /** fetch data from the table: "review_metric_question_criterion" */
  review_metric_question_criterion: Array<Review_Metric_Question_Criterion>;
  /** fetch aggregated fields from the table: "review_metric_question_criterion" */
  review_metric_question_criterion_aggregate: Review_Metric_Question_Criterion_Aggregate;
  /** fetch data from the table: "review_metric_question_criterion" using primary key columns */
  review_metric_question_criterion_by_pk?: Maybe<Review_Metric_Question_Criterion>;
  /** fetch data from the table in a streaming manner: "review_metric_question_criterion" */
  review_metric_question_criterion_stream: Array<Review_Metric_Question_Criterion>;
  /** fetch data from the table: "review_metric_question_response" */
  review_metric_question_response: Array<Review_Metric_Question_Response>;
  /** fetch aggregated fields from the table: "review_metric_question_response" */
  review_metric_question_response_aggregate: Review_Metric_Question_Response_Aggregate;
  /** fetch data from the table: "review_metric_question_response" using primary key columns */
  review_metric_question_response_by_pk?: Maybe<Review_Metric_Question_Response>;
  /** fetch data from the table in a streaming manner: "review_metric_question_response" */
  review_metric_question_response_stream: Array<Review_Metric_Question_Response>;
  /** fetch data from the table in a streaming manner: "review_metric_question" */
  review_metric_question_stream: Array<Review_Metric_Question>;
  /** fetch data from the table: "review_metric_question_type" */
  review_metric_question_type: Array<Review_Metric_Question_Type>;
  /** fetch aggregated fields from the table: "review_metric_question_type" */
  review_metric_question_type_aggregate: Review_Metric_Question_Type_Aggregate;
  /** fetch data from the table: "review_metric_question_type" using primary key columns */
  review_metric_question_type_by_pk?: Maybe<Review_Metric_Question_Type>;
  /** fetch data from the table in a streaming manner: "review_metric_question_type" */
  review_metric_question_type_stream: Array<Review_Metric_Question_Type>;
  /** fetch data from the table in a streaming manner: "review_metric" */
  review_metric_stream: Array<Review_Metric>;
  /** fetch data from the table: "role" */
  role: Array<Role>;
  /** fetch data from the table: "role_academic_department" */
  role_academic_department: Array<Role_Academic_Department>;
  /** fetch aggregated fields from the table: "role_academic_department" */
  role_academic_department_aggregate: Role_Academic_Department_Aggregate;
  /** fetch data from the table: "role_academic_department" using primary key columns */
  role_academic_department_by_pk?: Maybe<Role_Academic_Department>;
  /** fetch data from the table in a streaming manner: "role_academic_department" */
  role_academic_department_stream: Array<Role_Academic_Department>;
  /** fetch aggregated fields from the table: "role" */
  role_aggregate: Role_Aggregate;
  /** fetch data from the table: "role_benefit" */
  role_benefit: Array<Role_Benefit>;
  /** fetch aggregated fields from the table: "role_benefit" */
  role_benefit_aggregate: Role_Benefit_Aggregate;
  /** fetch data from the table: "role_benefit" using primary key columns */
  role_benefit_by_pk?: Maybe<Role_Benefit>;
  /** fetch data from the table in a streaming manner: "role_benefit" */
  role_benefit_stream: Array<Role_Benefit>;
  /** fetch data from the table: "role" using primary key columns */
  role_by_pk?: Maybe<Role>;
  /** fetch data from the table: "role_competence" */
  role_competence: Array<Role_Competence>;
  /** fetch aggregated fields from the table: "role_competence" */
  role_competence_aggregate: Role_Competence_Aggregate;
  /** fetch data from the table: "role_competence" using primary key columns */
  role_competence_by_pk?: Maybe<Role_Competence>;
  /** fetch data from the table in a streaming manner: "role_competence" */
  role_competence_stream: Array<Role_Competence>;
  /** fetch data from the table: "role_description" */
  role_description: Array<Role_Description>;
  /** fetch aggregated fields from the table: "role_description" */
  role_description_aggregate: Role_Description_Aggregate;
  /** fetch data from the table: "role_description" using primary key columns */
  role_description_by_pk?: Maybe<Role_Description>;
  /** fetch data from the table in a streaming manner: "role_description" */
  role_description_stream: Array<Role_Description>;
  /** fetch data from the table: "role_responsibility" */
  role_responsibility: Array<Role_Responsibility>;
  /** fetch aggregated fields from the table: "role_responsibility" */
  role_responsibility_aggregate: Role_Responsibility_Aggregate;
  /** fetch data from the table: "role_responsibility" using primary key columns */
  role_responsibility_by_pk?: Maybe<Role_Responsibility>;
  /** fetch data from the table in a streaming manner: "role_responsibility" */
  role_responsibility_stream: Array<Role_Responsibility>;
  /** fetch data from the table: "role_review_metric" */
  role_review_metric: Array<Role_Review_Metric>;
  /** fetch aggregated fields from the table: "role_review_metric" */
  role_review_metric_aggregate: Role_Review_Metric_Aggregate;
  /** fetch data from the table: "role_review_metric" using primary key columns */
  role_review_metric_by_pk?: Maybe<Role_Review_Metric>;
  /** fetch data from the table in a streaming manner: "role_review_metric" */
  role_review_metric_stream: Array<Role_Review_Metric>;
  /** fetch data from the table: "role_skill" */
  role_skill: Array<Role_Skill>;
  /** fetch aggregated fields from the table: "role_skill" */
  role_skill_aggregate: Role_Skill_Aggregate;
  /** fetch data from the table: "role_skill" using primary key columns */
  role_skill_by_pk?: Maybe<Role_Skill>;
  /** fetch data from the table in a streaming manner: "role_skill" */
  role_skill_stream: Array<Role_Skill>;
  /** fetch data from the table in a streaming manner: "role" */
  role_stream: Array<Role>;
  /** fetch data from the table: "sector" */
  sector: Array<Sector>;
  /** fetch aggregated fields from the table: "sector" */
  sector_aggregate: Sector_Aggregate;
  /** fetch data from the table: "sector" using primary key columns */
  sector_by_pk?: Maybe<Sector>;
  /** fetch data from the table in a streaming manner: "sector" */
  sector_stream: Array<Sector>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "user_key" */
  user_key: Array<User_Key>;
  /** fetch aggregated fields from the table: "user_key" */
  user_key_aggregate: User_Key_Aggregate;
  /** fetch data from the table: "user_key" using primary key columns */
  user_key_by_pk?: Maybe<User_Key>;
  /** fetch data from the table in a streaming manner: "user_key" */
  user_key_stream: Array<User_Key>;
  /** fetch data from the table: "user_role" */
  user_role: Array<User_Role>;
  /** fetch aggregated fields from the table: "user_role" */
  user_role_aggregate: User_Role_Aggregate;
  /** fetch data from the table: "user_role" using primary key columns */
  user_role_by_pk?: Maybe<User_Role>;
  /** fetch data from the table in a streaming manner: "user_role" */
  user_role_stream: Array<User_Role>;
  /** fetch data from the table: "user_setting" */
  user_setting: Array<User_Setting>;
  /** fetch aggregated fields from the table: "user_setting" */
  user_setting_aggregate: User_Setting_Aggregate;
  /** fetch data from the table: "user_setting" using primary key columns */
  user_setting_by_pk?: Maybe<User_Setting>;
  /** fetch data from the table: "user_setting_key" */
  user_setting_key: Array<User_Setting_Key>;
  /** fetch aggregated fields from the table: "user_setting_key" */
  user_setting_key_aggregate: User_Setting_Key_Aggregate;
  /** fetch data from the table: "user_setting_key" using primary key columns */
  user_setting_key_by_pk?: Maybe<User_Setting_Key>;
  /** fetch data from the table in a streaming manner: "user_setting_key" */
  user_setting_key_stream: Array<User_Setting_Key>;
  /** fetch data from the table in a streaming manner: "user_setting" */
  user_setting_stream: Array<User_Setting>;
  /** fetch data from the table: "user_status" */
  user_status: Array<User_Status>;
  /** fetch aggregated fields from the table: "user_status" */
  user_status_aggregate: User_Status_Aggregate;
  /** fetch data from the table: "user_status" using primary key columns */
  user_status_by_pk?: Maybe<User_Status>;
  /** fetch data from the table in a streaming manner: "user_status" */
  user_status_stream: Array<User_Status>;
  /** fetch data from the table in a streaming manner: "user" */
  user_stream: Array<User>;
  /** fetch data from the table: "video_interview" */
  video_interview: Array<Video_Interview>;
  /** fetch aggregated fields from the table: "video_interview" */
  video_interview_aggregate: Video_Interview_Aggregate;
  /** fetch data from the table: "video_interview" using primary key columns */
  video_interview_by_pk?: Maybe<Video_Interview>;
  /** fetch data from the table: "video_interview_criterion_score" */
  video_interview_criterion_score: Array<Video_Interview_Criterion_Score>;
  /** fetch aggregated fields from the table: "video_interview_criterion_score" */
  video_interview_criterion_score_aggregate: Video_Interview_Criterion_Score_Aggregate;
  /** fetch data from the table: "video_interview_criterion_score" using primary key columns */
  video_interview_criterion_score_by_pk?: Maybe<Video_Interview_Criterion_Score>;
  /** fetch data from the table in a streaming manner: "video_interview_criterion_score" */
  video_interview_criterion_score_stream: Array<Video_Interview_Criterion_Score>;
  /** fetch data from the table: "video_interview_question" */
  video_interview_question: Array<Video_Interview_Question>;
  /** fetch aggregated fields from the table: "video_interview_question" */
  video_interview_question_aggregate: Video_Interview_Question_Aggregate;
  /** fetch data from the table: "video_interview_question" using primary key columns */
  video_interview_question_by_pk?: Maybe<Video_Interview_Question>;
  /** fetch data from the table: "video_interview_question_event" */
  video_interview_question_event: Array<Video_Interview_Question_Event>;
  /** fetch aggregated fields from the table: "video_interview_question_event" */
  video_interview_question_event_aggregate: Video_Interview_Question_Event_Aggregate;
  /** fetch data from the table: "video_interview_question_event" using primary key columns */
  video_interview_question_event_by_pk?: Maybe<Video_Interview_Question_Event>;
  /** fetch data from the table in a streaming manner: "video_interview_question_event" */
  video_interview_question_event_stream: Array<Video_Interview_Question_Event>;
  /** fetch data from the table: "video_interview_question_event_type" */
  video_interview_question_event_type: Array<Video_Interview_Question_Event_Type>;
  /** fetch aggregated fields from the table: "video_interview_question_event_type" */
  video_interview_question_event_type_aggregate: Video_Interview_Question_Event_Type_Aggregate;
  /** fetch data from the table: "video_interview_question_event_type" using primary key columns */
  video_interview_question_event_type_by_pk?: Maybe<Video_Interview_Question_Event_Type>;
  /** fetch data from the table in a streaming manner: "video_interview_question_event_type" */
  video_interview_question_event_type_stream: Array<Video_Interview_Question_Event_Type>;
  /** fetch data from the table: "video_interview_question_score" */
  video_interview_question_score: Array<Video_Interview_Question_Score>;
  /** fetch aggregated fields from the table: "video_interview_question_score" */
  video_interview_question_score_aggregate: Video_Interview_Question_Score_Aggregate;
  /** fetch data from the table: "video_interview_question_score" using primary key columns */
  video_interview_question_score_by_pk?: Maybe<Video_Interview_Question_Score>;
  /** fetch data from the table in a streaming manner: "video_interview_question_score" */
  video_interview_question_score_stream: Array<Video_Interview_Question_Score>;
  /** fetch data from the table in a streaming manner: "video_interview_question" */
  video_interview_question_stream: Array<Video_Interview_Question>;
  /** fetch data from the table: "video_interview_reviewer" */
  video_interview_reviewer: Array<Video_Interview_Reviewer>;
  /** fetch aggregated fields from the table: "video_interview_reviewer" */
  video_interview_reviewer_aggregate: Video_Interview_Reviewer_Aggregate;
  /** fetch data from the table: "video_interview_reviewer" using primary key columns */
  video_interview_reviewer_by_pk?: Maybe<Video_Interview_Reviewer>;
  /** fetch data from the table in a streaming manner: "video_interview_reviewer" */
  video_interview_reviewer_stream: Array<Video_Interview_Reviewer>;
  /** fetch data from the table: "video_interview_status" */
  video_interview_status: Array<Video_Interview_Status>;
  /** fetch aggregated fields from the table: "video_interview_status" */
  video_interview_status_aggregate: Video_Interview_Status_Aggregate;
  /** fetch data from the table: "video_interview_status" using primary key columns */
  video_interview_status_by_pk?: Maybe<Video_Interview_Status>;
  /** fetch data from the table: "video_interview_status_history" */
  video_interview_status_history: Array<Video_Interview_Status_History>;
  /** fetch aggregated fields from the table: "video_interview_status_history" */
  video_interview_status_history_aggregate: Video_Interview_Status_History_Aggregate;
  /** fetch data from the table: "video_interview_status_history" using primary key columns */
  video_interview_status_history_by_pk?: Maybe<Video_Interview_Status_History>;
  /** fetch data from the table in a streaming manner: "video_interview_status_history" */
  video_interview_status_history_stream: Array<Video_Interview_Status_History>;
  /** fetch data from the table in a streaming manner: "video_interview_status" */
  video_interview_status_stream: Array<Video_Interview_Status>;
  /** fetch data from the table in a streaming manner: "video_interview" */
  video_interview_stream: Array<Video_Interview>;
  /** fetch data from the table: "video_interview_type" */
  video_interview_type: Array<Video_Interview_Type>;
  /** fetch aggregated fields from the table: "video_interview_type" */
  video_interview_type_aggregate: Video_Interview_Type_Aggregate;
  /** fetch data from the table: "video_interview_type" using primary key columns */
  video_interview_type_by_pk?: Maybe<Video_Interview_Type>;
  /** fetch data from the table in a streaming manner: "video_interview_type" */
  video_interview_type_stream: Array<Video_Interview_Type>;
  /** fetch data from the table: "video_process_status" */
  video_process_status: Array<Video_Process_Status>;
  /** fetch aggregated fields from the table: "video_process_status" */
  video_process_status_aggregate: Video_Process_Status_Aggregate;
  /** fetch data from the table: "video_process_status" using primary key columns */
  video_process_status_by_pk?: Maybe<Video_Process_Status>;
  /** fetch data from the table in a streaming manner: "video_process_status" */
  video_process_status_stream: Array<Video_Process_Status>;
  /** fetch data from the table: "view__client_candidate_projects" */
  view__client_candidate_projects: Array<View__Client_Candidate_Projects>;
  /** fetch aggregated fields from the table: "view__client_candidate_projects" */
  view__client_candidate_projects_aggregate: View__Client_Candidate_Projects_Aggregate;
  /** fetch data from the table in a streaming manner: "view__client_candidate_projects" */
  view__client_candidate_projects_stream: Array<View__Client_Candidate_Projects>;
  /** fetch data from the table: "view__ordered_video_interview_question" */
  view__ordered_video_interview_question: Array<View__Ordered_Video_Interview_Question>;
  /** fetch aggregated fields from the table: "view__ordered_video_interview_question" */
  view__ordered_video_interview_question_aggregate: View__Ordered_Video_Interview_Question_Aggregate;
  /** fetch data from the table in a streaming manner: "view__ordered_video_interview_question" */
  view__ordered_video_interview_question_stream: Array<View__Ordered_Video_Interview_Question>;
};

export type Subscription_RootAcademic_DepartmentArgs = {
  distinct_on?: InputMaybe<Array<Academic_Department_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Academic_Department_Order_By>>;
  where?: InputMaybe<Academic_Department_Bool_Exp>;
};

export type Subscription_RootAcademic_Department_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Academic_Department_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Academic_Department_Order_By>>;
  where?: InputMaybe<Academic_Department_Bool_Exp>;
};

export type Subscription_RootAcademic_Department_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootAcademic_Department_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Academic_Department_Stream_Cursor_Input>>;
  where?: InputMaybe<Academic_Department_Bool_Exp>;
};

export type Subscription_RootAddress_TypeArgs = {
  distinct_on?: InputMaybe<Array<Address_Type_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Address_Type_Order_By>>;
  where?: InputMaybe<Address_Type_Bool_Exp>;
};

export type Subscription_RootAddress_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Address_Type_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Address_Type_Order_By>>;
  where?: InputMaybe<Address_Type_Bool_Exp>;
};

export type Subscription_RootAddress_Type_By_PkArgs = {
  adress_type: Scalars["String"];
};

export type Subscription_RootAddress_Type_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Address_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Address_Type_Bool_Exp>;
};

export type Subscription_RootCityArgs = {
  distinct_on?: InputMaybe<Array<City_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<City_Order_By>>;
  where?: InputMaybe<City_Bool_Exp>;
};

export type Subscription_RootCity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<City_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<City_Order_By>>;
  where?: InputMaybe<City_Bool_Exp>;
};

export type Subscription_RootCity_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootCity_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<City_Stream_Cursor_Input>>;
  where?: InputMaybe<City_Bool_Exp>;
};

export type Subscription_RootClientArgs = {
  distinct_on?: InputMaybe<Array<Client_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Client_Order_By>>;
  where?: InputMaybe<Client_Bool_Exp>;
};

export type Subscription_RootClient_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Client_Order_By>>;
  where?: InputMaybe<Client_Bool_Exp>;
};

export type Subscription_RootClient_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootClient_CandidateArgs = {
  distinct_on?: InputMaybe<Array<Client_Candidate_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Client_Candidate_Order_By>>;
  where?: InputMaybe<Client_Candidate_Bool_Exp>;
};

export type Subscription_RootClient_Candidate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Candidate_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Client_Candidate_Order_By>>;
  where?: InputMaybe<Client_Candidate_Bool_Exp>;
};

export type Subscription_RootClient_Candidate_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootClient_Candidate_ProjectArgs = {
  distinct_on?: InputMaybe<Array<Client_Candidate_Project_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Client_Candidate_Project_Order_By>>;
  where?: InputMaybe<Client_Candidate_Project_Bool_Exp>;
};

export type Subscription_RootClient_Candidate_Project_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Candidate_Project_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Client_Candidate_Project_Order_By>>;
  where?: InputMaybe<Client_Candidate_Project_Bool_Exp>;
};

export type Subscription_RootClient_Candidate_Project_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootClient_Candidate_Project_Candidate_List_StatusArgs =
  {
    distinct_on?: InputMaybe<
      Array<Client_Candidate_Project_Candidate_List_Status_Select_Column>
    >;
    limit?: InputMaybe<Scalars["Int"]>;
    offset?: InputMaybe<Scalars["Int"]>;
    order_by?: InputMaybe<
      Array<Client_Candidate_Project_Candidate_List_Status_Order_By>
    >;
    where?: InputMaybe<Client_Candidate_Project_Candidate_List_Status_Bool_Exp>;
  };

export type Subscription_RootClient_Candidate_Project_Candidate_List_Status_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Client_Candidate_Project_Candidate_List_Status_Select_Column>
    >;
    limit?: InputMaybe<Scalars["Int"]>;
    offset?: InputMaybe<Scalars["Int"]>;
    order_by?: InputMaybe<
      Array<Client_Candidate_Project_Candidate_List_Status_Order_By>
    >;
    where?: InputMaybe<Client_Candidate_Project_Candidate_List_Status_Bool_Exp>;
  };

export type Subscription_RootClient_Candidate_Project_Candidate_List_Status_By_PkArgs =
  {
    candidate_status: Scalars["String"];
  };

export type Subscription_RootClient_Candidate_Project_Candidate_List_Status_StreamArgs =
  {
    batch_size: Scalars["Int"];
    cursor: Array<
      InputMaybe<Client_Candidate_Project_Candidate_List_Status_Stream_Cursor_Input>
    >;
    where?: InputMaybe<Client_Candidate_Project_Candidate_List_Status_Bool_Exp>;
  };

export type Subscription_RootClient_Candidate_Project_Candidate_ResolutionArgs =
  {
    distinct_on?: InputMaybe<
      Array<Client_Candidate_Project_Candidate_Resolution_Select_Column>
    >;
    limit?: InputMaybe<Scalars["Int"]>;
    offset?: InputMaybe<Scalars["Int"]>;
    order_by?: InputMaybe<
      Array<Client_Candidate_Project_Candidate_Resolution_Order_By>
    >;
    where?: InputMaybe<Client_Candidate_Project_Candidate_Resolution_Bool_Exp>;
  };

export type Subscription_RootClient_Candidate_Project_Candidate_Resolution_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Client_Candidate_Project_Candidate_Resolution_Select_Column>
    >;
    limit?: InputMaybe<Scalars["Int"]>;
    offset?: InputMaybe<Scalars["Int"]>;
    order_by?: InputMaybe<
      Array<Client_Candidate_Project_Candidate_Resolution_Order_By>
    >;
    where?: InputMaybe<Client_Candidate_Project_Candidate_Resolution_Bool_Exp>;
  };

export type Subscription_RootClient_Candidate_Project_Candidate_Resolution_By_PkArgs =
  {
    resolution: Scalars["String"];
  };

export type Subscription_RootClient_Candidate_Project_Candidate_Resolution_StreamArgs =
  {
    batch_size: Scalars["Int"];
    cursor: Array<
      InputMaybe<Client_Candidate_Project_Candidate_Resolution_Stream_Cursor_Input>
    >;
    where?: InputMaybe<Client_Candidate_Project_Candidate_Resolution_Bool_Exp>;
  };

export type Subscription_RootClient_Candidate_Project_NoteArgs = {
  distinct_on?: InputMaybe<Array<Client_Candidate_Project_Note_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Client_Candidate_Project_Note_Order_By>>;
  where?: InputMaybe<Client_Candidate_Project_Note_Bool_Exp>;
};

export type Subscription_RootClient_Candidate_Project_Note_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Candidate_Project_Note_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Client_Candidate_Project_Note_Order_By>>;
  where?: InputMaybe<Client_Candidate_Project_Note_Bool_Exp>;
};

export type Subscription_RootClient_Candidate_Project_Note_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootClient_Candidate_Project_Note_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Client_Candidate_Project_Note_Stream_Cursor_Input>>;
  where?: InputMaybe<Client_Candidate_Project_Note_Bool_Exp>;
};

export type Subscription_RootClient_Candidate_Project_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Client_Candidate_Project_Stream_Cursor_Input>>;
  where?: InputMaybe<Client_Candidate_Project_Bool_Exp>;
};

export type Subscription_RootClient_Candidate_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Client_Candidate_Stream_Cursor_Input>>;
  where?: InputMaybe<Client_Candidate_Bool_Exp>;
};

export type Subscription_RootClient_LocationArgs = {
  distinct_on?: InputMaybe<Array<Client_Location_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Client_Location_Order_By>>;
  where?: InputMaybe<Client_Location_Bool_Exp>;
};

export type Subscription_RootClient_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Location_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Client_Location_Order_By>>;
  where?: InputMaybe<Client_Location_Bool_Exp>;
};

export type Subscription_RootClient_Location_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootClient_Location_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Client_Location_Stream_Cursor_Input>>;
  where?: InputMaybe<Client_Location_Bool_Exp>;
};

export type Subscription_RootClient_SectorArgs = {
  distinct_on?: InputMaybe<Array<Client_Sector_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Client_Sector_Order_By>>;
  where?: InputMaybe<Client_Sector_Bool_Exp>;
};

export type Subscription_RootClient_Sector_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Sector_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Client_Sector_Order_By>>;
  where?: InputMaybe<Client_Sector_Bool_Exp>;
};

export type Subscription_RootClient_Sector_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootClient_Sector_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Client_Sector_Stream_Cursor_Input>>;
  where?: InputMaybe<Client_Sector_Bool_Exp>;
};

export type Subscription_RootClient_SettingArgs = {
  distinct_on?: InputMaybe<Array<Client_Setting_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Client_Setting_Order_By>>;
  where?: InputMaybe<Client_Setting_Bool_Exp>;
};

export type Subscription_RootClient_Setting_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Setting_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Client_Setting_Order_By>>;
  where?: InputMaybe<Client_Setting_Bool_Exp>;
};

export type Subscription_RootClient_Setting_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootClient_Setting_KeyArgs = {
  distinct_on?: InputMaybe<Array<Client_Setting_Key_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Client_Setting_Key_Order_By>>;
  where?: InputMaybe<Client_Setting_Key_Bool_Exp>;
};

export type Subscription_RootClient_Setting_Key_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Setting_Key_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Client_Setting_Key_Order_By>>;
  where?: InputMaybe<Client_Setting_Key_Bool_Exp>;
};

export type Subscription_RootClient_Setting_Key_By_PkArgs = {
  key: Scalars["String"];
};

export type Subscription_RootClient_Setting_Key_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Client_Setting_Key_Stream_Cursor_Input>>;
  where?: InputMaybe<Client_Setting_Key_Bool_Exp>;
};

export type Subscription_RootClient_Setting_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Client_Setting_Stream_Cursor_Input>>;
  where?: InputMaybe<Client_Setting_Bool_Exp>;
};

export type Subscription_RootClient_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Client_Stream_Cursor_Input>>;
  where?: InputMaybe<Client_Bool_Exp>;
};

export type Subscription_RootCompanyArgs = {
  distinct_on?: InputMaybe<Array<Company_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Company_Order_By>>;
  where?: InputMaybe<Company_Bool_Exp>;
};

export type Subscription_RootCompany_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Company_Order_By>>;
  where?: InputMaybe<Company_Bool_Exp>;
};

export type Subscription_RootCompany_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootCompany_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Company_Stream_Cursor_Input>>;
  where?: InputMaybe<Company_Bool_Exp>;
};

export type Subscription_RootCompany_SubscriptionArgs = {
  distinct_on?: InputMaybe<Array<Company_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Company_Subscription_Order_By>>;
  where?: InputMaybe<Company_Subscription_Bool_Exp>;
};

export type Subscription_RootCompany_Subscription_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Company_Subscription_Order_By>>;
  where?: InputMaybe<Company_Subscription_Bool_Exp>;
};

export type Subscription_RootCompany_Subscription_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootCompany_Subscription_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Company_Subscription_Stream_Cursor_Input>>;
  where?: InputMaybe<Company_Subscription_Bool_Exp>;
};

export type Subscription_RootConsentArgs = {
  distinct_on?: InputMaybe<Array<Consent_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Consent_Order_By>>;
  where?: InputMaybe<Consent_Bool_Exp>;
};

export type Subscription_RootConsent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Consent_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Consent_Order_By>>;
  where?: InputMaybe<Consent_Bool_Exp>;
};

export type Subscription_RootConsent_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootConsent_StatusArgs = {
  distinct_on?: InputMaybe<Array<Consent_Status_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Consent_Status_Order_By>>;
  where?: InputMaybe<Consent_Status_Bool_Exp>;
};

export type Subscription_RootConsent_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Consent_Status_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Consent_Status_Order_By>>;
  where?: InputMaybe<Consent_Status_Bool_Exp>;
};

export type Subscription_RootConsent_Status_By_PkArgs = {
  consent_status: Scalars["String"];
};

export type Subscription_RootConsent_Status_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Consent_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Consent_Status_Bool_Exp>;
};

export type Subscription_RootConsent_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Consent_Stream_Cursor_Input>>;
  where?: InputMaybe<Consent_Bool_Exp>;
};

export type Subscription_RootConsent_TypeArgs = {
  distinct_on?: InputMaybe<Array<Consent_Type_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Consent_Type_Order_By>>;
  where?: InputMaybe<Consent_Type_Bool_Exp>;
};

export type Subscription_RootConsent_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Consent_Type_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Consent_Type_Order_By>>;
  where?: InputMaybe<Consent_Type_Bool_Exp>;
};

export type Subscription_RootConsent_Type_By_PkArgs = {
  consent_type: Scalars["String"];
};

export type Subscription_RootConsent_Type_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Consent_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Consent_Type_Bool_Exp>;
};

export type Subscription_RootCountryArgs = {
  distinct_on?: InputMaybe<Array<Country_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Country_Order_By>>;
  where?: InputMaybe<Country_Bool_Exp>;
};

export type Subscription_RootCountry_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Country_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Country_Order_By>>;
  where?: InputMaybe<Country_Bool_Exp>;
};

export type Subscription_RootCountry_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootCountry_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Country_Stream_Cursor_Input>>;
  where?: InputMaybe<Country_Bool_Exp>;
};

export type Subscription_RootCurrencyArgs = {
  distinct_on?: InputMaybe<Array<Currency_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Currency_Order_By>>;
  where?: InputMaybe<Currency_Bool_Exp>;
};

export type Subscription_RootCurrency_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Currency_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Currency_Order_By>>;
  where?: InputMaybe<Currency_Bool_Exp>;
};

export type Subscription_RootCurrency_By_PkArgs = {
  currency: Scalars["String"];
};

export type Subscription_RootCurrency_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Currency_Stream_Cursor_Input>>;
  where?: InputMaybe<Currency_Bool_Exp>;
};

export type Subscription_RootDepartmentArgs = {
  distinct_on?: InputMaybe<Array<Department_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Department_Order_By>>;
  where?: InputMaybe<Department_Bool_Exp>;
};

export type Subscription_RootDepartment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Department_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Department_Order_By>>;
  where?: InputMaybe<Department_Bool_Exp>;
};

export type Subscription_RootDepartment_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootDepartment_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Department_Stream_Cursor_Input>>;
  where?: InputMaybe<Department_Bool_Exp>;
};

export type Subscription_RootEducation_TypeArgs = {
  distinct_on?: InputMaybe<Array<Education_Type_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Education_Type_Order_By>>;
  where?: InputMaybe<Education_Type_Bool_Exp>;
};

export type Subscription_RootEducation_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Education_Type_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Education_Type_Order_By>>;
  where?: InputMaybe<Education_Type_Bool_Exp>;
};

export type Subscription_RootEducation_Type_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootEducation_Type_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Education_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Education_Type_Bool_Exp>;
};

export type Subscription_RootInterview_CriterionArgs = {
  distinct_on?: InputMaybe<Array<Interview_Criterion_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Interview_Criterion_Order_By>>;
  where?: InputMaybe<Interview_Criterion_Bool_Exp>;
};

export type Subscription_RootInterview_Criterion_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Interview_Criterion_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Interview_Criterion_Order_By>>;
  where?: InputMaybe<Interview_Criterion_Bool_Exp>;
};

export type Subscription_RootInterview_Criterion_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootInterview_Criterion_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Interview_Criterion_Stream_Cursor_Input>>;
  where?: InputMaybe<Interview_Criterion_Bool_Exp>;
};

export type Subscription_RootInterview_TemplateArgs = {
  distinct_on?: InputMaybe<Array<Interview_Template_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Interview_Template_Order_By>>;
  where?: InputMaybe<Interview_Template_Bool_Exp>;
};

export type Subscription_RootInterview_Template_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Interview_Template_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Interview_Template_Order_By>>;
  where?: InputMaybe<Interview_Template_Bool_Exp>;
};

export type Subscription_RootInterview_Template_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootInterview_Template_Review_Metric_QuestionArgs = {
  distinct_on?: InputMaybe<
    Array<Interview_Template_Review_Metric_Question_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<
    Array<Interview_Template_Review_Metric_Question_Order_By>
  >;
  where?: InputMaybe<Interview_Template_Review_Metric_Question_Bool_Exp>;
};

export type Subscription_RootInterview_Template_Review_Metric_Question_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Interview_Template_Review_Metric_Question_Select_Column>
    >;
    limit?: InputMaybe<Scalars["Int"]>;
    offset?: InputMaybe<Scalars["Int"]>;
    order_by?: InputMaybe<
      Array<Interview_Template_Review_Metric_Question_Order_By>
    >;
    where?: InputMaybe<Interview_Template_Review_Metric_Question_Bool_Exp>;
  };

export type Subscription_RootInterview_Template_Review_Metric_Question_By_PkArgs =
  {
    id: Scalars["bigint"];
  };

export type Subscription_RootInterview_Template_Review_Metric_Question_StreamArgs =
  {
    batch_size: Scalars["Int"];
    cursor: Array<
      InputMaybe<Interview_Template_Review_Metric_Question_Stream_Cursor_Input>
    >;
    where?: InputMaybe<Interview_Template_Review_Metric_Question_Bool_Exp>;
  };

export type Subscription_RootInterview_Template_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Interview_Template_Stream_Cursor_Input>>;
  where?: InputMaybe<Interview_Template_Bool_Exp>;
};

export type Subscription_RootJob_DescriptionArgs = {
  distinct_on?: InputMaybe<Array<Job_Description_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Job_Description_Order_By>>;
  where?: InputMaybe<Job_Description_Bool_Exp>;
};

export type Subscription_RootJob_Description_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Description_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Job_Description_Order_By>>;
  where?: InputMaybe<Job_Description_Bool_Exp>;
};

export type Subscription_RootJob_Description_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootJob_Description_StepArgs = {
  distinct_on?: InputMaybe<Array<Job_Description_Step_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Job_Description_Step_Order_By>>;
  where?: InputMaybe<Job_Description_Step_Bool_Exp>;
};

export type Subscription_RootJob_Description_Step_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Description_Step_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Job_Description_Step_Order_By>>;
  where?: InputMaybe<Job_Description_Step_Bool_Exp>;
};

export type Subscription_RootJob_Description_Step_By_PkArgs = {
  step: Scalars["String"];
};

export type Subscription_RootJob_Description_Step_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Job_Description_Step_Stream_Cursor_Input>>;
  where?: InputMaybe<Job_Description_Step_Bool_Exp>;
};

export type Subscription_RootJob_Description_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Job_Description_Stream_Cursor_Input>>;
  where?: InputMaybe<Job_Description_Bool_Exp>;
};

export type Subscription_RootLanguageArgs = {
  distinct_on?: InputMaybe<Array<Language_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Language_Order_By>>;
  where?: InputMaybe<Language_Bool_Exp>;
};

export type Subscription_RootLanguage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Language_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Language_Order_By>>;
  where?: InputMaybe<Language_Bool_Exp>;
};

export type Subscription_RootLanguage_By_PkArgs = {
  language: Scalars["String"];
};

export type Subscription_RootLanguage_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Language_Stream_Cursor_Input>>;
  where?: InputMaybe<Language_Bool_Exp>;
};

export type Subscription_RootPartnerArgs = {
  distinct_on?: InputMaybe<Array<Partner_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Partner_Order_By>>;
  where?: InputMaybe<Partner_Bool_Exp>;
};

export type Subscription_RootPartner_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Partner_Order_By>>;
  where?: InputMaybe<Partner_Bool_Exp>;
};

export type Subscription_RootPartner_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootPartner_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Partner_Stream_Cursor_Input>>;
  where?: InputMaybe<Partner_Bool_Exp>;
};

export type Subscription_RootProductArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};

export type Subscription_RootProduct_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};

export type Subscription_RootProduct_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootProduct_PriceArgs = {
  distinct_on?: InputMaybe<Array<Product_Price_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Price_Order_By>>;
  where?: InputMaybe<Product_Price_Bool_Exp>;
};

export type Subscription_RootProduct_Price_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Price_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Product_Price_Order_By>>;
  where?: InputMaybe<Product_Price_Bool_Exp>;
};

export type Subscription_RootProduct_Price_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootProduct_Price_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Product_Price_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Price_Bool_Exp>;
};

export type Subscription_RootProduct_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Product_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Bool_Exp>;
};

export type Subscription_RootProjectArgs = {
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Project_Order_By>>;
  where?: InputMaybe<Project_Bool_Exp>;
};

export type Subscription_RootProject_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Project_Order_By>>;
  where?: InputMaybe<Project_Bool_Exp>;
};

export type Subscription_RootProject_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootProject_StatusArgs = {
  distinct_on?: InputMaybe<Array<Project_Status_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Project_Status_Order_By>>;
  where?: InputMaybe<Project_Status_Bool_Exp>;
};

export type Subscription_RootProject_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Status_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Project_Status_Order_By>>;
  where?: InputMaybe<Project_Status_Bool_Exp>;
};

export type Subscription_RootProject_Status_By_PkArgs = {
  status: Scalars["String"];
};

export type Subscription_RootProject_Status_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Project_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Project_Status_Bool_Exp>;
};

export type Subscription_RootProject_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Project_Stream_Cursor_Input>>;
  where?: InputMaybe<Project_Bool_Exp>;
};

export type Subscription_RootProject_UserArgs = {
  distinct_on?: InputMaybe<Array<Project_User_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Project_User_Order_By>>;
  where?: InputMaybe<Project_User_Bool_Exp>;
};

export type Subscription_RootProject_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_User_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Project_User_Order_By>>;
  where?: InputMaybe<Project_User_Bool_Exp>;
};

export type Subscription_RootProject_User_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootProject_User_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Project_User_Stream_Cursor_Input>>;
  where?: InputMaybe<Project_User_Bool_Exp>;
};

export type Subscription_RootReview_MetricArgs = {
  distinct_on?: InputMaybe<Array<Review_Metric_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Review_Metric_Order_By>>;
  where?: InputMaybe<Review_Metric_Bool_Exp>;
};

export type Subscription_RootReview_Metric_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Review_Metric_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Review_Metric_Order_By>>;
  where?: InputMaybe<Review_Metric_Bool_Exp>;
};

export type Subscription_RootReview_Metric_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootReview_Metric_QuestionArgs = {
  distinct_on?: InputMaybe<Array<Review_Metric_Question_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Review_Metric_Question_Order_By>>;
  where?: InputMaybe<Review_Metric_Question_Bool_Exp>;
};

export type Subscription_RootReview_Metric_Question_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Review_Metric_Question_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Review_Metric_Question_Order_By>>;
  where?: InputMaybe<Review_Metric_Question_Bool_Exp>;
};

export type Subscription_RootReview_Metric_Question_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootReview_Metric_Question_CriterionArgs = {
  distinct_on?: InputMaybe<
    Array<Review_Metric_Question_Criterion_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Review_Metric_Question_Criterion_Order_By>>;
  where?: InputMaybe<Review_Metric_Question_Criterion_Bool_Exp>;
};

export type Subscription_RootReview_Metric_Question_Criterion_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Review_Metric_Question_Criterion_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Review_Metric_Question_Criterion_Order_By>>;
  where?: InputMaybe<Review_Metric_Question_Criterion_Bool_Exp>;
};

export type Subscription_RootReview_Metric_Question_Criterion_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootReview_Metric_Question_Criterion_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<
    InputMaybe<Review_Metric_Question_Criterion_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Review_Metric_Question_Criterion_Bool_Exp>;
};

export type Subscription_RootReview_Metric_Question_ResponseArgs = {
  distinct_on?: InputMaybe<
    Array<Review_Metric_Question_Response_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Review_Metric_Question_Response_Order_By>>;
  where?: InputMaybe<Review_Metric_Question_Response_Bool_Exp>;
};

export type Subscription_RootReview_Metric_Question_Response_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Review_Metric_Question_Response_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Review_Metric_Question_Response_Order_By>>;
  where?: InputMaybe<Review_Metric_Question_Response_Bool_Exp>;
};

export type Subscription_RootReview_Metric_Question_Response_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootReview_Metric_Question_Response_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<
    InputMaybe<Review_Metric_Question_Response_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Review_Metric_Question_Response_Bool_Exp>;
};

export type Subscription_RootReview_Metric_Question_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Review_Metric_Question_Stream_Cursor_Input>>;
  where?: InputMaybe<Review_Metric_Question_Bool_Exp>;
};

export type Subscription_RootReview_Metric_Question_TypeArgs = {
  distinct_on?: InputMaybe<Array<Review_Metric_Question_Type_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Review_Metric_Question_Type_Order_By>>;
  where?: InputMaybe<Review_Metric_Question_Type_Bool_Exp>;
};

export type Subscription_RootReview_Metric_Question_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Review_Metric_Question_Type_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Review_Metric_Question_Type_Order_By>>;
  where?: InputMaybe<Review_Metric_Question_Type_Bool_Exp>;
};

export type Subscription_RootReview_Metric_Question_Type_By_PkArgs = {
  question_type: Scalars["String"];
};

export type Subscription_RootReview_Metric_Question_Type_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Review_Metric_Question_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Review_Metric_Question_Type_Bool_Exp>;
};

export type Subscription_RootReview_Metric_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Review_Metric_Stream_Cursor_Input>>;
  where?: InputMaybe<Review_Metric_Bool_Exp>;
};

export type Subscription_RootRoleArgs = {
  distinct_on?: InputMaybe<Array<Role_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Order_By>>;
  where?: InputMaybe<Role_Bool_Exp>;
};

export type Subscription_RootRole_Academic_DepartmentArgs = {
  distinct_on?: InputMaybe<Array<Role_Academic_Department_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Academic_Department_Order_By>>;
  where?: InputMaybe<Role_Academic_Department_Bool_Exp>;
};

export type Subscription_RootRole_Academic_Department_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Academic_Department_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Academic_Department_Order_By>>;
  where?: InputMaybe<Role_Academic_Department_Bool_Exp>;
};

export type Subscription_RootRole_Academic_Department_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootRole_Academic_Department_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Role_Academic_Department_Stream_Cursor_Input>>;
  where?: InputMaybe<Role_Academic_Department_Bool_Exp>;
};

export type Subscription_RootRole_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Order_By>>;
  where?: InputMaybe<Role_Bool_Exp>;
};

export type Subscription_RootRole_BenefitArgs = {
  distinct_on?: InputMaybe<Array<Role_Benefit_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Benefit_Order_By>>;
  where?: InputMaybe<Role_Benefit_Bool_Exp>;
};

export type Subscription_RootRole_Benefit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Benefit_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Benefit_Order_By>>;
  where?: InputMaybe<Role_Benefit_Bool_Exp>;
};

export type Subscription_RootRole_Benefit_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootRole_Benefit_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Role_Benefit_Stream_Cursor_Input>>;
  where?: InputMaybe<Role_Benefit_Bool_Exp>;
};

export type Subscription_RootRole_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootRole_CompetenceArgs = {
  distinct_on?: InputMaybe<Array<Role_Competence_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Competence_Order_By>>;
  where?: InputMaybe<Role_Competence_Bool_Exp>;
};

export type Subscription_RootRole_Competence_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Competence_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Competence_Order_By>>;
  where?: InputMaybe<Role_Competence_Bool_Exp>;
};

export type Subscription_RootRole_Competence_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootRole_Competence_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Role_Competence_Stream_Cursor_Input>>;
  where?: InputMaybe<Role_Competence_Bool_Exp>;
};

export type Subscription_RootRole_DescriptionArgs = {
  distinct_on?: InputMaybe<Array<Role_Description_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Description_Order_By>>;
  where?: InputMaybe<Role_Description_Bool_Exp>;
};

export type Subscription_RootRole_Description_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Description_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Description_Order_By>>;
  where?: InputMaybe<Role_Description_Bool_Exp>;
};

export type Subscription_RootRole_Description_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootRole_Description_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Role_Description_Stream_Cursor_Input>>;
  where?: InputMaybe<Role_Description_Bool_Exp>;
};

export type Subscription_RootRole_ResponsibilityArgs = {
  distinct_on?: InputMaybe<Array<Role_Responsibility_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Responsibility_Order_By>>;
  where?: InputMaybe<Role_Responsibility_Bool_Exp>;
};

export type Subscription_RootRole_Responsibility_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Responsibility_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Responsibility_Order_By>>;
  where?: InputMaybe<Role_Responsibility_Bool_Exp>;
};

export type Subscription_RootRole_Responsibility_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootRole_Responsibility_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Role_Responsibility_Stream_Cursor_Input>>;
  where?: InputMaybe<Role_Responsibility_Bool_Exp>;
};

export type Subscription_RootRole_Review_MetricArgs = {
  distinct_on?: InputMaybe<Array<Role_Review_Metric_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Review_Metric_Order_By>>;
  where?: InputMaybe<Role_Review_Metric_Bool_Exp>;
};

export type Subscription_RootRole_Review_Metric_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Review_Metric_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Review_Metric_Order_By>>;
  where?: InputMaybe<Role_Review_Metric_Bool_Exp>;
};

export type Subscription_RootRole_Review_Metric_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootRole_Review_Metric_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Role_Review_Metric_Stream_Cursor_Input>>;
  where?: InputMaybe<Role_Review_Metric_Bool_Exp>;
};

export type Subscription_RootRole_SkillArgs = {
  distinct_on?: InputMaybe<Array<Role_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Skill_Order_By>>;
  where?: InputMaybe<Role_Skill_Bool_Exp>;
};

export type Subscription_RootRole_Skill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Skill_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Role_Skill_Order_By>>;
  where?: InputMaybe<Role_Skill_Bool_Exp>;
};

export type Subscription_RootRole_Skill_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootRole_Skill_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Role_Skill_Stream_Cursor_Input>>;
  where?: InputMaybe<Role_Skill_Bool_Exp>;
};

export type Subscription_RootRole_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Role_Stream_Cursor_Input>>;
  where?: InputMaybe<Role_Bool_Exp>;
};

export type Subscription_RootSectorArgs = {
  distinct_on?: InputMaybe<Array<Sector_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Sector_Order_By>>;
  where?: InputMaybe<Sector_Bool_Exp>;
};

export type Subscription_RootSector_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sector_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Sector_Order_By>>;
  where?: InputMaybe<Sector_Bool_Exp>;
};

export type Subscription_RootSector_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootSector_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Sector_Stream_Cursor_Input>>;
  where?: InputMaybe<Sector_Bool_Exp>;
};

export type Subscription_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};

export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};

export type Subscription_RootUser_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootUser_KeyArgs = {
  distinct_on?: InputMaybe<Array<User_Key_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Key_Order_By>>;
  where?: InputMaybe<User_Key_Bool_Exp>;
};

export type Subscription_RootUser_Key_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Key_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Key_Order_By>>;
  where?: InputMaybe<User_Key_Bool_Exp>;
};

export type Subscription_RootUser_Key_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootUser_Key_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<User_Key_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Key_Bool_Exp>;
};

export type Subscription_RootUser_RoleArgs = {
  distinct_on?: InputMaybe<Array<User_Role_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Role_Order_By>>;
  where?: InputMaybe<User_Role_Bool_Exp>;
};

export type Subscription_RootUser_Role_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Role_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Role_Order_By>>;
  where?: InputMaybe<User_Role_Bool_Exp>;
};

export type Subscription_RootUser_Role_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootUser_Role_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<User_Role_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Role_Bool_Exp>;
};

export type Subscription_RootUser_SettingArgs = {
  distinct_on?: InputMaybe<Array<User_Setting_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Setting_Order_By>>;
  where?: InputMaybe<User_Setting_Bool_Exp>;
};

export type Subscription_RootUser_Setting_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Setting_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Setting_Order_By>>;
  where?: InputMaybe<User_Setting_Bool_Exp>;
};

export type Subscription_RootUser_Setting_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootUser_Setting_KeyArgs = {
  distinct_on?: InputMaybe<Array<User_Setting_Key_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Setting_Key_Order_By>>;
  where?: InputMaybe<User_Setting_Key_Bool_Exp>;
};

export type Subscription_RootUser_Setting_Key_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Setting_Key_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Setting_Key_Order_By>>;
  where?: InputMaybe<User_Setting_Key_Bool_Exp>;
};

export type Subscription_RootUser_Setting_Key_By_PkArgs = {
  key: Scalars["String"];
};

export type Subscription_RootUser_Setting_Key_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<User_Setting_Key_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Setting_Key_Bool_Exp>;
};

export type Subscription_RootUser_Setting_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<User_Setting_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Setting_Bool_Exp>;
};

export type Subscription_RootUser_StatusArgs = {
  distinct_on?: InputMaybe<Array<User_Status_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Status_Order_By>>;
  where?: InputMaybe<User_Status_Bool_Exp>;
};

export type Subscription_RootUser_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Status_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Status_Order_By>>;
  where?: InputMaybe<User_Status_Bool_Exp>;
};

export type Subscription_RootUser_Status_By_PkArgs = {
  status: Scalars["String"];
};

export type Subscription_RootUser_Status_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<User_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Status_Bool_Exp>;
};

export type Subscription_RootUser_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<User_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Bool_Exp>;
};

export type Subscription_RootVideo_InterviewArgs = {
  distinct_on?: InputMaybe<Array<Video_Interview_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Order_By>>;
  where?: InputMaybe<Video_Interview_Bool_Exp>;
};

export type Subscription_RootVideo_Interview_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Video_Interview_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Order_By>>;
  where?: InputMaybe<Video_Interview_Bool_Exp>;
};

export type Subscription_RootVideo_Interview_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootVideo_Interview_Criterion_ScoreArgs = {
  distinct_on?: InputMaybe<
    Array<Video_Interview_Criterion_Score_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Criterion_Score_Order_By>>;
  where?: InputMaybe<Video_Interview_Criterion_Score_Bool_Exp>;
};

export type Subscription_RootVideo_Interview_Criterion_Score_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Video_Interview_Criterion_Score_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Criterion_Score_Order_By>>;
  where?: InputMaybe<Video_Interview_Criterion_Score_Bool_Exp>;
};

export type Subscription_RootVideo_Interview_Criterion_Score_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootVideo_Interview_Criterion_Score_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<
    InputMaybe<Video_Interview_Criterion_Score_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Video_Interview_Criterion_Score_Bool_Exp>;
};

export type Subscription_RootVideo_Interview_QuestionArgs = {
  distinct_on?: InputMaybe<Array<Video_Interview_Question_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Question_Order_By>>;
  where?: InputMaybe<Video_Interview_Question_Bool_Exp>;
};

export type Subscription_RootVideo_Interview_Question_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Video_Interview_Question_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Question_Order_By>>;
  where?: InputMaybe<Video_Interview_Question_Bool_Exp>;
};

export type Subscription_RootVideo_Interview_Question_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootVideo_Interview_Question_EventArgs = {
  distinct_on?: InputMaybe<Array<Video_Interview_Question_Event_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Question_Event_Order_By>>;
  where?: InputMaybe<Video_Interview_Question_Event_Bool_Exp>;
};

export type Subscription_RootVideo_Interview_Question_Event_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Video_Interview_Question_Event_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Question_Event_Order_By>>;
  where?: InputMaybe<Video_Interview_Question_Event_Bool_Exp>;
};

export type Subscription_RootVideo_Interview_Question_Event_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootVideo_Interview_Question_Event_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Video_Interview_Question_Event_Stream_Cursor_Input>>;
  where?: InputMaybe<Video_Interview_Question_Event_Bool_Exp>;
};

export type Subscription_RootVideo_Interview_Question_Event_TypeArgs = {
  distinct_on?: InputMaybe<
    Array<Video_Interview_Question_Event_Type_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Question_Event_Type_Order_By>>;
  where?: InputMaybe<Video_Interview_Question_Event_Type_Bool_Exp>;
};

export type Subscription_RootVideo_Interview_Question_Event_Type_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Video_Interview_Question_Event_Type_Select_Column>
    >;
    limit?: InputMaybe<Scalars["Int"]>;
    offset?: InputMaybe<Scalars["Int"]>;
    order_by?: InputMaybe<Array<Video_Interview_Question_Event_Type_Order_By>>;
    where?: InputMaybe<Video_Interview_Question_Event_Type_Bool_Exp>;
  };

export type Subscription_RootVideo_Interview_Question_Event_Type_By_PkArgs = {
  type: Scalars["String"];
};

export type Subscription_RootVideo_Interview_Question_Event_Type_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<
    InputMaybe<Video_Interview_Question_Event_Type_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Video_Interview_Question_Event_Type_Bool_Exp>;
};

export type Subscription_RootVideo_Interview_Question_ScoreArgs = {
  distinct_on?: InputMaybe<Array<Video_Interview_Question_Score_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Question_Score_Order_By>>;
  where?: InputMaybe<Video_Interview_Question_Score_Bool_Exp>;
};

export type Subscription_RootVideo_Interview_Question_Score_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Video_Interview_Question_Score_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Question_Score_Order_By>>;
  where?: InputMaybe<Video_Interview_Question_Score_Bool_Exp>;
};

export type Subscription_RootVideo_Interview_Question_Score_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootVideo_Interview_Question_Score_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Video_Interview_Question_Score_Stream_Cursor_Input>>;
  where?: InputMaybe<Video_Interview_Question_Score_Bool_Exp>;
};

export type Subscription_RootVideo_Interview_Question_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Video_Interview_Question_Stream_Cursor_Input>>;
  where?: InputMaybe<Video_Interview_Question_Bool_Exp>;
};

export type Subscription_RootVideo_Interview_ReviewerArgs = {
  distinct_on?: InputMaybe<Array<Video_Interview_Reviewer_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Reviewer_Order_By>>;
  where?: InputMaybe<Video_Interview_Reviewer_Bool_Exp>;
};

export type Subscription_RootVideo_Interview_Reviewer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Video_Interview_Reviewer_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Reviewer_Order_By>>;
  where?: InputMaybe<Video_Interview_Reviewer_Bool_Exp>;
};

export type Subscription_RootVideo_Interview_Reviewer_By_PkArgs = {
  reviewer: Scalars["String"];
};

export type Subscription_RootVideo_Interview_Reviewer_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Video_Interview_Reviewer_Stream_Cursor_Input>>;
  where?: InputMaybe<Video_Interview_Reviewer_Bool_Exp>;
};

export type Subscription_RootVideo_Interview_StatusArgs = {
  distinct_on?: InputMaybe<Array<Video_Interview_Status_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Status_Order_By>>;
  where?: InputMaybe<Video_Interview_Status_Bool_Exp>;
};

export type Subscription_RootVideo_Interview_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Video_Interview_Status_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Status_Order_By>>;
  where?: InputMaybe<Video_Interview_Status_Bool_Exp>;
};

export type Subscription_RootVideo_Interview_Status_By_PkArgs = {
  status: Scalars["String"];
};

export type Subscription_RootVideo_Interview_Status_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Video_Interview_Status_History_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Status_History_Order_By>>;
  where?: InputMaybe<Video_Interview_Status_History_Bool_Exp>;
};

export type Subscription_RootVideo_Interview_Status_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Video_Interview_Status_History_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Status_History_Order_By>>;
  where?: InputMaybe<Video_Interview_Status_History_Bool_Exp>;
};

export type Subscription_RootVideo_Interview_Status_History_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootVideo_Interview_Status_History_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Video_Interview_Status_History_Stream_Cursor_Input>>;
  where?: InputMaybe<Video_Interview_Status_History_Bool_Exp>;
};

export type Subscription_RootVideo_Interview_Status_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Video_Interview_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Video_Interview_Status_Bool_Exp>;
};

export type Subscription_RootVideo_Interview_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Video_Interview_Stream_Cursor_Input>>;
  where?: InputMaybe<Video_Interview_Bool_Exp>;
};

export type Subscription_RootVideo_Interview_TypeArgs = {
  distinct_on?: InputMaybe<Array<Video_Interview_Type_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Type_Order_By>>;
  where?: InputMaybe<Video_Interview_Type_Bool_Exp>;
};

export type Subscription_RootVideo_Interview_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Video_Interview_Type_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Type_Order_By>>;
  where?: InputMaybe<Video_Interview_Type_Bool_Exp>;
};

export type Subscription_RootVideo_Interview_Type_By_PkArgs = {
  video_interview_type: Scalars["String"];
};

export type Subscription_RootVideo_Interview_Type_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Video_Interview_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Video_Interview_Type_Bool_Exp>;
};

export type Subscription_RootVideo_Process_StatusArgs = {
  distinct_on?: InputMaybe<Array<Video_Process_Status_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Process_Status_Order_By>>;
  where?: InputMaybe<Video_Process_Status_Bool_Exp>;
};

export type Subscription_RootVideo_Process_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Video_Process_Status_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Process_Status_Order_By>>;
  where?: InputMaybe<Video_Process_Status_Bool_Exp>;
};

export type Subscription_RootVideo_Process_Status_By_PkArgs = {
  status: Scalars["String"];
};

export type Subscription_RootVideo_Process_Status_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Video_Process_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Video_Process_Status_Bool_Exp>;
};

export type Subscription_RootView__Client_Candidate_ProjectsArgs = {
  distinct_on?: InputMaybe<
    Array<View__Client_Candidate_Projects_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<View__Client_Candidate_Projects_Order_By>>;
  where?: InputMaybe<View__Client_Candidate_Projects_Bool_Exp>;
};

export type Subscription_RootView__Client_Candidate_Projects_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<View__Client_Candidate_Projects_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<View__Client_Candidate_Projects_Order_By>>;
  where?: InputMaybe<View__Client_Candidate_Projects_Bool_Exp>;
};

export type Subscription_RootView__Client_Candidate_Projects_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<
    InputMaybe<View__Client_Candidate_Projects_Stream_Cursor_Input>
  >;
  where?: InputMaybe<View__Client_Candidate_Projects_Bool_Exp>;
};

export type Subscription_RootView__Ordered_Video_Interview_QuestionArgs = {
  distinct_on?: InputMaybe<
    Array<View__Ordered_Video_Interview_Question_Select_Column>
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<View__Ordered_Video_Interview_Question_Order_By>>;
  where?: InputMaybe<View__Ordered_Video_Interview_Question_Bool_Exp>;
};

export type Subscription_RootView__Ordered_Video_Interview_Question_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<View__Ordered_Video_Interview_Question_Select_Column>
    >;
    limit?: InputMaybe<Scalars["Int"]>;
    offset?: InputMaybe<Scalars["Int"]>;
    order_by?: InputMaybe<
      Array<View__Ordered_Video_Interview_Question_Order_By>
    >;
    where?: InputMaybe<View__Ordered_Video_Interview_Question_Bool_Exp>;
  };

export type Subscription_RootView__Ordered_Video_Interview_Question_StreamArgs =
  {
    batch_size: Scalars["Int"];
    cursor: Array<
      InputMaybe<View__Ordered_Video_Interview_Question_Stream_Cursor_Input>
    >;
    where?: InputMaybe<View__Ordered_Video_Interview_Question_Bool_Exp>;
  };

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["timestamp"]>;
  _gt?: InputMaybe<Scalars["timestamp"]>;
  _gte?: InputMaybe<Scalars["timestamp"]>;
  _in?: InputMaybe<Array<Scalars["timestamp"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["timestamp"]>;
  _lte?: InputMaybe<Scalars["timestamp"]>;
  _neq?: InputMaybe<Scalars["timestamp"]>;
  _nin?: InputMaybe<Array<Scalars["timestamp"]>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["timestamptz"]>;
  _gt?: InputMaybe<Scalars["timestamptz"]>;
  _gte?: InputMaybe<Scalars["timestamptz"]>;
  _in?: InputMaybe<Array<Scalars["timestamptz"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["timestamptz"]>;
  _lte?: InputMaybe<Scalars["timestamptz"]>;
  _neq?: InputMaybe<Scalars["timestamptz"]>;
  _nin?: InputMaybe<Array<Scalars["timestamptz"]>>;
};

/** columns and relationships of "user" */
export type User = {
  __typename?: "user";
  avatar?: Maybe<Scalars["String"]>;
  /** An object relationship */
  company: Company;
  company_id: Scalars["uuid"];
  created_at?: Maybe<Scalars["timestamptz"]>;
  email: Scalars["String"];
  id: Scalars["uuid"];
  invitation_token?: Maybe<Scalars["String"]>;
  is_active: Scalars["Boolean"];
  is_phone_verified?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  recovery_token?: Maybe<Scalars["String"]>;
  status?: Maybe<User_Status_Enum>;
  surname: Scalars["String"];
  title?: Maybe<Scalars["String"]>;
  /** An array relationship */
  user_project: Array<Project_User>;
  /** An aggregate relationship */
  user_project_aggregate: Project_User_Aggregate;
  /** An array relationship */
  user_roles: Array<User_Role>;
  /** An aggregate relationship */
  user_roles_aggregate: User_Role_Aggregate;
  /** An array relationship */
  user_settings: Array<User_Setting>;
  /** An aggregate relationship */
  user_settings_aggregate: User_Setting_Aggregate;
};

/** columns and relationships of "user" */
export type UserUser_ProjectArgs = {
  distinct_on?: InputMaybe<Array<Project_User_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Project_User_Order_By>>;
  where?: InputMaybe<Project_User_Bool_Exp>;
};

/** columns and relationships of "user" */
export type UserUser_Project_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_User_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Project_User_Order_By>>;
  where?: InputMaybe<Project_User_Bool_Exp>;
};

/** columns and relationships of "user" */
export type UserUser_RolesArgs = {
  distinct_on?: InputMaybe<Array<User_Role_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Role_Order_By>>;
  where?: InputMaybe<User_Role_Bool_Exp>;
};

/** columns and relationships of "user" */
export type UserUser_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Role_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Role_Order_By>>;
  where?: InputMaybe<User_Role_Bool_Exp>;
};

/** columns and relationships of "user" */
export type UserUser_SettingsArgs = {
  distinct_on?: InputMaybe<Array<User_Setting_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Setting_Order_By>>;
  where?: InputMaybe<User_Setting_Bool_Exp>;
};

/** columns and relationships of "user" */
export type UserUser_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Setting_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<User_Setting_Order_By>>;
  where?: InputMaybe<User_Setting_Bool_Exp>;
};

/** aggregated selection of "user" */
export type User_Aggregate = {
  __typename?: "user_aggregate";
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  __typename?: "user_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
};

/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: InputMaybe<Array<User_Bool_Exp>>;
  _not?: InputMaybe<User_Bool_Exp>;
  _or?: InputMaybe<Array<User_Bool_Exp>>;
  avatar?: InputMaybe<String_Comparison_Exp>;
  company?: InputMaybe<Company_Bool_Exp>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invitation_token?: InputMaybe<String_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  is_phone_verified?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  password?: InputMaybe<String_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  recovery_token?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<User_Status_Enum_Comparison_Exp>;
  surname?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  user_project?: InputMaybe<Project_User_Bool_Exp>;
  user_project_aggregate?: InputMaybe<Project_User_Aggregate_Bool_Exp>;
  user_roles?: InputMaybe<User_Role_Bool_Exp>;
  user_roles_aggregate?: InputMaybe<User_Role_Aggregate_Bool_Exp>;
  user_settings?: InputMaybe<User_Setting_Bool_Exp>;
  user_settings_aggregate?: InputMaybe<User_Setting_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint on columns "email" */
  UserEmailKey = "user_email_key",
  /** unique or primary key constraint on columns "phone_number" */
  UserPhoneNumberKey = "user_phone_number_key",
  /** unique or primary key constraint on columns "id" */
  UserPkey = "user_pkey",
}

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  avatar?: InputMaybe<Scalars["String"]>;
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  email?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  invitation_token?: InputMaybe<Scalars["String"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  is_phone_verified?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
  phone_number?: InputMaybe<Scalars["String"]>;
  recovery_token?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<User_Status_Enum>;
  surname?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  user_project?: InputMaybe<Project_User_Arr_Rel_Insert_Input>;
  user_roles?: InputMaybe<User_Role_Arr_Rel_Insert_Input>;
  user_settings?: InputMaybe<User_Setting_Arr_Rel_Insert_Input>;
};

/** columns and relationships of "user_key" */
export type User_Key = {
  __typename?: "user_key";
  created_at: Scalars["timestamptz"];
  expired_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  label: Scalars["String"];
  secret_hash: Scalars["String"];
  /** An object relationship */
  user: User;
  user_id: Scalars["uuid"];
};

/** aggregated selection of "user_key" */
export type User_Key_Aggregate = {
  __typename?: "user_key_aggregate";
  aggregate?: Maybe<User_Key_Aggregate_Fields>;
  nodes: Array<User_Key>;
};

/** aggregate fields of "user_key" */
export type User_Key_Aggregate_Fields = {
  __typename?: "user_key_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<User_Key_Max_Fields>;
  min?: Maybe<User_Key_Min_Fields>;
};

/** aggregate fields of "user_key" */
export type User_Key_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Key_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "user_key". All fields are combined with a logical 'AND'. */
export type User_Key_Bool_Exp = {
  _and?: InputMaybe<Array<User_Key_Bool_Exp>>;
  _not?: InputMaybe<User_Key_Bool_Exp>;
  _or?: InputMaybe<Array<User_Key_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  expired_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  secret_hash?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_key" */
export enum User_Key_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserKeyPkey = "user_key_pkey",
}

/** input type for inserting data into table "user_key" */
export type User_Key_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  expired_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  label?: InputMaybe<Scalars["String"]>;
  secret_hash?: InputMaybe<Scalars["String"]>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type User_Key_Max_Fields = {
  __typename?: "user_key_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  expired_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  label?: Maybe<Scalars["String"]>;
  secret_hash?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate min on columns */
export type User_Key_Min_Fields = {
  __typename?: "user_key_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  expired_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  label?: Maybe<Scalars["String"]>;
  secret_hash?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** response of any mutation on the table "user_key" */
export type User_Key_Mutation_Response = {
  __typename?: "user_key_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<User_Key>;
};

/** on_conflict condition type for table "user_key" */
export type User_Key_On_Conflict = {
  constraint: User_Key_Constraint;
  update_columns?: Array<User_Key_Update_Column>;
  where?: InputMaybe<User_Key_Bool_Exp>;
};

/** Ordering options when selecting data from "user_key". */
export type User_Key_Order_By = {
  created_at?: InputMaybe<Order_By>;
  expired_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  secret_hash?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_key */
export type User_Key_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "user_key" */
export enum User_Key_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExpiredAt = "expired_at",
  /** column name */
  Id = "id",
  /** column name */
  Label = "label",
  /** column name */
  SecretHash = "secret_hash",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "user_key" */
export type User_Key_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  expired_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  label?: InputMaybe<Scalars["String"]>;
  secret_hash?: InputMaybe<Scalars["String"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "user_key" */
export type User_Key_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Key_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Key_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  expired_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  label?: InputMaybe<Scalars["String"]>;
  secret_hash?: InputMaybe<Scalars["String"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "user_key" */
export enum User_Key_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExpiredAt = "expired_at",
  /** column name */
  Id = "id",
  /** column name */
  Label = "label",
  /** column name */
  SecretHash = "secret_hash",
  /** column name */
  UserId = "user_id",
}

export type User_Key_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Key_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Key_Bool_Exp;
};

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: "user_max_fields";
  avatar?: Maybe<Scalars["String"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  invitation_token?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  password?: Maybe<Scalars["String"]>;
  phone_number?: Maybe<Scalars["String"]>;
  recovery_token?: Maybe<Scalars["String"]>;
  surname?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: "user_min_fields";
  avatar?: Maybe<Scalars["String"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  invitation_token?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  password?: Maybe<Scalars["String"]>;
  phone_number?: Maybe<Scalars["String"]>;
  recovery_token?: Maybe<Scalars["String"]>;
  surname?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  __typename?: "user_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<User>;
};

/** input type for inserting object relation for remote table "user" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_On_Conflict>;
};

/** on_conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns?: Array<User_Update_Column>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** Ordering options when selecting data from "user". */
export type User_Order_By = {
  avatar?: InputMaybe<Order_By>;
  company?: InputMaybe<Company_Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invitation_token?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  is_phone_verified?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  password?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  recovery_token?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  surname?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  user_project_aggregate?: InputMaybe<Project_User_Aggregate_Order_By>;
  user_roles_aggregate?: InputMaybe<User_Role_Aggregate_Order_By>;
  user_settings_aggregate?: InputMaybe<User_Setting_Aggregate_Order_By>;
};

/** primary key columns input for table: user */
export type User_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** columns and relationships of "user_role" */
export type User_Role = {
  __typename?: "user_role";
  id: Scalars["Int"];
  /** An object relationship */
  user: User;
  user_id: Scalars["uuid"];
  user_role_name: Scalars["String"];
};

/** aggregated selection of "user_role" */
export type User_Role_Aggregate = {
  __typename?: "user_role_aggregate";
  aggregate?: Maybe<User_Role_Aggregate_Fields>;
  nodes: Array<User_Role>;
};

export type User_Role_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Role_Aggregate_Bool_Exp_Count>;
};

export type User_Role_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Role_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<User_Role_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_role" */
export type User_Role_Aggregate_Fields = {
  __typename?: "user_role_aggregate_fields";
  avg?: Maybe<User_Role_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<User_Role_Max_Fields>;
  min?: Maybe<User_Role_Min_Fields>;
  stddev?: Maybe<User_Role_Stddev_Fields>;
  stddev_pop?: Maybe<User_Role_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Role_Stddev_Samp_Fields>;
  sum?: Maybe<User_Role_Sum_Fields>;
  var_pop?: Maybe<User_Role_Var_Pop_Fields>;
  var_samp?: Maybe<User_Role_Var_Samp_Fields>;
  variance?: Maybe<User_Role_Variance_Fields>;
};

/** aggregate fields of "user_role" */
export type User_Role_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Role_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "user_role" */
export type User_Role_Aggregate_Order_By = {
  avg?: InputMaybe<User_Role_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Role_Max_Order_By>;
  min?: InputMaybe<User_Role_Min_Order_By>;
  stddev?: InputMaybe<User_Role_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Role_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Role_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Role_Sum_Order_By>;
  var_pop?: InputMaybe<User_Role_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Role_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Role_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_role" */
export type User_Role_Arr_Rel_Insert_Input = {
  data: Array<User_Role_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Role_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Role_Avg_Fields = {
  __typename?: "user_role_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "user_role" */
export type User_Role_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_role". All fields are combined with a logical 'AND'. */
export type User_Role_Bool_Exp = {
  _and?: InputMaybe<Array<User_Role_Bool_Exp>>;
  _not?: InputMaybe<User_Role_Bool_Exp>;
  _or?: InputMaybe<Array<User_Role_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_role_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_role" */
export enum User_Role_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserRolePkey = "user_role_pkey",
}

/** input type for incrementing numeric columns in table "user_role" */
export type User_Role_Inc_Input = {
  id?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "user_role" */
export type User_Role_Insert_Input = {
  id?: InputMaybe<Scalars["Int"]>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars["uuid"]>;
  user_role_name?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type User_Role_Max_Fields = {
  __typename?: "user_role_max_fields";
  id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["uuid"]>;
  user_role_name?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "user_role" */
export type User_Role_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_role_name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Role_Min_Fields = {
  __typename?: "user_role_min_fields";
  id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["uuid"]>;
  user_role_name?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "user_role" */
export type User_Role_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_role_name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_role" */
export type User_Role_Mutation_Response = {
  __typename?: "user_role_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<User_Role>;
};

/** on_conflict condition type for table "user_role" */
export type User_Role_On_Conflict = {
  constraint: User_Role_Constraint;
  update_columns?: Array<User_Role_Update_Column>;
  where?: InputMaybe<User_Role_Bool_Exp>;
};

/** Ordering options when selecting data from "user_role". */
export type User_Role_Order_By = {
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_role_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_role */
export type User_Role_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "user_role" */
export enum User_Role_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  UserId = "user_id",
  /** column name */
  UserRoleName = "user_role_name",
}

/** input type for updating data in table "user_role" */
export type User_Role_Set_Input = {
  id?: InputMaybe<Scalars["Int"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
  user_role_name?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type User_Role_Stddev_Fields = {
  __typename?: "user_role_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "user_role" */
export type User_Role_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Role_Stddev_Pop_Fields = {
  __typename?: "user_role_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "user_role" */
export type User_Role_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Role_Stddev_Samp_Fields = {
  __typename?: "user_role_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "user_role" */
export type User_Role_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "user_role" */
export type User_Role_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Role_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Role_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars["Int"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
  user_role_name?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type User_Role_Sum_Fields = {
  __typename?: "user_role_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "user_role" */
export type User_Role_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "user_role" */
export enum User_Role_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  UserId = "user_id",
  /** column name */
  UserRoleName = "user_role_name",
}

export type User_Role_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Role_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Role_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Role_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Role_Var_Pop_Fields = {
  __typename?: "user_role_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "user_role" */
export type User_Role_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Role_Var_Samp_Fields = {
  __typename?: "user_role_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "user_role" */
export type User_Role_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Role_Variance_Fields = {
  __typename?: "user_role_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "user_role" */
export type User_Role_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  Avatar = "avatar",
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Email = "email",
  /** column name */
  Id = "id",
  /** column name */
  InvitationToken = "invitation_token",
  /** column name */
  IsActive = "is_active",
  /** column name */
  IsPhoneVerified = "is_phone_verified",
  /** column name */
  Name = "name",
  /** column name */
  Password = "password",
  /** column name */
  PhoneNumber = "phone_number",
  /** column name */
  RecoveryToken = "recovery_token",
  /** column name */
  Status = "status",
  /** column name */
  Surname = "surname",
  /** column name */
  Title = "title",
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  avatar?: InputMaybe<Scalars["String"]>;
  company_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  email?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  invitation_token?: InputMaybe<Scalars["String"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  is_phone_verified?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
  phone_number?: InputMaybe<Scalars["String"]>;
  recovery_token?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<User_Status_Enum>;
  surname?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "user_setting" */
export type User_Setting = {
  __typename?: "user_setting";
  created_at: Scalars["timestamp"];
  id: Scalars["Int"];
  setting_key: User_Setting_Key_Enum;
  setting_value: Scalars["String"];
  updated_at: Scalars["timestamp"];
  user_id: Scalars["uuid"];
};

/** aggregated selection of "user_setting" */
export type User_Setting_Aggregate = {
  __typename?: "user_setting_aggregate";
  aggregate?: Maybe<User_Setting_Aggregate_Fields>;
  nodes: Array<User_Setting>;
};

export type User_Setting_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Setting_Aggregate_Bool_Exp_Count>;
};

export type User_Setting_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Setting_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<User_Setting_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_setting" */
export type User_Setting_Aggregate_Fields = {
  __typename?: "user_setting_aggregate_fields";
  avg?: Maybe<User_Setting_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<User_Setting_Max_Fields>;
  min?: Maybe<User_Setting_Min_Fields>;
  stddev?: Maybe<User_Setting_Stddev_Fields>;
  stddev_pop?: Maybe<User_Setting_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Setting_Stddev_Samp_Fields>;
  sum?: Maybe<User_Setting_Sum_Fields>;
  var_pop?: Maybe<User_Setting_Var_Pop_Fields>;
  var_samp?: Maybe<User_Setting_Var_Samp_Fields>;
  variance?: Maybe<User_Setting_Variance_Fields>;
};

/** aggregate fields of "user_setting" */
export type User_Setting_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Setting_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "user_setting" */
export type User_Setting_Aggregate_Order_By = {
  avg?: InputMaybe<User_Setting_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Setting_Max_Order_By>;
  min?: InputMaybe<User_Setting_Min_Order_By>;
  stddev?: InputMaybe<User_Setting_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Setting_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Setting_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Setting_Sum_Order_By>;
  var_pop?: InputMaybe<User_Setting_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Setting_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Setting_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_setting" */
export type User_Setting_Arr_Rel_Insert_Input = {
  data: Array<User_Setting_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Setting_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Setting_Avg_Fields = {
  __typename?: "user_setting_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "user_setting" */
export type User_Setting_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_setting". All fields are combined with a logical 'AND'. */
export type User_Setting_Bool_Exp = {
  _and?: InputMaybe<Array<User_Setting_Bool_Exp>>;
  _not?: InputMaybe<User_Setting_Bool_Exp>;
  _or?: InputMaybe<Array<User_Setting_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  setting_key?: InputMaybe<User_Setting_Key_Enum_Comparison_Exp>;
  setting_value?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_setting" */
export enum User_Setting_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserSettingPkey = "user_setting_pkey",
}

/** input type for incrementing numeric columns in table "user_setting" */
export type User_Setting_Inc_Input = {
  id?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "user_setting" */
export type User_Setting_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamp"]>;
  id?: InputMaybe<Scalars["Int"]>;
  setting_key?: InputMaybe<User_Setting_Key_Enum>;
  setting_value?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamp"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** columns and relationships of "user_setting_key" */
export type User_Setting_Key = {
  __typename?: "user_setting_key";
  key: Scalars["String"];
};

/** aggregated selection of "user_setting_key" */
export type User_Setting_Key_Aggregate = {
  __typename?: "user_setting_key_aggregate";
  aggregate?: Maybe<User_Setting_Key_Aggregate_Fields>;
  nodes: Array<User_Setting_Key>;
};

/** aggregate fields of "user_setting_key" */
export type User_Setting_Key_Aggregate_Fields = {
  __typename?: "user_setting_key_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<User_Setting_Key_Max_Fields>;
  min?: Maybe<User_Setting_Key_Min_Fields>;
};

/** aggregate fields of "user_setting_key" */
export type User_Setting_Key_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Setting_Key_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "user_setting_key". All fields are combined with a logical 'AND'. */
export type User_Setting_Key_Bool_Exp = {
  _and?: InputMaybe<Array<User_Setting_Key_Bool_Exp>>;
  _not?: InputMaybe<User_Setting_Key_Bool_Exp>;
  _or?: InputMaybe<Array<User_Setting_Key_Bool_Exp>>;
  key?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_setting_key" */
export enum User_Setting_Key_Constraint {
  /** unique or primary key constraint on columns "key" */
  UserSettingKeyPkey = "user_setting_key_pkey",
}

export enum User_Setting_Key_Enum {
  TwoFaEnabled = "TWO_FA_ENABLED",
  TwoFaMethod = "TWO_FA_METHOD",
}

/** Boolean expression to compare columns of type "user_setting_key_enum". All fields are combined with logical 'AND'. */
export type User_Setting_Key_Enum_Comparison_Exp = {
  _eq?: InputMaybe<User_Setting_Key_Enum>;
  _in?: InputMaybe<Array<User_Setting_Key_Enum>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _neq?: InputMaybe<User_Setting_Key_Enum>;
  _nin?: InputMaybe<Array<User_Setting_Key_Enum>>;
};

/** input type for inserting data into table "user_setting_key" */
export type User_Setting_Key_Insert_Input = {
  key?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type User_Setting_Key_Max_Fields = {
  __typename?: "user_setting_key_max_fields";
  key?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type User_Setting_Key_Min_Fields = {
  __typename?: "user_setting_key_min_fields";
  key?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "user_setting_key" */
export type User_Setting_Key_Mutation_Response = {
  __typename?: "user_setting_key_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<User_Setting_Key>;
};

/** on_conflict condition type for table "user_setting_key" */
export type User_Setting_Key_On_Conflict = {
  constraint: User_Setting_Key_Constraint;
  update_columns?: Array<User_Setting_Key_Update_Column>;
  where?: InputMaybe<User_Setting_Key_Bool_Exp>;
};

/** Ordering options when selecting data from "user_setting_key". */
export type User_Setting_Key_Order_By = {
  key?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_setting_key */
export type User_Setting_Key_Pk_Columns_Input = {
  key: Scalars["String"];
};

/** select columns of table "user_setting_key" */
export enum User_Setting_Key_Select_Column {
  /** column name */
  Key = "key",
}

/** input type for updating data in table "user_setting_key" */
export type User_Setting_Key_Set_Input = {
  key?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "user_setting_key" */
export type User_Setting_Key_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Setting_Key_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Setting_Key_Stream_Cursor_Value_Input = {
  key?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "user_setting_key" */
export enum User_Setting_Key_Update_Column {
  /** column name */
  Key = "key",
}

export type User_Setting_Key_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Setting_Key_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Setting_Key_Bool_Exp;
};

/** aggregate max on columns */
export type User_Setting_Max_Fields = {
  __typename?: "user_setting_max_fields";
  created_at?: Maybe<Scalars["timestamp"]>;
  id?: Maybe<Scalars["Int"]>;
  setting_value?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamp"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "user_setting" */
export type User_Setting_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  setting_value?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Setting_Min_Fields = {
  __typename?: "user_setting_min_fields";
  created_at?: Maybe<Scalars["timestamp"]>;
  id?: Maybe<Scalars["Int"]>;
  setting_value?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamp"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "user_setting" */
export type User_Setting_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  setting_value?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_setting" */
export type User_Setting_Mutation_Response = {
  __typename?: "user_setting_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<User_Setting>;
};

/** on_conflict condition type for table "user_setting" */
export type User_Setting_On_Conflict = {
  constraint: User_Setting_Constraint;
  update_columns?: Array<User_Setting_Update_Column>;
  where?: InputMaybe<User_Setting_Bool_Exp>;
};

/** Ordering options when selecting data from "user_setting". */
export type User_Setting_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  setting_key?: InputMaybe<Order_By>;
  setting_value?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_setting */
export type User_Setting_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "user_setting" */
export enum User_Setting_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  SettingKey = "setting_key",
  /** column name */
  SettingValue = "setting_value",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "user_setting" */
export type User_Setting_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamp"]>;
  id?: InputMaybe<Scalars["Int"]>;
  setting_key?: InputMaybe<User_Setting_Key_Enum>;
  setting_value?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamp"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type User_Setting_Stddev_Fields = {
  __typename?: "user_setting_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "user_setting" */
export type User_Setting_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Setting_Stddev_Pop_Fields = {
  __typename?: "user_setting_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "user_setting" */
export type User_Setting_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Setting_Stddev_Samp_Fields = {
  __typename?: "user_setting_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "user_setting" */
export type User_Setting_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "user_setting" */
export type User_Setting_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Setting_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Setting_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamp"]>;
  id?: InputMaybe<Scalars["Int"]>;
  setting_key?: InputMaybe<User_Setting_Key_Enum>;
  setting_value?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamp"]>;
  user_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate sum on columns */
export type User_Setting_Sum_Fields = {
  __typename?: "user_setting_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "user_setting" */
export type User_Setting_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "user_setting" */
export enum User_Setting_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  SettingKey = "setting_key",
  /** column name */
  SettingValue = "setting_value",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UserId = "user_id",
}

export type User_Setting_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Setting_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Setting_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Setting_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Setting_Var_Pop_Fields = {
  __typename?: "user_setting_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "user_setting" */
export type User_Setting_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Setting_Var_Samp_Fields = {
  __typename?: "user_setting_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "user_setting" */
export type User_Setting_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Setting_Variance_Fields = {
  __typename?: "user_setting_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "user_setting" */
export type User_Setting_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "user_status" */
export type User_Status = {
  __typename?: "user_status";
  status: Scalars["String"];
};

/** aggregated selection of "user_status" */
export type User_Status_Aggregate = {
  __typename?: "user_status_aggregate";
  aggregate?: Maybe<User_Status_Aggregate_Fields>;
  nodes: Array<User_Status>;
};

/** aggregate fields of "user_status" */
export type User_Status_Aggregate_Fields = {
  __typename?: "user_status_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<User_Status_Max_Fields>;
  min?: Maybe<User_Status_Min_Fields>;
};

/** aggregate fields of "user_status" */
export type User_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "user_status". All fields are combined with a logical 'AND'. */
export type User_Status_Bool_Exp = {
  _and?: InputMaybe<Array<User_Status_Bool_Exp>>;
  _not?: InputMaybe<User_Status_Bool_Exp>;
  _or?: InputMaybe<Array<User_Status_Bool_Exp>>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_status" */
export enum User_Status_Constraint {
  /** unique or primary key constraint on columns "status" */
  UserStatusPkey = "user_status_pkey",
}

export enum User_Status_Enum {
  Active = "ACTIVE",
  Invited = "INVITED",
  Passive = "PASSIVE",
}

/** Boolean expression to compare columns of type "user_status_enum". All fields are combined with logical 'AND'. */
export type User_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<User_Status_Enum>;
  _in?: InputMaybe<Array<User_Status_Enum>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _neq?: InputMaybe<User_Status_Enum>;
  _nin?: InputMaybe<Array<User_Status_Enum>>;
};

/** input type for inserting data into table "user_status" */
export type User_Status_Insert_Input = {
  status?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type User_Status_Max_Fields = {
  __typename?: "user_status_max_fields";
  status?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type User_Status_Min_Fields = {
  __typename?: "user_status_min_fields";
  status?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "user_status" */
export type User_Status_Mutation_Response = {
  __typename?: "user_status_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<User_Status>;
};

/** on_conflict condition type for table "user_status" */
export type User_Status_On_Conflict = {
  constraint: User_Status_Constraint;
  update_columns?: Array<User_Status_Update_Column>;
  where?: InputMaybe<User_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "user_status". */
export type User_Status_Order_By = {
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_status */
export type User_Status_Pk_Columns_Input = {
  status: Scalars["String"];
};

/** select columns of table "user_status" */
export enum User_Status_Select_Column {
  /** column name */
  Status = "status",
}

/** input type for updating data in table "user_status" */
export type User_Status_Set_Input = {
  status?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "user_status" */
export type User_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Status_Stream_Cursor_Value_Input = {
  status?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "user_status" */
export enum User_Status_Update_Column {
  /** column name */
  Status = "status",
}

export type User_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Status_Bool_Exp;
};

/** Streaming cursor of the table "user" */
export type User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Stream_Cursor_Value_Input = {
  avatar?: InputMaybe<Scalars["String"]>;
  company_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  email?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  invitation_token?: InputMaybe<Scalars["String"]>;
  is_active?: InputMaybe<Scalars["Boolean"]>;
  is_phone_verified?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
  phone_number?: InputMaybe<Scalars["String"]>;
  recovery_token?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<User_Status_Enum>;
  surname?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  Avatar = "avatar",
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Email = "email",
  /** column name */
  Id = "id",
  /** column name */
  InvitationToken = "invitation_token",
  /** column name */
  IsActive = "is_active",
  /** column name */
  IsPhoneVerified = "is_phone_verified",
  /** column name */
  Name = "name",
  /** column name */
  Password = "password",
  /** column name */
  PhoneNumber = "phone_number",
  /** column name */
  RecoveryToken = "recovery_token",
  /** column name */
  Status = "status",
  /** column name */
  Surname = "surname",
  /** column name */
  Title = "title",
}

export type User_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Bool_Exp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["uuid"]>;
  _gt?: InputMaybe<Scalars["uuid"]>;
  _gte?: InputMaybe<Scalars["uuid"]>;
  _in?: InputMaybe<Array<Scalars["uuid"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["uuid"]>;
  _lte?: InputMaybe<Scalars["uuid"]>;
  _neq?: InputMaybe<Scalars["uuid"]>;
  _nin?: InputMaybe<Array<Scalars["uuid"]>>;
};

/** columns and relationships of "video_interview" */
export type Video_Interview = {
  __typename?: "video_interview";
  /** An object relationship */
  client_candidate_project: Client_Candidate_Project;
  client_candidate_project_id: Scalars["Int"];
  completed_at?: Maybe<Scalars["timestamptz"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["uuid"];
  /** Gets last interview status from video_interview_status_history */
  interview_last_status?: Maybe<Scalars["String"]>;
  /** An object relationship */
  interview_template?: Maybe<Interview_Template>;
  interview_template_id?: Maybe<Scalars["Int"]>;
  interview_video_url?: Maybe<Scalars["String"]>;
  /** A computed field, executes function "compute_video_interview_score" */
  overall_score?: Maybe<Scalars["Int"]>;
  score: Scalars["Int"];
  started_at?: Maybe<Scalars["timestamptz"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  reviewer: Video_Interview_Reviewer_Enum;
  /** An array relationship */
  video_interview_questions: Array<Video_Interview_Question>;
  /** An aggregate relationship */
  video_interview_questions_aggregate: Video_Interview_Question_Aggregate;
  /** An array relationship */
  video_interview_statuses: Array<Video_Interview_Status_History>;
  /** An aggregate relationship */
  video_interview_statuses_aggregate: Video_Interview_Status_History_Aggregate;
  video_interview_type: Video_Interview_Type_Enum;
  video_process_status?: Maybe<Video_Process_Status_Enum>;
};

/** columns and relationships of "video_interview" */
export type Video_InterviewVideo_Interview_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Video_Interview_Question_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Question_Order_By>>;
  where?: InputMaybe<Video_Interview_Question_Bool_Exp>;
};

/** columns and relationships of "video_interview" */
export type Video_InterviewVideo_Interview_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Video_Interview_Question_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Question_Order_By>>;
  where?: InputMaybe<Video_Interview_Question_Bool_Exp>;
};

/** columns and relationships of "video_interview" */
export type Video_InterviewVideo_Interview_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Video_Interview_Status_History_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Status_History_Order_By>>;
  where?: InputMaybe<Video_Interview_Status_History_Bool_Exp>;
};

/** columns and relationships of "video_interview" */
export type Video_InterviewVideo_Interview_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Video_Interview_Status_History_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Status_History_Order_By>>;
  where?: InputMaybe<Video_Interview_Status_History_Bool_Exp>;
};

/** aggregated selection of "video_interview" */
export type Video_Interview_Aggregate = {
  __typename?: "video_interview_aggregate";
  aggregate?: Maybe<Video_Interview_Aggregate_Fields>;
  nodes: Array<Video_Interview>;
};

export type Video_Interview_Aggregate_Bool_Exp = {
  count?: InputMaybe<Video_Interview_Aggregate_Bool_Exp_Count>;
};

export type Video_Interview_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Video_Interview_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Video_Interview_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "video_interview" */
export type Video_Interview_Aggregate_Fields = {
  __typename?: "video_interview_aggregate_fields";
  avg?: Maybe<Video_Interview_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Video_Interview_Max_Fields>;
  min?: Maybe<Video_Interview_Min_Fields>;
  stddev?: Maybe<Video_Interview_Stddev_Fields>;
  stddev_pop?: Maybe<Video_Interview_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Video_Interview_Stddev_Samp_Fields>;
  sum?: Maybe<Video_Interview_Sum_Fields>;
  var_pop?: Maybe<Video_Interview_Var_Pop_Fields>;
  var_samp?: Maybe<Video_Interview_Var_Samp_Fields>;
  variance?: Maybe<Video_Interview_Variance_Fields>;
};

/** aggregate fields of "video_interview" */
export type Video_Interview_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Video_Interview_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "video_interview" */
export type Video_Interview_Aggregate_Order_By = {
  avg?: InputMaybe<Video_Interview_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Video_Interview_Max_Order_By>;
  min?: InputMaybe<Video_Interview_Min_Order_By>;
  stddev?: InputMaybe<Video_Interview_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Video_Interview_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Video_Interview_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Video_Interview_Sum_Order_By>;
  var_pop?: InputMaybe<Video_Interview_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Video_Interview_Var_Samp_Order_By>;
  variance?: InputMaybe<Video_Interview_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "video_interview" */
export type Video_Interview_Arr_Rel_Insert_Input = {
  data: Array<Video_Interview_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Video_Interview_On_Conflict>;
};

/** aggregate avg on columns */
export type Video_Interview_Avg_Fields = {
  __typename?: "video_interview_avg_fields";
  client_candidate_project_id?: Maybe<Scalars["Float"]>;
  interview_template_id?: Maybe<Scalars["Float"]>;
  /** A computed field, executes function "compute_video_interview_score" */
  overall_score?: Maybe<Scalars["Int"]>;
  score?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "video_interview" */
export type Video_Interview_Avg_Order_By = {
  client_candidate_project_id?: InputMaybe<Order_By>;
  interview_template_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "video_interview". All fields are combined with a logical 'AND'. */
export type Video_Interview_Bool_Exp = {
  _and?: InputMaybe<Array<Video_Interview_Bool_Exp>>;
  _not?: InputMaybe<Video_Interview_Bool_Exp>;
  _or?: InputMaybe<Array<Video_Interview_Bool_Exp>>;
  client_candidate_project?: InputMaybe<Client_Candidate_Project_Bool_Exp>;
  client_candidate_project_id?: InputMaybe<Int_Comparison_Exp>;
  completed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  interview_last_status?: InputMaybe<String_Comparison_Exp>;
  interview_template?: InputMaybe<Interview_Template_Bool_Exp>;
  interview_template_id?: InputMaybe<Int_Comparison_Exp>;
  interview_video_url?: InputMaybe<String_Comparison_Exp>;
  overall_score?: InputMaybe<Int_Comparison_Exp>;
  reviewer?: InputMaybe<Video_Interview_Reviewer_Enum_Comparison_Exp>;
  score?: InputMaybe<Int_Comparison_Exp>;
  started_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  video_interview_questions?: InputMaybe<Video_Interview_Question_Bool_Exp>;
  video_interview_questions_aggregate?: InputMaybe<Video_Interview_Question_Aggregate_Bool_Exp>;
  video_interview_statuses?: InputMaybe<Video_Interview_Status_History_Bool_Exp>;
  video_interview_statuses_aggregate?: InputMaybe<Video_Interview_Status_History_Aggregate_Bool_Exp>;
  video_interview_type?: InputMaybe<Video_Interview_Type_Enum_Comparison_Exp>;
  video_process_status?: InputMaybe<Video_Process_Status_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "video_interview" */
export enum Video_Interview_Constraint {
  /** unique or primary key constraint on columns "client_candidate_project_id", "video_interview_type" */
  VideoInterviewClientCandidateProjectIdVideoInterviewTyp = "video_interview_client_candidate_project_id_video_interview_typ",
  /** unique or primary key constraint on columns "id" */
  VideoInterviewPkey = "video_interview_pkey",
}

/** columns and relationships of "video_interview_criterion_score" */
export type Video_Interview_Criterion_Score = {
  __typename?: "video_interview_criterion_score";
  created_at: Scalars["timestamptz"];
  id: Scalars["Int"];
  /** An object relationship */
  interview_criterion: Interview_Criterion;
  interview_criterion_id: Scalars["Int"];
  score: Scalars["Int"];
  score_description: Scalars["String"];
  /** An object relationship */
  video_interview: Video_Interview;
  video_interview_id: Scalars["uuid"];
};

/** aggregated selection of "video_interview_criterion_score" */
export type Video_Interview_Criterion_Score_Aggregate = {
  __typename?: "video_interview_criterion_score_aggregate";
  aggregate?: Maybe<Video_Interview_Criterion_Score_Aggregate_Fields>;
  nodes: Array<Video_Interview_Criterion_Score>;
};

/** aggregate fields of "video_interview_criterion_score" */
export type Video_Interview_Criterion_Score_Aggregate_Fields = {
  __typename?: "video_interview_criterion_score_aggregate_fields";
  avg?: Maybe<Video_Interview_Criterion_Score_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Video_Interview_Criterion_Score_Max_Fields>;
  min?: Maybe<Video_Interview_Criterion_Score_Min_Fields>;
  stddev?: Maybe<Video_Interview_Criterion_Score_Stddev_Fields>;
  stddev_pop?: Maybe<Video_Interview_Criterion_Score_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Video_Interview_Criterion_Score_Stddev_Samp_Fields>;
  sum?: Maybe<Video_Interview_Criterion_Score_Sum_Fields>;
  var_pop?: Maybe<Video_Interview_Criterion_Score_Var_Pop_Fields>;
  var_samp?: Maybe<Video_Interview_Criterion_Score_Var_Samp_Fields>;
  variance?: Maybe<Video_Interview_Criterion_Score_Variance_Fields>;
};

/** aggregate fields of "video_interview_criterion_score" */
export type Video_Interview_Criterion_Score_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Video_Interview_Criterion_Score_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Video_Interview_Criterion_Score_Avg_Fields = {
  __typename?: "video_interview_criterion_score_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  interview_criterion_id?: Maybe<Scalars["Float"]>;
  score?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "video_interview_criterion_score". All fields are combined with a logical 'AND'. */
export type Video_Interview_Criterion_Score_Bool_Exp = {
  _and?: InputMaybe<Array<Video_Interview_Criterion_Score_Bool_Exp>>;
  _not?: InputMaybe<Video_Interview_Criterion_Score_Bool_Exp>;
  _or?: InputMaybe<Array<Video_Interview_Criterion_Score_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  interview_criterion?: InputMaybe<Interview_Criterion_Bool_Exp>;
  interview_criterion_id?: InputMaybe<Int_Comparison_Exp>;
  score?: InputMaybe<Int_Comparison_Exp>;
  score_description?: InputMaybe<String_Comparison_Exp>;
  video_interview?: InputMaybe<Video_Interview_Bool_Exp>;
  video_interview_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "video_interview_criterion_score" */
export enum Video_Interview_Criterion_Score_Constraint {
  /** unique or primary key constraint on columns "id" */
  VideoInterviewCriterionScorePkey = "video_interview_criterion_score_pkey",
  /** unique or primary key constraint on columns "interview_criterion_id", "video_interview_id" */
  VideoInterviewCriterionScoreVideoInterviewIdCriterionId = "video_interview_criterion_score_video_interview_id_criterion_id",
}

/** input type for incrementing numeric columns in table "video_interview_criterion_score" */
export type Video_Interview_Criterion_Score_Inc_Input = {
  id?: InputMaybe<Scalars["Int"]>;
  interview_criterion_id?: InputMaybe<Scalars["Int"]>;
  score?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "video_interview_criterion_score" */
export type Video_Interview_Criterion_Score_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["Int"]>;
  interview_criterion?: InputMaybe<Interview_Criterion_Obj_Rel_Insert_Input>;
  interview_criterion_id?: InputMaybe<Scalars["Int"]>;
  score?: InputMaybe<Scalars["Int"]>;
  score_description?: InputMaybe<Scalars["String"]>;
  video_interview?: InputMaybe<Video_Interview_Obj_Rel_Insert_Input>;
  video_interview_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Video_Interview_Criterion_Score_Max_Fields = {
  __typename?: "video_interview_criterion_score_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  interview_criterion_id?: Maybe<Scalars["Int"]>;
  score?: Maybe<Scalars["Int"]>;
  score_description?: Maybe<Scalars["String"]>;
  video_interview_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate min on columns */
export type Video_Interview_Criterion_Score_Min_Fields = {
  __typename?: "video_interview_criterion_score_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  interview_criterion_id?: Maybe<Scalars["Int"]>;
  score?: Maybe<Scalars["Int"]>;
  score_description?: Maybe<Scalars["String"]>;
  video_interview_id?: Maybe<Scalars["uuid"]>;
};

/** response of any mutation on the table "video_interview_criterion_score" */
export type Video_Interview_Criterion_Score_Mutation_Response = {
  __typename?: "video_interview_criterion_score_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Video_Interview_Criterion_Score>;
};

/** on_conflict condition type for table "video_interview_criterion_score" */
export type Video_Interview_Criterion_Score_On_Conflict = {
  constraint: Video_Interview_Criterion_Score_Constraint;
  update_columns?: Array<Video_Interview_Criterion_Score_Update_Column>;
  where?: InputMaybe<Video_Interview_Criterion_Score_Bool_Exp>;
};

/** Ordering options when selecting data from "video_interview_criterion_score". */
export type Video_Interview_Criterion_Score_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interview_criterion?: InputMaybe<Interview_Criterion_Order_By>;
  interview_criterion_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  score_description?: InputMaybe<Order_By>;
  video_interview?: InputMaybe<Video_Interview_Order_By>;
  video_interview_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: video_interview_criterion_score */
export type Video_Interview_Criterion_Score_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "video_interview_criterion_score" */
export enum Video_Interview_Criterion_Score_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InterviewCriterionId = "interview_criterion_id",
  /** column name */
  Score = "score",
  /** column name */
  ScoreDescription = "score_description",
  /** column name */
  VideoInterviewId = "video_interview_id",
}

/** input type for updating data in table "video_interview_criterion_score" */
export type Video_Interview_Criterion_Score_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["Int"]>;
  interview_criterion_id?: InputMaybe<Scalars["Int"]>;
  score?: InputMaybe<Scalars["Int"]>;
  score_description?: InputMaybe<Scalars["String"]>;
  video_interview_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type Video_Interview_Criterion_Score_Stddev_Fields = {
  __typename?: "video_interview_criterion_score_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  interview_criterion_id?: Maybe<Scalars["Float"]>;
  score?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Video_Interview_Criterion_Score_Stddev_Pop_Fields = {
  __typename?: "video_interview_criterion_score_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  interview_criterion_id?: Maybe<Scalars["Float"]>;
  score?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Video_Interview_Criterion_Score_Stddev_Samp_Fields = {
  __typename?: "video_interview_criterion_score_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  interview_criterion_id?: Maybe<Scalars["Float"]>;
  score?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "video_interview_criterion_score" */
export type Video_Interview_Criterion_Score_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Video_Interview_Criterion_Score_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Video_Interview_Criterion_Score_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["Int"]>;
  interview_criterion_id?: InputMaybe<Scalars["Int"]>;
  score?: InputMaybe<Scalars["Int"]>;
  score_description?: InputMaybe<Scalars["String"]>;
  video_interview_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate sum on columns */
export type Video_Interview_Criterion_Score_Sum_Fields = {
  __typename?: "video_interview_criterion_score_sum_fields";
  id?: Maybe<Scalars["Int"]>;
  interview_criterion_id?: Maybe<Scalars["Int"]>;
  score?: Maybe<Scalars["Int"]>;
};

/** update columns of table "video_interview_criterion_score" */
export enum Video_Interview_Criterion_Score_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InterviewCriterionId = "interview_criterion_id",
  /** column name */
  Score = "score",
  /** column name */
  ScoreDescription = "score_description",
  /** column name */
  VideoInterviewId = "video_interview_id",
}

export type Video_Interview_Criterion_Score_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Video_Interview_Criterion_Score_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Video_Interview_Criterion_Score_Set_Input>;
  /** filter the rows which have to be updated */
  where: Video_Interview_Criterion_Score_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Video_Interview_Criterion_Score_Var_Pop_Fields = {
  __typename?: "video_interview_criterion_score_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  interview_criterion_id?: Maybe<Scalars["Float"]>;
  score?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Video_Interview_Criterion_Score_Var_Samp_Fields = {
  __typename?: "video_interview_criterion_score_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  interview_criterion_id?: Maybe<Scalars["Float"]>;
  score?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Video_Interview_Criterion_Score_Variance_Fields = {
  __typename?: "video_interview_criterion_score_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  interview_criterion_id?: Maybe<Scalars["Float"]>;
  score?: Maybe<Scalars["Float"]>;
};

/** input type for incrementing numeric columns in table "video_interview" */
export type Video_Interview_Inc_Input = {
  client_candidate_project_id?: InputMaybe<Scalars["Int"]>;
  interview_template_id?: InputMaybe<Scalars["Int"]>;
  score?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "video_interview" */
export type Video_Interview_Insert_Input = {
  client_candidate_project?: InputMaybe<Client_Candidate_Project_Obj_Rel_Insert_Input>;
  client_candidate_project_id?: InputMaybe<Scalars["Int"]>;
  completed_at?: InputMaybe<Scalars["timestamptz"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  interview_template?: InputMaybe<Interview_Template_Obj_Rel_Insert_Input>;
  interview_template_id?: InputMaybe<Scalars["Int"]>;
  interview_video_url?: InputMaybe<Scalars["String"]>;
  score?: InputMaybe<Scalars["Int"]>;
  started_at?: InputMaybe<Scalars["timestamptz"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  reviewer?: InputMaybe<Video_Interview_Reviewer_Enum>;
  video_interview_questions?: InputMaybe<Video_Interview_Question_Arr_Rel_Insert_Input>;
  video_interview_statuses?: InputMaybe<Video_Interview_Status_History_Arr_Rel_Insert_Input>;
  video_interview_type?: InputMaybe<Video_Interview_Type_Enum>;
  video_process_status?: InputMaybe<Video_Process_Status_Enum>;
};

/** aggregate max on columns */
export type Video_Interview_Max_Fields = {
  __typename?: "video_interview_max_fields";
  client_candidate_project_id?: Maybe<Scalars["Int"]>;
  completed_at?: Maybe<Scalars["timestamptz"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  /** Gets last interview status from video_interview_status_history */
  interview_last_status?: Maybe<Scalars["String"]>;
  interview_template_id?: Maybe<Scalars["Int"]>;
  interview_video_url?: Maybe<Scalars["String"]>;
  /** A computed field, executes function "compute_video_interview_score" */
  overall_score?: Maybe<Scalars["Int"]>;
  score?: Maybe<Scalars["Int"]>;
  started_at?: Maybe<Scalars["timestamptz"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "video_interview" */
export type Video_Interview_Max_Order_By = {
  client_candidate_project_id?: InputMaybe<Order_By>;
  completed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interview_template_id?: InputMaybe<Order_By>;
  interview_video_url?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  started_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Video_Interview_Min_Fields = {
  __typename?: "video_interview_min_fields";
  client_candidate_project_id?: Maybe<Scalars["Int"]>;
  completed_at?: Maybe<Scalars["timestamptz"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  /** Gets last interview status from video_interview_status_history */
  interview_last_status?: Maybe<Scalars["String"]>;
  interview_template_id?: Maybe<Scalars["Int"]>;
  interview_video_url?: Maybe<Scalars["String"]>;
  /** A computed field, executes function "compute_video_interview_score" */
  overall_score?: Maybe<Scalars["Int"]>;
  score?: Maybe<Scalars["Int"]>;
  started_at?: Maybe<Scalars["timestamptz"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "video_interview" */
export type Video_Interview_Min_Order_By = {
  client_candidate_project_id?: InputMaybe<Order_By>;
  completed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interview_template_id?: InputMaybe<Order_By>;
  interview_video_url?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  started_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "video_interview" */
export type Video_Interview_Mutation_Response = {
  __typename?: "video_interview_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Video_Interview>;
};

/** input type for inserting object relation for remote table "video_interview" */
export type Video_Interview_Obj_Rel_Insert_Input = {
  data: Video_Interview_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Video_Interview_On_Conflict>;
};

/** on_conflict condition type for table "video_interview" */
export type Video_Interview_On_Conflict = {
  constraint: Video_Interview_Constraint;
  update_columns?: Array<Video_Interview_Update_Column>;
  where?: InputMaybe<Video_Interview_Bool_Exp>;
};

/** Ordering options when selecting data from "video_interview". */
export type Video_Interview_Order_By = {
  client_candidate_project?: InputMaybe<Client_Candidate_Project_Order_By>;
  client_candidate_project_id?: InputMaybe<Order_By>;
  completed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interview_last_status?: InputMaybe<Order_By>;
  interview_template?: InputMaybe<Interview_Template_Order_By>;
  interview_template_id?: InputMaybe<Order_By>;
  interview_video_url?: InputMaybe<Order_By>;
  overall_score?: InputMaybe<Order_By>;
  reviewer?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  started_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  video_interview_questions_aggregate?: InputMaybe<Video_Interview_Question_Aggregate_Order_By>;
  video_interview_statuses_aggregate?: InputMaybe<Video_Interview_Status_History_Aggregate_Order_By>;
  video_interview_type?: InputMaybe<Order_By>;
  video_process_status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: video_interview */
export type Video_Interview_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** columns and relationships of "video_interview_question" */
export type Video_Interview_Question = {
  __typename?: "video_interview_question";
  answer_transcript?: Maybe<Scalars["String"]>;
  answered_at?: Maybe<Scalars["timestamptz"]>;
  corrected_answer_transcript?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  original_answer_transcript?: Maybe<Scalars["String"]>;
  /** An object relationship */
  review_metric_question: Review_Metric_Question;
  review_metric_question_id: Scalars["Int"];
  storage_filename?: Maybe<Scalars["String"]>;
  upload_ends_at?: Maybe<Scalars["timestamptz"]>;
  upload_starts_at?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  video_interview?: Maybe<Video_Interview>;
  video_interview_id: Scalars["uuid"];
  /** An array relationship */
  video_interview_question_events: Array<Video_Interview_Question_Event>;
  /** An aggregate relationship */
  video_interview_question_events_aggregate: Video_Interview_Question_Event_Aggregate;
  /** An array relationship */
  video_interview_score: Array<Video_Interview_Question_Score>;
  /** An aggregate relationship */
  video_interview_score_aggregate: Video_Interview_Question_Score_Aggregate;
};

/** columns and relationships of "video_interview_question" */
export type Video_Interview_QuestionVideo_Interview_Question_EventsArgs = {
  distinct_on?: InputMaybe<Array<Video_Interview_Question_Event_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Question_Event_Order_By>>;
  where?: InputMaybe<Video_Interview_Question_Event_Bool_Exp>;
};

/** columns and relationships of "video_interview_question" */
export type Video_Interview_QuestionVideo_Interview_Question_Events_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Video_Interview_Question_Event_Select_Column>
    >;
    limit?: InputMaybe<Scalars["Int"]>;
    offset?: InputMaybe<Scalars["Int"]>;
    order_by?: InputMaybe<Array<Video_Interview_Question_Event_Order_By>>;
    where?: InputMaybe<Video_Interview_Question_Event_Bool_Exp>;
  };

/** columns and relationships of "video_interview_question" */
export type Video_Interview_QuestionVideo_Interview_ScoreArgs = {
  distinct_on?: InputMaybe<Array<Video_Interview_Question_Score_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Question_Score_Order_By>>;
  where?: InputMaybe<Video_Interview_Question_Score_Bool_Exp>;
};

/** columns and relationships of "video_interview_question" */
export type Video_Interview_QuestionVideo_Interview_Score_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Video_Interview_Question_Score_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Video_Interview_Question_Score_Order_By>>;
  where?: InputMaybe<Video_Interview_Question_Score_Bool_Exp>;
};

/** aggregated selection of "video_interview_question" */
export type Video_Interview_Question_Aggregate = {
  __typename?: "video_interview_question_aggregate";
  aggregate?: Maybe<Video_Interview_Question_Aggregate_Fields>;
  nodes: Array<Video_Interview_Question>;
};

export type Video_Interview_Question_Aggregate_Bool_Exp = {
  count?: InputMaybe<Video_Interview_Question_Aggregate_Bool_Exp_Count>;
};

export type Video_Interview_Question_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Video_Interview_Question_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Video_Interview_Question_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "video_interview_question" */
export type Video_Interview_Question_Aggregate_Fields = {
  __typename?: "video_interview_question_aggregate_fields";
  avg?: Maybe<Video_Interview_Question_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Video_Interview_Question_Max_Fields>;
  min?: Maybe<Video_Interview_Question_Min_Fields>;
  stddev?: Maybe<Video_Interview_Question_Stddev_Fields>;
  stddev_pop?: Maybe<Video_Interview_Question_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Video_Interview_Question_Stddev_Samp_Fields>;
  sum?: Maybe<Video_Interview_Question_Sum_Fields>;
  var_pop?: Maybe<Video_Interview_Question_Var_Pop_Fields>;
  var_samp?: Maybe<Video_Interview_Question_Var_Samp_Fields>;
  variance?: Maybe<Video_Interview_Question_Variance_Fields>;
};

/** aggregate fields of "video_interview_question" */
export type Video_Interview_Question_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Video_Interview_Question_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "video_interview_question" */
export type Video_Interview_Question_Aggregate_Order_By = {
  avg?: InputMaybe<Video_Interview_Question_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Video_Interview_Question_Max_Order_By>;
  min?: InputMaybe<Video_Interview_Question_Min_Order_By>;
  stddev?: InputMaybe<Video_Interview_Question_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Video_Interview_Question_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Video_Interview_Question_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Video_Interview_Question_Sum_Order_By>;
  var_pop?: InputMaybe<Video_Interview_Question_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Video_Interview_Question_Var_Samp_Order_By>;
  variance?: InputMaybe<Video_Interview_Question_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "video_interview_question" */
export type Video_Interview_Question_Arr_Rel_Insert_Input = {
  data: Array<Video_Interview_Question_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Video_Interview_Question_On_Conflict>;
};

/** aggregate avg on columns */
export type Video_Interview_Question_Avg_Fields = {
  __typename?: "video_interview_question_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  review_metric_question_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "video_interview_question" */
export type Video_Interview_Question_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "video_interview_question". All fields are combined with a logical 'AND'. */
export type Video_Interview_Question_Bool_Exp = {
  _and?: InputMaybe<Array<Video_Interview_Question_Bool_Exp>>;
  _not?: InputMaybe<Video_Interview_Question_Bool_Exp>;
  _or?: InputMaybe<Array<Video_Interview_Question_Bool_Exp>>;
  answer_transcript?: InputMaybe<String_Comparison_Exp>;
  answered_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  corrected_answer_transcript?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  original_answer_transcript?: InputMaybe<String_Comparison_Exp>;
  review_metric_question?: InputMaybe<Review_Metric_Question_Bool_Exp>;
  review_metric_question_id?: InputMaybe<Int_Comparison_Exp>;
  storage_filename?: InputMaybe<String_Comparison_Exp>;
  upload_ends_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  upload_starts_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  video_interview?: InputMaybe<Video_Interview_Bool_Exp>;
  video_interview_id?: InputMaybe<Uuid_Comparison_Exp>;
  video_interview_question_events?: InputMaybe<Video_Interview_Question_Event_Bool_Exp>;
  video_interview_question_events_aggregate?: InputMaybe<Video_Interview_Question_Event_Aggregate_Bool_Exp>;
  video_interview_score?: InputMaybe<Video_Interview_Question_Score_Bool_Exp>;
  video_interview_score_aggregate?: InputMaybe<Video_Interview_Question_Score_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "video_interview_question" */
export enum Video_Interview_Question_Constraint {
  /** unique or primary key constraint on columns "id" */
  InterviewQuestionPkey = "interview_question_pkey",
  /** unique or primary key constraint on columns "review_metric_question_id", "video_interview_id" */
  VideoInterviewQuestionReviewMetricQuestionIdVideoInterv = "video_interview_question_review_metric_question_id_video_interv",
}

/** columns and relationships of "video_interview_question_event" */
export type Video_Interview_Question_Event = {
  __typename?: "video_interview_question_event";
  created_at: Scalars["timestamptz"];
  id: Scalars["bigint"];
  type: Video_Interview_Question_Event_Type_Enum;
  /** An object relationship */
  video_interview_question: Video_Interview_Question;
  /** An object relationship */
  video_interview_question_event_type: Video_Interview_Question_Event_Type;
  video_interview_question_id: Scalars["Int"];
};

/** aggregated selection of "video_interview_question_event" */
export type Video_Interview_Question_Event_Aggregate = {
  __typename?: "video_interview_question_event_aggregate";
  aggregate?: Maybe<Video_Interview_Question_Event_Aggregate_Fields>;
  nodes: Array<Video_Interview_Question_Event>;
};

export type Video_Interview_Question_Event_Aggregate_Bool_Exp = {
  count?: InputMaybe<Video_Interview_Question_Event_Aggregate_Bool_Exp_Count>;
};

export type Video_Interview_Question_Event_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Video_Interview_Question_Event_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Video_Interview_Question_Event_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "video_interview_question_event" */
export type Video_Interview_Question_Event_Aggregate_Fields = {
  __typename?: "video_interview_question_event_aggregate_fields";
  avg?: Maybe<Video_Interview_Question_Event_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Video_Interview_Question_Event_Max_Fields>;
  min?: Maybe<Video_Interview_Question_Event_Min_Fields>;
  stddev?: Maybe<Video_Interview_Question_Event_Stddev_Fields>;
  stddev_pop?: Maybe<Video_Interview_Question_Event_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Video_Interview_Question_Event_Stddev_Samp_Fields>;
  sum?: Maybe<Video_Interview_Question_Event_Sum_Fields>;
  var_pop?: Maybe<Video_Interview_Question_Event_Var_Pop_Fields>;
  var_samp?: Maybe<Video_Interview_Question_Event_Var_Samp_Fields>;
  variance?: Maybe<Video_Interview_Question_Event_Variance_Fields>;
};

/** aggregate fields of "video_interview_question_event" */
export type Video_Interview_Question_Event_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Video_Interview_Question_Event_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "video_interview_question_event" */
export type Video_Interview_Question_Event_Aggregate_Order_By = {
  avg?: InputMaybe<Video_Interview_Question_Event_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Video_Interview_Question_Event_Max_Order_By>;
  min?: InputMaybe<Video_Interview_Question_Event_Min_Order_By>;
  stddev?: InputMaybe<Video_Interview_Question_Event_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Video_Interview_Question_Event_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Video_Interview_Question_Event_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Video_Interview_Question_Event_Sum_Order_By>;
  var_pop?: InputMaybe<Video_Interview_Question_Event_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Video_Interview_Question_Event_Var_Samp_Order_By>;
  variance?: InputMaybe<Video_Interview_Question_Event_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "video_interview_question_event" */
export type Video_Interview_Question_Event_Arr_Rel_Insert_Input = {
  data: Array<Video_Interview_Question_Event_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Video_Interview_Question_Event_On_Conflict>;
};

/** aggregate avg on columns */
export type Video_Interview_Question_Event_Avg_Fields = {
  __typename?: "video_interview_question_event_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  video_interview_question_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "video_interview_question_event" */
export type Video_Interview_Question_Event_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  video_interview_question_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "video_interview_question_event". All fields are combined with a logical 'AND'. */
export type Video_Interview_Question_Event_Bool_Exp = {
  _and?: InputMaybe<Array<Video_Interview_Question_Event_Bool_Exp>>;
  _not?: InputMaybe<Video_Interview_Question_Event_Bool_Exp>;
  _or?: InputMaybe<Array<Video_Interview_Question_Event_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  type?: InputMaybe<Video_Interview_Question_Event_Type_Enum_Comparison_Exp>;
  video_interview_question?: InputMaybe<Video_Interview_Question_Bool_Exp>;
  video_interview_question_event_type?: InputMaybe<Video_Interview_Question_Event_Type_Bool_Exp>;
  video_interview_question_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "video_interview_question_event" */
export enum Video_Interview_Question_Event_Constraint {
  /** unique or primary key constraint on columns "id" */
  VideoInterviewQuestionEventPkey = "video_interview_question_event_pkey",
}

/** input type for incrementing numeric columns in table "video_interview_question_event" */
export type Video_Interview_Question_Event_Inc_Input = {
  id?: InputMaybe<Scalars["bigint"]>;
  video_interview_question_id?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "video_interview_question_event" */
export type Video_Interview_Question_Event_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  type?: InputMaybe<Video_Interview_Question_Event_Type_Enum>;
  video_interview_question?: InputMaybe<Video_Interview_Question_Obj_Rel_Insert_Input>;
  video_interview_question_event_type?: InputMaybe<Video_Interview_Question_Event_Type_Obj_Rel_Insert_Input>;
  video_interview_question_id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Video_Interview_Question_Event_Max_Fields = {
  __typename?: "video_interview_question_event_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["bigint"]>;
  video_interview_question_id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "video_interview_question_event" */
export type Video_Interview_Question_Event_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  video_interview_question_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Video_Interview_Question_Event_Min_Fields = {
  __typename?: "video_interview_question_event_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["bigint"]>;
  video_interview_question_id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "video_interview_question_event" */
export type Video_Interview_Question_Event_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  video_interview_question_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "video_interview_question_event" */
export type Video_Interview_Question_Event_Mutation_Response = {
  __typename?: "video_interview_question_event_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Video_Interview_Question_Event>;
};

/** on_conflict condition type for table "video_interview_question_event" */
export type Video_Interview_Question_Event_On_Conflict = {
  constraint: Video_Interview_Question_Event_Constraint;
  update_columns?: Array<Video_Interview_Question_Event_Update_Column>;
  where?: InputMaybe<Video_Interview_Question_Event_Bool_Exp>;
};

/** Ordering options when selecting data from "video_interview_question_event". */
export type Video_Interview_Question_Event_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  video_interview_question?: InputMaybe<Video_Interview_Question_Order_By>;
  video_interview_question_event_type?: InputMaybe<Video_Interview_Question_Event_Type_Order_By>;
  video_interview_question_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: video_interview_question_event */
export type Video_Interview_Question_Event_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "video_interview_question_event" */
export enum Video_Interview_Question_Event_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Type = "type",
  /** column name */
  VideoInterviewQuestionId = "video_interview_question_id",
}

/** input type for updating data in table "video_interview_question_event" */
export type Video_Interview_Question_Event_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  type?: InputMaybe<Video_Interview_Question_Event_Type_Enum>;
  video_interview_question_id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Video_Interview_Question_Event_Stddev_Fields = {
  __typename?: "video_interview_question_event_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  video_interview_question_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "video_interview_question_event" */
export type Video_Interview_Question_Event_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  video_interview_question_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Video_Interview_Question_Event_Stddev_Pop_Fields = {
  __typename?: "video_interview_question_event_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  video_interview_question_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "video_interview_question_event" */
export type Video_Interview_Question_Event_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  video_interview_question_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Video_Interview_Question_Event_Stddev_Samp_Fields = {
  __typename?: "video_interview_question_event_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  video_interview_question_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "video_interview_question_event" */
export type Video_Interview_Question_Event_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  video_interview_question_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "video_interview_question_event" */
export type Video_Interview_Question_Event_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Video_Interview_Question_Event_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Video_Interview_Question_Event_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["bigint"]>;
  type?: InputMaybe<Video_Interview_Question_Event_Type_Enum>;
  video_interview_question_id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Video_Interview_Question_Event_Sum_Fields = {
  __typename?: "video_interview_question_event_sum_fields";
  id?: Maybe<Scalars["bigint"]>;
  video_interview_question_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "video_interview_question_event" */
export type Video_Interview_Question_Event_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  video_interview_question_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "video_interview_question_event_type" */
export type Video_Interview_Question_Event_Type = {
  __typename?: "video_interview_question_event_type";
  type: Scalars["String"];
};

/** aggregated selection of "video_interview_question_event_type" */
export type Video_Interview_Question_Event_Type_Aggregate = {
  __typename?: "video_interview_question_event_type_aggregate";
  aggregate?: Maybe<Video_Interview_Question_Event_Type_Aggregate_Fields>;
  nodes: Array<Video_Interview_Question_Event_Type>;
};

/** aggregate fields of "video_interview_question_event_type" */
export type Video_Interview_Question_Event_Type_Aggregate_Fields = {
  __typename?: "video_interview_question_event_type_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Video_Interview_Question_Event_Type_Max_Fields>;
  min?: Maybe<Video_Interview_Question_Event_Type_Min_Fields>;
};

/** aggregate fields of "video_interview_question_event_type" */
export type Video_Interview_Question_Event_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<
    Array<Video_Interview_Question_Event_Type_Select_Column>
  >;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "video_interview_question_event_type". All fields are combined with a logical 'AND'. */
export type Video_Interview_Question_Event_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Video_Interview_Question_Event_Type_Bool_Exp>>;
  _not?: InputMaybe<Video_Interview_Question_Event_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Video_Interview_Question_Event_Type_Bool_Exp>>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "video_interview_question_event_type" */
export enum Video_Interview_Question_Event_Type_Constraint {
  /** unique or primary key constraint on columns "type" */
  VideoInterviewQuestionEventTypePkey = "video_interview_question_event_type_pkey",
}

export enum Video_Interview_Question_Event_Type_Enum {
  AudioSourceEnded = "AUDIO_SOURCE_ENDED",
  VideoSourceEnded = "VIDEO_SOURCE_ENDED",
  VisibilityHidden = "VISIBILITY_HIDDEN",
  WindowBlurred = "WINDOW_BLURRED",
  WindowResized = "WINDOW_RESIZED",
}

/** Boolean expression to compare columns of type "video_interview_question_event_type_enum". All fields are combined with logical 'AND'. */
export type Video_Interview_Question_Event_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Video_Interview_Question_Event_Type_Enum>;
  _in?: InputMaybe<Array<Video_Interview_Question_Event_Type_Enum>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _neq?: InputMaybe<Video_Interview_Question_Event_Type_Enum>;
  _nin?: InputMaybe<Array<Video_Interview_Question_Event_Type_Enum>>;
};

/** input type for inserting data into table "video_interview_question_event_type" */
export type Video_Interview_Question_Event_Type_Insert_Input = {
  type?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Video_Interview_Question_Event_Type_Max_Fields = {
  __typename?: "video_interview_question_event_type_max_fields";
  type?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Video_Interview_Question_Event_Type_Min_Fields = {
  __typename?: "video_interview_question_event_type_min_fields";
  type?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "video_interview_question_event_type" */
export type Video_Interview_Question_Event_Type_Mutation_Response = {
  __typename?: "video_interview_question_event_type_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Video_Interview_Question_Event_Type>;
};

/** input type for inserting object relation for remote table "video_interview_question_event_type" */
export type Video_Interview_Question_Event_Type_Obj_Rel_Insert_Input = {
  data: Video_Interview_Question_Event_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Video_Interview_Question_Event_Type_On_Conflict>;
};

/** on_conflict condition type for table "video_interview_question_event_type" */
export type Video_Interview_Question_Event_Type_On_Conflict = {
  constraint: Video_Interview_Question_Event_Type_Constraint;
  update_columns?: Array<Video_Interview_Question_Event_Type_Update_Column>;
  where?: InputMaybe<Video_Interview_Question_Event_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "video_interview_question_event_type". */
export type Video_Interview_Question_Event_Type_Order_By = {
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: video_interview_question_event_type */
export type Video_Interview_Question_Event_Type_Pk_Columns_Input = {
  type: Scalars["String"];
};

/** select columns of table "video_interview_question_event_type" */
export enum Video_Interview_Question_Event_Type_Select_Column {
  /** column name */
  Type = "type",
}

/** input type for updating data in table "video_interview_question_event_type" */
export type Video_Interview_Question_Event_Type_Set_Input = {
  type?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "video_interview_question_event_type" */
export type Video_Interview_Question_Event_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Video_Interview_Question_Event_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Video_Interview_Question_Event_Type_Stream_Cursor_Value_Input = {
  type?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "video_interview_question_event_type" */
export enum Video_Interview_Question_Event_Type_Update_Column {
  /** column name */
  Type = "type",
}

export type Video_Interview_Question_Event_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Video_Interview_Question_Event_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Video_Interview_Question_Event_Type_Bool_Exp;
};

/** update columns of table "video_interview_question_event" */
export enum Video_Interview_Question_Event_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Type = "type",
  /** column name */
  VideoInterviewQuestionId = "video_interview_question_id",
}

export type Video_Interview_Question_Event_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Video_Interview_Question_Event_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Video_Interview_Question_Event_Set_Input>;
  /** filter the rows which have to be updated */
  where: Video_Interview_Question_Event_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Video_Interview_Question_Event_Var_Pop_Fields = {
  __typename?: "video_interview_question_event_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  video_interview_question_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "video_interview_question_event" */
export type Video_Interview_Question_Event_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  video_interview_question_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Video_Interview_Question_Event_Var_Samp_Fields = {
  __typename?: "video_interview_question_event_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  video_interview_question_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "video_interview_question_event" */
export type Video_Interview_Question_Event_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  video_interview_question_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Video_Interview_Question_Event_Variance_Fields = {
  __typename?: "video_interview_question_event_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  video_interview_question_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "video_interview_question_event" */
export type Video_Interview_Question_Event_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  video_interview_question_id?: InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "video_interview_question" */
export type Video_Interview_Question_Inc_Input = {
  id?: InputMaybe<Scalars["Int"]>;
  review_metric_question_id?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "video_interview_question" */
export type Video_Interview_Question_Insert_Input = {
  answer_transcript?: InputMaybe<Scalars["String"]>;
  answered_at?: InputMaybe<Scalars["timestamptz"]>;
  corrected_answer_transcript?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  original_answer_transcript?: InputMaybe<Scalars["String"]>;
  review_metric_question?: InputMaybe<Review_Metric_Question_Obj_Rel_Insert_Input>;
  review_metric_question_id?: InputMaybe<Scalars["Int"]>;
  storage_filename?: InputMaybe<Scalars["String"]>;
  upload_ends_at?: InputMaybe<Scalars["timestamptz"]>;
  upload_starts_at?: InputMaybe<Scalars["timestamptz"]>;
  video_interview?: InputMaybe<Video_Interview_Obj_Rel_Insert_Input>;
  video_interview_id?: InputMaybe<Scalars["uuid"]>;
  video_interview_question_events?: InputMaybe<Video_Interview_Question_Event_Arr_Rel_Insert_Input>;
  video_interview_score?: InputMaybe<Video_Interview_Question_Score_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Video_Interview_Question_Max_Fields = {
  __typename?: "video_interview_question_max_fields";
  answer_transcript?: Maybe<Scalars["String"]>;
  answered_at?: Maybe<Scalars["timestamptz"]>;
  corrected_answer_transcript?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  original_answer_transcript?: Maybe<Scalars["String"]>;
  review_metric_question_id?: Maybe<Scalars["Int"]>;
  storage_filename?: Maybe<Scalars["String"]>;
  upload_ends_at?: Maybe<Scalars["timestamptz"]>;
  upload_starts_at?: Maybe<Scalars["timestamptz"]>;
  video_interview_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "video_interview_question" */
export type Video_Interview_Question_Max_Order_By = {
  answer_transcript?: InputMaybe<Order_By>;
  answered_at?: InputMaybe<Order_By>;
  corrected_answer_transcript?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  original_answer_transcript?: InputMaybe<Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
  storage_filename?: InputMaybe<Order_By>;
  upload_ends_at?: InputMaybe<Order_By>;
  upload_starts_at?: InputMaybe<Order_By>;
  video_interview_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Video_Interview_Question_Min_Fields = {
  __typename?: "video_interview_question_min_fields";
  answer_transcript?: Maybe<Scalars["String"]>;
  answered_at?: Maybe<Scalars["timestamptz"]>;
  corrected_answer_transcript?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  original_answer_transcript?: Maybe<Scalars["String"]>;
  review_metric_question_id?: Maybe<Scalars["Int"]>;
  storage_filename?: Maybe<Scalars["String"]>;
  upload_ends_at?: Maybe<Scalars["timestamptz"]>;
  upload_starts_at?: Maybe<Scalars["timestamptz"]>;
  video_interview_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "video_interview_question" */
export type Video_Interview_Question_Min_Order_By = {
  answer_transcript?: InputMaybe<Order_By>;
  answered_at?: InputMaybe<Order_By>;
  corrected_answer_transcript?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  original_answer_transcript?: InputMaybe<Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
  storage_filename?: InputMaybe<Order_By>;
  upload_ends_at?: InputMaybe<Order_By>;
  upload_starts_at?: InputMaybe<Order_By>;
  video_interview_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "video_interview_question" */
export type Video_Interview_Question_Mutation_Response = {
  __typename?: "video_interview_question_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Video_Interview_Question>;
};

/** input type for inserting object relation for remote table "video_interview_question" */
export type Video_Interview_Question_Obj_Rel_Insert_Input = {
  data: Video_Interview_Question_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Video_Interview_Question_On_Conflict>;
};

/** on_conflict condition type for table "video_interview_question" */
export type Video_Interview_Question_On_Conflict = {
  constraint: Video_Interview_Question_Constraint;
  update_columns?: Array<Video_Interview_Question_Update_Column>;
  where?: InputMaybe<Video_Interview_Question_Bool_Exp>;
};

/** Ordering options when selecting data from "video_interview_question". */
export type Video_Interview_Question_Order_By = {
  answer_transcript?: InputMaybe<Order_By>;
  answered_at?: InputMaybe<Order_By>;
  corrected_answer_transcript?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  original_answer_transcript?: InputMaybe<Order_By>;
  review_metric_question?: InputMaybe<Review_Metric_Question_Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
  storage_filename?: InputMaybe<Order_By>;
  upload_ends_at?: InputMaybe<Order_By>;
  upload_starts_at?: InputMaybe<Order_By>;
  video_interview?: InputMaybe<Video_Interview_Order_By>;
  video_interview_id?: InputMaybe<Order_By>;
  video_interview_question_events_aggregate?: InputMaybe<Video_Interview_Question_Event_Aggregate_Order_By>;
  video_interview_score_aggregate?: InputMaybe<Video_Interview_Question_Score_Aggregate_Order_By>;
};

/** primary key columns input for table: video_interview_question */
export type Video_Interview_Question_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** columns and relationships of "video_interview_question_score" */
export type Video_Interview_Question_Score = {
  __typename?: "video_interview_question_score";
  created_at: Scalars["timestamptz"];
  id: Scalars["Int"];
  is_ai_score: Scalars["Boolean"];
  /** An object relationship */
  question: Video_Interview_Question;
  /** An object relationship */
  review_metric_question_response: Review_Metric_Question_Response;
  review_metric_question_response_id: Scalars["Int"];
  score_description?: Maybe<Scalars["String"]>;
  scored_by?: Maybe<Scalars["uuid"]>;
  video_interview_question_id: Scalars["Int"];
  /** An object relationship */
  video_interview_scored_by?: Maybe<User>;
};

/** aggregated selection of "video_interview_question_score" */
export type Video_Interview_Question_Score_Aggregate = {
  __typename?: "video_interview_question_score_aggregate";
  aggregate?: Maybe<Video_Interview_Question_Score_Aggregate_Fields>;
  nodes: Array<Video_Interview_Question_Score>;
};

export type Video_Interview_Question_Score_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Video_Interview_Question_Score_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Video_Interview_Question_Score_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Video_Interview_Question_Score_Aggregate_Bool_Exp_Count>;
};

export type Video_Interview_Question_Score_Aggregate_Bool_Exp_Bool_And = {
  arguments: Video_Interview_Question_Score_Select_Column_Video_Interview_Question_Score_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Video_Interview_Question_Score_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Video_Interview_Question_Score_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Video_Interview_Question_Score_Select_Column_Video_Interview_Question_Score_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Video_Interview_Question_Score_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Video_Interview_Question_Score_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Video_Interview_Question_Score_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Video_Interview_Question_Score_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "video_interview_question_score" */
export type Video_Interview_Question_Score_Aggregate_Fields = {
  __typename?: "video_interview_question_score_aggregate_fields";
  avg?: Maybe<Video_Interview_Question_Score_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Video_Interview_Question_Score_Max_Fields>;
  min?: Maybe<Video_Interview_Question_Score_Min_Fields>;
  stddev?: Maybe<Video_Interview_Question_Score_Stddev_Fields>;
  stddev_pop?: Maybe<Video_Interview_Question_Score_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Video_Interview_Question_Score_Stddev_Samp_Fields>;
  sum?: Maybe<Video_Interview_Question_Score_Sum_Fields>;
  var_pop?: Maybe<Video_Interview_Question_Score_Var_Pop_Fields>;
  var_samp?: Maybe<Video_Interview_Question_Score_Var_Samp_Fields>;
  variance?: Maybe<Video_Interview_Question_Score_Variance_Fields>;
};

/** aggregate fields of "video_interview_question_score" */
export type Video_Interview_Question_Score_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Video_Interview_Question_Score_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "video_interview_question_score" */
export type Video_Interview_Question_Score_Aggregate_Order_By = {
  avg?: InputMaybe<Video_Interview_Question_Score_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Video_Interview_Question_Score_Max_Order_By>;
  min?: InputMaybe<Video_Interview_Question_Score_Min_Order_By>;
  stddev?: InputMaybe<Video_Interview_Question_Score_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Video_Interview_Question_Score_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Video_Interview_Question_Score_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Video_Interview_Question_Score_Sum_Order_By>;
  var_pop?: InputMaybe<Video_Interview_Question_Score_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Video_Interview_Question_Score_Var_Samp_Order_By>;
  variance?: InputMaybe<Video_Interview_Question_Score_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "video_interview_question_score" */
export type Video_Interview_Question_Score_Arr_Rel_Insert_Input = {
  data: Array<Video_Interview_Question_Score_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Video_Interview_Question_Score_On_Conflict>;
};

/** aggregate avg on columns */
export type Video_Interview_Question_Score_Avg_Fields = {
  __typename?: "video_interview_question_score_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  review_metric_question_response_id?: Maybe<Scalars["Float"]>;
  video_interview_question_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "video_interview_question_score" */
export type Video_Interview_Question_Score_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  review_metric_question_response_id?: InputMaybe<Order_By>;
  video_interview_question_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "video_interview_question_score". All fields are combined with a logical 'AND'. */
export type Video_Interview_Question_Score_Bool_Exp = {
  _and?: InputMaybe<Array<Video_Interview_Question_Score_Bool_Exp>>;
  _not?: InputMaybe<Video_Interview_Question_Score_Bool_Exp>;
  _or?: InputMaybe<Array<Video_Interview_Question_Score_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_ai_score?: InputMaybe<Boolean_Comparison_Exp>;
  question?: InputMaybe<Video_Interview_Question_Bool_Exp>;
  review_metric_question_response?: InputMaybe<Review_Metric_Question_Response_Bool_Exp>;
  review_metric_question_response_id?: InputMaybe<Int_Comparison_Exp>;
  score_description?: InputMaybe<String_Comparison_Exp>;
  scored_by?: InputMaybe<Uuid_Comparison_Exp>;
  video_interview_question_id?: InputMaybe<Int_Comparison_Exp>;
  video_interview_scored_by?: InputMaybe<User_Bool_Exp>;
};

/** unique or primary key constraints on table "video_interview_question_score" */
export enum Video_Interview_Question_Score_Constraint {
  /** unique or primary key constraint on columns "id" */
  InterviewScorePkey = "interview_score_pkey",
  /** unique or primary key constraint on columns "is_ai_score", "video_interview_question_id" */
  VideoInterviewScoreVideoInterviewQuestionIdIsAiScoreK = "video_interview_score_video_interview_question_id_is_ai_score_k",
}

/** input type for incrementing numeric columns in table "video_interview_question_score" */
export type Video_Interview_Question_Score_Inc_Input = {
  id?: InputMaybe<Scalars["Int"]>;
  review_metric_question_response_id?: InputMaybe<Scalars["Int"]>;
  video_interview_question_id?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "video_interview_question_score" */
export type Video_Interview_Question_Score_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["Int"]>;
  is_ai_score?: InputMaybe<Scalars["Boolean"]>;
  question?: InputMaybe<Video_Interview_Question_Obj_Rel_Insert_Input>;
  review_metric_question_response?: InputMaybe<Review_Metric_Question_Response_Obj_Rel_Insert_Input>;
  review_metric_question_response_id?: InputMaybe<Scalars["Int"]>;
  score_description?: InputMaybe<Scalars["String"]>;
  scored_by?: InputMaybe<Scalars["uuid"]>;
  video_interview_question_id?: InputMaybe<Scalars["Int"]>;
  video_interview_scored_by?: InputMaybe<User_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Video_Interview_Question_Score_Max_Fields = {
  __typename?: "video_interview_question_score_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  review_metric_question_response_id?: Maybe<Scalars["Int"]>;
  score_description?: Maybe<Scalars["String"]>;
  scored_by?: Maybe<Scalars["uuid"]>;
  video_interview_question_id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "video_interview_question_score" */
export type Video_Interview_Question_Score_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  review_metric_question_response_id?: InputMaybe<Order_By>;
  score_description?: InputMaybe<Order_By>;
  scored_by?: InputMaybe<Order_By>;
  video_interview_question_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Video_Interview_Question_Score_Min_Fields = {
  __typename?: "video_interview_question_score_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  review_metric_question_response_id?: Maybe<Scalars["Int"]>;
  score_description?: Maybe<Scalars["String"]>;
  scored_by?: Maybe<Scalars["uuid"]>;
  video_interview_question_id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "video_interview_question_score" */
export type Video_Interview_Question_Score_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  review_metric_question_response_id?: InputMaybe<Order_By>;
  score_description?: InputMaybe<Order_By>;
  scored_by?: InputMaybe<Order_By>;
  video_interview_question_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "video_interview_question_score" */
export type Video_Interview_Question_Score_Mutation_Response = {
  __typename?: "video_interview_question_score_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Video_Interview_Question_Score>;
};

/** on_conflict condition type for table "video_interview_question_score" */
export type Video_Interview_Question_Score_On_Conflict = {
  constraint: Video_Interview_Question_Score_Constraint;
  update_columns?: Array<Video_Interview_Question_Score_Update_Column>;
  where?: InputMaybe<Video_Interview_Question_Score_Bool_Exp>;
};

/** Ordering options when selecting data from "video_interview_question_score". */
export type Video_Interview_Question_Score_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_ai_score?: InputMaybe<Order_By>;
  question?: InputMaybe<Video_Interview_Question_Order_By>;
  review_metric_question_response?: InputMaybe<Review_Metric_Question_Response_Order_By>;
  review_metric_question_response_id?: InputMaybe<Order_By>;
  score_description?: InputMaybe<Order_By>;
  scored_by?: InputMaybe<Order_By>;
  video_interview_question_id?: InputMaybe<Order_By>;
  video_interview_scored_by?: InputMaybe<User_Order_By>;
};

/** primary key columns input for table: video_interview_question_score */
export type Video_Interview_Question_Score_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "video_interview_question_score" */
export enum Video_Interview_Question_Score_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsAiScore = "is_ai_score",
  /** column name */
  ReviewMetricQuestionResponseId = "review_metric_question_response_id",
  /** column name */
  ScoreDescription = "score_description",
  /** column name */
  ScoredBy = "scored_by",
  /** column name */
  VideoInterviewQuestionId = "video_interview_question_id",
}

/** select "video_interview_question_score_aggregate_bool_exp_bool_and_arguments_columns" columns of table "video_interview_question_score" */
export enum Video_Interview_Question_Score_Select_Column_Video_Interview_Question_Score_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsAiScore = "is_ai_score",
}

/** select "video_interview_question_score_aggregate_bool_exp_bool_or_arguments_columns" columns of table "video_interview_question_score" */
export enum Video_Interview_Question_Score_Select_Column_Video_Interview_Question_Score_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsAiScore = "is_ai_score",
}

/** input type for updating data in table "video_interview_question_score" */
export type Video_Interview_Question_Score_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["Int"]>;
  is_ai_score?: InputMaybe<Scalars["Boolean"]>;
  review_metric_question_response_id?: InputMaybe<Scalars["Int"]>;
  score_description?: InputMaybe<Scalars["String"]>;
  scored_by?: InputMaybe<Scalars["uuid"]>;
  video_interview_question_id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Video_Interview_Question_Score_Stddev_Fields = {
  __typename?: "video_interview_question_score_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  review_metric_question_response_id?: Maybe<Scalars["Float"]>;
  video_interview_question_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "video_interview_question_score" */
export type Video_Interview_Question_Score_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  review_metric_question_response_id?: InputMaybe<Order_By>;
  video_interview_question_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Video_Interview_Question_Score_Stddev_Pop_Fields = {
  __typename?: "video_interview_question_score_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  review_metric_question_response_id?: Maybe<Scalars["Float"]>;
  video_interview_question_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "video_interview_question_score" */
export type Video_Interview_Question_Score_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  review_metric_question_response_id?: InputMaybe<Order_By>;
  video_interview_question_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Video_Interview_Question_Score_Stddev_Samp_Fields = {
  __typename?: "video_interview_question_score_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  review_metric_question_response_id?: Maybe<Scalars["Float"]>;
  video_interview_question_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "video_interview_question_score" */
export type Video_Interview_Question_Score_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  review_metric_question_response_id?: InputMaybe<Order_By>;
  video_interview_question_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "video_interview_question_score" */
export type Video_Interview_Question_Score_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Video_Interview_Question_Score_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Video_Interview_Question_Score_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["Int"]>;
  is_ai_score?: InputMaybe<Scalars["Boolean"]>;
  review_metric_question_response_id?: InputMaybe<Scalars["Int"]>;
  score_description?: InputMaybe<Scalars["String"]>;
  scored_by?: InputMaybe<Scalars["uuid"]>;
  video_interview_question_id?: InputMaybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Video_Interview_Question_Score_Sum_Fields = {
  __typename?: "video_interview_question_score_sum_fields";
  id?: Maybe<Scalars["Int"]>;
  review_metric_question_response_id?: Maybe<Scalars["Int"]>;
  video_interview_question_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "video_interview_question_score" */
export type Video_Interview_Question_Score_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  review_metric_question_response_id?: InputMaybe<Order_By>;
  video_interview_question_id?: InputMaybe<Order_By>;
};

/** update columns of table "video_interview_question_score" */
export enum Video_Interview_Question_Score_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsAiScore = "is_ai_score",
  /** column name */
  ReviewMetricQuestionResponseId = "review_metric_question_response_id",
  /** column name */
  ScoreDescription = "score_description",
  /** column name */
  ScoredBy = "scored_by",
  /** column name */
  VideoInterviewQuestionId = "video_interview_question_id",
}

export type Video_Interview_Question_Score_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Video_Interview_Question_Score_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Video_Interview_Question_Score_Set_Input>;
  /** filter the rows which have to be updated */
  where: Video_Interview_Question_Score_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Video_Interview_Question_Score_Var_Pop_Fields = {
  __typename?: "video_interview_question_score_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  review_metric_question_response_id?: Maybe<Scalars["Float"]>;
  video_interview_question_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "video_interview_question_score" */
export type Video_Interview_Question_Score_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  review_metric_question_response_id?: InputMaybe<Order_By>;
  video_interview_question_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Video_Interview_Question_Score_Var_Samp_Fields = {
  __typename?: "video_interview_question_score_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  review_metric_question_response_id?: Maybe<Scalars["Float"]>;
  video_interview_question_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "video_interview_question_score" */
export type Video_Interview_Question_Score_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  review_metric_question_response_id?: InputMaybe<Order_By>;
  video_interview_question_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Video_Interview_Question_Score_Variance_Fields = {
  __typename?: "video_interview_question_score_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  review_metric_question_response_id?: Maybe<Scalars["Float"]>;
  video_interview_question_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "video_interview_question_score" */
export type Video_Interview_Question_Score_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  review_metric_question_response_id?: InputMaybe<Order_By>;
  video_interview_question_id?: InputMaybe<Order_By>;
};

/** select columns of table "video_interview_question" */
export enum Video_Interview_Question_Select_Column {
  /** column name */
  AnswerTranscript = "answer_transcript",
  /** column name */
  AnsweredAt = "answered_at",
  /** column name */
  CorrectedAnswerTranscript = "corrected_answer_transcript",
  /** column name */
  Id = "id",
  /** column name */
  OriginalAnswerTranscript = "original_answer_transcript",
  /** column name */
  ReviewMetricQuestionId = "review_metric_question_id",
  /** column name */
  StorageFilename = "storage_filename",
  /** column name */
  UploadEndsAt = "upload_ends_at",
  /** column name */
  UploadStartsAt = "upload_starts_at",
  /** column name */
  VideoInterviewId = "video_interview_id",
}

/** input type for updating data in table "video_interview_question" */
export type Video_Interview_Question_Set_Input = {
  answer_transcript?: InputMaybe<Scalars["String"]>;
  answered_at?: InputMaybe<Scalars["timestamptz"]>;
  corrected_answer_transcript?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  original_answer_transcript?: InputMaybe<Scalars["String"]>;
  review_metric_question_id?: InputMaybe<Scalars["Int"]>;
  storage_filename?: InputMaybe<Scalars["String"]>;
  upload_ends_at?: InputMaybe<Scalars["timestamptz"]>;
  upload_starts_at?: InputMaybe<Scalars["timestamptz"]>;
  video_interview_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type Video_Interview_Question_Stddev_Fields = {
  __typename?: "video_interview_question_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  review_metric_question_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "video_interview_question" */
export type Video_Interview_Question_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Video_Interview_Question_Stddev_Pop_Fields = {
  __typename?: "video_interview_question_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  review_metric_question_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "video_interview_question" */
export type Video_Interview_Question_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Video_Interview_Question_Stddev_Samp_Fields = {
  __typename?: "video_interview_question_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  review_metric_question_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "video_interview_question" */
export type Video_Interview_Question_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "video_interview_question" */
export type Video_Interview_Question_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Video_Interview_Question_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Video_Interview_Question_Stream_Cursor_Value_Input = {
  answer_transcript?: InputMaybe<Scalars["String"]>;
  answered_at?: InputMaybe<Scalars["timestamptz"]>;
  corrected_answer_transcript?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  original_answer_transcript?: InputMaybe<Scalars["String"]>;
  review_metric_question_id?: InputMaybe<Scalars["Int"]>;
  storage_filename?: InputMaybe<Scalars["String"]>;
  upload_ends_at?: InputMaybe<Scalars["timestamptz"]>;
  upload_starts_at?: InputMaybe<Scalars["timestamptz"]>;
  video_interview_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate sum on columns */
export type Video_Interview_Question_Sum_Fields = {
  __typename?: "video_interview_question_sum_fields";
  id?: Maybe<Scalars["Int"]>;
  review_metric_question_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "video_interview_question" */
export type Video_Interview_Question_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
};

/** update columns of table "video_interview_question" */
export enum Video_Interview_Question_Update_Column {
  /** column name */
  AnswerTranscript = "answer_transcript",
  /** column name */
  AnsweredAt = "answered_at",
  /** column name */
  CorrectedAnswerTranscript = "corrected_answer_transcript",
  /** column name */
  Id = "id",
  /** column name */
  OriginalAnswerTranscript = "original_answer_transcript",
  /** column name */
  ReviewMetricQuestionId = "review_metric_question_id",
  /** column name */
  StorageFilename = "storage_filename",
  /** column name */
  UploadEndsAt = "upload_ends_at",
  /** column name */
  UploadStartsAt = "upload_starts_at",
  /** column name */
  VideoInterviewId = "video_interview_id",
}

export type Video_Interview_Question_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Video_Interview_Question_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Video_Interview_Question_Set_Input>;
  /** filter the rows which have to be updated */
  where: Video_Interview_Question_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Video_Interview_Question_Var_Pop_Fields = {
  __typename?: "video_interview_question_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  review_metric_question_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "video_interview_question" */
export type Video_Interview_Question_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Video_Interview_Question_Var_Samp_Fields = {
  __typename?: "video_interview_question_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  review_metric_question_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "video_interview_question" */
export type Video_Interview_Question_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Video_Interview_Question_Variance_Fields = {
  __typename?: "video_interview_question_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  review_metric_question_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "video_interview_question" */
export type Video_Interview_Question_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "video_interview_reviewer" */
export type Video_Interview_Reviewer = {
  __typename?: "video_interview_reviewer";
  reviewer: Scalars["String"];
};

/** aggregated selection of "video_interview_reviewer" */
export type Video_Interview_Reviewer_Aggregate = {
  __typename?: "video_interview_reviewer_aggregate";
  aggregate?: Maybe<Video_Interview_Reviewer_Aggregate_Fields>;
  nodes: Array<Video_Interview_Reviewer>;
};

/** aggregate fields of "video_interview_reviewer" */
export type Video_Interview_Reviewer_Aggregate_Fields = {
  __typename?: "video_interview_reviewer_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Video_Interview_Reviewer_Max_Fields>;
  min?: Maybe<Video_Interview_Reviewer_Min_Fields>;
};

/** aggregate fields of "video_interview_reviewer" */
export type Video_Interview_Reviewer_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Video_Interview_Reviewer_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "video_interview_reviewer". All fields are combined with a logical 'AND'. */
export type Video_Interview_Reviewer_Bool_Exp = {
  _and?: InputMaybe<Array<Video_Interview_Reviewer_Bool_Exp>>;
  _not?: InputMaybe<Video_Interview_Reviewer_Bool_Exp>;
  _or?: InputMaybe<Array<Video_Interview_Reviewer_Bool_Exp>>;
  reviewer?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "video_interview_reviewer" */
export enum Video_Interview_Reviewer_Constraint {
  /** unique or primary key constraint on columns "reviewer" */
  VideoInterviewReviewerPkey = "video_interview_reviewer_pkey",
}

export enum Video_Interview_Reviewer_Enum {
  Ai = "AI",
  Human = "HUMAN",
}

/** Boolean expression to compare columns of type "video_interview_reviewer_enum". All fields are combined with logical 'AND'. */
export type Video_Interview_Reviewer_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Video_Interview_Reviewer_Enum>;
  _in?: InputMaybe<Array<Video_Interview_Reviewer_Enum>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _neq?: InputMaybe<Video_Interview_Reviewer_Enum>;
  _nin?: InputMaybe<Array<Video_Interview_Reviewer_Enum>>;
};

/** input type for inserting data into table "video_interview_reviewer" */
export type Video_Interview_Reviewer_Insert_Input = {
  reviewer?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Video_Interview_Reviewer_Max_Fields = {
  __typename?: "video_interview_reviewer_max_fields";
  reviewer?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Video_Interview_Reviewer_Min_Fields = {
  __typename?: "video_interview_reviewer_min_fields";
  reviewer?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "video_interview_reviewer" */
export type Video_Interview_Reviewer_Mutation_Response = {
  __typename?: "video_interview_reviewer_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Video_Interview_Reviewer>;
};

/** on_conflict condition type for table "video_interview_reviewer" */
export type Video_Interview_Reviewer_On_Conflict = {
  constraint: Video_Interview_Reviewer_Constraint;
  update_columns?: Array<Video_Interview_Reviewer_Update_Column>;
  where?: InputMaybe<Video_Interview_Reviewer_Bool_Exp>;
};

/** Ordering options when selecting data from "video_interview_reviewer". */
export type Video_Interview_Reviewer_Order_By = {
  reviewer?: InputMaybe<Order_By>;
};

/** primary key columns input for table: video_interview_reviewer */
export type Video_Interview_Reviewer_Pk_Columns_Input = {
  reviewer: Scalars["String"];
};

/** select columns of table "video_interview_reviewer" */
export enum Video_Interview_Reviewer_Select_Column {
  /** column name */
  Reviewer = "reviewer",
}

/** input type for updating data in table "video_interview_reviewer" */
export type Video_Interview_Reviewer_Set_Input = {
  reviewer?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "video_interview_reviewer" */
export type Video_Interview_Reviewer_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Video_Interview_Reviewer_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Video_Interview_Reviewer_Stream_Cursor_Value_Input = {
  reviewer?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "video_interview_reviewer" */
export enum Video_Interview_Reviewer_Update_Column {
  /** column name */
  Reviewer = "reviewer",
}

export type Video_Interview_Reviewer_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Video_Interview_Reviewer_Set_Input>;
  /** filter the rows which have to be updated */
  where: Video_Interview_Reviewer_Bool_Exp;
};

/** select columns of table "video_interview" */
export enum Video_Interview_Select_Column {
  /** column name */
  ClientCandidateProjectId = "client_candidate_project_id",
  /** column name */
  CompletedAt = "completed_at",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InterviewTemplateId = "interview_template_id",
  /** column name */
  InterviewVideoUrl = "interview_video_url",
  /** column name */
  Reviewer = "reviewer",
  /** column name */
  Score = "score",
  /** column name */
  StartedAt = "started_at",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  VideoInterviewType = "video_interview_type",
  /** column name */
  VideoProcessStatus = "video_process_status",
}

/** input type for updating data in table "video_interview" */
export type Video_Interview_Set_Input = {
  client_candidate_project_id?: InputMaybe<Scalars["Int"]>;
  completed_at?: InputMaybe<Scalars["timestamptz"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  interview_template_id?: InputMaybe<Scalars["Int"]>;
  interview_video_url?: InputMaybe<Scalars["String"]>;
  reviewer?: InputMaybe<Video_Interview_Reviewer_Enum>;
  score?: InputMaybe<Scalars["Int"]>;
  started_at?: InputMaybe<Scalars["timestamptz"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  video_interview_type?: InputMaybe<Video_Interview_Type_Enum>;
  video_process_status?: InputMaybe<Video_Process_Status_Enum>;
};

/** columns and relationships of "video_interview_status" */
export type Video_Interview_Status = {
  __typename?: "video_interview_status";
  status: Scalars["String"];
};

/** aggregated selection of "video_interview_status" */
export type Video_Interview_Status_Aggregate = {
  __typename?: "video_interview_status_aggregate";
  aggregate?: Maybe<Video_Interview_Status_Aggregate_Fields>;
  nodes: Array<Video_Interview_Status>;
};

/** aggregate fields of "video_interview_status" */
export type Video_Interview_Status_Aggregate_Fields = {
  __typename?: "video_interview_status_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Video_Interview_Status_Max_Fields>;
  min?: Maybe<Video_Interview_Status_Min_Fields>;
};

/** aggregate fields of "video_interview_status" */
export type Video_Interview_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Video_Interview_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "video_interview_status". All fields are combined with a logical 'AND'. */
export type Video_Interview_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Video_Interview_Status_Bool_Exp>>;
  _not?: InputMaybe<Video_Interview_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Video_Interview_Status_Bool_Exp>>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "video_interview_status" */
export enum Video_Interview_Status_Constraint {
  /** unique or primary key constraint on columns "status" */
  VideoInterviewStatusPkey = "video_interview_status_pkey",
}

export enum Video_Interview_Status_Enum {
  CandidateInProgress = "CANDIDATE_IN_PROGRESS",
  CandidateNotInterested = "CANDIDATE_NOT_INTERESTED",
  Expired = "EXPIRED",
  InappropriateContent = "INAPPROPRIATE_CONTENT",
  InsufficientContent = "INSUFFICIENT_CONTENT",
  InReview = "IN_REVIEW",
  LinkSent = "LINK_SENT",
  NotCompleted = "NOT_COMPLETED",
  NotSet = "NOT_SET",
  NoAiReviewWanted = "NO_AI_REVIEW_WANTED",
  Scored = "SCORED",
  VideoReceived = "VIDEO_RECEIVED",
  VideoSent = "VIDEO_SENT",
}

/** Boolean expression to compare columns of type "video_interview_status_enum". All fields are combined with logical 'AND'. */
export type Video_Interview_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Video_Interview_Status_Enum>;
  _in?: InputMaybe<Array<Video_Interview_Status_Enum>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _neq?: InputMaybe<Video_Interview_Status_Enum>;
  _nin?: InputMaybe<Array<Video_Interview_Status_Enum>>;
};

/** History table for video interview status */
export type Video_Interview_Status_History = {
  __typename?: "video_interview_status_history";
  created_at: Scalars["timestamptz"];
  id: Scalars["Int"];
  status: Video_Interview_Status_Enum;
  /** An object relationship */
  video_interview: Video_Interview;
  video_interview_id: Scalars["uuid"];
  /** An object relationship */
  video_interview_status: Video_Interview_Status;
};

/** aggregated selection of "video_interview_status_history" */
export type Video_Interview_Status_History_Aggregate = {
  __typename?: "video_interview_status_history_aggregate";
  aggregate?: Maybe<Video_Interview_Status_History_Aggregate_Fields>;
  nodes: Array<Video_Interview_Status_History>;
};

export type Video_Interview_Status_History_Aggregate_Bool_Exp = {
  count?: InputMaybe<Video_Interview_Status_History_Aggregate_Bool_Exp_Count>;
};

export type Video_Interview_Status_History_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Video_Interview_Status_History_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Video_Interview_Status_History_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "video_interview_status_history" */
export type Video_Interview_Status_History_Aggregate_Fields = {
  __typename?: "video_interview_status_history_aggregate_fields";
  avg?: Maybe<Video_Interview_Status_History_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Video_Interview_Status_History_Max_Fields>;
  min?: Maybe<Video_Interview_Status_History_Min_Fields>;
  stddev?: Maybe<Video_Interview_Status_History_Stddev_Fields>;
  stddev_pop?: Maybe<Video_Interview_Status_History_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Video_Interview_Status_History_Stddev_Samp_Fields>;
  sum?: Maybe<Video_Interview_Status_History_Sum_Fields>;
  var_pop?: Maybe<Video_Interview_Status_History_Var_Pop_Fields>;
  var_samp?: Maybe<Video_Interview_Status_History_Var_Samp_Fields>;
  variance?: Maybe<Video_Interview_Status_History_Variance_Fields>;
};

/** aggregate fields of "video_interview_status_history" */
export type Video_Interview_Status_History_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Video_Interview_Status_History_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "video_interview_status_history" */
export type Video_Interview_Status_History_Aggregate_Order_By = {
  avg?: InputMaybe<Video_Interview_Status_History_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Video_Interview_Status_History_Max_Order_By>;
  min?: InputMaybe<Video_Interview_Status_History_Min_Order_By>;
  stddev?: InputMaybe<Video_Interview_Status_History_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Video_Interview_Status_History_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Video_Interview_Status_History_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Video_Interview_Status_History_Sum_Order_By>;
  var_pop?: InputMaybe<Video_Interview_Status_History_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Video_Interview_Status_History_Var_Samp_Order_By>;
  variance?: InputMaybe<Video_Interview_Status_History_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "video_interview_status_history" */
export type Video_Interview_Status_History_Arr_Rel_Insert_Input = {
  data: Array<Video_Interview_Status_History_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Video_Interview_Status_History_On_Conflict>;
};

/** aggregate avg on columns */
export type Video_Interview_Status_History_Avg_Fields = {
  __typename?: "video_interview_status_history_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "video_interview_status_history" */
export type Video_Interview_Status_History_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "video_interview_status_history". All fields are combined with a logical 'AND'. */
export type Video_Interview_Status_History_Bool_Exp = {
  _and?: InputMaybe<Array<Video_Interview_Status_History_Bool_Exp>>;
  _not?: InputMaybe<Video_Interview_Status_History_Bool_Exp>;
  _or?: InputMaybe<Array<Video_Interview_Status_History_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<Video_Interview_Status_Enum_Comparison_Exp>;
  video_interview?: InputMaybe<Video_Interview_Bool_Exp>;
  video_interview_id?: InputMaybe<Uuid_Comparison_Exp>;
  video_interview_status?: InputMaybe<Video_Interview_Status_Bool_Exp>;
};

/** unique or primary key constraints on table "video_interview_status_history" */
export enum Video_Interview_Status_History_Constraint {
  /** unique or primary key constraint on columns "id" */
  VideoInterviewStatusHistoryPkey = "video_interview_status_history_pkey",
}

/** input type for incrementing numeric columns in table "video_interview_status_history" */
export type Video_Interview_Status_History_Inc_Input = {
  id?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "video_interview_status_history" */
export type Video_Interview_Status_History_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<Video_Interview_Status_Enum>;
  video_interview?: InputMaybe<Video_Interview_Obj_Rel_Insert_Input>;
  video_interview_id?: InputMaybe<Scalars["uuid"]>;
  video_interview_status?: InputMaybe<Video_Interview_Status_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Video_Interview_Status_History_Max_Fields = {
  __typename?: "video_interview_status_history_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  video_interview_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "video_interview_status_history" */
export type Video_Interview_Status_History_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  video_interview_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Video_Interview_Status_History_Min_Fields = {
  __typename?: "video_interview_status_history_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  video_interview_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "video_interview_status_history" */
export type Video_Interview_Status_History_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  video_interview_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "video_interview_status_history" */
export type Video_Interview_Status_History_Mutation_Response = {
  __typename?: "video_interview_status_history_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Video_Interview_Status_History>;
};

/** on_conflict condition type for table "video_interview_status_history" */
export type Video_Interview_Status_History_On_Conflict = {
  constraint: Video_Interview_Status_History_Constraint;
  update_columns?: Array<Video_Interview_Status_History_Update_Column>;
  where?: InputMaybe<Video_Interview_Status_History_Bool_Exp>;
};

/** Ordering options when selecting data from "video_interview_status_history". */
export type Video_Interview_Status_History_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  video_interview?: InputMaybe<Video_Interview_Order_By>;
  video_interview_id?: InputMaybe<Order_By>;
  video_interview_status?: InputMaybe<Video_Interview_Status_Order_By>;
};

/** primary key columns input for table: video_interview_status_history */
export type Video_Interview_Status_History_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "video_interview_status_history" */
export enum Video_Interview_Status_History_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Status = "status",
  /** column name */
  VideoInterviewId = "video_interview_id",
}

/** input type for updating data in table "video_interview_status_history" */
export type Video_Interview_Status_History_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<Video_Interview_Status_Enum>;
  video_interview_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type Video_Interview_Status_History_Stddev_Fields = {
  __typename?: "video_interview_status_history_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "video_interview_status_history" */
export type Video_Interview_Status_History_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Video_Interview_Status_History_Stddev_Pop_Fields = {
  __typename?: "video_interview_status_history_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "video_interview_status_history" */
export type Video_Interview_Status_History_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Video_Interview_Status_History_Stddev_Samp_Fields = {
  __typename?: "video_interview_status_history_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "video_interview_status_history" */
export type Video_Interview_Status_History_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "video_interview_status_history" */
export type Video_Interview_Status_History_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Video_Interview_Status_History_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Video_Interview_Status_History_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<Video_Interview_Status_Enum>;
  video_interview_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate sum on columns */
export type Video_Interview_Status_History_Sum_Fields = {
  __typename?: "video_interview_status_history_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "video_interview_status_history" */
export type Video_Interview_Status_History_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "video_interview_status_history" */
export enum Video_Interview_Status_History_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Status = "status",
  /** column name */
  VideoInterviewId = "video_interview_id",
}

export type Video_Interview_Status_History_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Video_Interview_Status_History_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Video_Interview_Status_History_Set_Input>;
  /** filter the rows which have to be updated */
  where: Video_Interview_Status_History_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Video_Interview_Status_History_Var_Pop_Fields = {
  __typename?: "video_interview_status_history_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "video_interview_status_history" */
export type Video_Interview_Status_History_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Video_Interview_Status_History_Var_Samp_Fields = {
  __typename?: "video_interview_status_history_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "video_interview_status_history" */
export type Video_Interview_Status_History_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Video_Interview_Status_History_Variance_Fields = {
  __typename?: "video_interview_status_history_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "video_interview_status_history" */
export type Video_Interview_Status_History_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** input type for inserting data into table "video_interview_status" */
export type Video_Interview_Status_Insert_Input = {
  status?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Video_Interview_Status_Max_Fields = {
  __typename?: "video_interview_status_max_fields";
  status?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Video_Interview_Status_Min_Fields = {
  __typename?: "video_interview_status_min_fields";
  status?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "video_interview_status" */
export type Video_Interview_Status_Mutation_Response = {
  __typename?: "video_interview_status_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Video_Interview_Status>;
};

/** input type for inserting object relation for remote table "video_interview_status" */
export type Video_Interview_Status_Obj_Rel_Insert_Input = {
  data: Video_Interview_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Video_Interview_Status_On_Conflict>;
};

/** on_conflict condition type for table "video_interview_status" */
export type Video_Interview_Status_On_Conflict = {
  constraint: Video_Interview_Status_Constraint;
  update_columns?: Array<Video_Interview_Status_Update_Column>;
  where?: InputMaybe<Video_Interview_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "video_interview_status". */
export type Video_Interview_Status_Order_By = {
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: video_interview_status */
export type Video_Interview_Status_Pk_Columns_Input = {
  status: Scalars["String"];
};

/** select columns of table "video_interview_status" */
export enum Video_Interview_Status_Select_Column {
  /** column name */
  Status = "status",
}

/** input type for updating data in table "video_interview_status" */
export type Video_Interview_Status_Set_Input = {
  status?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "video_interview_status" */
export type Video_Interview_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Video_Interview_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Video_Interview_Status_Stream_Cursor_Value_Input = {
  status?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "video_interview_status" */
export enum Video_Interview_Status_Update_Column {
  /** column name */
  Status = "status",
}

export type Video_Interview_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Video_Interview_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Video_Interview_Status_Bool_Exp;
};

/** aggregate stddev on columns */
export type Video_Interview_Stddev_Fields = {
  __typename?: "video_interview_stddev_fields";
  client_candidate_project_id?: Maybe<Scalars["Float"]>;
  interview_template_id?: Maybe<Scalars["Float"]>;
  /** A computed field, executes function "compute_video_interview_score" */
  overall_score?: Maybe<Scalars["Int"]>;
  score?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "video_interview" */
export type Video_Interview_Stddev_Order_By = {
  client_candidate_project_id?: InputMaybe<Order_By>;
  interview_template_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Video_Interview_Stddev_Pop_Fields = {
  __typename?: "video_interview_stddev_pop_fields";
  client_candidate_project_id?: Maybe<Scalars["Float"]>;
  interview_template_id?: Maybe<Scalars["Float"]>;
  /** A computed field, executes function "compute_video_interview_score" */
  overall_score?: Maybe<Scalars["Int"]>;
  score?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "video_interview" */
export type Video_Interview_Stddev_Pop_Order_By = {
  client_candidate_project_id?: InputMaybe<Order_By>;
  interview_template_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Video_Interview_Stddev_Samp_Fields = {
  __typename?: "video_interview_stddev_samp_fields";
  client_candidate_project_id?: Maybe<Scalars["Float"]>;
  interview_template_id?: Maybe<Scalars["Float"]>;
  /** A computed field, executes function "compute_video_interview_score" */
  overall_score?: Maybe<Scalars["Int"]>;
  score?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "video_interview" */
export type Video_Interview_Stddev_Samp_Order_By = {
  client_candidate_project_id?: InputMaybe<Order_By>;
  interview_template_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "video_interview" */
export type Video_Interview_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Video_Interview_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Video_Interview_Stream_Cursor_Value_Input = {
  client_candidate_project_id?: InputMaybe<Scalars["Int"]>;
  completed_at?: InputMaybe<Scalars["timestamptz"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  interview_template_id?: InputMaybe<Scalars["Int"]>;
  interview_video_url?: InputMaybe<Scalars["String"]>;
  reviewer?: InputMaybe<Video_Interview_Reviewer_Enum>;
  score?: InputMaybe<Scalars["Int"]>;
  started_at?: InputMaybe<Scalars["timestamptz"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  video_interview_type?: InputMaybe<Video_Interview_Type_Enum>;
  video_process_status?: InputMaybe<Video_Process_Status_Enum>;
};

/** aggregate sum on columns */
export type Video_Interview_Sum_Fields = {
  __typename?: "video_interview_sum_fields";
  client_candidate_project_id?: Maybe<Scalars["Int"]>;
  interview_template_id?: Maybe<Scalars["Int"]>;
  /** A computed field, executes function "compute_video_interview_score" */
  overall_score?: Maybe<Scalars["Int"]>;
  score?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "video_interview" */
export type Video_Interview_Sum_Order_By = {
  client_candidate_project_id?: InputMaybe<Order_By>;
  interview_template_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** columns and relationships of "video_interview_type" */
export type Video_Interview_Type = {
  __typename?: "video_interview_type";
  video_interview_type: Scalars["String"];
};

/** aggregated selection of "video_interview_type" */
export type Video_Interview_Type_Aggregate = {
  __typename?: "video_interview_type_aggregate";
  aggregate?: Maybe<Video_Interview_Type_Aggregate_Fields>;
  nodes: Array<Video_Interview_Type>;
};

/** aggregate fields of "video_interview_type" */
export type Video_Interview_Type_Aggregate_Fields = {
  __typename?: "video_interview_type_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Video_Interview_Type_Max_Fields>;
  min?: Maybe<Video_Interview_Type_Min_Fields>;
};

/** aggregate fields of "video_interview_type" */
export type Video_Interview_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Video_Interview_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "video_interview_type". All fields are combined with a logical 'AND'. */
export type Video_Interview_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Video_Interview_Type_Bool_Exp>>;
  _not?: InputMaybe<Video_Interview_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Video_Interview_Type_Bool_Exp>>;
  video_interview_type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "video_interview_type" */
export enum Video_Interview_Type_Constraint {
  /** unique or primary key constraint on columns "video_interview_type" */
  VideoInterviewTypePkey = "video_interview_type_pkey",
}

export enum Video_Interview_Type_Enum {
  CompetencyAssessment = "COMPETENCY_ASSESSMENT",
  EnglishAssessment = "ENGLISH_ASSESSMENT",
}

/** Boolean expression to compare columns of type "video_interview_type_enum". All fields are combined with logical 'AND'. */
export type Video_Interview_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Video_Interview_Type_Enum>;
  _in?: InputMaybe<Array<Video_Interview_Type_Enum>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _neq?: InputMaybe<Video_Interview_Type_Enum>;
  _nin?: InputMaybe<Array<Video_Interview_Type_Enum>>;
};

/** input type for inserting data into table "video_interview_type" */
export type Video_Interview_Type_Insert_Input = {
  video_interview_type?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Video_Interview_Type_Max_Fields = {
  __typename?: "video_interview_type_max_fields";
  video_interview_type?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Video_Interview_Type_Min_Fields = {
  __typename?: "video_interview_type_min_fields";
  video_interview_type?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "video_interview_type" */
export type Video_Interview_Type_Mutation_Response = {
  __typename?: "video_interview_type_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Video_Interview_Type>;
};

/** on_conflict condition type for table "video_interview_type" */
export type Video_Interview_Type_On_Conflict = {
  constraint: Video_Interview_Type_Constraint;
  update_columns?: Array<Video_Interview_Type_Update_Column>;
  where?: InputMaybe<Video_Interview_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "video_interview_type". */
export type Video_Interview_Type_Order_By = {
  video_interview_type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: video_interview_type */
export type Video_Interview_Type_Pk_Columns_Input = {
  video_interview_type: Scalars["String"];
};

/** select columns of table "video_interview_type" */
export enum Video_Interview_Type_Select_Column {
  /** column name */
  VideoInterviewType = "video_interview_type",
}

/** input type for updating data in table "video_interview_type" */
export type Video_Interview_Type_Set_Input = {
  video_interview_type?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "video_interview_type" */
export type Video_Interview_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Video_Interview_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Video_Interview_Type_Stream_Cursor_Value_Input = {
  video_interview_type?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "video_interview_type" */
export enum Video_Interview_Type_Update_Column {
  /** column name */
  VideoInterviewType = "video_interview_type",
}

export type Video_Interview_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Video_Interview_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Video_Interview_Type_Bool_Exp;
};

/** update columns of table "video_interview" */
export enum Video_Interview_Update_Column {
  /** column name */
  ClientCandidateProjectId = "client_candidate_project_id",
  /** column name */
  CompletedAt = "completed_at",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InterviewTemplateId = "interview_template_id",
  /** column name */
  InterviewVideoUrl = "interview_video_url",
  /** column name */
  Reviewer = "reviewer",
  /** column name */
  Score = "score",
  /** column name */
  StartedAt = "started_at",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  VideoInterviewType = "video_interview_type",
  /** column name */
  VideoProcessStatus = "video_process_status",
}

export type Video_Interview_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Video_Interview_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Video_Interview_Set_Input>;
  /** filter the rows which have to be updated */
  where: Video_Interview_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Video_Interview_Var_Pop_Fields = {
  __typename?: "video_interview_var_pop_fields";
  client_candidate_project_id?: Maybe<Scalars["Float"]>;
  interview_template_id?: Maybe<Scalars["Float"]>;
  /** A computed field, executes function "compute_video_interview_score" */
  overall_score?: Maybe<Scalars["Int"]>;
  score?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "video_interview" */
export type Video_Interview_Var_Pop_Order_By = {
  client_candidate_project_id?: InputMaybe<Order_By>;
  interview_template_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Video_Interview_Var_Samp_Fields = {
  __typename?: "video_interview_var_samp_fields";
  client_candidate_project_id?: Maybe<Scalars["Float"]>;
  interview_template_id?: Maybe<Scalars["Float"]>;
  /** A computed field, executes function "compute_video_interview_score" */
  overall_score?: Maybe<Scalars["Int"]>;
  score?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "video_interview" */
export type Video_Interview_Var_Samp_Order_By = {
  client_candidate_project_id?: InputMaybe<Order_By>;
  interview_template_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Video_Interview_Variance_Fields = {
  __typename?: "video_interview_variance_fields";
  client_candidate_project_id?: Maybe<Scalars["Float"]>;
  interview_template_id?: Maybe<Scalars["Float"]>;
  /** A computed field, executes function "compute_video_interview_score" */
  overall_score?: Maybe<Scalars["Int"]>;
  score?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "video_interview" */
export type Video_Interview_Variance_Order_By = {
  client_candidate_project_id?: InputMaybe<Order_By>;
  interview_template_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** columns and relationships of "video_process_status" */
export type Video_Process_Status = {
  __typename?: "video_process_status";
  status: Scalars["String"];
};

/** aggregated selection of "video_process_status" */
export type Video_Process_Status_Aggregate = {
  __typename?: "video_process_status_aggregate";
  aggregate?: Maybe<Video_Process_Status_Aggregate_Fields>;
  nodes: Array<Video_Process_Status>;
};

/** aggregate fields of "video_process_status" */
export type Video_Process_Status_Aggregate_Fields = {
  __typename?: "video_process_status_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Video_Process_Status_Max_Fields>;
  min?: Maybe<Video_Process_Status_Min_Fields>;
};

/** aggregate fields of "video_process_status" */
export type Video_Process_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Video_Process_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "video_process_status". All fields are combined with a logical 'AND'. */
export type Video_Process_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Video_Process_Status_Bool_Exp>>;
  _not?: InputMaybe<Video_Process_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Video_Process_Status_Bool_Exp>>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "video_process_status" */
export enum Video_Process_Status_Constraint {
  /** unique or primary key constraint on columns "status" */
  VideoProcessStatusPkey = "video_process_status_pkey",
}

export enum Video_Process_Status_Enum {
  AiScored = "AI_SCORED",
  AudioExtracted = "AUDIO_EXTRACTED",
  NotStarted = "NOT_STARTED",
  TranscriptExtracted = "TRANSCRIPT_EXTRACTED",
}

/** Boolean expression to compare columns of type "video_process_status_enum". All fields are combined with logical 'AND'. */
export type Video_Process_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Video_Process_Status_Enum>;
  _in?: InputMaybe<Array<Video_Process_Status_Enum>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _neq?: InputMaybe<Video_Process_Status_Enum>;
  _nin?: InputMaybe<Array<Video_Process_Status_Enum>>;
};

/** input type for inserting data into table "video_process_status" */
export type Video_Process_Status_Insert_Input = {
  status?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Video_Process_Status_Max_Fields = {
  __typename?: "video_process_status_max_fields";
  status?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Video_Process_Status_Min_Fields = {
  __typename?: "video_process_status_min_fields";
  status?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "video_process_status" */
export type Video_Process_Status_Mutation_Response = {
  __typename?: "video_process_status_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Video_Process_Status>;
};

/** on_conflict condition type for table "video_process_status" */
export type Video_Process_Status_On_Conflict = {
  constraint: Video_Process_Status_Constraint;
  update_columns?: Array<Video_Process_Status_Update_Column>;
  where?: InputMaybe<Video_Process_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "video_process_status". */
export type Video_Process_Status_Order_By = {
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: video_process_status */
export type Video_Process_Status_Pk_Columns_Input = {
  status: Scalars["String"];
};

/** select columns of table "video_process_status" */
export enum Video_Process_Status_Select_Column {
  /** column name */
  Status = "status",
}

/** input type for updating data in table "video_process_status" */
export type Video_Process_Status_Set_Input = {
  status?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "video_process_status" */
export type Video_Process_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Video_Process_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Video_Process_Status_Stream_Cursor_Value_Input = {
  status?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "video_process_status" */
export enum Video_Process_Status_Update_Column {
  /** column name */
  Status = "status",
}

export type Video_Process_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Video_Process_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Video_Process_Status_Bool_Exp;
};

/** columns and relationships of "view__client_candidate_projects" */
export type View__Client_Candidate_Projects = {
  __typename?: "view__client_candidate_projects";
  candidate_list_status?: Maybe<Scalars["String"]>;
  candidate_name?: Maybe<Scalars["String"]>;
  candidate_resolution?: Maybe<Scalars["String"]>;
  candidate_surname?: Maybe<Scalars["String"]>;
  client_candidate_created_at?: Maybe<Scalars["timestamptz"]>;
  client_candidate_id?: Maybe<Scalars["uuid"]>;
  client_id?: Maybe<Scalars["uuid"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  cv_file_name?: Maybe<Scalars["String"]>;
  cv_file_uri?: Maybe<Scalars["String"]>;
  email_address?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  interview_status?: Maybe<Scalars["String"]>;
  interview_status_created_at?: Maybe<Scalars["timestamptz"]>;
  mobile_number?: Maybe<Scalars["String"]>;
  note_count?: Maybe<Scalars["bigint"]>;
  project_count?: Maybe<Scalars["bigint"]>;
  project_id?: Maybe<Scalars["uuid"]>;
  score?: Maybe<Scalars["Int"]>;
  status_count?: Maybe<Scalars["bigint"]>;
  video_interview_id?: Maybe<Scalars["uuid"]>;
};

/** aggregated selection of "view__client_candidate_projects" */
export type View__Client_Candidate_Projects_Aggregate = {
  __typename?: "view__client_candidate_projects_aggregate";
  aggregate?: Maybe<View__Client_Candidate_Projects_Aggregate_Fields>;
  nodes: Array<View__Client_Candidate_Projects>;
};

/** aggregate fields of "view__client_candidate_projects" */
export type View__Client_Candidate_Projects_Aggregate_Fields = {
  __typename?: "view__client_candidate_projects_aggregate_fields";
  avg?: Maybe<View__Client_Candidate_Projects_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<View__Client_Candidate_Projects_Max_Fields>;
  min?: Maybe<View__Client_Candidate_Projects_Min_Fields>;
  stddev?: Maybe<View__Client_Candidate_Projects_Stddev_Fields>;
  stddev_pop?: Maybe<View__Client_Candidate_Projects_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<View__Client_Candidate_Projects_Stddev_Samp_Fields>;
  sum?: Maybe<View__Client_Candidate_Projects_Sum_Fields>;
  var_pop?: Maybe<View__Client_Candidate_Projects_Var_Pop_Fields>;
  var_samp?: Maybe<View__Client_Candidate_Projects_Var_Samp_Fields>;
  variance?: Maybe<View__Client_Candidate_Projects_Variance_Fields>;
};

/** aggregate fields of "view__client_candidate_projects" */
export type View__Client_Candidate_Projects_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<View__Client_Candidate_Projects_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type View__Client_Candidate_Projects_Avg_Fields = {
  __typename?: "view__client_candidate_projects_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  note_count?: Maybe<Scalars["Float"]>;
  project_count?: Maybe<Scalars["Float"]>;
  score?: Maybe<Scalars["Float"]>;
  status_count?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "view__client_candidate_projects". All fields are combined with a logical 'AND'. */
export type View__Client_Candidate_Projects_Bool_Exp = {
  _and?: InputMaybe<Array<View__Client_Candidate_Projects_Bool_Exp>>;
  _not?: InputMaybe<View__Client_Candidate_Projects_Bool_Exp>;
  _or?: InputMaybe<Array<View__Client_Candidate_Projects_Bool_Exp>>;
  candidate_list_status?: InputMaybe<String_Comparison_Exp>;
  candidate_name?: InputMaybe<String_Comparison_Exp>;
  candidate_resolution?: InputMaybe<String_Comparison_Exp>;
  candidate_surname?: InputMaybe<String_Comparison_Exp>;
  client_candidate_created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  client_candidate_id?: InputMaybe<Uuid_Comparison_Exp>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  company_id?: InputMaybe<Uuid_Comparison_Exp>;
  cv_file_name?: InputMaybe<String_Comparison_Exp>;
  cv_file_uri?: InputMaybe<String_Comparison_Exp>;
  email_address?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  interview_status?: InputMaybe<String_Comparison_Exp>;
  interview_status_created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  mobile_number?: InputMaybe<String_Comparison_Exp>;
  note_count?: InputMaybe<Bigint_Comparison_Exp>;
  project_count?: InputMaybe<Bigint_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  score?: InputMaybe<Int_Comparison_Exp>;
  status_count?: InputMaybe<Bigint_Comparison_Exp>;
  video_interview_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type View__Client_Candidate_Projects_Max_Fields = {
  __typename?: "view__client_candidate_projects_max_fields";
  candidate_list_status?: Maybe<Scalars["String"]>;
  candidate_name?: Maybe<Scalars["String"]>;
  candidate_resolution?: Maybe<Scalars["String"]>;
  candidate_surname?: Maybe<Scalars["String"]>;
  client_candidate_created_at?: Maybe<Scalars["timestamptz"]>;
  client_candidate_id?: Maybe<Scalars["uuid"]>;
  client_id?: Maybe<Scalars["uuid"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  cv_file_name?: Maybe<Scalars["String"]>;
  cv_file_uri?: Maybe<Scalars["String"]>;
  email_address?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  interview_status?: Maybe<Scalars["String"]>;
  interview_status_created_at?: Maybe<Scalars["timestamptz"]>;
  mobile_number?: Maybe<Scalars["String"]>;
  note_count?: Maybe<Scalars["bigint"]>;
  project_count?: Maybe<Scalars["bigint"]>;
  project_id?: Maybe<Scalars["uuid"]>;
  score?: Maybe<Scalars["Int"]>;
  status_count?: Maybe<Scalars["bigint"]>;
  video_interview_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate min on columns */
export type View__Client_Candidate_Projects_Min_Fields = {
  __typename?: "view__client_candidate_projects_min_fields";
  candidate_list_status?: Maybe<Scalars["String"]>;
  candidate_name?: Maybe<Scalars["String"]>;
  candidate_resolution?: Maybe<Scalars["String"]>;
  candidate_surname?: Maybe<Scalars["String"]>;
  client_candidate_created_at?: Maybe<Scalars["timestamptz"]>;
  client_candidate_id?: Maybe<Scalars["uuid"]>;
  client_id?: Maybe<Scalars["uuid"]>;
  company_id?: Maybe<Scalars["uuid"]>;
  cv_file_name?: Maybe<Scalars["String"]>;
  cv_file_uri?: Maybe<Scalars["String"]>;
  email_address?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  interview_status?: Maybe<Scalars["String"]>;
  interview_status_created_at?: Maybe<Scalars["timestamptz"]>;
  mobile_number?: Maybe<Scalars["String"]>;
  note_count?: Maybe<Scalars["bigint"]>;
  project_count?: Maybe<Scalars["bigint"]>;
  project_id?: Maybe<Scalars["uuid"]>;
  score?: Maybe<Scalars["Int"]>;
  status_count?: Maybe<Scalars["bigint"]>;
  video_interview_id?: Maybe<Scalars["uuid"]>;
};

/** Ordering options when selecting data from "view__client_candidate_projects". */
export type View__Client_Candidate_Projects_Order_By = {
  candidate_list_status?: InputMaybe<Order_By>;
  candidate_name?: InputMaybe<Order_By>;
  candidate_resolution?: InputMaybe<Order_By>;
  candidate_surname?: InputMaybe<Order_By>;
  client_candidate_created_at?: InputMaybe<Order_By>;
  client_candidate_id?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  cv_file_name?: InputMaybe<Order_By>;
  cv_file_uri?: InputMaybe<Order_By>;
  email_address?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interview_status?: InputMaybe<Order_By>;
  interview_status_created_at?: InputMaybe<Order_By>;
  mobile_number?: InputMaybe<Order_By>;
  note_count?: InputMaybe<Order_By>;
  project_count?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  status_count?: InputMaybe<Order_By>;
  video_interview_id?: InputMaybe<Order_By>;
};

/** select columns of table "view__client_candidate_projects" */
export enum View__Client_Candidate_Projects_Select_Column {
  /** column name */
  CandidateListStatus = "candidate_list_status",
  /** column name */
  CandidateName = "candidate_name",
  /** column name */
  CandidateResolution = "candidate_resolution",
  /** column name */
  CandidateSurname = "candidate_surname",
  /** column name */
  ClientCandidateCreatedAt = "client_candidate_created_at",
  /** column name */
  ClientCandidateId = "client_candidate_id",
  /** column name */
  ClientId = "client_id",
  /** column name */
  CompanyId = "company_id",
  /** column name */
  CvFileName = "cv_file_name",
  /** column name */
  CvFileUri = "cv_file_uri",
  /** column name */
  EmailAddress = "email_address",
  /** column name */
  Id = "id",
  /** column name */
  InterviewStatus = "interview_status",
  /** column name */
  InterviewStatusCreatedAt = "interview_status_created_at",
  /** column name */
  MobileNumber = "mobile_number",
  /** column name */
  NoteCount = "note_count",
  /** column name */
  ProjectCount = "project_count",
  /** column name */
  ProjectId = "project_id",
  /** column name */
  Score = "score",
  /** column name */
  StatusCount = "status_count",
  /** column name */
  VideoInterviewId = "video_interview_id",
}

/** aggregate stddev on columns */
export type View__Client_Candidate_Projects_Stddev_Fields = {
  __typename?: "view__client_candidate_projects_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  note_count?: Maybe<Scalars["Float"]>;
  project_count?: Maybe<Scalars["Float"]>;
  score?: Maybe<Scalars["Float"]>;
  status_count?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type View__Client_Candidate_Projects_Stddev_Pop_Fields = {
  __typename?: "view__client_candidate_projects_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  note_count?: Maybe<Scalars["Float"]>;
  project_count?: Maybe<Scalars["Float"]>;
  score?: Maybe<Scalars["Float"]>;
  status_count?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type View__Client_Candidate_Projects_Stddev_Samp_Fields = {
  __typename?: "view__client_candidate_projects_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  note_count?: Maybe<Scalars["Float"]>;
  project_count?: Maybe<Scalars["Float"]>;
  score?: Maybe<Scalars["Float"]>;
  status_count?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "view__client_candidate_projects" */
export type View__Client_Candidate_Projects_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: View__Client_Candidate_Projects_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type View__Client_Candidate_Projects_Stream_Cursor_Value_Input = {
  candidate_list_status?: InputMaybe<Scalars["String"]>;
  candidate_name?: InputMaybe<Scalars["String"]>;
  candidate_resolution?: InputMaybe<Scalars["String"]>;
  candidate_surname?: InputMaybe<Scalars["String"]>;
  client_candidate_created_at?: InputMaybe<Scalars["timestamptz"]>;
  client_candidate_id?: InputMaybe<Scalars["uuid"]>;
  client_id?: InputMaybe<Scalars["uuid"]>;
  company_id?: InputMaybe<Scalars["uuid"]>;
  cv_file_name?: InputMaybe<Scalars["String"]>;
  cv_file_uri?: InputMaybe<Scalars["String"]>;
  email_address?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  interview_status?: InputMaybe<Scalars["String"]>;
  interview_status_created_at?: InputMaybe<Scalars["timestamptz"]>;
  mobile_number?: InputMaybe<Scalars["String"]>;
  note_count?: InputMaybe<Scalars["bigint"]>;
  project_count?: InputMaybe<Scalars["bigint"]>;
  project_id?: InputMaybe<Scalars["uuid"]>;
  score?: InputMaybe<Scalars["Int"]>;
  status_count?: InputMaybe<Scalars["bigint"]>;
  video_interview_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate sum on columns */
export type View__Client_Candidate_Projects_Sum_Fields = {
  __typename?: "view__client_candidate_projects_sum_fields";
  id?: Maybe<Scalars["Int"]>;
  note_count?: Maybe<Scalars["bigint"]>;
  project_count?: Maybe<Scalars["bigint"]>;
  score?: Maybe<Scalars["Int"]>;
  status_count?: Maybe<Scalars["bigint"]>;
};

/** aggregate var_pop on columns */
export type View__Client_Candidate_Projects_Var_Pop_Fields = {
  __typename?: "view__client_candidate_projects_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  note_count?: Maybe<Scalars["Float"]>;
  project_count?: Maybe<Scalars["Float"]>;
  score?: Maybe<Scalars["Float"]>;
  status_count?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type View__Client_Candidate_Projects_Var_Samp_Fields = {
  __typename?: "view__client_candidate_projects_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  note_count?: Maybe<Scalars["Float"]>;
  project_count?: Maybe<Scalars["Float"]>;
  score?: Maybe<Scalars["Float"]>;
  status_count?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type View__Client_Candidate_Projects_Variance_Fields = {
  __typename?: "view__client_candidate_projects_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  note_count?: Maybe<Scalars["Float"]>;
  project_count?: Maybe<Scalars["Float"]>;
  score?: Maybe<Scalars["Float"]>;
  status_count?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "view__ordered_video_interview_question" */
export type View__Ordered_Video_Interview_Question = {
  __typename?: "view__ordered_video_interview_question";
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  interview_template_id?: Maybe<Scalars["Int"]>;
  max_duration?: Maybe<Scalars["Int"]>;
  question?: Maybe<Scalars["String"]>;
  question_order?: Maybe<Scalars["Int"]>;
  review_metric_language?: Maybe<Scalars["String"]>;
  review_metric_question_id?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
  storage_filename?: Maybe<Scalars["String"]>;
  tip?: Maybe<Scalars["String"]>;
  upload_ends_at?: Maybe<Scalars["timestamptz"]>;
  upload_starts_at?: Maybe<Scalars["timestamptz"]>;
  video_interview_id?: Maybe<Scalars["uuid"]>;
};

/** aggregated selection of "view__ordered_video_interview_question" */
export type View__Ordered_Video_Interview_Question_Aggregate = {
  __typename?: "view__ordered_video_interview_question_aggregate";
  aggregate?: Maybe<View__Ordered_Video_Interview_Question_Aggregate_Fields>;
  nodes: Array<View__Ordered_Video_Interview_Question>;
};

/** aggregate fields of "view__ordered_video_interview_question" */
export type View__Ordered_Video_Interview_Question_Aggregate_Fields = {
  __typename?: "view__ordered_video_interview_question_aggregate_fields";
  avg?: Maybe<View__Ordered_Video_Interview_Question_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<View__Ordered_Video_Interview_Question_Max_Fields>;
  min?: Maybe<View__Ordered_Video_Interview_Question_Min_Fields>;
  stddev?: Maybe<View__Ordered_Video_Interview_Question_Stddev_Fields>;
  stddev_pop?: Maybe<View__Ordered_Video_Interview_Question_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<View__Ordered_Video_Interview_Question_Stddev_Samp_Fields>;
  sum?: Maybe<View__Ordered_Video_Interview_Question_Sum_Fields>;
  var_pop?: Maybe<View__Ordered_Video_Interview_Question_Var_Pop_Fields>;
  var_samp?: Maybe<View__Ordered_Video_Interview_Question_Var_Samp_Fields>;
  variance?: Maybe<View__Ordered_Video_Interview_Question_Variance_Fields>;
};

/** aggregate fields of "view__ordered_video_interview_question" */
export type View__Ordered_Video_Interview_Question_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<
    Array<View__Ordered_Video_Interview_Question_Select_Column>
  >;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type View__Ordered_Video_Interview_Question_Avg_Fields = {
  __typename?: "view__ordered_video_interview_question_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  interview_template_id?: Maybe<Scalars["Float"]>;
  max_duration?: Maybe<Scalars["Float"]>;
  question_order?: Maybe<Scalars["Float"]>;
  review_metric_question_id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "view__ordered_video_interview_question". All fields are combined with a logical 'AND'. */
export type View__Ordered_Video_Interview_Question_Bool_Exp = {
  _and?: InputMaybe<Array<View__Ordered_Video_Interview_Question_Bool_Exp>>;
  _not?: InputMaybe<View__Ordered_Video_Interview_Question_Bool_Exp>;
  _or?: InputMaybe<Array<View__Ordered_Video_Interview_Question_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  interview_template_id?: InputMaybe<Int_Comparison_Exp>;
  max_duration?: InputMaybe<Int_Comparison_Exp>;
  question?: InputMaybe<String_Comparison_Exp>;
  question_order?: InputMaybe<Int_Comparison_Exp>;
  review_metric_language?: InputMaybe<String_Comparison_Exp>;
  review_metric_question_id?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  storage_filename?: InputMaybe<String_Comparison_Exp>;
  tip?: InputMaybe<String_Comparison_Exp>;
  upload_ends_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  upload_starts_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  video_interview_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type View__Ordered_Video_Interview_Question_Max_Fields = {
  __typename?: "view__ordered_video_interview_question_max_fields";
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  interview_template_id?: Maybe<Scalars["Int"]>;
  max_duration?: Maybe<Scalars["Int"]>;
  question?: Maybe<Scalars["String"]>;
  question_order?: Maybe<Scalars["Int"]>;
  review_metric_language?: Maybe<Scalars["String"]>;
  review_metric_question_id?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
  storage_filename?: Maybe<Scalars["String"]>;
  tip?: Maybe<Scalars["String"]>;
  upload_ends_at?: Maybe<Scalars["timestamptz"]>;
  upload_starts_at?: Maybe<Scalars["timestamptz"]>;
  video_interview_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate min on columns */
export type View__Ordered_Video_Interview_Question_Min_Fields = {
  __typename?: "view__ordered_video_interview_question_min_fields";
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  interview_template_id?: Maybe<Scalars["Int"]>;
  max_duration?: Maybe<Scalars["Int"]>;
  question?: Maybe<Scalars["String"]>;
  question_order?: Maybe<Scalars["Int"]>;
  review_metric_language?: Maybe<Scalars["String"]>;
  review_metric_question_id?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
  storage_filename?: Maybe<Scalars["String"]>;
  tip?: Maybe<Scalars["String"]>;
  upload_ends_at?: Maybe<Scalars["timestamptz"]>;
  upload_starts_at?: Maybe<Scalars["timestamptz"]>;
  video_interview_id?: Maybe<Scalars["uuid"]>;
};

/** Ordering options when selecting data from "view__ordered_video_interview_question". */
export type View__Ordered_Video_Interview_Question_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interview_template_id?: InputMaybe<Order_By>;
  max_duration?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
  question_order?: InputMaybe<Order_By>;
  review_metric_language?: InputMaybe<Order_By>;
  review_metric_question_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  storage_filename?: InputMaybe<Order_By>;
  tip?: InputMaybe<Order_By>;
  upload_ends_at?: InputMaybe<Order_By>;
  upload_starts_at?: InputMaybe<Order_By>;
  video_interview_id?: InputMaybe<Order_By>;
};

/** select columns of table "view__ordered_video_interview_question" */
export enum View__Ordered_Video_Interview_Question_Select_Column {
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  InterviewTemplateId = "interview_template_id",
  /** column name */
  MaxDuration = "max_duration",
  /** column name */
  Question = "question",
  /** column name */
  QuestionOrder = "question_order",
  /** column name */
  ReviewMetricLanguage = "review_metric_language",
  /** column name */
  ReviewMetricQuestionId = "review_metric_question_id",
  /** column name */
  Status = "status",
  /** column name */
  StorageFilename = "storage_filename",
  /** column name */
  Tip = "tip",
  /** column name */
  UploadEndsAt = "upload_ends_at",
  /** column name */
  UploadStartsAt = "upload_starts_at",
  /** column name */
  VideoInterviewId = "video_interview_id",
}

/** aggregate stddev on columns */
export type View__Ordered_Video_Interview_Question_Stddev_Fields = {
  __typename?: "view__ordered_video_interview_question_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  interview_template_id?: Maybe<Scalars["Float"]>;
  max_duration?: Maybe<Scalars["Float"]>;
  question_order?: Maybe<Scalars["Float"]>;
  review_metric_question_id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type View__Ordered_Video_Interview_Question_Stddev_Pop_Fields = {
  __typename?: "view__ordered_video_interview_question_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  interview_template_id?: Maybe<Scalars["Float"]>;
  max_duration?: Maybe<Scalars["Float"]>;
  question_order?: Maybe<Scalars["Float"]>;
  review_metric_question_id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type View__Ordered_Video_Interview_Question_Stddev_Samp_Fields = {
  __typename?: "view__ordered_video_interview_question_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  interview_template_id?: Maybe<Scalars["Float"]>;
  max_duration?: Maybe<Scalars["Float"]>;
  question_order?: Maybe<Scalars["Float"]>;
  review_metric_question_id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "view__ordered_video_interview_question" */
export type View__Ordered_Video_Interview_Question_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: View__Ordered_Video_Interview_Question_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type View__Ordered_Video_Interview_Question_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  interview_template_id?: InputMaybe<Scalars["Int"]>;
  max_duration?: InputMaybe<Scalars["Int"]>;
  question?: InputMaybe<Scalars["String"]>;
  question_order?: InputMaybe<Scalars["Int"]>;
  review_metric_language?: InputMaybe<Scalars["String"]>;
  review_metric_question_id?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<Scalars["String"]>;
  storage_filename?: InputMaybe<Scalars["String"]>;
  tip?: InputMaybe<Scalars["String"]>;
  upload_ends_at?: InputMaybe<Scalars["timestamptz"]>;
  upload_starts_at?: InputMaybe<Scalars["timestamptz"]>;
  video_interview_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate sum on columns */
export type View__Ordered_Video_Interview_Question_Sum_Fields = {
  __typename?: "view__ordered_video_interview_question_sum_fields";
  id?: Maybe<Scalars["Int"]>;
  interview_template_id?: Maybe<Scalars["Int"]>;
  max_duration?: Maybe<Scalars["Int"]>;
  question_order?: Maybe<Scalars["Int"]>;
  review_metric_question_id?: Maybe<Scalars["Int"]>;
};

/** aggregate var_pop on columns */
export type View__Ordered_Video_Interview_Question_Var_Pop_Fields = {
  __typename?: "view__ordered_video_interview_question_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  interview_template_id?: Maybe<Scalars["Float"]>;
  max_duration?: Maybe<Scalars["Float"]>;
  question_order?: Maybe<Scalars["Float"]>;
  review_metric_question_id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type View__Ordered_Video_Interview_Question_Var_Samp_Fields = {
  __typename?: "view__ordered_video_interview_question_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  interview_template_id?: Maybe<Scalars["Float"]>;
  max_duration?: Maybe<Scalars["Float"]>;
  question_order?: Maybe<Scalars["Float"]>;
  review_metric_question_id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type View__Ordered_Video_Interview_Question_Variance_Fields = {
  __typename?: "view__ordered_video_interview_question_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  interview_template_id?: Maybe<Scalars["Float"]>;
  max_duration?: Maybe<Scalars["Float"]>;
  question_order?: Maybe<Scalars["Float"]>;
  review_metric_question_id?: Maybe<Scalars["Float"]>;
};

export type ChangeCandidateStatusMutationVariables = Exact<{
  status?: InputMaybe<Client_Candidate_Project_Candidate_List_Status_Enum>;
  ids?: InputMaybe<Array<Scalars["Int"]> | Scalars["Int"]>;
}>;

export type ChangeCandidateStatusMutation = {
  __typename?: "mutation_root";
  update_client_candidate_project?: {
    __typename?: "client_candidate_project_mutation_response";
    returning: Array<{ __typename?: "client_candidate_project"; id: number }>;
  } | null;
};

export type ChangePasswordMutationVariables = Exact<{
  currentPassword: Scalars["String"];
  newPassword: Scalars["String"];
}>;

export type ChangePasswordMutation = {
  __typename?: "mutation_root";
  changePassword?: {
    __typename?: "CommonActionOutput";
    message?: string | null;
    success?: boolean | null;
  } | null;
};

export type DeleteClientMutationVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type DeleteClientMutation = {
  __typename?: "mutation_root";
  delete_client_by_pk?: { __typename?: "client"; id: any } | null;
};

export type DeleteClientCandidateProjectMutationVariables = Exact<{
  projectId?: InputMaybe<Scalars["uuid"]>;
  clientCandidateIds: Array<Scalars["uuid"]> | Scalars["uuid"];
}>;

export type DeleteClientCandidateProjectMutation = {
  __typename?: "mutation_root";
  delete_client_candidate_project?: {
    __typename?: "client_candidate_project_mutation_response";
    returning: Array<{
      __typename?: "client_candidate_project";
      project_id: any;
    }>;
  } | null;
};

export type DeleteClientCandidateProjectNoteMutationVariables = Exact<{
  id: Scalars["Int"];
}>;

export type DeleteClientCandidateProjectNoteMutation = {
  __typename?: "mutation_root";
  delete_client_candidate_project_note_by_pk?: {
    __typename?: "client_candidate_project_note";
    id: number;
  } | null;
};

export type DeleteInterviewTemplateMutationVariables = Exact<{
  id: Scalars["Int"];
}>;

export type DeleteInterviewTemplateMutation = {
  __typename?: "mutation_root";
  delete_interview_template?: {
    __typename?: "interview_template_mutation_response";
    returning: Array<{ __typename?: "interview_template"; id: number }>;
  } | null;
};

export type DeleteJobDescriptionMutationVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type DeleteJobDescriptionMutation = {
  __typename?: "mutation_root";
  delete_job_description_by_pk?: {
    __typename?: "job_description";
    id: any;
  } | null;
};

export type DeleteProjectMutationVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type DeleteProjectMutation = {
  __typename?: "mutation_root";
  delete_project_by_pk?: { __typename?: "project"; id: any } | null;
};

export type DeleteReviewMetricQuestionsRelatedInterviewTemplateMutationVariables =
  Exact<{
    interviewTemplateId: Scalars["Int"];
  }>;

export type DeleteReviewMetricQuestionsRelatedInterviewTemplateMutation = {
  __typename?: "mutation_root";
  delete_interview_template_review_metric_question?: {
    __typename?: "interview_template_review_metric_question_mutation_response";
    affected_rows: number;
  } | null;
};

export type DeleteUserKeyMutationVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type DeleteUserKeyMutation = {
  __typename?: "mutation_root";
  delete_user_key_by_pk?: { __typename?: "user_key"; id: any } | null;
};

export type ForgotPasswordMutationVariables = Exact<{
  username: Scalars["String"];
}>;

export type ForgotPasswordMutation = {
  __typename?: "mutation_root";
  forgotPassword?: {
    __typename?: "CommonActionOutput";
    success?: boolean | null;
    message?: string | null;
  } | null;
};

export type GetCitiesDropdownQueryVariables = Exact<{ [key: string]: never }>;

export type GetCitiesDropdownQuery = {
  __typename?: "query_root";
  city: Array<{ __typename?: "city"; city_name: string; id: number }>;
};

export type GetClientQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetClientQuery = {
  __typename?: "query_root";
  client_by_pk?: {
    __typename?: "client";
    client_name: string;
    has_multilocation: boolean;
    description?: string | null;
    employee_count?: number | null;
    working_type?: string | null;
    client_locations: Array<{
      __typename?: "client_location";
      address?: string | null;
      address_phone?: string | null;
      city_id: number;
      id: any;
      city: {
        __typename?: "city";
        city_name: string;
        id: number;
        country: { __typename?: "country"; country_name: string; id: number };
      };
    }>;
    client_sectors: Array<{
      __typename?: "client_sector";
      sector_id: number;
      id: number;
      sector: { __typename?: "sector"; sector_name: string };
    }>;
  } | null;
};

export type GetClientCandidateQueryVariables = Exact<{
  searchString?: InputMaybe<Scalars["String"]>;
}>;

export type GetClientCandidateQuery = {
  __typename?: "query_root";
  client_candidate: Array<{
    __typename?: "client_candidate";
    email_address: string;
    id: any;
    mobile_number: string;
    cv_file_name?: string | null;
    cv_file_uri?: string | null;
    client_id: any;
    name: string;
    surname: string;
    client_candidate_projects: Array<{
      __typename?: "client_candidate_project";
      video_interviews: Array<{
        __typename?: "video_interview";
        score: number;
        video_interview_statuses: Array<{
          __typename?: "video_interview_status_history";
          created_at: any;
        }>;
      }>;
    }>;
  }>;
};

export type GetClientCandidateByPkQueryVariables = Exact<{
  clientCandidateId: Scalars["uuid"];
}>;

export type GetClientCandidateByPkQuery = {
  __typename?: "query_root";
  client_candidate_by_pk?: {
    __typename?: "client_candidate";
    email_address: string;
    id: any;
    mobile_number: string;
    cv_file_name?: string | null;
    cv_file_uri?: string | null;
    client_id: any;
    name: string;
    surname: string;
    client_candidate_projects: Array<{
      __typename?: "client_candidate_project";
      video_interviews: Array<{
        __typename?: "video_interview";
        score: number;
        video_interview_statuses: Array<{
          __typename?: "video_interview_status_history";
          created_at: any;
        }>;
      }>;
    }>;
  } | null;
};

export type GetClientCandidateProjectWithOrderAndFilterQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  projectId?: InputMaybe<Scalars["uuid"]>;
  candidate_list_status?: InputMaybe<Client_Candidate_Project_Candidate_List_Status_Enum>;
  listFilter?: InputMaybe<Client_Candidate_Project_Bool_Exp>;
  listOrder: Client_Candidate_Project_Order_By;
}>;

export type GetClientCandidateProjectWithOrderAndFilterQuery = {
  __typename?: "query_root";
  client_candidate_project: Array<{
    __typename?: "client_candidate_project";
    id: number;
    client_candidate_id: any;
    candidate_resolution?: Client_Candidate_Project_Candidate_Resolution_Enum | null;
    client_candidate: {
      __typename?: "client_candidate";
      client_id: any;
      created_at: any;
      cv_file_name?: string | null;
      cv_file_uri?: string | null;
      email_address: string;
      id: any;
      mobile_number: string;
      name: string;
      surname: string;
      client_candidate_projects: Array<{
        __typename?: "client_candidate_project";
        project: { __typename?: "project"; id: any; project_name: string };
        video_interviews: Array<{
          __typename?: "video_interview";
          video_interview_statuses: Array<{
            __typename?: "video_interview_status_history";
            status: Video_Interview_Status_Enum;
            video_interview: {
              __typename?: "video_interview";
              score: number;
              video_interview_type: Video_Interview_Type_Enum;
            };
          }>;
        }>;
      }>;
    };
    video_interviews: Array<{
      __typename?: "video_interview";
      id: any;
      video_interview_type: Video_Interview_Type_Enum;
      video_interview_statuses: Array<{
        __typename?: "video_interview_status_history";
        id: number;
        status: Video_Interview_Status_Enum;
        created_at: any;
        video_interview: {
          __typename?: "video_interview";
          video_interview_type: Video_Interview_Type_Enum;
          score: number;
        };
      }>;
      video_interview_questions: Array<{
        __typename?: "video_interview_question";
        video_interview_question_events: Array<{
          __typename?: "video_interview_question_event";
          type: Video_Interview_Question_Event_Type_Enum;
          id: any;
          video_interview_question: {
            __typename?: "video_interview_question";
            review_metric_question: {
              __typename?: "review_metric_question";
              interview_template_review_metric_questions: Array<{
                __typename?: "interview_template_review_metric_question";
                question_order: number;
              }>;
            };
          };
        }>;
      }>;
    }>;
    client_candidate_project_notes: Array<{
      __typename?: "client_candidate_project_note";
      client_candidate_project_id: number;
      created_at?: any | null;
      created_by: any;
      id: number;
      note: string;
      updated_at?: any | null;
      user: { __typename?: "user"; name: string; surname: string };
    }>;
  }>;
  client_candidate_aggregate: {
    __typename?: "client_candidate_aggregate";
    aggregate?: {
      __typename?: "client_candidate_aggregate_fields";
      count: number;
    } | null;
  };
};

export type GetClientCandidateScoreQueryVariables = Exact<{
  clientCandidateId: Scalars["uuid"];
  projectId?: InputMaybe<Scalars["uuid"]>;
}>;

export type GetClientCandidateScoreQuery = {
  __typename?: "query_root";
  client_candidate_by_pk?: {
    __typename?: "client_candidate";
    client_candidate_projects: Array<{
      __typename?: "client_candidate_project";
      video_interviews: Array<{
        __typename?: "video_interview";
        score: number;
      }>;
    }>;
  } | null;
};

export type GetClientCandidateOverviewQueryVariables = Exact<{
  id: Scalars["uuid"];
  interviewType: Video_Interview_Type_Enum;
}>;

export type GetClientCandidateOverviewQuery = {
  __typename?: "query_root";
  video_interview_long_list: Array<{
    __typename?: "video_interview";
    score: number;
    video_interview_statuses: Array<{
      __typename?: "video_interview_status_history";
      status: Video_Interview_Status_Enum;
    }>;
  }>;
  client_candidate_aggregate_long_list: {
    __typename?: "client_candidate_aggregate";
    aggregate?: {
      __typename?: "client_candidate_aggregate_fields";
      count: number;
    } | null;
  };
  video_interview_short_list: Array<{
    __typename?: "video_interview";
    score: number;
    video_interview_statuses: Array<{
      __typename?: "video_interview_status_history";
      status: Video_Interview_Status_Enum;
    }>;
  }>;
  client_candidate_aggregate_short_list: {
    __typename?: "client_candidate_aggregate";
    aggregate?: {
      __typename?: "client_candidate_aggregate_fields";
      count: number;
    } | null;
  };
  project_by_pk?: {
    __typename?: "project";
    created_at?: any | null;
    description?: string | null;
    id: any;
    end_date?: any | null;
    project_name: string;
    status: Project_Status_Enum;
    client: { __typename?: "client"; client_name: string; id: any };
    owner: {
      __typename?: "user";
      name: string;
      surname: string;
      avatar?: string | null;
    };
  } | null;
};

export type GetClientCandidateProjectNoteQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type GetClientCandidateProjectNoteQuery = {
  __typename?: "query_root";
  client_candidate_project: Array<{
    __typename?: "client_candidate_project";
    client_candidate_project_notes: Array<{
      __typename?: "client_candidate_project_note";
      client_candidate_project_id: number;
      created_at?: any | null;
      created_by: any;
      id: number;
      note: string;
      updated_at?: any | null;
      user: { __typename?: "user"; name: string; surname: string };
    }>;
  }>;
};

export type GetClientsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  searchString?: InputMaybe<Scalars["String"]>;
}>;

export type GetClientsQuery = {
  __typename?: "query_root";
  client: Array<{
    __typename?: "client";
    id: any;
    client_name: string;
    description?: string | null;
    is_default: boolean;
    employee_count?: number | null;
    has_multilocation: boolean;
    client_locations: Array<{
      __typename?: "client_location";
      address?: string | null;
    }>;
    client_sectors: Array<{
      __typename?: "client_sector";
      sector_id: number;
      id: number;
      sector: { __typename?: "sector"; sector_name: string };
    }>;
  }>;
  client_aggregate: {
    __typename?: "client_aggregate";
    aggregate?: {
      __typename?: "client_aggregate_fields";
      count: number;
    } | null;
  };
};

export type GetClientsDropdownQueryVariables = Exact<{ [key: string]: never }>;

export type GetClientsDropdownQuery = {
  __typename?: "query_root";
  client: Array<{
    __typename?: "client";
    is_default: boolean;
    id: any;
    client_name: string;
  }>;
};

export type GetCompanySubscriptionQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetCompanySubscriptionQuery = {
  __typename?: "query_root";
  company_subscription: Array<{
    __typename?: "company_subscription";
    id: any;
    ends_at: any;
    starts_at: any;
    product_price: {
      __typename?: "product_price";
      price: any;
      max_credit: number;
      currency: Currency_Enum;
    };
  }>;
};

export type GetCountriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCountriesQuery = {
  __typename?: "query_root";
  country: Array<{
    __typename?: "country";
    country_name: string;
    id: number;
    cities: Array<{ __typename?: "city"; city_name: string; id: number }>;
  }>;
};

export type GetInterviewTemplateQuestionsByTemplateIdQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type GetInterviewTemplateQuestionsByTemplateIdQuery = {
  __typename?: "query_root";
  interview_template_review_metric_question: Array<{
    __typename?: "interview_template_review_metric_question";
    review_metric_question: {
      __typename?: "review_metric_question";
      question?: string | null;
      is_shown_to_candidate: boolean;
      id: number;
      review_metric_question_responses: Array<{
        __typename?: "review_metric_question_response";
        description: string;
        score: number;
        id: number;
      }>;
      review_metric: {
        __typename?: "review_metric";
        description: string;
        id: number;
        language: Language_Enum;
      };
    };
  }>;
};

export type GetInterviewTemplatesDropdownQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars["uuid"]>;
}>;

export type GetInterviewTemplatesDropdownQuery = {
  __typename?: "query_root";
  interview_template: Array<{
    __typename?: "interview_template";
    template_name: string;
    id: number;
  }>;
};

export type GetJobDescriptionQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  jobDescriptionFilter?: InputMaybe<Job_Description_Bool_Exp>;
}>;

export type GetJobDescriptionQuery = {
  __typename?: "query_root";
  job_description: Array<{
    __typename?: "job_description";
    id: any;
    title: string;
    created_at: any;
    project: {
      __typename?: "project";
      project_name: string;
      created_by: any;
      client: { __typename?: "client"; client_name: string };
    };
  }>;
};

export type GetJobDescriptionAggregateQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetJobDescriptionAggregateQuery = {
  __typename?: "query_root";
  job_description_aggregate: {
    __typename?: "job_description_aggregate";
    aggregate?: {
      __typename?: "job_description_aggregate_fields";
      count: number;
    } | null;
  };
};

export type GetJobDescriptionWithPaginationQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  projectId: Scalars["uuid"];
}>;

export type GetJobDescriptionWithPaginationQuery = {
  __typename?: "query_root";
  project_by_pk?: {
    __typename?: "project";
    job_descriptions_aggregate: {
      __typename?: "job_description_aggregate";
      aggregate?: {
        __typename?: "job_description_aggregate_fields";
        count: number;
      } | null;
    };
    job_descriptions: Array<{
      __typename?: "job_description";
      id: any;
      created_at: any;
      title: string;
      step: Job_Description_Step_Enum;
      owner: {
        __typename?: "user";
        name: string;
        surname: string;
        avatar?: string | null;
      };
    }>;
  } | null;
};

export type GetJobDescriptionByPkQueryVariables = Exact<{
  jdId: Scalars["uuid"];
}>;

export type GetJobDescriptionByPkQuery = {
  __typename?: "query_root";
  job_description_by_pk?: {
    __typename?: "job_description";
    id: any;
    title: string;
    role_id?: any | null;
    status: string;
    step: Job_Description_Step_Enum;
    salary?: any | null;
    job_description_about?: any | null;
    job_description_intro?: any | null;
    job_description_skills?: any | null;
    job_description_benefits?: any | null;
    job_experience?: any | null;
    job_environment?: any | null;
    academic_department?: any | null;
    job_description_responsibilities?: any | null;
    job_description_requirements?: any | null;
    job_type?: any | null;
    sections?: any | null;
    role?: {
      __typename?: "role";
      id: any;
      title: string;
      position?: string | null;
      experience_level?: string | null;
    } | null;
    project: {
      __typename?: "project";
      id: any;
      client: {
        __typename?: "client";
        id: any;
        client_name: string;
        description?: string | null;
        employee_count?: number | null;
        client_locations: Array<{
          __typename?: "client_location";
          city: { __typename?: "city"; city_name: string };
        }>;
      };
    };
  } | null;
};

export type GetLanguagesDropdownQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetLanguagesDropdownQuery = {
  __typename?: "query_root";
  language: Array<{ __typename?: "language"; language: string }>;
};

export type GetNextQuestionQueryVariables = Exact<{
  jid: Scalars["uuid"];
}>;

export type GetNextQuestionQuery = {
  __typename?: "query_root";
  getNextJDQuestion?: {
    __typename?: "JDPageOutput";
    title: string;
    subtitle?: string | null;
    completed: number;
    page: string;
    step: string;
    answers?: Array<{
      __typename?: "JDAnswerData";
      value?: string | null;
      isSelected?: boolean | null;
      title?: string | null;
      body?: string | null;
    }> | null;
    meta?: {
      __typename?: "JDPageMeta";
      canAddMore?: boolean | null;
      maxVisibleCount?: number | null;
      position?: string | null;
      company?: string | null;
      location?: string | null;
      workingType?: string | null;
      numberOfEmployees?: string | null;
    } | null;
    validation?: {
      __typename?: "JDPageValidation";
      count?: {
        __typename?: "JDPageValidationCount";
        min?: number | null;
        max?: number | null;
      } | null;
    } | null;
  } | null;
};

export type GetProjectEditInfoQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  projectId: Scalars["uuid"];
}>;

export type GetProjectEditInfoQuery = {
  __typename?: "query_root";
  project_by_pk?: {
    __typename?: "project";
    description?: string | null;
    id: any;
    start_date?: any | null;
    end_date?: any | null;
    project_name: string;
    language: Language_Enum;
    interview_ends_at?: any | null;
    interview_starts_at?: any | null;
    client: { __typename?: "client"; client_name: string; id: any };
    role?: { __typename?: "role"; id: any; title: string } | null;
    interview_template?: {
      __typename?: "interview_template";
      id: number;
      template_name: string;
    } | null;
  } | null;
};

export type GetProjectByIdQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetProjectByIdQuery = {
  __typename?: "query_root";
  project: Array<{
    __typename?: "project";
    project_name: string;
    language: Language_Enum;
    role_id?: any | null;
  }>;
};

export type GetProjectInterviewTemplateByIdQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetProjectInterviewTemplateByIdQuery = {
  __typename?: "query_root";
  project_by_pk?: {
    __typename?: "project";
    interview_template_id?: number | null;
  } | null;
};

export type GetProjectsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  projectOrder?: InputMaybe<Array<Project_Order_By> | Project_Order_By>;
  projectFilter?: InputMaybe<Project_Bool_Exp>;
}>;

export type GetProjectsQuery = {
  __typename?: "query_root";
  project: Array<{
    __typename?: "project";
    id: any;
    description?: string | null;
    is_default?: boolean | null;
    end_date?: any | null;
    project_name: string;
    status: Project_Status_Enum;
    created_at?: any | null;
    client: { __typename?: "client"; client_name: string };
    project_users: Array<{
      __typename?: "project_user";
      user: {
        __typename?: "user";
        name: string;
        surname: string;
        avatar?: string | null;
        email: string;
      };
    }>;
  }>;
  project_aggregate: {
    __typename?: "project_aggregate";
    aggregate?: {
      __typename?: "project_aggregate_fields";
      count: number;
    } | null;
  };
};

export type GetProjectsAggregateQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetProjectsAggregateQuery = {
  __typename?: "query_root";
  project_aggregate: {
    __typename?: "project_aggregate";
    aggregate?: {
      __typename?: "project_aggregate_fields";
      count: number;
    } | null;
  };
};

export type GetProjectsDropdownQueryVariables = Exact<{ [key: string]: never }>;

export type GetProjectsDropdownQuery = {
  __typename?: "query_root";
  project: Array<{
    __typename?: "project";
    id: any;
    is_default?: boolean | null;
    project_name: string;
    client: { __typename?: "client"; client_name: string; id: any };
  }>;
};

export type GetReviewMetricByRoleAndLanguageQueryVariables = Exact<{
  roleId: Scalars["uuid"];
  language?: InputMaybe<Array<Language_Enum> | Language_Enum>;
}>;

export type GetReviewMetricByRoleAndLanguageQuery = {
  __typename?: "query_root";
  role_review_metric: Array<{
    __typename?: "role_review_metric";
    review_metric: {
      __typename?: "review_metric";
      id: number;
      description: string;
    };
  }>;
};

export type GetReviewMetricQuestionByIdQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type GetReviewMetricQuestionByIdQuery = {
  __typename?: "query_root";
  review_metric_question: Array<{
    __typename?: "review_metric_question";
    description: string;
    id: number;
    question?: string | null;
    reviewer_description?: string | null;
    company_id?: any | null;
    is_shown_to_candidate: boolean;
    judge?: string | null;
    order: number;
    question_type: Review_Metric_Question_Type_Enum;
    tip?: string | null;
    review_metric: {
      __typename?: "review_metric";
      description: string;
      id: number;
    };
    review_metric_question_responses: Array<{
      __typename?: "review_metric_question_response";
      description: string;
      score: number;
      id: number;
    }>;
  }>;
};

export type GetReviewMetricQuestionsByIdsQueryVariables = Exact<{
  ids: Array<Scalars["Int"]> | Scalars["Int"];
}>;

export type GetReviewMetricQuestionsByIdsQuery = {
  __typename?: "query_root";
  review_metric_question: Array<{
    __typename?: "review_metric_question";
    description: string;
    id: number;
    question?: string | null;
    reviewer_description?: string | null;
    company_id?: any | null;
    is_shown_to_candidate: boolean;
    judge?: string | null;
    order: number;
    question_type: Review_Metric_Question_Type_Enum;
    tip?: string | null;
    review_metric: {
      __typename?: "review_metric";
      description: string;
      id: number;
    };
    review_metric_question_responses: Array<{
      __typename?: "review_metric_question_response";
      description: string;
      score: number;
      id: number;
    }>;
  }>;
};

export type GetReviewMetricQuestionsByMetricIdQueryVariables = Exact<{
  reviewMetricId: Scalars["Int"];
}>;

export type GetReviewMetricQuestionsByMetricIdQuery = {
  __typename?: "query_root";
  review_metric_question: Array<{
    __typename?: "review_metric_question";
    description: string;
    id: number;
    is_shown_to_candidate: boolean;
    review_metric: { __typename?: "review_metric"; language: Language_Enum };
  }>;
};

export type GetRolesQueryVariables = Exact<{ [key: string]: never }>;

export type GetRolesQuery = {
  __typename?: "query_root";
  role: Array<{
    __typename?: "role";
    id: any;
    title: string;
    experience_level?: string | null;
    position?: string | null;
  }>;
};

export type GetRolesDropdownQueryVariables = Exact<{
  title?: InputMaybe<Scalars["String"]>;
}>;

export type GetRolesDropdownQuery = {
  __typename?: "query_root";
  role: Array<{ __typename?: "role"; id: any; title: string }>;
};

export type GetSectorQueryVariables = Exact<{ [key: string]: never }>;

export type GetSectorQuery = {
  __typename?: "query_root";
  sector: Array<{ __typename?: "sector"; sector_name: string; id: number }>;
};

export type GetTestInterviewQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetTestInterviewQuery = {
  __typename?: "query_root";
  video_interview_criterion_score: Array<{
    __typename?: "video_interview_criterion_score";
    score: number;
    score_description: string;
    id: number;
    interview_criterion: {
      __typename?: "interview_criterion";
      id: number;
      name: string;
    };
    video_interview: {
      __typename?: "video_interview";
      interview_video_url?: string | null;
      score: number;
      completed_at?: any | null;
      id: any;
      client_candidate_project: {
        __typename?: "client_candidate_project";
        client_candidate: {
          __typename?: "client_candidate";
          name: string;
          surname: string;
          client: { __typename?: "client"; client_name: string };
        };
        project: { __typename?: "project"; project_name: string };
      };
    };
  }>;
};

export type GetUserByPkQueryVariables = Exact<{
  userId: Scalars["uuid"];
}>;

export type GetUserByPkQuery = {
  __typename?: "query_root";
  user_by_pk?: {
    __typename?: "user";
    email: string;
    name: string;
    surname: string;
    avatar?: string | null;
    title?: string | null;
    company: { __typename?: "company"; company_name: string };
  } | null;
};

export type GetUserCompanyQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type GetUserCompanyQuery = {
  __typename?: "query_root";
  user_by_pk?: {
    __typename?: "user";
    company: { __typename?: "company"; company_name: string; id: any };
  } | null;
};

export type GetUserKeysQueryVariables = Exact<{
  userId: Scalars["uuid"];
}>;

export type GetUserKeysQuery = {
  __typename?: "query_root";
  user_key: Array<{
    __typename?: "user_key";
    created_at: any;
    expired_at?: any | null;
    id: any;
    label: string;
  }>;
};

export type GetUsersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  searchString?: InputMaybe<Scalars["String"]>;
}>;

export type GetUsersQuery = {
  __typename?: "query_root";
  user: Array<{
    __typename?: "user";
    id: any;
    name: string;
    surname: string;
    email: string;
    is_active: boolean;
    created_at?: any | null;
    avatar?: string | null;
    company: { __typename?: "company"; company_name: string };
    user_roles: Array<{
      __typename?: "user_role";
      user_role_name: string;
      user_id: any;
    }>;
  }>;
};

export type GetUsersCountQueryVariables = Exact<{ [key: string]: never }>;

export type GetUsersCountQuery = {
  __typename?: "query_root";
  user_aggregate: {
    __typename?: "user_aggregate";
    aggregate?: { __typename?: "user_aggregate_fields"; count: number } | null;
  };
};

export type GetVideoInterviewQueryVariables = Exact<{
  id: Scalars["uuid"];
  question_order?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
}>;

export type GetVideoInterviewQuery = {
  __typename?: "query_root";
  video_interview_by_pk?: {
    __typename?: "video_interview";
    interview_video_url?: string | null;
    id: any;
    score: number;
    completed_at?: any | null;
    video_interview_type: Video_Interview_Type_Enum;
    video_interview_questions: Array<{
      __typename?: "video_interview_question";
      id: number;
      video_interview_score: Array<{
        __typename?: "video_interview_question_score";
        review_metric_question_response_id: number;
        video_interview_question_id: number;
        is_ai_score: boolean;
        scored_by?: any | null;
        score_description?: string | null;
        video_interview_scored_by?: {
          __typename?: "user";
          avatar?: string | null;
          name: string;
        } | null;
      }>;
      review_metric_question: {
        __typename?: "review_metric_question";
        is_shown_to_candidate: boolean;
        id: number;
        reviewer_description?: string | null;
        question?: string | null;
        review_metric_question_responses: Array<{
          __typename?: "review_metric_question_response";
          score: number;
          id: number;
          description: string;
        }>;
        review_metric: {
          __typename?: "review_metric";
          description: string;
          order: number;
          id: number;
        };
      };
    }>;
    client_candidate_project: {
      __typename?: "client_candidate_project";
      client_candidate: {
        __typename?: "client_candidate";
        name: string;
        surname: string;
        client: { __typename?: "client"; client_name: string };
      };
      project: {
        __typename?: "project";
        project_name: string;
        interview_template?: {
          __typename?: "interview_template";
          interview_template_review_metric_questions: Array<{
            __typename?: "interview_template_review_metric_question";
            weight?: any | null;
            review_metric_question: {
              __typename?: "review_metric_question";
              id: number;
            };
          }>;
        } | null;
      };
    };
  } | null;
};

export type GetVideoInterviewsByDateQueryVariables = Exact<{
  startedAt: Scalars["timestamptz"];
  endedAt: Scalars["timestamptz"];
}>;

export type GetVideoInterviewsByDateQuery = {
  __typename?: "query_root";
  video_interview_aggregate: {
    __typename?: "video_interview_aggregate";
    aggregate?: {
      __typename?: "video_interview_aggregate_fields";
      count: number;
    } | null;
  };
};

export type GetVideoInterviewByProjectIdQueryVariables = Exact<{
  projectId: Scalars["uuid"];
}>;

export type GetVideoInterviewByProjectIdQuery = {
  __typename?: "query_root";
  client_candidate_project: Array<{
    __typename?: "client_candidate_project";
    video_interviews: Array<{ __typename?: "video_interview"; id: any }>;
  }>;
};

export type InsertApiKeyMutationVariables = Exact<{
  label: Scalars["String"];
  expiredAt: Scalars["String"];
}>;

export type InsertApiKeyMutation = {
  __typename?: "mutation_root";
  insertApiKey: {
    __typename?: "ApiKeyActionOutput";
    apiKey?: string | null;
    message?: string | null;
    success?: boolean | null;
  };
};

export type InsertClientMutationVariables = Exact<{
  clientName?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  employeeCount?: InputMaybe<Scalars["Int"]>;
  hasMultilocation?: InputMaybe<Scalars["Boolean"]>;
  working_type?: InputMaybe<Scalars["String"]>;
  addresses: Array<Client_Location_Insert_Input> | Client_Location_Insert_Input;
  sector_id?: InputMaybe<Scalars["Int"]>;
}>;

export type InsertClientMutation = {
  __typename?: "mutation_root";
  insert_client?: {
    __typename?: "client_mutation_response";
    returning: Array<{ __typename?: "client"; id: any }>;
  } | null;
};

export type InsertClientCandidateMutationVariables = Exact<{
  input: Array<Client_Candidate_Insert_Input> | Client_Candidate_Insert_Input;
}>;

export type InsertClientCandidateMutation = {
  __typename?: "mutation_root";
  insert_client_candidate?: {
    __typename?: "client_candidate_mutation_response";
    returning: Array<{ __typename?: "client_candidate"; id: any }>;
  } | null;
};

export type InsertClientCandidateProjectMutationVariables = Exact<{
  clientCandidateId?: InputMaybe<Scalars["uuid"]>;
  projectId?: InputMaybe<Scalars["uuid"]>;
  candidateStatus?: InputMaybe<Client_Candidate_Project_Candidate_List_Status_Enum>;
}>;

export type InsertClientCandidateProjectMutation = {
  __typename?: "mutation_root";
  insert_client_candidate_project?: {
    __typename?: "client_candidate_project_mutation_response";
    returning: Array<{ __typename?: "client_candidate_project"; id: number }>;
  } | null;
};

export type InsertClientCandidateProjectNoteMutationVariables = Exact<{
  client_candidate_project_id: Scalars["Int"];
  note: Scalars["String"];
}>;

export type InsertClientCandidateProjectNoteMutation = {
  __typename?: "mutation_root";
  insert_client_candidate_project_note?: {
    __typename?: "client_candidate_project_note_mutation_response";
    affected_rows: number;
  } | null;
};

export type InsertInterviewTemplateMutationVariables = Exact<{
  objects?: InputMaybe<
    Array<Interview_Template_Insert_Input> | Interview_Template_Insert_Input
  >;
}>;

export type InsertInterviewTemplateMutation = {
  __typename?: "mutation_root";
  insert_interview_template?: {
    __typename?: "interview_template_mutation_response";
    returning: Array<{ __typename?: "interview_template"; id: number }>;
  } | null;
};

export type InsertJobDescriptionMutationVariables = Exact<{
  roleId?: InputMaybe<Scalars["uuid"]>;
  projectId?: InputMaybe<Scalars["uuid"]>;
  title?: InputMaybe<Scalars["String"]>;
  clientName?: InputMaybe<Scalars["String"]>;
}>;

export type InsertJobDescriptionMutation = {
  __typename?: "mutation_root";
  insert_job_description?: {
    __typename?: "job_description_mutation_response";
    returning: Array<{ __typename?: "job_description"; id: any }>;
  } | null;
};

export type InsertProjectMutationVariables = Exact<{
  endDate?: InputMaybe<Scalars["date"]>;
  startDate?: InputMaybe<Scalars["date"]>;
  description?: InputMaybe<Scalars["String"]>;
  projectName?: InputMaybe<Scalars["String"]>;
  clientId?: InputMaybe<Scalars["uuid"]>;
  language?: InputMaybe<Language_Enum>;
  role_id?: InputMaybe<Scalars["uuid"]>;
  interviewTemplateId?: InputMaybe<Scalars["Int"]>;
}>;

export type InsertProjectMutation = {
  __typename?: "mutation_root";
  insert_project?: {
    __typename?: "project_mutation_response";
    returning: Array<{ __typename?: "project"; id: any }>;
  } | null;
};

export type InsertReviewMetricQuestionMutationVariables = Exact<{
  objects:
    | Array<Review_Metric_Question_Insert_Input>
    | Review_Metric_Question_Insert_Input;
}>;

export type InsertReviewMetricQuestionMutation = {
  __typename?: "mutation_root";
  insert_review_metric_question?: {
    __typename?: "review_metric_question_mutation_response";
    returning: Array<{ __typename?: "review_metric_question"; id: number }>;
  } | null;
};

export type InsertVideoInterviewScoreMutationVariables = Exact<{
  responseId: Scalars["Int"];
  videoInterviewQuestionId: Scalars["Int"];
  scoreDescription?: InputMaybe<Scalars["String"]>;
}>;

export type InsertVideoInterviewScoreMutation = {
  __typename?: "mutation_root";
  insert_video_interview_question_score_one?: {
    __typename?: "video_interview_question_score";
    id: number;
  } | null;
};

export type InsertVideoInterviewStatusMutationVariables = Exact<{
  vid: Scalars["uuid"];
  status?: InputMaybe<Video_Interview_Status_Enum>;
}>;

export type InsertVideoInterviewStatusMutation = {
  __typename?: "mutation_root";
  insert_video_interview_status_history_one?: {
    __typename?: "video_interview_status_history";
    id: number;
  } | null;
};

export type InviteMemberMutationVariables = Exact<{
  email: Scalars["String"];
  name: Scalars["String"];
  surname: Scalars["String"];
}>;

export type InviteMemberMutation = {
  __typename?: "mutation_root";
  inviteMember?: {
    __typename?: "CommonActionOutput";
    success?: boolean | null;
    message?: string | null;
  } | null;
};

export type LoginMutationVariables = Exact<{
  username: Scalars["String"];
  password: Scalars["String"];
}>;

export type LoginMutation = {
  __typename?: "mutation_root";
  login?: {
    __typename?: "LoginOutput";
    accessToken: string;
    otpExpiration?: string | null;
    twoFactorRequired?: boolean | null;
  } | null;
};

export type RegisterMutationVariables = Exact<{
  inviteToken: Scalars["String"];
  name: Scalars["String"];
  otpCode: Scalars["String"];
  password: Scalars["String"];
  phoneNumber: Scalars["String"];
  surname: Scalars["String"];
}>;

export type RegisterMutation = {
  __typename?: "mutation_root";
  register?: { __typename?: "RegisterOutput"; accessToken: string } | null;
};

export type ResendOtpMutationVariables = Exact<{
  invitationToken?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
}>;

export type ResendOtpMutation = {
  __typename?: "mutation_root";
  resendOtp?: {
    __typename?: "SendOtpOutput";
    otpExpiration: string;
    success: boolean;
  } | null;
};

export type ResetPasswordMutationVariables = Exact<{
  username: Scalars["String"];
  recoveryToken: Scalars["String"];
  password: Scalars["String"];
}>;

export type ResetPasswordMutation = {
  __typename?: "mutation_root";
  resetPassword: {
    __typename?: "CommonActionOutput";
    success?: boolean | null;
    message?: string | null;
  };
};

export type SearchQueryVariables = Exact<{
  index: Scalars["String"];
  limit: Scalars["Int"];
  offset: Scalars["Int"];
  query: Scalars["String"];
}>;

export type SearchQuery = {
  __typename?: "query_root";
  search?: {
    __typename?: "SearchOutput";
    clientCandidate?: Array<{
      __typename?: "ClientCandidateSearchOutput";
      id: string;
    } | null> | null;
  } | null;
};

export type SendOtpMutationVariables = Exact<{
  invitationToken: Scalars["String"];
  phoneNumber: Scalars["String"];
}>;

export type SendOtpMutation = {
  __typename?: "mutation_root";
  sendOtp?: {
    __typename?: "SendOtpOutput";
    success: boolean;
    otpExpiration: string;
  } | null;
};

export type SendVideoInterviewLinkToCandidateMutationVariables = Exact<{
  ccpIds: Array<Scalars["Int"]> | Scalars["Int"];
  interviewType: Scalars["String"];
  resetInterview?: InputMaybe<Scalars["Boolean"]>;
}>;

export type SendVideoInterviewLinkToCandidateMutation = {
  __typename?: "mutation_root";
  sendVideoInterviewLinkToCandidates: {
    __typename?: "CommonActionOutput";
    success?: boolean | null;
    message?: string | null;
  };
};

export type GenerateShareableJdLinkMutationVariables = Exact<{
  jdId: Scalars["String"];
}>;

export type GenerateShareableJdLinkMutation = {
  __typename?: "mutation_root";
  generateShareableJDLink?: {
    __typename?: "GenerateShareableJDLinkOutput";
    shareLink: string;
  } | null;
};

export type UpdateCandidateResolutionMutationVariables = Exact<{
  id: Scalars["Int"];
  candidate_resolution: Client_Candidate_Project_Candidate_Resolution_Enum;
}>;

export type UpdateCandidateResolutionMutation = {
  __typename?: "mutation_root";
  update_client_candidate_project_by_pk?: {
    __typename?: "client_candidate_project";
    id: number;
  } | null;
};

export type UpdateClientMutationVariables = Exact<{
  client_id: Scalars["uuid"];
  client_name: Scalars["String"];
  description?: InputMaybe<Scalars["String"]>;
  employee_count?: InputMaybe<Scalars["Int"]>;
  has_multilocation?: InputMaybe<Scalars["Boolean"]>;
  working_type?: InputMaybe<Scalars["String"]>;
  client_locations:
    | Array<Client_Location_Insert_Input>
    | Client_Location_Insert_Input;
  client_sectors: Client_Sector_Insert_Input;
}>;

export type UpdateClientMutation = {
  __typename?: "mutation_root";
  update_client_by_pk?: { __typename?: "client"; id: any } | null;
  insert_client_location?: {
    __typename?: "client_location_mutation_response";
    affected_rows: number;
  } | null;
  insert_client_sector_one?: {
    __typename?: "client_sector";
    id: number;
  } | null;
};

export type UpdateClientCandidateMutationVariables = Exact<{
  clientCandidateId: Scalars["uuid"];
  name?: InputMaybe<Scalars["String"]>;
  surname?: InputMaybe<Scalars["String"]>;
  mobile_number?: InputMaybe<Scalars["String"]>;
  email_address?: InputMaybe<Scalars["String"]>;
  cv_file_uri?: InputMaybe<Scalars["String"]>;
  cv_file_name?: InputMaybe<Scalars["String"]>;
}>;

export type UpdateClientCandidateMutation = {
  __typename?: "mutation_root";
  update_client_candidate_by_pk?: {
    __typename?: "client_candidate";
    id: any;
  } | null;
};

export type UpdateClientCandidateProjectNoteMutationVariables = Exact<{
  id: Scalars["Int"];
  note: Scalars["String"];
}>;

export type UpdateClientCandidateProjectNoteMutation = {
  __typename?: "mutation_root";
  update_client_candidate_project_note_by_pk?: {
    __typename?: "client_candidate_project_note";
    id: number;
  } | null;
};

export type UpdateJobDescriptionSkillsMutationVariables = Exact<{
  id?: InputMaybe<Uuid_Comparison_Exp>;
  values?: InputMaybe<Scalars["jsonb"]>;
  step?: InputMaybe<Job_Description_Step_Enum>;
}>;

export type UpdateJobDescriptionSkillsMutation = {
  __typename?: "mutation_root";
  update_job_description?: {
    __typename?: "job_description_mutation_response";
    returning: Array<{ __typename?: "job_description"; id: any }>;
  } | null;
};

export type UpdateJobDescriptionEducationTypeMutationVariables = Exact<{
  id?: InputMaybe<Uuid_Comparison_Exp>;
  values?: InputMaybe<Scalars["jsonb"]>;
  step?: InputMaybe<Job_Description_Step_Enum>;
}>;

export type UpdateJobDescriptionEducationTypeMutation = {
  __typename?: "mutation_root";
  update_job_description?: {
    __typename?: "job_description_mutation_response";
    returning: Array<{ __typename?: "job_description"; id: any }>;
  } | null;
};

export type UpdateJobDescriptionExperienceLevelMutationVariables = Exact<{
  id?: InputMaybe<Uuid_Comparison_Exp>;
  values?: InputMaybe<Scalars["jsonb"]>;
  step?: InputMaybe<Job_Description_Step_Enum>;
}>;

export type UpdateJobDescriptionExperienceLevelMutation = {
  __typename?: "mutation_root";
  update_job_description?: {
    __typename?: "job_description_mutation_response";
    returning: Array<{ __typename?: "job_description"; id: any }>;
  } | null;
};

export type UpdateJobDescriptionRequirementsMutationVariables = Exact<{
  id?: InputMaybe<Uuid_Comparison_Exp>;
  values?: InputMaybe<Scalars["jsonb"]>;
  step?: InputMaybe<Job_Description_Step_Enum>;
}>;

export type UpdateJobDescriptionRequirementsMutation = {
  __typename?: "mutation_root";
  update_job_description?: {
    __typename?: "job_description_mutation_response";
    returning: Array<{ __typename?: "job_description"; id: any }>;
  } | null;
};

export type UpdateJobDescriptionResponsibilitiesMutationVariables = Exact<{
  id?: InputMaybe<Uuid_Comparison_Exp>;
  values?: InputMaybe<Scalars["jsonb"]>;
  step?: InputMaybe<Job_Description_Step_Enum>;
}>;

export type UpdateJobDescriptionResponsibilitiesMutation = {
  __typename?: "mutation_root";
  update_job_description?: {
    __typename?: "job_description_mutation_response";
    returning: Array<{ __typename?: "job_description"; id: any }>;
  } | null;
};

export type UpdateJobDescriptionBenefitsMutationVariables = Exact<{
  id?: InputMaybe<Uuid_Comparison_Exp>;
  values?: InputMaybe<Scalars["jsonb"]>;
  step?: InputMaybe<Job_Description_Step_Enum>;
}>;

export type UpdateJobDescriptionBenefitsMutation = {
  __typename?: "mutation_root";
  update_job_description?: {
    __typename?: "job_description_mutation_response";
    returning: Array<{ __typename?: "job_description"; id: any }>;
  } | null;
};

export type UpdateJobDescriptionSalaryMutationVariables = Exact<{
  id?: InputMaybe<Uuid_Comparison_Exp>;
  values?: InputMaybe<Scalars["jsonb"]>;
  step?: InputMaybe<Job_Description_Step_Enum>;
}>;

export type UpdateJobDescriptionSalaryMutation = {
  __typename?: "mutation_root";
  update_job_description?: {
    __typename?: "job_description_mutation_response";
    returning: Array<{ __typename?: "job_description"; id: any }>;
  } | null;
};

export type UpdateJobDescriptionIntroductionMutationVariables = Exact<{
  id?: InputMaybe<Uuid_Comparison_Exp>;
  values?: InputMaybe<Scalars["String"]>;
  step?: InputMaybe<Job_Description_Step_Enum>;
}>;

export type UpdateJobDescriptionIntroductionMutation = {
  __typename?: "mutation_root";
  update_job_description?: {
    __typename?: "job_description_mutation_response";
    returning: Array<{ __typename?: "job_description"; id: any }>;
  } | null;
};

export type UpdateProjectMutationVariables = Exact<{
  id: Scalars["uuid"];
  project_name?: InputMaybe<Scalars["String"]>;
  start_date?: InputMaybe<Scalars["date"]>;
  end_date?: InputMaybe<Scalars["date"]>;
  description?: InputMaybe<Scalars["String"]>;
  client_id?: InputMaybe<Scalars["uuid"]>;
  language?: InputMaybe<Language_Enum>;
  role_id?: InputMaybe<Scalars["uuid"]>;
  interviewTemplateId?: InputMaybe<Scalars["Int"]>;
}>;

export type UpdateProjectMutation = {
  __typename?: "mutation_root";
  update_project_by_pk?: { __typename?: "project"; id: any } | null;
};

export type UpdateProjectInterviewTemplateMutationVariables = Exact<{
  id: Scalars["uuid"];
  interview_template_id: Scalars["Int"];
}>;

export type UpdateProjectInterviewTemplateMutation = {
  __typename?: "mutation_root";
  update_project_by_pk?: { __typename?: "project"; id: any } | null;
};

export type UpdateProjectLanguageMutationVariables = Exact<{
  projectId: Scalars["uuid"];
  language: Language_Enum;
}>;

export type UpdateProjectLanguageMutation = {
  __typename?: "mutation_root";
  update_project_by_pk?: { __typename?: "project"; id: any } | null;
};

export type UpdateProjectStartAndEndDateMutationVariables = Exact<{
  projectId: Scalars["uuid"];
  interview_starts_at: Scalars["timestamptz"];
  interview_ends_at: Scalars["timestamptz"];
}>;

export type UpdateProjectStartAndEndDateMutation = {
  __typename?: "mutation_root";
  update_project_by_pk?: { __typename?: "project"; id: any } | null;
};

export type UpdateProjectStatusMutationVariables = Exact<{
  status?: InputMaybe<Project_Status_Enum>;
  projectId: Scalars["uuid"];
}>;

export type UpdateProjectStatusMutation = {
  __typename?: "mutation_root";
  update_project_by_pk?: { __typename?: "project"; id: any } | null;
};

export type UpdateProjectTemplateIdMutationVariables = Exact<{
  id: Scalars["uuid"];
  interview_template_id: Scalars["Int"];
}>;

export type UpdateProjectTemplateIdMutation = {
  __typename?: "mutation_root";
  update_project_by_pk?: { __typename?: "project"; id: any } | null;
};

export type UpdateUserAvatarMutationVariables = Exact<{
  id: Scalars["uuid"];
  avatar: Scalars["String"];
}>;

export type UpdateUserAvatarMutation = {
  __typename?: "mutation_root";
  update_user_by_pk?: { __typename?: "user"; id: any } | null;
};

export type UpdateUserKeyMutationVariables = Exact<{
  id: Scalars["uuid"];
  expired_at?: InputMaybe<Scalars["timestamptz"]>;
}>;

export type UpdateUserKeyMutation = {
  __typename?: "mutation_root";
  update_user_key_by_pk?: { __typename?: "user_key"; id: any } | null;
};

export type UpdateUserTitleMutationVariables = Exact<{
  id: Scalars["uuid"];
  title?: InputMaybe<Scalars["String"]>;
}>;

export type UpdateUserTitleMutation = {
  __typename?: "mutation_root";
  update_user_by_pk?: { __typename?: "user"; id: any } | null;
};

export type UpdateVideoInterviewScoreMutationVariables = Exact<{
  videoInterviewQuestionId: Scalars["Int"];
  scoreDescription?: InputMaybe<Scalars["String"]>;
}>;

export type UpdateVideoInterviewScoreMutation = {
  __typename?: "mutation_root";
  update_video_interview_question_score?: {
    __typename?: "video_interview_question_score_mutation_response";
    returning: Array<{
      __typename?: "video_interview_question_score";
      id: number;
    }>;
  } | null;
};

export type UpsertClientCandidateMutationVariables = Exact<{
  input: Array<Client_Candidate_Insert_Input> | Client_Candidate_Insert_Input;
}>;

export type UpsertClientCandidateMutation = {
  __typename?: "mutation_root";
  insert_client_candidate?: {
    __typename?: "client_candidate_mutation_response";
    returning: Array<{ __typename?: "client_candidate"; id: any }>;
  } | null;
};

export type Verify2FaMutationVariables = Exact<{
  otpCode: Scalars["String"];
}>;

export type Verify2FaMutation = {
  __typename?: "mutation_root";
  verify2FA?: { __typename?: "Verify2FAOutput"; accessToken: string } | null;
};

export type GetSignedUrlQueryVariables = Exact<{
  signedUrlInput: SignedUrlInput;
}>;

export type GetSignedUrlQuery = {
  __typename?: "query_root";
  getSignedUrl?: {
    __typename?: "SignedUrlOutput";
    signedUrls: Array<string>;
  } | null;
};

export const ChangeCandidateStatusDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ChangeCandidateStatus" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "status" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "client_candidate_project_candidate_list_status_enum",
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "ids" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_client_candidate_project" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_in" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "ids" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "candidate_list_status" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "status" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returning" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ChangeCandidateStatusMutation,
  ChangeCandidateStatusMutationVariables
>;
export const ChangePasswordDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "changePassword" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "currentPassword" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "newPassword" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "changePassword" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "passwordChangeInput" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "currentPassword" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "currentPassword" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "newPassword" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "newPassword" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "message" } },
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;
export const DeleteClientDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteClient" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "delete_client_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteClientMutation,
  DeleteClientMutationVariables
>;
export const DeleteClientCandidateProjectDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteClientCandidateProject" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "projectId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "clientCandidateIds" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "uuid" },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "delete_client_candidate_project" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "project_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "projectId" },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "client_candidate_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_in" },
                            value: {
                              kind: "Variable",
                              name: {
                                kind: "Name",
                                value: "clientCandidateIds",
                              },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returning" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "project_id" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteClientCandidateProjectMutation,
  DeleteClientCandidateProjectMutationVariables
>;
export const DeleteClientCandidateProjectNoteDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteClientCandidateProjectNote" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "delete_client_candidate_project_note_by_pk",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteClientCandidateProjectNoteMutation,
  DeleteClientCandidateProjectNoteMutationVariables
>;
export const DeleteInterviewTemplateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteInterviewTemplate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "delete_interview_template" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returning" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteInterviewTemplateMutation,
  DeleteInterviewTemplateMutationVariables
>;
export const DeleteJobDescriptionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "deleteJobDescription" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "delete_job_description_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteJobDescriptionMutation,
  DeleteJobDescriptionMutationVariables
>;
export const DeleteProjectDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteProject" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "delete_project_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteProjectMutation,
  DeleteProjectMutationVariables
>;
export const DeleteReviewMetricQuestionsRelatedInterviewTemplateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "DeleteReviewMetricQuestionsRelatedInterviewTemplate",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "interviewTemplateId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "delete_interview_template_review_metric_question",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "interview_template_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: {
                                kind: "Name",
                                value: "interviewTemplateId",
                              },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteReviewMetricQuestionsRelatedInterviewTemplateMutation,
  DeleteReviewMetricQuestionsRelatedInterviewTemplateMutationVariables
>;
export const DeleteUserKeyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "deleteUserKey" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "delete_user_key_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteUserKeyMutation,
  DeleteUserKeyMutationVariables
>;
export const ForgotPasswordDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "forgotPassword" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "forgotPassword" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
                { kind: "Field", name: { kind: "Name", value: "message" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>;
export const GetCitiesDropdownDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getCitiesDropdown" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "city" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "city_name" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCitiesDropdownQuery,
  GetCitiesDropdownQueryVariables
>;
export const GetClientDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getClient" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "client_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "client_name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "client_locations" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "address" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "address_phone" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "city" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "city_name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "country" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "country_name",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "city_id" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "has_multilocation" },
                },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "employee_count" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "working_type" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "client_sectors" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sector_id" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sector" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sector_name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetClientQuery, GetClientQueryVariables>;
export const GetClientCandidateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getClientCandidate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "searchString" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "client_candidate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "_or" },
                      value: {
                        kind: "ListValue",
                        values: [
                          {
                            kind: "ObjectValue",
                            fields: [
                              {
                                kind: "ObjectField",
                                name: { kind: "Name", value: "email_address" },
                                value: {
                                  kind: "ObjectValue",
                                  fields: [
                                    {
                                      kind: "ObjectField",
                                      name: { kind: "Name", value: "_ilike" },
                                      value: {
                                        kind: "Variable",
                                        name: {
                                          kind: "Name",
                                          value: "searchString",
                                        },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: "ObjectValue",
                            fields: [
                              {
                                kind: "ObjectField",
                                name: { kind: "Name", value: "name" },
                                value: {
                                  kind: "ObjectValue",
                                  fields: [
                                    {
                                      kind: "ObjectField",
                                      name: { kind: "Name", value: "_ilike" },
                                      value: {
                                        kind: "Variable",
                                        name: {
                                          kind: "Name",
                                          value: "searchString",
                                        },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: "ObjectValue",
                            fields: [
                              {
                                kind: "ObjectField",
                                name: { kind: "Name", value: "surname" },
                                value: {
                                  kind: "ObjectValue",
                                  fields: [
                                    {
                                      kind: "ObjectField",
                                      name: { kind: "Name", value: "_ilike" },
                                      value: {
                                        kind: "Variable",
                                        name: {
                                          kind: "Name",
                                          value: "searchString",
                                        },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "email_address" },
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "mobile_number" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cv_file_name" },
                },
                { kind: "Field", name: { kind: "Name", value: "cv_file_uri" } },
                { kind: "Field", name: { kind: "Name", value: "client_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "surname" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "client_candidate_projects" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "video_interviews" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "score" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "video_interview_statuses",
                              },
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "order_by" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "created_at",
                                        },
                                        value: {
                                          kind: "EnumValue",
                                          value: "desc",
                                        },
                                      },
                                    ],
                                  },
                                },
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "limit" },
                                  value: { kind: "IntValue", value: "1" },
                                },
                              ],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "created_at" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetClientCandidateQuery,
  GetClientCandidateQueryVariables
>;
export const GetClientCandidateByPkDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getClientCandidateByPk" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "clientCandidateId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "client_candidate_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "clientCandidateId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "email_address" },
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "mobile_number" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cv_file_name" },
                },
                { kind: "Field", name: { kind: "Name", value: "cv_file_uri" } },
                { kind: "Field", name: { kind: "Name", value: "client_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "surname" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "client_candidate_projects" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "video_interviews" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "score" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "video_interview_statuses",
                              },
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "order_by" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "created_at",
                                        },
                                        value: {
                                          kind: "EnumValue",
                                          value: "desc",
                                        },
                                      },
                                    ],
                                  },
                                },
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "limit" },
                                  value: { kind: "IntValue", value: "1" },
                                },
                              ],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "created_at" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetClientCandidateByPkQuery,
  GetClientCandidateByPkQueryVariables
>;
export const GetClientCandidateProjectWithOrderAndFilterDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {
        kind: "Name",
        value: "getClientCandidateProjectWithOrderAndFilter",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "projectId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "candidate_list_status" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "client_candidate_project_candidate_list_status_enum",
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "listFilter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "client_candidate_project_bool_exp" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "listOrder" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "client_candidate_project_order_by",
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "client_candidate_project" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "limit" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "limit" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "offset" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "listFilter" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "order_by" },
                value: {
                  kind: "ListValue",
                  values: [
                    {
                      kind: "Variable",
                      name: { kind: "Name", value: "listOrder" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "client_candidate_id" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "client_candidate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "client_id" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "created_at" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cv_file_name" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cv_file_uri" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "email_address" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mobile_number" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "surname" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "client_candidate_projects",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "project" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "project_name",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "video_interviews" },
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "order_by" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "created_at",
                                        },
                                        value: {
                                          kind: "EnumValue",
                                          value: "desc",
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "video_interview_statuses",
                                    },
                                    arguments: [
                                      {
                                        kind: "Argument",
                                        name: {
                                          kind: "Name",
                                          value: "order_by",
                                        },
                                        value: {
                                          kind: "ObjectValue",
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "created_at",
                                              },
                                              value: {
                                                kind: "EnumValue",
                                                value: "desc",
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "video_interview",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "score",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "video_interview_type",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "status",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "video_interviews" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "order_by" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "created_at" },
                            value: { kind: "EnumValue", value: "desc" },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "video_interview_type" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "video_interview_statuses",
                        },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "order_by" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "created_at" },
                                  value: { kind: "EnumValue", value: "desc" },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "created_at" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "video_interview" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "video_interview_type",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "score" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "video_interview_questions",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "video_interview_question_events",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "video_interview_question",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "review_metric_question",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value:
                                                    "interview_template_review_metric_questions",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "question_order",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "candidate_resolution" },
                },
                {
                  kind: "Field",
                  name: {
                    kind: "Name",
                    value: "client_candidate_project_notes",
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "client_candidate_project_id",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "created_at" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "created_by" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "note" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updated_at" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "surname" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "client_candidate_aggregate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "_and" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: {
                              kind: "Name",
                              value: "client_candidate_projects",
                            },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "project_id" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "_eq" },
                                        value: {
                                          kind: "Variable",
                                          name: {
                                            kind: "Name",
                                            value: "projectId",
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: {
                                    kind: "Name",
                                    value: "candidate_list_status",
                                  },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "_eq" },
                                        value: {
                                          kind: "Variable",
                                          name: {
                                            kind: "Name",
                                            value: "candidate_list_status",
                                          },
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "aggregate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "count" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetClientCandidateProjectWithOrderAndFilterQuery,
  GetClientCandidateProjectWithOrderAndFilterQueryVariables
>;
export const GetClientCandidateScoreDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getClientCandidateScore" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "clientCandidateId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "projectId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "client_candidate_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "clientCandidateId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "client_candidate_projects" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "where" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "project_id" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "_eq" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "projectId" },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "video_interviews" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "limit" },
                            value: { kind: "IntValue", value: "1" },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "score" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetClientCandidateScoreQuery,
  GetClientCandidateScoreQueryVariables
>;
export const GetClientCandidateOverviewDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getClientCandidateOverview" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "interviewType" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "video_interview_type_enum" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "video_interview_long_list" },
            name: { kind: "Name", value: "video_interview" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "client_candidate_project" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "project_id" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "_eq" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "id" },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: {
                              kind: "Name",
                              value: "candidate_list_status",
                            },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "_eq" },
                                  value: {
                                    kind: "EnumValue",
                                    value: "IN_LONGLIST",
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "video_interview_type" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "interviewType" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "score" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "video_interview_statuses" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "order_by" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "created_at" },
                            value: { kind: "EnumValue", value: "asc" },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: {
              kind: "Name",
              value: "client_candidate_aggregate_long_list",
            },
            name: { kind: "Name", value: "client_candidate_aggregate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "_and" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: {
                              kind: "Name",
                              value: "client_candidate_projects",
                            },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "project_id" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "_eq" },
                                        value: {
                                          kind: "Variable",
                                          name: { kind: "Name", value: "id" },
                                        },
                                      },
                                    ],
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: {
                                    kind: "Name",
                                    value: "candidate_list_status",
                                  },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "_eq" },
                                        value: {
                                          kind: "EnumValue",
                                          value: "IN_LONGLIST",
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "aggregate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "count" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "video_interview_short_list" },
            name: { kind: "Name", value: "video_interview" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "client_candidate_project" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "project_id" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "_eq" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "id" },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: {
                              kind: "Name",
                              value: "candidate_list_status",
                            },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "_eq" },
                                  value: {
                                    kind: "EnumValue",
                                    value: "IN_SHORTLIST",
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "video_interview_type" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "interviewType" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "score" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "video_interview_statuses" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "order_by" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "created_at" },
                            value: { kind: "EnumValue", value: "asc" },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: {
              kind: "Name",
              value: "client_candidate_aggregate_short_list",
            },
            name: { kind: "Name", value: "client_candidate_aggregate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "_and" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: {
                              kind: "Name",
                              value: "client_candidate_projects",
                            },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "project_id" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "_eq" },
                                        value: {
                                          kind: "Variable",
                                          name: { kind: "Name", value: "id" },
                                        },
                                      },
                                    ],
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: {
                                    kind: "Name",
                                    value: "candidate_list_status",
                                  },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "_eq" },
                                        value: {
                                          kind: "EnumValue",
                                          value: "IN_SHORTLIST",
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "aggregate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "count" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "project_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "client" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "client_name" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "owner" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "surname" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "avatar" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "created_at" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "end_date" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "project_name" },
                },
                { kind: "Field", name: { kind: "Name", value: "status" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetClientCandidateOverviewQuery,
  GetClientCandidateOverviewQueryVariables
>;
export const GetClientCandidateProjectNoteDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getClientCandidateProjectNote" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "client_candidate_project" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: {
                    kind: "Name",
                    value: "client_candidate_project_notes",
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "client_candidate_project_id",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "created_at" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "created_by" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "note" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updated_at" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "surname" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetClientCandidateProjectNoteQuery,
  GetClientCandidateProjectNoteQueryVariables
>;
export const GetClientsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getClients" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "searchString" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "client" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "limit" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "limit" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "offset" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "client_name" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_ilike" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "searchString" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "client_name" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "is_default" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "employee_count" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "has_multilocation" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "client_locations" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "limit" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "address" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "client_sectors" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sector_id" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sector" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sector_name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "client_aggregate" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "aggregate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "count" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetClientsQuery, GetClientsQueryVariables>;
export const GetClientsDropdownDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getClientsDropdown" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "client" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "is_default" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "client_name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetClientsDropdownQuery,
  GetClientsDropdownQueryVariables
>;
export const GetCompanySubscriptionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getCompanySubscription" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "company_subscription" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "company_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "ends_at" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "product_price" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "price" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "max_credit" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "starts_at" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCompanySubscriptionQuery,
  GetCompanySubscriptionQueryVariables
>;
export const GetCountriesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getCountries" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "country" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "country_name" },
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cities" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "city_name" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCountriesQuery, GetCountriesQueryVariables>;
export const GetInterviewTemplateQuestionsByTemplateIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {
        kind: "Name",
        value: "getInterviewTemplateQuestionsByTemplateId",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "interview_template_review_metric_question",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "interview_template_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "review_metric_question" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "question" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "is_shown_to_candidate" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "review_metric_question_responses",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "score" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "review_metric" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "language" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetInterviewTemplateQuestionsByTemplateIdQuery,
  GetInterviewTemplateQuestionsByTemplateIdQueryVariables
>;
export const GetInterviewTemplatesDropdownDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getInterviewTemplatesDropdown" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "companyId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
          defaultValue: { kind: "StringValue", value: "", block: false },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "interview_template" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "company_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "companyId" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "template_name" },
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetInterviewTemplatesDropdownQuery,
  GetInterviewTemplatesDropdownQueryVariables
>;
export const GetJobDescriptionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getJobDescription" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "jobDescriptionFilter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "job_description_bool_exp" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "job_description" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "limit" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "limit" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "offset" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "jobDescriptionFilter" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "created_at" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "project" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "client" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "client_name" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "project_name" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "created_by" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetJobDescriptionQuery,
  GetJobDescriptionQueryVariables
>;
export const GetJobDescriptionAggregateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getJobDescriptionAggregate" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "job_description_aggregate" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "aggregate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "count" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetJobDescriptionAggregateQuery,
  GetJobDescriptionAggregateQueryVariables
>;
export const GetJobDescriptionWithPaginationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getJobDescriptionWithPagination" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "projectId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "project_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "projectId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "job_descriptions_aggregate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "aggregate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "count" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "job_descriptions" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "offset" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "offset" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "limit" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "limit" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "created_at" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "owner" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "surname" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatar" },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "step" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetJobDescriptionWithPaginationQuery,
  GetJobDescriptionWithPaginationQueryVariables
>;
export const GetJobDescriptionByPkDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetJobDescriptionByPk" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "jdId" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "job_description_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "jdId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "role_id" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "step" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "role" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "position" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "experience_level" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "salary" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "job_description_about" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "job_description_intro" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "job_description_skills" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "job_description_benefits" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "job_experience" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "job_environment" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "academic_department" },
                },
                {
                  kind: "Field",
                  name: {
                    kind: "Name",
                    value: "job_description_responsibilities",
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "job_description_requirements" },
                },
                { kind: "Field", name: { kind: "Name", value: "job_type" } },
                { kind: "Field", name: { kind: "Name", value: "sections" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "project" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "client" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "client_name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "client_locations" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "city" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "city_name",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "employee_count" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetJobDescriptionByPkQuery,
  GetJobDescriptionByPkQueryVariables
>;
export const GetLanguagesDropdownDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getLanguagesDropdown" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "language" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "language" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLanguagesDropdownQuery,
  GetLanguagesDropdownQueryVariables
>;
export const GetNextQuestionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getNextQuestion" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "jid" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getNextJDQuestion" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "jdId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "jid" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "subtitle" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "answers" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isSelected" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      { kind: "Field", name: { kind: "Name", value: "body" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "completed" } },
                { kind: "Field", name: { kind: "Name", value: "page" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "meta" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "canAddMore" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "maxVisibleCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "position" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "company" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "location" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "workingType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "numberOfEmployees" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "validation" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "count" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "min" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "max" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "step" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetNextQuestionQuery,
  GetNextQuestionQueryVariables
>;
export const GetProjectEditInfoDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getProjectEditInfo" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "projectId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "project_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "projectId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "client" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "client_name" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "start_date" } },
                { kind: "Field", name: { kind: "Name", value: "end_date" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "project_name" },
                },
                { kind: "Field", name: { kind: "Name", value: "language" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "role" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "interview_template" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "template_name" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "interview_ends_at" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "interview_starts_at" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProjectEditInfoQuery,
  GetProjectEditInfoQueryVariables
>;
export const GetProjectByIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetProjectById" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "project" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "project_name" },
                },
                { kind: "Field", name: { kind: "Name", value: "language" } },
                { kind: "Field", name: { kind: "Name", value: "role_id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProjectByIdQuery, GetProjectByIdQueryVariables>;
export const GetProjectInterviewTemplateByIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetProjectInterviewTemplateById" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "project_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "interview_template_id" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProjectInterviewTemplateByIdQuery,
  GetProjectInterviewTemplateByIdQueryVariables
>;
export const GetProjectsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getProjects" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "projectOrder" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "project_order_by" },
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "projectFilter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "project_bool_exp" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "project" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "limit" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "limit" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "offset" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "projectFilter" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "order_by" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "projectOrder" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "is_default" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "end_date" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "project_name" },
                },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "created_at" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "client" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "client_name" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "project_users" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "surname" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatar" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "project_aggregate" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "aggregate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "count" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProjectsQuery, GetProjectsQueryVariables>;
export const GetProjectsAggregateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getProjectsAggregate" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "project_aggregate" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "aggregate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "count" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProjectsAggregateQuery,
  GetProjectsAggregateQueryVariables
>;
export const GetProjectsDropdownDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getProjectsDropdown" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "project" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "is_default" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "project_name" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "client" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "client_name" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProjectsDropdownQuery,
  GetProjectsDropdownQueryVariables
>;
export const GetReviewMetricByRoleAndLanguageDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetReviewMetricByRoleAndLanguage" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "roleId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "language" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "language_enum" },
              },
            },
          },
          defaultValue: { kind: "EnumValue", value: "en" },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "role_review_metric" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "role_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "roleId" },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "review_metric" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "language" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "_in" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "language" },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "review_metric" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetReviewMetricByRoleAndLanguageQuery,
  GetReviewMetricByRoleAndLanguageQueryVariables
>;
export const GetReviewMetricQuestionByIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetReviewMetricQuestionById" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "review_metric_question" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "review_metric" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "question" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "reviewer_description" },
                },
                {
                  kind: "Field",
                  name: {
                    kind: "Name",
                    value: "review_metric_question_responses",
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "score" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "company_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "is_shown_to_candidate" },
                },
                { kind: "Field", name: { kind: "Name", value: "judge" } },
                { kind: "Field", name: { kind: "Name", value: "order" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "question_type" },
                },
                { kind: "Field", name: { kind: "Name", value: "tip" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetReviewMetricQuestionByIdQuery,
  GetReviewMetricQuestionByIdQueryVariables
>;
export const GetReviewMetricQuestionsByIdsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetReviewMetricQuestionsByIds" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "ids" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "Int" },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "review_metric_question" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_in" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "ids" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "review_metric" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "question" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "reviewer_description" },
                },
                {
                  kind: "Field",
                  name: {
                    kind: "Name",
                    value: "review_metric_question_responses",
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "score" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "company_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "is_shown_to_candidate" },
                },
                { kind: "Field", name: { kind: "Name", value: "judge" } },
                { kind: "Field", name: { kind: "Name", value: "order" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "question_type" },
                },
                { kind: "Field", name: { kind: "Name", value: "tip" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetReviewMetricQuestionsByIdsQuery,
  GetReviewMetricQuestionsByIdsQueryVariables
>;
export const GetReviewMetricQuestionsByMetricIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetReviewMetricQuestionsByMetricId" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "reviewMetricId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "review_metric_question" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "review_metric_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "reviewMetricId" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "is_shown_to_candidate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "review_metric" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "language" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetReviewMetricQuestionsByMetricIdQuery,
  GetReviewMetricQuestionsByMetricIdQueryVariables
>;
export const GetRolesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getRoles" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "role" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "experience_level" },
                },
                { kind: "Field", name: { kind: "Name", value: "position" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRolesQuery, GetRolesQueryVariables>;
export const GetRolesDropdownDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getRolesDropdown" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "title" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "role" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "title" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_ilike" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "title" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetRolesDropdownQuery,
  GetRolesDropdownQueryVariables
>;
export const GetSectorDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getSector" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "sector" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "sector_name" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSectorQuery, GetSectorQueryVariables>;
export const GetTestInterviewDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getTestInterview" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "video_interview_criterion_score" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "video_interview_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "interview_criterion" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "score" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "score_description" },
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "video_interview" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "interview_video_url" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "score" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "completed_at" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "client_candidate_project",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "client_candidate" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "surname" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "client" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "client_name",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "project" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "project_name",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTestInterviewQuery,
  GetTestInterviewQueryVariables
>;
export const GetUserByPkDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getUserByPk" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "user_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "userId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "surname" } },
                { kind: "Field", name: { kind: "Name", value: "avatar" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "company" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "company_name" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "title" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserByPkQuery, GetUserByPkQueryVariables>;
export const GetUserCompanyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getUserCompany" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "user_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "company" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "company_name" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserCompanyQuery, GetUserCompanyQueryVariables>;
export const GetUserKeysDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getUserKeys" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "user_key" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "user_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "userId" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "created_at" } },
                { kind: "Field", name: { kind: "Name", value: "expired_at" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "label" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserKeysQuery, GetUserKeysQueryVariables>;
export const GetUsersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getUsers" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "searchString" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "limit" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "limit" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "offset" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "name" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_ilike" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "searchString" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "surname" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "is_active" } },
                { kind: "Field", name: { kind: "Name", value: "created_at" } },
                { kind: "Field", name: { kind: "Name", value: "avatar" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "company" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "company_name" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "user_roles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user_role_name" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user_id" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUsersQuery, GetUsersQueryVariables>;
export const GetUsersCountDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getUsersCount" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "user_aggregate" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "aggregate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "count" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUsersCountQuery, GetUsersCountQueryVariables>;
export const GetVideoInterviewDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getVideoInterview" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "question_order" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "order_by" },
          },
          defaultValue: { kind: "EnumValue", value: "asc" },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "order" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "order_by" },
          },
          defaultValue: { kind: "EnumValue", value: "asc" },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "video_interview_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "interview_video_url" },
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "score" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "completed_at" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "video_interview_type" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "video_interview_questions" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "order_by" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "id" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "order" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "video_interview_score" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "review_metric_question_response_id",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "video_interview_question_id",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "is_ai_score" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "scored_by" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "score_description",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "video_interview_scored_by",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatar" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "review_metric_question" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "is_shown_to_candidate",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "reviewer_description",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "question" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "review_metric_question_responses",
                              },
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "order_by" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "score" },
                                        value: {
                                          kind: "EnumValue",
                                          value: "asc",
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "score" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "description",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "review_metric" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "description",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "order" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "client_candidate_project" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "client_candidate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "surname" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "client" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "client_name",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "project" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "project_name" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "interview_template",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value:
                                        "interview_template_review_metric_questions",
                                    },
                                    arguments: [
                                      {
                                        kind: "Argument",
                                        name: {
                                          kind: "Name",
                                          value: "order_by",
                                        },
                                        value: {
                                          kind: "ObjectValue",
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "question_order",
                                              },
                                              value: {
                                                kind: "Variable",
                                                name: {
                                                  kind: "Name",
                                                  value: "question_order",
                                                },
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "weight",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "review_metric_question",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetVideoInterviewQuery,
  GetVideoInterviewQueryVariables
>;
export const GetVideoInterviewsByDateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getVideoInterviewsByDate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "startedAt" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "timestamptz" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "endedAt" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "timestamptz" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "video_interview_aggregate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "completed_at" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_gte" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "startedAt" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_lte" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "endedAt" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "aggregate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "count" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetVideoInterviewsByDateQuery,
  GetVideoInterviewsByDateQueryVariables
>;
export const GetVideoInterviewByProjectIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetVideoInterviewByProjectId" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "projectId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "client_candidate_project" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "project_id" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "projectId" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "video_interviews" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetVideoInterviewByProjectIdQuery,
  GetVideoInterviewByProjectIdQueryVariables
>;
export const InsertApiKeyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "insertApiKey" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "label" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "expiredAt" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insertApiKey" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "insertApiKeyInput" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "label" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "label" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "expiredAt" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "expiredAt" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "apiKey" } },
                { kind: "Field", name: { kind: "Name", value: "message" } },
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertApiKeyMutation,
  InsertApiKeyMutationVariables
>;
export const InsertClientDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "insertClient" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "clientName" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "description" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "employeeCount" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "hasMultilocation" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "working_type" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "addresses" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "client_location_insert_input" },
                },
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sector_id" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_client" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "objects" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "client_name" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "clientName" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "description" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "description" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "employee_count" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "employeeCount" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "has_multilocation" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "hasMultilocation" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "working_type" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "working_type" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "client_locations" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "data" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "addresses" },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "client_sectors" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "data" },
                            value: {
                              kind: "ListValue",
                              values: [
                                {
                                  kind: "ObjectValue",
                                  fields: [
                                    {
                                      kind: "ObjectField",
                                      name: {
                                        kind: "Name",
                                        value: "sector_id",
                                      },
                                      value: {
                                        kind: "Variable",
                                        name: {
                                          kind: "Name",
                                          value: "sector_id",
                                        },
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returning" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertClientMutation,
  InsertClientMutationVariables
>;
export const InsertClientCandidateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "insertClientCandidate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: {
                    kind: "Name",
                    value: "client_candidate_insert_input",
                  },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_client_candidate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "objects" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returning" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertClientCandidateMutation,
  InsertClientCandidateMutationVariables
>;
export const InsertClientCandidateProjectDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "insertClientCandidateProject" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "clientCandidateId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "projectId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "candidateStatus" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "client_candidate_project_candidate_list_status_enum",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_client_candidate_project" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "objects" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "client_candidate_id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "clientCandidateId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "project_id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "projectId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "candidate_list_status" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "candidateStatus" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returning" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertClientCandidateProjectMutation,
  InsertClientCandidateProjectMutationVariables
>;
export const InsertClientCandidateProjectNoteDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "insertClientCandidateProjectNote" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "client_candidate_project_id" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "note" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "insert_client_candidate_project_note",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "objects" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: {
                        kind: "Name",
                        value: "client_candidate_project_id",
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "client_candidate_project_id",
                        },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "note" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "note" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertClientCandidateProjectNoteMutation,
  InsertClientCandidateProjectNoteMutationVariables
>;
export const InsertInterviewTemplateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "insertInterviewTemplate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "objects" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: {
                  kind: "Name",
                  value: "interview_template_insert_input",
                },
              },
            },
          },
          defaultValue: {
            kind: "ObjectValue",
            fields: [
              {
                kind: "ObjectField",
                name: {
                  kind: "Name",
                  value: "interview_template_review_metric_questions",
                },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "data" },
                      value: { kind: "ObjectValue", fields: [] },
                    },
                  ],
                },
              },
              {
                kind: "ObjectField",
                name: { kind: "Name", value: "template_name" },
                value: { kind: "StringValue", value: "", block: false },
              },
            ],
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_interview_template" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "objects" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "objects" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returning" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertInterviewTemplateMutation,
  InsertInterviewTemplateMutationVariables
>;
export const InsertJobDescriptionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "insertJobDescription" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "roleId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "projectId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "title" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "clientName" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_job_description" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "objects" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "project_id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "projectId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "title" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "title" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: {
                        kind: "Name",
                        value: "job_description_company_name",
                      },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "clientName" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returning" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertJobDescriptionMutation,
  InsertJobDescriptionMutationVariables
>;
export const InsertProjectDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "insertProject" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "endDate" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "date" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "startDate" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "date" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "description" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "projectName" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "clientId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "language" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "language_enum" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "role_id" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "interviewTemplateId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_project" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "objects" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "end_date" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "endDate" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "description" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "description" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "project_name" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "projectName" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "start_date" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "startDate" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "client_id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "clientId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "language" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "language" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "role_id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "role_id" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "interview_template_id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "interviewTemplateId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returning" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertProjectMutation,
  InsertProjectMutationVariables
>;
export const InsertReviewMetricQuestionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "insertReviewMetricQuestion" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "objects" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: {
                    kind: "Name",
                    value: "review_metric_question_insert_input",
                  },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_review_metric_question" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "objects" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "objects" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returning" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertReviewMetricQuestionMutation,
  InsertReviewMetricQuestionMutationVariables
>;
export const InsertVideoInterviewScoreDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "insertVideoInterviewScore" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "responseId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "videoInterviewQuestionId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "scoreDescription" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "insert_video_interview_question_score_one",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "object" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: {
                        kind: "Name",
                        value: "review_metric_question_response_id",
                      },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "responseId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: {
                        kind: "Name",
                        value: "video_interview_question_id",
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "videoInterviewQuestionId",
                        },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "score_description" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "scoreDescription" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "on_conflict" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "constraint" },
                      value: {
                        kind: "EnumValue",
                        value:
                          "video_interview_score_video_interview_question_id_is_ai_score_k",
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "update_columns" },
                      value: {
                        kind: "ListValue",
                        values: [
                          {
                            kind: "EnumValue",
                            value: "review_metric_question_response_id",
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertVideoInterviewScoreMutation,
  InsertVideoInterviewScoreMutationVariables
>;
export const InsertVideoInterviewStatusDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "InsertVideoInterviewStatus" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "vid" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "status" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "video_interview_status_enum" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "insert_video_interview_status_history_one",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "object" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "status" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "status" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "video_interview_id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "vid" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertVideoInterviewStatusMutation,
  InsertVideoInterviewStatusMutationVariables
>;
export const InviteMemberDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "inviteMember" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "surname" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "inviteMember" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "inviteMemberInput" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "email" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "email" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "name" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "name" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "surname" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "surname" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
                { kind: "Field", name: { kind: "Name", value: "message" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InviteMemberMutation,
  InviteMemberMutationVariables
>;
export const LoginDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "login" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "password" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "login" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "loginInput" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "username" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "username" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "password" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "password" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "accessToken" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "otpExpiration" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "twoFactorRequired" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoginMutation, LoginMutationVariables>;
export const RegisterDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "register" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "inviteToken" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "otpCode" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "password" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "phoneNumber" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "surname" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "register" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "registerInput" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "inviteToken" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "inviteToken" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "name" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "name" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "otpCode" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "otpCode" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "password" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "password" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "phoneNumber" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "phoneNumber" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "surname" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "surname" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "accessToken" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RegisterMutation, RegisterMutationVariables>;
export const ResendOtpDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ResendOTP" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "invitationToken" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          defaultValue: { kind: "StringValue", value: "", block: false },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "phoneNumber" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          defaultValue: { kind: "StringValue", value: "", block: false },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          defaultValue: { kind: "StringValue", value: "", block: false },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "resendOtp" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "resendOtpInput" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "invitationToken" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "invitationToken" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "phoneNumber" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "phoneNumber" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "email" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "email" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "otpExpiration" },
                },
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResendOtpMutation, ResendOtpMutationVariables>;
export const ResetPasswordDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "resetPassword" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "recoveryToken" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "password" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "resetPassword" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "resetPasswordInput" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "username" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "username" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "recoveryToken" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "recoveryToken" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "password" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "password" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
                { kind: "Field", name: { kind: "Name", value: "message" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const SearchDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Search" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "index" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "query" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "search" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "index" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "index" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "limit" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "limit" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "query" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "query" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "offset" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "clientCandidate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchQuery, SearchQueryVariables>;
export const SendOtpDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "sendOTP" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "invitationToken" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "phoneNumber" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "sendOtp" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "sendOtpInput" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "invitationToken" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "invitationToken" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "phoneNumber" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "phoneNumber" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "otpExpiration" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SendOtpMutation, SendOtpMutationVariables>;
export const SendVideoInterviewLinkToCandidateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "sendVideoInterviewLinkToCandidate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "ccpIds" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "Int" },
                },
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "interviewType" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "resetInterview" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
          defaultValue: { kind: "BooleanValue", value: false },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "sendVideoInterviewLinkToCandidates" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "ccpIds" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "ccpIds" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "interviewType" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "interviewType" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "resetInterview" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "resetInterview" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
                { kind: "Field", name: { kind: "Name", value: "message" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SendVideoInterviewLinkToCandidateMutation,
  SendVideoInterviewLinkToCandidateMutationVariables
>;
export const GenerateShareableJdLinkDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "generateShareableJDLink" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "jdId" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "generateShareableJDLink" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "jdId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "jdId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "shareLink" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GenerateShareableJdLinkMutation,
  GenerateShareableJdLinkMutationVariables
>;
export const UpdateCandidateResolutionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateCandidateResolution" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "candidate_resolution" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "client_candidate_project_candidate_resolution_enum",
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "update_client_candidate_project_by_pk",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pk_columns" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "candidate_resolution" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "candidate_resolution" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCandidateResolutionMutation,
  UpdateCandidateResolutionMutationVariables
>;
export const UpdateClientDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateClient" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "client_id" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "client_name" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "description" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "employee_count" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "has_multilocation" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "working_type" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "client_locations" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "client_location_insert_input" },
                },
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "client_sectors" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "client_sector_insert_input" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_client_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pk_columns" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "client_id" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "client_name" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "client_name" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "description" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "description" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "employee_count" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "employee_count" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "has_multilocation" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "has_multilocation" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "working_type" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "working_type" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_client_location" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "objects" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "client_locations" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "on_conflict" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "constraint" },
                      value: {
                        kind: "EnumValue",
                        value: "client_location_pkey",
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "update_columns" },
                      value: {
                        kind: "ListValue",
                        values: [
                          { kind: "EnumValue", value: "city_id" },
                          { kind: "EnumValue", value: "address" },
                          { kind: "EnumValue", value: "address_phone" },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affected_rows" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_client_sector_one" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "object" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "client_sectors" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "on_conflict" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "constraint" },
                      value: { kind: "EnumValue", value: "client_sector_pkey" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "update_columns" },
                      value: {
                        kind: "ListValue",
                        values: [{ kind: "EnumValue", value: "sector_id" }],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateClientMutation,
  UpdateClientMutationVariables
>;
export const UpdateClientCandidateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateClientCandidate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "clientCandidateId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "surname" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "mobile_number" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email_address" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cv_file_uri" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cv_file_name" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_client_candidate_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pk_columns" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "clientCandidateId" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "name" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "name" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "surname" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "surname" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "email_address" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "email_address" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "mobile_number" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "mobile_number" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "cv_file_uri" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cv_file_uri" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "cv_file_name" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cv_file_name" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateClientCandidateMutation,
  UpdateClientCandidateMutationVariables
>;
export const UpdateClientCandidateProjectNoteDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateClientCandidateProjectNote" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "note" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "update_client_candidate_project_note_by_pk",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pk_columns" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "note" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "note" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateClientCandidateProjectNoteMutation,
  UpdateClientCandidateProjectNoteMutationVariables
>;
export const UpdateJobDescriptionSkillsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateJobDescriptionSkills" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "uuid_comparison_exp" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "values" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "jsonb" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "step" } },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "job_description_step_enum" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_job_description" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "job_description_skills" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "values" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "step" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "step" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returning" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateJobDescriptionSkillsMutation,
  UpdateJobDescriptionSkillsMutationVariables
>;
export const UpdateJobDescriptionEducationTypeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateJobDescriptionEducationType" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "uuid_comparison_exp" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "values" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "jsonb" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "step" } },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "job_description_step_enum" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_job_description" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "academic_department" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "values" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "step" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "step" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returning" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateJobDescriptionEducationTypeMutation,
  UpdateJobDescriptionEducationTypeMutationVariables
>;
export const UpdateJobDescriptionExperienceLevelDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateJobDescriptionExperienceLevel" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "uuid_comparison_exp" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "values" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "jsonb" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "step" } },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "job_description_step_enum" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_job_description" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "job_experience" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "values" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "step" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "step" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returning" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateJobDescriptionExperienceLevelMutation,
  UpdateJobDescriptionExperienceLevelMutationVariables
>;
export const UpdateJobDescriptionRequirementsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateJobDescriptionRequirements" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "uuid_comparison_exp" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "values" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "jsonb" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "step" } },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "job_description_step_enum" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_job_description" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: {
                        kind: "Name",
                        value: "job_description_requirements",
                      },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "values" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "step" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "step" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returning" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateJobDescriptionRequirementsMutation,
  UpdateJobDescriptionRequirementsMutationVariables
>;
export const UpdateJobDescriptionResponsibilitiesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateJobDescriptionResponsibilities" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "uuid_comparison_exp" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "values" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "jsonb" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "step" } },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "job_description_step_enum" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_job_description" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: {
                        kind: "Name",
                        value: "job_description_responsibilities",
                      },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "values" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "step" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "step" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returning" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateJobDescriptionResponsibilitiesMutation,
  UpdateJobDescriptionResponsibilitiesMutationVariables
>;
export const UpdateJobDescriptionBenefitsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateJobDescriptionBenefits" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "uuid_comparison_exp" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "values" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "jsonb" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "step" } },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "job_description_step_enum" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_job_description" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "job_description_benefits" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "values" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "step" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "step" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returning" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateJobDescriptionBenefitsMutation,
  UpdateJobDescriptionBenefitsMutationVariables
>;
export const UpdateJobDescriptionSalaryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateJobDescriptionSalary" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "uuid_comparison_exp" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "values" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "jsonb" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "step" } },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "job_description_step_enum" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_job_description" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "salary" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "values" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "step" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "step" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returning" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateJobDescriptionSalaryMutation,
  UpdateJobDescriptionSalaryMutationVariables
>;
export const UpdateJobDescriptionIntroductionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateJobDescriptionIntroduction" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "uuid_comparison_exp" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "values" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "step" } },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "job_description_step_enum" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_job_description" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "step" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "step" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_append" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "sections" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "introduction" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "values" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returning" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateJobDescriptionIntroductionMutation,
  UpdateJobDescriptionIntroductionMutationVariables
>;
export const UpdateProjectDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateProject" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "project_name" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "start_date" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "date" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "end_date" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "date" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "description" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "client_id" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "language" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "language_enum" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "role_id" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "interviewTemplateId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_project_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pk_columns" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "project_name" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "project_name" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "start_date" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "start_date" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "end_date" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "end_date" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "description" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "description" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "client_id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "client_id" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "language" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "language" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "role_id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "role_id" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "interview_template_id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "interviewTemplateId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateProjectMutation,
  UpdateProjectMutationVariables
>;
export const UpdateProjectInterviewTemplateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateProjectInterviewTemplate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "interview_template_id" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_project_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pk_columns" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "interview_template_id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "interview_template_id" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateProjectInterviewTemplateMutation,
  UpdateProjectInterviewTemplateMutationVariables
>;
export const UpdateProjectLanguageDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateProjectLanguage" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "projectId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "language" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "language_enum" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_project_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pk_columns" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "projectId" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "language" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "language" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateProjectLanguageMutation,
  UpdateProjectLanguageMutationVariables
>;
export const UpdateProjectStartAndEndDateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateProjectStartAndEndDate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "projectId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "interview_starts_at" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "timestamptz" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "interview_ends_at" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "timestamptz" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_project_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pk_columns" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "projectId" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "interview_starts_at" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "interview_starts_at" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "interview_ends_at" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "interview_ends_at" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateProjectStartAndEndDateMutation,
  UpdateProjectStartAndEndDateMutationVariables
>;
export const UpdateProjectStatusDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateProjectStatus" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "status" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "project_status_enum" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "projectId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_project_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pk_columns" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "projectId" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "status" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "status" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateProjectStatusMutation,
  UpdateProjectStatusMutationVariables
>;
export const UpdateProjectTemplateIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateProjectTemplateId" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "interview_template_id" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_project_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pk_columns" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "interview_template_id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "interview_template_id" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateProjectTemplateIdMutation,
  UpdateProjectTemplateIdMutationVariables
>;
export const UpdateUserAvatarDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateUserAvatar" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "avatar" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_user_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pk_columns" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "avatar" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "avatar" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateUserAvatarMutation,
  UpdateUserAvatarMutationVariables
>;
export const UpdateUserKeyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateUserKey" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "expired_at" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "timestamptz" },
          },
          defaultValue: { kind: "StringValue", value: "", block: false },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_user_key_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pk_columns" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "expired_at" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "expired_at" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateUserKeyMutation,
  UpdateUserKeyMutationVariables
>;
export const UpdateUserTitleDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateUserTitle" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "uuid" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "title" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          defaultValue: { kind: "StringValue", value: "", block: false },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_user_by_pk" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pk_columns" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "title" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "title" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateUserTitleMutation,
  UpdateUserTitleMutationVariables
>;
export const UpdateVideoInterviewScoreDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateVideoInterviewScore" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "videoInterviewQuestionId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "scoreDescription" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "update_video_interview_question_score",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "is_ai_score" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: { kind: "BooleanValue", value: false },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: {
                        kind: "Name",
                        value: "video_interview_question_id",
                      },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "_eq" },
                            value: {
                              kind: "Variable",
                              name: {
                                kind: "Name",
                                value: "videoInterviewQuestionId",
                              },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "_set" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "score_description" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "scoreDescription" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returning" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateVideoInterviewScoreMutation,
  UpdateVideoInterviewScoreMutationVariables
>;
export const UpsertClientCandidateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "upsertClientCandidate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: {
                    kind: "Name",
                    value: "client_candidate_insert_input",
                  },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "insert_client_candidate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "objects" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "on_conflict" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "constraint" },
                      value: {
                        kind: "EnumValue",
                        value: "client_candidate_email_address_client_id_key",
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "update_columns" },
                      value: {
                        kind: "ListValue",
                        values: [{ kind: "EnumValue", value: "updated_at" }],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returning" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertClientCandidateMutation,
  UpsertClientCandidateMutationVariables
>;
export const Verify2FaDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "verify2FA" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "otpCode" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "verify2FA" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "verify2FAInput" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "otpCode" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "otpCode" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "accessToken" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Verify2FaMutation, Verify2FaMutationVariables>;
export const GetSignedUrlDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getSignedUrl" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "signedUrlInput" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "SignedUrlInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getSignedUrl" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "signedUrlInput" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "signedUrlInput" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "signedUrls" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSignedUrlQuery, GetSignedUrlQueryVariables>;
