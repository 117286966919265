import styled from "styled-components";
import { NSCandidateResultInfoCardStyle } from "../../../components/CandidateResultInfoCard/CandidateResultInfoCard.style";
import { NSOutlinedButtonStyle } from "../../../components/OutlinedButton/OutlinedButton.style";
import { NSMainButtonStyle } from "../../../components/MainButton/MainButton.style";

export namespace NSCandidateScoreResultStyle {
  export const Container = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: auto;
    background-color: ${(props) => props.theme.colors.light.light};
  `;

  export const InnerContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 24px;
  `;

  export const BorderContainer = styled.div`
    border-radius: 16px;
    border-top: 3.5px solid transparent;
    background-image: linear-gradient(white, white),
      linear-gradient(to left, #56ba6c, #2638d9);
    background-origin: border-box;
    background-clip: content-box, border-box;
    position: relative;
    margin-bottom: 124px;
    width: 792px;
  `;

  export const LogoContainer = styled.div`
    margin: 47px auto 77px;
  `;

  export const InfoContainer = styled.div`
    margin: 47px auto 77px;
  `;

  export const Position = styled.div`
    font-size: ${(props) => props.theme.typography.titleh3.size};
    line-height: ${(props) => props.theme.typography.titleh3.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.bold};
    margin-bottom: 4px;
  `;

  export const CompanyAndLocation = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    margin-bottom: 24px;
  `;

  export const WorkingType = styled(CompanyAndLocation)`
    color: ${(props) => props.theme.colors.neutrals.grey06};
    margin-bottom: 32px;
  `;

  export const ScoreResultPageContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    backgroundcolor: ${(props) => props.theme.colors.light.light};
    height: fit-content;
  `;

  export const GreenEllipse = styled.div`
    position: absolute;
    top: 0;
    left: 0;
  `;

  export const Purplellipse = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
  `;

  export const ContentContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    z-index: 3;
  `;

  export const OverallScoreContainer = styled.div`
    height: 200px;
    width: 100%;
    margin-top: 120px;
    ${NSCandidateResultInfoCardStyle.Header} {
      font-size: ${(props) => props.theme.typography.pharagraph1.size};
      line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
      font-weight: ${(props) => props.theme.typography.fontWeights.bold};
      padding: 16px;
    }
  `;

  export const BarChartContainer = styled.div`
    height: fit-content;
    width: 100%;
    margin-top: 16px;
    gap: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  `;

  export const Title = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: ${(props) => props.theme.typography.pharagraph1.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.bold};
    padding: 16px 0;
    color: ${(props) => props.theme.colors.dark.dark};
  `;

  export const MetricsAndReviewsContainer = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 48px;
  `;

  export const MetricsAndReview = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 0;
    border-bottom: 1px solid ${(props) => props.theme.colors.neutrals.grey03};
  `;

  export const SecondaryTitle = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.bold};
    color: ${(props) => props.theme.colors.dark.dark};
  `;

  export const Explanation = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    color: ${(props) => props.theme.colors.dark.dark};
  `;

  export const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    margin-bottom: 12px;
    position: absolute;
    top: 182px;
    padding: 0 24px;
    width: 100%;
    z-index: 4;
  `;

  export const LeftButtonContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;

    ${NSOutlinedButtonStyle.OutlinedButtonBase} {
      ${NSMainButtonStyle.ButtonBase} {
        :hover {
          svg {
            path {
              stroke: ${(props) => props.theme.colors.light.light};
            }
          }
        }
      }
    }
  `;
}
