import React, { useRef, FC, useEffect } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { NSOTPModalType } from "./OTPModal.type";
import { NSOTPModalStyle } from "./OTPModal.style";
import Modal from "../Modal/Modal";
import SolidButton from "../SolidButton/SolidButton";
import Config from "../../config.json";
import i18n from "../../library/i18next";

import { useCounter } from "@uidotdev/usehooks";
import { Trans } from "react-i18next";

const otpLength = parseInt(Config.REACT_APP_OTP_LENGTH);

const schema = yup.object().shape(
  Array.from({ length: otpLength }).reduce(
    (acc: Record<string, yup.StringSchema>, _, index) => {
      acc[`otp${index + 1}`] = yup
        .string()
        .length(1, i18n.t("forms.otpError"))
        .required();
      return acc;
    },
    {} as Record<string, yup.StringSchema>
  )
);

const OTPModal: FC<NSOTPModalType.IOTPModal> = ({
  onClose,
  onResend,
  onVerify,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: Array.from({ length: otpLength }).reduce(
      (acc: Record<string, string>, _, index) => {
        acc[`otp${index + 1}`] = "";
        return acc;
      },
      {} as Record<string, string>
    ),
  });

  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const [timeLeft, { decrement, reset }] = useCounter(
    parseInt(Config.REACT_APP_OTP_RESEND_TIMEOUT),
    {
      min: 0,
    }
  );
  const resendEnabled = timeLeft === 0;

  useEffect(() => {
    if (timeLeft > 0) {
      const interval = setInterval(decrement, 1000);
      return () => clearInterval(interval);
    }
  }, [timeLeft, decrement]);

  const onSubmit: SubmitHandler<Record<string, string | undefined>> = (
    data
  ) => {
    const otp = Array.from({ length: otpLength })
      .map((_, index) => data[`otp${index + 1}`] || "")
      .join("");
    onVerify(otp);
  };

  const handleResend = () => {
    Array.from({ length: otpLength }).forEach((_, index) => {
      setValue(`otp${index + 1}`, "");
    });
    inputRefs.current[0]?.focus();
    reset();
    onResend();
  };

  const handleChange = (index: number, value: string) => {
    setValue(`otp${index + 1}`, value);

    if (value && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Backspace") {
      e.preventDefault();

      if (getValues(`otp${index + 1}`) !== "") {
        setValue(`otp${index + 1}`, "");
      } else if (index > 0) {
        setValue(`otp${index}`, "");
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  const errorMessage = Array.from({ length: otpLength })
    .map((_, index) => errors[`otp${index + 1}`]?.message)
    .filter(Boolean)[0] as string | undefined;

  return (
    <Modal isOpen={true} onClickCloseButton={onClose}>
      <NSOTPModalStyle.ModalContainer>
        <NSOTPModalStyle.ModalTitle>
          {i18n.t("forms.verifyPhone")}
        </NSOTPModalStyle.ModalTitle>
        <NSOTPModalStyle.ModalSubtitle>
          <Trans
            i18nKey={i18n.t("forms.verifyPhoneDescription")}
            values={{
              otpLength: otpLength,
            }}
          />
        </NSOTPModalStyle.ModalSubtitle>
        <NSOTPModalStyle.OTPForm onSubmit={handleSubmit(onSubmit)}>
          <NSOTPModalStyle.InputContainer>
            {Array.from({ length: otpLength }).map((_, index) => (
              <Controller
                key={index}
                name={`otp${index + 1}`}
                control={control}
                render={({ field }) => (
                  <NSOTPModalStyle.Input
                    type="text"
                    maxLength={1}
                    {...field}
                    onChange={(e) => {
                      if (!/^[0-9]+$/.test(e.target.value)) return;
                      field.onChange(e);
                      handleChange(index, e.target.value);
                    }}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    ref={(el) => {
                      inputRefs.current[index] = el;
                    }}
                    style={{
                      width: "40px",
                      textAlign: "center",
                      fontSize: "18px",
                      marginRight: "10px",
                    }}
                  />
                )}
              />
            ))}
            {errorMessage && (
              <NSOTPModalStyle.ErrorText>
                {errorMessage}
              </NSOTPModalStyle.ErrorText>
            )}
          </NSOTPModalStyle.InputContainer>

          <SolidButton fullWidth label={i18n.t("forms.verify")} type="submit" />
          <NSOTPModalStyle.ResendContainer>
            <NSOTPModalStyle.ResendText>
              {i18n.t("forms.resendDescription")}
            </NSOTPModalStyle.ResendText>
            <SolidButton
              size="small"
              onClick={handleResend}
              disabled={!resendEnabled}
              label={
                <NSOTPModalStyle.ResendButtonText>
                  {resendEnabled
                    ? i18n.t("forms.resend")
                    : i18n.t("forms.resend")}
                  {!resendEnabled && (
                    <NSOTPModalStyle.TimeLeft>
                      {" "}
                      ({timeLeft}s)
                    </NSOTPModalStyle.TimeLeft>
                  )}
                </NSOTPModalStyle.ResendButtonText>
              }
            />
          </NSOTPModalStyle.ResendContainer>
        </NSOTPModalStyle.OTPForm>
      </NSOTPModalStyle.ModalContainer>
    </Modal>
  );
};

export default OTPModal;
