import styled from "styled-components";
import colors from "../../style/colors";

export namespace NSModalStyle {
  export const CloseButtonContainer = styled.div`
    position: absolute;
    right: 0px;
    top: 0px;

    svg {
      width: 24px;
      height: 24px;
    }
  `;

  export const customModalStyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "48px 32px",
      background: "#FFFFFF",
      borderRadius: "16px",
      boxShadow: `0px 8px 24px ${colors.shadesOfdark.dark04}`,
    },

    overlay: {
      backgroundColor: colors.shadesOfdark.dark05,

      zIndex: 10000,
    },
  };

  export const BluredBackground = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colors.dark.dark};
    opacity: 0.64;
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
  `;
}
