import styled from "styled-components";

export namespace NSRegisterStyle {
  export const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colors.neutrals.grey02};
    display: flex;
    overflow-x: hidden;
  `;

  export const LeftSideContainer = styled.div`
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    flex: 1.4;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-right: 40%;
  `;

  export const RightSideContainer = styled.div<{ imgSrc: string }>`
    position: fixed;
    top: 0;
    right: 0;
    width: 40%;
    height: 100vh;
    background-image: url(${(props) => props.imgSrc});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 1;
  `;

  export const GradientOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    opacity: 0.7;
    background: linear-gradient(
      180deg,
      ${(props) => props.theme.colors.gradients.gradientsCars.secondary},
      ${(props) => props.theme.colors.gradients.gradientsCars.primary}
    );
  `;

  export const DescriptionContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
  `;

  const Title = styled.h1`
    font-size: ${(props) => props.theme.typography.titleh1.size};
    font-weight: ${(props) => props.theme.typography.fontWeights.bold};
    z-index: 2;
  `;

  export const WhiteTitle = styled(Title)`
    color: ${(props) => props.theme.colors.light.light};
    text-align: center;
  `;

  export const ColoredTitle = styled(Title)`
    color: ${(props) => props.theme.colors.tertiary.tertiary};
  `;

  export const SubTitle = styled.h2`
    color: ${(props) => props.theme.colors.light.light};
    font-size: ${(props) => props.theme.typography.pharagraph1.size};
    line-height: ${(props) => props.theme.typography.pharagraph1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    margin-top: 1.5rem;
    padding: 0 6rem;
    text-align: center;
    z-index: 2;
  `;
}
