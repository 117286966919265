import DatePicker from "../../../components/DatePicker/DatePicker";
import Drawer from "../../../components/Drawer/Drawer";
import Dropdown from "../../../components/Dropdown/Dropdown";
import Loading from "../../../components/Loading/Loading";
import { NSCreateProjectStyle } from "./CreateProject.style";
import SolidButton from "../../../components/SolidButton/SolidButton";
import TextArea from "../../../components/TextArea/TextArea";
import TextInput from "../../../components/TextInput/TextInput";
import i18n from "../../../library/i18next";
import useCreateProjectVm from "./CreateProjectVm";
import { NSEditProjectStyle } from "../Edit/EditProject.style";
import { Language_Enum } from "../../../gql/graphql";
import { Controller } from "react-hook-form";
import LinkButton from "../../../components/LinkButton/LinkButton";
export default function CreateProject() {
  const createProjectVm = useCreateProjectVm();

  const { control } = createProjectVm;

  return createProjectVm.defaultSelectedClient === undefined ? (
    <Loading />
  ) : (
    <Drawer
      onClickCloseIcon={createProjectVm.closeDrawer}
      isAddedSelfCloseFunction
      title={i18n.t("general.createNewProject")}
    >
      <NSCreateProjectStyle.Container>
        <NSCreateProjectStyle.CreateProjectForm
          onSubmit={createProjectVm.handleSubmit()}
        >
          <Controller
            name="projectName"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextInput
                id="ProjectNameId"
                placeholder={i18n.t("forms.projectName")}
                error={!!error?.message}
                errorText={error?.message}
                {...field}
              />
            )}
          />
          <Controller
            name="client"
            control={control}
            render={({ field, fieldState: { error } }) => {
              const { ref, ...fieldProps } = field;
              return (
                <Dropdown
                  id={1}
                  type="basic"
                  placeholder={i18n.t("forms.client")}
                  options={createProjectVm.clients ?? []}
                  onSelect={(_id, selected) => field.onChange(selected)}
                  selectedDropdownItem={createProjectVm.defaultSelectedClient}
                  error={!!error?.message}
                  errorText={error?.message}
                  {...fieldProps}
                />
              );
            }}
          />
          <Controller
            name="location"
            control={control}
            render={({ field, fieldState: { error } }) => {
              const { ref, ...fieldProps } = field;
              return (
                <Dropdown
                  id={2}
                  type="withCheckbox"
                  placeholder={i18n.t("forms.location")}
                  options={createProjectVm.cities ?? []}
                  onCheckedItems={(items) => field.onChange(items)}
                  error={!!error?.message}
                  errorText={error?.message}
                  {...fieldProps}
                />
              );
            }}
          />
          <NSEditProjectStyle.ControllerContainer>
            <Controller
              name="role"
              control={control}
              render={({ field, fieldState: { error } }) => {
                const { ref, ...fieldProps } = field;
                return (
                  <Dropdown
                    id={3}
                    type="basic"
                    placeholder={i18n.t("forms.role")}
                    options={createProjectVm.roles ?? []}
                    onSelect={(_id, selected) => field.onChange(selected)}
                    error={!!error?.message}
                    errorText={error?.message}
                    isSearchActive
                    onSearchChange={createProjectVm.onRoleSearchChange}
                    {...fieldProps}
                  />
                );
              }}
            />
            <NSEditProjectStyle.WarningText>
              {i18n.t("forms.roleInformation")}
            </NSEditProjectStyle.WarningText>
          </NSEditProjectStyle.ControllerContainer>
          <Controller
            name="timePeriod"
            control={control}
            render={({ field }) => {
              const { ref, onChange, ...fieldProps } = field;
              return (
                <DatePicker
                  placeholder={i18n.t("forms.startDateEndDate")}
                  onChange={(dates) => onChange(dates)}
                  {...fieldProps}
                />
              );
            }}
          />

          <NSEditProjectStyle.ControllerContainer>
            <Controller
              name="language"
              control={control}
              render={({ field, fieldState: { error } }) => {
                const { ref, ...fieldProps } = field;
                return (
                  <Dropdown
                    id={5}
                    type="basic"
                    placeholder={i18n.t("forms.language")}
                    options={createProjectVm.languages ?? []}
                    onSelect={(_id, selected) =>
                      field.onChange(selected?.id as Language_Enum)
                    }
                    selectedDropdownItem={createProjectVm.languages?.[0]}
                    error={!!error?.message}
                    errorText={error?.message}
                    {...fieldProps}
                  />
                );
              }}
            />

            <NSEditProjectStyle.WarningText>
              {i18n.t("forms.languageInformation")}
            </NSEditProjectStyle.WarningText>
          </NSEditProjectStyle.ControllerContainer>

          {createProjectVm.interviewTemplates &&
            createProjectVm.interviewTemplates.length > 0 && (
              <NSEditProjectStyle.ControllerContainer>
                <LinkButton
                  label={
                    createProjectVm.seePreviewTemplates
                      ? i18n.t("projects.notSeePreviewTemplates")
                      : i18n.t("projects.seePreviewTemplates")
                  }
                  onClick={
                    createProjectVm.seePreviewTemplates
                      ? createProjectVm.onClickNotSeePreviewTemplates
                      : createProjectVm.onClickSeePreviewTemplates
                  }
                />

                {createProjectVm.seePreviewTemplates && (
                  <Controller
                    name="interviewTemplate"
                    control={control}
                    render={({ field, fieldState: { error } }) => {
                      const { ref, ...fieldProps } = field;
                      return (
                        <Dropdown
                          id={4}
                          type="basic"
                          placeholder={i18n.t("forms.interviewTemplate")}
                          options={createProjectVm.interviewTemplates ?? []}
                          onSelect={(_id, selected) => field.onChange(selected)}
                          error={!!error?.message}
                          errorText={error?.message}
                          {...fieldProps}
                        />
                      );
                    }}
                  />
                )}
              </NSEditProjectStyle.ControllerContainer>
            )}

          <Controller
            name="description"
            control={control}
            render={({ field, fieldState: { error } }) => {
              const { ref, ...fieldProps } = field;
              return (
                <TextArea
                  id="descriptionId"
                  placeholder={i18n.t("forms.description")}
                  error={!!error?.message}
                  errorText={error?.message}
                  {...fieldProps}
                />
              );
            }}
          />
          <SolidButton
            type="submit"
            label={i18n.t("general.createNewProject")}
            fullWidth
          />
        </NSCreateProjectStyle.CreateProjectForm>
      </NSCreateProjectStyle.Container>
    </Drawer>
  );
}
